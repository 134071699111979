import React, { Component } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material'
import { QueuePlayNext, DeleteForever } from '@mui/icons-material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import Api from '../../services/api'
import CustomizedSnackbars from '../../components/material-snackbars'
import Dropzone from 'react-dropzone'
import HeaderMenu from '../../components/header-menu'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SimpleSelect from '../../components/select/simple'
import SimpleCheckBox from '../../components/checkbox/check'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import SimpleDatePicker from '../../components/date-pickers/inline'
import * as Service from '../../services/priority.service'
import { parseJSON } from 'date-fns'
import Footer from '../../components/footer'
import { browserHistory } from '../../helpers/history'
import { FormattedMessage, injectIntl } from 'react-intl'
import Title from '../../components/title-name'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { SketchPicker } from 'react-color';

class PriorityRegistration extends Component {

  constructor(props) {
    super(props)
    const { userSession } = this.props

    this.globalAdm = false
    this.isClient = true
    
    if (userSession.user.userrole == null) {
      Api.logoff()
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype !== 3) {
        this.isClient = false
      }
      if (item.role.roletype === 0) {
        this.globalAdm = true
      }
    })

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      name: '',
      colorhex: ''
    }

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.changeValues = this.changeValues.bind(this)

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount()
    }
  }

  handleChangeComplete = (color) => {
    this.setState(prevState => ({
      item: { 
        ...prevState.item,
        colorhex: color.hex
      }
    }))
  }

  handleInputChange = e => {
    const name = e.target.name
    const value = e.target.value

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }))
  }

  handleSubmit = async e => {

    const intl = this.props.intl;
      e.preventDefault()

      this.setState({ loading: true })

      let data = new FormData()
            
      if( this.state.item.name.trim() === "") {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: this.props.intl.formatMessage({id:"missing.name.field"})
        })
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }))

            
        for (let key in this.state.item) {
          if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date') > -1 || key.toString().toLowerCase().indexOf('eventdate') > -1) {
            data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
          }else if(key.toString().toLowerCase().indexOf('createduser') > -1) {
            data.append(key, this.state.item[key]);
          }else if(key.toString().toLowerCase().indexOf('createdat') > -1) {
            data.append(key, this.state.item[key]);
          }else if(key.toString().toLowerCase().indexOf('name') > -1) {
            data.append("name", this.state.item[key]);
          }else if(key.toString().toLowerCase().indexOf('colorhex') > -1) {
            data.append("colorhex", this.state.item[key]);
          }
        }

        var result = await Service.addItem(data)
        if(result && result.success) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "add.success" }),
            item: this.initialState
          })
        } else {
          const intl = this.props.intl
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
      }
  }

  closeNotification() {
    this.setState({ openNotification: false })
  }

  changeValues(stateName, value, text = '') {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }))
  }

  render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}>
        <div className={classes.drawerHeader} />
        <Container component='main' maxWidth='md'>
          <CssBaseline />
          <div className={classes.paper} style={{ marginTop: 0 }}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
              </Grid>
              <Grid item xs={12} sm={8} />
            </Grid>
            <Avatar className={classes.avatar}>
              <QueuePlayNext />
            </Avatar>
            <Typography component='h1' variant='h5'>
              {<FormattedMessage id="add.priority" />}
              <Title />
            </Typography>
            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField inputProps={{ maxLength: 1000 }} fullWidth label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name}  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SketchPicker
                    color={ item.colorhex }
                    onChangeComplete={ this.handleChangeComplete } />
                </Grid>
                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={4}    >
                  <Button 
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit} >
                    {<FormattedMessage id="save" />}
                  </Button>
                </Grid>
              </Grid>
              </Grid>
              </Grid>
            </form>
          </div>
          {this.state.loading && (
            <Overlay>
              <CircularProgress color='secondary' />
            </Overlay>
          )}
          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification} />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

PriorityRegistration.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(PriorityRegistration)));