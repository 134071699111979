import React, { Component, useEffect  } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Link from "@mui/material/Link";
import moment from "moment";
import Grid from '@mui/material/Grid';
import SimpleCheckBox from '../checkbox/check';
import Api from "../../services/api";
import {IconButton, Tooltip} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReactHtmlParser from 'react-html-parser';
import { renderToString, renderToStaticMarkup } from "react-dom/server"
import CheckIcon from '@mui/icons-material/Check';
import { PapiroConsole } from "../../utils/papiroConsole";
import './styles.css'
import { FormattedMessage,injectIntl } from 'react-intl';
import parse from "html-react-parser";

class AccompanimentAction extends Component {

    render() {
        const intl = this.props.intl;

        const { classes, action, showPrivacy, attachments, getFile, id, finishAccompanimentId, addAccompanimentToKnowledgeBase, deleteKnowledgeBase, knowledgebage, originId, openAccompanimentLocationModal, client } = this.props;

        //this.action = renderToStaticMarkup(<p> {action.description} </p>);
        this.action =action.description;

        if(action.description.trim().startsWith("<p> &lt;h") || action.description.trim().startsWith("<p>&lt;div")) {
            PapiroConsole.log("=== AccompanimentAction if 1 ===")
            this.action = renderToStaticMarkup(action.description)
            PapiroConsole.log(this.action)
        } else if (action.description.trim().startsWith("<div style=\"") || action.description.trim().startsWith("<div dir=") || action.description.trim().startsWith("<p>") || action.description.trim().startsWith("<h1>") || action.description.trim().startsWith("<h2>") || action.description.trim().startsWith("<h3>")) {
            this.action = action.description
            PapiroConsole.log("=== AccompanimentAction else if ===")
            PapiroConsole.log(this.action)
        }

        const handleFile = (fileName, id,filerepositorymethod) => {
            getFile(fileName, id,filerepositorymethod);
        }
        const handleKnowledgeBaseState = async (statename, value) => {
            //? Adiciona à base ou deleta dependendo do estado do checkbox (value)
            value ? addAccompanimentToKnowledgeBase() : deleteKnowledgeBase(); 
        }
        const handleOpenAccompanimentLocationModal = () => {
            openAccompanimentLocationModal()
        }
        
        return (
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12}>
                    <div className={classes.actionBox}>
                        <Grid item xs={12} sm={12}>
                            <div className={action.privacy ? 'privacyAction' : 'publicAction'}>
                                <p>
                                    <span className="user-action-name">{action.useraction.name}</span>
                                    <span className="date">{moment(action.actiontime).format("DD/MM/YYYY HH:mm")}</span>
                                    {showPrivacy && <span className="type">{action.privacy ? this.props.intl.formatMessage({id:"private.action"}) : this.props.intl.formatMessage({id:"public.action"})}</span>}
                                    { originId && originId == 6 && (<IconButton style={{ padding: 0 }} aria-label="LocationIcon"><LocationOnIcon onClick={handleOpenAccompanimentLocationModal} style={{ fontColor: "#CC1717", fill: "#CC1717" }} /></IconButton>)}
                                    { id == finishAccompanimentId && (<Tooltip title={<h5>{this.props.intl.formatMessage({id:"accompaniment.actions.title.recently.added.action"})}</h5>}><IconButton style={{ padding: 0, marginLeft: 10 }} aria-label="CheckIcon"><CheckIcon style={{ fontColor: "#43a047", fill: "#43a047" }} /></IconButton></Tooltip>) }
                                    <span style={{float: "right", border: "none", marginTop: "-10px"}}>
                                    {(!action.privacy && !client) && 
                                        <SimpleCheckBox 
                                            label={this.props.intl.formatMessage({id:"add.to.knowledgebase"})}
                                            name='knowledgebage' 
                                            stateName='knowledgebage' 
                                            changeSelect={handleKnowledgeBaseState} 
                                            selected={knowledgebage} />
                                    }
                                    </span>
                                </p>
                            </div>
                        </Grid>
						{/*<p><div className="action-text-content" style={{ fontWeight: 'normal' }} dangerouslySetInnerHTML={{__html: this.action}} /></p>*/}
						{/*<div className="action-text-content" style={{ fontWeight: 'normal' }}> { ReactHtmlParser (this.action) } </div>*/}
						<p><div className="action-text-content" style={{ fontWeight: 'normal' }}> { parse (this.action) } </div></p>
                        <div className={classes.attachmentsBox} style={{border: 0}}>
                            <ul>
                                {attachments.map(item => {
                                    if(item.attachment.type.indexOf("image/png") > -1 || item.attachment.type.indexOf("image/jpg") > -1 || item.attachment.type.indexOf("image/jpeg") > -1){
                                        return (<li key={item.attachment.path}>
                                            <AttachFileIcon /> <Link onClick={() => handleFile(item.attachment.path, id, item.attachment.filerepositorymethod)}> { item.attachment.path.split('/').pop()} { /*<img style={{maxHeight: '250px', maxWidth: '250px'}} src={Api.apiurlbase()+item.attachment.path}></img>*/ }</Link>
                                        </li>)
                                    }else{
                                        return (<li key={item.attachment.path}>
                                            <AttachFileIcon /> <Link onClick={() => handleFile(item.attachment.path, id, item.attachment.filerepositorymethod)}>{item.attachment.description}</Link>
                                        </li>)
                                    }
                                    
                                })}
                            </ul>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

AccompanimentAction.propTypes = {
    classes: PropTypes.object.isRequired,
    action: PropTypes.object.isRequired,
    showPrivacy: PropTypes.bool.isRequired,
    attachments: PropTypes.array,
    getFile: PropTypes.func,
    id: PropTypes.number
};

export default injectIntl(withStyles(Styles)(AccompanimentAction));