import axios from 'axios';
import * as userSessionActions from '../redux/actions/user-session';
import { useHistory } from "react-router-dom";
import { PapiroConsole } from '../utils/papiroConsole';

//PapiroConsole.log("dev env")

let saasError = false
try {
  const urlSaaS = process.env.REACT_APP_API+"/hirerconfig/geturl/"+window.location.host+"/1";
  console.log(urlSaaS)
  var xhttp = new XMLHttpRequest();
  xhttp.open("GET", urlSaaS, false);
  xhttp.send();
} catch(e) {
  if(e.name == "NetworkError") {
    PapiroConsole.log("entrou NetworkError")
    if(e.message.indexOf("Failed to execute 'send' on 'XMLHttpRequest'") !== -1) {
      saasError = true;
      PapiroConsole.log("entrou Failed 'send' 'XMLHttpRequest'")
    }else if(e.message.indexOf("A network error occurred.") !== -1) {
      saasError = true;
      PapiroConsole.log("entrou em A network error occurred.")
    }else {
      PapiroConsole.log("Outro erro")
      PapiroConsole.log(e.name)
      PapiroConsole.log(e.message)
    }
  }
}

let Api = {}

let baseURL = null
let baseURLBack = null
async function refreshToken(token, cb) {
    const refreshingCall = await Api.post('/users/refresh-token', { token }).then(({ data }) => {
		localStorage.removeItem('accesstoken');
		if(data.success){
			localStorage.setItem('accesstoken', data.data.accesstoken);
		}
        return Promise.resolve(data);
    }).then(cb);
    return refreshingCall;
}

let acceptLanguage = 'pt-BR'
let language = window.location.pathname.split('/')[1]

if (language === 'en')
  acceptLanguage = 'en-US'
else if (language === 'es')
  acceptLanguage = 'es-ES'

if(!saasError) {

  //PapiroConsole.log(xhttp);

  var baseUrlResponse = JSON.parse(xhttp.responseText);
  if(baseUrlResponse && baseUrlResponse.data && baseUrlResponse.data.urlapicube) {
    baseURL = "https://"+baseUrlResponse.data.urlapicube+"/api"; 
    baseURLBack = "https://"+baseUrlResponse.data.urlapicube+"/"; 
    const urlfront = window.location.host;
    let token = localStorage.getItem('accesstoken');

    Api = axios.create({
      baseURL: "https://"+baseUrlResponse.data.urlapicube+"/api",
    });
	  /*Api.interceptors.response.use(response => response, error =>  {
		const status = error.response ? error.response.status : null
    
		if (status === 401) {
      if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0 && error.response.data.errors[0] == "Acesso não permitido.") {
        return Promise.reject(error);
      } else {
			  return  refreshToken(token, _ => {
          let token = localStorage.getItem('accesstoken');

					if(token){
						error.config.headers['Authorization'] = 'Bearer ' + token;
						error.config.baseURL = undefined;
						return Api.request(error.config);
					}
					else{
						return Promise.reject(error);
					}
				});
			}
		}
	
		return Promise.reject(error);
	});*/


    Api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    Api.defaults.headers.post['Accept'] = 'application/json';
    Api.defaults.headers.post['Content-Type'] = 'application/json';
    Api.defaults.headers.common['Accept-Language'] = acceptLanguage


    if (token == null) {
    }

    Api.setHeaderAcceptLanguage = function (lang) {
      Api.defaults.headers.common['Accept-Language'] = lang
    }

    Api.applicationName = function () {
      return "Atendimento Inteligente ao Cliente";
    }

    Api.apiurl = function () {
      return baseURL;
    }
    Api.apiurlbase = function () {
      return baseURLBack;
    }

    Api.fronturl = function () {
      return urlfront;
    }

    Api.getProductsAvaliable = async function  (hirerid) {
      var ret = [];
      var Hirer = {
        Id : hirerid
      };
      var headers = {
        'Content-Type': 'application/json',
      };
    
    Api.resetToken = function () {
      token = ''
      Api.defaults.headers.common['Authorization'] = 'Bearer invalidtoken' + token;
    }

      await this.post("/users/avaliable/products", Hirer, {
        headers: headers
      })
        .then(async result => {
          if (result.data.success) {
            //alert(JSON.stringify(result));
            if(result && result.data && result.data.success){
              if(result.data.data){
                ret = result.data.data;
              }
            }        
          
          }
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
          }
          else{
          }
        });

      return ret;
    }
    Api.VertexIsAvaliable = async function (){
      var result = false;
      var data = await this.getProductsAvaliable();
      if(data.constructor === Array && data.length > 0){
        data.forEach(element => {
          if(element.id == 3){
            result=true;
          }
        });
      }
      return result;
    }

    Api.logoff = function () {
      userSessionActions.removeUserSession();
      Api.get("/users/removeClaims")
        .then(result => {
			if(Api && Api.resetToken){Api.resetToken();}
          document.location.href = '/?sessão expirada';
        })
        .catch(err => {
			if(Api && Api.resetToken){Api.resetToken();}
          document.location.href = '/?sessão expirada';
      });
      
    }

    Api.kickoff = function (result){
	  PapiroConsole.log("=== Api.kickoff ===");
	  console.error("=== Api.kickoff ===");
	  console.error(result);
      PapiroConsole.log(result);
      if((result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0])){
    
        if((result.response.data.errors[0].indexOf("invalid_token") > -1)){
      
          userSessionActions.removeUserSession();
          document.location.href='/?sessão expirada';
        }

        if((result.response.data.errors[0].indexOf("ContractInvalid") > -1)){
      
          document.location.href='/pt/my-plan?message';
        }
      } 
      if((result && result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0]) ){
    
        if( (result.data.response.data.errors[0].indexOf("invalid_token") > -1)){
        
          userSessionActions.removeUserSession();
          document.location.href='/?sessão expirada';
        }

        if( (result.data.response.data.errors[0].indexOf("ContractInvalid") > -1)){
        
          document.location.href='/pt/my-plan?message';
        }
      }
      
      if(result && result.errors && result.errors[0] ){
          if(result.errors[0] === "Error: invalid_token")
          {
            userSessionActions.removeUserSession();
            document.location.href='/?sessão expirada';
          }

          if(result.errors[0] === "Error: ContractInvalid")
          {
            document.location.href='/pt/my-plan?message';
          }
      }
      
    
      if(result && result.data && result.data.errors && result.data.errors[0])
      {
        if((result.data.errors[0] === "Error: invalid_token")){
          userSessionActions.removeUserSession();
          document.location.href='/?sessão expirada';
        }
        
        if((result.data.errors[0] === "Error: ContractInvalid")){
          document.location.href='/pt/my-plan?message';
        }
      }
    }

    Api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }else {
    Api = null
    baseURL = null
    baseURLBack = null
  }
} else {
  Api = null
  baseURL = null
  baseURLBack = null
}

export { baseURL, baseURLBack }
export default Api;
