import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever, ContactPhoneOutlined } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import CustomizedSnackbars from '../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../../components/select/simple';
import { browserHistory } from '../../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import * as Service from '../../../services/address.service';
import SimpleHelp from '../../../components/help';
import SimpleCheckBox from '../../../components/checkbox/check';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Paper from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import {  Dialog } from '@mui/material';

class AddressRegistration extends Component {

  constructor(props) {
    super(props);
    
    const { userSession, organizationid } = this.props;
    this.globalAdm = false;
    this.arrayLenght = 0;
    this.hasAddress = null
    this.address = null
    this.editAddressPattern = false

    this.isClient = true;
    if(userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      id : 0,
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      OrganizationId:  organizationid,
      template : true,

     OrganizationText: '',
     UserText: '',
     name : '', 
     street : '', 
     zipcode : '', 
     number : '', 
     complement : '', 
     neighborhood : '', 
     city : '', 
     state : '', 
     country : '', 
     longitude : '', 
     latitude : '',
     keepaddress: false,
     iscustomaddress : false, 
     };         
    this.state = {
      item: this.initialState,
      modalOpen : false,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      UserList: [],

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleChangeRadio =  this.handleChangeRadio.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getCep = this.getCep.bind(this);
  }


  componentDidMount() {
        this.getOrganizations();
        this.getDados();
        this.getUsers();
     };
     async getOrganizations(){
          this.setState({ loading: true });
          var result = await Service.getOrganizations();
            if (result.success) {
              this.setState({ loading: false, OrganizationList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };     async getUsers(){
          this.setState({ loading: true });
          var result = await Service.getUsers();
            if (result.success) {
              this.setState({ loading: false, UserList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };

  handleOpen() {
    
    this.setState({ modalOpen: true });
  };

  handleClose  (){
    this.setState({modalOpen:false})
  };

  handleChangeRadio(e){

    var value = null;

    if(e.target.value === 'true')
    {
      value =true
    }
    else
    {
      value =false
    }
    
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        ['template']: value,
        
      }
    })); 


  }

  async handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if(name == 'zipcode'){
      
      if(e.target.value === '' || /^\d+$/.test(e.target.value)) { 
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [name]: value
          }
        }));
        if(value.length == 8){
          this.setState({ loading: true });
          var data = await this.getCep(value);
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['street']: data.logradouro,
              ['complement']: data.complemento,
              ['neighborhood']: data.bairro,
              ['city']: data.localidade,
              ['state']: data.uf,
              ['country']: 'BRASIL',
            }
          }));
          this.setState({ loading: false });
        }
      } else { 
          return false; 
      } 
    }else{
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));
    }
    
  };

  handleSubmit = async e => {
   

    if(e!== undefined)
    {
      e.preventDefault();
    }
    

    if( this.state.item.template === true && this.state.modalOpen !== true && this.editAddressPattern === false && this.hasAddress === true ){
      this.handleOpen()
  }
    else
    {
      this.setState({ loading: true });

      let data = new FormData();
  
  
        if (this.isClient) {
          this.setState(prevState => ({
            item: {
              ...prevState.item,
            }
          }));
        } else {
          this.setState(prevState => ({
            item: {
              ...prevState.item,
            }
          }));
        }
  
        
  
  
        for (let key in this.state.item) {
          if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
            data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
          } else {
            data.append(key, this.state.item[key]);
          }
        }
  
        data.append('defaultlocation', this.state.item['template']);
  
        this.state.files.forEach(file => data.append('filesUpload', file));
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
        if(this.arrayLenght > 0)
        {
          Api.put('/addresses', data, config)
          .then(result => {
            const intl = this.props.intl;
            if (result.data.success) {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'success',
                  notificationMessage: intl.formatMessage({id:"add.success"}),
                  item: this.initialState,
                  files: []
                });
                
                this.handleClose()
                this.props.next();
                
  
            }
            else{
  
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
  
              });
              Api.kickoff(result);
  
            }
          })
          .catch(err => {
            const intl = this.props.intl;
            if(err.response && err.response.data && err.response.data.errors){
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
            }
            else{
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                              notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
  
              });
            }
            Api.kickoff(err);
  
          });
        }
        else
        {
          Api.post('/addresses', data, config)
  
          .then(result => {
            if (result.data.success) {
              const intl = this.props.intl;
  
              if(!this.state.item.keepaddress){
                this.setState({
                  item: this.initialState,
                  files: []
                })
              }

              this.handleClose()
  
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
              });
          //this.getOrganizations();
          //this.getUsers();
          //this.getDados()
          this.props.handleComplete();
          this.props.handleNextBarProgress()
  
  
            }
            else{
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
  
              });
              Api.kickoff(result);
  
            }          
          })
          .catch(err => {
            if(err.response && err.response.data && err.response.data.errors){
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
            }
            else{
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({id:"process.error"})
              });
            }
          });
  
        }
    }

    
        
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
}

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async choiceDefaultLocation(value){


    await this.setStateAsync(prevState => ({
      loading: true,
      item: {
        ...prevState.item,
        ['template'] : value,
      }
      
    }))

    this.handleSubmit()
      
    /*this.setState(prevState => ({
      loading: true,
      item: {
        ...prevState.item,
        ['template'] : value,
      }
      
    })); */

      

      
      
          
    
          
        
}

  async getDados(){
    this.setState({ loading: true });
   
    const { organizationid } = this.props;
    var result = await Service.getOrganizations(organizationid);
    this.arrayLenght = result.data.length;
    if (result.success) {
      if(result.data.length === 0)
      {
          this.hasAddress = false
      }
      else
      {
          this.hasAddress = true
          result.data.forEach(element => {
          if(element.defaultlocation=== true)
          {
            if(result.data[0].id === element.id)
            {
                this.editAddressPattern = true
           }
            this.address = element.name

          }
        });
        if(this.editAddressPattern === true)
        {
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['template']: true,
            
            }
          })); 
        }
        else
        {
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['template']: false,
            
            }
          })); 
        }
      }
      this.arrayLenght = result.data.length;
      if(result.data.length > 0){
        let resultData = result.data[0];
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['id']: resultData.id,
            ['name'] : resultData.name, 
            ['street'] : resultData.street, 
            ['zipcode'] : resultData.zipcode, 
            ['number'] : resultData.number, 
            ['complement'] : resultData.complement, 
            ['neighborhood'] : resultData.neighborhood, 
            ['city'] : resultData.city, 
            ['state'] : resultData.state, 
            ['country'] : resultData.country, 
            ['longitude'] : resultData.longitude, 
            ['latitude'] : resultData.latitude, 
          }
        }));
      }              
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
  };

  async getCep(cepNumber) {

    return new Promise(function(resolve, reject) {
      
      fetch("https://viacep.com.br/ws/".concat(cepNumber, "/json/")).then(function (response) {
        return response.json();
      }).then(function (data) {
        resolve(data);
      })["catch"](function (err) {
        reject(err);
      })["finally"](function (data) {
        return data;
      });

    });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));        
    if(stateName === 'OrganizationId'){
      
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OrganizationText']: text,
            }
          }));
    }
   
        
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    
     return (
      <div className={classes.root}>
        
          <div className={classes.drawerHeader} /> 
          <div>
            
           {/* <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style = {{ display: 'flex',
                     alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px ridge #000',
                  
                    }}
              open={this.state.modalOpen}
              onClose={this.handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.modalOpen}>
                <div style={{ backgroundColor: '#F8F9F9',
                          border: '1px ridge #000',
                          padding: '15px',
                          maxWidth : '500px'
                                }}>

                <div>
                    <button style={{backgroundColor: 'transparent',
                                    border: 'none',
                                    outline:'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    color: 'gray'}}  
                                    onClick={this.handleClose} >
                        <HighlightOffIcon className="closeModals" />
                    </button>
                  </div>
                  <h4 id="transition-modal-title">{intl.formatMessage({id:"wizard.pages.address.location.exists.first.half"})} {this.address} {intl.formatMessage({id:"wizard.pages.address.location.exists.second.half"})} </h4>
                 
                 <div style={{textAlign: 'center', marginTop:'6px' }}>
                    <Button  variant='contained' color='primary' onClick={()=>{

                      this.choiceDefaultLocation(true)
                      
                        


                      }} style={{ marginRight: '4px' }}>{intl.formatMessage({id:"yes"})}</Button>
                      <Button  variant='contained' color='primary' onClick={()=>{
                        
                        this.choiceDefaultLocation(false)



                      }}>Não</Button>
                 </div>
                
                 
                </div>
              </Fade>
                    </Modal>*/}

              <Dialog open={this.state.modalOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        {intl.formatMessage({id:"default.location"})}
                    </DialogTitle>

                    <DialogContent>
                    <DialogContentText>
                        {intl.formatMessage({id:"wizard.pages.address.location.exists.first.half"})} {this.address} {intl.formatMessage({id:"wizard.pages.address.location.exists.second.half"})}
                    </DialogContentText>
                    
                    </DialogContent>
                   
                    <div style={{textAlign: 'center', marginTop:'6px', marginBottom: '10px' }}>
                        <Button  variant='contained' color='primary' onClick={()=>{

                          this.choiceDefaultLocation(true)
                          
                            
                          

                        }} style={{ marginRight: '4px' }}>{intl.formatMessage({id:"yes"})}</Button>
                        <Button  variant='contained' color='primary' onClick={()=>{
                            
                            this.choiceDefaultLocation(false)
                
                      

                        }}>{intl.formatMessage({id:"no"})}</Button>
                    </div>
                   
              </Dialog>
          </div>

          <Container component='main' maxWidth='md'>
            
            <div className={classes.paper} style={{ marginTop: 0 }}>
             
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="add.item" />}
              
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>


                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                    </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField inputProps={{ maxLength: 8 }} fullWidth label={intl.formatMessage({id:"cep"})} autoComplete='fname' variant='outlined' name='zipcode' onChange={this.handleInputChange} value={item.zipcode} />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField fullWidth label={intl.formatMessage({id:"street"})} inputProps={{ maxLength: 255 }}  autoComplete='fname' variant='outlined' name='street' onChange={this.handleInputChange} value={item.street} />
                      </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"number"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='number' onChange={this.handleInputChange} value={item.number} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"complement"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='complement' onChange={this.handleInputChange} value={item.complement} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"neighborhood"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='neighborhood' onChange={this.handleInputChange} value={item.neighborhood} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"city"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='city' onChange={this.handleInputChange} value={item.city} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"state"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='state' onChange={this.handleInputChange} value={item.state} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"country"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='country' onChange={this.handleInputChange} value={item.country}  />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: 'none'}}>
                      <TextField fullWidth label={intl.formatMessage({id:"longitude"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='longitude' onChange={this.handleInputChange} value={item.longitude}  />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: 'none'}}>
                      <TextField fullWidth label={intl.formatMessage({id:"latitude"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='latitude' onChange={this.handleInputChange} value={item.latitude}  />
                    </Grid>
                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"keep.called"})} name='keepaddress' 
                      stateName='keepaddress' changeSelect={this.changeValues} />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={intl.formatMessage({id:"wizard.pages.address.functionality.keep.address.data"})} />
                    </Grid>
                </Grid>

                {this.hasAddress === true &&
              <Grid  item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography component='h1' variant='h5'>
                        {intl.formatMessage({id:"enable.as.default.location"})}
                      </Typography>
                  </Grid> 
                  <Grid item xs={6} sm={4} md={3} lg={2}>
                    <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"yes"})}</span>
                      <Radio
                      label = {this.props.intl.formatMessage({id:"yes"})}
                      checked={this.state.item.template === true}
                      onChange={this.handleChangeRadio}
                      value= {true}
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': true }}
                      size="small"
                    /> 
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2}>
                    <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"no"})}</span>
                    <Radio
                      label = {this.props.intl.formatMessage({id:"no"})}
                      checked={this.state.item.template === false}
                      onChange={this.handleChangeRadio}
                      value={false}
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': false }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Grid>}

              <Grid container spacing={0}  style={{marginBottom: '60px', display: 'flex', marginTop:'20px'}}>
                
                <Grid item xs={12} sm={2} >
                      {this.props.backButton}


                  </Grid>

                  <Grid item xs={12} sm={2} >
                  {this.props.nextButton} 


                  </Grid>
                  <Grid item xs={12} sm={6} style={{marginLeft:'auto'}}>
                        <Button 
                          
              
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                         
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                  </Grid>
              
            
             </Grid>

                  
                          
  
              
               
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        
                    
      </div>
    );
  }
}
AddressRegistration.propTypes = {
                    classes: PropTypes.object.isRequired,
                    organizationid: PropTypes.string,
                    back: PropTypes.func,
                    next: PropTypes.func
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                   
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AddressRegistration)));