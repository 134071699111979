import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { ExitToApp, Person, CompareArrows, AccountBox } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import * as userSessionActions from '../../redux/actions/user-session';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import Tooltip from '@mui/material/Tooltip';
import Api from '../../services/api';
import BookIcon from '@mui/icons-material/Book';
import { Overlay } from "../../styles/global";
import { CircularProgress } from "@mui/material";


function UserMenu(props) {

  let history = useHistory();
  //console.log(props);
  const intl = props.intl;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenWiki = () => {
    window.open(`https://wiki.mexx.com.br/en/1-In%C3%ADcio/home`, '_blank');
  };

  const logOut = () => {
    setLoading(true)
    handleClose();
    userSessionActions.removeUserSession();

    let language = window.location.pathname.split('/')[1]
    if (language != "pt" && language != "es" && language != "en")
      language = "pt"

    Api.get("/users/removeClaims")
      .then(result => {
        if(Api && Api.resetToken){Api.resetToken();}
        history.push(`/${language}/`)
      })
      .catch(err => {
        if(Api && Api.resetToken){Api.resetToken();}
        history.push(`/${language}/`)
    });
  };

  //{ resetSession },

  return (
  
    <div>

      {loading && (
        <Overlay>
          <CircularProgress color="secondary" />
        </Overlay>
      )}

    <Tooltip title={intl.formatMessage({id:"user.options"})} aria-label="add">

      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <Person style={{ color: '#FFF' }} />
        </Button>

    </Tooltip>
      
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} {...{ to: `/${props.match.params.lang}/user-edit-client` }}>
          <AccountBox /> 
          <span style={{ marginLeft: '10px' }}><FormattedMessage id="my.data"/></span>
        </MenuItem>

        <MenuItem onClick={handleClose} component={Link} {...{ to: `/${props.match.params.lang}/password-reset-auth` }}>
          <CompareArrows /> 
          <span style={{ marginLeft: '10px' }}><FormattedMessage id="change.password"/></span>
        </MenuItem>

        <MenuItem onClick={handleOpenWiki} >
          <BookIcon /> 
          <span style={{ marginLeft: '10px' }}>{props.intl.formatMessage({id:"user.menu.mexx.wiki"})}</span>
        </MenuItem>

        <MenuItem onClick={logOut}>
          <ExitToApp /> 
          <span style={{ marginLeft: '10px' }}>{props.intl.formatMessage({id:"user.menu.logout"})}</span>
        </MenuItem>

      </Menu>
    </div>
  );
}

const mapStateToProps = state => ({
  userSession: state.userSession,
});

//const mapDispatchToProps = dispatch => ({
//  resetSession: () => dispatch(userSessionActions.removeUserSession())
//});

const mapDispatchToProps = dispatch =>
  bindActionCreators(userSessionActions, dispatch);

//export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UserMenu))))
