import React, { Component } from 'react';
                import clsx from 'clsx';
                import { connect } from 'react-redux';
                import PropTypes from 'prop-types';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import HeaderMenu from '../../components/header-menu';
                import { AbilityContext } from '../../config/ability-context'
                    import Footer from '../../components/footer';
                    import { withRouter } from 'react-router-dom';


class Base extends Component {

                    constructor(props) {
                        super(props);
                        //console.log('base');
                        //console.log(props);

                    }

                

                    componentWillReceiveProps(nextProps){
                    }    

                    async componentDidMount() {
                    }

                     render() {
                        const { classes, headerMenu,children } = this.props;
                        
                        return (
                            <div className={classes.root}>
                                <HeaderMenu />
                                <main
                                    className={clsx(classes.content, {
                                        [classes.contentShift]: headerMenu.open,
                                    })}
                                >
                                    <div className={classes.drawerHeader} />

                                     {children}

                                </main>
                                <Footer />   
                            </div>
                        );
                    }
                }
Base.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });

            export default withRouter(connect(mapStateToProps)(withStyles(Styles)(Base)));

            Base.contextType = AbilityContext;                                    