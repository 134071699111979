import React, { Component } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, Divider, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { Menu, QueuePlayNext, ChevronLeft, Home, Dashboard } from '@mui/icons-material';
import { makeStyles, createStyles, withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Container, Grid, CssBaseline, Button, ButtonGroup } from '@mui/material';
import UserMenu from '../user-menu';
import NotificationMenu from '../notification-menu';
import GroupMenu from '../group-menu';
import GroupMenu2 from '../group-menu/index-2';
import UserMenuMobile from '../user-menu-mobile';
import UserInfo from '../user-info';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as headerMenuActions from '../../redux/actions/header-menu';
import * as userSessionActions from '../../redux/actions/user-session'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

import Api from '../../services/api';
import LogoSmall from '../logo/logo-small';
import LogoSmall2 from '../logo/logo-small-2';
import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClassIcon from '@mui/icons-material/Class';
import CachedIcon from '@mui/icons-material/Cached';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SpeakerGroupIcon from '@mui/icons-material/SpeakerGroup';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import QRCodeReader from "../qrcode-reader/qrcode-reader";
import TransitionsModal from '../modal/modal';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import Tooltip from '@mui/material/Tooltip';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import HelpIcon from '@mui/icons-material/Help';
import MailIcon from '@mui/icons-material/Mail';
import AppMenu from '../appmenu/appmenu'
import * as Service from "../../services/organizationsetting.service";
import { getOrganizations } from '../../services/organization.service'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SimpleAutoComplete from '../select/simple';
import { Overlay } from "../../styles/global";
import { CircularProgress } from "@mui/material";
import CustomizedSnackbars from "../material-snackbars";
import MyMaterialTable from '../../components/table';
import { PapiroConsole } from "../../utils/papiroConsole";
import './header-menu.css'
import ReactFlagsSelect from "react-flags-select";
import CallEndIcon from '@mui/icons-material/CallEnd';
import { calledExists } from '../../services/called.service'
import NumberFormat from "react-number-format"
import styled from "styled-components";


function ListItemLink(props) {
  const { icon, primary, to, state } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={{
      pathname: to,
      state: state
    }} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}


ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  state: PropTypes.string,
};

const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  }),
)
const drawerWidth = 240;
function click(i) {
  //console.log(i);
}

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFF;
  }
  & .MuiOutlinedInput-notchedOutline:hover {
    border-color: #CCC;
  }
  & .MuiInputLabel-root {
    color: #FFF;
  }
  & .MuiOutlinedInput-input {
    color: #FFF;
  }
  & .MuiOutlinedInput-root {
    border-color: #FFF;
  }
  & .MuiOutlinedInput-root .Mui-focused {
    border: 1px solid #FFF;
  }
  & .MuiInputBase-colorPrimary {
    border-color: #FFF;
  }
`;

class HeaderMenu extends Component {

  constructor(props) {
    super(props);

    let langCode = 'BR'
    let language = window.location.pathname.split('/')[1]
    if (language === 'en')
      langCode = 'US'
    else if (language === 'es')
      langCode = 'ES'
    else
      langCode = 'BR'

    this.state = {
      loading: false,
      redirect: false,
      subsopen: [false, false, false, false],
      matches: window.matchMedia("(max-width: 660px)").matches,
      style: { paddingLeft: '30px', color: '#ffffff', fontSize: '20px' },
      styleMobile: { paddingLeft: '15px', color: '#ffffff', fontSize: '15px' },
      modalopen: false,
      modalgroup: false,
      WindowSize: window.innerWidth,
      marginLeft: window.innerWidth > 540 ? ((window.innerWidth - 540) * 0.8) : ((window.innerWidth - 310) * 0.8),
      OrganizationListMenu: [],
      variantDot: '',
      listNotification: [],
      qtdNotification: 0,
      openSteps: false,
      orgId: 0,
      openCheckEmail: false,
      OrganizationList: [],
      organizationCheckEmail: 0,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      displayfinished: false,
      languageCode: langCode,
      searchCalledId: ""
    };
    // console.log(props);
    this.handleResize = this.handleResize.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.QRCode = this.QRCode.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openModalGroup = this.openModalGroup.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalGroup = this.closeModalGroup.bind(this);
    this.getTutorial = this.getTutorial.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
    this.handleSearchCalledId = this.handleSearchCalledId.bind(this)
    this.goToCalled = this.goToCalled.bind(this)
  }

  handleSearchCalledId = async (e) => {
    if (e.target.value != null && typeof e.target.value !== 'undefined') {
      this.setState({ searchCalledId: e.target.value })
    }
  }

  goToCalled = async (newTab = false) => {
    PapiroConsole.log("=== this.props.history ===")
    PapiroConsole.log(this.props.history)
    const intl = this.props.intl;
    this.setState({ loading: true })
    if (this.state.searchCalledId && this.state.searchCalledId.length > 0) {
      let result = await calledExists(this.state.searchCalledId);
      if (result.success) {
        this.setState({ loading: false })
        if (result.data != null && typeof result.data !== 'undefined' && result.data === true) {
          if (!newTab) {
            this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${this.state.searchCalledId}`);
            if (this.props.history && this.props.history.location && this.props.history.location && this.props.history.location.pathname && this.props.history.location.pathname.length > 0) {
              PapiroConsole.log("=== qual path? ===")
              let pathList = this.props.history.location.pathname.split('/')
              PapiroConsole.log(pathList)
              PapiroConsole.log(pathList.length)
              if (pathList && pathList.length >= 2) {
                if (pathList[2] == 'called-accompaniment') {
                  this.setState({ searchCalledId: "" })
                  //await this.handleReload()
                  window.location.reload()
                }
              }
            }
          } else {
            window.open(`${window.location.origin}/${this.props.match.params.lang}/called-accompaniment/${this.state.searchCalledId}`, '_blank', 'noopener,noreferrer')
          }
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "called.does.not.exist" }),
          });
        }
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(result);
      }
    }
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        isNumericString
      />
    );
  }

  selectLanguage = async (code) => {
    await this.setState({ languageCode: code })
    let urlCode = 'pt'
    let headerCode = 'pt-BR'
    if (code === "US") {
      urlCode = 'en'
      headerCode = 'en-US'
    } else if (code === "ES") {
      urlCode = 'es'
      headerCode = 'es-ES'
    }

    Api.setHeaderAcceptLanguage(headerCode)
    this.props.history.push(`/${urlCode}/calleds`);
    window.location.reload()
  }

  QRCode() {
    this.openModal();
  }

  calculate() {
    if (this.state.WindowSize > 540) {
      this.setState({ marginLeft: ((this.state.WindowSize - 540) * 0.8) });
    } else {

      this.setState({ marginLeft: ((this.state.WindowSize - 310) * 0.8) });
    }


  }

  closeNotification = () => {
    this.setState({ openNotification: false });
  }

  changeValuesCheckEmail = async (stateName, value, text = '') => {
    console.log(value)
    console.log("changeValuesCheckEmail")
    await this.setState({ organizationCheckEmail: value });
  }

  getOrganizations = async () => {

    var result = await Service.getOrganizations(0);
    if (result.success) {
      this.setState({ OrganizationList: result.data });
    } else {
      // console.log("erro get organizations")
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  openModal() {
    this.setState({ modalopen: true });

  }
  async closeModal() {
    this.setState({ modalopen: false });

  }

  openModalGroup() {
    this.setState({ modalgroup: true });

  }
  async closeModalGroup() {
    this.setState({ modalgroup: false });

  }


  handleClick = (i) => {

  }
  handleResize(WindowSize, event) {
    this.setState({ WindowSize: window.innerWidth })

    this.calculate();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    const handler = (e) => {
      this.setState({ matches: e.matches })
    };
    window.matchMedia("(max-width: 660px)").addListener(handler);

    this.getTutorial();
    await this.getDisplayFinishedCalls();

  }

  openCheckEmail = async () => {
    this.setState({ openCheckEmail: true, loading: true });
    await this.getOrganizations();
    this.setState({ openCheckEmail: true, loading: false });
  }

  closeCheckEmail = () => {
    this.setState({ openCheckEmail: false });
  }

  async getDisplayFinishedCalls(){
    await Api.get(`configs/finishedcalleds/`)
    .then(async result =>  {
        if (result.data.success) {
            this.setState({displayfinished: result.data.data}) 
          }
        });
  }

  getTutorial() {
    Api.get(`/notification/tutorial`)
      .then(result => {
        if (result.data.success) {
          this.setState({ loading: false, listNotification: result.data.data });
          let count = 0;
          var alert = false;
          this.state.listNotification.map((item, key) => {
            item.notification.map((itemDois, key) => {
              if (itemDois.required == true && itemDois.finish == false) {
                alert = true;
              }
              if (itemDois.finish == false) {
                count++;
              }
            });
          });
          this.setState({ loading: false, qtdNotification: count });
          //console.log(alert);
          if (alert == true) {
            window.onload = function (e) {
              document.getElementById('btnNotification').click();
            }
          }
        }
      });

  }

  componentWillUnmount() {
    window.addEventListener("resize", null);
  }

  handleDrawerOpen = () => {
    this.props.location.state = "";
    if (this.props.headerMenu && this.props.headerMenu.open != null) {
      if (this.props.headerMenu.open)
        this.props.toggle(false)
      else
        this.props.toggle(true)
    } else
      this.props.toggle(true);

  };

  getOrganizationsReload = async () => {
    this.setState({ loading: true });
    var result = await getOrganizations();
    if (result.success) {
      if(result.data && result.data.length > 0) {
        const { userSession } = this.props
        if(userSession && userSession.user) {
          let user = userSession.user
          user.userorganizations = result.data
          this.props.addUserSession(user);
        }
      }
      this.setState({ loading: false });
    } else {
      // console.log("erro get organizations")
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  handleReload = async () => {

    const { router, params, match, location, history, routes } = this.props
    await this.getOrganizationsReload()
    const current = location.pathname;
    this.props.location.state = "reload";
    this.props.reload(true);

  }
  handleDrawerClose = () => {
    this.props.location.state = "";
    this.props.toggle(false);
  };

  checkEmail = async () => {
    const intl = this.props.intl;

    this.setState({ loading: true });
    console.log(this.state)
    console.log("checkEmail")
    await Api.get('/emails/' + this.state.organizationCheckEmail).then(result => {

      if (result.data.success) {
        if (result.data.data > 1) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({id:"called.list.notification.was.created"}) + result.data.data + intl.formatMessage({id:"called.list.notification.called.with.success"}),
            resultList: []
          });
          if (window.location.pathname.split('/')[2] == "calleds") {
            this.props.location.state = "reload";
            this.props.reload(true);
          }

        } else if (result.data.data == 1) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({id:"called.list.notification.was.created.plural"}) + result.data.data + intl.formatMessage({id:"called.list.notification.called.with.success.plural"}),
            resultList: []
          });

          if (window.location.pathname.split('/')[2] == "calleds") {
            this.props.location.state = "reload";
            this.props.reload(true);
          }
        } else {

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({id:"called.list.notification.empty.mailbox"}),
            resultList: []
          });
          Api.kickoff(result);
        }

        this.componentDidMount();
      }
    }).catch(err => {
      // console.log(err);
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(err);

    });;

    this.setState({ loading: false });
  }

  render() {
    const intl = this.props.intl;

    const { classes, headerMenu, userSession, history, location } = this.props;
    const theme = createTheme({
      overrides: {
        MuiIconButton: {
          root: {
            '&:hover': {
              backgroundColor: "$labelcolor"
              /*padding : "3px",
              borderRadius: "10px"*/
            }
          },

        }

      }
    })

    const divStyle = {
      day: {
        backgroundColor: "inherit !important",

        "&:focus": {
          outline: "none"
        },
        ".MuiIconButtonRoot": {

          padding: "0px"

        }
      }
    };
    const appMenuItems = [];

    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    this.isAdm = false;
    this.isClient = true;
    this.isAdmOrg = false;
    this.isAdmGlobal = false;
    this.isAdmGroup = false;
    this.isTec = false;

    userSession.user.userrole.map((item, key) => {


      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0 || item.role.roletype == 1 || item.role.roletype == 6 || item.role.roletype == 4) {
        this.isAdm = true;
        if (item.role.roletype == 0) {
          this.isAdmGlobal = true;
        }
      }
      if (item.role.roletype == 1 && item.role.roletype != 0 && !this.isAdmGlobal) {
        this.isAdmOrg = true;
      }
      if (item.role.roletype == 2) {
        this.isTec = true;
      }
    });


   
      let dashboards = appMenuItems.push({
        name: this.props.intl.formatMessage({ id: "dashboards" }),
        Icon: Dashboard,
      });
      
      appMenuItems[dashboards - 1].items = [];
        
      appMenuItems[dashboards - 1].items.push({
        name: this.props.intl.formatMessage({ id: "menu.dashboards.operation" }),
        link: `/${this.props.match.params.lang}/dashboard`,
        state: "operationdashboard",
      });
      
    if (this.isAdmGlobal || this.isAdm || this.isAdmGroup || this.isAdmOrg) {
      appMenuItems[dashboards - 1].items.push({
        name: this.props.intl.formatMessage({ id: "menu.dashboards.productivity" }),
        link: `/${this.props.match.params.lang}/productivitydashboard`,
        state: "productivitydashboard",
      });

      appMenuItems[dashboards - 1].items.push({
        name: this.props.intl.formatMessage({ id: "menu.dashboards.quality" }),
        link: `/${this.props.match.params.lang}/qualitydashboard`,
        state: "qualitydashboard",
      });
    }

    if (this.context.can('List', 'Called')) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "called.header" }),
          link: `/${this.props.match.params.lang}/calleds`,
          Icon: QueuePlayNext,
          state: "calleds",
        });
    }

    if (this.context.can('List', 'AssignedCalled')) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "called.assing" }),
          link: `/${this.props.match.params.lang}/assignedcalleds`,
          Icon: EventSeatIcon,
          state: "assignedcalleds",
        });
    }

    if (this.context.can('List', 'CalledRejection')) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "called.rejection" }),
          link: `/${this.props.match.params.lang}/called-rejection`,
          Icon: DesktopAccessDisabledIcon,
          state: "calledrejection",
        });
    }
    
    if ((this.isAdmGlobal || this.isAdm || this.isAdmGroup || this.isAdmOrg || this.isTec) && this.state.displayfinished) {
      appMenuItems.push({
        name: this.props.intl.formatMessage({ id: "called.finished" }),
        link: `/${this.props.match.params.lang}/finishedCalleds`,
        Icon: CallEndIcon,
        state: "finishedCalleds",
      });
    }   

    if (this.context.can('List', 'CalledClassification')) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.called.classifications" }),
          link: `/${this.props.match.params.lang}/calledclassifications`,
          Icon: ClassIcon,
          state: "calledclassifications",
        });
    }

    if(this.isClient) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "knowledgebase" }),
          link: `/${this.props.match.params.lang}/knowledgebases-client`,
          Icon: EmojiObjectsIcon,
          state: "knowledgebasesclient",
        });
    }

    if (this.isAdmGlobal) {

      //if (this.context.can('List', 'Nps')) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "nps.search" }),
          link: `/${this.props.match.params.lang}/nps-search`,
          Icon: ThumbsUpDownIcon,
          state: "npssearchs",
        });
      //}

    }


    if (this.isAdm) {

      
      appMenuItems.push({ link: 'divider' });

      if (this.context.can('List', 'KnowledgeBase')) {
        appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "knowledgebase" }),
            link: `/${this.props.match.params.lang}/knowledgebases`,
            Icon: EmojiObjectsIcon,
            state: "knowledgebases",
          });
      }

      if (this.context.can('List', 'Group')) {
        appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.groups" }),
            link: `/${this.props.match.params.lang}/groups`,
            Icon: AccountBalanceWalletIcon,
            state: "groups",
          });
      }

      if (this.context.can('List', 'Organization')) {


        var organizations = appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.organizations" }),
            Icon: AccountBalanceIcon
          });
       
        appMenuItems[organizations - 1].items = [];
      }

      if (this.context.can('List', 'Organization')) {
        appMenuItems[organizations - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.organization" }),
            link: `/${this.props.match.params.lang}/organizations`,
            //Icon: AccountBalanceIcon,
            state: "organizations",
          });

        if (this.context.can('List', 'OrganizationSla')) {
          appMenuItems[organizations - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.organization.sla" }),
              link: `/${this.props.match.params.lang}/organizationslas`,
              //Icon: HomeWork,
              state: "organizationslas",
            });
        }

        if (this.context.can('List', 'OrganizationCategory')) {
          appMenuItems[organizations - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.organization.categories" }),
              link: `/${this.props.match.params.lang}/organizationcategories`,
              //Icon: LocationCity,
              state: "organizationcategories",
            });
        }

        if (this.context.can('List', 'OrganizationDepartament')) {
          appMenuItems[organizations - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.organizationdepartaments" }),
              link: `/${this.props.match.params.lang}/organizationdepartaments`,
              //Icon: LocationCity,
              state: "organizationdepartaments",
            });
        }
        if (this.context.can('List', 'OrganizationPatrimony')) {
          appMenuItems[organizations - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.organizationpatrimonies" }),
              link: `/${this.props.match.params.lang}/organizationpatrimonies`,
              //Icon: LocationCity,
              state: "organizationpatrimonies",
            });
        }

      
        if (this.context.can('List', 'OrganizationMaterial')) {
          appMenuItems[organizations - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.organizationmaterials" }),
              link: `/${this.props.match.params.lang}/organizationmaterials`,
              //Icon: LocationCity,
              state: "organizationmaterials",
            });
        }

        appMenuItems[organizations - 1].items.push(
          {
              name: this.props.intl.formatMessage({ id: "menu.calledlistdatas" }),
              link: `/${this.props.match.params.lang}/calledlistdatas`,
              //Icon: SettingsApplicationsIcon,
              state: "calledlistdatas",
        });

        appMenuItems[organizations - 1].items.push(
          {
              name: this.props.intl.formatMessage({ id: "additional.called.cost.config" }),
              link: `/${this.props.match.params.lang}/additional-service-config`,
              //Icon: SettingsApplicationsIcon,
              state: "additionalservicesconfig",
        });

        appMenuItems[organizations - 1].items.push({
          name: this.props.intl.formatMessage({ id: "called.report.configs" }),
          link: `/${this.props.match.params.lang}/called-report-configs`,
          state: "calledreportconfigs",
        })
      }



      if (this.context.can('List', 'User')) {
        var access = appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.access" }),
            Icon: GroupAddIcon
          });
        appMenuItems[access - 1].items = [];
      }

      if (this.context.can('List', 'User')) {
        appMenuItems[access - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.users-agents" }),
            link: `/${this.props.match.params.lang}/users`,
            //Icon: AccountBoxIcon,
            state: "users",
          });

        appMenuItems[access - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.users-client" }),
            link: `/${this.props.match.params.lang}/users-client`,
            //Icon: AccountBoxIcon,
            state: "users-client",
          });
      }

      if (this.context.can('List', 'Role')) {
        appMenuItems[access - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.roles" }),
            link: `/${this.props.match.params.lang}/roles`,
            //Icon: SupervisedUserCircleIcon,
            state: "roles",
          });
      }

      if (this.context.can('List', 'Permission')) {
        appMenuItems[access - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.permissions" }),
            link: `/${this.props.match.params.lang}/permissions`,
            //Icon: FunctionsIcon,
            state: "permissions",
          });
      }

      if (this.context.can('List', 'TechinicalTeam')) {
        var servicegroup = appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.servicegroup" }),
            Icon: SpeakerGroupIcon
          });
        appMenuItems[servicegroup - 1].items = [];
      }

      if (this.context.can('List', 'TechinicalTeam')) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.techinicalteams" }),
            link: `/${this.props.match.params.lang}/techinicalteams`,
            //Icon: GroupIcon,
            state: "techinicalteams",
          });
      }


      if (this.context.can('List', 'TechinicalTeamUser')) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.techinicalteamusers" }),
            link: `/${this.props.match.params.lang}/techinicalteamusers`,
            //Icon: GroupWorkIcon,
            state: "techinicalteamusers",
          });
      }


      if (this.context.can('List', 'TechinicalTeamOrganization')) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.techinicalteamorganizations" }),
            link: `/${this.props.match.params.lang}/techinicalteamorganizations`,
            //Icon: LocationCityIcon,
            state: "techinicalteamorganizations",
          });
      }

      if (this.context.can('List', 'TechinicalTeamOrganizationCategory')) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.techinicalteamorganizationcategories" }),
            link: `/${this.props.match.params.lang}/techinicalteamorganizationcategories`,
            //Icon: ScatterPlotIcon,
            state: "techinicalteamorganizationcategories",
          });
      }

      var servicegroup = appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "reports" }),
          Icon: AssignmentIcon
        });
      appMenuItems[servicegroup - 1].items = [];

      if (this.context.can('List', 'CalledReport')) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "called.report.header" }),
            link: `/${this.props.match.params.lang}/calledreports`,
            //Icon: QueuePlayNext,
            state: "calledreports",
          });
      }


      appMenuItems[servicegroup - 1].items.push(
        {
          name: this.props.intl.formatMessage({ id: "calledClassification.report.header" }),
          link: `/${this.props.match.params.lang}/calledClassificationReports`,
          //Icon: QueuePlayNext,
          state: "calledClassificationReports",
        });

      if (this.context.can('List', 'MaterialReport')) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "material.report.header" }),
            link: `/${this.props.match.params.lang}/materialreports`,
            //Icon: QueuePlayNext,
            state: "materialReports",
          });
      }





      if (this.context.can('List', 'DashboardReport')) {

       
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "report.dashboard" }),
            link: `/${this.props.match.params.lang}/dashboardreports`,
            //Icon: QueuePlayNext,
            state: "dashboardreports",
          });
      }

      if (this.context.can('List', 'CostReport')) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "cost.report" }),
            link: `/${this.props.match.params.lang}/costreports`,
            //Icon: QueuePlayNext,
            state: "costreports",
          });
      }

      if (this.context.can('List', 'MaterialReport')) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "report.fcr" }),
            link: `/${this.props.match.params.lang}/reportfcr`,
            //Icon: QueuePlayNext,
            state: "reportfcr",
          });
      }


      if (this.context.can('List', 'ChecklistReport')) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "checklist.report" }),
            link: `/${this.props.match.params.lang}/checklistreports`,
            //Icon: QueuePlayNext,
            state: "checklistreports",
          });
      }

      if (this.context.can('List', 'SendMailReport')) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "sendmail.report.header" }),
            link: `/${this.props.match.params.lang}/sendmailreports`,
            //Icon: QueuePlayNext,
            state: "sendmailreports",
          });
      }

      appMenuItems[servicegroup - 1].items.push(
        {
          name: this.props.intl.formatMessage({ id: "sendsms.report.header" }),
          link: `/${this.props.match.params.lang}/sendsmsreports`,
          //Icon: QueuePlayNext,
          state: "sendsmsreports",
        });

      if (this.context.can('List', 'ChatReport')) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "report.chat" }),
            link: `/${this.props.match.params.lang}/chatreports`,
            //Icon: QueuePlayNext,
            state: "chatreports",
          });
      }


      appMenuItems[servicegroup - 1].items.push(
        {
          name: "Erros Reportados",
          link: `/${this.props.match.params.lang}/usererrorreport`,
          //Icon: QueuePlayNext,
          state: "userreporterros",
        });

      if (this.isAdm) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: intl.formatMessage({id:"audit"}),
            link: `/${this.props.match.params.lang}/audit-report`,
            //Icon: QueuePlayNext,
            state: "audit",
          });
      }

      if (this.context.can('List', 'WorkHour')) {
        var configs = appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.configs" }),
            Icon: SettingsApplicationsIcon
          });
        appMenuItems[configs - 1].items = [];

        if (this.context.can('List', 'Category')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.categories" }),
              link: `/${this.props.match.params.lang}/categories`,
              //Icon: CategoryIcon,
              state: "categories",
            });
        }
        if (this.context.can('List', 'Sla')) {

          if (!this.isAdmOrg) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.slas" }),
                link: `/${this.props.match.params.lang}/slas`,
                //Icon: AcUnitIcon,
                state: "slas",
              });
          }
        }
        if (this.context.can('List', 'Segment')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.segments" }),
              link: `/${this.props.match.params.lang}/segments`,
              //Icon: AddToHomeScreenIcon,
              state: "segments",
            });
        }

        if (this.context.can('List', 'Segment')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "reasons" }),
              link: `/${this.props.match.params.lang}/reasons`,
              //Icon: AddToHomeScreenIcon,
              state: "reasons",
            });
        }
        if (this.context.can('List', 'Type')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.called.types" }),
              link: `/${this.props.match.params.lang}/types`,
              //Icon: AdjustIcon,
              state: "types",
            });
        }
        if (this.context.can('List', 'OriginRequest')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.originrequests" }),
              link: `/${this.props.match.params.lang}/originrequests`,
              //Icon: TripOriginIcon,
              state: "originrequests",
            });
        }

        if (this.context.can('List', 'Status')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.statuses" }),
              link: `/${this.props.match.params.lang}/statuses`,
              //Icon: StarIcon,
              state: "statuses",
            });
        }


        if (this.context.can('List', 'Config')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.configs" }),
              link: `/${this.props.match.params.lang}/configs`,
              //Icon: SettingsApplicationsIcon,
              state: "configs",
            });
        }

        if (this.context.can('List', 'CalledFlow')) {
          if (!this.isAdmOrg) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.calledflows" }),
                link: `/${this.props.match.params.lang}/calledflows`,
                //Icon: Timeline,
                state: "calledflows",
              });
          }
        }

        if (this.context.can('List', 'CalledFlowStatus')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.calledflowstatuses" }),
              link: `/${this.props.match.params.lang}/calledflowstatuses`,
              //Icon: SwapCalls,
              state: "calledflowstatuses",
            });
        }



        if (this.context.can('List', 'DistributionStrategy')) {

          if (!this.isAdmOrg) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.distributionstrategies" }),
                link: `/${this.props.match.params.lang}/distributionstrategies`,
                //Icon: LowPriority,
                state: "distributionstrategies",
              });
          }
        }

        if (this.context.can('List', 'WorkHour')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.workhours" }),
              link: `/${this.props.match.params.lang}/workhours`,
              //Icon: AccessTime,
              state: "workhours",
            });
        }

        if (this.context.can('List', 'Holiday')) {

          if (!this.isAdmOrg) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.holiday" }),
                link: `/${this.props.match.params.lang}/holidays`,
                //Icon: AccessTime,
                state: "holiday",
              });
          }
        }



        if (this.context.can('List', 'SlaCountType')) {
          if (!this.isAdmOrg) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.slacounttypes" }),
                link: `/${this.props.match.params.lang}/slacounttypes`,
                //Icon: AvTimer,
                state: "slacounttypes",
              });
          }
        }

        if (this.context.can('List', 'DefaultAccompaniment')) {
          if (!this.isAdmOrg) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "default.actions" }),
                link: `/${this.props.match.params.lang}/default-actions`,
                //Icon: AvTimer,
                state: "defaultactions",
              });
          }
        }

        if (this.context.can('List', 'CalledClosingStatus')) {
          if (!this.isAdmOrg) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "calledclosingstatus" }),
                link: `/${this.props.match.params.lang}/called-closing-statuses`,
                state: "calledclosingstatus",
              });
          }
        }

        if (!this.isAdmOrg) {
          appMenuItems[configs - 1].items.push({
            name: this.props.intl.formatMessage({ id: "priorities" }),
            link: `/${this.props.match.params.lang}/priorities`,
            state: "priorities"
          })
        }

        if (this.context.can('List', 'Material')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.materials" }),
              link: `/${this.props.match.params.lang}/materials`,
              //Icon: AvTimer,
              state: "materials",
            });
        }




        if (this.context.can('List', 'PatrimonyGroup')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.patrimonygroups" }),
              link: `/${this.props.match.params.lang}/patrimonygroups`,
              //Icon: AvTimer,
              state: "patrimonygroup",
            });
        }


        if (this.context.can('List', 'PatrimonySubGroup')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.patrimonysubgroups" }),
              link: `/${this.props.match.params.lang}/patrimonysubgroups`,
              //Icon: AvTimer,
              state: "patrimonysubgroups",
            });
        }


        if (this.context.can('List', 'PatrimonyMaterial')) {
          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.patrimonymaterials" }),
              link: `/${this.props.match.params.lang}/patrimonymaterials`,
              //Icon: AvTimer,
              state: "patrimonymaterials",
            });
        }

        if (this.context.can('List', 'Activity')) {

          appMenuItems[configs - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.activities" }),
              link: `/${this.props.match.params.lang}/activity-list`,
              //Icon: AvTimer,
              state: "activitys",
            });

        }

      }
    }

    if (userSession.user == null) {
      Api.logoff();
    }

    if (this.state.redirect) {
      return <Redirect push component={Link} to={location.pathname} />;
    }
    if (this.state.openSteps) {
      if (this.state.orgId != 0) {
        this.setState({ openSteps: false, orgId: 0 })
        return <Redirect push component={Link} to={{ pathname: "/pt/dashboard", state: { orgId: this.state.orgid } }} />
      }
    }

    var organizationName = "";
    var OrganizationListMenu = [];

    if (this.isAdmGlobal) {
      organizationName = <FormattedMessage id="Administração" />;
    } else if (this.isClient) {

      if (userSession.user.organizationusers.length < 3) {

        for (let key in userSession.user.organizationusers) {
          organizationName += userSession.user.organizationusers[key].organization.name + ", ";
        }

        organizationName = organizationName.substring(0, organizationName.length - 2)

      } else {
        let Obj = {
          groupName: "",
        }
        OrganizationListMenu.push(Obj);
        for (let key in userSession.user.organizationusers) {
          let Obj = {
            groupName: userSession.user.organizationusers[key].organization.name,
          }
          OrganizationListMenu.push(Obj);
        }
      }

    } else if (this.isTec || this.isAdmOrg) {
      let Obj = {
        groupName: "",
      }
      OrganizationListMenu.push(Obj);

      for (let key in userSession.user.organizationusers) {
        let Obj = {
          groupName: userSession.user.organizationusers[key].organization.name,
        }
        OrganizationListMenu.push(Obj);
      }

    }

    if (userSession.user.organization) {
      organizationName = userSession.user.organization.name;
    }

    const MenuGroup = (item) => (
      <GroupMenu group={item} />
    )
    const MenuGroup2 = (item) => (
      <GroupMenu2 group={item} />
    )
    /*
  const handleSteps = (id) => (
    //this.setState({ openSteps: true, orgId: id })
    
  )*/

    const handleSteps = (id) => {
      this.props.history.push({
        pathname: `/${this.props.match.params.lang}/steps`,
        state: { orgId: id }
      })
    };

    const handleTutorial = () => {
      this.getTutorial()
    }

    return (
      <div>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: headerMenu.open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, headerMenu.open && classes.hide)}

            >
              <Menu />
            </IconButton>
            <LogoSmall2 />
            {!this.state.matches && <Typography variant="h6" className={classes.title}>
              <div className={classes.title} style={this.state.style}>


                {OrganizationListMenu.length > 4 &&
                  <Tooltip title={intl.formatMessage({id:"user.options"})} aria-label="add">
                    <IconButton color="inherit" onClick={this.openModalGroup}>
                      <PeopleAltIcon /> &nbsp;
                        {intl.formatMessage({id:"menu.organizations"})}
                    </IconButton>
                  </Tooltip>
                }

                {OrganizationListMenu.length <= 4 && <div>
                  {this.isClient && (organizationName)}
                  {this.isAdmGlobal && (organizationName)}

                  {(!this.isTec || this.isAdmOrg) && !this.isAdmGlobal && OrganizationListMenu.map((item, key) => {
                    if (key != 0 && (OrganizationListMenu.length > 2 || OrganizationListMenu[1].length > 1)) {
                      return MenuGroup2(item);
                    } else if (key != 0) {
                      return item[0];
                    }
                  })}
                </div>}

              </div>
            </Typography>}
            {this.state.matches && <span style={{ marginLeft: '10px' }}><UserMenuMobile organizationName={organizationName} /></span>}

            <StyledTextField
              style={{ marginRight: 10, borderColor: '#fff' }}
              label={intl.formatMessage({id:"enter.the.called.number"})}
              inputProps={{ maxLength: 255 }}
              autoComplete='fname'
              variant='outlined'
              name='searchCalledId'
              onChange={this.handleSearchCalledId}
              value={this.state.searchCalledId}
              InputProps={{
                inputComponent: this.NumberFormatCustom,
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter")
                  this.goToCalled()
              }}  />

            <Tooltip title={intl.formatMessage({id:"menu.reload.page"})} aria-label="add">

              <IconButton color="inherit"
                aria-label="open drawer"
                onClick={this.handleReload}
                edge="start"
              >
                <CachedIcon />
              </IconButton>


            </Tooltip>



            <Can I="Open" a="Called">
              <Tooltip title={intl.formatMessage({id:"menu.called.qr.code"})} aria-label="add">

                <IconButton color="inherit" onClick={this.QRCode}>
                  <DeveloperModeIcon />
                </IconButton>

              </Tooltip>
            </Can>

            {(this.isAdm || this.isAdmGlobal || this.isAdmOrg) && (
              <Tooltip title={intl.formatMessage({id:"menu.check.organization.email"})} aria-label="add">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.openCheckEmail}
                  style={{ padding: "5px" }} >
                  <MailIcon />
                </IconButton>
              </Tooltip>
            )}


            <TransitionsModal isopen={this.state.modalopen} handleOpen={this.openModal} handleClose={this.closeModal}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12}>
                  <QRCodeReader width={300} handleClose={this.closeModal} />
                </Grid>
              </Grid>
            </TransitionsModal>

            {this.state.openCheckEmail && (
              <Dialog open={this.state.openCheckEmail} onClose={this.closeCheckEmail} >
                <DialogTitle id="form-dialog-title">{intl.formatMessage({id:"check.email"})}</DialogTitle>
                <DialogContent style={{ width: "600px" }}>
                  <SimpleAutoComplete
                    label={intl.formatMessage({ id: 'organization' })}
                    options={this.state.OrganizationList}
                    stateName='organizationCheckEmail'
                    name='organizationCheckEmail'
                    changeSelect={this.changeValuesCheckEmail}
                    style={{ width: "600px" }}
                    selected={this.state.organizationCheckEmail}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeCheckEmail} color="primary">
                  {intl.formatMessage({id:"close"})}
                  </Button>
                  <Button onClick={this.checkEmail} color="primary">
                  {intl.formatMessage({id:"confirm"})}
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            <TransitionsModal isopen={this.state.modalgroup} handleOpen={this.openModalGroup} handleClose={this.closeModalGroup}>
              <Grid style={{ maxWidth: '450px', }} container spacing={6}>
                <Grid item xs={12} sm={12}>
                  <Card className="background-title-search">
                    <CardContent>

                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={12} md={12}>
                          <Typography variant="h5">
                            {<FormattedMessage id="organization.par" />}
                          </Typography>
                        </Grid>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginLeft: '15px', fontSize: '15px' }}>
                  {this.isClient && (organizationName)}
                  {this.isAdmGlobal && (organizationName)}
                  {!this.isAdmGlobal && (
                    <MyMaterialTable
                      title=""
                      columns={[
                        { title: intl.formatMessage({ id: "name" }), field: 'groupName' },
                      ]}
                      data={OrganizationListMenu}
                    />
                  )}
                </Grid>
              </Grid>
            </TransitionsModal>
            <NotificationMenu handleSteps={handleSteps} listNotification={this.props.attNotification ? this.props.attNotification.length > 0 ? this.props.attNotification : this.state.listNotification : this.state.listNotification} />

            {!this.state.matches && <UserMenu />}
            <div className='reactFlagsSelectCustomHeader'>
              <ReactFlagsSelect
                style={{ color: 'black' }}
                countries={["BR", "US", "ES"]}
                customLabels={{ BR: "PT-BR", US: "EN-US", ES: "ES" }}
                placeholder={intl.formatMessage({ id: "select.language" })}
                onSelect={this.selectLanguage}
                selected={this.state.languageCode} />
            </div>
            {!this.state.matches && <UserInfo />}
          </Toolbar>
        </AppBar>

        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={headerMenu.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <ThemeProvider theme={theme}>


              <IconButton onClick={this.handleDrawerClose} style={divStyle.day}>
                <LogoSmall /><ChevronLeft />
              </IconButton>

            </ThemeProvider>
          </div>
          <Divider />

          <AppMenu appMenuItems={appMenuItems} />

        </Drawer>
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  attNotification: PropTypes.array
};



const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, headerMenuActions, userSessionActions), dispatch);



export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(HeaderMenu))));
HeaderMenu.contextType = AbilityContext;