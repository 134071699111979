import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'; import SimpleSelect from '../../components/select/simple';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleDatePicker from '../../components/date-pickers/inline';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import * as Service from '../../services/organizationchecklist.service';
import * as CalledListDataService from '../../services/calledlistdata.service'
import * as UserService from "../../services/user.service";
import { getOrganizationCategoryDepartamentsByOrganizationCategory, getDepartamentsByOrganizationCategoryId } from '../../services/organizationcategory.service';
import * as componentActions from '../../redux/actions/calledlistdatas';
import * as CalledService from '../../services/called.service';
import { FormattedMessage, injectIntl } from 'react-intl';
import MyCheckList from "../../components/checklist/checklist";
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

class CalledListDataEdit extends Component {

    constructor(props) {
        super(props);
        const { userSession, calledlistdatas, organizations } = this.props;
        const intl = this.props.intl;

        this.fromProps = false
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
            this.fromProps = true

        this.globalAdm = false;
        this.isClient = true;
        if (userSession.user.userrole == null) {
            Api.logoff();
        }

        if(!calledlistdatas || (calledlistdatas && !calledlistdatas.calledlistdata)) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/calleds`
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0) {
                this.globalAdm = true;
            }
        });

        console.log("=== calledlistdatas ===")
        console.log(calledlistdatas)
        let roles = calledlistdatas.calledlistdata.calledlistdataroles.map(r => {
          if (r && r.role && r.role.id)
            return r.role.id
        })

        let selected = this.getSelected(calledlistdatas.calledlistdata)


        this.initialState = {
            UpdatedUser: userSession.user.id,
            id: calledlistdatas.calledlistdata.id,
            OrganizationId: calledlistdatas.calledlistdata.OrganizationId,
            calledid: true,
            calledorganization: calledlistdatas.calledlistdata.calledorganization,
            calledtitle: calledlistdatas.calledlistdata.calledtitle,
            calledcategory: calledlistdatas.calledlistdata.calledcategory,
            calledtype: calledlistdatas.calledlistdata.calledtype,
            calleddepartament: calledlistdatas.calledlistdata.calleddepartament,
            calledslaservice: calledlistdatas.calledlistdata.calledslaservice,
            calledslasolution: calledlistdatas.calledlistdata.calledslasolution,
            calledstatus: calledlistdatas.calledlistdata.calledstatus,
            calledchangestatus: calledlistdatas.calledlistdata.calledchangestatus,
            calledrequester: calledlistdatas.calledlistdata.calledrequester,
            calledassigner: calledlistdatas.calledlistdata.calledassigner,
            calledaddress: calledlistdatas.calledlistdata.calledaddress,
            calledclassification: calledlistdatas.calledlistdata.calledclassification,
            calledopendate: calledlistdatas.calledlistdata.calledopendate,
            calledduedate: calledlistdatas.calledlistdata.calledduedate,
            calledslaserviceexpired: calledlistdatas.calledlistdata.calledslaserviceexpired,
            calledmenu: calledlistdatas.calledlistdata.calledmenu,
            calledchangeassign: calledlistdatas.calledlistdata.calledchangeassign,
            roles: roles,
            selected: selected,
            saveToAllOrganizationsInGroup: false,
        };

        this.state = {
            RoleList: [],
            options: [
              { value: 'calledorganization', label: this.props.intl.formatMessage({id:"menu.organization"}) },
              { value: 'calledtitle', label: this.props.intl.formatMessage({id:"called.list.data.edit.called.title"}) },
              { value: 'calledcategory', label: intl.formatMessage({id:"category"}) },
              { value: 'calledtype', label: intl.formatMessage({id:"type"}) },
              { value: 'calleddepartament', label: intl.formatMessage({id:"departament"}) },
              { value: 'calledslaservice', label: intl.formatMessage({id:"organization.category.service.sla"}) },
              { value: 'calledslasolution', label: intl.formatMessage({id:"sla.solution"}) },
              { value: 'calledstatus', label: intl.formatMessage({id:"menu.statuses"}) },
              //{ value: 'calledchangestatus', label: 'Selecionar status' },
              { value: 'calledrequester', label: intl.formatMessage({id:"requestuser"}) },
              { value: 'calledassigner', label: intl.formatMessage({id:"assigned"}) },
              { value: 'calledaddress', label: intl.formatMessage({id:"location"}) },
              { value: 'calledclassification', label: intl.formatMessage({id:"classification"}) },
              { value: 'calledopendate', label: intl.formatMessage({id:"open.date"}) },
              { value: 'calledduedate', label: intl.formatMessage({id:"estimated.solution.date"}) },
              { value: 'calledslaserviceexpired', label: intl.formatMessage({id:"expired.attendance.sla"}) },
              //{ value: 'calledmenu', label: 'Menu' },
              //{ value: 'calledchangeassign', label: 'Assumir chamado' },
            ],
            userid: userSession.user.id,
            item: this.initialState,
            organizations: organizations,
            files: [],
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            OrganizationList: [],
            OrganizationCategoriesList: [],
            //DepartamentList: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    getSelected = (data) => {
      let selected = []

      if (data.calledid === true)
        selected.push('calledid')
      if (data.calledorganization === true)
        selected.push('calledorganization')
      if (data.calledtitle === true)
        selected.push('calledtitle')
      if (data.calledcategory === true)
        selected.push('calledcategory')
      if (data.calledtype === true)
        selected.push('calledtype')
      if (data.calleddepartament === true)
        selected.push('calleddepartament')
      if (data.calledslaservice === true)
        selected.push('calledslaservice')
      if (data.calledslasolution === true)
        selected.push('calledslasolution')
      if (data.calledstatus === true)
        selected.push('calledstatus')
      if (data.calledchangestatus === true)
        selected.push('calledchangestatus')
      if (data.calledrequester === true)
        selected.push('calledrequester')
      if (data.calledassigner === true)
        selected.push('calledassigner')
      if (data.calledaddress === true)
        selected.push('calledaddress')
      if (data.calledclassification === true)
        selected.push('calledclassification')
      if (data.calledopendate === true)
        selected.push('calledopendate')
      if (data.calledduedate === true)
        selected.push('calledduedate')
      if (data.calledslaserviceexpired === true)
        selected.push('calledslaserviceexpired')
      if (data.calledmenu === true)
        selected.push('calledmenu')
      if (data.calledchangeassign === true)
        selected.push('calledchangeassign')

      return selected
    }

    async componentDidMount() {
        if (this.state.organizations && this.state.organizations.organization && this.state.organizations.organization.id && this.fromProps) {
            this.getOrganizationsById(this.state.organizations.organization.id);
        }
        else {
            this.getOrganizations();
        }
        this.getRoles();
    };
    async getOrganizations() {
        this.setState({ loading: true });
        var result = await Service.getOrganizations();
        if (result.success) {
            this.setState({ loading: false, OrganizationList: result.data });

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };
    async getOrganizationsById(id) {
        this.setState({ loading: true });
        var result = await Service.getOrganizationsByIdLight(id);
        if (result.success) {
            this.setState({ loading: false, OrganizationList: [result.data] });

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
          item: {
                ...prevState.item,
                [name]: value
            }
        }));
    };

    handleSubmit = async e => {
      const intl = this.props.intl;
        e.preventDefault();

        this.setState({ loading: true });
        const { userSession } = this.props;

        let data = new FormData();


        if (this.isClient) {
            this.setState(prevState => ({
                item: {
                    ...prevState.item,
                }
            }));
        } else {
            this.setState(prevState => ({
              item: {
                    ...prevState.item,
                }
            }));
        }

        for (let key in this.state.item) {
            if(key === 'id') {
                data.append(key, this.state.item[key]);
            }else if(key.toString().toLowerCase().indexOf('organizationid') > -1) {
                data.append(key, this.state.item[key]);
            }else if(key.toString().toLowerCase().indexOf('calledid') > -1) {
              data.append('calledid', true);
            }else if(key.toString().toLowerCase().indexOf('selected') > -1) {
              if (this.state.item.selected && this.state.item.selected.length > 0) {
                this.state.item.selected.map(itemData => {
                  if (itemData === 'calledid') {
                    data.append('calledid', true);
                  } else if (itemData === 'calledorganization') {
                    data.append('calledorganization', true);
                  } else if (itemData === 'calledtitle') {
                    data.append('calledtitle', true);
                  } else if (itemData === 'calledcategory') {
                    data.append('calledcategory', true);
                  } else if (itemData === 'calledtype') {
                    data.append('calledtype', true);
                  } else if (itemData === 'calleddepartament') {
                    data.append('calleddepartament', true);
                  } else if (itemData === 'calledslaservice') {
                    data.append('calledslaservice', true);
                  } else if (itemData === 'calledslasolution') {
                    data.append('calledslasolution', true);
                  } else if (itemData === 'calledstatus') {
                    data.append('calledstatus', true);
                  } else if (itemData === 'calledchangestatus') {
                    data.append('calledchangestatus', true);
                  } else if (itemData === 'calledrequester') {
                    data.append('calledrequester', true);
                  } else if (itemData === 'calledassigner') {
                    data.append('calledassigner', true);
                  } else if (itemData === 'calledaddress') {
                    data.append('calledaddress', true);
                  } else if (itemData === 'calledclassification') {
                    data.append('calledclassification', true);
                  } else if (itemData === 'calledopendate') {
                    data.append('calledopendate', true);
                  } else if (itemData === 'calledduedate') {
                    data.append('calledduedate', true);
                  } else if (itemData === 'calledslaserviceexpired') {
                    data.append('calledslaserviceexpired', true);
                  } else if (itemData === 'calledmenu') {
                    data.append('calledmenu', true);
                  } else if (itemData === 'calledchangeassign') {
                    data.append('calledchangeassign', true);
                  }
                })

                let notSelectedArray = this.state.options.filter(f => !this.state.item.selected.includes(f.value))
                if (notSelectedArray && notSelectedArray.length > 0) {
                  notSelectedArray.map(itemData => {
                    if (itemData.value === 'calledid') {
                      data.append('calledid', true);
                    } else if (itemData.value === 'calledorganization') {
                      data.append('calledorganization', false);
                    } else if (itemData.value === 'calledtitle') {
                      data.append('calledtitle', false);
                    } else if (itemData.value === 'calledcategory') {
                      data.append('calledcategory', false);
                    } else if (itemData.value === 'calledtype') {
                      data.append('calledtype', false);
                    } else if (itemData.value === 'calleddepartament') {
                      data.append('calleddepartament', false);
                    } else if (itemData.value === 'calledslaservice') {
                      data.append('calledslaservice', false);
                    } else if (itemData.value === 'calledslasolution') {
                      data.append('calledslasolution', false);
                    } else if (itemData.value === 'calledstatus') {
                      data.append('calledstatus', false);
                    } else if (itemData.value === 'calledchangestatus') {
                      data.append('calledchangestatus', false);
                    } else if (itemData.value === 'calledrequester') {
                      data.append('calledrequester', false);
                    } else if (itemData.value === 'calledassigner') {
                      data.append('calledassigner', false);
                    } else if (itemData.value === 'calledaddress') {
                      data.append('calledaddress', false);
                    } else if (itemData.value === 'calledclassification') {
                      data.append('calledclassification', false);
                    } else if (itemData.value === 'calledopendate') {
                      data.append('calledopendate', false);
                    } else if (itemData.value === 'calledduedate') {
                      data.append('calledduedate', false);
                    } else if (itemData.value === 'calledslaserviceexpired') {
                      data.append('calledslaserviceexpired', false);
                    } else if (itemData.value === 'calledmenu') {
                      data.append('calledmenu', false);
                    } else if (itemData.value === 'calledchangeassign') {
                      data.append('calledchangeassign', false);
                    }
                  })
                }
              } else {
                let notSelectedArray = this.state.options.filter(f => !this.state.item.selected.includes(f.value))
                if (notSelectedArray && notSelectedArray.length > 0) {
                  notSelectedArray.map(itemData => {
                    if (itemData.value === 'calledid') {
                      data.append('calledid', true);
                    } else if (itemData.value === 'calledorganization') {
                      data.append('calledorganization', false);
                    } else if (itemData.value === 'calledtitle') {
                      data.append('calledtitle', false);
                    } else if (itemData.value === 'calledcategory') {
                      data.append('calledcategory', false);
                    } else if (itemData.value === 'calledtype') {
                      data.append('calledtype', false);
                    } else if (itemData.value === 'calleddepartament') {
                      data.append('calleddepartament', false);
                    } else if (itemData.value === 'calledslaservice') {
                      data.append('calledslaservice', false);
                    } else if (itemData.value === 'calledslasolution') {
                      data.append('calledslasolution', false);
                    } else if (itemData.value === 'calledstatus') {
                      data.append('calledstatus', false);
                    } else if (itemData.value === 'calledchangestatus') {
                      data.append('calledchangestatus', false);
                    } else if (itemData.value === 'calledrequester') {
                      data.append('calledrequester', false);
                    } else if (itemData.value === 'calledassigner') {
                      data.append('calledassigner', false);
                    } else if (itemData.value === 'calledaddress') {
                      data.append('calledaddress', false);
                    } else if (itemData.value === 'calledclassification') {
                      data.append('calledclassification', false);
                    } else if (itemData.value === 'calledopendate') {
                      data.append('calledopendate', false);
                    } else if (itemData.value === 'calledduedate') {
                      data.append('calledduedate', false);
                    } else if (itemData.value === 'calledslaserviceexpired') {
                      data.append('calledslaserviceexpired', false);
                    } else if (itemData.value === 'calledmenu') {
                      data.append('calledmenu', false);
                    } else if (itemData.value === 'calledchangeassign') {
                      data.append('calledchangeassign', false);
                    }
                  })
                }
              }
            }else if (key.toString().toLowerCase().indexOf("roles") > -1) {
              data.append('rolesids', this.state.item[key]);
            }else if(key.toString().toLowerCase().indexOf('savetoallorganizationsingroup') > -1) {
                data.append(key, this.state.item[key]);
            }
        }
        data.append('updateduser', this.state.userid);

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        let response = await CalledListDataService.editItem(data)
        if (response && response.success) {
          const formattedList = response.data;
          formattedList.OrganizationId = formattedList.organization ? formattedList.organization.id : 0;
          formattedList.OrganizationText = formattedList.organization ? formattedList.organization.name : '';

          let roles = ''
          if(formattedList && formattedList.calledlistdataroles && formattedList.calledlistdataroles.length > 0) {
              const roleFilter = formattedList.calledlistdataroles.map(i => {
                  if (i && i.role && i.role.displayname)
                    return i.role.displayname
              })
              if (roleFilter && roleFilter.length > 0)
                  roles = roleFilter.join(' ,')
          }

          formattedList.Roles = roles

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({id:"edit.success"}),
            files: []
          });
          if (this.fromProps)
            this.props.addCurrentOrganization(formattedList, this.state.organizations.organization);
          else
            this.props.addCurrent(formattedList);
          this.componentDidMount();
        } else {
          const intl = this.props.intl
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(response)
        }
    };

    getRoles = async _ => {
      this.setState({ loading: true });
      var result = await UserService.getRoles();
      if (result.success) {
        
        this.setState({ loading: false, RoleList: result.data });
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(result);
      }
    }

    onChange = (selected) => {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          selected: selected
        }
      }))
    }

    getRolesIds = _ => {
      const idList = this.state.RoleList.map((item) => {
        return item.id;
      });
      return idList;
    }
    
    getRolesNames = _ => {
      const idList = this.state.RoleList.map((item) => {
        return item.name;
      });
      return idList;
    }

    closeNotification() {
        this.setState({ openNotification: false });
    }

    changeValues(stateName, value, text = '') {
        this.setState({ loading: true });

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [stateName]: value
            }
        }));
        if (stateName === 'OrganizationId') {
            this.setState(prevState => ({
              item: {
                    ...prevState.item,
                    ['OrganizationText']: text,
                }
            }));
        }

        this.setState({ loading: false });

    };

    removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }

    render() {
        const { classes, headerMenu, calledlistdatas } = this.props;
        const { item } = this.state;
        const intl = this.props.intl;

        if(Api == null) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/unavailable`
          }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={() => {
                                        this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 11 }) : browserHistory.goBack()}
                                    }>{this.props.intl.formatMessage({id:"back"})}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="edit.item" />}
                                <Title />
                            </Typography>
                            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={intl.formatMessage({ id: "organization" })} options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={item.OrganizationId}  />
                                    </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <DualListBox
                                      options={this.state.options}
                                      selected={item.selected}
                                      onChange={this.onChange} />
                                  </Grid>
                                  <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                                    <Typography component="h5" variant="h5">
                                      {<FormattedMessage id="user.profile" />}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <MyCheckList
                                      label={intl.formatMessage({ id: "menu.groups" })}
                                      name="UserRoles"
                                      optionsText={this.getRolesNames()}
                                      options={this.getRolesIds()}
                                      stateName="roles"
                                      changeSelect={this.changeValues}
                                      selected={item.roles}
                                    />
                                  </Grid>
                                  <Grid item xs={11} sm={11}>
                                    <SimpleCheckBox
                                      label={intl.formatMessage({id:"save.to.all.organizations.in.group"})}
                                      name='saveToAllOrganizationsInGroup'
                                      stateName='saveToAllOrganizationsInGroup' 
                                      changeSelect={this.changeValues}
                                      selected={item.saveToAllOrganizationsInGroup} />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                      <Grid item xs={12} sm={4}    >
                                          <Button 
                                          type='submit'
                                          fullWidth
                                          variant='contained'
                                          color='primary'
                                          className={classes.submit}
                                          >
                                          {<FormattedMessage id="save" />}
                                          </Button>
                                      </Grid>
                                    </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}
CalledListDataEdit.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
    calledlistdatas: state.calledlistdatas
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledListDataEdit)));
