import Api from './api';
export const getCalleds = async () => { 
  let data = [];
  await Api.get('/calleds')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getNextStatuses = async () => { 
  let data = [];
  await Api.get('/statuses')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getStatuses = async () => { 
  let data = [];
  await Api.get('/statuses')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getPreviousStatuses = async () => { 
  let data = [];
  await Api.get('/statuses')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAttendanceTimeDashboardData = async (startDate, endDate, organizationId, technicalTeamId, userId) => { 
  let data = [];
  await Api.get(`/calledStatus/dashboard/attendancetime?startDate=${startDate}&endDate=${endDate}&organizationId=${organizationId}&technicalTeamId=${technicalTeamId}&userId=${userId}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getSolutionTimeDashboardData = async (startDate, endDate, organizationId, technicalTeamId, userId) => { 
  let data = [];
  await Api.get(`/calledStatus/dashboard/solutiontime?startDate=${startDate}&endDate=${endDate}&organizationId=${organizationId}&technicalTeamId=${technicalTeamId}&userId=${userId}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};