import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, CircularProgress, Container } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import Rating from '@mui/material/Rating';
import CustomizedSnackbars from "../../components/material-snackbars";
import { parseJSON } from "date-fns";
import CallEndIcon from '@mui/icons-material/CallEnd';
import CallIcon from '@mui/icons-material/Call';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ReactHtmlParser from 'react-html-parser';
import { injectIntl } from "react-intl";

class FeedbackAssigned extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionCalled : null,
      lastaccompainiment : null,
      feedback: {
        title : null,
        technicalName : null,
        userName : null,
        calledId : null,
        description: "",
        acesstokenassigned: "",
       
        
      },
      disabledButtons : true,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      starRating: 0,
      ratingVisible: true,
      disabledResendButton : false
    };


    
    this.ButtonExpired = null
    this.closeNotification = this.closeNotification.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getFeedback = this.getFeedback.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
  }

  

  componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
                      //this.getAllFeedbacks();

    if (this.props.match.params.token) {
      this.getFeedback(this.props.match.params.token);
    }

  }

  getAllFeedbacks()
  {
    
    this.setState({ loading: true });
    Api.get("/feedback")
      .then(result => {
        if (result.data.success) {
     
        
        }
        else{
         
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          });
          document.getElementById('submit').classList.add("Mui-disabled");    
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        //document.getElementById('submit').classList.add("Mui-disabled");
      });
  }

  getFeedback(token) {
    const intl = this.props.intl;
    this.setState({ loading: true });
    Api.get(`/feedback/assigned/${token}`)
      .then(result => {
        if (result.data.success) {
          const value = result.data.data.acesstokenassigned;
        
          let ordenedAccompaniments = result.data.data.called.accompaniments ? result.data.data.called.accompaniments.sort((a, b) => b.id - a.id) : null

          this.setState(prevState => ({
            loading: false,
            disabledButtons : false,
            descriptionCalled :result.data.data.called.description, 
            feedback: {

              ...prevState.feedback,
              description : result.data.data.called.description,
              calledId : result.data.data.calledid,
              title : result.data.data.called.title,
              technicalName : result.data.data.called.assigneduser.name,
              userName : result.data.data.called.requestinguser.name,
              lastaccompainiment : ordenedAccompaniments ? ordenedAccompaniments[0].description : result.data.data.called.accompaniments[result.data.data.called.accompaniments.length-1].description,
              'acesstokenassigned': value
            }
            }));
        }
        else{
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            disabledButtons : true,
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          });
          document.getElementById('submit').classList.add("Mui-disabled");    
        }
      })
      .catch(err => {
        this.setState({
          disabledButtons : true,
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          
        });

        if(err.response && err.response.data && err.response.data.errors &&  err.response.data.errors[0])
        {
          
            if(err.response.data.errors[0] == intl.formatMessage({id:"nps.email.rating.link.expired.feedback"})){
            
              this.addButtonExpiredToken()
            }
        }
       // document.getElementById('submit').classList.add("Mui-disabled");
      });
  };

  componentDidUpdate(_, prevState) { }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      feedback: {
        ...prevState.feedback,
        [name]: value
      }
    }));
  }

  addButtonExpiredToken(){
   
    const intl = this.props.intl;
    const { classes } = this.props;
    this.ButtonExpired =   
          <Button
        
          variant="contained"
          color="primary"
          className={classes.submit}
          
          onClick={this.resendEmail}
          style={{width: "300px",marginRight:'16px'}}
   
        >
          {intl.formatMessage({id:"resend.feedback.link"})}
        </Button>
  
    this.setState({ loading: false});
    
   
  
  }

  async resendEmail(){
    const intl = this.props.intl;
    this.setState({ loading: true});
  
    console.log("entrei aqui")
  
  Api.put(`feedback/resendFeedbackAssigned/${this.props.match.params.token}`)
    .then(result => {
      if (result.data.success) {
  
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "success",
          notificationMessage: intl.formatMessage({id:"nps.email.rating.feedback.link.sent"}),
          disabledResendButton : true
        });
  
        
      }
    })
    .catch(err => {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        disabledResendButton : true,
        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
    })
  
    
    
  
  }

  handleSubmit = async e => {
    const intl = this.props.intl;
    const { history } = this.props;
    
    e.preventDefault();
    
    this.setState({loading: true});

    const { description, acesstokenassigned } = this.state.feedback;

    const { starRating } = this.state;
    var data = {ObservationRequest: description, NoteRequest: starRating, AccessTokenAssigned : acesstokenassigned};

    
    
    Api.put("/calledclassifications/assigned", data)
    .then(result => {
     
      if (result.data.success) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "success",
          notificationMessage: intl.formatMessage({id:"feedback.assigned.feedback.received"})
        });

        setTimeout(function () { history.push("/"); }, 3000);
      }else{
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"feedback.assigned.error"})
        });
      }
    })
    .catch(err => {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });

      if(err.response && err.response.data && err.response.data.errors &&  err.response.data.errors[0])
        {
          
            if(err.response.data.errors[0] == intl.formatMessage({id:"nps.email.rating.link.expired.feedback"})){
            
              this.addButtonExpiredToken()
            }
        }
    })

  };

  closeNotification() {
    this.setState({ openNotification: false });
  }
  render() {
    const intl = this.props.intl;
    const styles = {

       regularFont : {
        color: "black",
        fontWeight: "700",
        margin: "0px",
        padding: "0px",
      },
  
      grayFont : {
        margin: "0px",
        padding: "0px",
        display: 'flex',
        color: "#909497 ",
        fontSize: "15px",
        fontWeight: "500",
      },
  
       alignMiddle : {
      
        verticalAlign : "middle"
      },

    }

   


    const { classes } = this.props;
    const { feedbackAssigned } = this.state;
  
    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <Container component="main" maxWidth="md">
        <div>
          <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
          <div>
            <h2 style={styles.regularFont} >Avaliação de Chamado</h2>
          </div>

          <div style={{marginTop: "4px"}}>
            <h4 style={styles.grayFont}> <CallEndIcon style={styles.alignMiddle}/> Chamado {this.state.feedback.calledId} • {this.state.feedback.title}</h4>
          </div>
          <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
         
        </div>
        <div>
          <h4 style={styles.grayFont}> <AccountCircleIcon style={styles.alignMiddle}/> <span  style={styles.regularFont} > {intl.formatMessage({id:"modalRating.called.user"})} :</span> <span>{this.state.feedback.userName}</span> </h4>
        </div>

        <Divider style={{marginTop: "20px", marginBottom: "20px"}} />

        <div>
          <h4 style={styles.grayFont}> <DescriptionIcon style={styles.alignMiddle}/> <span style={styles.regularFont}>{intl.formatMessage({id:"description"})} :</span> <span>{ReactHtmlParser(this.state.descriptionCalled)}</span> </h4>
        </div>

        <Divider style={{marginTop: "20px", marginBottom: "20px"}} />

        <div>
          <h4 style={styles.grayFont}> <PlaylistAddCheckIcon style={styles.alignMiddle}/> <span style={styles.regularFont}>Ultimo Acompanhamento :</span> <span>{ReactHtmlParser(this.state.feedback.lastaccompainiment)}</span> </h4>
        </div>

        <Divider style={{marginTop: "20px", marginBottom: "20px"}} />

        <div>
          <div>
            <h3 style={styles.regularFont} > O que você achou do Atendimento? </h3>
          </div>
          <div style={{marginTop: "6px"}}>
            <h4 style={styles.grayFont}> Escolha de 1 a 5 estrelas para avaliar</h4>
          </div>
        </div>


        
         {/* <div className="title">
            <h3 className="boldClass"><CallIcon/> Chamado: {this.state.feedback.calledId}</h3>
          </div>
         
           <div className="title">
            <h3 className="boldClass">Título do Chamado: {this.state.feedback.title}</h3>
          </div>
          <div className="technician">
            <h4 className="boldClass"><span className="spantitle">Usuário do Chamado</span><p style={{textAlign: "center"}}>{this.state.feedback.technicalName}</p></h4>
          </div>
          
          <div>
            <h4 className="boldClass"><span className="spantitle">Descrição</span> <p style={{textAlign: "center"}}>{this.state.descriptionCalled}</p></h4>
          </div>

        
         { <Typography component="h1" variant="h5">
            Classificação
    </Typography>}
          <div>
            <h4 className="boldClass"><span className="spantitle">Classificação</span></h4>
          </div>
    */}
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} container alignItems="flex-start" justify="flex-start" direction="row" style={{display: this.state.ratingVisible ? '' : 'none' }}>
                <Rating 
                style={{fontSize: "45px"}}
                  name="rating"
                  required
                  disabled={this.state.disabledButtons}
                  value={this.state.starRating}
                  onChange={(event, newValue) => {
                    this.setState({
                      starRating: newValue
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  id="outlined-multiline-static"
                  label={intl.formatMessage({id:"comment"})}
                  name="description"
                  multiline
                  rows="8"
                  fullWidth
                  variant="outlined"
                  disabled={this.state.disabledButtons}
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
            <div style={{textAlign: "right"}}>
        
              {!this.state.disabledResendButton && (

              <>
                {this.ButtonExpired}
              </>

              )}  
              <Button
               //justifyContent="center"
                type="submit"
                //style={{textAlign: "center"}}
                
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={this.state.disabledButtons}
                id="submit"
                style={{width: "200px"}}
                
              >
                Avaliar Chamado
              </Button>
            </div>
            
          </form>
        

        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
    );
  }
}

FeedbackAssigned.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectIntl(withStyles(Styles)(FeedbackAssigned));
