import React from "react";
import PropTypes from "prop-types";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Link from "@mui/material/Link";
import moment from "moment";
import Grid from '@mui/material/Grid';
import Api from '../../services/api';
import ReactHtmlParser from 'react-html-parser';

export default function Action(props) {
    const { 
        classes, 
        action, 
        showPrivacy, 
        attachments, 
        getFile, 
        id, 
    } = props;

    console.log(attachments)
    console.log(props)
    console.log("props aqui")

    const handleFile = (fileName, id) => {
        getFile(fileName, id);
    }

    const renderAttachment = (type, path, description,id) => {
        
        if (type.includes("image")) {
            
            //return <img key={path} style={{ maxWidth: "100%", marginBottom: "5px" }} src={`${Api.apiurlbase()}${path}`}/>
            return (
                <li key={path}>
                    <AttachFileIcon /> <Link onClick={() => handleFile(path, id)}>{description}</Link>
                </li>
            )

		} else {
            
            return (
                <li key={path}>
                    <AttachFileIcon /> <Link onClick={() => handleFile(path, id)}>{description}</Link>
                </li>
            )
        }
    }

    const checkifItsVideo = () => {
        const intl = props.intl;
        let reg = new RegExp(/\@\@{.*\|\@\|.*\}$$/gm)
        let testReg = reg.test(action.description.trim())

        if(action.description.includes("https") && action.description.includes(".webm")) {

            const myArr = action.description.split(" :");
            console.log("entrei aqui no check")
            console.log(myArr)
            if(myArr.length > 1){
                var link = myArr[1];
                var message = myArr[0];
                console.log(link)
                console.log(message)

                return (
                    <p><span>{message}</span> <a href={link} target='_blank'>{link}</a> </p>
                )


            }
            return (
                <p>{ReactHtmlParser(action.description)} </p>
            )
            
        } else if(testReg) {
            let descriptionSplice = action.description.split(" : ")
            if(descriptionSplice.length > 0) {
                if(descriptionSplice[0] && descriptionSplice[1]) {
                    let newAction = descriptionSplice[1].slice(3, -1)
                    let newActionSplice = newAction.split("|@|")
                    if(newActionSplice.length > 0) {
                        if(newActionSplice[0] && newActionSplice[1]) {
                            let title = newActionSplice[1]
                            if(title.trim().endsWith("}"))
                                title = title.slice(0, -1)
                            return (
                                <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"document.sent"}))} <br/> <a href={newActionSplice[0]}  target="_blank"> {title} </a> </p>
                            )
                        }
                    }
                }
            }
            return (
                <p>{ReactHtmlParser(action.description)} </p>
            )
        } else if(action.description.trim().endsWith(".pdf")){
            let descriptionSplice = action.description.split(" : ");
            if(descriptionSplice.length > 0){
                if(descriptionSplice[0] && descriptionSplice[1]){
                    return (
                        <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"document.sent"}))} <br/> <a href={descriptionSplice[1]}  target="_blank"> {descriptionSplice[1]} </a> </p>
                    )
                }
            }
            return (
                <p>{ReactHtmlParser(action.description)} </p>
            )
        } else {
            return (
                <p>{ReactHtmlParser(action.description)} </p>
            )
        }
        
        
    }

    return (
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12}>
                <div className={classes.actionBox}>
                    <Grid item xs={12} sm={12}>
                        <div className={action.privacy ? 'privacyAction' : 'publicAction'}>
                            <p>
                                {action.useraction.name}
                                <span className="date">{moment(action.actiontime).format("DD/MM/YYYY HH:mm")}</span>
                                {showPrivacy && <span className="type">{action.privacy ? props.intl.formatMessage({id:"private.action"}) : props.intl.formatMessage({id:"public.action"})}</span>}
                            </p>
                        </div>
                    </Grid>
                    {checkifItsVideo()}
                    <div className={classes.attachmentsBox} style={{border: 0}}>
                        <ul>
                            {attachments.map(({ attachment }) => renderAttachment(attachment.type, attachment.path, attachment.description, attachment.id))}
                        </ul>
                    </div>
                </div>
            </Grid>
        </Grid>
    );

}

Action.propTypes = {
    classes: PropTypes.object.isRequired,
    action: PropTypes.object.isRequired,
    showPrivacy: PropTypes.bool.isRequired,
    attachments: PropTypes.array,
    getFile: PropTypes.func,
    id: PropTypes.number
};