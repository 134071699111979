import React, { Component } from 'react';
                import clsx from 'clsx';
                import { connect } from 'react-redux';
                import { bindActionCreators } from 'redux';
                import PropTypes from 'prop-types';
                import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import { Overlay } from '../../styles/global';
                import CustomizedSnackbars from '../../components/material-snackbars';
                import HeaderMenu from '../../components/header-menu';
                import AddIcon from '@mui/icons-material/Add';
                import { Link } from 'react-router-dom';
                import Api from '../../services/api';
                import MyMaterialTable from '../../components/table';
                import { Can } from '../../config/ability-context'
                import { AbilityContext } from '../../config/ability-context'
                import Footer from '../../components/footer';
                import { FormattedMessage,injectIntl } from 'react-intl';
                import Card from '@mui/material/Card';
                import CardContent from '@mui/material/CardContent';
                import Typography from '@mui/material/Typography';
                import Pagination from '@mui/material/Pagination';  
                import Search from '@mui/icons-material/Search';
                import TextField from '@mui/material/TextField';
                import SimpleSelect from '../../components/select/simple-search';
                import ClearIcon from '@mui/icons-material/Clear';
                import FirstElement from "../../components/first-element";
                import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
                import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';    

 
                    import * as componentActions from '../../redux/actions/techinicalteamorganizations';
                    class TechinicalTeamOrganizations extends Component {

                    constructor(props) {
                        super(props);
                        this.emptyArray = null;

                        this.typingTimeout = null;

                        const intl = this.props.intl;

                        let filters = [
                            {name:intl.formatMessage({id:"team"}),id:"team"},
                            {name:intl.formatMessage({id:"organization"}),id:"org"}
                            ];

                        const { userSession } = this.props;
                        this.state = {
                            dataFilter: false,
                            loading: false,
                            openNotification: false,
                            notificationVariant: 'error',
                            notificationMessage: '',
                            resultList: [],

                            totalPages:0,
                            page:1,
                            pageStart:0,
                            pageEnd:0,
                            count:0,
                            searchValue: '',
                            filterSelected: 0,
                            filters:filters
                        };

                        this.callSearch = this.callSearch.bind(this);
                        this.onFieldChange = this.onFieldChange.bind(this);
                        this.changeValues = this.changeValues.bind(this);

                        this.closeNotification = this.closeNotification.bind(this);
                        this.deleteItem = this.deleteItem.bind(this);
                        this.clearFields = this.clearFields.bind(this);
                    }

                    onChangePaginate = (e,page) =>{
                        this.setState({ page:page });
                        this.getItens(page);
                    }
                
                    setPagination(count,page){
                                            
                                            let totalPage = Math.ceil (count/10);
                                            this.setState({ totalPages:totalPage })
                                            if(count > 0){
                                                if(page == 1){
                                                    if(count < 10){
                                                        this.setState({pageStart:1,pageEnd:count});
                                                    } else{
                                                        this.setState({pageStart:1,pageEnd:10});
                                                    }
                                                } else{
                                                    let pageStart = ((page - 1) * 10) + 1;
                                                    
                                                    
                                                    
                                                    if(count >= page * 10){
                                                        this.setState({pageStart:pageStart,pageEnd:page * 10});
                                                    } else{
                                                        this.setState({pageStart:pageStart,pageEnd:count});
                                                    }
                                                }
                                            } else {
                                                this.setState({totalPages:0,page:1,count:0});
                                            }
                                            
                    }
                
                    onFieldChange(event) {
    
        
                        this.setState({ [event.target.name]: event.target.value, page:1 });
                
                    }
                
                    callSearch(){
                        if(this.state.filterSelected != 0){
                            this.getItens(this.state.page);
                        }                        
                    }
                
                    async clearFields(){
                        await   this.setState({filterSelected: 0, searchValue: '', page:1, loading: true})
                        this.getItens(1);
                    }

                    async changeValues(stateName, value, text = '') {
                        
                        this.setState({filterSelected : value});
                    }

                    deleteItem(data, resolve) {
                        let abl = this.context.can('Delete', 'TechinicalTeamOrganization');
                        if (abl == false){
                            const intl = this.props.intl;
                            this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
                            resolve(); return false;
                        }

                    Api.delete(`/techinicalteamorganizations/${data.id}`)
    
                            .then(result => {
                                if (result.data.success) {
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false,
                                        openNotification: true,
                                        notificationVariant: 'success',
                                        notificationMessage: intl.formatMessage({id:"delete.sucess"}),
                                        resultList: []
                                      });
          
                                    this.getItens();
                                }
                                else{
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                                resolve();
                            })
                            .catch(err => {
                                resolve();
                                const intl = this.props.intl;
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
                            });
    

                    };
    

                    componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
                        this.getItens();
                    }

                    getItens(page = 1) {

                        let url = `/techinicalteamorganizations/paginate?page=${page}`;
        
                        if(this.state.searchValue != '' && this.state.filterSelected != 0){
                            
                            url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`
                            this.setState({ dataFilter: true });
                        
                        }


                        this.setState({ loading: true });
                                            Api.get(url)
                            
                                    .then(result => {
                                        if (result.data.success) {

                                            let count = result.data.count;
                                            this.setState({count:count});
                                            this.setPagination(count,page);
                                            if(result.data.data.length === 0)
                                            {
                                                this.emptyArray = true 
                                            }
                                            else
                                            {
                                                this.emptyArray = false
                                            }


                                            const formattedList = result.data.data.map(item => {
                                                return {
                                                    ...item,
                                                     TechinicalTeamId: item.techinicalteam ? item.techinicalteam.id : 0,
                                                     TechinicalTeamText: item.techinicalteam ? item.techinicalteam.name : '',
                                                     OrganizationId: item.organization ? item.organization.id : 0,
                                                     OrganizationText: item.organization ? item.organization.name : '',

                                          }
                                    });
                                    this.setState({ loading: false, resultList: formattedList });
                                }
                                else{
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                            })
                            .catch(err => {
                                const intl = this.props.intl;
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
            
                            });
                    };


                    closeNotification() {
                        this.setState({ openNotification: false });
                    }
                     render() {
                        const { classes, headerMenu } = this.props;
                        const intl = this.props.intl;

                        if(Api == null) {
                            var l = this.props.match.params.lang;
                            document.location.href=`/${l}/unavailable`
                          }

                        return (
                            <div className={classes.root} style={{paddingLeft:0, marginLeft:0}}>
                                <HeaderMenu />
                                <main
                                    className={clsx(classes.content, {
                                        [classes.contentShift]: headerMenu.open,
                                    })}
                                >
                                    <div className={classes.drawerHeader} />

                                    <Container component='main' maxWidth='xl' style={{paddingLeft:0, marginLeft:0}}>
                                    {(!this.emptyArray || this.state.dataFilter) ? (
                                        <div>
                                            <CssBaseline />
                                            <Grid container className={classes.mb20}  spacing={0}>
                                                    <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        size='large'
                                                        disabled={!this.context.can('Add', 'TechinicalTeamOrganization')}
                                                        className={classes.button}
                                                        startIcon={<AddIcon />}
                                        component={Link} {...{ to: `/${this.props.match.params.lang}/techinicalteamorganization-registration` }}
                                                    >
                                                        {<FormattedMessage id="add" />}
                                                    </Button>
                                            </Grid>

                                            <Grid container spacing={6}> 
                                            <Grid item xs={12} sm={12}>

                                                    <Card className="background-title-search">
                                                <CardContent>
                                                    
                                                    <Grid container spacing={1} alignItems="flex-end">
                                                            <Grid item xs={12} md={6}>
                                                            <Typography variant="h5">
                                                            {<FormattedMessage id="menu.techinicalteamorganizations" />}
                                                            </Typography>
                                                                </Grid>

                                                                <Grid item xs={12} md={2}>
                                                                    <SimpleSelect label={intl.formatMessage({id:"field"})} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected}/>
                                                                    </Grid>
                                                                <Grid item>
                                                                    <Search />
                                                                </Grid>
                                                                <Grid item >
                                                                    <TextField type="text"
                                                                    name="searchValue"
                                                                    value={this.state.searchValue}
                                                                    placeholder={intl.formatMessage({id:"search"})}
                                                                    onChange={this.onFieldChange}/>

                                                    <Button
                                                            variant='contained'
                                                            color='secondary'
                                                            size='large'
                                                            className={classes.button}
                                                            style={{marginRight:4,marginLeft:8}}
                                                            onClick={this.callSearch}
                                                        >
                                                            <Search />
                                                    </Button>

                                                    <Button
                                                            variant='contained'
                                                            color='primary'
                                                            size='large'
                                                            onClick={this.clearFields}
                                                            className={classes.button}
                                                
                                                        >
                                                            <ClearIcon />
                                                    </Button>

                                                                </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                                </Grid>
                                                                
                                            </Grid>

                                            <Can I='List' a='TechinicalTeamOrganization'>      
                                            <MyMaterialTable
                                                            title=""
                                                                    rowClick={(event, rowData) => {
                                                                    let abl = this.context.can('Edit', 'TechinicalTeamOrganization');
                                                                        if (abl == false)
                                                                        {
                                                                            this.setState({ loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
                                                                            return false;
                                                                        }
                                                                        this.props.addCurrent(rowData);
                                                            this.props.history.push(`/${this.props.match.params.lang}/techinicalteamorganization-edit`);
                                                                }}
                                                columns={[
                                                        { title: intl.formatMessage({id:"team"}), field: 'TechinicalTeamText' },
                                                        { title: intl.formatMessage({id:"organization"}), field: 'OrganizationText' },


                                                ]}
                                                data={this.state.resultList}
                                                deleteItem={this.deleteItem} 
                                            />
                                            </Can>

                                            <Can I='List' a='TechinicalTeamOrganization'>
                                                
                                                <Grid container style={{marginBottom:12,marginTop:8}}>
                                                    <Grid item xs={12} md={3}>
                                                    <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} style={{marginTop:6}}>
                                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                                    </Grid>
                                                </Grid>

                                                </Can> 
                                            {this.state.loading && (
                                                <Overlay>
                                                    <CircularProgress color='secondary' />
                                                </Overlay>
                                            )}
                        

                                            <CustomizedSnackbars
                                                variant={this.state.notificationVariant}
                                                message={this.state.notificationMessage}
                                                isOpen={this.state.openNotification}
                                                toClose={this.closeNotification}
                                            />
                                        </div>)
                                        :(
                                            <div>
                                                <FirstElement
                                                title = {intl.formatMessage({id:"techinical.team.organization.list.create.team.x.organizations"})}
                                                subtitle = {intl.formatMessage({id:"techinical.team.organization.list.create.team.x.organizations.subtitle"})}
                                                //buttonText = "Novo Chamado"
                                                buttonText = 
                                                {
                                                    <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='large'
                                                    className={classes.button}
                                                    startIcon={<AddIcon />}
                                    component={Link} {...{ to: `/${this.props.match.params.lang}/techinicalteamorganization-registration` }}
                                                >
                                                    {<FormattedMessage id="add" />}
                                                </Button>
                                                }
                                                
                                                icon = {<SupervisedUserCircleIcon style={{fontSize: "45px"}} />}
                                               
                                               />
                                            </div>
                                             )}

                                            {this.state.loading && (
                                                <Overlay>
                                                    <CircularProgress color='secondary' />
                                                </Overlay>
                                            )}
                        

                                            <CustomizedSnackbars
                                                variant={this.state.notificationVariant}
                                                message={this.state.notificationMessage}
                                                isOpen={this.state.openNotification}
                                                toClose={this.closeNotification}
                                            />
                                        
                                        
                                        
                                    </Container>
                                </main>
                                <Footer />   
                            </div>
                        );
                    }
                }
TechinicalTeamOrganizations.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);
            export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(TechinicalTeamOrganizations)));TechinicalTeamOrganizations.contextType = AbilityContext;
