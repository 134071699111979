import React, { PureComponent } from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import { Card, CardContent, Grid, CardHeader } from '@mui/material'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { FormattedMessage, injectIntl } from 'react-intl'

const COLORS = ['#008e7d', '#52c5a9', '#ffe234', '#ffa534', '#db324d']

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

class Pizza extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/c9pL8k61/'

  totalIndex() {
    return this.props.data[0].value + this.props.data[1].value + this.props.data[2].value + this.props.data[3].value + this.props.data[4].value
  }

  getPercent(indexValue) {
    let total = this.totalIndex()

    if (indexValue <= 0) {
      return 0
    } else {
      return ((indexValue * 100) / total).toFixed(2)
    }
  }

  calculate() {
    let total = this.props.data[0].value + this.props.data[1].value + this.props.data[2].value + this.props.data[3].value + this.props.data[4].value
    let getBestRated = this.props.data[0].value + this.props.data[1].value

    let calculate = 0
    if(getBestRated != 0 && total != 0 ) {
      calculate = ((getBestRated)/total)*100
    }
    let result = calculate.toFixed(2)
    return result


  }

  render() {
    const { intl } = this.props

    let colorBackNPS = 'white'

    if (this.calculate() < 0 || this.calculate() === 0) {
      colorBackNPS = '#E74C3C'
    } else if(this.calculate() > 0 && this.calculate() <= 50 ){
      colorBackNPS = '#FFBB28'
    } else if(this.calculate() > 50 && this.calculate() <= 75 ){
      colorBackNPS = '#ece637'
    } else if(this.calculate() > 75 ){
      colorBackNPS = '#00C49F'
    }

    return (

      <Card>

        <CardHeader
          title={intl.formatMessage({ id: "quality.dashboard.csat.title" })}
        />
        <Divider />
        <CardContent>
          <Grid container >   
          <Grid item xs={12}>
              <Grid item xs={8} style={{ float: 'left' }}>
                <PieChart width={300} height={250}>
                  <Pie
                    data={this.props.data}
                    cx={150}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {
                      this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index]} />)
                    }
                  </Pie>
                </PieChart>
              </Grid>

              <Grid item xs={4} style={{ float: 'right' }}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" style={{ backgroundColor: '#8884d8', color: 'white', fontSize: '15px' }}><strong><FormattedMessage id="quality.dashboard.csat.score" /></strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" style={{ fontSize: '15px', backgroundColor: 'white', color: 'black'  }}><strong>{this.calculate()}</strong></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{marginTop:'0px'}} >
              <span><FormattedMessage id="quality.dashboard.csat.result" /></span>
              <TableContainer style={{marginTop:'5px'}}>
                <Table size="small" aria-label="a dense table" >
                  <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#8884d8', color:'white' , fontWeight:'bold'}}>
                        <FormattedMessage id="quality.dashboard.csat.subtitle" />
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid',  backgroundColor: '#8884d8', color:'white', fontWeight:'bold'}}><FormattedMessage id="quality.dashboard.csat.situation" /></TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid',  backgroundColor: '#8884d8', color:'white', fontWeight:'bold'}}><FormattedMessage id="quality.dashboard.csat.percentage" /></TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid',  backgroundColor: '#8884d8', color:'white', fontWeight:'bold' }}><FormattedMessage id="quality.dashboard.csat.amount" /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#008e7d' }}>
                        
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}><FormattedMessage id="quality.dashboard.csat.very.good" /></TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[0].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[0].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#52c5a9' }}>
                        
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}><FormattedMessage id="quality.dashboard.csat.good" /></TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[1].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[1].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#ffe234' }}>
                        
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}><FormattedMessage id="quality.dashboard.csat.neutral" /></TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[2].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[2].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#ffa534' }}>
                        
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}><FormattedMessage id="quality.dashboard.csat.bad" /></TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[3].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[3].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#db324d' }}>
                       
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}><FormattedMessage id="quality.dashboard.csat.too.bad" /></TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[4].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[4].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" align="center" style={{ backgroundColor: 'darkgrey', color: 'white' }} colSpan={3}>
                        <strong><FormattedMessage id="quality.dashboard.csat.total" /></strong>
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.totalIndex()}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item sm={2} md={2}></Grid>
          </Grid>
        </CardContent>
      </Card>


    );
  }
}

export default injectIntl(Pizza)