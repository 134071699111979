import React, { Component } from "react";
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { connect } from 'react-redux';
import HeaderMenu from "../../components/header-menu";
import { makeStyles } from '@mui/styles';
import { Grid, CircularProgress } from '@mui/material';
import Api from "../../services/api";
import Budget from './components/budget';
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver';
import TutorialProgress from './components/tutorialProgress';
import { Overlay } from "../../styles/global";
import TotalUsers from './components/totalusers';
import TasksProgress from './components/tasksprogress';
import ComparationCalledForMonth from './components/latestsales';
import * as TableCustomFilterService from '../../services/tablecustomfilter.service'
import CustomizedSnackbars from "../../components/material-snackbars"
import CalledForStatus from './components/usersbydevice';
import LastFeedback from './components/latestproducts';
import LastFeedbackPdf from './components/latestproductspdf';
import Pizza from './components/pizza';
import Footer from '../../components/footer';
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import LinearProgress from '@mui/material/LinearProgress';
import Pdf from "react-to-pdf";
//import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
//import ReactPDF from '@react-pdf/renderer';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import SimpleSelect from '../../components/select/simple';
import Chip from '@mui/material/Chip';
import {
  CardHeader
} from '@mui/material';

import { bindActionCreators } from 'redux';
import * as calledsActions from '../../redux/actions/calleds';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import CardContent from '@mui/material/CardContent';
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import moment from 'moment';
import Divider from '@mui/material/Divider'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button } from '@mui/material';
import LogoMarca from '../../images/new-logo-cmtech.png';
import * as CalledService from '../../services/called.service';
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import * as Service from "../../services/user.service";
import FilterListIcon from '@mui/icons-material/FilterList';
import { evaluateSession } from '../../utils/sessionUtils'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import PizzaNps from "./components/pizzaNps";
import { PapiroConsole } from "../../utils/papiroConsole";

const data = [
  {
    name: 'Page A', uv: 4000, chamados: 2400, amt: 2400,
  },
  {
    name: 'Page B', uv: 3000, chamados: 1398, amt: 2210,
  },
  {
    name: 'Page C', uv: 2000, chamados: 9800, amt: 2290,
  },
  {
    name: 'Page D', uv: 2780, chamados: 3908, amt: 2000,
  },
  {
    name: 'Page E', uv: 1890, chamados: 4800, amt: 2181,
  },
  {
    name: 'Page F', uv: 2390, chamados: 3800, amt: 2500,
  },
  {
    name: 'Page G', uv: 3490, chamados: 4300, amt: 2100,
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.Reload = this.Reload.bind(this);
    this.Reload();


    const { userSession } = this.props;

    evaluateSession(userSession)

    this.isClient = true;
    this.globalAdm = false;
    this.AdmOrganization = false;
    this.onlyAdmOrganization = false;
    this.percentSlasisCoordenator = false;
    this.isTechinical = false;

    // this.organizationId=null;

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
      if (item.role.roletype == 1) {
        this.AdmOrganization = true;
      }
      if (item.role.roletype == 4) {
        this.isCoordenator = true;
      }
      if (item.role.roletype == 2) {
        this.isTechinical = true;
      }
    });
    if (this.AdmOrganization == true && this.globalAdm == false) {
      this.onlyAdmOrganization = true;
    }

    if (userSession.user.userrole == null) {
      Api.logoff();
    }
    const listMonthts = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    this.state = {
      dateStart: moment(new Date().setDate(1)).format('YYYY/MM/DD HH:mm:ss'),
      dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
      totalCalled: 0,
      comparation: 0,
      positiveMonth: true,
      totalClassification: 0,
      comparationClassification: 0,
      positiveClassificationMonth: true,
      progressCalled: 0,
      grid: 4,
      grid12: 12,
      WindowSize: window.innerWidth * 0.9,
      maxWidth: '100%',
      width: '100%',
      calledStatus: [],
      calledStatusChart: [],
      percentSlas: [],
      listMonthts: listMonthts,
      dataStatus: [],
      classificationNoteList: [],
      NpsNoteList:[],
      dataStatusName: [],
      dataStatusColor: [],
      dataLastFeedback: [],
      dataCalledForMonth: [],
      loading: true,
      organization: 0,
      isTutorial: false,
      showChart: false,
      showButtonPdf: false,
      loadingSLA: false,
      organizationsList: [],
      organization: 0,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      favoriteFilters: [],
    };

    this.handleResize = this.handleResize.bind(this);
    this.getById = this.getById.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.search =  this.search.bind(this);
    this.getCalledForMonthTotal = this.getCalledForMonthTotal.bind(this);
    this.getCalleds = this.getCalleds.bind(this)
    this.getOrganizations = this.getOrganizations.bind(this)
    this.getCalledTotal = this.getCalledTotal.bind(this)
    this.getProgressTotal = this.getProgressTotal.bind(this)
    this.getCalledStatusTotal = this.getCalledStatusTotal.bind(this)
    this.getFavoritesAndCountByUserIdAndTableName = this.getFavoritesAndCountByUserIdAndTableName.bind(this)
    this.getPdf = this.getPdf.bind(this)
    this.getExcel = this.getExcel.bind(this)
    this.handleResize = this.handleResize.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }


  closeNotification = _ => {
    this.setState({ openNotification: false });
  }

  async getCalledForMonthTotal(organizationId = '') {

    let url = `/dashboard/monthCalled`;

    if(organizationId != null || organizationId != '' || organizationId != 0){
      url += "/" + organizationId;
    }

    // console.log("a url usada aqui é essa :::: " + url);

    Api.get(url)
      .then(result => {
        if (result.data.success) {

          let list = result.data.data.map((item) => {
            return {
              ...item,
              name: item.month,
              'Chamados': item.count

            }
          })
          //list.sort((a, b) => b.monthnumber < a.monthnumber ? -1 : b.monthnumber > a.monthnumber ? 1 : 0)
          this.setState({
            dataCalledForMonth: list,
            // loading: false,
          });


        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })
  }

  async getClassificationPie(organizationId = '') {

    // this.setState({loading: true})
    if(organizationId == null || organizationId == ''){
      organizationId = 0;
    }
    
    let url = `/calledclassifications/chart/query?&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&organizationId=${organizationId}`;

    Api.get(url)
      .then(result => {
        if (result.data.success) {
          this.setState({
            classificationNoteList: result.data.data,
            //loading: false,
          });


        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })
  }

  async getNpsClassification(organizationId = '') {

    // this.setState({loading: true})
    if(organizationId == null || organizationId == ''){
      organizationId = 0;
    }
    
    let url = `/nps/chart/query?&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&organizationId=${organizationId}`;


    console.log(url)
    console.log("url aqui")
    Api.get(url)
      .then(result => {
        console.log(result)
        console.log("result quero")
        if (result.data.success) {
          this.setState({
            NpsNoteList: result.data.data,
            //loading: false,
          });


        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })
  }

  async getCalledTotal(organizationId = '') {

    if(organizationId == null || organizationId == ''){
      organizationId = 0;
    }

    let start = moment(this.state.dateStart).format('YYYY-MM-DD');
    let end = moment(this.state.dateEnd).format('YYYY-MM-DD');

    let url = `/dashboard/all/query?dateStart=${start}&dateEnd=${end}&organizationId=${organizationId}`;

    Api.get(url)
      .then(result => {
        if (result.data.success) {
          this.setState({
            totalCalled: result.data.data.totalmonth,
            comparation: result.data.data.totalmonthpass,
            positiveMonth: result.data.data.positivemonth
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });


    // this.setState({ loading:false })
  }

  async getLastClassification(organizationId = '') {
    
    let url = `/dashboard/lastFeedback`;

    if(organizationId != null || organizationId != '' || organizationId != 0){
      url += "/" + organizationId;
    }

    Api.get(url)
      .then(result => {
        if (result.data.success) {
          // console.log("feedback abaixo")
          // console.log(result.data.data)
          this.setState({
            dataLastFeedback: result.data.data,
            // loading: false
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })

  }

  async getProgressTotal(organizationId = '') {

    if(organizationId == null || organizationId == ''){
      organizationId = 0;
    }

    let start = moment(this.state.dateStart).format('YYYY-MM-DD');
    let end = moment(this.state.dateEnd).format('YYYY-MM-DD');

    let url = `/dashboard/progress/query?dateStart=${start}&dateEnd=${end}&organizationId=${organizationId}`;

    Api.get(url)
      .then(result => {
        if (result.data.success) {

          if (result.data.data.totalinprogress > 0) {
            this.setState({
              progressCalled: result.data.data.totalinprogress,
              // loading: false
            });
          } else {
            this.setState({
              progressCalled: 0,
              // loading: false
            });
          }
        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })
  }
  timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  async getExcel() {
    const intl = this.props.intl;

    let startDate = moment(this.state.dateStart).format('_YYYY-MM-DD')
    let endDate = moment(this.state.dateEnd).format('_YYYY-MM-DD')

    let wb = new ExcelJS.Workbook() 

    let calledMounth = wb.addWorksheet(intl.formatMessage({ id: "called.mounth" }))
    calledMounth.columns = [
      { header: intl.formatMessage({ id: "called.mounth" }), key: 'total', width: 25 },
      { header: intl.formatMessage({ id: "relation.last.mounth" }), key: 'comparation', width: 25 },
      { header: intl.formatMessage({ id: "progress.calls.done" }), key: 'progress', width: 25 }
    ]

    let calledMounthData = [{
      total: this.state.totalCalled,
      comparation: `${this.state.comparation}%`,
      progress: `${this.state.progressCalled}%`
    }]

    calledMounth.getCell('A1').style = { font: { bold: true } }
    calledMounth.getCell('B1').style = { font: { bold: true } }
    calledMounth.getCell('C1').style = { font: { bold: true } }

    calledMounth.addRows(calledMounthData)

    let slasMounth = wb.addWorksheet(intl.formatMessage({ id: "slas.solution.attend" }))
    slasMounth.columns = [
      { header: intl.formatMessage({ id: "month" }), key: 'month', width: 25 },
      { header: intl.formatMessage({ id: "operation.dashboard.total" }), key: 'totalcalleds', width: 25 },
      { header: intl.formatMessage({ id: "operation.dashboard.sla.service.reached" }), key: 'totalcountcalledslaservicehit', width: 25 },
      { header: intl.formatMessage({ id: "operation.dashboard.sla.service.reached.percentage" }), key: 'calledslaservicehitpencent', width: 25 },
      { header: intl.formatMessage({ id: "operation.dashboard.sla.solution.reached.percentage" }), key: 'totalcountcalledslasolutionhit', width: 25 },
      { header: intl.formatMessage({ id: "operation.dashboard.sla.solution.reached" }), key: 'calledslasolutionhitpercent', width: 25 }
    ]

    let slasMonthData = this.state.percentSlas.map((item) => {
      return {
        month: `${this.state.listMonthts[parseInt(item.month) - 1]} / ${item.year}`,
        totalcalleds: item.totalcalleds,
        totalcountcalledslaservicehit: item.totalcountcalledslaservicehit,
        calledslaservicehitpencent: item.calledslaservicehitpencent,
        totalcountcalledslasolutionhit: item.totalcountcalledslasolutionhit,
        calledslasolutionhitpercent: item.calledslasolutionhitpercent,
      }
    })

    slasMounth.getCell('A1').style = { font: { bold: true } }
    slasMounth.getCell('B1').style = { font: { bold: true } }
    slasMounth.getCell('C1').style = { font: { bold: true } }
    slasMounth.getCell('D1').style = { font: { bold: true } }
    slasMounth.getCell('E1').style = { font: { bold: true } }
    slasMounth.getCell('F1').style = { font: { bold: true } }

    slasMounth.addRows(slasMonthData)

    let calledStatus = wb.addWorksheet(intl.formatMessage({ id: "called.status" }))
    calledStatus.columns = [
      { header: intl.formatMessage({ id: "status" }), key: 'name', width: 25 },
      { header: intl.formatMessage({ id: "quality.dashboard.csat.percentage" }), key: 'percent', width: 25 }
    ]

    let calledStatusData = this.state.calledStatus.map(status => {
      return {
        name: status.statusname,
        percent: `${status.percent}%`
      }
    })

    calledStatus.getCell('A1').style = { font: { bold: true } }
    calledStatus.getCell('B1').style = { font: { bold: true } }

    calledStatus.addRows(calledStatusData)

    let amountCalleds = wb.addWorksheet(intl.formatMessage({ id: "amount.calss" }))
    amountCalleds.columns = [
      { header: intl.formatMessage({ id: "month" }), key: 'month', width: 25 },
      { header: intl.formatMessage({ id: "amount" }), key: 'count', width: 25 }
    ]

    amountCalleds.getCell('A1').style = { font: { bold: true } }
    amountCalleds.getCell('B1').style = { font: { bold: true } }

    amountCalleds.addRows(this.state.dataCalledForMonth)

    let buf = await wb.xlsx.writeBuffer()

    saveAs(new Blob([buf]), `${intl.formatMessage({ id: "dashboards.operation.file" })}_${startDate}_${endDate}.xlsx`)
  }

  async getPdf() {

    await this.setState({ loading: true })

    let windowSize = this.state.WindowSize

    await this.setState({ maxWidth: '500px', grid: 12, width: '500px', showChart: true })

    const header = document.getElementById('header-pdf');
    let headerCanvas = await html2canvas(header);
    const headerImg = headerCanvas.toDataURL('image/png');

    let imgData = null
    let canvas = null
    const page1 = document.getElementById('div-id-to-print');
    if(page1 != null)
      canvas = await html2canvas(page1);
    if(canvas != null)
      imgData = canvas.toDataURL('image/png');

    const chart = document.getElementById('chart-called');
    let canvasChart = await html2canvas(chart);
    const imgChart = canvasChart.toDataURL('image/png');

    const page2 = document.getElementById('page2');
    let canvasPage2 = await html2canvas(page2);
    const imgData2 = canvasPage2.toDataURL('image/png');






    const page31 = document.getElementById('page3-1');
    let canvaspage31 = await html2canvas(page31);
    const imgData31 = canvaspage31.toDataURL('image/png');

    const pdf = new jsPDF();
    pdf.addImage(headerImg, 'PNG', 0, 0);
    if(imgData != null)
      pdf.addImage(imgData, 'PNG', 0, 25);
    pdf.addPage();
    pdf.addImage(imgData2, 'PNG', 0, 0);
    pdf.addImage(imgChart, 'PNG', 0, 150)
    pdf.addPage();

    if (this.onlyAdmOrganization || this.isCoordenator || this.AdmOrganization || this.globalAdm) {
      const page3 = document.getElementById('page3');
      let canvaspage3 = await html2canvas(page3);
      const imgData3 = canvaspage3.toDataURL('image/png');
      pdf.addImage(imgData3, 'PNG', 0, 0);
    }

    pdf.addImage(imgData31, 'PNG', 0, 150);
    if (this.onlyAdmOrganization || this.AdmOrganization || this.globalAdm) {
      const pizza = document.getElementById('pizza');
      if(pizza != null){

        let canvaspizza = await html2canvas(pizza);
        const imgPizza = canvaspizza.toDataURL('image/png');
        pdf.addPage();
        pdf.addImage(imgPizza, 'PNG', 0, 0);

      }
      

    }

    pdf.save("download.pdf");
    //   html2canvas(input)
    // .then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, 'PNG', 0, 0);
    //   pdf.save("download.pdf");
    // });

    await this.setState({ maxWidth: '100%', width: '100%', grid: 4, showChart: false, loading: false })

  }

  async getOrganizations() {
    //this.setState({ loading: true });
    const { userSession } = this.props
    let orgData = []
    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      orgData = userSession.user.userorganizations
    } else {
      const result = await CalledService.getOrganizations();
      if (result.success) {
        orgData = result.data
      } else {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);
  
      }
    }

    if(orgData != null) {
      this.setState({ organizationsList: orgData })
    }
  };

  async getCalleds(organizationId = '') {
    if (this.onlyAdmOrganization || this.isCoordenator || this.AdmOrganization || this.globalAdm) {

      let startDate = moment(this.state.dateStart).format('YYYY-MM-DD 00:00:00')
      let endDate = moment(this.state.dateEnd).format('YYYY-MM-DD 23:59:59')

      if(organizationId == null || organizationId == '')
        organizationId = 0

      let result = await CalledService.getMontlySLADashboardData(startDate, endDate, organizationId)
      if (result.success) {
        this.setState({ percentSlas: result.data, showButtonPdf: true })
      } else {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);
      }
      this.setState({ loadingSLA: false });
    }
    this.setState({ showButtonPdf: true })
  }

  async getCalledStatusTotal(organizationId = '') {

    if(organizationId == null || organizationId == ''){
      organizationId = 0;
    }

    let start = moment(this.state.dateStart).format('YYYY-MM-DD');
    let end = moment(this.state.dateEnd).format('YYYY-MM-DD');

    let url = `/dashboard/calledStatus/query?dateStart=${start}&dateEnd=${end}&organizationId=${organizationId}`;

    Api.get(url)
      .then(result => {
        if (result.data.success) {
          var listValues = [];
          var listNames = [];
          var listChart = [];
          var listColors = [];

          result.data.data.statuspercent.map(status => (
            listValues.push(status.total),
            listNames.push(status.statusname),
            listColors.push(status.statuscolorhex),
            listChart.push(status.chart)
          ))
          this.setState({
            calledStatusChart: listChart,
            calledStatus: result.data.data.statuspercent,
            dataStatus: listValues,
            dataStatusName: listNames,
            dataStatusColor: listColors
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })
  }

  async getClassificationTotal(organizationId = '') {

    let url = `/dashboard/classification`;

    if (organizationId != '' || organizationId != 0) {
      url += '/' + organizationId;
    }

    Api.get(url)
      .then(result => {

        if (result.data.success) {
          if (result.data.data.totalgeral === null) {
            result.data.data.totalgeral = 0;
          }
          this.setState({
            totalClassification: parseFloat(result.data.data.totalgeral.toFixed(2)),
            comparationClassification: result.data.data.totalmonthpass,
            positiveClassificationMonth: result.data.data.positivemonth
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    //this.setState({ loading: false })

  }

  handleResize(WindowSize, event) {
    this.setState({ WindowSize: window.innerWidth * 0.9 })

  }

  async changeValues(stateName, value, text = '') {
    //console.log(stateName+" : "+value);
    if (stateName === "organization") {
      // this.organizationId=value;
      this.setState({ organization: value });
      // console.log("Organization id >>>" + value)

      //  await this.loadItems(value);
      // this.updateOrganization(this.organizationId);

    } else {
      this.setState({ [stateName]: value });
    }


    if (stateName === "dateEnd") {
      // this.organizationId=value;
      console.log(value)
      console.log("value aqui")
      console.log(this.state.dateStart)
      if(value < this.state.dateStart){
        console.log("não é possivel ter uma data final menor que a data inicial")
      }
      // console.log("Organization id >>>" + value)

      //  await this.loadItems(value);
      // this.updateOrganization(this.organizationId);

    }
  };

  async loadItems(organizationId = '') {
    this.setState({ loading: true })
    await this.getCalleds(organizationId); // **
    await this.getOrganizations(); // **
    await this.getCalledTotal(organizationId); // **
    await this.getProgressTotal(organizationId); // **
    await this.getCalledStatusTotal(organizationId); // **
    await this.getCalledForMonthTotal(organizationId); // **
    await this.getFavoritesAndCountByUserIdAndTableName(); // **
    this.setState({ loading: false })

    

  }

  async search() {
    let dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    let dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')

    if(dateStart > dateEnd){
        
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:"Data inicial não pode ser maior que data final",
                loading :false
                });
   
    } else {
      await this.loadItems(this.state.organization && this.state.organization.id)
    }
  }

  getFavoritesAndCountByUserIdAndTableName = async _ => {

    const { userSession } = this.props
    let userId = userSession && userSession.user && userSession.user.id

    let result = await TableCustomFilterService.getFavoritesAndCountCalledByUserIdAndTableName(userId, "calleds")
    if(result && result.success) {
        if(result.data != null) {
            this.setState({
              favoriteFilters: result.data.favorites
            })
        }
    } else {
        const intl = this.props.intl
        this.setState({
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
    }
  }

  goToCalledWithFilter = (tableCustomFilter) => {
    if(tableCustomFilter && tableCustomFilter.tablecustomfilterfields) {
      let field = ''
      let search = ''

      tableCustomFilter.tablecustomfilterfields.map(item => {
        field += `${item.field}@`
        search += `${item.value}@`
      })

      localStorage.setItem('newFilter', field);
      localStorage.setItem('newSearch', search);
      this.props.history.push(`/${this.props.match.params.lang}/calleds`);
    }
  }


  async getById(id) {
    
    let data = {}
    this.setState({ loading: true });

    Api.get(`/calleds/calledInfoPaginate/${id}`)
      .then(async result => {
        if (result.data.success) {

          let item = result.data.data

          let times = "";
            if (item.organizationcategory.techinicalteamorganizationcategories != null) {
              item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
                  times = times + item2.techinicalteam.name + ","
              });
              times = times.substring(0, times.length - 1);
            }
            data = {
              ...item,
              statusName: item.status.name,
              OrganizationCategory: item.organizationcategory.parentname,
              Organization: item.organization.name,
              slaAtendimento: getSlaAtendimento(item, this.props.userSession) + "%",
              slaSolucao: getSlaSolucao(item, this.props.userSession) + "%",
              assignedUser: item.assigneduser ? item.assigneduser.name : "",
              requestingUser: item.requestinguser ? item.requestinguser.name : "",
              time: times,
              addressname: item.address.name,
              opening: moment(item.openingdate).format("DD/MM/YYYY"),
              classification: item.calledclassification
            }

            await this.props.addCurrentCalled(data);
            await this.props.addAccompanimentsList(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : null);

            await this.props.addCalled(data);
            await this.props.addAccompanimentsListInCalled(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : [], data.id);

            const urlRedirect = "/" + this.props.match.params.lang + `/called-accompaniment/${data.id}`;
            // window.open(urlRedirect, "_blank");
            // this.setState({ loading: false })
            await setTimeout(() => { 
              window.open(urlRedirect, "_blank");
              this.setState({ loading: false })
            }, 3000);

          } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : 'Erro ao processar a solicitação.'
            });
            Api.kickoff(result);
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação."
        });
        Api.kickoff(err);

      });
  };
  async componentDidMount() {
    await this.loadItems();
  }

  Reload = () => {
    const reload = localStorage.getItem('reload');
    if (reload == "true") {
      localStorage.setItem('reload', false);

      setTimeout(function () { document.location.reload(true); }, 500);
    }
    return 0;
  }

  render() {
    const { classes, headerMenu, userSession } = this.props;
    const intl = this.props.intl;
    const ref = React.createRef();


    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    const save = (organization) => {
      this.setState({ organization: organization });
    }

    const options = {
      orientation: 'landscape',
      unit: 'in',
      format: [4, 2]
    };

    const reset = () => {
    }

    const printDiv = (divName) => {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;

    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} style={{ minWidth: '300px' }} />
          <Grid container spacing={2}>
            <Grid id="header-pdf" item lg={12} sm={12} xl={12} xs={12} style={{ padding: '2px' }}>
              <Card>
                <CardContent>
                  {this.state.showChart && <img src={LogoMarca} style={{ width: 70 }} /> }
                  <span style={{ marginLeft: 25, fontSize: 18 }}>{ this.state.showChart && intl.formatMessage({ id: "productivity.dashboard.report.of" }) } <FormattedMessage id="operation.dashboards.title" /></span>
                  { this.state.showChart && <span style={{ marginLeft: 25, fontSize: 18 }}><FormattedMessage id="from.capital" /> {moment(this.state.dateStart).format('DD/MM/YYYY')} <FormattedMessage id="to" /> {moment(this.state.dateEnd).format('DD/MM/YYYY')}</span>}
                  <Divider />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={1} direction="row" justify="space-between" alignItems="stretch" style={ this.state.favoriteFilters && this.state.favoriteFilters.length > 0 ? { marginBottom: 30 } : {}}>
              {this.state.favoriteFilters.map((item, key) => {
                return <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Card
                    style={{ height: '100%', padding: 10, backgroundColor: '#8884d8', color: '#fff', cursor: 'pointer' }}
                    onClick={() => this.goToCalledWithFilter(item)} >
                    <Grid container alignItems="center" style={{ height: '100%'}}>
                      <Grid item md={2}>
                        <FilterListIcon />
                      </Grid>
                      <Grid item md={8}>
                        <Typography variant="h5" component="h2" style={{ color: '#fff' }}>
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <Chip label={item.countcalleds} variant="outlined" style={{ borderColor: '#fff', color: '#fff' }} />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9} md={2} lg={2}>
                  {this.state.showButtonPdf && <Button variant='contained'
                    color='secondary'
                    style={{ marginBottom: "24px" }}
                    size='large' onClick={this.getExcel}>{<FormattedMessage id="export" />}</Button>}
                </Grid>
              
                  <Grid item xs={12} sm={4} md={3} lg={3}>
                    <SimpleDatePicker label={intl.formatMessage({id:"start.date"})} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required/>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={3}>
                    <SimpleDatePicker label={intl.formatMessage({id:"end.date"})} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd}  required/>
                  </Grid>
                {(this.AdmOrganization || this.globalAdm) &&
                <>
                  <Grid item xs={12} sm={6} md={2} lg={2}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "productivity.dashboard.organizations" })} options={this.state.organizationsList} stateName="organization" changeSelect={this.changeValues} required />
                  </Grid>
                </>
                }
                <Grid item xs={12} sm={4} md={4} lg={2}>
                  <Button variant='contained'
                    color='secondary'
                    size='large' onClick={this.search}>
                    {<FormattedMessage id="search" />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container style={{ maxWidth: this.state.maxWidth }} id="page2">
                <Grid item sm={6} xs={12} style={{ padding: '2px' }} >
                  <Budget total={this.state.totalCalled} comparation={this.state.comparation} positiveMonth={this.state.positiveMonth} />
                </Grid>
                <Grid item sm={6} xs={12} style={{ padding: '2px' }}>
                  <TasksProgress progress={this.state.progressCalled} />
                </Grid>
              </Grid>
            </Grid>
            {(this.onlyAdmOrganization || this.AdmOrganization || this.globalAdm) &&(
            <Grid item xs={12} sm={12} md={12} lg={12} id="page3" style={{ padding: '2px' }}>
              <Card >
                <CardHeader title={intl.formatMessage({ id: "menu.slas.attend" })} />
                  <Divider />
                <CardContent>
                  <Grid container spacing={1} alignItems="flex-end" >
                    <Grid item xs={4} md={4}  >
                      <Typography component='h1' variant='h5'>
                        {intl.formatMessage({ id: "month" })}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography component='h1' variant='h5'>
                        {intl.formatMessage({ id: "sla.service" })}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography component='h1' variant='h5'>
                        {intl.formatMessage({ id: "sla.solution" })}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider />
                    </Grid>
                    {this.state.loadingSLA && (
                      <>
                        <CircularProgress color="secondary" />
                        <div style={{ marginLeft: '15px' }}>Essa Operação pode demorar um pouco</div>
                      </>
                    )}
                    {this.state.percentSlas.length > 0 && <>
                      {this.state.percentSlas.map((item) => {
                        return (
                          <>
                            <Grid item xs={4} md={4}>
                              <Typography component='h1' variant='h5'>
                                {this.state.listMonthts[parseInt(item.month) - 1]} / {item.year}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} md={4}>
                              {item.calledslaservicehitpencent.toFixed(2)} %
                                <LinearProgress variant="determinate" value={item.calledslaservicehitpencent} />
                              {item.totalcountcalledslaservicehit} / {item.totalcalleds} ({intl.formatMessage({ id: "operation.dashboard.reached" })} / {intl.formatMessage({ id: "operation.dashboard.total" })})
                            </Grid>
                            <Grid item xs={4} md={4}>
                              {item.calledslasolutionhitpercent.toFixed(2)} %
                                <LinearProgress variant="determinate" value={item.calledslasolutionhitpercent} />
                              {item.totalcountcalledslasolutionhit} / {item.totalcalleds} ({intl.formatMessage({ id: "operation.dashboard.reached" })} / {intl.formatMessage({ id: "operation.dashboard.total" })})
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Divider />
                            </Grid>
                          </>
                        )
                      })}
                    </>}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            )}
            {(this.onlyAdmOrganization || this.AdmOrganization || this.globalAdm || this.isCoordenator || this.isTechinical) &&(
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2}>
                <Grid item id="div-id-to-print" lg={6} md={6} sm={12} xs={12} style={{ padding: '2px' }}>
                  <CalledForStatus chart={this.state.calledStatusChart} status={this.state.calledStatus} dataValues={this.state.dataStatus} dataNames={this.state.dataStatusName} dataColors={this.state.dataStatusColor} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{ padding: '2px' }} id="chart-called">
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={intl.formatMessage({ id: "amount.calss" })} />
                    <Divider />
                    <CardContent>
                      <Typography component='h1' variant='h5'>
                        {intl.formatMessage({ id: "called.header" })}
                      </Typography>
                      <BarChart
                        width={530}
                        height={500}
                        data={this.state.dataCalledForMonth}
                        barSize={20} >
                        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar dataKey="Chamados" fill="#8884d8" background={{ fill: '#eee' }} />
                      </BarChart>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>)}
          </Grid>
          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}
           <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />

        </main>
        <Footer />

      </div>

    );
  }
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chatHubConnection: state.chatHubConnection,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Dashboard)));
