import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@mui/material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


import Base from '../../components/layout-base/base';
import NavTabs from '../../components/tab/tab';
import {Grid} from '@mui/material';

import NpsSearchEditContent from './index.content';
import OrganizationSettingsContent from '../organizationsetting-list/index.content';
import AddressesContent from '../address-list/index.content';
import OrganizationCalendarsContent from '../organizationcalendar-list/index.content';
import OrganizationFieldsContent from '../organizationfield-list/index.content';
import OrganizationChecklistsContent from '../organizationchecklist-list/index.content';
import OrganizationEmailAccountConfig from '../organizationaccountemail-list/index.content';

import * as componentActions from '../../redux/actions/organizations';
import EventIcon from '@mui/icons-material/Event';
import InputIcon from '@mui/icons-material/Input';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import SettingsIcon from '@mui/icons-material/Settings';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import EmailIcon from '@mui/icons-material/Email';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import Wizard from '../wizard-organization/wizard';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AnswerContent from '../calledassigneduser-list/indexanswercontent';



class NpsSearchEdit extends Component {

  constructor(props) {
    super(props);
    //console.log('edit');
    //console.log(this.props.organizations.organization);
    //console.log('edit');
    console.log("props do primeiro aqui")
      console.log(props)
    
    
  }

  componentWillReceiveProps(nextProps){
  }    

  componentDidMount() {
  };


    render() {
      const intl = this.props.intl;
      
     return (
        <Base >
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={8} />
              </Grid>

              <Grid container>
                <Grid item xs={11} sm={12} >
                <Tabs >
                  <TabList style={{borderColor:'#BB8FCE'}}>
                    <Tab  style={{fontSize: "14px"}}>< AssignmentIcon/> {intl.formatMessage({id:"data"})}  </Tab>
                    <Tab  style={{fontSize: "14px"}}>< QuestionAnswerIcon/> {intl.formatMessage({id:"response"})}  </Tab>

                    
                   
                
                  </TabList>
            
                  <TabPanel>
                  
                  <NpsSearchEditContent/>

                 </TabPanel>

                  <TabPanel>
                  
                  <AnswerContent/>

                  </TabPanel>

                 
                </Tabs>
                </Grid>
              </Grid>





              

        </Base>  
    );
  }
}
NpsSearchEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
            const mapStateToProps = state => ({
                headerMenu: state.headerMenu,
                userSession: state.userSession,
                npssearchs: state.npssearchs,
            });
      const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

  export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(NpsSearchEdit)));