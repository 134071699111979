import Api from './api';
export const getCalleds = async () => { 
  let data = [];
  await Api.get('/calleds')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getUsers = async () => { 
  let data = [];
  await Api.get('/users')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getAssignedUsers = async () => { 
  let data = [];
  await Api.get('/users')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getChart = async (dateStart, dateEnd, organizationId) => { 
  let data = [];
  await Api.get(`/calledclassifications/chart/query?&dateStart=${dateStart}&dateEnd=${dateEnd}&organizationId=${organizationId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
  });
  return data;
};
