import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import * as Service from "../../services/organizationpatrimony.service";
import { WithContext as ReactTags } from 'react-tag-input';
import './style.css';
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class KnowledgeBaseRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      CalledId:  "",
      CalledText: '',
      description : '', 
      PatrimonySubgroupId: 0,
      PatrimonySubgroupText: "",
      PatrimonyGroupId: 0,
      PatrimonyGroupText: "",
      PatrimonyMaterialId: "",
      keeppatrimony: true,
      PatrimonyMaterialText: "",
      title: ""
    };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      CalledList: [],
      PatrimonySubgroupList: [],
      PatrimonyGroupList: [],
      PatrimonyMaterialList: [],
      KeywordsList: [],
      files: [],
      tags: []
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }

  componentDidMount() {
    this.getPatrimonyGroups();
  };

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
   this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleTagClick(index) {
    // console.log('The tag at index ' + index + ' was clicked');
  }

    async getPatrimonySubgroups(patrimonyGroupId) {
      const intl = this.props.intl;
      this.setState({ loading: true });
      var result = await Service.getPatrimonySubgroups(patrimonyGroupId);
      if (result.success) {
        this.setState({ loading: false, PatrimonySubgroupList: result.data });
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
              ? result.data.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
        Api.kickoff(result);
      }
    }

    async getPatrimonyGroups() {
      const intl = this.props.intl;
      this.setState({ loading: true });
      var result = await Service.getPatrimonyGroups();
      if (result.success) {
        this.setState({ loading: false, PatrimonyGroupList: result.data });
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
              ? result.data.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
        Api.kickoff(result);
      }
    }
    async getPatrimonyMaterials(subgroupid) {
      const intl = this.props.intl;
      this.setState({ loading: true });
      var result = await Service.getPatrimonyMaterials(subgroupid);
      if (result.success) {
        this.setState({ loading: false, PatrimonyMaterialList: result.data });
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
              ? result.data.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
        Api.kickoff(result);
      }
    }
  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();

      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }


      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if(key.toString().toLowerCase().indexOf('description') > -1) {
          data.append(key, this.state.item[key] == null || typeof this.state.item[key] === "undefined" ? "" : this.state.item[key]);
        } else {
          data.append(key, this.state.item[key]);
        }
      }

      var word = ""
      for (let key in this.state.tags) {
        word = this.state.tags[key].text+","+word
      }

      var patrimony = ""
      for (let key in this.state.item.PatrimonyMaterialId) {
        patrimony = this.state.item.PatrimonyMaterialId[key].id+","+patrimony
      }

      data.append("words", word);
      data.append("patrimonyid", patrimony);

      for (var pair of data.entries()) {
        // console.log(pair[0]+ ', ' + pair[1]); 
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      Api.post('/knowledgebases', data, config)

        .then(result => {
          const intl = this.props.intl;
          if (result.data.success) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: this.props.intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: [],
              tags: []
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: this.props.intl.formatMessage({id:"process.error"})
            });
          }
          Api.kickoff(err);
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));

    if (stateName === "PatrimonySubgroupId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonySubgroupText"]: text,
          ["PatrimonyMaterialId"]: "",
        },
      }));

      if (value.id != 0) {
        this.getPatrimonyMaterials(value.id);
      }
    }

    if (stateName === "PatrimonyGroupId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonyGroupText"]: text,
          ["PatrimonySubgroupId"]: "",
          ["PatrimonyMaterialId"]: "",
        },
      }));
      if (value.id != 0) {
        this.getPatrimonySubgroups(value.id);
      }
    }
    if (stateName === "PatrimonyMaterialId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonyMaterialText"]: text,
        },
      }));
    }
 
    this.setState({ loading: false });

  };

  handleChangeEditor = (value) => {
		this.setState(prevState => ({
			item: {
			  ...prevState.item,
			  description: value
			}
		  }));
	}

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, intl } = this.props;
    const { item } = this.state;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'   onClick={browserHistory.goBack}>{this.props.intl.formatMessage({id:"back"})}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {intl.formatMessage({id:"typography.add.item"})}
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <TextField 
                        fullWidth
                        label={intl.formatMessage({id:'title'})} 
                        autoComplete='fname' 
                        variant='outlined' 
                        name='title' 
                        onChange={this.handleInputChange} 
                        value={item.title} />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginBottom: 50 }}>
                      <Typography>
                        <FormattedMessage id="description" />
                      </Typography>
                      <ReactQuill name="description" value={item.description} style={{ height: 200 }}
                        fullWidth
                        required
                        InputLabelProps={{ shrink: item.description && item.description.length > 0 ? true : false }}
                        variant="outlined"
                        id="outlined-multiline-static"
                        onChange={this.handleChangeEditor} />
                    </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "menu.patrimonygroups" })}
                      options={this.state.PatrimonyGroupList}
                      stateName="PatrimonyGroupId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonyGroupId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({
                        id: "menu.patrimonysubgroups",
                      })}
                      options={this.state.PatrimonySubgroupList}
                      stateName="PatrimonySubgroupId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonySubgroupId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label={intl.formatMessage({
                        id: "menu.patrimonymaterials",
                      })}
                      options={this.state.PatrimonyMaterialList}
                      stateName="PatrimonyMaterialId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonyMaterialId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ReactTags
                      placeholder={intl.formatMessage({ id: "keywords.press.enter" })}
                      tags={this.state.tags}
                      delimiters={delimiters}
                      handleDelete={this.handleDelete}
                      handleAddition={this.handleAddition}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <section style={{ width: '100%' }}>
                      <div className={classes.dropzoneArea}>
                        <Dropzone onDrop={acceptedFiles => { this.setState({ files: acceptedFiles }) }}>
                          {({ getRootProps, getInputProps, acceptedFiles }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>{<FormattedMessage id="drag.and.drop.files" />}</p>
                            </div>
                          )}
                        </Dropzone>
                        {this.state.files.map(file => {
                          return (
                            <div className={classes.dropzoneItem} key={file.name}>
                              {file.name}
                              <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                            </div>
                          )
                        })}
                      </div>
                    </section>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                className={classes.submit}
                            >
                                {this.props.intl.formatMessage({id:"save"})}
                            </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}

KnowledgeBaseRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(KnowledgeBaseRegistration)));
