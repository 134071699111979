import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import Routes from './routes';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './redux/store';
import { ThemeProvider } from '@mui/material/styles';
import { Chart } from 'react-chartjs-2';
import chartjs  from './helpers/chartjs';
import theme from './styles/theme';
import { browserHistory } from './helpers/history';
import IntlProviderConfigured from './_i18n/intlproviderconfigured';
import './paginate.css';
import { useChatHubContext } from './contexts/chatHubContext';
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { useSelector } from "react-redux";
import { baseURL } from "./services/api";
import { PapiroConsole } from './utils/papiroConsole';
import axios from 'axios';

//Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
//  draw: chartjs.draw
//});

function App() {
    const { 
      setChatHubConnection,
      setOpenChatListInitialValue,
      setNewPrivateMessage,
      setOpenClientChat,
      loadChatMessages,
      handleNewChat,
      UpdateChatInTheList,
      closeChat,
      ShowError,
      Ok,
    } = useChatHubContext();
    const userSession = useSelector((state) => state.userSession);

    const isClient = (userSession) => {
      let isClient = false;
      userSession.user.userrole.map(item => {
        if (item.role.roletype === 3) {
            isClient = true
        }
      })
      return isClient
    };

    const handleHubConnection = async (accessToken, userId) => {
        try {
          //PapiroConsole.log("=== handleHubConnection => accessToken ===")
          //PapiroConsole.log(accessToken)
          const chatHub = new HubConnectionBuilder()
          .withUrl(`${baseURL}/chat`, { accessTokenFactory: () => accessToken, skipNegotiation: true,
			// eslint-disable-next-line no-undef
			transport: HttpTransportType.WebSockets })
          .build();
          
          await chatHub.start();

          setChatHubConnection(chatHub);
  
          chatHub.on("Log", (message, log) => {
            PapiroConsole.log("=== APP.js - chatHub => message, log ===")
            PapiroConsole.log(message)
            PapiroConsole.log(log)
          });
          chatHub.on("ShowError", ShowError);

          chatHub.on("Ok", Ok);

          chatHub.on("InitChat", handleNewChat)
  
          chatHub.on("SendPrivateMessage", setNewPrivateMessage);
  
          chatHub.on("ListOpenChats", setOpenChatListInitialValue)

          chatHub.on("ClientOpenChat", setOpenClientChat)
          
          chatHub.on("LoadChatMessages", loadChatMessages);

          chatHub.on("ResetNotSeenMessages", UpdateChatInTheList);
          
          chatHub.on("CloseChat", closeChat);
          
          chatHub.on("RegisterConnectionId", (ok, connections) => {
            //PapiroConsole.log(ok)
            //PapiroConsole.log(connections)
          });

          
          // Carrega o chat atual do client ou lista os chats para o técnico
          isClient(userSession) ? await chatHub.invoke("ClientOpenChat", userId) : await chatHub.invoke("ListOpenChats", userId);  
        } catch(err) {
          PapiroConsole.log("=== handleHubConnection -> error === ")
          PapiroConsole.log(err);
        }
    }

    useEffect(() => {
      try {
      
        if (userSession && userSession.user) {
          
          const { id, accesstoken } = userSession.user;
          axios.get(`${baseURL}/users/verifyToken`, {
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': "Bearer "+accesstoken },
          }).then(result => {
              if(result.data.success){
            //    console.log(userSession)
          //console.log("userSession")
                handleHubConnection(accesstoken, id);
              }
          //console.log("verifyToken")
          //console.log(result)            
          })
          .catch(err => {
              
          console.log("verifyToken")
          console.log(err)
          });

          
        }
      } catch(err) {
        throw err;
      }
    }, []);

    return (
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <Router history={browserHistory}>
                    <IntlProviderConfigured>
                        <Routes>
                    
                        </Routes>
                    </IntlProviderConfigured>                            
                </Router>
            </ThemeProvider>
        </PersistGate>

    );
}

export default App;