import React, { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import 'react-chat-widget/lib/styles.css';
import CloseIcon from '@mui/icons-material/Close';
import { getOrganizations } from "../../services/called.service";
import CustomizedSnackbars from "../../components/material-snackbars";
import { useChatHubContext } from "../../contexts/chatHubContext";
import { ChatOptions } from "../../components/chat-options";
import { InitChatDialog } from "../../components/initChatDialog";

import "./styles.scss";
import { PapiroConsole } from '../../utils/papiroConsole';

export function ClientChat({ userId, userSession, closeChat, intl }) {
  const { 
    chatHub: chatHubConnection,
    currentClientChat,
    currentMessages,
    error,
  } = useChatHubContext();

  const [organizations, setOrganizations] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [showInitChatDialog, setShowInitChatDialog] = useState(true);
  const [alertNotification, setAlertNotification] = useState({ variant: "error", isOpen: false, message: "" });
  const [inputMessage, setInputMessage] = useState(null);
  const [errorInt, setErrorInt] = useState(error);

  const closeAlertMessage = () => setAlertNotification({...alertNotification, isOpen: false});
  const openAlertMessage = (variant, message) => setAlertNotification({variant, message, isOpen: true});

  useEffect(() => {
    if (!currentClientChat) {
      setShowInitChatDialog(true);
      setShowChat(false);
    } else {
      setShowInitChatDialog(false);
      setShowChat(true);
    }

    PapiroConsole.log("=== useEffect => chatHubConnection ===")
    PapiroConsole.log(chatHubConnection)

     chatHubConnection.invoke("CheckClientOpenChat", currentClientChat ? currentClientChat.id : 0, userId).catch(function (err) {
      setAlertNotification({variant: "error", message: err.toString(), isOpen: true});
      console.error(err.toString());
     
    });
    
    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      setOrganizations(userSession.user.userorganizations)
    } else {
      getOrganizations().then((result) => {
          if (result.data && result.data.length > 0) {
            const organizations = result.data.filter(org => {
              return !!org.organizationsettings.allowattendanceviachat
            }); 
            setOrganizations(organizations);
          } else {
            setOrganizations([]);
          }
        }).catch((err) => {
          setOrganizations([]);
          setAlertNotification({variant: "error", message: err.toString(), isOpen: true});
          console.error(err.toString());

          throw err;
        });
    }
  }, []);
  
  useEffect(() => {
    scrollChatToBottom();
    setErrorInt(error);
    console.log("aqui!!!!!!! -> "+error);
  }, [currentMessages, error]);

  
/*
  const setErrorMessage = async (message) => {
      alert(1);
      setAlertNotification({variant: "error", message: message, isOpen: true});
      console.error(message.toString());
  
  };
*/
  const closeChatAttendance = async () => {
    await chatHubConnection.invoke("CloseChat", currentClientChat.id, userId).catch(function (err) {
      setAlertNotification({variant: "error", message: err.toString(), isOpen: true});
      console.error(err.toString());
      setErrorInt(true);
    });
    closeChat(true);
  };

  const initChatConversation = async (orgId) => {
    //? Chat já foi iniciado pelo cliente
    if (!currentClientChat) {

     PapiroConsole.log("initChatConversation")
     PapiroConsole.log(orgId)
     PapiroConsole.log(userId)

      await chatHubConnection.invoke("InitChat", userId, orgId) .then(result => {

        if(result == false){

          setShowChat(false);
          return;

        }


      })
    }
    setErrorInt(false);
  };

  const sendPrivateMessage = async (message) => {
    //? Chat já foi iniciado pelo cliente
    if (!message || !currentClientChat) return;

    await chatHubConnection.invoke("SendPrivateMessage", message, currentClientChat.id, userId).catch(function (err) {
      setAlertNotification({variant: "error", message: err.toString(), isOpen: true});
      console.error(err.toString());
    });
  };

  //TODO ver isso aqui
  const addMessage = (e, message, sender) => {
    e.preventDefault();
    if (!message) return;
    
    if (!currentClientChat) {
      initChatConversation(message);
    } else {
      sendPrivateMessage(message);
    }
    setInputMessage("");
  }

  const scrollChatToBottom = () => {
    const chatContent = document.querySelector(".chat-main-content");
    if (!chatContent) return;
    chatContent.scrollTo({ top: chatContent.scrollHeight, behavior: "smooth" })
  }

  const verifyClientChat = () => {
   
    return returnClientChat();
  };

  const returnClientChat = () => {


      return (
      <div className="client-chat-container">
        <div className="header-container">
          <div className="chat-button">
            <CloseIcon onClick={ closeChat } />
          </div>
          <div className="header-content">

            <div className="title">Atendimento via chat</div>
            <div className="username">{ currentClientChat ? currentClientChat.assignedUser.name : ""}</div>
            {/* <div className="organization">{ currentClientChat ? currentClientChat.assignedUser.name : ""}</div> */}
          </div>
        </div>
        {/*console.log(currentClientChat)*/}

        <div className="chat-main-content">
          { currentMessages && currentMessages.length > 0 && currentMessages.map(({ id, message, senderUser }) => {
            if (senderUser.id === userId) {
              return (
                <div key={ id } className="sender">
                  <span style={{ backgroundColor: "#5f5fff", color: "white" }} className="message sender">{ message }</span>
                </div>
              )
            } else {
              return (
                <div key={id} className="receiver" style={{display:'flex', justifyContent:'flex-start', flexDirection: 'column' }}>

                  <span>{ currentClientChat ? currentClientChat.assignedUser.name : ""}<br /></span>
                  <span className="message receiver">{ message }</span>
                </div>
              )
            }
          }) }
        </div>
        <div className="chat-input-container">
          <form onSubmit={ (e) => addMessage(e, inputMessage, userId) } className="input-wrapper">
            <input disabled={errorInt} onChange={ (e) => setInputMessage(e.target.value) } placeholder="Começar conversa..." className="chat-input" type="text" value={inputMessage}></input>
            <SendIcon disabled={errorInt} onClick={ (e) => addMessage(e, inputMessage, userId) } className="send-icon"/>
            <ChatOptions disabled={errorInt}  closeChat={closeChatAttendance} />
          </form>
        </div>
    </div>
      )
    }

  return (
    <>
      { showChat ? verifyClientChat() : <InitChatDialog openChat={ () => setShowChat(true) } onStartChat={ (orgId) => initChatConversation(orgId) } organizations={organizations} open={ showInitChatDialog } handleClose={ () => setShowInitChatDialog(false) } intl={intl} /> }
      <CustomizedSnackbars
        variant={alertNotification.variant}
        message={alertNotification.message}
        isOpen={alertNotification.isOpen}
        toClose={closeAlertMessage}
      />
    </>
  );
}