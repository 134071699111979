import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleDatePicker from '../../components/date-pickers/inline';
import * as Service from '../../services/organizationfield.service';
import { parseJSON } from 'date-fns';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import Title from '../../components/title-name';

import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

class OrganizationFieldRegistration extends Component {

    constructor(props) {
        super(props);
        // console.log(props);
        const { userSession, organizations } = this.props;

        this.globalAdm = false;
        this.isClient = true;

        this.fromProps = false
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
            this.fromProps = true

        if (userSession.user.userrole == null) {
            Api.logoff();
        }


        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype !== 3) {
                this.isClient = false;
            }
            if (item.role.roletype === 0) {
                this.globalAdm = true;
            }
        }
        );

        this.initialState = {
            createdUser: userSession.user.id,
            createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            OrganizationId: 0,
            OrganizationText: '',
            name: '',
            active: false,
            isrequired: false,
            customeredit: false,
            saveToAllOrganizationsInGroup: false,

            organizationfieldapptarget: 0
        };

        this.state = {

            item: this.initialState,
            organizations: organizations,
            files: [],
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            OrganizationList: [],

            apptargetlist: [ { id: 0, name: 'Todos' }, { id: 1, name: '2DO' }, { id: 2, name: '2MOVE' }]
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);

    } // Fim - construtor

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        if (this.props.location && /*this.props.location.state.internal === true &&*/ this.props.location.state != undefined) {
            this.getOrganizationsById(this.state.organizations.organization.id);
        }
        else {
            this.getOrganizations();
        }
    };

    async getOrganizations() {
        this.setState({ loading: true });
        var result = await Service.getOrganizations();

        if (result.success) {
            this.setState({ loading: false, OrganizationList: result.data });

        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };

    async getOrganizationsById(id) {

        this.setState({ loading: true });
        var result = await Service.getOrganizationsByIdLight(id);
        if (result.success) {
            this.setState({ loading: false, OrganizationList: [result.data] });

        } else {
            const intl = this.props.intl;

            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });

            Api.kickoff(result);
        }
    };

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [name]: value
            }
        }));
    };

    handleSubmit = async e => {
        e.preventDefault();

        this.setState({ loading: true });

        let data = new FormData();

        // console.log(this.state.item)

        if (this.state.item.OrganizationId === 0 || this.state.item.name.trim() === "" ) {

            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organizationfields.required" }),
               
            });

        } else {
            if (this.isClient) {
                this.setState(prevState => ({
                    item: {
                        ...prevState.item,
                    }
                }));
            } else {
                this.setState(prevState => ({
                    item: {
                        ...prevState.item,
                    }
                }));
            }


            for (let key in this.state.item) {
                if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date') > -1 || key.toString().toLowerCase().indexOf('eventdate') > -1) {
                    data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
                } 
                
                else if (key.toString().toLowerCase().indexOf('organizationfieldapptarget') > -1) {
                    data.append(key, this.state.item[key]);
                  }                  
                
                else {
                    data.append(key, this.state.item[key]);
                }
            }


            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            //console.log(parseJSON(data));
            //console.log(data);
            //console.log(JSON.stringify(data));

            Api.post('/organizationfields', data, config)

                .then(result => {
                    if (result.data.success) {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'success',
                            notificationMessage: intl.formatMessage({ id: "add.success" }),
                            item: this.initialState,
                            files: []
                        });



                    }
                    else {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

                        });
                        Api.kickoff(result);
                    }

                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    }
                    else {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data
                        });
                    }
                    Api.kickoff(err);
                });
        }



    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    changeValues(stateName, value, text = '') {
        this.setState({ loading: true });

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [stateName]: value
            }
        }));

        if (stateName === 'OrganizationId') {
            this.setState(prevState => ({
                item: {
                    ...prevState.item,
                    ['OrganizationText']: text,
                }
            }));
        }

        this.setState({ loading: false });
    };

    removeItem(file) {

        let array = [...this.state.files];
        let index = array.indexOf(file)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }

    render() {
        const { classes, headerMenu } = this.props;
        const { item } = this.state;
        const intl = this.props.intl;

        if(Api == null) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/unavailable`
          }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >

                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={() => {
                                        this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 4 }) : browserHistory.goBack()}
                                    }>{<FormattedMessage id="back" />}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="add.item" />}
                                <Title />
                            </Typography>
                            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={intl.formatMessage({ id: "organization" })} options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={item.OrganizationId} required />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={intl.formatMessage({ id: "organizationfield.app.target" })} options={this.state.apptargetlist} stateName='organizationfieldapptarget' changeSelect={this.changeValues} selected={this.state.item.organizationfieldapptarget} required />
                                    </Grid>                                    

                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={intl.formatMessage({ id: "required" })} name='isrequired' stateName='isrequired' changeSelect={this.changeValues} selected={item.isrequired} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={intl.formatMessage({ id: "customer.edit" })} name='customeredit' stateName='customeredit' changeSelect={this.changeValues} selected={item.customeredit} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={intl.formatMessage({ id: "active" })} name='active' stateName='active' changeSelect={this.changeValues} selected={item.active} />
                                    </Grid>
                                    <Grid item xs={11} sm={11}>
                                        <SimpleCheckBox
                                        label={intl.formatMessage({id:"save.to.all.organizations.in.group"})}
                                        name='saveToAllOrganizationsInGroup'
                                        stateName='saveToAllOrganizationsInGroup' 
                                        changeSelect={this.changeValues}
                                        selected={item.saveToAllOrganizationsInGroup} />
                                    </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                        <Grid item xs={12} sm={4}    >
                                            <Button 
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            className={classes.submit}
                                            >
                                            {<FormattedMessage id="save" />}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </Grid>
                            </form>

                        </div>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>

                </main>
            </div>
        )
    }
}

OrganizationFieldRegistration.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationFieldRegistration)));

