import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Service from '../../services/config.service';
import * as componentActions from '../../redux/actions/configs';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import NumberFormat from "react-number-format"
import SimpleHelp from "../../components/help"

import Autocomplete from '@mui/material/Autocomplete';
import SimpleCheckBox from '../../components/checkbox/check';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import SimpleSelect from "../../components/select/simple";
import { PapiroConsole } from '../../utils/papiroConsole';

function getRandomNUmber(){
  const min = 1;
  const max = 10000;
  const rand = min + Math.random() * (max - min);      
  return rand;
}

class ConfigEdit extends Component {

  constructor(props) {
    super(props);
            const { userSession,configs } = this.props;

    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    if(!configs || (configs && !configs.config)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );
    console.log(configs)
    console.log("configsconfigs")
    const defaultvalue = {id:0, name:''};
    this.timezoneid =   configs.config.timezone;
    const intl = this.props.intl;
    const ascending = intl.formatMessage({id:"order.ascending"}); const descending = intl.formatMessage({id:"order.descending"});
    let ordeningoptions = [ { id: 1, name: ascending }, { id: 2, name: descending }]
    
    this.initialState = {
      UpdatedUser: userSession.user.id,
      id: configs.config.id,
      //timezone :  defaultvalue, 
      timezone :  {id: configs.config.timezone.id, name: configs.config.timezone.id + ' - ' + configs.config.timezone.name}, 
      formatdate : configs.config.formatdate == null ? defaultvalue : { id: getRandomNUmber(), name: configs.config.formatdate}, 
      decimalseparator : configs.config.decimalseparator == null ? '' : configs.config.decimalseparator, 
      thousandseparator : configs.config.thousandseparator == null ? '' : configs.config.thousandseparator, 
      //gasvalueperliter: configs.config.gasvalueperliter == null ? '' : configs.config.gasvalueperliter, 
      //kmperliter: configs.config.kmperliter == null ? '' : configs.config.kmperliter,
      kmvaluerotated: configs.config.kmvaluerotated == null ? '' : configs.config.kmvaluerotated,
      frommail: configs.config.frommail == null ? '' : configs.config.frommail,
      hostmail: configs.config.hostmail == null ? '' : configs.config.hostmail,
      portmail: configs.config.portmail == null ? '' : configs.config.portmail,
      usermail: configs.config.usermail == null ? '' : configs.config.usermail,
      passwordmail: "",
      enablessl: configs.config.enablessl == null ? false : configs.config.enablessl,
      mailtest: "",
      removecallswithfinishedstatus:
        configs.config.removecallswithfinishedstatus == 1
          ? true
          : false,
      calledaccompanimentordertype: configs.config.calledaccompanimentordertype
     };         
    this.state = {
      config: this.initialState,

      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      TimeZoneList: [],
      FormatDateList: [],
      ordeningoptions: ordeningoptions
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.newchangeValues = this.newchangeValues.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  async componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

  async componentDidMount() {
    await this.getTimeZones();
    //console.log(this.state.config.timezoneid);
    // await this.getTimeZone(this.timezoneid);
    await this.getFormatDates();
  };

  async getTimeZone(id){
    this.setState({ loading: true });
    var result = await Service.getTimeZone(id);
      if (result.success) {
        //console.log(result.data);
        //this.setState({ loading: false, timezone: result.data });

        this.setState(prevState => ({
          config: {
            ...prevState.config,
            ['timezone']: result.data
          }
        }));        
                      
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
      }
  };

  async getTimeZones(){
      this.setState({ loading: true });
      var result = await Service.getTimeZones();
        if (result.success) {
          //console.log(result.data);
          if(result.data && result.data.length > 0) {
            var formattedList = result.data.map(item => {

              let itemOldName = item.name
              item.name = item.id + ' - ' + item.name

              return {
                  ...item,
                  oldName: itemOldName
              }
            })
            this.setState({ loading: false, TimeZoneList: formattedList });
          }
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);
        }
  };

  async getFormatDates(){
        this.setState({ loading: true });
        var result = await Service.getFormatDates();
        //console.log(result.data);
          if (result.success) {
            this.setState({ loading: false, FormatDateList: result.data });
                          
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false, openNotification: true, notificationVariant: 'error',
              notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
          }
  };

  NumberFormatCustom(props) {

    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  async newchangeValues(stateName, value) {
    this.setState({ loading: true });

    this.setState(prevState => ({
      config: {
        ...prevState.config,
        [stateName]: value
      }
    }));

    this.setState({ loading: false });

  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'kmvaluerotated' || value.length == 0) {
      const re = /^[0-9\b]+$/;
      if (re.test(value)) {
        this.setState(prevState => ({
          config: {
            ...prevState.config,
            [name]: value
          }
        }));
      }else{
        this.setState(prevState => ({
          config: {
            ...prevState.config,
            [name]: ""
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        config: {
          ...prevState.config,
          [name]: value
        }
      }));
    }
  }

  handleClick = async (e) => {
    const intl = this.props.intl;
    this.setState({ loading: true });
    if(this.state.config.mailtest.length > 0) {
        let result = await Service.testMail(this.state.config.mailtest);
        if(result && result.data && result.data.success == true){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({id:"email.sent"})
            });
        }else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result && result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);
        }
    }else {
        this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({id:"missing.destination.email"})
        })
    }
  };

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    this.setState({ loading: true });

    if (this.state.config.calledaccompanimentordertype == null || this.state.config.calledaccompanimentordertype == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"config.edit.notification.invalid.called.accompaniment.order"})
      })
    } else {
    

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          config: {
            ...prevState.config,
          }
        }));
      } else {
        this.setState(prevState => ({
          config: {
            ...prevState.config,
          }
        }));
      }


      for (let key in this.state.config) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.config[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('timezone') > -1) { 
          data.append(key, this.state.config[key].id);
        } else if (key.toString().toLowerCase().indexOf('formatdate') > -1) { 
          data.append(key, this.state.config[key].name);
        } else if(key.toString().toLowerCase().indexOf("gasvalueperliter") > -1) {
            if(this.state.config[key].length > 0) {
              data.append(key, this.state.config[key])
            } else
              data.append(key, 0)
        } else if (key.toString().toLowerCase().indexOf('removecallswithfinishedstatus') > -1) {
          data.append(key, this.state.config[key]);
        } else if (key.toString().toLowerCase().indexOf('calledaccompanimentordertype') > -1) {
          data.append(key, this.state.config[key]);
        } else {
          data.append(key, this.state.config[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.put('/configs', data, config)

        .then(result => {
          if (result.data.success) {
        const formattedList = result.data.data;
        const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              files: []
            });
            //console.log(formattedList);
        this.props.addCurrent(formattedList);
        this.timezoneid=formattedList.timezone;
        this.componentDidMount();
 
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
        });
    }
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });
    this.setState(prevState => ({
      config: {
        ...prevState.config,
        [stateName]: value
      }
    }));
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

    render() {
    const { classes, headerMenu, configs } = this.props;
    const { config } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="edit.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"timezone"})} options={this.state.TimeZoneList} stateName='timezone' changeSelect={this.changeValues} selected={config.timezone} />
                      {/* <Autocomplete id="timezone" options={this.state.TimeZoneList} stateName='timezone' changeSelect={this.changeValues} inputValue={config.timezone.name} getOptionLabel={(option) => option.name} style={{ width: 300 }} renderInput={(params) => <TextField {...params} label={intl.formatMessage({id:"timezone"})} variant="outlined" />} /> */}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"date.format"})} options={this.state.FormatDateList} stateName='formatdate' changeSelect={this.changeValues} selected={config.formatdate} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"decimal.separator"})} autoComplete='fname' variant='outlined' name='decimalseparator' onChange={this.handleInputChange} value={config.decimalseparator} required 
                      onInput = {(e) =>{
                        e.target.value = e.target.value.toString().slice(0,1)
                      }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"thousand.separator"})} autoComplete='fname' variant='outlined' name='thousandseparator' onChange={this.handleInputChange} value={config.thousandseparator} required 
                      onInput = {(e) =>{
                        e.target.value = e.target.value.toString().slice(0,1)
                      }}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <TextField
                        label={intl.formatMessage({id:"called.distance.value"})}
                        variant="outlined"
                        value={config.kmvaluerotated}
                        onChange={this.handleInputChange}
                        name="kmvaluerotated"
                        id="formatted-numberformat-input"
                        InputProps={{
                          inputComponent: this.NumberFormatCustom,
                        }}
                        autoComplete="fname"
                        required
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect
                      label={intl.formatMessage({id:"calledaccompanimentordertype"})}
                      options={this.state.ordeningoptions}
                      stateName='calledaccompanimentordertype'
                      changeSelect={this.changeValues}
                      selected={config.calledaccompanimentordertype}
                      required={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox 
                        label={intl.formatMessage({id:"config.removecallswithfinishedstatus"})} 
                        name='removecallswithfinishedstatus' 
                        stateName='removecallswithfinishedstatus' 
                        changeSelect={this.changeValues} 
                        selected={config.removecallswithfinishedstatus} />                       
                    </Grid>                  

                    <Grid item xs={12} sm={12}>
                      <Typography component="h1" variant="h5" style={{color: "#263238", fontSize: "16px" }}>
                        {intl.formatMessage({id:"mail.sending"})}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="frommail"
                        variant="outlined"
                        type="email"
                        fullWidth
                        id="frommail"
                        label={intl.formatMessage({id:"config.edit.email.from"})}
                        value={config.frommail}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="hostmail"
                        variant="outlined"
                        fullWidth
                        id="hostmail"
                        label={intl.formatMessage({id:"hostmail"})}
                        value={config.hostmail}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="portmail"
                        variant="outlined"
                        type="number"
                        fullWidth
                        id="portmail"
                        label={intl.formatMessage({id:"port"})}
                        value={config.portmail}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="usermail"
                        variant="outlined"
                        type="email"
                        fullWidth
                        id="usermail"
                        label={intl.formatMessage({id:"usermail"})}
                        value={config.usermail}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="passwordmail"
                        variant="outlined"
                        fullWidth
                        id="passwordmail"
                        label={intl.formatMessage({id:"mailpass"})}
                        type='password'
                        placeholder={intl.formatMessage({id:"leave.empty"})}
                        value={config.passwordmail}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                        label={intl.formatMessage({id:"config.edit.enable.ssl"})}
                        name='enablessl'
                        stateName='enablessl'
                        changeSelect={this.newchangeValues}
                        selected={config.enablessl} />
                    </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name="mailtest"
                      variant="outlined"
                      fullWidth
                      id="mailtest"
                      label={intl.formatMessage({id:"config.edit.label.send.test.email"})}
                      value={config.mailtest}
                      onChange={this.handleInputChange} />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Grid container justify="flex-start" spacing={2} style={{ justifyContent: 'flex-start' }}>
                      <Grid item xs={12} sm={6} align-items-xs-center={'true'}>
                        <Button
                        onClick={this.handleClick}
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit} >
                          {intl.formatMessage({id:"config.edit.test.sending.email"})}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
      </div>
    );
  }
}
ConfigEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    configs: state.configs
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);

            export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(ConfigEdit)));
