import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import SimpleCheckBox from '../../components/checkbox/check';
import * as componentActions from '../../redux/actions/organizationcategories';
import * as Service from '../../services/organizationcategory.service';
import { getOrganizationCategoryDepartamentsByOrganizationCategory, getDepartamentsByOrganizationCategoryId } from '../../services/organizationcategory.service';
import * as OrgDepartamentService from '../../services/organizationdepartament.service';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import SimpleHelp from '../../components/help';
import * as PriorityService from '../../services/priority.service'

class OrganizationCategoryEdit extends Component {

  constructor(props) {
    super(props);
    
    const { userSession, organizationcategories, organizations } = this.props;

    this.fromProps = false
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
      this.fromProps = true

    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }

    if(!organizationcategories || (organizationcategories && !organizationcategories.organizationcategory)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    console.log("=== organizationcategories.organizationcategory ===")
    console.log(organizationcategories.organizationcategory)
      
        
    this.initialState = {
      UpdatedUser: userSession.user.id,
     id: organizationcategories.organizationcategory.id,
     OrganizationId:  organizationcategories.organizationcategory.OrganizationId,
     TypeId:  organizationcategories.organizationcategory.typeid,
     TypeText:  organizationcategories.organizationcategory.TypeText,
     PriorityId:  organizationcategories.organizationcategory.priorityid,
     OrganizationText: organizationcategories.organizationcategory.OrganizationText,
     OrganizationSlaId:  organizationcategories.organizationcategory.OrganizationSlaId,
     OrganizationSlaText: organizationcategories.organizationcategory.OrganizationSlaText,
     CalledFlowId:  organizationcategories.organizationcategory.CalledFlowId,
     CalledFlowText: organizationcategories.organizationcategory.CalledFlowText,
     SlaCountTypeId:  organizationcategories.organizationcategory.SlaCountTypeId,
     SlaCountTypeText: organizationcategories.organizationcategory.SlaCountTypeText,
     CategoryId:  organizationcategories.organizationcategory.categoryid,
     CategoryText: organizationcategories.organizationcategory.CategoryText,
     ParentOrganizationCategoryId:  organizationcategories.organizationcategory.parentorganizationcategoryid,
     ParentOrganizationCategoryText: organizationcategories.organizationcategory.ParentOrganizationCategoryText,
     name : organizationcategories.organizationcategory.name == null ? '' : organizationcategories.organizationcategory.name , 
     cancreatecalled : organizationcategories.organizationcategory.cancreatecalled, 
     imageicon : organizationcategories.organizationcategory.imageicon == null ? '' : organizationcategories.organizationcategory.imageicon , 
     parentname : organizationcategories.organizationcategory.parentname == null ? '' : organizationcategories.organizationcategory.parentname , 
     servicetime : organizationcategories.organizationcategory.servicetime == null ? '' : organizationcategories.organizationcategory.servicetime , 
     solutiontime : organizationcategories.organizationcategory.solutiontime == null ? '' : organizationcategories.organizationcategory.solutiontime , 
     useslatime : organizationcategories.organizationcategory.useslatime, 
     serviceslanotification : organizationcategories.organizationcategory.serviceslanotification, 
     isdefaultcategory : organizationcategories.organizationcategory.isdefaultcategory, 
     template: false,
     caneditcalledtitleanddescription: organizationcategories.organizationcategory.caneditcalledtitleanddescription,
     saveToAllOrganizationsInGroup: false,
     };         
    this.state = {
      item: this.initialState,
      organizations: organizations,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      OrganizationSlaList: [],
      CalledFlowList: [],
      TypeList: [],
      SlaCountTypeList: [],
      CategoryList: [],
      ParentOrganizationCategoryList: [],
      template: false,
      disableFields: false,
      DepartamentId: 0,
      DepartamentText: '',
      DepartamentList: [],
      priorityList: []
    };

    userSession.user.userrole.map((item, key) => {
          if(item.role.roletype !=3){
              this.isClient = false;
          }
          if(item.role.roletype ==0){
            this.globalAdm =true;
          }
      }
    );

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getTemplate = this.getTemplate.bind(this);
  }

  async loadSlas() {
      if(this.state.item.useslatime && this.state.item.OrganizationSlaId !== 0){
        let result = await Service.getSlaById(this.state.item.OrganizationSlaId);
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['servicetime']: result.data.servicetime,
            ['solutiontime']: result.data.solutiontime
          },
          disableFields:true
        }));
      } else {
        this.setState(prevState => ({
          disableFields:false
        }));
      }

    
  }

  async componentDidMount() {
    if (this.state.organizations && this.state.organizations.organization && this.state.organizations.organization.id) {
      this.getOrganizationsById(this.state.organizations.organization.id);
    } else {
      this.getOrganizations();
    }
        this.getTypes();
        this.getPriorities();
        this.getOrganizationSlas();
        this.getCalledFlows();
        this.getSlaCountTypes();
        this.getCategories();
        this.getParentOrganizationCategories(this.state.item.OrganizationId);
        this.getOrganizationDepartaments(this.state.item.OrganizationId);
        this.loadSlas();
     };
     async getOrganizations(){
          this.setState({ loading: true });
          var result = await Service.getOrganizations();
            if (result.success) {
              this.setState({ loading: false, OrganizationList: result.data });
              console.log("=== getOrganizations ===")
              console.log(result.data)
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };   
  
  getOrganizationsById = async (id) => {
    const intl = this.props.intl;
    this.setState({ loading: true });
    var result = await Service.getOrganizationsByIdLight(id);
    if (result.success) {
        this.setState({ loading: false, OrganizationList: [result.data] });
        console.log("=== getOrganizationsById ===")
        console.log(result.data)
    } else {
        this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
    }
  };
  
  async getOrganizationSlas(){
          this.setState({ loading: true });
          var result = await Service.getOrganizationSlas(this.state.item.OrganizationId);
            if (result.success) {
              this.setState({ loading: false, OrganizationSlaList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };     async getCalledFlows(){
          this.setState({ loading: true });
          var result = await Service.getCalledFlows();
            if (result.success) {
              this.setState({ loading: false, CalledFlowList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };     async getSlaCountTypes(){
          this.setState({ loading: true });
          var result = await Service.getSlaCountTypes();
            if (result.success) {
              this.setState({ loading: false, SlaCountTypeList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };     async getCategories(){
          this.setState({ loading: true });
          var result = await Service.getCategoriesList();
            if (result.success) {
              this.setState({ loading: false, CategoryList: result.data });
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };     
  
  getPriorities = async () => {
    this.setState({ loading: true });
    let result = await PriorityService.getAllByHirerId()
    if(result && result.success) {
        if(result.data != null)
            this.setState({ loading: false, priorityList: result.data })
    } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
    }
  }

  async getDepartamentsByOrganizationCategoryId(id) {
    this.setState({ loading: true });
    var result = await getDepartamentsByOrganizationCategoryId(id);
      if (result.success) {
        if(result.data && result.data.length > 0) {
          this.setState({ loading: false, DepartamentId: result.data });
        }else {
          this.setState({ loading: false, DepartamentId: [] });
        }
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
  }

  async getOrganizationDepartaments(id) {
    this.setState({ loading: true });
      var result = await OrgDepartamentService.getOrganizationsDepartaments(id);
        if (result.success) {
          this.setState({ loading: false, DepartamentList: result.data });
          this.getDepartamentsByOrganizationCategoryId(this.state.item.id)
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);  
        }
  }

  async getTypes(){
    this.setState({ loading: true });
    var result = await Service.getTypes();
      if (result.success) {
        this.setState({ loading: false, TypeList: result.data });
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
};

      async getParentOrganizationCategories(id){
          this.setState({ loading: true });
          var result = await Service.getParentOrganizationCategoriesList(id);
            if (result.success) {
              this.setState({ loading: false, ParentOrganizationCategoryList: result.data });
              var resultFilter = result.data.filter(item => item.id == this.state.item.ParentOrganizationCategoryId)
              if(resultFilter != null && resultFilter.length > 0) {
                this.setState(prevState => ({
                  item: {
                    ...prevState.item,
                    ['ParentOrganizationCategoryId']: resultFilter[0]
                  }
                }));
              }
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));

  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    var departamentIds = "";
    console.log(this.state.DepartamentId)
    if(this.state.DepartamentId){
      this.state.DepartamentId.forEach(element => {
        departamentIds = departamentIds+element.id+","
      })
    }
    
    var data = {
      Id: this.state.item.id,
      Name: this.state.item.name,
      ParentName : this.state.item.parentname,
      ServiceTime: this.state.item.servicetime,
      SolutionTime: this.state.item.solutiontime,
      CanCreateCalled: this.state.item.cancreatecalled,
      UseSLATime: this.state.item.useslatime,
      ServiceSLANotification: this.state.item.serviceslanotification,
      IsDefaultCategory: this.state.item.isdefaultcategory,
      OrganizationId: this.state.item.OrganizationId,
      TypeId: this.state.item.TypeId,
      PriorityId: this.state.item.PriorityId ? this.state.item.PriorityId : 0,
      OrganizationSLAId: this.state.item.OrganizationSlaId,
      CalledFlowId: this.state.item.CalledFlowId,
      SLACountTypeId: this.state.item.SlaCountTypeId,
      CategoryId: this.state.item.CategoryId,
      ParentOrganizationCategoryId: this.state.item.ParentOrganizationCategoryId ? this.state.item.ParentOrganizationCategoryId.id : 0,
      UpdatedAt: this.state.item.UpdatedAt,
      UpdatedUser: this.state.item.updatedUser,
      DepartamentId: 0,
      Departaments: departamentIds,
      caneditcalledtitleanddescription: this.state.item.caneditcalledtitleanddescription,
      saveToAllOrganizationsInGroup: this.state.item.saveToAllOrganizationsInGroup,
    }
    
    Api.put('/organizationcategories', data)
    .then(result => {
      if (result.data.success) {
        const intl = this.props.intl;
        const formattedList = result.data.data;
        const item = result.data.data;
        formattedList.OrganizationId= item.organization ? item.organization.id : 0;
        formattedList.TypeId = item.type ? item.type.id : 0;
        formattedList.PriorityId = item.priorityid != null ? item.priorityid : 0;
        formattedList.OrganizationText= item.organization ? item.organization.name : '';
        formattedList.OrganizationSlaId= item.organizationsla ? item.organizationsla.id : 0;
        formattedList.OrganizationSlaText= item.organizationsla ? item.organizationsla.name : '';
        formattedList.CalledFlowId= item.calledflow ? item.calledflow.id : 0;
        formattedList.CalledFlowText= item.calledflow ? item.calledflow.name : '';
        formattedList.SlaCountTypeId= item.slacounttype ? item.slacounttype.id : 0;
        formattedList.SlaCountTypeText= item.slacounttype ? item.slacounttype.name : '';
        formattedList.CategoryId= item.category ? item.category.id : 0;
        formattedList.CategoryText= item.name;
        formattedList.ParentOrganizationCategoryId= item.parentorganizationcategoryid ? item.parentorganizationcategoryid : 0;
        formattedList.ParentOrganizationCategoryText= item.parentorganizationcategory ? item.parentorganizationcategory.name : '';
        formattedList.CanCreateCalled= item.cancreatecalled == true  ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"});
        formattedList.UseSlaTime= item.useslatime == true  ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"});
        formattedList.ServiceSlaNotification= item.serviceslanotification == true  ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"});
        formattedList.IsDefaultCategory= item.isdefaultcategory == true  ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"});

        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({id:"edit.success"}),
          files: []
        });
        if(this.props.organizations && this.state.organizations.organization)
          this.props.addCurrentOrganization(formattedList, this.state.organizations.organization);
        else
          this.props.addCurrent(formattedList);
        this.getParentOrganizationCategories(result.data.data.organizationid);
      }
      else{
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

        });
        Api.kickoff(result);
      }

    })
    .catch(err => {
      if(err.response && err.response.data && err.response.data.errors){
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
      }
      else{
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
        });
      }
      Api.kickoff(err);
    });
  };

  getTemplate(id){

    Api.get(`/categories/${id}`)
      .then(result => {
        if (result.data.success) {
            /*
            this.setState(prevState => ({
              item: {
                ...prevState.item,
                    cancreatecalled: result.data.data.cancreatecalled ? result.data.data.cancreatecalled: false,
                    name: result.data.data.name ? result.data.data.name : '',
              }
            }));*/
          this.setState({ loading: false });
        }
        else{
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);
    
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(err);

      });
  } 

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
              ...prevState.item,
              [stateName]: value
            }
      }));

        if(stateName === 'DepartamentId') {
          this.setState({
              ['DepartamentId']: value,
              ['DepartamentText']: text
          });
        }
      // console.log(value);
        if(stateName === 'OrganizationId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OrganizationText']: text,
            }
          }));
          this.getParentOrganizationCategories(value);
          await this.getOrganizationDepartaments(value)
        }
        if(stateName === 'OrganizationSlaId'){
          if(value !== 0 && this.state.item.useslatime){
            let result = await Service.getSlaById(value);
            // console.log(result);
            this.setState(prevState => ({
              item: {
                ...prevState.item,
                ['servicetime']: result.data.servicetime,
                ['solutiontime']: result.data.solutiontime
              },
              disableFields:true
            }));
            //this.state.item.solutiontime = result
          } else {
            this.setState(prevState => ({
              disableFields:false
            }));
          }
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OrganizationSlaText']: text,
            }
          }));
        }
        if(stateName === 'CalledFlowId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['CalledFlowText']: text,
            }
          }));
        }

        if(stateName === 'TypeId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['TypeText']: text,
            }
          }));
        }

        /*if(stateName === 'PriorityId') {

        }*/

        if(stateName === 'SlaCountTypeId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['SlaCountTypeText']: text,
            }
          }));
        }
        if(stateName === 'CategoryId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['CategoryText']: text,
            }
          }));
        }
        if(stateName === 'ParentOrganizationCategoryId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['ParentOrganizationCategoryText']: text,
            }

          }));
        }

        if(stateName == 'CategoryId' && this.state.template === true){
          this.getTemplate(value.id);
        }

        if(stateName === 'useslatime'){
          if(value && this.state.item.OrganizationSlaId !== 0){
            let result = await Service.getSlaById(this.state.item.OrganizationSlaId);
            // console.log(result);
            this.setState(prevState => ({
              item: {
                ...prevState.item,
                ['servicetime']: result.data.servicetime,
                ['solutiontime']: result.data.solutiontime
              },
              disableFields:true
            }));
          } else {
            this.setState(prevState => ({
              disableFields:false
            }));
          }

        }
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'  onClick={() => {
                    this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 7 }) : browserHistory.goBack()}
                  }>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="edit.item" />}
              <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                  
              <Grid container spacing={2}>
                    <Grid item xs={11} sm={11}>
                      <SimpleSelect label={intl.formatMessage({id:"organization"})} options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={item.OrganizationId} required />
                    </Grid>
                    <Grid item xs={11} sm={11}>
                      <SimpleSelect label={intl.formatMessage({id:"type"})} options={this.state.TypeList} stateName='TypeId' changeSelect={this.changeValues} selected={item.TypeId} isRequired />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.default.called.type"})}/>
                    </Grid>
                    <Grid item xs={11} sm={11}>
                      <SimpleSelect label={intl.formatMessage({id:"priority"})} options={this.state.priorityList} stateName='PriorityId' changeSelect={this.changeValues} selected={item.PriorityId} isRequired />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.default.called.priority"})}/>
                    </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"departament"})} options={this.state.DepartamentList} selected={this.state.DepartamentId} stateName='DepartamentId' changeSelect={this.changeValues} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.departament"})}/>
                  </Grid>
                    <Grid item xs={11} sm={11}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"organization.category.parent"})} options={this.state.ParentOrganizationCategoryList} stateName='ParentOrganizationCategoryId' created={true} selected={item.ParentOrganizationCategoryId} changeSelect={this.changeValues} />
                    </Grid>

                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.category"})}/>
                    </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                      </Grid>
                    <Grid item xs={11} sm={11}>
                      <SimpleSelect label={intl.formatMessage({id:"organization.category.sla"})} options={this.state.OrganizationSlaList} stateName='OrganizationSlaId' changeSelect={this.changeValues} selected={item.OrganizationSlaId} required />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.category.sla"})}/>
                    </Grid>
                    <Grid item xs={11} sm={11}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.category.time.sla"})} name='useslatime' stateName='useslatime' changeSelect={this.changeValues} selected={item.useslatime} />
                    </Grid>
                    <Grid item xs={11} sm={11} style={{display: 'none'}}>
                      <SimpleSelect label={intl.formatMessage({id:"flow"})} options={this.state.CalledFlowList} stateName='CalledFlowId' changeSelect={this.changeValues} selected={item.CalledFlowId} />
                    </Grid>
                    <Grid item xs={11} sm={11}>
                      <SimpleSelect label={intl.formatMessage({id:"organization.category.count.type"})} options={this.state.SlaCountTypeList} stateName='SlaCountTypeId' changeSelect={this.changeValues} selected={item.SlaCountTypeId} />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.category.sla.count"})}/>
                    </Grid>
                    <Grid item xs={11} sm={11}>
                      <TextField fullWidth type='number' label={intl.formatMessage({id:"organization.category.service.sla.min"})} autoComplete='fname' variant='outlined' name='servicetime' disabled={this.state.disableFields} onChange={this.handleInputChange} value={item.servicetime} required />
                    </Grid>
                    <Grid item xs={11} sm={11}>
                      <TextField fullWidth type='number' label={intl.formatMessage({id:"organization.category.solution.sla.min"})} autoComplete='fname' variant='outlined' name='solutiontime' disabled={this.state.disableFields}  onChange={this.handleInputChange} value={item.solutiontime} required />
                    </Grid>
                {!this.state.template && (
                    <Grid item xs={11} sm={11}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.category.create.call"})} name='cancreatecalled' stateName='cancreatecalled' changeSelect={this.changeValues} selected={item.cancreatecalled} />
                    </Grid>)}
                {!this.state.template && (
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.category.import.flag.default"})}/>
                    </Grid>)}
                {this.state.template && (
                    <Grid item xs={11} sm={11}>
                      <SimpleCheckBox disabled label={intl.formatMessage({id:"organization.category.create.call"})} name='cancreatecalled' stateName='cancreatecalled' changeSelect={this.changeValues} selected={item.cancreatecalled} />
                    </Grid>)}
                {this.state.template && (
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.category.import.flag.default"})}/>
                    </Grid>)}
                    <Grid item xs={11} sm={11}>
                      <SimpleCheckBox label={intl.formatMessage({id:"notification"})} name='serviceslanotification' stateName='serviceslanotification' changeSelect={this.changeValues} selected={item.serviceslanotification} />
                    </Grid>
                    <Grid item xs={11} sm={11}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.category.time.standard"})} name='isdefaultcategory' stateName='isdefaultcategory' changeSelect={this.changeValues} selected={item.isdefaultcategory} />
                    </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "organization.category.can.edit.called.title.and.description" })} name='caneditcalledtitleanddescription' stateName='caneditcalledtitleanddescription' changeSelect={this.changeValues} selected={item.caneditcalledtitleanddescription} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox
                    label={intl.formatMessage({id:"apply.changes.to.all.organizations.in.group"})}
                    name='saveToAllOrganizationsInGroup'
                    stateName='saveToAllOrganizationsInGroup' 
                    changeSelect={this.changeValues}
                    selected={item.saveToAllOrganizationsInGroup} />
                  </Grid>
                <Grid item xs={11} sm={11} md={11} lg={11} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}
OrganizationCategoryEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations,
  organizationcategories: state.organizationcategories
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(OrganizationCategoryEdit)));