import React, { Component } from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material";
import Rating from '@mui/material/Rating';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import MaterialTable from 'material-table';
import AddIcon from '@mui/icons-material/Add';
//import MyMaterialTable from '../../components/table/index-report';
import MyMaterialTable from '../../components/table';
import Api from "../../services/api";
import * as calledsActions from '../../redux/actions/calleds';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer';
import moment from "moment";
import * as CalledService from '../../services/called.service';
import * as TechnicalTeamService from '../../services/techinicalteamuser.service';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import { saveAs } from 'file-saver';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@mui/material/Pagination';
import LogoMarca from '../../images/new-logo-cmtech.png';
import html2canvas from 'html2canvas';
import Divider from '@mui/material/Divider'
import * as ExcelJS from 'exceljs'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CallMade from '@mui/icons-material/CallMade';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class CalledClassificationReports extends Component {

    constructor(props) {
        super(props);
        this.Reload = this.Reload.bind(this);
        this.Reload();

        const { userSession } = this.props;
        const intl = this.props.intl;

        this.isClient = true;
        this.isAdm = false;

        if (userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0 || item.role.roletype == 1) {
                this.isAdm = true;
            }
        }
        );
        this.organizationId = null;
        if (userSession.user.organization) {
            this.organizationId = userSession.user.organization.id;
        }

        this.state = {
            dateStart: moment(new Date().setDate(1)).format('YYYY/MM/DD HH:mm:ss'),
            dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
            organizationId: this.organizationId,
            OrganizationFilter: 0,
            location: 0,
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            organizationAddressesList: [],
            showGrid: false,
            calledsList: [],
            organizationList: [],
            userList: [],
            user: 0,
            classificationList: [
                { id: 5, name: intl.formatMessage({id:"classification.very.good"}) },
                { id: 4, name: intl.formatMessage({id:"classification.good"}) },
                { id: 3, name: intl.formatMessage({id:"classification.neutral"}) },
                { id: 2, name: intl.formatMessage({id:"classification.bad"}) },
                { id: 1, name: intl.formatMessage({id:"classification.very.bad"}) },
            ],
            classification: "",
            showHeader: false,
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
        };
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);
        this.exportPdf = this.exportPdf.bind(this);
        this.getExcel = this.getExcel.bind(this)
        this.getUserOptions = this.getUserOptions.bind(this);
        this.renderSatisfactionIcons = this.renderSatisfactionIcons.bind(this);
    }

    setPagination = (count, page) => {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    async changeValues(stateName, value, text = '') {
        this.setState({ loading: true });
        if (stateName === 'location') {
            this.setState({ [stateName]: value.id });
        }
        else if (stateName === 'OrganizationFilter') {
            this.setState({ [stateName]: value.id });
            if (value.id !== 0) {
                await this.getOrganizationAddresses(value.id);
                await this.getUserOptions(value.id);
            }

        } else if (stateName === 'classification') {
            this.setState({ [stateName]: value.id })
        } else if (stateName === 'user') {
            this.setState({ [stateName]: value.id })
        } else {
            this.setState({ [stateName]: value });
        }
        this.setState({ loading: false });
    };

    async exportPdf() {
        const data = this.state.calledsList;
        const intl = this.props.intl;
        await this.setState({ loading: true, showHeader: true });

        const doc = new jsPDF("p", "mm", "a3");

        const header = document.getElementById('header-pdf');
        let headerCanvas = await html2canvas(header);
        const headerImg = headerCanvas.toDataURL('image/png');
        doc.addImage(headerImg, 'PNG', 0, 0);

        var width = doc.internal.pageSize.getWidth();

        doc.setLineWidth(width);
        doc.autoTable({
            body: data,
            margin: { top: 30 },
            bodyStyles: { valign: 'middle' },
            styles: { overflow: 'linebreak', columnWidth: 'wrap', fontSize: 7, halign: 'center' },
            theme: 'striped',
            columns: [
                { header: intl.formatMessage({id:"id"}), dataKey: 'id' },
                { header: intl.formatMessage({ id: "organization" }), dataKey: 'Organization' },
                { header: intl.formatMessage({ id: "title" }), dataKey: 'title' },
                { header: intl.formatMessage({ id: "category" }), dataKey: 'OrganizationCategory' },
                { header: intl.formatMessage({ id: "menu.organizationpatrimonies" }), dataKey: 'patrimonies' },
                { header: intl.formatMessage({id:"menu.statuses"}), dataKey: 'statusName' },
                { header: intl.formatMessage({ id: "sla.service" }), dataKey: 'slaAtendimento' },
                { header: intl.formatMessage({ id: "sla.solution" }), dataKey: 'slaSolucao' },
                { header: intl.formatMessage({ id: "requestuser" }), dataKey: 'requestingUser' },
                { header: intl.formatMessage({ id: "location" }), dataKey: 'addressname' },
                { header: intl.formatMessage({ id: "open.date" }), dataKey: 'opening' },
                { header: intl.formatMessage({ id: "assigned" }), dataKey: 'assignedUser' },
                { header: intl.formatMessage({ id: "classification" }), dataKey: 'classificationPdf' },
                { header: intl.formatMessage({ id: "wasbaduse" }), dataKey: 'baduse' },
            ],

            columnStyles: {
                id: {
                    columnWidth: 9
                },
                Organization: {
                    columnWidth: 18,
                },
                title: {
                    columnWidth: 16,
                },
                OrganizationCategory: {
                    columnWidth: 17,
                },
                patrimonies: {
                    columnWidth: 18,
                },
                statusName: {
                    columnWidth: 15,
                },
                slaAtendimento: {
                    columnWidth: 15,
                },
                slaSolucao: {
                    columnWidth: 15,
                },
                requestingUser: {
                    columnWidth: 16,
                },
                addressname: {
                    columnWidth: 15,
                },
                opening: {
                    columnWidth: 13,
                },
                assignedUser: {
                    columnWidth: 13,
                },
                time: {
                    columnWidth: 15,
                },
                classificationPdf: {
                    columnWidth: 12,
                },
                baduse: {
                    columnWidth: 12,
                },

            },
        })
        doc.save("relatorio-classificação-de-chamados.pdf");
        await this.setState({ loading: false, showHeader: false });

    }

    getMessageBadUseFlag(message) {
        let writing = ""
        if (message === true) {
            writing = this.props.intl.formatMessage({id:"yes"})
        }
        else if (message === false) {
            writing = this.props.intl.formatMessage({id:"no"})
        }
        else {
            writing = this.props.intl.formatMessage({id:"undefined"})
        }

        return writing
    }

    async getOrganizationAddresses(organizationId) {
        this.setState({ loading: true });

        const result = await CalledService.getOrganizationAddresses(organizationId);

        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1) {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: this.props.intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.address"})
                    });
                }
            }

            this.setState({ loading: false, organizationAddressesList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    async getUserOptions(organizationId) {
        const intl = this.props.intl;
        this.setState({ loading: true });

        const result = await CalledService.getTechnicalUsersByOrganizationId(organizationId);

        if (result.success) {
            if (result.data.length < 1) {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.classification.report.no.technicians"})
                });
                return
            }
            this.setState({ loading: false, userList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };


    Reload = () => {
        const reload = localStorage.getItem('reload');
        if (reload == "true") {
            localStorage.setItem('reload', false);
        }
        return null;
    }

    async getOrganizations() {
        let data = [];
        const { userSession } = this.props
        if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
            data = userSession.user.userorganizations
        } else {
            await Api.get('/organizations/tech')
                .then(result => {
                    data = result.data.data
                })
                .catch(err => {
                    data = err;
                });
        }
        this.setState({ organizationList: data })
    };

    async componentDidMount() {
        this.setState({ loading: true })
        await this.getOrganizations();
        this.setState({ loading: false })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async getExcel() {
        const intl = this.props.intl;
        const wb = new ExcelJS.Workbook()

        const ws = wb.addWorksheet()

        ws.columns = [
            { header: intl.formatMessage({id:"id"}), key: 'id', width: 25 },
            { header: intl.formatMessage({ id: "organization" }), key: 'Organization', width: 25 },
            { header: intl.formatMessage({ id: "title" }), key: 'title', width: 25 },
            { header: intl.formatMessage({ id: "category" }), key: 'OrganizationCategory', width: 35 },
            { header: intl.formatMessage({ id: "menu.organizationpatrimonies" }), key: 'patrimonies', width: 25 },
            { header: intl.formatMessage({id:"menu.statuses"}), key: 'statusName', width: 25 },
            { header: intl.formatMessage({ id: "sla.service" }), key: 'slaAtendimento', width: 25 },
            { header: intl.formatMessage({ id: "sla.solution" }), key: 'slaSolucao', width: 25 },
            { header: intl.formatMessage({ id: "requestuser" }), key: 'requestingUser', width: 25 },
            { header: intl.formatMessage({ id: "location" }), key: 'addressname', width: 25 },
            { header: intl.formatMessage({ id: "open.date" }), key: 'opening', width: 25 },
            { header: intl.formatMessage({ id: "assigned" }), key: 'assignedUser', width: 25 },
            { header: intl.formatMessage({ id: "classification" }), key: 'classificationPdf', width: 25 },
            { header: intl.formatMessage({ id: "wasbaduse" }), key: 'baduse', width: 25 },
        ];

        ws.getCell('A1').style = { font: { bold: true } }
        ws.getCell('B1').style = { font: { bold: true } }
        ws.getCell('C1').style = { font: { bold: true } }
        ws.getCell('D1').style = { font: { bold: true } }
        ws.getCell('E1').style = { font: { bold: true } }
        ws.getCell('F1').style = { font: { bold: true } }
        ws.getCell('G1').style = { font: { bold: true } }
        ws.getCell('H1').style = { font: { bold: true } }
        ws.getCell('I1').style = { font: { bold: true } }
        ws.getCell('J1').style = { font: { bold: true } }
        ws.getCell('K1').style = { font: { bold: true } }
        ws.getCell('L1').style = { font: { bold: true } }
        ws.getCell('M1').style = { font: { bold: true } }
        ws.getCell('N1').style = { font: { bold: true } }
        ws.getCell('O1').style = { font: { bold: true } }

        ws.addRows(this.state.calledsList);

        const buf = await wb.xlsx.writeBuffer()

        saveAs(new Blob([buf]), 'relatorio_chamados.xlsx')
    }

    handleSubmit = async e => {
        const intl = this.props.intl;
        e.preventDefault();

        this.setState({ loading: true });

        var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
        var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')
        if (dateStart > dateEnd) {

            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
                loading: false
            });
        }

        else {
            const request = {
                dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'), // string
                dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'), // string
                organization: this.state.OrganizationFilter, // int
                location: this.state.location, // int
                user: this.state.user, // int
                classification: this.state.classification, // string
                page: this.state.page
            }

            const response = await CalledService.getCalledsByFiltering(request);

            if (response.success) {
                //console.log(response.data)
                let count = response.count;
                this.setState({ count: count });
                this.setPagination(count, this.state.page);

                const formattedList = response.data.map(item => {

                    let times = "";

                    if (item.organizationcategory.techinicalteamorganizationcategories != null) {
                        item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
                            times = times + item2.techinicalteam.name + ","
                        });
                        times = times.substring(0, times.length - 1);
                    }

                    const patrimonyList = item.calledorganizationpatrimonies;

                    let patrimonies = '';
                    if (patrimonyList) {
                        patrimonyList.forEach(item => {
                            if (item.organizationpatrimony) {
                                patrimonies = patrimonies + `${item.organizationpatrimony.tipping}(${item.organizationpatrimony.serialnumber}), `;
                            }
                        });
                        patrimonies = patrimonies.slice(0, -2);
                    }

                    let writing = ""
                    //console.log(item.baduse)
                    writing = this.getMessageBadUseFlag(item.baduse)

                    return {
                        ...item,
                        baduse: writing,
                        statusName: item.status.name,
                        patrimonies,
                        OrganizationCategory: item.organizationcategory.parentname,
                        Organization: item.organization.name,
                        slaAtendimento: getSlaAtendimento(item, this.props.userSession) + "%",
                        slaSolucao: getSlaSolucao(item, this.props.userSession) + "%",
                        assignedUser: item.assigneduser ? item.assigneduser.name : "",
                        requestingUser: item.requestinguser ? item.requestinguser.name : "",
                        time: times,
                        addressname: item.address.name,
                        opening: moment(item.openingdate).format("DD/MM/YYYY"),
                        classification: item.calledclassification ? <Rating name="read-only" value={item.calledclassification.noteassigned} readOnly /> : 'N/A',
                        classificationPdf: item.calledclassification ? item.calledclassification.noteassigned : 'N/A'
                    }
                });

                this.setState({
                    calledsList: formattedList,
                    loading: false,
                    showGrid: true
                })
            }

            this.setState({ loading: false });
        }


    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    renderSatisfactionIcons(option) {
        const intl = this.props.intl;
        switch (option) {
            case intl.formatMessage({id:"dashboard.report.components.pizza.detractor"}):
                return <SentimentVeryDissatisfiedIcon style={{ color: '#E74C3C', textAlign: 'center' }} />
            case intl.formatMessage({id:"dashboard.report.components.pizza.neutral"}):
                return <SentimentSatisfiedAltIcon style={{ color: '#00C49F', textAlign: 'center' }} />
            case intl.formatMessage({id:"dashboard.report.components.pizza.promoter"}):
                return <SentimentSatisfiedIcon style={{ color: '#FFBB28', textAlign: 'center' }} />
        }
    }

    onChangePaginate = async (e, page) => {
        await this.setState({ page: page });
        this.handleSubmit(e);
    }

    getById = async (id, newTab = false) => {
        let url = `/calleds/calledInfoPaginate/${id}`;
        let data = {}

        await this.setState({ loading: true });

        Api.get(url)
            .then(async result => {
                if (result.data.success) {
                    let item = result.data.data
                    let times = "";
                    /*if (item.organizationcategory.techinicalteamorganizationcategories != null) {
                        item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
                            times = times + item2.techinicalteam.name + ","
                        });
                        times = times.substring(0, times.length - 1);
                    }*/
                    data = {
                        ...item,
                        typeName: item.type.name,
                        originRequestName: item.originrequest.name,
                        //statusName: item.status.name,
                        //OrganizationCategory: item.organizationcategory.parentname,
                        //Organization: item.organization.name,
                        //slaAtendimento: getSlaAtendimento(item, this.props.userSession) + "%",
                        //slaSolucao: getSlaSolucao(item, this.props.userSession) + "%",
                        //assignedUser: item.assigneduser ? item.assigneduser.name : "",
                        requestingUser: item.requestinguser ? item.requestinguser.name : "",
                        time: times,
                        //addressname: item.address.name,
                        opening: moment(item.openingdate).format("DD/MM/YYYY"),
                        //classification: item.calledclassification
                    }
                    
                    await this.props.addCurrentCalled(data);
                    await this.props.addAccompanimentsList(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : null);

                    await this.props.addCalled(data);
                    await this.props.addAccompanimentsListInCalled(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : [], data.id);

                    let urlRedirect = "/" + this.props.match.params.lang + `/called-accompaniment/${data.id}`;

                    await setTimeout(() => {
                        window.open(urlRedirect, "_blank");
                        this.setState({ loading: false })
                    }, 3000);
                }
                else {
                    // console.log("Aqui 3");
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    // console.log(result);
                    Api.kickoff(result);
                }
            })
            .catch((err) => {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        err.response &&
                            err.response.data &&
                            err.response.data.errors &&
                            err.response.data.errors[0]
                            ? err.response.data.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                });
                Api.kickoff(err);
            });
    };

    render() {
        const { classes, userSession, headerMenu } = this.props;

        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        const tableList = () => {
            return (
                <div>
                    {this.state.showHeader &&
                        <Grid container style={{ zIndex: -2000, position: "absolute" }}>
                            <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{ padding: '2px' }} style={{ minWidth: 600 }}>
                                <Card>
                                    <CardContent>
                                        <img src={LogoMarca} style={{ width: "70px" }} /> <span style={{ marginLeft: 25, fontSize: 18 }}> {<FormattedMessage id="calledClassification.report.header" />}</span>
                                        <Divider />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    }
                    <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{intl.formatMessage({id:"filters"})}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container style={{ marginBottom: 10 }} spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3} >
                                        <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} required />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3} >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.organizationList} stateName='OrganizationFilter' changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3} >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "location" })} options={this.state.organizationAddressesList} stateName='location' changeSelect={this.changeValues} />
                                    </Grid>


                                    <Grid item xs={12} sm={6} md={3}  >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "user" })} options={this.state.userList} stateName='user' changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleAutoComplete
                                            label={intl.formatMessage({ id: "classification" })}
                                            options={this.state.classificationList}
                                            stateName='classification'
                                            changeSelect={this.changeValues}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Grid container style={{ marginBottom: 10 }}>
                            <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                >
                                    {<FormattedMessage id="filter" />}
                                </Button>
                            </Grid>
                            {this.state.showGrid && this.state.calledsList.length > 0 && <Grid item xs={12} sm={5} md={2} style={{ marginTop: 10, marginRight: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    onClick={this.getExcel}
                                >
                                    {<FormattedMessage id="export.plan" />}
                                </Button>
                            </Grid>
                            }
                            {this.state.showGrid && this.state.calledsList.length > 0 && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.exportPdf}
                                >
                                    {<FormattedMessage id="dash.pdf" />}
                                </Button>
                            </Grid>
                            }
                        </Grid>
                    </form>
                    {
                        this.state.showGrid &&
                        <>
                            <MyMaterialTable
                                title=""
                                columns={[
                                    { title: intl.formatMessage({id:"id"}), field: 'id' }, { title: intl.formatMessage({ id: "organization" }), field: 'Organization' },
                                    { title: intl.formatMessage({ id: "title" }), field: 'title' }, { title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' },
                                    { title: intl.formatMessage({id:"menu.organizationpatrimonies"}), field: 'patrimonies' }, { title: intl.formatMessage({id:"menu.statuses"}), field: 'statusName' },
                                    { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' }, { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' },
                                    { title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' }, { title: intl.formatMessage({ id: "location" }), field: 'addressname' },
                                    { title: intl.formatMessage({ id: "open.date" }), field: 'opening' }, { title: intl.formatMessage({ id: "assigned" }), field: 'assignedUser' },
                                    { title: intl.formatMessage({id:"classification"}), field: 'classification' }, { title: intl.formatMessage({ id: "wasbaduse" }), field: 'baduse' }
                                ]}
                                data={this.state.calledsList}
                                action={[
                                    {
                                        icon: () => <CallMade />,
                                        tooltip: intl.formatMessage({id:"new.tab"}),
                                        onClick: (event, rowData) => this.getById(rowData.id, true)
                                    },
                                ]}
                            />

                            <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                <Grid item xs={12} md={3}>
                                    <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                </Grid>
                            </Grid>
                        </>
                    }
                </div>
            );
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />
                    <Container component='main' maxWidth='xl' style={{ paddingLeft: 0, marginLeft: 0 }}>
                        <CssBaseline />
                        <Grid container className={classes.mb20} spacing={0}>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>
                                <Card >
                                    <CardContent>
                                        <Typography variant="h5">
                                            {<FormattedMessage id="calledClassification.report.header" />}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Can I="List" a="Called">
                            {tableList()}
                        </Can>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}
                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}

CalledClassificationReports.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledClassificationReports)));
CalledClassificationReports.contextType = AbilityContext;