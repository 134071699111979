import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import CustomizedSnackbars from '../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../../helpers/history';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../../components/select/simple';
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../../components/title-name';
import * as Service from '../../../services/organizationdepartament.service';

class OrganizationDepartamentRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession, organizationid } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    this.arrayLenght = 0;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
     OrganizationId:  organizationid,
     id:0,
     updatedUser : 0,
     OrganizationText: '',
     ParentOrganizationDepartamentId:  0,

     ParentOrganizationDepartamentText: '',
     name : '', 
     parentname : '', 

     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      ParentOrganizationDepartamentList: [],

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentDidMount() {
        this.getOrganizations();
        this.getDados();
        //this.getParentOrganizationDepartaments();
     };
     async getOrganizations(){
          this.setState({ loading: true });
          var result = await Service.getOrganizations();
            if (result.success) {
              this.setState({ loading: false, OrganizationList: result.data });
                            
            } else {
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };     

  async getDados(){
    this.setState({ loading: true });
    var result = await Service.getOrganizationsDepartaments(this.props.organizationid);
      if (result.success) {
        this.arrayLenght = result.data.length;
        if(result.data.length > 0){
          
          this.setState({
            template: false
          });
          let resultData = result.data[0];
          if(resultData.parentorganizationdepartament!= null)
          {
            this.setState(prevState => ({
              item: {
                ...prevState.item,
                id: resultData.id,
                updatedUser : resultData.id,
                ['name']: resultData.name,
                ['ParentOrganizationDepartamentId']:resultData.parentorganizationdepartament,
            
              }
            }));
          }
          else
          {
            this.setState(prevState => ({
              item: {
                ...prevState.item,
                ['name']: resultData.name,
                ['id']: resultData.id,
                ['updatedUser'] : resultData.id,
                ['ParentOrganizationDepartamentId']:0,
            
              }
            }));
          }
          
        }
        
        this.setState({ loading: false });
                      
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
};
  
  async getParentOrganizationDepartaments(orgId){
          this.setState({ loading: true });
          var result = await Service.getParentOrganizationDepartaments(orgId);
            if (result.success) {

                var parentResult = [];
                result.data.map(item => {
                  var parent = {id: item.id, name: item.parentname ? item.parentname : item.name}
                  parentResult.push(parent);
                })

              this.setState({ loading: false, ParentOrganizationDepartamentList: parentResult});
                            
            } else {
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { intl } = this.props;

    if(!this.state.item.OrganizationId) {
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({ id: "organization.required.message" })
      });
      
      return
    } else if (this.state.item.name.length > 255) {
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({ id: "organization.required.message" })
      });
      return
    }

    this.setState({ loading: true });

    let data = new FormData();
    

      if (this.isClient) {
        if(this.state.item)
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }

     


      for (let key in this.state.item) {
        
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
      
        } else if (key.toString().toLowerCase().indexOf('parentorganizationdepartamentid') > -1 && this.state.item[key] != "") {
          data.append(key, this.state.item[key].id);
        }else {
          data.append(key, this.state.item[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      if(this.arrayLenght > 0)
      {
        Api.put('/organizationdepartaments', data, config)
        .then(result => {
          const intl = this.props.intl;
          if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                item: this.initialState,
                files: []
              });

              this.props.next();

          }
          else{

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);

          }
        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
          }
          Api.kickoff(err);

        });
      }
      else
      {
        Api.post('/organizationdepartaments', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });
            this.getOrganizations();
            this.getParentOrganizationDepartaments(result.data.OrganizationId);
            this.props.handleComplete();
            this.props.handleNextBarProgress();


          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });
      }

       
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));

    if(stateName === 'OrganizationId'){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['OrganizationText']: text,
        }
      }));
      this.getParentOrganizationDepartaments(value);
    }

    if(stateName === 'ParentOrganizationDepartamentId'){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['ParentOrganizationDepartamentText']: text,
        }
      }));
    }
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
     return (
      <div className={classes.root}>
        
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />} 
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect disabled label={intl.formatMessage({id:"organization"})} options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={item.OrganizationId} required={ true }/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"organization.departament.parent"})} created={true} options={this.state.ParentOrganizationDepartamentList} stateName='ParentOrganizationDepartamentId' changeSelect={this.changeValues} selected={item.ParentOrganizationDepartamentId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                    </Grid>
                </Grid>

                <Grid container spacing={0}  style={{marginBottom: '60px', display: 'flex', marginTop:'20px'}}>
                
                <Grid item xs={12} sm={2} >
                      {this.props.backButton}


                  </Grid>

                  <Grid item xs={12} sm={2} >
                  {this.props.nextButton} 


                  </Grid>
                  <Grid item xs={12} sm={6} style={{marginLeft:'auto'}}>
                        <Button 
                          
              
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                         
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                  </Grid>
              
            
             </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        
      </div>
    );
  }
}
OrganizationDepartamentRegistration.propTypes = {
                    classes: PropTypes.object.isRequired,
                    organizationid: PropTypes.string,
                    back: PropTypes.func,
                    next: PropTypes.func
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationDepartamentRegistration)));
