import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleTreeView from '../../components/tree-view/tree';
import SimpleCheckBox from '../../components/checkbox/check';
import * as Service from '../../services/category.service';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';

class CategoryRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      parentCategoryId: 0,
      parentCategoryText: '',
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      name: "",
      cancreatecalled: false,
      imageicon: "",
    };

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      categoriesList: [],
      
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
    this.getCategories();
  };

  async getCategories() {
    this.setState({ loading: true });

    var result = await Service.getAllCategories();

    if (result.success) {
        this.setState({ loading: false, categoriesListAll: result.data });
    } else {
      const intl = this.props.intl;
        this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
    }
    
    result = await Service.getCategories();

    if (result.success) {
      this.setState({ loading: false, categoriesList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
    }
  };


  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }

      this.state.files.forEach(file => data.append('filesUpload', file));

      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss"));
        } else {
          data.append(key, this.state.item[key]);
        }
      }
      //console.log(data);
      //alert(JSON.stringify(data));
      //return false;    

      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      Api.post('/categories', data, config)
        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: "Cadastrado com sucesso!",
              item: this.initialState,
              files: []
            });
            this.getCategories();
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);

          }          
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"process.error"})
            });
          }
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
    if(stateName === "parentCategoryId"){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ["parentCategoryText"]: text,
        }
      }));
    }
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  

  render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    if(this.state.categoriesListAll){
      var categoriesId="'0',"+this.state.categoriesListAll.map(item => `'${item.id}'`).join(",");  
    }
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant="outlined" color="primary" onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
              {<FormattedMessage id="add.item" />}
              <Title />
              </Typography>
              <form name="myForm" className={classes.form} onSubmit={this.handleSubmit} encType="multipart/form-data">
                <Grid container spacing={2}>
                {!this.isClient && (<>
                    <Grid item xs={12} sm={12}>
                        <SimpleTreeView rootId={categoriesId} allowSelect="true" label={intl.formatMessage({id:"menu.categories"})} parentLabel={intl.formatMessage({id:"select.category.parent"})} options={this.state.categoriesList} stateName="parentCategoryId" changeSelect={this.changeValues} selected={item.parentCategoryId} selectedText={item.parentCategoryText} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                            <TextField
                              name="categoria"
                              variant="outlined"
                              fullWidth
                              id="categoria"
                              label={intl.formatMessage({id:"select.category.above"})}
                              value={item.parentCategoryText}
                              required
                              readyonly="true"
                              disabled
                            />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="fname"
                        name="name"
                        variant="outlined"
                        fullWidth
                        id="name"
                        label={intl.formatMessage({id:"name"})}
                        value={item.name}
                        onChange={this.handleInputChange}
                        required
                      />
                    </Grid>
                </>)}
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox label={intl.formatMessage({id:"organization.category.create.call"})}  stateName="cancreatecalled" changeSelect={this.changeValues} selected={item.cancreatecalled} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <section style={{ width: '100%' }}>
                      <div className={classes.dropzoneArea}>
                        <Dropzone onDrop={acceptedFiles => { this.setState({ files: acceptedFiles }) }}>
                          {({ getRootProps, getInputProps, acceptedFiles }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>{<FormattedMessage id="drag.and.drop" />}</p>
                            </div>
                          )}
                        </Dropzone>
                        {this.state.files.map(file => {
                          return (
                            <div className={classes.dropzoneItem} key={file.name}>
                              {file.name}
                              <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                            </div>
                          )
                        })}
                      </div>
                    </section>
                </Grid>
                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
     </div>
    );
  }
}

CategoryRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CategoryRegistration)));
