import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'; import SimpleSelect from '../../components/select/simple';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleDatePicker from '../../components/date-pickers/inline';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import * as Service from '../../services/organizationfield.service';
import * as componentActions from '../../redux/actions/organizationfields';
import { FormattedMessage, injectIntl } from 'react-intl';

class OrganizationFieldEdit extends Component {

    constructor(props) {
        super(props);
        //  console.log(props);
        const { userSession, organizationfields, organizations } = this.props;

        this.fromProps = false
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
            this.fromProps = true

        this.globalAdm = false;
        this.isClient = true;
        if (userSession.user.userrole == null) {
            Api.logoff();
        }

        if(!organizationfields || (organizationfields && !organizationfields.organizationfield)) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/calleds`
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0) {
                this.globalAdm = true;
            }
        });

        //console.log(moment(organizationfields.organizationfield.starttime).format('YYYY/MM/DD HH:mm:ss'));
        let apptargetlist = [ { id: 0, name: 'Todos' }, { id: 1, name: '2DO' }, { id: 2, name: '2MOVE' }]

        this.initialState = {
            UpdatedUser: userSession.user.id,
            id: organizationfields.organizationfield.id,
            OrganizationId: organizationfields.organizationfield.OrganizationId,
            OrganizationText: organizationfields.organizationfield.OrganizationText,
            name: organizationfields.organizationfield.name == null ? '' : organizationfields.organizationfield.name,
            active: organizationfields.organizationfield.active,
            isrequired: organizationfields.organizationfield.isrequired,
            customeredit: organizationfields.organizationfield.customeredit,
            saveToAllOrganizationsInGroup: false,

            organizationfieldapptarget: organizationfields.organizationfield.organizationfieldapptarget
        };

        this.state = {
            organizationfield: this.initialState,
            organizations: organizations,
            files: [],
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            OrganizationList: [],

            apptargetlist: apptargetlist
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state.internal === true) {
            this.getOrganizationsById(this.state.organizations.organization.id);
        }
        else {
            this.getOrganizations();
        }
    };
    async getOrganizations() {
        const intl = this.props.intl;
        this.setState({ loading: true });
        var result = await Service.getOrganizations();
        if (result.success) {
            this.setState({ loading: false, OrganizationList: result.data });

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };
    async getOrganizationsById(id) {
        const intl = this.props.intl;
        this.setState({ loading: true });
        var result = await Service.getOrganizationsByIdLight(id);
        if (result.success) {
            this.setState({ loading: false, OrganizationList: [result.data] });

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };
    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            organizationfield: {
                ...prevState.organizationfield,
                [name]: value
            }
        }));
    };

    handleSubmit = async e => {
        e.preventDefault();

        this.setState({ loading: true });

        let data = new FormData();


        if (this.isClient) {
            this.setState(prevState => ({
                organizationfield: {
                    ...prevState.organizationfield,
                }
            }));
        } else {
            this.setState(prevState => ({
                organizationfield: {
                    ...prevState.organizationfield,
                }
            }));
        }


        for (let key in this.state.organizationfield) {
            if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1 || key.toString().toLowerCase().indexOf('eventdate') > -1) {
                data.append(key, moment(this.state.organizationfield[key]).format('YYYY/MM/DD HH:mm:ss'));
            } 
          
            else if (key.toString().toLowerCase().indexOf('organizationfieldapptarget') > -1) {
                data.append(key, this.state.organizationfield[key]);
              }              
            
            else {
                data.append(key, this.state.organizationfield[key]);
            }
        }

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        Api.put('/organizationfields', data, config)

            .then(result => {
                const intl = this.props.intl;
                if (result.data.success) {
                    const formattedList = result.data.data;
                    formattedList.OrganizationId = formattedList.organization ? formattedList.organization.id : 0;
                    formattedList.OrganizationText = formattedList.organization ? formattedList.organization.name : '';

                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: this.props.intl.formatMessage({id:"edit.success"}),
                        files: []
                    });
                    this.props.addCurrentOrganization(formattedList, this.state.organizations.organization);
                    this.componentDidMount();

                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });

                }
            })
            .catch(err => {
                const intl = this.props.intl;
                if (err.response && err.response.data && err.response.data.errors) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"}) + err.response.data
                    });
                }
            });
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    changeValues(stateName, value, text = '') {
        this.setState({ loading: true });

        this.setState(prevState => ({
            organizationfield: {
                ...prevState.organizationfield,
                [stateName]: value
            }
        }));
        if (stateName === 'OrganizationId') {
            this.setState(prevState => ({
                organizationfield: {
                    ...prevState.organizationfield,
                    ['OrganizationText']: text,
                }
            }));
        }

        this.setState({ loading: false });

    };

    removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }

    render() {
        const { classes, headerMenu, organizationfields } = this.props;
        const { organizationfield } = this.state;
        const intl = this.props.intl;

        if(Api == null) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/unavailable`
          }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={() => {
                                        this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 4 }) : browserHistory.goBack()}
                                    }>{this.props.intl.formatMessage({id:"back"})}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="edit.item" />}
                                <Title />
                            </Typography>
                            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={this.props.intl.formatMessage({id:"organization"})+':'} name='organizationid' options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={organizationfield.OrganizationId} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={organizationfield.name} required />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={intl.formatMessage({ id: "organizationfield.app.target" })} options={this.state.apptargetlist} stateName='organizationfieldapptarget' changeSelect={this.changeValues} selected={organizationfield.organizationfieldapptarget} required />
                                    </Grid> 

                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={this.props.intl.formatMessage({id:"required"})} name='isrequired' stateName='isrequired' changeSelect={this.changeValues} selected={organizationfield.isrequired} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={intl.formatMessage({ id: "customer.edit" })} name='customeredit' stateName='customeredit' changeSelect={this.changeValues} selected={organizationfield.customeredit} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={this.props.intl.formatMessage({id:"active"})} name='active' stateName='active' changeSelect={this.changeValues} selected={organizationfield.active} />
                                    </Grid>
                                    <Grid item xs={11} sm={11}>
                                        <SimpleCheckBox
                                        label={intl.formatMessage({id:"apply.changes.to.all.organizations.in.group"})}
                                        name='saveToAllOrganizationsInGroup'
                                        stateName='saveToAllOrganizationsInGroup' 
                                        changeSelect={this.changeValues}
                                        selected={organizationfield.saveToAllOrganizationsInGroup} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                        <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                            <Grid item xs={12} sm={4}    >
                                                <Button 
                                                type='submit'
                                                fullWidth
                                                variant='contained'
                                                color='primary'
                                                className={classes.submit}
                                                >
                                                {<FormattedMessage id="save" />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}
OrganizationFieldEdit.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
    organizationfields: state.organizationfields
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationFieldEdit)));
