import React, { Component, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DraftsIcon from '@mui/icons-material/Drafts';
import { ExitToApp, Assignment } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import * as userSessionActions from '../../redux/actions/user-session';
import { useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import If from './../conditionals/if'
import Api from '../../services/api';
import BookIcon from '@mui/icons-material/Book';
import { Overlay } from "../../styles/global";
import { CircularProgress } from "@mui/material";

const UserRoles = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const intl = props.intl;
    let history = useHistory();
    //console.log(props.user.userrole);
    //console.log(props.class);
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    let sortedList = props.user.userrole.map(
        (item, index) => <MenuItem key={index} onClick={handleClose}>{item.role.name}</MenuItem>);  

    const logOut = () => {
      setLoading(true)
      handleClose();
      userSessionActions.removeUserSession();

      let language = window.location.pathname.split('/')[1]
      if (language != "pt" && language != "es" && language != "en")
        language = "pt"

      Api.get("/users/removeClaims")
        .then(result => {
          if(Api && Api.resetToken){Api.resetToken();}
          history.push(`/${language}/`)
        })
        .catch(err => {
	      if(Api && Api.resetToken){Api.resetToken();}
          history.push(`/${language}/`)
        });
    };

    const handleOpenWiki = () => {
      window.open(`https://wiki.mexx.com.br/en/1-In%C3%ADcio/home`, '_blank');
    };

    const [displayMyPlan, setDisplayMyPlan] = React.useState(false);
    useEffect(() => {
      props.user.userrole.map(item => {
        if(item.role.roletype == 0)
          setDisplayMyPlan(true)
      })
    },[])

    return (
        <div>
          {loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}
          <Button color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} inline="true">
            <Typography variant="h6" className={props.class}>
              <div className={props.class} style={{ color:'#ffffff' }}>
                {props.user.name}
              </div>
            </Typography>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem key={-1} onClick={handleClose}>
              <ListItemIcon>
                <DraftsIcon fontSize="default" />
              </ListItemIcon>              
              {props.user.email}
            </MenuItem>
            <MenuItem key={0} onClick={handleClose}>
              <Typography variant="inherit">                  
              {intl.formatMessage({id:"profile.text"})} { props.user.userrole.map(item => `${item.role.name}`).join(', ')}
                </Typography>
            </MenuItem>

            <MenuItem onClick={handleOpenWiki} >
              <BookIcon /> 
              <span style={{ marginLeft: '10px' }}>Wiki Mexx</span>
            </MenuItem>
            
            <If test={ displayMyPlan }>
              <MenuItem key={1} onClick={handleClose} component={Link} {...{ to: `/${props.match.params.lang}/my-plan` }}>
                <Assignment /> <span style={{ marginLeft: '10px' }}>{ intl.formatMessage({id:"my.plan"}) }</span>
              </MenuItem>
            </If>
            <MenuItem onClick={logOut}><ExitToApp /> <span style={{ marginLeft: '10px' }}>Logout</span></MenuItem>

          </Menu>
        </div>
      );

}


class UserInfo extends Component {
    componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
     
    }
    constructor(props) {
        super(props);
    }
    render() {
        const { classes, userSession, history, location, match } = this.props;
        return (
            <div >
                <UserRoles
                  intl={this.props.intl}
                  user={userSession.user}
                  class={classes.title}
                  history={ history }
                  location={ location }
                  match={ match } />
            </div>
        );
    }
  }

  const mapStateToProps = state => ({
    userSession: state.userSession
  });

  const mapDispatchToProps = dispatch =>
  bindActionCreators(userSessionActions, dispatch);
  
  export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UserInfo))));
  