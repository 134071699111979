import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    
    setOpen(true);
    //console.log(props.userListForDelete)

    /*if(!(props.userListForDelete && props.userListForDelete.length > 0) ){

      
      if(props.openErrorNotification != null){
        props.openErrorNotification()
      }
      

    }*/

    
  };

  const handleClose = () => {
    setOpen(false);
    props.handleConfirmation();
  };

  const handleConfirmation = () => {
    setOpen(false);
    props.handleConfirmation();
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={() => props.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.msg}</DialogTitle>
        
        <DialogActions>
          <Button onClick={() => props.handleClose()} color="primary">
            {props.msgDisagree}
          </Button>
          <Button onClick={handleConfirmation} color="primary" autoFocus>
            {props.msgAgree}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}