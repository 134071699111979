import React, { Component } from 'react'; 
                import clsx from 'clsx';
                import { connect } from 'react-redux';
                import { bindActionCreators } from 'redux';
                import PropTypes from 'prop-types';
                import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import { Overlay } from '../../styles/global';
                import CustomizedSnackbars from '../../components/material-snackbars';
                import HeaderMenu from '../../components/header-menu';
                import AddIcon from '@mui/icons-material/Add';
                import { Link } from 'react-router-dom';
                import Api from '../../services/api';
                import MyMaterialTable from '../../components/table';
                import { Can } from '../../config/ability-context'
                import { AbilityContext } from '../../config/ability-context'
                import Footer from '../../components/footer';
                import { FormattedMessage,injectIntl } from 'react-intl';
                import Card from '@mui/material/Card';
                import CardContent from '@mui/material/CardContent';
                import Typography from '@mui/material/Typography';
                import Pagination from '@mui/material/Pagination';  
                import Search from '@mui/icons-material/Search';
                import TextField from '@mui/material/TextField';
                import ClearIcon from '@mui/icons-material/Clear';
                import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
                import FirstElement from "../../components/first-element";

                import * as componentActions from '../../redux/actions/groups';
                    class Groups extends Component {

                    constructor(props) {
                        super(props);

                        this.typingTimeout = null;
                        this.emptyArray = null;

                        const { userSession } = this.props;
                        this.state = {
                            dataFilter: false,
                            loading: false,
                            openNotification: false,
                            notificationVariant: 'error',
                            notificationMessage: '',
                            resultList: [],

                            totalPages:0,
                            page:1,
                            pageStart:0,
                            pageEnd:0,
                            count:0,
                            searchValue: ''
                        };

                        this.callSearch = this.callSearch.bind(this);
                        this.onFieldChange = this.onFieldChange.bind(this);

                        this.closeNotification = this.closeNotification.bind(this);
                        this.deleteItem = this.deleteItem.bind(this);
                        this.clearFields = this.clearFields.bind(this);
                    }

                    onChangePaginate = (e,page) =>{
                        this.setState({ page:page });
                        this.getItens(page);
                    }
                
                    setPagination(count,page){
                                            
                                            let totalPage = Math.ceil (count/10);
                                            this.setState({ totalPages:totalPage })
                                            if(count > 0){
                                                if(page == 1){
                                                    if(count < 10){
                                                        this.setState({pageStart:1,pageEnd:count});
                                                    } else{
                                                        this.setState({pageStart:1,pageEnd:10});
                                                    }
                                                } else{
                                                    let pageStart = ((page - 1) * 10) + 1;
                                                    
                                                    
                                                    
                                                    if(count >= page * 10){
                                                        this.setState({pageStart:pageStart,pageEnd:page * 10});
                                                    } else{
                                                        this.setState({pageStart:pageStart,pageEnd:count});
                                                    }
                                                }
                                            } else {
                                                this.setState({totalPages:0,page:1,count:0});
                                            }
                                            
                    }
                
                    onFieldChange(event) {
    
        
                        this.setState({ [event.target.name]: event.target.value, page:1 });
                
                    }
                
                    callSearch(){
                        if(this.state.searchValue != ''){
                            this.getItens(this.state.page);
                        }                        
                    }
                
                    async clearFields(){
                        await   this.setState({searchValue: '', page:1, loading: true})
                        this.getItens(1);
                    }

                    deleteItem(data, resolve) {
                        const intl = this.props.intl;
                        let abl = this.context.can('Delete', 'Group');
                        if(abl == false){
                            this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
                            resolve(); return false;
                        }
                
                        Api.delete(`/groups/${data.id}`)
    
                            .then(result => {
                                const intl = this.props.intl;
                                if (result.data.success) {
                                    this.setState({
                                        loading: false,
                                        openNotification: true,
                                        notificationVariant: 'success',
                                        notificationMessage: intl.formatMessage({id:"delete.sucess"}),
                                        resultList: []
                                      });
          
                                    this.getItens();
                                }
                                else{
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                                resolve();
                            })
                            .catch(err => {
                                const intl = this.props.intl;
                                resolve();
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
                            });
                    

                    };
    
                    componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
                        this.getItens();
                    }
                
                    getItens(page = 1) {
                        this.setState({ loading: true });

                        let url = `/groups/paginate?page=${page}`;
        
                        if(this.state.searchValue != ''){
                            url += `&search=${this.state.searchValue}`
                            this.setState({ dataFilter: true });
                        }
                                            Api.get(url)
                            
                                    .then(result => {
                                        if (result.data.success) {

                                            if(result.data.data.length === 0)
                                            {
                                                this.emptyArray = true 
                                            }
                                            else
                                            {
                                                this.emptyArray = false
                                            }

                                            let count = result.data.count;
                                            this.setState({count:count});
                                            this.setPagination(count,page);

                                            const formattedList = result.data.data.map(item => {
                                                return {
                                                    ...item,

                                          }
                                    });
                                    this.setState({ loading: false, resultList: formattedList });
                                }
                                else{
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: "error",
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                            })
                            .catch(err => {
                                const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: "error",
                                        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                    });
                                Api.kickoff(err);
                            });
                    };


                    closeNotification() {
                        this.setState({ openNotification: false });
                    }
                     render() {
                        const { classes, headerMenu } = this.props;
                        const intl = this.props.intl;

                        if(Api == null) {
                            var l = this.props.match.params.lang;
                            document.location.href=`/${l}/unavailable`
                          }

                        return (
                            <div className={classes.root} style={{paddingLeft:0, marginLeft:0}}>
                                <HeaderMenu />
                                <main
                                    className={clsx(classes.content, {
                                        [classes.contentShift]: headerMenu.open,
                                    })}
                                >
                                    <div className={classes.drawerHeader} />
                                    
                                    <Container component='main' maxWidth='xl' style={{paddingLeft:0, marginLeft:0}}>
                                    {(!this.emptyArray || this.state.dataFilter) ? (
                                    <div>

                                    
                                        <CssBaseline />
                                        <Grid container className={classes.mb20}  spacing={0}>
                                            
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                size='large'
                                                className={classes.button}
                                                startIcon={<AddIcon />}
                                                disabled={!this.context.can('Add', 'Group')}
                                                component={Link} {...{ to: `/${this.props.match.params.lang}/group-registration` }}
                                            >
                                                {<FormattedMessage id="add" />}
                                        </Button>
                                        </Grid>
                                        <Grid container spacing={6} alignItems="center"> 
                                            <Grid item xs={12} sm={12}>

                                            <Card className="background-title-search">
                                            <CardContent>
                                                
                                                <Grid container spacing={1} alignItems="center">
                                                <Grid item xs={12} sm={5} md={3} lg={6}>
                                                <Typography variant="h5">
                                                    {<FormattedMessage id="group.header" />}
                                                </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Search />
                                                    </Grid>
                                                    <Grid item xs={10} sm={6} md={4} lg={3}>
                                                        <TextField type="text"
                                                        name="searchValue"
                                                        value={this.state.searchValue}
                                                        placeholder={intl.formatMessage({id:"search"})}
                                                        onChange={this.onFieldChange}/>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={2} lg={1}>
                                                <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        size='large'
                                                        className={classes.button}
                                                        style={{marginRight:4,marginLeft:8}}
                                                        onClick={this.callSearch}
                                                    >
                                                        <Search />
                                                </Button>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={2} lg={1}>
                                                <Button
                                                        variant='contained'
                                                        color='primary'
                                                        size='large'
                                                        onClick={this.clearFields}
                                                        className={classes.button}
                                            
                                                    >
                                                        <ClearIcon />
                                                </Button>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>

                                            </Grid>
                                                            
                                        </Grid>
                                        <Can I="List" a="Group">
                                        <MyMaterialTable
                                                         title=""
                                                                   rowClick={(event, rowData) => {
                                                                    let abl = this.context.can('Edit', 'Group');
                                                                    if(abl == false){
                                                                        this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"})});
                                                                        return false;
                                                                    }
                                            
                                                                    this.props.addCurrent(rowData);
                                                          this.props.history.push(`/${this.props.match.params.lang}/group-edit`);
                                                               }}
                                            columns={[
                                                       { title: intl.formatMessage({id:"name"}), field: 'name' },


                                            ]}
                                            data={this.state.resultList}
                                            deleteItem={this.deleteItem} 
                                            
                                        />
                                        </Can>

                                        <Can I='List' a='Group'>
                                            
                                            <Grid container style={{marginBottom:12,marginTop:8}}>
                                                <Grid item xs={12} md={3}>
                                                <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                                                </Grid>
                                                <Grid item xs={12} sm={3} style={{marginTop:6}}>
                                                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                                </Grid>
                                            </Grid>

                                            </Can> 

                                      
                                        </div>)
                                         :(
                                            <div>
                                               <FirstElement
                                               title = {intl.formatMessage({id:"group.list.create.first.group"})}
                                               subtitle = {intl.formatMessage({id:"group.list.create.groups"})}
                                               //buttonText = "Novo Chamado"
                                               buttonText = 
                                               {
                                                <Button
                                                variant='contained'
                                                color='secondary'
                                                size='large'
                                                className={classes.button}
                                                startIcon={<AddIcon />}
                                                component={Link} {...{ to: `/${this.props.match.params.lang}/group-registration` }}
                                            >
                                                {<FormattedMessage id="add" />}
                                                    </Button>
                                               }
                                            
                                               icon = {<AccountBalanceWalletIcon style={{fontSize: "45px"}} />}
                                               
                                               />
                                            </div>
                                    )}
                                      {this.state.loading && (
                                            <Overlay>
                                                <CircularProgress color='secondary' />
                                            </Overlay>
                                        )}
                      

                                        <CustomizedSnackbars
                                            variant={this.state.notificationVariant}
                                            message={this.state.notificationMessage}
                                            isOpen={this.state.openNotification}
                                            toClose={this.closeNotification}
                                        />
                                    </Container>
                                </main>
                                <Footer />   
                            </div>
                        );
                    }
                }
Groups.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);
            export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Groups)));

            Groups.contextType = AbilityContext;            