import React, { Component } from "react"
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from "prop-types"
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material"
import { withStyles } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Overlay } from "../../styles/global"
import CustomizedSnackbars from "../../components/material-snackbars"
import HeaderMenu from "../../components/header-menu"
import MaterialTable from 'material-table'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import MyMaterialTable from '../../components/table'
import Api from "../../services/api"
import * as CalledService from '../../services/called.service'
import * as calledsActions from '../../redux/actions/calleds'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer'
import moment from "moment"
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import SimpleDatePicker from '../../components/date-pickers/inline-no-today'
import { saveAs } from 'file-saver'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import Pagination from '@mui/material/Pagination'
import Search from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import autoTable from 'jspdf-autotable'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import LogoMarca from '../../images/new-logo-cmtech.png'
import html2canvas from 'html2canvas'
import Divider from '@mui/material/Divider'
import * as ExcelJS  from 'exceljs'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import * as FileService from '../../services/attachment.service';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class SendSMSReport extends Component {

  constructor(props) {
    super(props)

    this.isClient = true
    this.isAdm = false
    this.typingTimeout = null
    this.organizationId=null
    
    const { userSession } = this.props

    if(userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {
      if(item.role.roletype !=3)
        this.isClient = false
      if(item.role.roletype == 0 || item.role.roletype == 1)
        this.isAdm = true
    });

    if(userSession.user.organization)
      this.organizationId = userSession.user.organization.id
        
    this.state = {
      dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
      dateEnd:moment().format('YYYY/MM/DD HH:mm:ss'),
      organizationId: this.organizationId,
      OrganizationFilter: 0,
      location: 0,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      showGrid: false,
      calledsList: [],
      organizationList: [],
      organizationAddressesList: [],
      calledPdfList:[],
      totalPages:0,
      page:1,
      pageStart:0,
      pageEnd:0,
      count:0,
      searchValue: '',
      showHeader:false,
      strhtmlModalOpen: false,
      detailsModalOpen: false,
      dialogHTMLString: '',
      message: '',
	  item:null,
    }
  }

  openHTMLRenderModal = (message) => {
    this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, message: message })
  }

  closeHTMLRenderModal = () => {
    this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, message: '' })
  }

  changeValues = async (stateName, value, text = '') => {
    this.setState({ loading: true })
    
    this.setState({ [stateName]: value });

    this.setState({ loading: false })
  };

  Reload = () => {
    const reload = localStorage.getItem('reload')
    if(reload == "true" )
      localStorage.setItem('reload', false)
    return null;
  }

  onChangePaginate = (e,page) =>{
    this.setState({ page:page })
    this.getCalleds('',page)
  }

  exportPdf = _ => {
    this.getCalledsToPdf()
  }

  setPagination = (count,page) => {
    let totalPage = Math.ceil (count/10)
    this.setState({ totalPages:totalPage })
    if(count > 0){
      if(page == 1){
        if(count < 10)
          this.setState({pageStart:1, pageEnd:count})
        else
          this.setState({pageStart:1, pageEnd:10})
      } else{
        let pageStart = ((page - 1) * 10) + 1
        if(count >= page * 10)
          this.setState({pageStart:pageStart, pageEnd:page * 10})
        else
          this.setState({pageStart:pageStart, pageEnd:count})
      }
    } else
      this.setState({totalPages:0, page:1, count:0})
    }

  onFieldChange = event => {
    // Clears the previously set timer.
    clearTimeout(this.typingTimeout)
    // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
    this.typingTimeout = setTimeout(this.callSearch, 475);
    this.setState({ [event.target.name]: event.target.value, page:1 })
  }

  callSearch = _ => {
    this.getCalleds('',this.state.page)
  }

  async componentDidMount() {
    this.setState({loading: true})

    this.setState({loading: false})  
  }

  openDetailsModal = (message,item) => {
    this.setState({ detailsModalOpen: !this.state.detailsModalOpen, message: message, item: item })
  }

  closeDetailsModal = () => {
    this.setState({ detailsModalOpen: !this.state.detailsModalOpen, message: '' })
  }

  getCalleds = async (filter = '',page = 1) => {
    this.setState({ loading: true });
    const intl = this.props.intl;
    let url = `/usersreporterros?`
        
    url += `dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}`

    this.setState({ showGrid: true })

    Api.get(url)
      .then(result => {
        if (result.data.success) {
          let count = result.data.count
          this.setState({count:count})
          this.setPagination(count,page)

          const formattedList = result.data.data.map(item => {
            return {
              ...item,
              fMessage: item.message.length > 150 ? item.message.substring(0, 150) + "..." : item.message,
              createdat: moment(item.createdat).format('DD/MM/YYYY HH:mm'),
              MessageDetails: item.message && item.message.length > 1 ? <Tooltip title={intl.formatMessage({id:"user.error.report.view.full.message"})}>
                <IconButton aria-label="textevidence"
                  onClick={ e => this.openDetailsModal(item.message,item) }>
                    <ZoomInIcon />
                  </IconButton>
                </Tooltip> : ''
            }
          })

          this.setState({ calledsList: formattedList, loading:false })
        } else{
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {
        console.log("=== Catch(e) ===")
        console.log(err)
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        })
        Api.kickoff(err)
      })
    }

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();
    this.setState({ loading: true });

    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')
    if(dateStart > dateEnd){
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
        loading :false
      });
    }
    else
    {
      await this.getCalleds(this.props.location.state)
    }
  }

  closeNotification = _ => {
    this.setState({ openNotification: false });
  }
  async getFile(item) {
	const intl = this.props.intl;
	this.setState({ loading: true });

	let url = await FileService.getFile(item.attachment.hirerid, item.attachment.id,item.attachment.path, item.attachment.filerepositorymethod); 
	this.setState({ loading: false });

	if(url &&  url.data && url.data.data && url.data.success){
		window.open(`${url.data.data}`, '_blank');
	}
	else{
		this.setState({
			loading: false, openNotification: true, notificationVariant: "error",
			notificationMessage: intl.formatMessage({ id: "file.not.found" })
		});

	}

	
};

  render() {
    const { classes, headerMenu } = this.props
    const intl = this.props.intl

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    const tableList = () => {
		let tipo = ['Erro','Sugestão','Melhoria'];

      return (
        <>
          <Dialog open={this.state.detailsModalOpen} onClose={this.closeDetailsModal} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
              {intl.formatMessage({id:"mail.body"})}
              <ClearIcon onClick={this.closeDetailsModal}  style={{ float: 'right', cursor : 'pointer'}}/> 
            </DialogTitle>
            <DialogContent style={{ marginBottom: 30 }}>
				<div>{ this.state.item ? this.state.item.name : "" }</div>
				<div>{ this.state.item ? this.state.item.email : "" }</div>
				<div>{ this.state.item ? tipo[this.state.item.typereport] : ""}</div>
				<div>{ this.state.item ? this.state.item.urlinstance : ""}</div>
				<div>{ this.state.item ? moment(this.state.item.createdat).format('DD/MM/YYYY HH:mm')  : ""}</div>
				<div>{ this.state.message }</div>
				<div className={classes.attachmentsBox} style={{border: 0}}>
                            <ul>
                                {this.state.item && this.state.item.userreporterrorattachment.map(item => {
                                        if(item && item.attachment){
										return (<li key={item.attachment.path}>
                                            <AttachFileIcon /> <Button style={{position:'inherit'}} onClick={async () => await this.getFile(item)}> { item.attachment.path.split('/').pop()}</Button>
                                        </li>)
										}
                                    
                                })}
                            </ul>
                        </div>
            </DialogContent>
          </Dialog>
          <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'> 
            <Accordion >
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
              >
                <Typography className={classes.heading}>{intl.formatMessage({id:"filters"})}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container style={{marginBottom:20}}>
                  <Grid item xs={12} sm={6} md={3}>
                    <SimpleDatePicker label={intl.formatMessage({id:"start.date"})} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required/>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <SimpleDatePicker label={intl.formatMessage({id:"end.date"})} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd}  required/>
                  </Grid>
                </Grid>  
              </AccordionDetails>
            </Accordion>

            <Grid container style={{marginBottom:10, float:'right', textAlign:'right'}}>
              <Grid item xs={12} sm={10} md={10}></Grid>
              <Grid item xs={12} sm={2} md={2} style={{marginTop:10}}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='secondary' >
                  {<FormattedMessage id="filter" />}
                </Button>
              </Grid>

            </Grid>                                   
          </form>
                
        {
          this.state.showGrid && <> 
            <MyMaterialTable
              title=""
              columns={
              [
                { title: intl.formatMessage({id:"user.error.report.view.full.message"}), field: 'MessageDetails' },
                { title: intl.formatMessage({id:"message"}), field: 'fMessage' },
                { title: intl.formatMessage({id:"user.error.report.date.time"}), field: 'createdat' },
              ]}
              data={this.state.calledsList} />

            <Grid container style={{marginBottom:12,marginTop:8}}>
              <Grid item xs={12} md={3}>
                <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
              </Grid>
              <Grid item xs={12} sm={3} style={{marginTop:6}}>
                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
              </Grid>
            </Grid>
          </>
        }
        </>
      );
    }

    return (
      <div className={classes.root} style={{paddingLeft:0, marginLeft:0}}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })} >
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='xl' style={{paddingLeft:0, marginLeft:0}}>
            <CssBaseline />
          {this.state.showHeader && 
            <Grid container>
              <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{padding: '2px'}} style={{minWidth:600}}>
                <Card >
                  <CardContent>
                    <img src={LogoMarca} style={{width:"70px"}}/> <span style={{marginLeft:25, fontSize:18}}> {intl.formatMessage({id:"user.error.report.errors.reported"})} </span>
                    <Divider />
                  </CardContent>
                </Card>  
              </Grid>
            </Grid>
          }
          
            <Grid container spacing={6}> 
              <Grid item xs={12} sm={12}>
                <Card >
                  <CardContent className="background-title-search">
                    <Typography variant="h5">
                      {intl.formatMessage({id:"user.error.report.errors.reported"})}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          
            {tableList()}

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
          </Container>
        </main>
      <Footer />                    
      </div>
    )
  }
}

SendSMSReport.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(SendSMSReport)))
SendSMSReport.contextType = AbilityContext