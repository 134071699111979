import React from 'react';
import { Button, Menu, MenuItem as MaterialMenuItem, Typography, IconButton } from '@mui/material';
import { ExitToApp, Person, CompareArrows, AccountBox } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import SnackbarContent from '@mui/material/SnackbarContent';

import ItemMenu from './index.itemMenu';

import { makeStyles } from '@mui/styles';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';


function NoticiationMenu(props) {

  //console.log(props);
  const intl = props.intl;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  }));

  
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  var badge = false;

  if(props.listNotification.length > 0){
    badge = true;
    //setOpen(false);
  }
  
  var count = 0;
  if(props.listNotification != null){
	props.listNotification.map((item, key) => {
		if(item.notification != null){
			item.notification.map((item2) => {
			if(item2.finish == false){
				count  = count + 1;
			}
			})
		}
	})
  }
  //{ resetSession },

  const MenuItem = (item) => (
    <ItemMenu orgName={item.name} orgId={item.id} listNotification={item.notification} organization={item.organization} handleSteps={props.handleSteps} intl={intl} />
  )
  return (
    <div  style={{ marginRight:'-10px' }}>
      
      <IconButton 
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          id="btnNotification"
          style={{color:'#FFFFFF' }}
        >
        {badge && (
          <Badge badgeContent={count} color="error" >

             <Tooltip title={intl.formatMessage({id:"notifications"})} aria-label={props.intl.formatMessage({id:"add"})}>

               <NotificationsIcon />
             </Tooltip>
            
          </Badge>
        )}
        {!badge && (
             <Tooltip title={intl.formatMessage({id:"notifications"})} aria-label={props.intl.formatMessage({id:"add"})}>

              <NotificationsIcon />
           </Tooltip>
        )}
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                { (props.listNotification == null || (props.listNotification && props.listNotification.length == 0)) ? (
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MaterialMenuItem onClick={handleClose}>{intl.formatMessage({id:"notification.menu.no.notifications"})}</MaterialMenuItem>
                  </MenuList>
                ) : (
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {props.listNotification.map((item, key) => {
                    return MenuItem(item);
                  })}
                  </MenuList>
                )}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

    </div>
  );
}

NoticiationMenu.propTypes = {
  listNotification: PropTypes.array.isRequired,
  handleSteps: PropTypes.func
};


export default injectIntl(NoticiationMenu);
