import React, { Component } from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import MaterialTable from 'material-table';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import MyMaterialTable from '../../components/table/index-report';
import Api from "../../services/api";
import * as calledsActions from '../../redux/actions/calleds';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer';
import moment from "moment";
import * as CalledService from '../../services/called.service';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import { saveAs } from 'file-saver';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import LogoMarca from '../../images/new-logo-cmtech.png';
import html2canvas from 'html2canvas';
import Divider from '@mui/material/Divider'
import * as ExcelJS from 'exceljs'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { renderToStaticMarkup } from "react-dom/server";

class MaterialReports extends Component {

    constructor(props) {
        super(props);
        this.Reload = this.Reload.bind(this);
        this.getFile = this.getFile.bind(this);
        this.Reload();

        const { userSession } = this.props;

        this.isClient = true;
        this.isAdm = false;

        if (userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0 || item.role.roletype == 1) {
                this.isAdm = true;
            }
        }
        );
        this.organizationId = null;
        if (userSession.user.organization) {
            this.organizationId = userSession.user.organization.id;
        }

        this.state = {
            dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
            dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
            organizationId: this.organizationId,
            OrganizationFilter: 0,
            location: 0,
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            organizationAddressesList: [],
            showGrid: false,
            calledsList: [],
            organizationList: [],
            showHeader: false
        };
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);
        this.exportPdf = this.exportPdf.bind(this);
        this.getExcel = this.getExcel.bind(this)
    }

    async changeValues(stateName, value, text = '') {
        this.setState({ loading: true });
        if (stateName === 'location') {
            this.setState({ [stateName]: value.id });
        }
        else if (stateName === 'OrganizationFilter') {
            this.setState({ [stateName]: value.id });
            await this.getOrganizationAddresses(value.id);
        } else {
            this.setState({ [stateName]: value });
        }
        this.setState({ loading: false });
    };

    async exportPdf() {
        const data = this.state.calledsList;
        const intl = this.props.intl;
        await this.setState({ loading: true, showHeader: true });

        const doc = new jsPDF("p", "mm", "a4");

        const header = document.getElementById('header-pdf');
        let headerCanvas = await html2canvas(header);
        const headerImg = headerCanvas.toDataURL('image/png');
        doc.addImage(headerImg, 'PNG', 0, 0);

        var width = doc.internal.pageSize.getWidth();

        doc.setLineWidth(width);
        doc.autoTable({
            body: data,
            margin: { top: 30 },
            bodyStyles: { valign: 'middle' },
            styles: { overflow: 'linebreak', columnWidth: 'wrap', fontSize: 7, halign: 'center' },
            theme: 'striped',
            columns: [
                { header: intl.formatMessage({id:"id"}), dataKey: 'id' },
                { header: intl.formatMessage({ id: "called.Accom" }), dataKey: 'annotation' },
                { header: intl.formatMessage({ id: "called.Accom.date" }), dataKey: 'lastAction' },
                { header: intl.formatMessage({ id: "organization" }), dataKey: 'Organization' },
                { header: intl.formatMessage({ id: "departament" }), dataKey: 'userDepartment' },
                { header: intl.formatMessage({ id: "components" }), dataKey: 'materials' },
                //{ header: intl.formatMessage({id:"number.reams"}), dataKey: 'reams' },
                { header: intl.formatMessage({ id: "value" }), dataKey: 'value' },
                { header: intl.formatMessage({ id: "menu.organizationpatrimonies" }), dataKey: 'patrimonys' },
                { header: intl.formatMessage({ id: "location" }), dataKey: 'location' },
                { header: intl.formatMessage({ id: "wasbaduse" }), dataKey: 'baduse' }
            ],
            columnStyles: {
                id: {
                    columnWidth: 9
                },
                annotation: {
                    columnWidth: 25,
                },
                lastAction: {
                    columnWidth: 28,
                },
                Organization: {
                    columnWidth: 19,
                },
                userDepartment: {
                    columnWidth: 20,
                },
                materials: {
                    columnWidth: 20,
                },
                reams: {
                    columnWidth: 20,
                },
                patrimonys: {
                    columnWidth: 19,
                },
                location: {
                    columnWidth: 19,
                },
                baduse: {
                    columnWidth: 15,
                },
            },
        })
        doc.save("relatorio-materiais.pdf");
        await this.setState({ loading: false, showHeader: false });

    }

    async getOrganizationAddresses(organizationId) {
        this.setState({ loading: true });

        const result = await CalledService.getOrganizationAddresses(organizationId);

        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1) {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: this.props.intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.address"})
                    });
                }
            }

            this.setState({ loading: false, organizationAddressesList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    Reload = () => {
        const reload = localStorage.getItem('reload');
        if (reload == "true") {
            localStorage.setItem('reload', false);
        }
        return null;
    }

    async getOrganizations() {
        let data = [];
        const { userSession } = this.props
        if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
            data = userSession.user.userorganizations
        } else {
            await Api.get('/organizations/tech')
                .then(result => {
                    data = result.data.data
                })
                .catch(err => {
                    data = err;
                });
        }
        this.setState({ organizationList: data })
    };

    deleteItem(data, resolve) {
        const intl = this.props.intl;
        let abl = this.context.can('Delete', 'Called');
        if (abl == false) {
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"}) });
            resolve(); return false;
        }
        Api.delete(`/materialreports/${data.id}`)
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"called.list.notification.delete.success"}),
                        resultList: []
                    });

                    this.getCalleds();

                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);
            });
    };

    async componentDidMount() {
        this.setState({ loading: true })
        await this.getOrganizations();
        this.setState({ loading: false })

        //await this.getAllCalleds()
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.location.state === "calledReports") {
            this.getCalleds();
        }

        if (nextProps.location.state === "materialReports") {
            this.getCalleds();
        }

        else if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async getExcel() {
        const intl = this.props.intl;
        const wb = new ExcelJS.Workbook()

        const ws = wb.addWorksheet()

        // console.log(this.state.calledsList)

        ws.columns = [
            { header: intl.formatMessage({id:"id"}), key: 'id', width: 25 },
            { header: intl.formatMessage({ id: "called.Accom" }), key: 'annotation', width: 25 },
            { header: intl.formatMessage({ id: "called.Accom.date" }), key: 'lastAction', width: 25 },
            { header: intl.formatMessage({ id: "organization" }), key: 'Organization', width: 25 },
            { header: intl.formatMessage({ id: "departament" }), key: 'userDepartment', width: 25 },
            { header: intl.formatMessage({ id: "components" }), key: 'materials', width: 25 },
            { header: intl.formatMessage({id:"amount"}), key: 'qtdmaterial', width: 25 },
            { header: intl.formatMessage({id:"total.value"}), key: 'value', width: 25 },
            //{ header: intl.formatMessage({id:"number.reams"}), key: 'reams',width: 25  },
            { header: intl.formatMessage({ id: "menu.organizationpatrimonies" }), key: 'patrimonys', width: 25 },
            { header: intl.formatMessage({ id: "location" }), key: 'location', width: 25 },
            { header: intl.formatMessage({ id: "sla.service" }), key: 'slaAtendimento', width: 25 },
            { header: intl.formatMessage({ id: "sla.solution" }), key: 'slaSolucao', width: 25 },
            { header: intl.formatMessage({ id: "wasbaduse" }), key: 'baduse', width: 25 },
        ];

        ws.getCell('A1').style = { font: { bold: true } }
        ws.getCell('B1').style = { font: { bold: true } }
        ws.getCell('C1').style = { font: { bold: true } }
        ws.getCell('D1').style = { font: { bold: true } }
        ws.getCell('E1').style = { font: { bold: true } }
        ws.getCell('F1').style = { font: { bold: true } }
        ws.getCell('G1').style = { font: { bold: true } }
        ws.getCell('H1').style = { font: { bold: true } }
        ws.getCell('I1').style = { font: { bold: true } }
        ws.getCell('J1').style = { font: { bold: true } }
        ws.getCell('K1').style = { font: { bold: true } }
        ws.getCell('L1').style = { font: { bold: true } }

        ws.addRows(this.state.calledsList);

        const buf = await wb.xlsx.writeBuffer()

        saveAs(new Blob([buf]), 'relatorio_chamados.xlsx')
    }
    getFile() {

        this.setState({ loading: true });
        var url = '/materialreports/getfile';
        url = url + '?filter=' + this.props.location.state;

        url += `&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&loc=${this.state.location}&org=${this.state.OrganizationFilter}`;

        Api.get(url, {
            responseType: 'blob'
        })
            .then((response) => {

                let url = window.URL.createObjectURL(new Blob([response.data]));
                saveAs(url, 'relatorio_chamados.xlsx');
                this.setState({ loading: false });
            });
    }

    getMessageBadUseFlag(message) {
        let writing = ""
        if (message === true) {
            writing = this.props.intl.formatMessage({id:"yes"})
        }
        else if (message === false) {
            writing = this.props.intl.formatMessage({id:"no"})
        }
        else {
            writing = this.props.intl.formatMessage({id:"undefined"})
        }

        return writing
    }




    getCalleds(filter = '') {
        const intl = this.props.intl;
        this.setState({ loading: true });
        var url = '/materialreports';
        if (this.isClient) {
            url = `/materialreports/${this.state.organizationId}`;
        }

        url += `?dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&loc=${this.state.location}&org=${this.state.OrganizationFilter}`;
        var writing = ""
        Api.get(url)
            .then(result => {
                if (result.data.success) {
                    const formattedList = result.data.data.map(item => {
                        writing = this.getMessageBadUseFlag(item.called.baduse)
                        
                        let convertedAccompaniment = renderToStaticMarkup(<p>{item.annotation}</p>)
                        if (item.annotation.trim().startsWith("<p> &lt;h") || item.annotation.trim().startsWith("<p>&lt;div"))
                        convertedAccompaniment = renderToStaticMarkup(item.annotation)
                        else if (item.annotation.trim().startsWith("<div style=\"") || item.annotation.trim().startsWith("<div dir=") || item.annotation.trim().startsWith("<p>") || item.annotation.trim().startsWith("<h1>") || item.annotation.trim().startsWith("<h2>") || item.annotation.trim().startsWith("<h3>")) {
                            convertedAccompaniment = item.annotation
                        }

                        return {
                            ...item,
                            baduse: writing,
                            materials: item.materials,
                            value: item.value ? `R$${item.value}` : +`R$0`,
                            annotation: <div dangerouslySetInnerHTML={{__html: convertedAccompaniment}} />,
                            Organization: item.organization,
                            lastAction: <div dangerouslySetInnerHTML={{__html: item.lastaction}} />,
                            reams: item.reams,
                            userDepartment: item.userdepartament ? item.userdepartament : "",
                            patrimonys: item.patrimonys,
                            location: item.address,
                            quantity: item.quantity,
                            slaAtendimento: getSlaAtendimento(item.called, this.props.userSession) + "%",
                            slaSolucao: getSlaSolucao(item.called, this.props.userSession) + "%",
                        }
                    });
                    this.setState({
                        calledsList: formattedList,
                        loading: false,
                        showGrid: true
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                // console.log(err);
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });
    };

    async getAllCalleds() {

        Api.get('/calleds')
            .then(result => {
                if (result.data.success) {
                    // console.log(result.data)
                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });

        //this.setState({ organizationList: data })
    };



    handleSubmit = async e => {
        e.preventDefault();

        this.setState({ loading: true });
        this.getCalleds(this.props.location.state);
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    render() {
        const { classes, userSession, headerMenu } = this.props;
        var link = `/${this.props.match.params.lang}/called-registration`;
        if (this.isClient) {
            link = `/${this.props.match.params.lang}/called-registration-client`;
        }

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        const intl = this.props.intl;
        const tableList = () => {
            return (
                <div>
                    <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>


                        {this.state.showHeader &&
                            <Grid container>
                                <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{ padding: '2px' }} style={{ minWidth: 600 }}>
                                    <Card>
                                        <CardContent>
                                            <img src={LogoMarca} style={{ width: "70px" }} /> <span style={{ marginLeft: 25, fontSize: 18 }}> {<FormattedMessage id="material.report.header" />}</span>
                                            <Divider />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>}
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{intl.formatMessage({id:"filters"})}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container style={{ marginBottom: 20 }} spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} required />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3} >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.organizationList} stateName='OrganizationFilter' changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "location" })} options={this.state.organizationAddressesList} stateName='location' changeSelect={this.changeValues} />
                                    </Grid>


                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Grid container style={{ marginBottom: 10 }}>
                            <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='secondary'

                                >
                                    {<FormattedMessage id="filter" />}
                                </Button>
                            </Grid>
                            {this.state.showGrid && <Grid item xs={6} sm={5} md={2} style={{ marginTop: 10, marginRight: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    onClick={this.getExcel}
                                >
                                    {<FormattedMessage id="export.plan" />}
                                </Button>
                            </Grid>}

                            {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.exportPdf}

                                >
                                    {<FormattedMessage id="dash.pdf" />}
                                </Button>
                            </Grid>}
                        </Grid>
                    </form>

                    {
                        this.state.showGrid &&
                        <>
                            <MyMaterialTable
                                title=""
                                columns={[{ title: intl.formatMessage({id:"id"}), field: 'id' }, { title: intl.formatMessage({ id: "called.Accom" }), field: 'annotation' },
                                { title: intl.formatMessage({ id: "called.Accom.date" }), field: 'lastAction' },
                                { title: intl.formatMessage({ id: "organization" }), field: 'Organization' },
                                { title: intl.formatMessage({ id: "departament" }), field: 'userDepartment' },
                                { title: intl.formatMessage({ id: "components" }), field: 'materials' },
                                { title: intl.formatMessage({id:"amount"}), field: 'qtdmaterial' },
                                { title: intl.formatMessage({id:"total.value"}), field: 'value' },
                                { title: intl.formatMessage({ id: "menu.organizationpatrimonies" }), field: 'patrimonys' },
                                { title: intl.formatMessage({ id: "location" }), field: 'location' },
                                { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' },
                                { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' },
                                { title: intl.formatMessage({ id: "wasbaduse" }), field: 'baduse' }]}
                                data={this.state.calledsList}

                            />
                        </>
                    }


                </div>
            );
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{ paddingLeft: 0, marginLeft: 0 }}>
                        <CssBaseline />
                        <Grid container className={classes.mb20} spacing={0}>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>

                                <Card >
                                    <CardContent>
                                        <Typography variant="h5">
                                            {<FormattedMessage id="material.report.header" />}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                        <Can I="List" a="Called">
                            {tableList()}
                        </Can>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}

MaterialReports.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(MaterialReports)));
MaterialReports.contextType = AbilityContext;