import moment from "moment";

function getFirstMove(status){
    var result = null;
    status.forEach((element) => {
        if(element.previousstatus.statustype.id == 1 && element.nextstatus.statustype.id == 2 ) { // NOVO && MOVIMENTO
            result=element;
            return false;
        }
    });
    return result;
}
function getLastMove(status){
    var result = null;
    status.forEach((element) => {
        if(element.previousstatus.statustype.id == 2 && element.nextstatus.statustype.id == 5 ) { // MOVIMENTO && ENCERRADO
            result=element;
            return false;
        }
    });
    return result;
}
function isMinuteBeetween(minute, hours){
    var result = minute;
    var isBeetween=false;
    var nextstart = 0;
    for(var i=0; i<hours.length;i++){ 
        var item = hours[i];
        var start = item.starthour; 
        var end = item.endhour; 

        if(minute >=start && minute <=end){ //  verificando se o minuto está entre o intervalo
            isBeetween = true;
        }
        if(nextstart == 0){
            if(start > minute){
                nextstart=start;
            }
        }
    }
    if(! isBeetween){
        if(nextstart > result){
            result = nextstart;
        }

    }
    return result;

}
function calculateWork(openingdate,movedate, day, hours){
    var now = new Date(movedate);

   var minutos_mudanca_status = movedate.diff(openingdate, 'minutes'); //  recuperando a diferenca de tempo entre criaçao e abertura em minutos
   var currDate =   openingdate.clone().startOf('day'); // clonando a data de abertura do chamado e zerando a hora; Essa data será a utilizada para percorrer os dias no for mais abaixo
   var lastDate = movedate.clone().startOf('day'); // clonando a primeira data de movimentacao do chamado e zerando a hora
   var minutes_worked=0;
   //console.log(openingdate._i);
   //console.log(lastDate._i);

    while(currDate.diff(lastDate) <= 0) { // andando os dias existentes entre abertura e movimentacao

        var timestart= (openingdate.hour()*60) + openingdate.minute(); // transformando a hora de abertura em minutos

        var timeend= (movedate.hour()*60) + movedate.minute(); // transformando a hora da primeira movimentacao em minutos
        day = currDate.weekday();  // recuperando o dia atual da semana
        var f = hours.filter(h => h.weekday == day); //  filtrando o array de expediente para considerar somente o dia atual da semana pego acima
        //console.log(timestart);
        timestart = isMinuteBeetween(timestart,f);
        //console.log(timestart);

        //console.log(timeend);
        //console.log(f);
        for(var i=0; i<f.length;i++){ // varrendo o array de expedientes
            var item = f[i];
            var end = item.endhour; // recuperando a hora final de expediente do dia
            if(currDate.clone().startOf('day').isSame(movedate.clone().startOf('day')) ){  // verificando se a data corrente chegou no dia que o chamado foi movimentado
                 if(timeend < end){ // se a hora de movimentação for menor que o fim do expediente, deve-se considerar ela
                    end=timeend;
                 }
            }
            for(var start=item.starthour; start<=end;start++){ //  varrendo o intervalo do expediente
                    if(start >=timestart && timestart <=end){ //  verificando se o tempo em minutos da abertura está no intervalo do expediente; somente minutos dentro do intervalo serão considerados como minutos trabalhados
                        minutes_worked++; //  contando os minutos trabalhados
                    }
            }
        }
        currDate.add(1, 'days'); // percorrendo 1 dia na data corrente(que iniciou com a data de abertura) para o "for"
        openingdate.add(1, 'days').startOf('day'); // // percorrendo 1 dia na data de abertura zerando a hora, para poder contar do início do expediente no próximo dia

    }
   //console.log('minutes_worked: '+minutes_worked); 
  return minutes_worked;
}
function generateFullWork(){
    //0: {id: 6, starthour: 480, endhour: 720, weekday: 1}
    const min = 1;
    const max = 1000000;

    var result = [];

    for(var i=0; i<=7; i++){
        var obj = {};
        var rand = i+(min + Math.random() * (max - min));
        obj.id=rand;

        obj.starthour=0;
        obj.endhour=1440;
        obj.weekday=i;
        result.push(obj);
    }

    return result;

}

export function getSlaAtendimento(item,userSession){
    // percentual de tempo corrido baseado na escala de horário até a primeira 
    // girada de status para em andamento
    var percentual = 0.0;
    
    var status = item.calledstatus;
    var servicetime = item.organizationcategory.organizationsla.servicetime;
    var solutiontime = item.organizationcategory.organizationsla.solutiontime;
    var openingdate = item.openingdate;
    var useslatime = item.organizationcategory.useslatime;
    if(useslatime == false){
        if(item.organizationcategory.servicetime > 0 && item.organizationcategory.solutiontime > 0){
            servicetime = item.organizationcategory.servicetime;
            solutiontime = item.organizationcategory.solutiontime;
        }
    }
    var hours = item.organizationcategory.organizationsla.workhour ? item.organizationcategory.organizationsla.workhour.scheduleworkhours : generateFullWork();
    var countType = 0;
    if(item.organizationcategory.slacounttype != null){
        countType = item.organizationcategory.slacounttype.counttype;
    }

    if(countType == 1){ // escala do usuário
        if( userSession.user.workhour != null){
            hours = userSession.user.workhour.scheduleworkhours;
        }
        else{
            hours = generateFullWork();
        }
    }
    else if(countType == 3){ // tempo corrido
        hours = generateFullWork();
    }

    var first = getFirstMove(status);
    const dayofweek = item.dayofweek;
    const currentdate = moment(item.currentdate);
   
    if(first != null){
      var movedate = first.createdat;
    }
    else{
      var movedate = currentdate;
    }
      var openingdate = moment(openingdate);
      var movedate = moment(movedate);
      var minutos_mudanca_status = movedate.diff(openingdate, 'minutes');
      var horainiciochamado = openingdate.format("HH:mm");
      var horainiciomovimento = movedate.format("HH:mm");
      var minutos_chamado_aberto= (openingdate.hour()*60) + openingdate.minute();
      var minutos_chamado_inicio= (movedate.hour()*60) + movedate.minute();

      //console.log(item); 
      //console.log(dayofweek);
      //console.log(hours);
      var minutes_worked = calculateWork(openingdate,movedate,dayofweek,hours)

      //servicetime     -----------  100%
      //minutes_worked  ------------ x
      /*
          720x = 103*100
          x = 10.300/720
          x = 14.30%
      */
     
     percentual =  ((minutes_worked*100)/servicetime).toFixed(2);
      
     if(percentual == 0.00){
        percentual=0;
       } 
    if(percentual > 100){
        percentual = 100;
    }
    

  return percentual;
}

export function getSlaSolucao(item,userSession){
    // percentual de tempo corrido baseado na escala de horário até a 
    // conclusao do chamado, onde o status vai para concluido;
    var percentual = 0.0;
    //return percentual;
    var status = item.calledstatus;
    var servicetime = item.organizationcategory.organizationsla.servicetime;
    var solutiontime = item.organizationcategory.organizationsla.solutiontime;
    var openingdate = item.openingdate;
    var useslatime = item.organizationcategory.useslatime;
    if(useslatime == false){

         if(item.organizationcategory.servicetime > 0 && item.organizationcategory.solutiontime > 0){
            servicetime = item.organizationcategory.servicetime;
            solutiontime = item.organizationcategory.solutiontime;
         }


    }
    var hours = item.organizationcategory.organizationsla.workhour ? item.organizationcategory.organizationsla.workhour.scheduleworkhours : generateFullWork();

    var countType = 0;
    if(item.organizationcategory.slacounttype != null){
        countType = item.organizationcategory.slacounttype.counttype;
    }

    if(countType == 1){ // escala do usuário
        if( userSession.user.workhour != null){
            hours = userSession.user.workhour.scheduleworkhours;
        }
        else{
            hours = generateFullWork();
        }
    }
    else if(countType == 3){ // tempo corrido
        hours = generateFullWork();
    }


    var last = getLastMove(status);
    const dayofweek = item.dayofweek;
    const currentdate = moment(item.currentdate);
   
    if(last != null){
      var movedate = last.createdat;
    }
    else{
      var movedate = currentdate;
    }

      //console.log(movedate);

      var openingdate = moment(openingdate);
      var movedate = moment(movedate);
      var minutos_mudanca_status = movedate.diff(openingdate, 'minutes');
      var horainiciochamado = openingdate.format("HH:mm");
      var horainiciomovimento = movedate.format("HH:mm");
      var minutos_chamado_aberto= (openingdate.hour()*60) + openingdate.minute();
      var minutos_chamado_inicio= (movedate.hour()*60) + movedate.minute();


      var minutes_worked = calculateWork(openingdate,movedate,dayofweek, hours)

      //solutiontime     -----------  100%
      //minutes_worked  ------------ x
      /*
          720x = 103*100
          x = 10.300/720
          x = 14.30%
      */
     percentual =  ((minutes_worked*100)/solutiontime).toFixed(2);
      

   if(percentual == 0.00){
    percentual=0;
   } 
   if(percentual > 100){
    percentual= 100;
   } 

  return percentual;
}

