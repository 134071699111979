import React, { Component, Fragment } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { Avatar, Button, CssBaseline, TextField, Link, Grid, Typography, CircularProgress, Container } from "@mui/material";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import { connect } from 'react-redux';
import HeaderMenu from "../../components/header-menu";
import { FormattedMessage,injectIntl } from 'react-intl';

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordResetForm: {
        email: "",
        password: "",
        confirmPassword: "",
        emailConfirm: ""
      },
      disabledButtons: true,
      loading: false,
      emailExpired: null,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      disabledResendButton: false,
      userLogged: false
    };

    this.ButtonExpired = null

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    const email = this.props.userSession.user.email;
    this.setState(prevState => ({
      userLogged: true,
      disabledButtons: false,
      passwordResetForm: {
        ...prevState.passwordResetForm,
        email
      }
    }));
  }

  resendEmail() {
    const intl = this.props.intl;

    console.log(this.state)

    this.setState({ loading: true });
    let data = new FormData();
    data.append("email", this.state.emailExpired);


    Api.post("/users/resendExpiredEmailPassword", data)
      .then(result => {
        if (result.data.success) {
          this.setState((prevState) => ({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: this.props.intl.formatMessage({id:"email.sent"}),
            disabledResendButton: true

          }));
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          disabledResendButton: true,
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
      });

  }


  addButtonExpiredToken() {

    const intl = this.props.intl;

    this.setState({ loading: true });
    Api.get(`/users/get-user-by-token/${this.props.match.params.token}`)
      .then((result) => {
        console.log(result)
        if (result.data.success) {

          this.setState({
            emailExpired: result.data.data.email,
            passwordResetForm: {
              email: result.data.data.email,
            },
          });

        }
      })
      .catch((err) => {

        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });

      });

    const { classes } = this.props;
    this.ButtonExpired =
      <div className={classes.form} style={{ marginTop: '0px' }}  >
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={this.resendEmail}

        >
          {intl.formatMessage({id:"resend.new.link"})}
        </Button>
      </div>

    this.setState({ buttonRender: true, loading: false });



  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      passwordResetForm: {
        ...prevState.passwordResetForm,
        [name]: value
      }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (this.validateEmail()) {
      this.setState({ loading: true });
      let data = new FormData();
      data.append("email", this.state.passwordResetForm.email);

      Api.post("/users/password-recovery", data)
        .then(result => {
          if (result.data.success) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: this.props.intl.formatMessage({id:"mail.sent.success.verify.mail"})
            });
            setTimeout(() => {
              this.props.history.push("/");
            }, 2000);
          }
        })
        .catch(err => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          });
        });

    }
  };

  validateEmail = () => {
    const intl = this.props.intl;
    const { passwordResetForm } = this.state;
    if (passwordResetForm.email !== passwordResetForm.emailConfirm) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: this.props.intl.formatMessage({id:"password.reset.auth.emails.dont.match"})
      });
      return false;
    } else {
      this.closeNotification();
      return true;
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes, headerMenu } = this.props;
    const { passwordResetForm, userLogged } = this.state;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    const containerBody = () => {
      const intl = this.props.intl;
      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}> <CompareArrowsIcon /> </Avatar>
            <Typography component="h1" variant="h5"> Redefina sua senha </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={this.props.intl.formatMessage({id:"email"})}
                    name="email"
                    autoComplete="email"
                    value={passwordResetForm.email}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="emailConfirm"
                    label={this.props.intl.formatMessage({id:"password.reset.auth.email.confirmation"})}
                    name="emailConfirm"
                    autoComplete="email"
                    value={passwordResetForm.emailConfirm}
                    onChange={this.handleInputChange}
                  />
                </Grid>
              </Grid>
              <Button
                style={{ marginBottom: '8px' }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={this.state.disabledButtons}
              >
                {this.props.intl.formatMessage({id:"password.reset.auth.send.alteration.email"})}
              </Button>





            </form>
            {!this.state.disabledResendButton && (

              <>
                {this.ButtonExpired}
              </>

            )}

            {!userLogged && (
              <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: '2px' }}>
                <Grid item>
                  <Link href="/" variant="body2"> {this.props.intl.formatMessage({id:"password.reset.has.account.login"})} </Link>
                </Grid>
              </Grid>
            )}
          </div>

          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />
        </Container>
      );
    };

    if (userLogged) {
      return (
        <div className={classes.root}>
          <HeaderMenu />
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: headerMenu.open,
            })}
          >
            <div className={classes.drawerHeader} />

            {containerBody()}
          </main>
        </div>
      );
    } else {
      return (
        <Fragment>
          {containerBody()}
        </Fragment>
      );
    }
  }
}

PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(PasswordReset)));