import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from "prop-types"
import { withStyles } from "@mui/styles"
import Api from '../../../services/api'
import * as calledsActions from '../../../redux/actions/calleds'
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete'
import { Styles } from "../../../styles/material-styles"
import { Overlay } from '../../../styles/global'
import CustomizedSnackbars from '../../../components/material-snackbars'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Tab as RTab, Tabs as RTabs, TabList as RTabList, TabPanel as RTabPanel } from 'react-tabs'
import { getAccompanimentsToFinish } from '../../../services/called.service'
import Dropzone from 'react-dropzone';
import { DeleteForever, TrendingUpSharp } from '@mui/icons-material';
import moment from 'moment';
import SimpleCheckBox from '../../../components/checkbox/check';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { PapiroConsole } from '../../../utils/papiroConsole';
import TextFieldCheckIcon from '../../../custom/icons/TextfieldCheckIcon'
import AttachFileCheckIcon from '../../../custom/icons/AttachFileCheckIcon'

const CalledOrganizationChecklistStep = props => {

  const intl = props.intl
  const classes = props.classes
  const called = props.called
  const organizationsettings = props?.called?.organizationsettings
  let [files, setFiles] = useState([])
  let [openNotification, setOpenNotification] = useState(false)
  let [notificationVariant, setNotificationVariant] = useState("error")
  let [notificationMessage, setNotificationMessage] = useState("")
  let [loading, setLoading] = useState(false)
  let [checklistList, setChecklistList] = useState(props.CalledOrganizationChecklistList)

  useEffect(() => {
    setChecklistList(props.CalledOrganizationChecklistList)
    PapiroConsole.log("=== CalledOrganizationChecklistStep useEffect ===")
    PapiroConsole.log(props.CalledOrganizationChecklistList)
  }, [props.CalledOrganizationChecklistList])

  const saveAction = async e => {
    PapiroConsole.log("=== saveAction ===")
    PapiroConsole.log(props)
    if (props.called && props.called.organizationsettings && props.called.organizationsettings.canfinalchecklistisrequired != null && props.called.organizationsettings.canfinalchecklistisrequired) {
      PapiroConsole.log(props.called.organizationsettings.canfinalchecklistisrequired)
      if (props.CalledOrganizationChecklistList && props.CalledOrganizationChecklistList.length > 0) {
        PapiroConsole.log(props.CalledOrganizationChecklistList)
        let hasCompletedChecklist = true
        props.CalledOrganizationChecklistList.forEach((item) => {
          let evOk = false
          let attachOk = false
          let valueOk = false
          
          if (item.evidencetext && item.evidencetext.length > 0) {
            PapiroConsole.log("saveAction => item.evidencetext")
            evOk = true
          }
          if (item.calledorganizationchecklistattachments && item.calledorganizationchecklistattachments.length > 0) {
            PapiroConsole.log("saveAction => item.calledorganizationchecklistattachments")
            attachOk = true
          }
          if (item.value != null && item.value) {
            PapiroConsole.log("saveAction => item.value")
            valueOk = true
          }

          if (!evOk && !attachOk && !valueOk)
            hasCompletedChecklist = false
        })
        PapiroConsole.log(hasCompletedChecklist)
        if (!hasCompletedChecklist) {
          setNotificationVariant("error")
          setNotificationMessage(intl.formatMessage({id:"called.organization.checklist.step.fill.activity.checklist"}))
          setOpenNotification(true)
          return false
        }
      }
    }

    e.preventDefault()
    props.setFinishStep(4)
    props.setNavValue("material")
    props.nextStep()
  }

  const previousStep = _ => {
    if(props.StatusPrevious)
      props.setNavValue("status")
    else
      props.setNavValue("action")
    props.previousStep()
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant("error")
    setNotificationMessage("")
  }

  useEffect( () => {
    setLoading(props.setLoading)
  }, [props.setLoading])

  return (
    <form name='CalledOrganizationChecklistForm' onSubmit={saveAction} encType='multipart/form-data' className={classes.form} >
      <Grid container spacing={2} >
        <Grid xs={12} sm={12} style={{ marginLeft: 5, marginTop: 20, padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
          <Grid xs={12} sm={3} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
            <Typography component='h1' variant='h5'>{intl.formatMessage({id:"menu.organization.checklists"})}</Typography>
          </Grid>  
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper style={{ height: 200, minHeight: 200, maxHeight: 200, overflow: 'auto', padding: '0 35px', marginBottom: 15, marginTop: 8 }}>
            <Grid container spacing={2} style={{ marginTop: 5}}>
                {props.CalledOrganizationChecklistList.map(item => {
                return (
                  <Grid item xs={12} sm={12}
                    style={{ padding: '15px 15px 0 15px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={8}>
                        <SimpleCheckBox changeSelect={ props.changeValues } stateName={ 'chk-' + item.id } fullWidth autoComplete='fname' name={ 'chk-' + item.id } selected={ props.Checkstate['chk-' + item.id] } variant='outlined' label={ item.organizationchecklist ? item.organizationchecklist.title : '' } disabled />
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <Tooltip title={intl.formatMessage({id:"checklist.modal.add.evidence.text"})}>
                          <IconButton aria-label="textevidence"
                            onClick={ e => props.openChecklistTextEvidenceModal(item.id, item.organizationchecklist ? item.organizationchecklist.title : '', item.evidencetext) }>
                            {item.evidencetext && item.evidencetext.length > 0 ? (<TextFieldCheckIcon />) : (<TextFieldsIcon />)}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <Tooltip title={intl.formatMessage({id:"checklist.modal.add.evidence.file"})}>
                          <IconButton aria-label="attachevidence"
                            onClick={ e => props.openChecklistImageEvidenceModal(item.id, item.organizationchecklist ? item.organizationchecklist.title : '', item.evidenceattachmentid) }>
                            { item.calledorganizationchecklistattachments && item.calledorganizationchecklistattachments.length > 0 ? (<AttachFileCheckIcon />) : (<AttachFileIcon />)}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                )})}
            </Grid>
          </Paper>
        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
          <Grid item xs={3} sm={3}>
            {props.previous || props.SLAPrevious ?
            (
            <Button
              variant='contained'
              type="button"
              onClick={previousStep}
              fullWidth >
              {intl.formatMessage({id:"previous"})}
            </Button>
            ) : (
              <Button
              variant='contained'
              type="button"
              onClick={props.closeModal}
              fullWidth >
              {intl.formatMessage({id:"cancel"})}
            </Button>
            )}
          </Grid>
          <Grid item xs={3} sm={3}>
            {props.caninformutilizedmaterialsincalledfinish ?(
              <Button
                type='button'
                variant='contained'
                color='primary'
                onClick={saveAction}
                fullWidth >
                {intl.formatMessage({id:"next"})}
              </Button>
              
            ):(
              <Button
                type='button'
                variant='contained'
                color='primary'
                onClick={props.handleSubmit}
                fullWidth >
                {intl.formatMessage({id:"end.called"})}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      </Grid>
      </Grid>

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />

      {loading && (
        <Overlay>
          <CircularProgress color="secondary" />
        </Overlay>
      )}
    </form>
  )
}

CalledOrganizationChecklistStep.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userSession: state.userSession,
  calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledOrganizationChecklistStep))));