import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid, Avatar } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import CustomizedSnackbars from '../../../components/material-snackbars';
import HeaderMenu from '../../../components/header-menu-admin';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../../services/api';
import MyMaterialTable from '../../../components/table';
import { Can } from '../../../config/ability-context'
import { AbilityContext } from '../../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';
import * as componentActions from '../../../redux/actions/multihirer';
import SimpleCheckBox from '../../../components/checkbox/check';
import moment from "moment";
import PhoneInput from 'react-phone-input-2'
import { browserHistory } from "../../../helpers/history";
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';

class MultiHirer extends Component {

  constructor(props) {
    super(props);

    this.typingTimeout = null;

    const intl = this.props.intl;

    let filters = [
      { name: intl.formatMessage({ id: "name" }), id: "name" },
      { name: intl.formatMessage({ id: "email" }), id: "email" },
    ];

    const { userSession } = this.props;
    console.log(this.props)
    console.log("userSession")

    this.initialState = {
      //createdUser: userSession.user.id,
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      name: "",
      active: true,
      document: "",
      website: "",
      responsiblename: "",
      responsiblephone: "",
      responsiblemail: "",
      phone: "",
      blocked: false,
      email: "",
      userHirer: []
    };

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      resultList: [],

      totalPages: 0,
      page: 1,
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      searchValue: '',
      filterSelected: 0,
      filters: filters
    };

    this.closeNotification = this.closeNotification.bind(this);
  }

  componentDidMount() {
  }

  handleInputChange = (e) => {
    

    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));

  }

  mtel = (v) => {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  changeValues = async (stateName, value, text = "") => {
    console.log("AQUI")
    this.setState({ loading: true });

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));


    this.setState({ loading: false });
  }

  handleChangePhone = (e) => {

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        ['phone']: e
      }
    }));
  }

  handleChangeResponsiblePhone = (e) => {

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        ['responsiblephone']: e
      }
    }));
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    let data = new FormData();

    this.setState({ loading: true });

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
      },
    }));

    for (let key in this.state.item) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("phone") > -1
      ) {
        data.append(key, this.state.item[key].replace(/\D/gim, ""));
      } else if (key === "userhirer") {
        let idsString = "";
        if (this.state.item.userHirer.length > 0) {
          this.state.item.userHirer.forEach(
            (uHirer) => (idsString += uHirer.UserId + ",")
          );
        }

        var newStr = idsString.slice(0, -1);
        data.append(key, newStr);
      }  else {
        data.append(key, this.state.item[key]);
      }

    }

    let config = {
      headers: {
        'Content-Type': "multipart/form-data",
        dataType: "json",
      },
    };

    Api.post("/hirer", data, config)
      .then((result) => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState((prevState) => ({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({id:"add.success"}),
            item: this.initialState
          }));
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }
  render() {
    const { classes, headerMenu } = this.props;
    const intl = this.props.intl;
    const { item } = this.state;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md' >
            <CssBaseline />

            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='admin' onClick={browserHistory.goBack}>{this.props.intl.formatMessage({id:"back"})}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {intl.formatMessage({id:"typography.add.item"})}
              </Typography>
            </div>

            <div className={classes.root}>
              <form
                name="myForm"
                className={classes.form}
                encType="multipart/form-data"
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={item.name}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label={"Documento"}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="document"
                      onChange={this.handleInputChange}
                      value={item.document}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <PhoneInput
                      inputStyle={{
                        padding: '23.5px 14px 23.5px 58px',
                        width: '100%'
                      }}
                      margin="normal"
                      id="phone"
                      name="phone"
                      country={'br'}
                      value={item.phone}
                      onChange={this.handleChangePhone}
                      InputLabel={intl.formatMessage({id:"phone"})}
                      specialLabel={intl.formatMessage({id:"phone"})}
                      inputProps={{
                        name: intl.formatMessage({id:"phone"})
                      }} />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Site"
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="website"
                      onChange={this.handleInputChange}
                      value={item.website}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "email" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="email"
                      onChange={this.handleInputChange}
                      value={item.email}
                      source="email"
                      type="email"
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={"Responsável"}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="responsiblename"
                      onChange={this.handleInputChange}
                      value={item.responsiblename}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                  <PhoneInput
                    inputStyle={{
                      padding: '23.5px 14px 23.5px 58px',
                      width: '100%'
                    }}
                    margin="normal"
                    id="responsiblephone"
                    name="responsiblephone"
                    country={'br'}
                    value={item.responsiblephone}
                    onChange={this.handleChangeResponsiblePhone}
                    InputLabel={intl.formatMessage({id:"responsible.phone"})}
                    specialLabel={intl.formatMessage({id:"responsible.phone"})}
                    inputProps={{
                      name: intl.formatMessage({id:"responsible.phone"})
                    }} />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({id:"responsable.email"})}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="responsiblemail"
                      onChange={this.handleInputChange}
                      value={item.responsiblemail}
                      source="email"
                      type="email"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid style={{ textAlign: "left" }} item xs={2} sm={6}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "active" })}
                      name="active"
                      stateName="active"
                      changeSelect={this.changeValues}
                      selected={item.active}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid style={{ textAlign: "left" }} item xs={2} sm={6}>
                    <SimpleCheckBox
                      label={"Bloqueado"}
                      name="blocked"
                      stateName="blocked"
                      changeSelect={this.changeValues}
                      selected={item.blocked}
                    />
                  </Grid>
                </Grid>

                <Grid container justify="flex-end" style={{ marginBottom: '10px' }}  >
                  <Grid item xs={12} sm={4}    >
                    <Button
                      onClick={this.handleSubmit}
                      fullWidth
                      variant='contained'
                      color='admin'
                      className={classes.submit}

                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>

              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}


            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}
MultiHirer.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(MultiHirer)));
MultiHirer.contextType = AbilityContext;
