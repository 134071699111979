import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, ImageList } from '@mui/material';
import { QueuePlayNext, DeleteForever, Height, AttachFile } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import * as CalledService from '../../services/called.service';
import * as CalledOrganizationChecklistService from '../../services/calledorganizationchecklist.service'
import * as OrgDepartamentService from '../../services/organizationdepartament.service';
import { getDepartamentByUserId } from '../../services/user.service'
import { getDepartamentsByOrganizationCategoryIdNew } from '../../services/organizationcategory.service';
import CustomizedSnackbars from '../../components/material-snackbars';
import SimpleSelect from '../../components/select/simple';
import SimpleDatePicker from '../../components/date-pickers/inline';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
//import SimpleTreeView from '../../components/tree-view/tree';
//import { Link } from 'react-router-dom';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteAsynchronousKnowledgebase from './components/auto-complete-async-knowledgebase';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleHelp from '../../components/help';
//import ModalRating from "../../components/ModalRating";
import If from '../../components/conditionals/if'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import * as PriorityService from '../../services/priority.service';
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import SimpleAutoCompleteByName from '../../components/auto-complete/autocomplete-by-name';
import ReactDOM from 'react-dom'
import { PapiroConsole } from '../../utils/papiroConsole';
import SimpleSelectWithColor from '../../components/select/simple-with-color';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class CalledRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    // PapiroConsole.log(this.props);
    //this.isClient = userSession.user.role.roleType === 3;
    this.globalAdm = false;
    this.AdmOrganization = false;
    this.isClient = true;
    this.isTec = false;

    this.idCalled = null;
    this.noteUser = null
    this.descriptionCalled = undefined;
    this.lastAccompaniment = null
    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
      if (item.role.roletype == 1) {
        this.AdmOrganization = true;
      }
      if (item.role.roletype == 2 || item.role.roletype == 4) {
        this.isTec = true;
        // PapiroConsole.log('entrei aqui')
      }
    }
    );

    this.canselectpatrimonyincalled = false;

    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }
  
    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]

    this.categoryId = null;
    this.organizationId = null;
    if (this.isClient || this.AdmOrganization) {
      if (!this.globalAdm) {
        // PapiroConsole.log(userSession.user);
        if (userSession.user.organizationlistid) {
          this.organizationId = userSession.user.organizationlistid;
          PapiroConsole.log("=== userSession.user.organizationlistid ===")
          PapiroConsole.log(userSession.user.organizationlistid)
        }
      }
    }

    const defaultvalue = { id: 0, name: '' };

    this.addressInitialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      UserText: '',
      template: true,
      template2: false,
      name: '',
      street: '',
      zipcode: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',
      longitude: '',
      latitude: '',
      keepaddress: false,
      iscustomaddress : true, 
      saveToAllOrganizationsInGroup: false,
    };

    this.initialCalledState = {
      requestingUser: userSession.user.id,
      createdUser: userSession.user.id,
      organization: this.organizationId,
      type: 0,
      typeDisabled: false,
      priorityDisabled: false,
      keepcalled: false,
      CategoryId: defaultvalue,
      categoryText: '',
      ParentCalledId: 0,
      ParentCalledText: '',
      dueDate: moment().endOf('day').add('days', 1).format("YYYY/MM/DD HH:mm:ss"),
      title: "",
      status: 0,
      description: "",
      AddressId: 0,
      originRequest: 0,
      observantUser: 0,
      assignedUser: 0,
      patrimonyId: "",
      departamentId: 0,
      priorityid: 1,
      newCategoryDepartament: false,
      hasdisplacementTrue: true,
      hasdisplacementFalse: false,
      knowledgebaseattachments: [],
      addresstype: "1"
    };

    this.categoriesByLevelsInitialState = {
      firstCategoryId: defaultvalue,
    }

    this.levelCategoriesInitialState = {
      rootCategoriesList: [],
    }

    this.levelCategoryId = 1

    this.state = {
      called: this.initialCalledState,
      address: this.addressInitialState,
      categoriesByLevels: this.categoriesByLevelsInitialState,
      search: {
        knowledgebase: '/knowledgebases'
      },
      canassingusercalled: false,
      canselectorganizationcategoriesbylevels: false,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      notificationLinkMove: "",
      knowledgebase: null,
      knowledgeBaseList: [],
      typesList: [],
      categoriesList: [],
      parentCalledsList: [],
      technicalUsersList: [],
      usersList: [],
      clientUsersList: [],
      organizationAddressesList: [],
      userAddressesList: [],
      organizationsList: [],
      patrimonyList: [],
      organizationFieldList: [],
      userId: userSession.user.id,
      organizationDepartamentList: [],
      userDepartamentId: 0,
      DepartamentList: [],
      priorityList: [],
      openDialogNewAttendence: false,
      valueNewAttendence: 0.00,
      newAttendenceAccept: false,

      openDialogNewDisk: false,
      valueNewDisk: 0.00,
      newDiskAccept: false,
      knowledgeBaseDialogOpen: false,
      levelCategories: this.levelCategoriesInitialState,
      levelCategoryId: 0,
      categoryChildrens: [ defaultvalue ],
      categoryLists: [],
      addresstype: 1,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeAddress = this.handleInputChangeAddress.bind(this)
    this.randomIntFromInterval = this.randomIntFromInterval.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleRedirection = this.handleRedirection.bind(this);
    this.changeValues = this.changeValues.bind(this);
	  this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.getUserAddresses = this.getUserAddresses.bind(this)
    this.getCep = this.getCep.bind(this)
    this.convertHtmlOption = this.convertHtmlOption.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  convertHtmlOption = value => {
    let body = value
    let temp = document.createElement("div")
    temp.innerHTML = body
    let sanitized = temp.textContent || temp.innerText

    PapiroConsole.log("=== convertHtmlOption ===")
    PapiroConsole.log(temp.textContent)
    PapiroConsole.log(temp.textContent.length)
    PapiroConsole.log(temp.innerText)
    PapiroConsole.log(temp.innerText.length)
    
    return sanitized
  }

  openDialogNewAttendence = async () => {
    this.setState({ openDialogNewAttendence: !this.state.openDialogNewAttendence });
  }

  openDialogNewDisk = async () => {
    this.setState({ openDialogNewDisk: !this.state.openDialogNewDisk });
  }

  openKnowledgeBaseDialog = () => this.setState({ knowledgeBaseDialogOpen: true })

  closeKnowledgeBaseDialog = () => this.setState({ knowledgeBaseDialogOpen: false })

  async componentDidMount() {

    this.setState({loading: true});
    await this.getTypes();
    await this.getPriorities()
    await this.getDepartamentByUserId()
    await this.getOrganizations();
    if (this.isTec === true) {
      await this.getItens();
    }
    this.setState({loading: false});

  };

  getPriorities = async (isLoading = false) => {
    if(isLoading)
      this.setState({ loading: true });
    let result = await PriorityService.getAllByHirerId()
    if(result && result.success) {
      if(result.data != null)
        this.setState({ priorityList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data })
      if(isLoading)
        this.setState({ loading: false });
    } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
    }
  }

  getAccompaniment(id) {
    if (id == null) {
      return false;
    }
    Api.get(`accompaniments/getAllByCalled/${id}`)
      .then(result => {

        if (result.data.success) {
          // PapiroConsole.log(result.data)
          this.lastAccompaniment = result.data.data[result.data.data.length - 1].description
          // PapiroConsole.log(this.lastAccompaniment)


        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "add.action.success" }),
            files: [],
            action: {
              description: "",
              privacy: "false"
            }
          });
        }
      }
      )
  }

  async getTypes(isLoading = false) {
    if(isLoading)
      this.setState({ loading: true });

    const result = await CalledService.getTypes();

    if (result.success) {
      this.setState({ typesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
      if(isLoading)
        this.setState({ loading: false });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getPatrimonios(id) {

    const result = await CalledService.getPatrimonyByOrg(id);
    // PapiroConsole.log(result);
    if (result.success) {
      this.setState({ patrimonyList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getDepartamentsByOrganizationCategoryId(id) {
    this.setState({ loading: true });
    var result = await getDepartamentsByOrganizationCategoryIdNew(id);
    if (result.success) {
      if (result.data && result.data.length > 0) {
        if (this.isClient) {
          if (this.state.called.departamentId && this.state.called.departamentId.id) {
            var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)
            if (updatedList)
              updatedList = updatedList[0]

            if (result.data.length == 1) {
              this.setState(prevState => ({
                called: {
                  ...prevState.called,
                  departamentId: result.data[0]
                }
              }))
            }

            this.setState({ loading: false, DepartamentList: updatedList && updatedList.length > 1 ? updatedList.sort((a, b) => a.name.localeCompare(b.name)) : updatedList });
          }
        } else if (this.isTec) {
          if (this.state.called.departamentId && this.state.called.departamentId.id) {
            var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)

            if (result.data.length == 1) {
              this.setState(prevState => ({
                called: {
                  ...prevState.called,
                  departamentId: result.data[0]
                }
              }))
            }

            this.setState({ loading: false, DepartamentList: updatedList && updatedList.length > 1 ? updatedList.sort((a, b) => a.name.localeCompare(b.name)) : updatedList });
          } else {
            /*if(result.data.length == 1) {
              this.setState(prevState => ({
                called: {
                  ...prevState,
                  departamentId: result.data[0]
                }
              }))
            }*/

            this.setState({ loading: false, DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
          }
        } else {
          /*if(result.data.length == 1) {
            this.setState(prevState => ({
              called: {
                ...prevState,
                departamentId: result.data[0]
              }
            }))
          }*/

          this.setState({ loading: false, DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
        }
        this.setState({ newCategoryDepartament: false })
      } else {
        PapiroConsole.log("=== this.getOrganizationDepartaments ===")
        PapiroConsole.log(this.state.called.organization)
        this.getOrganizationDepartaments(this.state.called.organization.id)
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getOrganizationDepartaments(id) {
    var result = await OrgDepartamentService.getOrganizationsDepartaments(id);
    if (result.success) {
      this.setState({ DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data, newCategoryDepartament: true });
    } else {
      const intl = this.props.intl;
      this.setState({
        newCategoryDepartament: false,
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getDepartamentByUserId(isLoading = false) {
    const { userSession } = this.props;
    if(isLoading)
      this.setState({ loading: true });

    var result = await getDepartamentByUserId(userSession.user.id);
    if (result.success) {
      this.setState({ userDepartamentId: result.data });
      if(isLoading)
        this.setState({ loading: false });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  }

  async getCategories() {
    this.setState({ loading: true });

    var result = await CalledService.getAllCategoriesWithParent();

    if (result.success) {
      this.setState({ loading: false, categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };
  async getCategoriesByOrganization(organizationId) {
    const intl = this.props.intl;
    var result = await CalledService.getCategoriesByOrganization(organizationId);

    if (result.success) {
      if (this.isTec == false) {
        if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
          if (this.AdmOrganization) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"}),
              notificationLinkMove: "organizationcategories"
            });
          } else {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"})
            });
          }
        }
      }

      this.setState({ categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  getRootCategoriesByOrganization = async (organizationId) => {
    const intl = this.props.intl;

    var result = await CalledService.getRootCategoriesByOrganization(organizationId);
    if (result.success) {
      if (this.isTec == false) {
        if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
          if (this.AdmOrganization) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"}),
              notificationLinkMove: "organizationcategories"
            });
          } else {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"})
            });
          }
        }
      }
      // <Grid item xs={1} sm={1} style={{ display: 'flex', alignItems: 'center', fontSize: '2em' }}>&gt;</Grid>
      this.setState({
        levelCategories: {
          rootCategoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
        },
        categoryLists: [
          result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
        ]
      });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  GoodRender = (thing, place) => {
    let extradiv = document.getElementsByClassName('categoriesByLevels')[0];
    ReactDOM.render(thing, extradiv);
    extradiv = document.getElementsByClassName('categoriesByLevels')[0];
    place.innerHTML += extradiv.innerHTML;
  }

  getByParentOrganizationCategoryId = async (parentId, stateName = '') => {
    const intl = this.props.intl;

    PapiroConsole.log("=== getByParentOrganizationCategoryId.map ===")
    PapiroConsole.log(stateName)
    
    var result = await CalledService.getByParentOrganizationCategoryId(parentId);
    if (result && result.success) {
      let firstCategoryId = null
      let rootCategoriesList = null
      if(stateName.includes("lcat-")) {
        let stateNameSplit = stateName.split("-")
        let stateNameId = stateNameSplit[1]
        stateNameId = parseInt(stateNameId)

        let listLevelCatArr = []
        let levelCatArr = []

        Object.keys(this.state.levelCategories).forEach((key) => {
          if (key.includes("lcatlist-")) {
            let lCatListSplit = key.split("-")
            let lCatListId = lCatListSplit[1]
            lCatListId = parseInt(lCatListId)

            PapiroConsole.log("=== Object.keys(this.state.levelCategories) ===")
            PapiroConsole.log(lCatListId)
            PapiroConsole.log(stateNameId)

            if(lCatListId < stateNameId)
              listLevelCatArr.push(this.state.levelCategories[key])
          } else
            rootCategoriesList = this.state.levelCategories[key] && this.state.levelCategories[key].length > 1 ? this.state.levelCategories[key].sort((a, b) => a.name.localeCompare(b.name)) : this.state.levelCategories[key]
        })
        Object.keys(this.state.categoriesByLevels).forEach((key) => {
          if (key.includes("lcat-")) {
            let lCatSplit = key.split("-")
            let lCatId = lCatSplit[1]
            lCatId = parseInt(lCatId)

            PapiroConsole.log("=== Object.keys(this.state.categoriesByLevels) ===")
            PapiroConsole.log(lCatId)
            PapiroConsole.log(stateNameId)

            if(lCatId < stateNameId)
              levelCatArr.push(this.state.categoriesByLevels[key])
          } else
            firstCategoryId = this.state.categoriesByLevels[key]
        })

        await this.setState({
          levelCategories: {
            rootCategoriesList: rootCategoriesList && rootCategoriesList.length > 1 ? rootCategoriesList.sort((a, b) => a.name.localeCompare(b.name)) : rootCategoriesList,
            ...listLevelCatArr,
          }
        })
        await this.setState({
          categoriesByLevels: {
            firstCategoryId: firstCategoryId,
            ...levelCatArr,
          }
        })

        let childrens =
          <Grid item xs={11} sm={3}>
            <SimpleAutoCompleteByName
              intl={intl}
              label={intl.formatMessage({ id: "category" })}
              options={this.state.levelCategories.rootCategoriesList}
              stateName='firstCategoryId'
              name='firstCategoryId'
              changeSelect={this.changeValuesCategoriesByLevels}
              selected={this.state.categoriesByLevels.firstCategoryId} required />
          </Grid>
        
        let levelCategoryId = 1

        listLevelCatArr.map((item, key) => {
          let lCatListSplit = key.split("-")
          let lCatListId = lCatListSplit[1]

          PapiroConsole.log("=== listLevelCatArr.map ===")
          PapiroConsole.log(key)
          PapiroConsole.log(levelCategoryId)

          if(parseInt(lCatListId) > levelCategoryId)
            levelCategoryId = lCatListId

          childrens += <Grid item xs={11} sm={3}>
              <SimpleAutoCompleteByName
                intl={intl}
                label={intl.formatMessage({ id: "category" })}
                options={this.state.levelCategories[`lcatlist-${lCatListId}`] || []}
                stateName={`lcat-${lCatListId}`}
                name={`lcat-${lCatListId}`}
                changeSelect={this.changeValuesCategoriesByLevels}
                selected={this.state.categoriesByLevels[`lcat-${lCatListId}`]} required />
            </Grid>

          return { levelCategoryId: levelCategoryId }
        })

        let rootElement = document.getElementById("categoriesByLevels")
        rootElement && ReactDOM.render(
          childrens,
          rootElement
        )

        this.levelCategoryId = levelCategoryId
        await this.setState({ levelCategoryId: levelCategoryId })
      } else {
        await this.setState({
          levelCategories: {
            rootCategoriesList: this.state.levelCategories.rootCategoriesList && this.state.levelCategories.rootCategoriesList.length > 1 ? this.state.levelCategories.rootCategoriesList.sort((a, b) => a.name.localeCompare(b.name)) : this.state.levelCategories.rootCategoriesList,
          }
        })
        await this.setState({
          categoriesByLevels: {
            firstCategoryId: this.state.categoriesByLevels.firstCategoryId,
          }
        })

        let childrens =
          <Grid item xs={11} sm={3}>
            <SimpleAutoCompleteByName
              intl={intl}
              label={intl.formatMessage({ id: "category" })}
              options={this.state.levelCategories.rootCategoriesList}
              stateName='firstCategoryId'
              name='firstCategoryId'
              changeSelect={this.changeValuesCategoriesByLevels}
              selected={this.state.categoriesByLevels.firstCategoryId} required />
          </Grid>
        
        let rootElement = document.getElementById("categoriesByLevels")
        rootElement && ReactDOM.render(
          childrens,
          rootElement
        )

        await this.setState({ levelCategoryId: 1 })
        this.levelCategoryId =  1
      }

      if(result.data && result.data.length > 0) {
        let levelCategoryId = this.state.levelCategoryId + 1
        await this.setState({ levelCategoryId: levelCategoryId })
        this.levelCategoryId = this.levelCategoryId + 1
        
        this.setState(prevState=> ({
          levelCategories: {
            ...prevState.levelCategories,
            [`lcatlist-${this.levelCategoryId}`]: result.data,
          },
          categoriesByLevels: {
            ...prevState.categoriesByLevels,
            [`lcat-${this.levelCategoryId}`]: { id: 0, name: '' }
          }
        }), () => {
          let rootElement = document.getElementById("categoriesByLevels")
          rootElement && ReactDOM.render(
            ReactDOM.createPortal(
              <Grid item xs={1} sm={1} style={{ display: 'flex', alignItems: 'center', fontSize: '2em' }}>&gt;</Grid>,
              rootElement
            ),
            document.createElement('div')
          )

          rootElement = document.getElementById("categoriesByLevels")
          rootElement && ReactDOM.render(
            ReactDOM.createPortal(
              <Grid item xs={11} sm={3}>
                <SimpleAutoCompleteByName
                  intl={intl}
                  label={intl.formatMessage({ id: "category" })}
                  options={this.state.levelCategories[`lcatlist-${this.levelCategoryId}`] || []}
                  stateName={`lcat-${this.levelCategoryId}`}
                  name={`lcat-${this.levelCategoryId}`}
                  changeSelect={this.changeValuesCategoriesByLevels}
                  selected={this.state.categoriesByLevels[`lcat-${this.levelCategoryId}`]} required />
              </Grid>,
              rootElement
            ),
            document.createElement('div')
          )
        });
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };



  async getOrganizationFields(organizationId) {

    var result = await CalledOrganizationChecklistService.getActiveOrganizationFieldsByOrganization(organizationId);

    if (result.success) {
      this.setState({ organizationFieldList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };


  async getParentCalleds(organizationid) {

    var result = await CalledService.getParentCalled(organizationid);

    if (result.success) {

      let parents = result.data.map(item => {
        return {
          ...item,
          name: `${item.id}: ${item.title}`,
          parentname: `${item.id}: ${item.title}`
        }

      })

      await this.setState({ parentCalledsList: parents && parents.length > 1 ? parents.sort((a, b) => {
        if (a.name && a.name.length > 0)
         return a.name.localeCompare(b.name)
        else
          return a.parentname.localeCompare(b.parentname)
      }) : parents });
      // PapiroConsole.log(this.state.parentCalledsList);
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getOrganizations(isLoading = false) {
    if(isLoading)
      this.setState({ loading: true });
    const { userSession } = this.props
    let orgData = []
    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      orgData = userSession.user.userorganizations
    } else {
      const result = await CalledService.getOrganizations()
      if (result.success)
        orgData = result.data
      else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);
      }
    }

    if(orgData != null) {
      if (this.globalAdm) {
        this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
        if(isLoading)
          this.setState({ loading: false });
      }
      else {
        //PapiroConsole.log("this.organizationId : "+this.organizationId);
        this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
        await this.updateOrganization(this.organizationId);
        this.setState({ organization: this.organizationId });
        if(isLoading)
          this.setState({ loading: false });
      }
      if(this.globalAdm || this.AdmOrganization) {
        if(orgData && orgData.length == 1) {
          this.setState((prevState) => ({
            called: {
              ...prevState.called,
              organization: orgData[0]
            }
          }), () => {
            this.organizationId = orgData[0].id
            this.updateOrganization(this.organizationId);
          })
          
        }
      }
    } else {
      orgData = []
      this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
      if(isLoading)
        this.setState({ loading: false });
    }
  };




  async getOrganizationsByCategory(categoryId) {
    this.setState({ loading: true });

    const result = await CalledService.getOrganizationsByCategory(categoryId);
    // PapiroConsole.log(result);
    if (result.success) {
      if (this.globalAdm) {
        this.setState({ loading: false, organizationsList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
      }
      else {
        var filteredOrg = result.data.filter(org => org.id == this.organizationId)
        this.setState({ organizationsList: filteredOrg && filteredOrg.length > 1 ? filteredOrg.sort((a, b) => a.name.localeCompare(b.name)) : filteredOrg });

      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };


  async getRequestingUsers(organizationId, locationid, isLoading = false) {
    if(isLoading)
      this.setState({ loading: true });

    const result = await CalledService.getRequestingUsersByLocation(organizationId, locationid);
    const intl = this.props.intl;

    if (result.success) {
      if (this.isTec == false) {
        if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
          if (this.AdmOrganization) {
            this.setState({
              loading: false, openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.requester"}),
              notificationLinkMove: "users"
            });
          } else {
            this.setState({
              loading: false, openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.requester"})
            });
          }
        }
      }
      let reordernewarrayuserteclist = new Array();
      result.data.map(item => {
        item.name =  item.name + " ("+item.email+")";
        reordernewarrayuserteclist.push(item);
      })
      this.setState({ clientUsersList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
      if(isLoading)
        this.setState({ loading: false });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };


  async getTechnicalUsers(categoryId, organizationId) {
    if (categoryId && categoryId > 0) {
      this.setState({ loading: true });

      const result = await CalledService.getTechnicalUsers(categoryId, organizationId);
      const intl = this.props.intl;

      if (result.success) {
        if(result.data.length > 0) {
          let reordernewarrayuserteclist = new Array();
          result.data.map(item => {
              item.name =  item.name + " ("+item.email+")";
              reordernewarrayuserteclist.push(item);
          })
          this.setState({ technicalUsersList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "info",
            notificationMessage: intl.formatMessage({id:"called.registration.category.missing.team"})
          })
        }
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);

      }
    }
  };

  async getOrganizationCategoryDepartaments(organizationCategoryId) {
    this.setState({ loading: true })

    const result = await CalledService.getOrganizationCategoryDepartaments(organizationCategoryId)

    if (result.success) {
      this.setState({ loading: false })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.erros ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  async getUsersExceptClients(organizationId) {
    this.setState({ loading: true });

    const result = await CalledService.getUsersExceptClients(organizationId);

    if (result.success) {
      this.setState({ loading: false, usersList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getOrganizationAddresses(organizationId) {

    const result = await CalledService.getOrganizationAddresses(organizationId);
    const intl = this.props.intl;


    if (result.success) {
      if (this.isTec == false) {
        if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
          if (this.AdmOrganization) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.address"}),
              notificationLinkMove: "organizations"
            });
          } else {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.address"})
            });
          }
        }
      }

      if(this.globalAdm || this.AdmOrganization) {
        if(result.data && result.data.length == 1) {
          PapiroConsole.log("=== AddressId ===")
          PapiroConsole.log(this.state.called)
          PapiroConsole.log(this.organizationId)

          this.setState(prevState => ({
            called: {
              ...prevState.called,
              AddressId: result.data[0]
            }
          }), () => { 
            PapiroConsole.log("=== After SetState ===")
            PapiroConsole.log(this.state.called)
            this.getRequestingUsers(this.organizationId, this.state.called.AddressId.id)
           })
        }
      }

      this.setState({ organizationAddressesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getUserAddresses(userId) {
    const intl = this.props.intl;
    this.setState({ loading: true })
    const result = await CalledService.getUserAddresses(userId);
    if (result.success) {
      if (this.globalAdm || this.AdmOrganization) {
        if (result.data.length < 1) {
          if (this.AdmOrganization) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"user.no.registered.addresses"}),
              notificationLinkMove: "organizations"
            });
          } else {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"user.no.registered.addresses"})
            });
          }
        }
      }

      if(this.globalAdm || this.AdmOrganization) {
        if(result.data && result.data.length == 1) {
          PapiroConsole.log("=== AddressId ===")
          PapiroConsole.log(this.state.called)
          PapiroConsole.log(this.organizationId)

          this.setState(prevState => ({
            called: {
              ...prevState.called,
              AddressId: result.data[0]
            }
          }))
        }
      }

      this.setState({ loading: false, userAddressesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getClientsByOrganizationId() {

    this.setState({ loading: true })

    const result = await CalledService.getClientsByOrganizationId(this.organizationId);
    const intl = this.props.intl;

    if (result.success) {
      if (this.isTec == false) {
        if (result.data.length < 1 && this.organizationId.toString().indexOf(",") == -1) {
          if (this.AdmOrganization) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.requester"}),
              notificationLinkMove: "users"
            });
          } else {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.requester"})
            });
          }
        }
      }
      let reordernewarrayuserteclist = new Array();
      result.data.map(item => {
        item.name =  item.name + " ("+item.email+")";
        reordernewarrayuserteclist.push(item);
      })

      this.setState({ loading: false, clientUsersList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getItens(page = 1) {
    let idOrganization = null;
    const { userSession } = this.props;
    this.setState({ loading: true });
    //PapiroConsole.log(this.state.userId)

    let url = `/calleds/classifications/paginate/${this.state.userId}?page=${page}`;

    if (this.state.searchValue != "" && this.state.filterSelected != 0) {
      url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`;
    }
    //PapiroConsole.log(this.state.userId);
    Api.get(url)
      .then((result) => {
        if (result.data.success) {


          // PapiroConsole.log(result.data)


        }
      })
      .catch((err) => {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response &&
              err.response.data &&
              err.response.data.errors &&
              err.response.data.errors[0]
              ? err.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(err);
      });
  }


  async getOrganizationSettings() {
    const intl = this.props.intl;
    let infos;
    if (this.organizationId == 0 || this.organizationId == null) {
      return;
    }
    await Api.get(`/organizationsettings/organization/${this.organizationId}`)
      .then(result => {
        if (result.data.success) {
          if (result.data.length < 1) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.registration.missing.organization.config"})
            });
          }
          infos = result.data.data;
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);

        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);

      });
    return infos;
  };

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  async getCep(cepNumber) {
    return new Promise(function(resolve, reject) {
      
      fetch("https://viacep.com.br/ws/".concat(cepNumber, "/json/")).then(function (response) {
        return response.json();
      }).then(function (data) {
        // console.log(data);
        resolve(data);
      })["catch"](function (err) {
        // console.log(err);
        reject(err);
      })["finally"](function (data) {
        return data;
      });

    });
  }

  async handleInputChangeAddress(e) {
    const name = e.target.name;
    const value = e.target.value;

    if(name == 'zipcode'){
      if(e.target.value === '' || /^\d+$/.test(e.target.value)) { 
        this.setState(prevState => ({
          address: {
            ...prevState.address,
            [name]: value
          }
        }));
        if(value.length == 8){
          this.setState({ loading: true });
          var data = await this.getCep(value);
          this.setState(prevState => ({
            address: {
              ...prevState.address,
              ['street']: data.logradouro,
              ['complement']: data.complemento,
              ['neighborhood']: data.bairro,
              ['city']: data.localidade,
              ['state']: data.uf,
              ['country']: 'BRASIL',
            }
          }));
          this.setState({ loading: false });
        }
      } else { 
          return false; 
      } 
    } else {
      this.setState(prevState => ({
        address: {
          ...prevState.address,
          [name]: value
        }
      }));
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      called: {
        ...prevState.called,
        [name]: value
      }
    }));
  };

  handleChange = async (e) => {
    let value = e.target.value

    this.setState(prevState => ({
      called: {
        ...prevState.called,
        addresstype: value,
        AddressId: 0
      }
    }))

    if (value == 2 || value == 3)
      await this.getClientsByOrganizationId()
  }

  handleChangeEditor(value) {
    //this.setState({ description: value });

	this.setState(prevState => ({
		called: {
		  ...prevState.called,
		  description: value
		}
	  }));


  }
 
  checkSubmit = () => {
    const intl = this.props.intl;

    let calledDesc = this.convertHtmlOption(this.state.called.description)
    calledDesc = calledDesc.trim()
    if(calledDesc == null || (calledDesc != null && calledDesc.length == 0 )) {
      this.setState({
        loading: false,
        loadingTwo: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.called.description"})
      });
      return false;
    }

    if(this.state.called.priorityid == null || (this.state.called.priorityid && this.state.called.priorityid == 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"called.registration.missing.priority.field"})
      })
      return false
    }

    PapiroConsole.log("=== canselectorganizationcategoriesbylevels ===")
    PapiroConsole.log(this.state.canselectorganizationcategoriesbylevels)
    if(this.state.canselectorganizationcategoriesbylevels === true && this.state.categoryChildrens && this.state.categoryChildrens.length > 0) {
      let lastItem = this.state.categoryChildrens[this.state.categoryChildrens.length - 1]
      PapiroConsole.log("=== lastItem ===")
      PapiroConsole.log(lastItem)
      if(lastItem && lastItem.cancreatecalled != null && lastItem.cancreatecalled === false) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"called.registration.create.error.category"})
        })
        return false
      } else if(lastItem && lastItem.id == 0) {
        if(this.state.categoryChildrens[this.state.categoryChildrens.length - 2] !== undefined) {
          lastItem = this.state.categoryChildrens[this.state.categoryChildrens.length - 2]
          PapiroConsole.log("=== lastItem 2 ===")
          PapiroConsole.log(lastItem)
          if(lastItem && lastItem.cancreatecalled != null && lastItem.cancreatecalled === false) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.registration.create.error.category"})
            })
            return false
          }
        }
      }
    }
    
    if(this.state.called.CategoryId == null || ((this.state.called.CategoryId && this.state.called.CategoryId == 0) || (this.state.called.CategoryId && this.state.called.CategoryId.id && this.state.called.CategoryId.id == 0))) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"called.registration.missing.category.field"})
      })
      return false
    }

    return true
  }

  handleSubmit = async e => {
    const intl = this.props.intl;

    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();
    if(this.checkSubmit()) {
      let addressData = null
      if (this.state.called.addresstype == "3") {
        if(this.state.address.name == null || !this.state.address.name) {
          this.setState({
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"missing.name.field"})
          })
          return false
      }
        
        let addressConfig = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
  
        let addressData = new FormData()
        for (let key in this.state.address) {
          if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
            addressData.append(key, moment(this.state.address[key]).format('YYYY/MM/DD HH:mm:ss'));
          } else {
            addressData.append(key, this.state.address[key]);
          }
        }

        addressData.append("organization", this.state.called.organization.id)
  
        await Api.post('/addresses', addressData, addressConfig)
          .then(result => {
            if (result.data.success) {
              addressData = result.data.data
              this.setState(prevState => ({
                called: {
                  ...prevState.called,
                  AddressId: result.data.data,
                },
                address: this.addressInitialState
              }))
            }
          })
          .catch(err => {
            if(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]){
              if(!isNaN(err.response.data.errors[0])){
                if(err.response.data.errors[0] == 5){
                  this.setState({
                    loading: false,
                    openDialogNewDisk: true,
                    valueNewDisk: err.response.data.errors[0],
                  });
                }else{
                  this.setState({
                    loading: false,
                    openDialogNewAttendence: true,
                    valueNewAttendence: err.response.data.errors[0],
                  });
                }
                
  
              }else{
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage:
                    err.response && err.response.data && err.response.data.errors
                      ? err.response.data.errors[0]
                      : intl.formatMessage({ id: "process.error" }),
                });
              }
              
            }else if (err.response && err.response.data && err.response.data.errors) {
  
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
              });
            }
            else {
  
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "process.error" })
              });
            }
          })
      }

    const clientInfos = await this.getOrganizationSettings();

    if (clientInfos) {
      if (this.isClient) {
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            CategoryId: clientInfos.category.id,
            originRequest: clientInfos.originrequest.id,
            status: clientInfos.status.id,
            type: clientInfos.type.id,
          }
        }));
      } else {
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            originRequest: clientInfos.originrequest.id,
            status: clientInfos.status.id,
          }
        }));
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let calledOrganizationFieldList = []

      if(this.state.newAttendenceAccept){
        data.append("acceptNewAttendence", true);
        this.setState({ newAttendenceAccept: false });
      }

      if(this.state.newDiskAccept){
        data.append("AcceptNewDisk", true);
        this.setState({ newDiskAccept: false });
      }

      for (let key in this.state.called) {

        if (key === "ParentCalledId") {
          if (this.state.called.ParentCalledId !== 0) {
            data.append("ParentCalledId", this.state.called.ParentCalledId.id);
          }
        }
        if (key === 'dueDate') {
          data.append(key, moment(this.state.called[key]).format("YYYY/MM/DD HH:mm:ss"));
        }
        else if (key.toString().toLowerCase().indexOf('categoryid') > -1) {
          data.append("OrganizationCategory", this.state.called[key].id);
        } else if (key.toString().toLowerCase().indexOf('departamentid') > -1) {
          let deptVal = this.state.called[key].id
          if (deptVal == null || typeof deptVal === 'undefined')
            deptVal = 0
          data.append("OrganizationDepartamentId", deptVal);
        } else if (key.toString().toLowerCase().indexOf('requestinguser') > -1) {
          if (this.state.called[key].id) {
            data.append("requestingUser", this.state.called[key].id);
          } else {
            data.append("requestingUser", 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('assigneduser') > -1) {
          if (this.state.called[key].id) {
            data.append("assignedUser", this.state.called[key].id);
          } else {
            data.append("assignedUser", 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('observantuser') > -1) {
          if (this.state.called[key].id) {
            data.append("observantUser", this.state.called[key].id);
          } else {
            data.append("observantUser", 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('addressid') > -1) {
          PapiroConsole.log("=== addressid ===")
          PapiroConsole.log(this.state.called[key])
          PapiroConsole.log("this.state.called.addresstype =>")
          PapiroConsole.log(this.state.called.addresstype)
          PapiroConsole.log(typeof this.state.called.addresstype)
          PapiroConsole.log("addressData =>")
          PapiroConsole.log(addressData)
          if (this.state.called[key].id) {
            if (this.state.called[key].id > 0)
              data.append("AddressId", this.state.called[key].id);
            else if (this.state.called.addresstype == "3" && addressData != null) {
              data.append("AddressId", addressData.id);
            }
          } else {
            if (this.state.called.addresstype == "3" && addressData != null)
              data.append("AddressId", addressData.id);
            else
              data.append("AddressId", 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('organization') > -1) {
          if (this.state.called[key].id) {
            data.append("organization", this.state.called[key].id);
          } else {
            data.append("organization", 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('addresstype') > -1) {
          let addresstypeNumber = parseInt(this.state.called[key])
          if (!isNaN(addresstypeNumber))
            data.append("addresstype", addresstypeNumber);
          else
            data.append("addresstype", 1);
        }
        else {
          if (key.toString().toLowerCase().indexOf("ofield-") > -1) {
            calledOrganizationFieldList.push(this.state.called.key)
          } else
            data.append(key, this.state.called[key]);
        }
      }

      var SecurityPassword = this.randomIntFromInterval(1000, 99999)

      data.append("SecurityPassword", SecurityPassword.toString());

      let list = this.state.called['patrimonyId'];

      if (list != null && list != "") {

        let result = "";

        for (let value in this.state.called['patrimonyId']) {
          result = this.state.called['patrimonyId'][value].id + "," + result
        }

        data.append("calledOrganizationPatrimonyId", result);
      }

      let listKnowledgebaseAttachments = this.state.called.knowledgebaseattachments
      if(listKnowledgebaseAttachments != null && listKnowledgebaseAttachments != "") {
        let knowledgeBaseAttachmentsIds = ""
        listKnowledgebaseAttachments.map(item => {
          knowledgeBaseAttachmentsIds = `${item.id},${knowledgeBaseAttachmentsIds}`
        })

        data.append("knowledgeBaseAttachmentsIds", knowledgeBaseAttachmentsIds)
      }

      data.append("newCategoryDepartament", this.state.newCategoryDepartament)

      let orgFields = ""
      let orgFieldValues = ""
      Object.keys(this.state.called).forEach((key) => {
        if (key.includes("ofield-")) {
          let val = this.state.called[key]
          let explode = key.split('-')
          let organizationFieldId = explode[1];

          orgFields = `${organizationFieldId},${orgFields}`
          orgFieldValues = `${val},${orgFieldValues}`
          data.append("OrgFieldIdsList", parseInt(organizationFieldId))
          data.append("OrgFieldValuesList", val)
        }
      })
      data.append("orgfields", orgFields)
      data.append("orgfieldvalues", orgFieldValues)

      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      PapiroConsole.log("Is Here")
      PapiroConsole.log(this.state.called)

     await Api.post("/calleds", data, config)
        .then(result => {
          
          if (result.data.success && result.data.data != null) {

            Object.keys(this.state.called).forEach((key) => {
              if (key.includes("ofield-")) {

                let val = this.state.called[key]
                let explode = key.split('-')
                let organizationFieldId = explode[1];

                let dataCallOrgField = new FormData();
                dataCallOrgField.append('OrganizationFieldId', organizationFieldId);
                dataCallOrgField.append('CalledId', result.data.data.id);
                dataCallOrgField.append('Value', val);
                dataCallOrgField.append('CreatedUser', this.state.userId);
                /*Api.post("/calledorganizationfields", dataCallOrgField)
                  .then(result => {
                    if (result.data.success) {

                    }
                  })
                  .catch(err => {

                    const intl = this.props.intl;
                    this.setState({
                      loading: false,
                      openNotification: true,
                      notificationVariant: "error",
                      notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                  });*/
              }
            })
            if (!this.state.called.keepcalled) {
              this.setState({
                called: this.initialCalledState,
                files: []
              })
              Object.keys(this.state.called).forEach((key) => {
                if (key.includes("ofield-")) {
                  this.setState(prevState => ({
                    called: {
                      ...prevState.called,
                      [key]: ''
                    },
                    [key]: ''
                  }))
                }
              })
            }
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({ id: "add.success" }),
              notificationLinkMove: "",
              
              openDialogNewAttendence: false,
              openDialogNewDisk: false,
            });
          }
        })
        .catch(err => {
          PapiroConsole.log(err)
          PapiroConsole.log("result")

          if(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]){
            if(!isNaN(err.response.data.errors[0])){
              if(err.response.data.errors[0] == 5){
                this.setState({
                  loading: false,
                  openDialogNewDisk: true,
                  valueNewDisk: err.response.data.errors[0],
                });
              }else{
                this.setState({
                  loading: false,
                  openDialogNewAttendence: true,
                  valueNewAttendence: err.response.data.errors[0],
                });
              }
              
  
            }else{
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data && err.response.data.errors
                    ? err.response.data.errors[0]
                    : intl.formatMessage({ id: "process.error" }),
              });
            }
            
          }else if (err.response && err.response.data && err.response.data.errors) {

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
        });
    } else {

      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "process.error" })
      });
    }
    }
  };



  async updateOrganization(value, isLoading = false) {
    if (value != null) {
      if (this.categoryId != null) {
        await this.getTechnicalUsers(this.categoryId.id, value);
      }
      //await this.getRequestingUsers(value);
      if (this.state.called.addresstype == "1")
        await this.getOrganizationAddresses(value);
      await this.getOrganizationFields(value);
      await this.getParentCalleds(value);
      // Voltar aqui
    }

    const clientInfos = await this.getOrganizationSettings();
    if (clientInfos) {
      this.setState({
        canassingusercalled: clientInfos.canassingusercalled,
        canselectorganizationcategoriesbylevels: clientInfos.canselectorganizationcategoriesbylevels,
      });
      this.canselectpatrimonyincalled = clientInfos.canselectpatrimonyincalled;

      if (value != null) {
        if (clientInfos.canselectorganizationcategoriesbylevels === true)
          await this.getRootCategoriesByOrganization(value);
        else
          await this.getCategoriesByOrganization(value);
      }
    } else {
      if (value != null)
        await this.getCategoriesByOrganization(value);
    }

    if (value != null) {
      await this.getPatrimonios(value);
    }
    if(isLoading)
      this.setState({ loading: false })
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleRedirection() {
    this.props.history.push({
      pathname: '/pt/' + this.state.notificationLinkMove
    })
  }

  setErrorMessage = (open, variant, message) => {
    this.setState({
      openNotification: open,
      notificationVariant: variant,
      notificationMessage: message
    });
  }

  changeValuesKnowledgebase = (stateName, value, text = '') => {
    this.setState({ [stateName]: value });
  }

  insertKnowledgebase = (e) => {
    e.preventDefault()

    if(this.state.knowledgebase != null) {
      if(this.state.knowledgebase.knowledgebaseattachments != null && this.state.knowledgebase.knowledgebaseattachments.length > 0) {
        let attachmentList = []
        this.state.knowledgebase.knowledgebaseattachments.forEach(element => {
          if(element != null && element.attachment != null) {
            attachmentList.push(element.attachment)
          }
        });
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            knowledgebaseattachments: attachmentList
          }
        }))
      }
      if(this.state.knowledgebase.description != null) {
        let convKnowldgbs = this.convertHtmlOption(this.state.knowledgebase.description)
        convKnowldgbs = convKnowldgbs.trim()
        if(convKnowldgbs.length > 0) {
          this.setState(prevState => ({
            called: {
              ...prevState.called,
              description: this.state.knowledgebase.description
            }
          }))
        }
      }
    }
    this.closeKnowledgeBaseDialog()
  }

  changeValuesCategoriesByLevels = async (stateName, value, text = '') => {
    const intl = this.props.intl;

    this.setState(prevState => ({
      categoriesByLevels: {
        ...prevState.categoriesByLevels,
        [stateName]: value
      }
    }));

    if(value && value.id && value.id > 0) {
      await this.getByParentOrganizationCategoryId(value.id, stateName)
    } else {
      let firstCategoryId = null
      let rootCategoriesList = null
      if(stateName.includes("lcat-")) {
        let stateNameSplit = stateName.split("-")
        let stateNameId = stateNameSplit[1]
        stateNameId = parseInt(stateNameId)

        let listLevelCatArr = []
        let levelCatArr = []
        
        Object.keys(this.state.levelCategories).forEach((key) => {
          if (key.includes("lcatlist-")) {
            let lCatListSplit = key.split("-")
            let lCatListId = lCatListSplit[1]
            lCatListId = parseInt(lCatListId)

            if(lCatListId < stateNameId)
              listLevelCatArr.push(this.state.levelCategories[key])
          } else
            rootCategoriesList = this.state.levelCategories[key]
        })
        Object.keys(this.state.categoriesByLevels).forEach((key) => {
          if (key.includes("lcat-")) {
            let lCatSplit = key.split("-")
            let lCatId = lCatSplit[1]
            lCatId = parseInt(lCatId)

            if(lCatId < stateNameId)
              levelCatArr.push(this.state.categoriesByLevels[key])
          } else
            firstCategoryId = this.state.categoriesByLevels[key]
        })

        /*listLevelCatArr.reduce(function(result, item) {
          var key = Object.keys(item)[0]; //first property: a, b, c
          result[key] = item[key];
          return result;
        }, {})

        levelCatArr.reduce(function(result, item) {
          var key = Object.keys(item)[0]; //first property: a, b, c
          result[key] = item[key];
          return result;
        }, {})*/

        /*let listLevelCatObj = listLevelCatArr
        let levelCatObj = levelCatArr*/

        PapiroConsole.log("=== listLevelCatArr.reduce ===")
        PapiroConsole.log(listLevelCatArr)
        PapiroConsole.log(levelCatArr)

        await this.setState({
          levelCategories: {
            rootCategoriesList: rootCategoriesList && rootCategoriesList.length > 1 ? rootCategoriesList.sort((a, b) => a.name.localeCompare(b.name)) : rootCategoriesList,
            ...listLevelCatArr,
          }
        })
        await this.setState({
          categoriesByLevels: {
            firstCategoryId: firstCategoryId,
            ...levelCatArr,
          }
        })

        let childrens =
          <Grid item xs={11} sm={3}>
            <SimpleAutoCompleteByName
              intl={intl}
              label={intl.formatMessage({ id: "category" })}
              options={this.state.levelCategories.rootCategoriesList}
              stateName='firstCategoryId'
              name='firstCategoryId'
              changeSelect={this.changeValuesCategoriesByLevels}
              selected={this.state.categoriesByLevels.firstCategoryId} required />
          </Grid>
        
        let levelCategoryId = 1

        listLevelCatArr.map((item, key) => {
          let lCatListSplit = key.split("-")
          let lCatListId = lCatListSplit[1]

          PapiroConsole.log("=== listLevelCatArr.map ===")
          PapiroConsole.log(key)
          PapiroConsole.log(levelCategoryId)

          if(parseInt(lCatListId) > levelCategoryId)
            levelCategoryId = lCatListId

          childrens += <Grid item xs={11} sm={3}>
              <SimpleAutoCompleteByName
                intl={intl}
                label={intl.formatMessage({ id: "category" })}
                options={this.state.levelCategories[`lcatlist-${lCatListId}`] || []}
                stateName={`lcat-${lCatListId}`}
                name={`lcat-${lCatListId}`}
                changeSelect={this.changeValuesCategoriesByLevels}
                selected={this.state.categoriesByLevels[`lcat-${lCatListId}`]} required />
            </Grid>
          
          return { levelCategoryId: levelCategoryId }
        })

        let rootElement = document.getElementById("categoriesByLevels")
        rootElement && ReactDOM.render(
          childrens,
          rootElement
        )

        await this.setState({ levelCategoryId: levelCategoryId })
        this.levelCategoryId = levelCategoryId
      } else {
        await this.setState({
          levelCategories: {
            rootCategoriesList: this.state.levelCategories.rootCategoriesList && this.state.levelCategories.rootCategoriesList.length > 1 ? this.state.levelCategories.rootCategoriesList.sort((a, b) => a.name.localeCompare(b.name)) : this.state.levelCategories.rootCategoriesList,
          }
        })
        await this.setState({
          categoriesByLevels: {
            firstCategoryId: this.state.categoriesByLevels.firstCategoryId,
          }
        })

        let childrens =
          <Grid item xs={11} sm={3}>
            <SimpleAutoCompleteByName
              intl={intl}
              label={intl.formatMessage({ id: "category" })}
              options={this.state.levelCategories.rootCategoriesList}
              stateName='firstCategoryId'
              name='firstCategoryId'
              changeSelect={this.changeValuesCategoriesByLevels}
              selected={this.state.categoriesByLevels.firstCategoryId} required />
          </Grid>
        
        let rootElement = document.getElementById("categoriesByLevels")
        rootElement && ReactDOM.render(
          childrens,
          rootElement
        )

        await this.setState({ levelCategoryId: 1 })
        this.levelCategoryId = 1
      }
    }
  }

  createUi = () => {
    const intl = this.props.intl;

    return this.state.categoryChildrens.map((el, i) =>
      <Grid key={i} item xs={4} sm={4}>
        { i > 0 ?
        (<Grid container spacing={4}>
          <Grid item xs={1} sm={1} style={{ display: 'flex', alignItems: 'center', fontSize: '2em' }}>&gt;</Grid>
          <Grid item xs={10} sm={10}>
            <SimpleAutoCompleteByName
              intl={intl}
              label=""
              options={this.state.categoryLists[i] || []}
              stateName={`lcat-${i}`}
              name={`lcat-${i}`}
              changeSelect={this.handleChangeCategoryChildrens.bind(this, i)}
              selected={el}
               />
          </Grid>
        </Grid>)
        :
        (
          <SimpleAutoCompleteByName
            intl={intl}
            label=""
            options={this.state.categoryLists[i] || []}
            stateName={`lcat-${i}`}
            name={`lcat-${i}`}
            changeSelect={this.handleChangeCategoryChildrens.bind(this, i)}
            selected={el}
             />
        )}
      </Grid>
    )
  }

  handleAddChildrenCategory = (listCategory) => {
    this.setState(prevState => ({
      categoryChildrens: [
        ...prevState.categoryChildrens,
        { id: 0, name: '' }
      ],
      categoryLists: [
        ...prevState.categoryLists,
        listCategory
      ]
    }))
  }

  handleRemoveChildrenCategory = async (i) => {
    this.setState({ loading: true })

    let categoryChildrens = [...this.state.categoryChildrens]
    let categoryLists = [...this.state.categoryLists]
    categoryChildrens.splice(i+1)
    categoryLists.splice(i+1)

    await this.getPreviousCategory(i)

    this.setState({ categoryChildrens, categoryLists })
    this.setState({ loading: false })
  }

  getPreviousCategory = async (i) => {
    if(i > 0) {
      if (this.state.categoryChildrens[i-1] !== undefined) {
        if(this.state.categoryChildrens[i-1].cancreatecalled === true) {
          this.categoryId = this.state.categoryChildrens[i-1]
          this.setState(prevState => ({
            called: {
              ...prevState.called,
              categoryId: this.state.categoryChildrens[i-1]
            }
          }))
          await this.categoryCanCreateCalled(this.state.categoryChildrens[i-1])
        } else {
          await this.getPreviousCategory(i-1)
        }
      }
    } else {
      if (this.state.categoryChildrens[i] !== undefined && this.state.categoryChildrens[i].cancreatecalled === true) {
        this.categoryId = this.state.categoryChildrens[i]
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            categoryId: this.state.categoryChildrens[i]
          }
        }))
      } else {
        this.categoryId = null
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            categoryId: null
          }
        }))
      }
    }
  }

  async handleChangeCategoryChildrens(i, stateName, value) {
    this.setState(prevState => ({
      called: {
        ...prevState.called,
        categoryId: value,
      },
      loading: true
    }))
    
    let categoryChildrens = [...this.state.categoryChildrens]
    if (i < categoryChildrens.length -1 )
      this.handleRemoveChildrenCategory(i)
    else {
      categoryChildrens[i] = value
      this.setState({ categoryChildrens })
    }

    if(value && value.id && value.id > 0) {
      await this.getCategoryChildrenByParentOrganizationCategoryId(value.id, stateName)
      if(value.cancreatecalled === true) {
        await this.categoryCanCreateCalled(value)
      }
    }
    this.setState({ loading: false })
  }

  categoryCanCreateCalled = async (value) => {
    this.categoryId = value;
    let data = await CalledService.getTypeById(value.id, this.organizationId);
    if (data.data.typeid == null) {
      data.data.typeid = 0;
    }
    let initDate = moment().endOf('day').add('days', 1).format("YYYY/MM/DD HH:mm:ss")
    let newDate = initDate
    if(value.useslatime == true) {
      if(value.organizationsla && value.organizationsla.solutiontime && value.organizationsla.solutiontime > 0) {
        newDate = moment().add(value.organizationsla.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
        this.changeDueDateByOrganizationCagetoryMessage(1, moment().add(value.organizationsla.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
      } else if(value.solutiontime && value.solutiontime > 0) {
        newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
        this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
      } else
        newDate = initDate
    } else if(value.useslatime == false) {
      if(value.solutiontime && value.solutiontime > 0) {
        newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
        this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
      } else
        newDate = initDate
    } else
      newDate = initDate

    this.setState(prevState => ({
      called: {
        ...prevState.called,
        ["CategoryId"]: value,
        type: value.typeid && value.typeid > 0 ? value.typeid : this.state.called.type && this.state.called.type > 0 ? this.state.called.type : 0,
        typeDisabled: false,
        priorityid: value.priorityid && value.priorityid > 0 ? value.priorityid : this.state.called.priorityid && this.state.called.priorityid > 0 ? this.state.called.priorityid : 0,
        priorityDisabled: value.priorityid && value.priorityid > 0 ? true : false,
        dueDate: newDate
      }
    }));
    if (this.organizationId != null) {
      await this.getTechnicalUsers(this.categoryId.id, this.organizationId);
      await this.getDepartamentsByOrganizationCategoryId(this.categoryId.id)
    }
  }

  getCategoryChildrenByParentOrganizationCategoryId = async (parentId, stateName = '') => {
    const intl = this.props.intl;
    
    var result = await CalledService.getByParentOrganizationCategoryId(parentId);
    if (result && result.success) {
      if(result.data && result.data.length > 0) {
        this.handleAddChildrenCategory(result.data)
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  changeDueDateByOrganizationCagetoryMessage = (type, date) => {
    const intl = this.props.intl;

    switch (type) {
      case 1:
        this.setState({
          openNotification: true,
          notificationVariant: "info",
          notificationMessage: intl.formatMessage({id:"called.registration.notification.date.changed.sla.category"})
        });
        break;
      case 2:
        this.setState({
          openNotification: true,
          notificationVariant: "info",
          notificationMessage: intl.formatMessage({id:"called.registration.notification.date.changed.sla.category"})
        });
        break;
      default:
        break;
    }
  }

  async changeValues(stateName, value, text = '') {
    // PapiroConsole.log(stateName)
    // PapiroConsole.log(value)
    this.setState(prevState => ({
      called: {
        ...prevState.called,
        [stateName]: value
      }
    }));

    if (stateName === "ParentCalledId") {
      this.setState(prevState => ({
        called: {
          ...prevState.called,
          ["ParentCalledText"]: text,
        }
      }));
    }
    //PapiroConsole.log(stateName+" : "+value);
    if (stateName === "organization") {
      if(value && value.id && value.id > 0) {
        this.organizationId = value.id;
        this.updateOrganization(value.id);
        document.getElementsByClassName('MuiAutocomplete-clearIndicator')[1].click()
        document.getElementsByClassName('MuiAutocomplete-clearIndicator')[2].click()
        this.setState({ CategoryId: { id: 0, name: '' },  departamentId : 0 });
        
        await this.getOrganizationDepartaments(value.id)
      } else {
        this.setState({
          canselectorganizationcategoriesbylevels: false,
          canassingusercalled: false,
          levelCategories: this.levelCategoriesInitialState,
        })
        this.canselectpatrimonyincalled = false
      }
    }
    else if (stateName === "CategoryId") {
      this.categoryId = value;
      let data = await CalledService.getTypeById(value.id, this.organizationId);
      if (data.data.typeid == null) {
        data.data.typeid = 0;
      }
      let initDate = moment().endOf('day').add('days', 1).format("YYYY/MM/DD HH:mm:ss")
      let newDate = initDate
      if(value) {
        if(value.useslatime == true) {
          if(value.organizationsla && value.organizationsla.solutiontime && value.organizationsla.solutiontime > 0) {
            newDate = moment().add(value.organizationsla.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
            this.changeDueDateByOrganizationCagetoryMessage(1, moment().add(value.organizationsla.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
          } else if(value.solutiontime && value.solutiontime > 0) {
            newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
            this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
          } else
            newDate = initDate
        } else if(value.useslatime == false) {
          if(value.solutiontime && value.solutiontime > 0) {
            newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
            this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
          } else
            newDate = initDate
        } else
          newDate = initDate
      } else
        newDate = initDate

      this.setState(prevState => ({
        called: {
          ...prevState.called,
          ["categoryText"]: text,
          type: value.typeid && value.typeid > 0 ? value.typeid : this.state.called.type && this.state.called.type > 0 ? this.state.called.type : 0,
          typeDisabled: false,
          priorityid: value.priorityid && value.priorityid > 0 ? value.priorityid : this.state.called.priorityid && this.state.called.priorityid > 0 ? this.state.called.priorityid : 0,
          priorityDisabled: value.priorityid && value.priorityid > 0 ? true : false,
          dueDate: newDate
        }
      }));
      if (this.organizationId != null) {
        await this.getTechnicalUsers(this.categoryId.id, this.organizationId);
        await this.getDepartamentsByOrganizationCategoryId(this.categoryId.id)
        this.setState({ loading: false })
      }
    }
    else if (stateName === "AddressId") {
      PapiroConsole.log("=== AddressId ChangeValues ===")
      PapiroConsole.log(this.organizationId)
      PapiroConsole.log(value.id)
      if (this.organizationId != null) {
        await this.getRequestingUsers(this.organizationId, value.id, true);
      }


    } else if(stateName === "priorityid") {
      this.setState(prevState => ({
        called: {
          ...prevState.called,
          ["priorityid"]: value && value.id ? value.id : value
        }
      }));
    } else if (stateName === "requestingUser") {
      if (this.state.called.addresstype == 2 && value && value.id && value.id > 0)
        await this.getUserAddresses(value.id)
    }
  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  handleSubmitAccept = async (e) => {
    await this.setState({ newAttendenceAccept: true });
    this.handleSubmit(e);
  }

  handleSubmitDiskAccept = async (e) => {
    await this.setState({ newDiskAccept: true });
    this.handleSubmit(e);
  }

  getCalledFile(fileName) {
    let fName = fileName
    if(fName.startsWith('/wwwroot/', 0))
      fName = fName.substring(9)
    
    let apiUrl = Api.apiurlbase()
    if (apiUrl.endsWith("/"))
      apiUrl = apiUrl.slice(0, -1)

    window.open(`${apiUrl}/${fName.replace("///", "/").replace("//", "/").replace("/files", "files")}`, '_blank');
  };

  useStyles = () => {
    return withStyles({
        ImageList: {
            width: "100%",
            height: 100,
        },
    });
  }

  deleteItem = (file) => {
    if(file != null && file.id != null) {
      let newListItem = this.state.called.knowledgebaseattachments.filter(item => item.id != file.id)
      this.setState(prevState => ({
        called: {
          ...prevState.called,
          knowledgebaseattachments: newListItem
        }
      }))
    }
  }

  render() {
    const { classes, headerMenu } = this.props;
    const { called, address, categoriesByLevels, levelCategories } = this.state;
    const intl = this.props.intl;
      PapiroConsole.log(this.state)
      PapiroConsole.log("state aqui")
    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    const classes2 = this.useStyles();

    return (
      <div className={classes.root}>
        <HeaderMenu />
      
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Dialog
              maxWidth="md"
              open={this.state.knowledgeBaseDialogOpen}
              onClose={this.closeKnowledgeBaseDialog}
              aria-labelledby="knowledgebase-dialog-title" >
                <DialogTitle id="knowledgebase-dialog-title">
                  <FormattedMessage id="insert.knowledge.base" />
                  <ClearIcon onClick={this.closeKnowledgeBaseDialog} style={{ float: 'right', cursor: 'pointer' }} />
                </DialogTitle>
                <DialogContent style={{ width: "800px" }} >
                  <Grid container spacing={0} style={{ marginTop: 15 }}>
                    <Grid item xs={12}>
                      <SimpleAutoCompleteAsynchronousKnowledgebase
                        label={intl.formatMessage({ id: "knowledgebase" })}
                        linkToSearch={this.state.search.knowledgebase}
                        toSearchProp={true}
                        nameAndEmail={false}
                        stateToName="title"
                        options={this.state.knowledgeBaseList}
                        stateName="knowledgebase" 
                        changeSelect={this.changeValuesKnowledgebase}
                        selected={this.state.knowledgebase}
                        setErrorMessage={this.setErrorMessage}
                        isNotClientErrorMessage={true} />
                    </Grid>
                  </Grid>
                </DialogContent>
                  <DialogActions>
                  <Button onClick={this.closeKnowledgeBaseDialog} variant="contained" color="secondary" autoFocus >
                    {intl.formatMessage({id:"cancel"})}
                  </Button>
                  <Button variant="contained" onClick={this.insertKnowledgebase} color="primary" >
                    <FormattedMessage id="insert" />
                  </Button>
                </DialogActions>
            </Dialog>
          <Container component="main" maxWidth="md">
            <Dialog
              open={this.state.openDialogNewAttendence}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{intl.formatMessage({id:"dialog.title.warning.max.attendances.reached"})}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {intl.formatMessage({id:"warning.max.attendances.reached.first.half"})} R$ {this.state.valueNewAttendence} {intl.formatMessage({id:"warning.max.attendances.reached.second.half"})}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.openDialogNewAttendence} variant="contained" color="secondary" autoFocus >
                  {intl.formatMessage({id:"cancel"})}
                </Button>
                <Button variant="contained" onClick={this.handleSubmitAccept} color="primary" >
                {intl.formatMessage({id:"accept"})}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.openDialogNewDisk}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{intl.formatMessage({id:"dialog.title.warning.max.files.reached"})}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {intl.formatMessage({id:"warning.max.files.reached.first.half"})} R$ {this.state.valueNewDisk} {intl.formatMessage({id:"warning.max.files.reached.second.half"})} 
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.openDialogNewDisk} variant="contained" color="secondary" autoFocus >
                  {intl.formatMessage({id:"cancel"})}
                </Button>
                <Button variant="contained" onClick={this.handleSubmitDiskAccept} color="primary" >
                  {intl.formatMessage({id:"accept"})}
                </Button>
              </DialogActions>
            </Dialog>

            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant="outlined" color="primary" onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="called.new" />}
              </Typography>
              <form name="myForm" className={classes.form} onSubmit={this.handleSubmit} encType="multipart/form-data">
                  <Grid container spacing={2}>
                  {!this.isClient && (<>
                  
                    <Grid item xs={12} sm={4} />
                    <Grid item xs={12} sm={4} />
                    <Grid item xs={12} sm={4}>
                      <SimpleDatePicker label={intl.formatMessage({ id: "estimate.resol" })} stateName="dueDate" changeDate={this.changeValues} currentDate={called.dueDate} isRequired />
                    </Grid>
                    <Grid item xs={4} sm={4} style={!this.isClient ? { paddingBottom: 0 } : { display: 'none' } }>
                      <SimpleSelectWithColor label={intl.formatMessage({ id: "priority" })} options={this.state.priorityList} stateName="priorityid" changeSelect={this.changeValues} selected={this.state.called.priorityid} disabled={ called.priorityDisabled } required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.organizationsList} stateName="organization" changeSelect={this.changeValues} selected={called.organization} isRequired required />
                    </Grid>
                  
                    {this.state.canselectorganizationcategoriesbylevels === false ? (<Grid item xs={12} sm={12}>
                        <SimpleAutoComplete label={intl.formatMessage({ id: "category" })} options={this.state.categoriesList} stateName='CategoryId' changeSelect={this.changeValues} selected={called.CategoryId} required />
                      </Grid>)
                    :
                    (
                    <Grid item xs={12} sm={12} style={{ marginBottom: 20, marginTop: 20 }}>
                      <fieldset style={{ padding: '10px 10px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                        <legend style={{ fontSize: '0.8rem', marginBottom: '0!important' }}><FormattedMessage id="category" />*</legend>
                        <Grid container spacing={2} id="categoriesByLevels" className="categoriesByLevels">
                          {this.createUi()}
                        </Grid>
                      </fieldset>
                    </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "departament" })} options={this.state.DepartamentList} stateName='departamentId' changeSelect={this.changeValues} selected={called.departamentId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "parent.called" })} options={this.state.parentCalledsList} stateName='ParentCalledId' changeSelect={this.changeValues} selected={called.ParentCalledId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">{intl.formatMessage({ id: "location" })}</FormLabel>
                        <RadioGroup row aria-label="addresstype" name="addresstype" defaultValue="1" value={called.addresstype} onChange={this.handleChange}>
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={intl.formatMessage({id:"organization.location"})} />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label={intl.formatMessage({id:"requester.location"})} />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label={intl.formatMessage({id:"location.new"})} />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {(called.addresstype != null && called.addresstype === "1") && (<Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "location" })} options={this.state.organizationAddressesList} stateName="AddressId" changeSelect={this.changeValues} selected={called.AddressId} required />
                    </Grid>)}
                    {(called.addresstype != null && called.addresstype === "2") && (<Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "location" })} options={this.state.userAddressesList} stateName="AddressId" changeSelect={this.changeValues} selected={called.AddressId} required />
                    </Grid>)}
                    {(called.addresstype != null && called.addresstype === "3") && (<Grid item xs={12} sm={12}>
                      <Accordion style={{marginTop: "6px", marginBottom: "10px"}}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>{intl.formatMessage({id:"location.new"})}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={`${intl.formatMessage({id:"name"})}*`} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChangeAddress} value={address.name} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField inputProps={{ maxLength: 100 }} fullWidth label={intl.formatMessage({id:"cep"})} autoComplete='fname' variant='outlined' name='zipcode' onChange={this.handleInputChangeAddress} value={address.zipcode} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"street"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='street' onChange={this.handleInputChangeAddress} value={address.street} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"number"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='number' onChange={this.handleInputChangeAddress} value={address.number} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"complement"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='complement' onChange={this.handleInputChangeAddress} value={address.complement} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"neighborhood"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='neighborhood' onChange={this.handleInputChangeAddress} value={address.neighborhood} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"city"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='city' onChange={this.handleInputChangeAddress} value={address.city} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"state"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='state' onChange={this.handleInputChangeAddress} value={address.state} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"country"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='country' onChange={this.handleInputChangeAddress} value={address.country}  />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"longitude"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='longitude' onChange={this.handleInputChangeAddress} value={address.longitude}  />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"latitude"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='latitude' onChange={this.handleInputChangeAddress} value={address.latitude}  />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>)}
                    <Grid item xs={12} sm={4}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "requestuser" })} options={this.state.clientUsersList} stateName="requestingUser" changeSelect={this.changeValues} selected={called.requestinguser} required />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "attr.to" })} options={this.state.technicalUsersList} stateName="assignedUser" changeSelect={this.changeValues} selected={called.assigneduser}
                        disabled={!this.state.canassingusercalled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "observer" })} options={this.state.clientUsersList} stateName="observantUser" changeSelect={this.changeValues} selected={called.observantuser} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({ id: "type" })} options={this.state.typesList} stateName="type" changeSelect={this.changeValues} selected={called.type} disabled={ called.typeDisabled } required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="fname"
                        name="title"
                        variant="outlined"
                        fullWidth
                        id="title"
                        label={intl.formatMessage({ id: "title" })}
                        value={called.title}
                        onChange={this.handleInputChange}
                        inputProps={{ maxLength: 250 }}
                        required
                      />
                    </Grid>
                </>)}

                {this.canselectpatrimonyincalled && (
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"called.registration.patrimonies.associated"})} options={this.state.patrimonyList} stateName='patrimonyId' changeSelect={this.changeValues} selected={called.patrimonyId} />
                    </Grid>
                )}
                  <Grid item xs={12} sm={12}>
          <Typography>
            <FormattedMessage id="description" />*
          </Typography>
					<ReactQuill name="description" value={called.description} style={{ height: 200 }} modules={this.reactQuillModules} formats={this.reactQuillFormats}
					fullWidth
					required
					variant="outlined"
					id="outlined-multiline-static"
									onChange={this.handleChangeEditor} />					  

                  </Grid>
                  <Grid item xs={12} sm={12} justify="flex-end" style={{ display: 'flex', marginTop: 45 }}>
                    <Tooltip title={<h5><FormattedMessage id="insert.knowledge.base" /></h5>}>
                        <IconButton aria-label="insert-knowledge-base"
                            style={{ marginBottom: '30px' }}
                            onClick={this.openKnowledgeBaseDialog}
                            style={{
                                backgroundColor: '#277afe',
                                color: '#FFF'
                            }}>
                            <EmojiObjectsIcon style={{ fontSize: '2rem' }} />
                        </IconButton>
                    </Tooltip>
                  </Grid>

                <If test={this.state.organizationFieldList.length > 0} >
                  <Grid item xs={12} sm={12}>
                  <fieldset style={{
                    marginTop: 25,
                    marginBottom: 25,
                    border: '1px solid #c0c0c0',
                    padding: '0 0 15px 0',
                    borderLeft: 'none',
                    borderRight: 'none'
                  }}>
                    <legend style={{
                      paddingTop: 15,
                      fontSize: '1.3rem',
                      border: '1px solid #c0c0c0',
                      borderBottom: 'none',
                      borderLeft: 'none',
                      borderRight: 'none'
                    }}>{intl.formatMessage({ id: "organization.custom.fields" })}</legend>
                    <Grid container spacing={4}>
                      {this.state.organizationFieldList.map(item => {
                        return (
                          <Grid item xs={6} sm={6}>
                            <TextField
                              autoComplete="fname"
                              name={'ofield-' + item.id}
                              variant="outlined"
                              id={'ofield-' + item.id}
                              label={item.name}
                              fullWidth
                              onChange={this.handleInputChange}
                              inputProps={{ maxLength: 250 }}
                              required={item.isrequired}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </fieldset>
                  </Grid>
                </If>

                <Grid item xs={12} sm={12}>
                  <section style={{ width: '100%' }}>
                    <div className={classes.dropzoneArea}>
                      <Dropzone onDrop={acceptedFiles => { this.setState({ files: acceptedFiles }) }}>
                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>{<FormattedMessage id="drag.and.drop.files" />}</p>
                          </div>
                        )}
                      </Dropzone>
                      {this.state.files.map(file => {
                        return (
                          <div className={classes.dropzoneItem} key={file.name}>
                            {file.name}
                            <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                          </div>
                        )
                      })}
                    </div>
                  </section>
                </Grid>
                {called.knowledgebaseattachments.length > 0  && (
                      <Grid item xs={12}>
                          <div className={classes.attachmentsBox}>
                              <label>{<FormattedMessage id="knowledgebase.attachments" />}</label>
                              <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                  <ul>
                                      {called.knowledgebaseattachments.map(file => {
                                          return (<li key={file.path}>
                                            <DeleteForeverIcon onClick={() => this.deleteItem(file)} style={{ cursor: "pointer" }} />
                                            <AttachFile /> <Button onClick={() => this.getCalledFile(file.path)}>{file.description}</Button>
                                          </li>)
                                      })}
                                  </ul>
                              </ImageList>
                          </div>
                      </Grid>
                )}
                  <Grid item xs={11} sm={11} style={{ textAlign: 'left' }}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "keep.called" })} name='keepcalled'
                      stateName='keepcalled' changeSelect={this.changeValues} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={intl.formatMessage({id:"called.registration.message.functionality.called.data"})} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}

                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                  </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
              toMove={this.state.notificationLinkMove}
              handleRedirection={this.handleRedirection}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}

CalledRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CalledRegistration)));
