import { withStyles } from '@mui/styles'
import React, { useState, useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Styles } from '../../styles/material-styles'
import PropTypes from "prop-types";
import { Button, Card, CardContent, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Overlay } from '../../styles/global'
import CustomizedSnackbars from '../material-snackbars'
import { getCalledOrganizationChecklistById, getAllCalledOrganizationChecklistByCalledIdAndType, updateEvidenceTextCalledOrganizationChecklist, updateValueCalledOrganizationChecklist, updateEvidenceAttachCalledOrganizationChecklist, deleteAttachCalledOrganizationChecklist } from '../../services/calledorganizationchecklist.service'
import Api from "../../services/api";
import { PapiroConsole } from '../../utils/papiroConsole'
import SimpleCheckBox from '../checkbox/check'

import Dropzone from 'react-dropzone';
import ImageList from '@mui/material/ImageList'
import { DeleteForever, AttachFile } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TextFieldCheckIcon from '../../custom/icons/TextfieldCheckIcon'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachFileCheckIcon from '../../custom/icons/AttachFileCheckIcon'
import * as FileService from '../../services/attachment.service';

function ChecklistModal(props) {

  const { intl, calledId, userSession, classes } = props

  const [loading, setLoading] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState("error")
  const [notificationMessage, setNotificationMessage] = useState("")
  const [openNotification, setOpenNotification] = useState(false)

  const [evTextId, setEvTextId] = useState(0)
  const [evTextTitle, setEvTextTitle] = useState("")
  const [evTextInput, setEvTextInput] = useState("")
  const [evTextDialogOpen, setEvTextDialogOpen] = useState(false)

  const [evAttachFile, setEvAttachFile] = useState({})

  const [evAttachId, setEvAttachId] = useState(0)
  const [evAttachTitle, setEvAttachTitle] = useState("")
  const [evAttachFileData, setEvAttachFileData] = useState(null)
  const [evAttachDialogOpen, setEvAttachDialogOpen] = useState(false)

  const [calledOrganizationChecklists, setCalledOrganizationChecklists] = useState([])

  const classes2 = () => {
    return withStyles({
      ImageList: {
        width: "100%",
        height: 100,
      }
    });
  }

  const onClose = () => {
    let completedCheck = 0
    calledOrganizationChecklists.forEach(item => {
      if ((item.evidencetext && item.evidencetext.length > 0) || ((item.attachments && item.attachments.length > 0) || (item.calledorganizationchecklistattachments && item.calledorganizationchecklistattachments.length > 0)))
        completedCheck++
    })

    props.setCheckConfig(calledOrganizationChecklists.length, completedCheck)

    props.onClose()
  }

  const setNotification = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
  }

  const closeEvTextDialog = _ => {
    resetEvTextDialog()
    setEvTextDialogOpen(false)
  }

  const closeEvAttachDialog = _ => {
    resetEvAttachDialog()
    setEvAttachDialogOpen(false)
  }

  const resetEvTextDialog = _ => {
    setEvTextId(0)
    setEvTextTitle("")
    setEvTextInput("")
  }

  const resetEvAttachDialog = _ => {
    setEvAttachId(0)
    setEvAttachTitle("")
    setEvAttachFileData(null)
  }

  const validateImagesAttachments = (files) => {
    if (files.length > 1) {
      setEvAttachFile({})
      setNotification(true, "error", intl.formatMessage({id:"checklist.modal.select.image.error"}))
      return
    }
    for (const file of files) {
      if (!file.type.includes("image")) {
        setEvAttachFile({})
        setNotification(true, "error", intl.formatMessage({id:"checklist.modal.image.field.error"}))
        return
      }
    }

    const img = new Image()
    img.addEventListener('load', () => {
      setEvAttachFile(files[0])
    })
    img.src = URL.createObjectURL(files[0])
  }

  const removeFileFromDropzone = () => {
    setEvAttachFile({})
  }

  //const getCalledFile = (fileName) => {
  const  getCalledFile = async (item) => {	
    /*
	let fName = fileName
    if(fName.startsWith('/wwwroot/', 0))
      fName = fName.substring(9)
    
    let apiUrl = Api.apiurlbase()
    if (apiUrl.endsWith("/"))
      apiUrl = apiUrl.slice(0, -1)

    window.open(`${apiUrl}/${fName.replace("///", "/").replace("//", "/").replace("/files", "files")}`, '_blank');
  */
 	if(item){
		
		setLoading(true);
		
		let url = await FileService.getFile(item.hirerid,item.id,item.path,item.filerepositorymethod); 
		//alert(JSON.stringify(url.data));
		setLoading(false);
		if(url &&  url.data && url.data.data && url.data.success){
			window.open(`${url.data.data}`, '_blank');
		}
		else{
			setNotification(true, "error", intl.formatMessage({id:"file.not.found"}))

		}
	}


  }

  const createCheckState = (itemId) => {
    let item = calledOrganizationChecklists.filter(checklist => (checklist.id == itemId))
    if (item.size > 0) {
      item[0].value = true

      let items = calledOrganizationChecklists.filter(checklist => (checklist.id != itemId))
      items.push(item)

      setCalledOrganizationChecklists(items)
    }
  }
  
  const deleteDocument = async (id) => {
    setLoading(true)

    let result = await deleteAttachCalledOrganizationChecklist(id)
    if (result && result.data) {
      setNotification(true, "success", intl.formatMessage({id:"checklist.modal.evidence.file.deleted"}))
    } else {
      setNotification(true, "error", result && result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
    setLoading(false)
  }

  const getInitialChecklists = async () => {
    setLoading(true)

    let result = await getAllCalledOrganizationChecklistByCalledIdAndType(calledId, 1)
    if(result && result.data && result.data.length > 0) {
      
      setCalledOrganizationChecklists(result.data)

      setLoading(false)
    } else {
      setNotification(true, "error", result && result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
      setLoading(false)
      Api.kickoff(result);
    }
  }

  const newchangeValues = async (stateName, value) => {
    if (stateName.includes("chk-")) {
      
      let splitChk = stateName.split("-")
      let checkId = splitChk[1]

      setLoading(true)

      let data = new FormData()
      data.append('id', checkId)
      data.append('value', value) 
      data.append('updateduser', userSession.user.id)

      let result = await updateValueCalledOrganizationChecklist(data)
      if (result && result.success && result.success == true) {
        setNotification(true, "success", intl.formatMessage({id:"checklist.modal.updated.checklist"}))
        setLoading(false)
      } else {
        setNotification(true, "error", result && result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
        setLoading(false)
        Api.kickoff(result);
      }
    }
  }

  const openChecklistTextEvidenceModal = async (id, name, evidenceText) => {
    setLoading(true)

    let result = await getCalledOrganizationChecklistById(id)
    if (result && result.data) {
      
      setEvTextTitle(name)
      setEvTextInput(result.data.evidencetext)
      setEvTextId(id)
      setEvTextDialogOpen(!evTextDialogOpen)

      setLoading(false)
    } else {
      
      setEvTextTitle(name)
      setEvTextInput(evidenceText)
      setEvTextId(id)
      setEvTextDialogOpen(!evTextDialogOpen)

      setNotification(true, "error", result && result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
      setLoading(false)
      Api.kickoff(result);
    }
  }

  const openChecklistImageEvidenceModal = async (id, name, evAttachId) => {
    setLoading(true)

    let result = await getCalledOrganizationChecklistById(id)
    if (result && result.data) {
      if (result.data.attachments && result.data.attachments.length > 0) {
        setEvAttachTitle(name)
        setEvAttachFileData(result.data.attachments[0].attachment)
        setEvAttachId(id)
        setEvAttachDialogOpen(!evAttachDialogOpen)
      } else {
        setEvAttachTitle(name)
        setEvAttachFileData(null)
        setEvAttachId(id)
        setEvAttachDialogOpen(!evAttachDialogOpen)
      }
    } else {
      
      setEvAttachTitle(name)
      setEvAttachFileData(null)
      setEvAttachId(id)
      setEvAttachDialogOpen(!evAttachDialogOpen)

      setNotification(true, "error", result && result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
    setLoading(false)
  }

  const sendTextEvidence = async _ => {
    setLoading(true)

    let data = new FormData()
    data.append('id', evTextId)
    data.append('value', false)
    data.append('evidencetext', evTextInput)
    data.append('updateduser', userSession.user.id)

    let result = await updateEvidenceTextCalledOrganizationChecklist(data)
    if (result && result.success && result.success == true) {
      setNotification(true, "success", intl.formatMessage({id:"checklist.modal.evidence.text.updated"}))
      createCheckState(evTextId)
      getInitialChecklists()
      closeEvTextDialog()
    } else {
      setNotification(true, "error", result && result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
    setLoading(false)
  }

  const sendImageEvidence = async _ => {
    if (evAttachFile && evAttachFile.name) {
      setLoading(true)

      let data = new FormData()
      data.append('id', evAttachId)
      data.append('value', false)
      data.append('filesUpload', evAttachFile)
      data.append('updateduser', userSession.user.id)

      let result = await updateEvidenceAttachCalledOrganizationChecklist(data)
      if (result && result.success && result.success == true) {
        setNotification(true, "success", intl.formatMessage({id:"checklist.modal.evidence.file.updated"}))
        createCheckState(evAttachId)
        getInitialChecklists()
        closeEvAttachDialog()
      } else {
        setNotification(true, "error", result && result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
        Api.kickoff(result);
      }
      setLoading(false)
    } else {
      closeEvAttachDialog()
    }
  }

  useEffect(() => {
    getInitialChecklists()
  }, [])

  return (
    <>
      <Dialog
        open={evTextDialogOpen}
        onClose={closeEvTextDialog}
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="form-ev-text-dialog-title" >
        <DialogTitle id="form-ev-text-dialog-title">
          {intl.formatMessage({id:"activities.checklist"})} - {intl.formatMessage({ id: "evidence.text" })} - {evTextTitle}
          <ClearIcon onClick={closeEvTextDialog} style={{ float: 'right', cursor: 'pointer' }} />
        </DialogTitle>
        <DialogContent>
          <TextField
          style={{ marginTop: 15 }}
            name="evTextInput"
            autoComplete="fname"
            label={intl.formatMessage({ id: "evidence.text" })}
            multiline
            rows="4"
            fullWidth
            value={evTextInput}
            variant="outlined"
            onChange={(e) => setEvTextInput(e.target.value)}
            inputProps={{ maxLength: 255 }} />
        </DialogContent>
        <Grid container justify="flex-end" style={{ justifyContent: 'flex-end', paddingRight: 25, paddingBottom: 15 }}>
          <Grid item xs={12} sm={4}>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={sendTextEvidence} >
              {<FormattedMessage id="save" />}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={evAttachDialogOpen}
        onClose={closeEvAttachDialog}
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="form-ev-attach-dialog-title" >
        <DialogTitle id="form-ev-attach-dialog-title">
          {intl.formatMessage({id:"activities.checklist"})} - {intl.formatMessage({ id: "evidence.attachment" })} - {evAttachTitle}
          <ClearIcon onClick={closeEvAttachDialog} style={{ float: 'right', cursor: 'pointer' }} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={0} style={{ marginBottom: 12 }} >
            <section style={{ width: '100%', parginTop: 0, marginBottom: 10 }} >
              <div className={classes.dropzoneArea} style={{ marginTop: 0 }} >
                <Dropzone onDrop={acceptFiles => validateImagesAttachments(acceptFiles)} >
                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <div { ...getRootProps() }>
                      <input { ...getInputProps() } />
                      <p>"{intl.formatMessage({id:"checklist.modal.upload.image.drag"})}"</p>
                    </div>
                  )}
                </Dropzone>
                {evAttachFile && evAttachFile.name && (
                  <div className={classes.dropzoneItem} key={evAttachFile.name}>
                    {evAttachFile.name}
                    <DeleteForever className={classes.deleteItem} onClick={() => { removeFileFromDropzone() }} />
                  </div>
                )}
              </div>
            </section>
          </Grid>
          {evAttachFileData && evAttachFileData.path && (
          <Grid container spacing={0} style={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <div className={classes.attachmentsBox}>
                <label>{intl.formatMessage({id:"attachments"})}</label>
                <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                  <ul>
                    <li key={evAttachFileData.path}>
                      <AttachFile /> <Button onClick={() => getCalledFile(evAttachFileData)}>{evAttachFileData.description}</Button>
                      <DeleteForeverIcon onClick={() => deleteDocument(evAttachId)} style={{ cursor: "pointer" }} />
                    </li>
                  </ul>
                </ImageList>
              </div>
            </Grid>
          </Grid>
          )}
        </DialogContent>
        <Grid container justify="flex-end" style={{ justifyContent: 'flex-end', paddingRight: 25, paddingBottom: 15 }}>
          <Grid item xs={12} sm={4}>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={sendImageEvidence} >
              {<FormattedMessage id="save" />}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={props.open}
        onClose={onClose}
        fullWidth={true}
        maxWidth="lg"
        aria-labelledby="initial-checklist-dialog" >
        <DialogTitle id="initial-checklist-dialog">
          { intl.formatMessage({ id: "initial.checklist" }) }
          <ClearIcon
            onClick={onClose}
            style={{ float: 'right', cursor: 'pointer' }} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ padding: 15 }} >
            <Grid item xs={12} sm={12} md={12} lg={12}
              style={{
                color: 'black',
                fontSize: '12px',
                padding: '20px 5px 20px 20px',
                backgroundColor: '#F9F9F9',
                borderBottom: '1px solid #b3b3b3'
              }} >
              <Grid container spacing={2} >
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  {intl.formatMessage({id:"name"})}
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} >
                  {intl.formatMessage({id:"type"})}
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} >
                  {intl.formatMessage({id:"description"})}
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} >
                  {intl.formatMessage({id:"attachments"})}
                </Grid>
              </Grid>
            </Grid>
            { calledOrganizationChecklists && calledOrganizationChecklists.map(item => {
              return (
                <Grid item xs={12} sm={12} md={12} lg={12}
                  style={{ padding: '15px 15px 0 15px' }} >
                  <Grid container spacing={2} >
                    <Grid item xs={6} sm={6}>
                      <SimpleCheckBox
                        changeSelect={newchangeValues}
                        stateName={'chk-' + item.id}
                        fullWidth
                        autoComplete='fname'
                        name={'chk-' + item.id}
                        selected={item.value}
                        variant='outlined'
                        label={item.organizationchecklist ? item.organizationchecklist.title : ''}
                        disabled={true} />
                    </Grid>
                    <Grid item xs={2} sm={2}
                      style={{ display: 'flex', alignItems: 'center' }}>
                      { item.type ? item.type == 1 ? 'Inicial' : 'Final' : '' }
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <Tooltip title={intl.formatMessage({id:"checklist.modal.add.evidence.text"})}>
                        <IconButton
                          aria-label="textevidence"
                          onClick={e => openChecklistTextEvidenceModal(item.id, item.organizationchecklist ? item.organizationchecklist.title : '', item.evidencetext)} >
                          {item.evidencetext && item.evidencetext.length > 0 ? (<TextFieldCheckIcon />) : (<TextFieldsIcon />)}
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <Tooltip title={intl.formatMessage({id:"checklist.modal.add.evidence.file"})}>
                        <IconButton
                          aria-label="attachevidence"
                          onClick={e => openChecklistImageEvidenceModal(item.id, item.organizationchecklist ? item.organizationchecklist.title : '', item.evidenceattachmentid)} >
                          { ((item.attachments && item.attachments.length > 0) || (item.calledorganizationchecklistattachments && item.calledorganizationchecklistattachments.length > 0)) ? (<AttachFileCheckIcon />) : (<AttachFileIcon />)}
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }) }
          </Grid>
        </DialogContent>
      </Dialog>
      {loading && (
      <Overlay>
        <CircularProgress color="secondary" />
      </Overlay>
      )}
      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </>
  )
}

ChecklistModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(ChecklistModal))
)