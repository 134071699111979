import React, { Component } from 'react';
import { render } from 'react-dom';
import _ from 'lodash';
import moment from "moment";
import SimpleAutoCompleteMultiple from '../../../components/auto-complete/autocomplete-multiple';
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete';
import SimpleCheckBox from '../../../components/checkbox/check';
import { Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, Radio, RadioGroup, BottomNavigationAction, BottomNavigation } from "@mui/material";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl'
import { AbilityContext } from '../../../config/ability-context'
import { connect } from 'react-redux';
import { makeStyles, createStyles, withStyles } from "@mui/styles";
import { Styles } from "../../../styles/material-styles";
import '../css/app.scss';
import Api from "../../../services/api";
import CustomizedSnackbars from "../../../components/material-snackbars";
import { bindActionCreators } from 'redux';
import * as calledsActions from '../../../redux/actions/calleds';
import { Overlay } from "../../../styles/global";
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear'
import Dropzone from 'react-dropzone';
import { DeleteForever, AttachFile } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ImageList from '@mui/material/ImageList'
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import MyMaterialTable from '../../../components/table';
import AddIcon from '@mui/icons-material/Add';
import { Link } from '@mui/material'
import SimpleDatePicker from '../../../components/date-pickers/inline-no-today'
import NumberFormat from 'react-number-format';
import SimpleHelp from '../../../components/help';
import * as Service from '../../../services/organizationmaterial.service';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import SLAOverflowStep from './SLAOverflowStep';
import If from './../../../components/conditionals/if'
import './../css/calledFinish.css'
import ActionStep from './ActionStep';
import StepWizard from 'react-step-wizard';
import CalledOrganizationChecklistStep from './CalledOrganizationChecklistStep';
import CalledOrganizationMaterialStep from './CalledOrganizationMaterialStep';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckIcon from '@mui/icons-material/Check';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import { getCanInformCalledClosingStatusByOrgId } from '../../../services/calledclosingstatus.service'
import { useState } from 'react';
import { useEffect } from 'react';
import CalledClosingStatusStep from './CalledClosingStatusStep';
import { PapiroConsole } from '../../../utils/papiroConsole';
import * as FileService from '../../../services/attachment.service';


const navStyle = () => {
  return withStyles((theme) => ({
    root: {
      color: theme.palette.text.secondary,
      '&$selected': {
        color: theme.palette.primary.main,
      },
    },
    /* Styles applied to the root element if selected. */
    selected: {},
  }))
}

const StepNav = props => {

  const style = navStyle()
  const styleButton = { cursor: 'default', backgroundColor: "#EEE" }
  const styleIcon = { cursor: 'default', fill: "primary" }
  let [styleButtonSLA, setStyleButtonSLA] = useState({ cursor: 'default' })
  let [styleIconSLA, setStyleIconSLA] = useState({ cursor: 'default' })
  let [styleButtonAction, setStyleButtonAction] = useState({ cursor: 'default' })
  let [styleIconAction, setStyleIconAction] = useState({ cursor: 'default' })
  let [styleButtonCheck, setStyleButtonCheck] = useState({ cursor: 'default' })
  let [styleIconCheck, setStyleIconCheck] = useState({ cursor: 'default' })
  let [styleButtonMaterial, setStyleButtonMaterial] = useState({ cursor: 'default' })
  let [styleIconMaterial, setStyleIconMaterial] = useState({ cursor: 'default' })
  let [styleIconCalledClosingStatus, setStyleIconCalledClosingStatus] = useState({ cursor: 'default' })
  let [styleButtonCalledClosingStatus, setStyleButtonCalledClosingStatus] = useState({ cursor: 'default' })

  const intl = props.intl;

  useEffect(() => {
    console.log("=== 1 ===")
    console.log(props)
    if(props.slaAttend > 100) {
      props.setNavValue("sla", props.value)
    }else {
      console.log("Entrou else")
      props.setNavValue("action", props.value)
    }
  }, [])

  useEffect(() => {
    //console.log("=== 2 ===")
    //console.log(props)
    switch(props.value) {
      case "sla":
        setStyleButtonSLA(styleButton)
        setStyleIconSLA(styleIcon)
        setStyleButtonAction({ cursor: 'default' })
        setStyleIconAction({ cursor: 'default' })
        setStyleButtonCheck({ cursor: 'default' })
        setStyleIconCheck({ cursor: 'default' })
        setStyleButtonMaterial({ cursor: 'default' })
        setStyleIconMaterial({ cursor: 'default' })
        setStyleIconCalledClosingStatus({ cursor: 'default' })
        setStyleButtonCalledClosingStatus({ cursor: 'default' })
        break
      case "action":
        setStyleButtonSLA({ cursor: 'default' })
        setStyleIconSLA({ cursor: 'default' })
        setStyleButtonAction(styleButton)
        setStyleIconAction(styleIcon)
        setStyleButtonCheck({ cursor: 'default' })
        setStyleIconCheck({ cursor: 'default' })
        setStyleButtonMaterial({ cursor: 'default' })
        setStyleIconMaterial({ cursor: 'default' })
        setStyleIconCalledClosingStatus({ cursor: 'default' })
        setStyleButtonCalledClosingStatus({ cursor: 'default' })
        break
      case "check":
        setStyleButtonSLA({ cursor: 'default' })
        setStyleIconSLA({ cursor: 'default' })
        setStyleButtonAction({ cursor: 'default' })
        setStyleIconAction({ cursor: 'default' })
        setStyleButtonCheck(styleButton)
        setStyleIconCheck(styleIcon)
        setStyleButtonMaterial({ cursor: 'default' })
        setStyleIconMaterial({ cursor: 'default' })
        setStyleIconCalledClosingStatus({ cursor: 'default' })
        setStyleButtonCalledClosingStatus({ cursor: 'default' })
        break
      case "material":
        setStyleButtonSLA({ cursor: 'default' })
        setStyleIconSLA({ cursor: 'default' })
        setStyleButtonAction({ cursor: 'default' })
        setStyleIconAction({ cursor: 'default' })
        setStyleButtonCheck({ cursor: 'default' })
        setStyleIconCheck({ cursor: 'default' })
        setStyleButtonMaterial(styleButton)
        setStyleIconMaterial(styleIcon)
        setStyleIconCalledClosingStatus({ cursor: 'default' })
        setStyleButtonCalledClosingStatus({ cursor: 'default' })
        break
      case "status":
        setStyleButtonSLA({ cursor: 'default' })
        setStyleIconSLA({ cursor: 'default' })
        setStyleButtonAction({ cursor: 'default' })
        setStyleIconAction({ cursor: 'default' })
        setStyleButtonCheck({ cursor: 'default' })
        setStyleIconCheck({ cursor: 'default' })
        setStyleButtonMaterial({ cursor: 'default' })
        setStyleIconMaterial({ cursor: 'default' })
        setStyleIconCalledClosingStatus(styleButton)
        setStyleButtonCalledClosingStatus(styleButton)
        break
    }
  }, [props.value])


  //console.log(props)
  return (
    <BottomNavigation
      value={props.value}
      showLabels
      className={style.root} >
      { props.slaAttend > 100 && <BottomNavigationAction style={styleButtonSLA} label={intl.formatMessage({id:"called.accompaniment.sla.overflow"})} value="sla" icon={<AccessTimeIcon style={styleIconSLA} />} /> }
      <BottomNavigationAction style={styleButtonAction} label={intl.formatMessage({id:"add.action"})} value="action" icon={<DescriptionIcon style={styleIconAction} />} />
      { props.caninformcalledclosingstatus && <BottomNavigationAction style={styleButtonCalledClosingStatus} label={intl.formatMessage({id:"closingstatus"})} value="status" icon={<InfoIcon style={styleIconCalledClosingStatus} />} /> }
      { props.CalledOrganizationChecklistList.length > 0 && !props.isClient && <BottomNavigationAction style={styleButtonCheck} label={intl.formatMessage({id:"menu.organization.checklists"})} value="check" icon={<CheckIcon style={styleIconCheck} />} /> }
      { props.caninformutilizedmaterialsincalledfinish && <BottomNavigationAction style={styleButtonMaterial} label={intl.formatMessage({id:"materials.used"})} value="material" icon={<QueuePlayNextIcon style={styleIconMaterial} />} /> }
    </BottomNavigation>
  )
}

const NoDataRows = _ => {
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
  )
}

class CalledFinishPatrimony extends Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();
    
    //const { user, called, socket } = this.props;
    const { user, userSession, called, calledorganizationfields } = this.props;

    

    this.state = {
      createdUser: userSession.user.id,
      called:called,
      calledorganizationmaterial: [],
      user:user.user,
      template: called.therewasmaterialsubstitution ? called.therewasmaterialsubstitution : false,
      template2: called.therewasmaterialsubstitution ? !called.therewasmaterialsubstitution : true,
      template3: false,
      template4: true,
      templateorganizationmaterial: false,
      disableValueInformation: false,
      MaterialList: [],
      material: [],
      OrganizationChecklistList: [],
      CalledOrganizationChecklistList: [],
      caninformcalledclosingstatus: false,
      calledorganizationfields: calledorganizationfields,
      quantityreama4: called.quantityreama4,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      materialSelected: [],
      checklistTextEvidenceModalOpen: false,
      checklistImageEvidenceModalOpen: false,
      evAttachTitle: '',
      evTextTitle: '',
      evTextInput: '',
      evTextId: null,
      evAttachFile: {},
      evAttachFileData: null,
      evAttachId: null,
      slaOverflowContent: false,
      reasonsList: [],
      calledReasonId: null,
      reasonOther: '',
      materialFinishCalledDeleteButton: 'none',
      materialFinishCalledDeleteItems: null,
      calledOrganizationMaterialDeleteModalOpen: false,
      calledOrganizationMaterialInsertModalOpen: false,
      CalledOrganizationMaterialList: [],
      OrganizationMaterialList: [],
      formCallOrgMatOrganiaztionMaterial: null,
      formCallOrgMatQuantity: 0,
      organizationMaterialInsertModalOpen: false,
      OrganizationMaterialId: 0,
      OrganizatioMaterialText: '',
      InsertMaterialList: [],
      InsertOrganizationMaterialName: "",
      InsertOrganizationMaterialGaranty : 0, 
      InsertOrganizationMaterialValue : '',
      invoicedate: moment().format('YYYY/MM/DD HH:mm:ss'),
      SLAStepIsPrevious: false,
      ActionStepIsPrevious: true,
      StatusIsPrevious: false,
      TwoStepsPrevious: false,
      StepCalledOrganizationChecklist: false,
      finishStepSLA: false,
      finishStepAction: false,
      finishStepChecklist: false,
      finishStepStatus: false,
      finishStepMaterial: false,
      getChecklists: false,
      navValue: "sla",
      navPrevValue: "",
      setLoading: false
    };
    this.pc = {};
    this.config = null;
    
    this.closeModal = this.closeModal.bind(this);  
    this.globalAdm = false;
    this.AdmOrganization = false;
    this.isCoordenator = false;
    this.isTechinical = false;
    this.isClient = true;
    this.RoleType = -1;
    this.changeValues = this.changeValues.bind(this);  
    this.getMaterials = this.getMaterials.bind(this); 
    this.getOrganizationLists = this.getOrganizationLists.bind(this);
    this.getCalledOrganizationLists = this.getCalledOrganizationLists.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this); 

    this.openChecklistTextEvidenceModal = this.openChecklistTextEvidenceModal.bind(this)
    this.openChecklistImageEvidenceModal = this.openChecklistImageEvidenceModal.bind(this)
    this.closeTextEvidenceModal = this.closeTextEvidenceModal.bind(this)
    this.closeImageEvidenceModal = this.closeImageEvidenceModal.bind(this)
    this.sendTextEvidence = this.sendTextEvidence.bind(this)
    this.sendImageEvidence = this.sendImageEvidence.bind(this)

    this.openCalledOrganizationMaterialDeleteModal = this.openCalledOrganizationMaterialDeleteModal.bind(this)
    this.openCalledOrganizationMaterialInsertModal = this.openCalledOrganizationMaterialInsertModal.bind(this)
    this.closeCalledOrganizationMaterialDeleteModal = this.closeCalledOrganizationMaterialDeleteModal.bind(this)
    this.closeCalledOrganizationMaterialInsertModal = this.closeCalledOrganizationMaterialInsertModal.bind(this)

    this.validateImagesAtachments = this.validateImagesAtachments.bind(this)
    this.removeFileFromDropzone = this.removeFileFromDropzone.bind(this)
    this.useStyles = this.useStyles.bind(this)
    this.hideOverflowStyle = this.hideOverflowStyle.bind(this)
    this.deleteDocument = this.deleteDocument.bind(this)
    this.createCheckState = this.createCheckState.bind(this)
    this.deleteCalledOrganizationMaterials = this.deleteCalledOrganizationMaterials.bind(this)
    this.createCalledOrganizationMaterial = this.createCalledOrganizationMaterial.bind(this)
    this.getCalledOrganizationMaterials = this.getCalledOrganizationMaterials.bind(this)
    this.getCanInformCalledClosingStatusByOrgId = this.getCanInformCalledClosingStatusByOrgId.bind(this)
    this.getOrganizationMaterials = this.getOrganizationMaterials.bind(this)
    this.updateCalledOrganizationMaterialQuantity = this.updateCalledOrganizationMaterialQuantity.bind(this)
    this.openNewOrganizationMaterialModal = this.openNewOrganizationMaterialModal.bind(this)
    this.closeNewOrganizationMaterialModal =  this.closeNewOrganizationMaterialModal.bind(this)
    this.createOrganizationMaterial = this.createOrganizationMaterial.bind(this)

    this.changeSLAOverflowStepReasonId = this.changeSLAOverflowStepReasonId.bind(this)
    this.changeSLAOverflowStepReasonDesc = this.changeSLAOverflowStepReasonDesc.bind(this)
    
    user.user.userrole.map((item, key) => {
        if(item.role.roletype !=3){
            this.isClient = false;
        }
        if(item.role.roletype == 0){
            this.globalAdm =true;
            this.RoleType = item.role.roletype;
        }
        if(item.role.roletype == 1){
            this.AdmOrganization =true;
            this.RoleType = item.role.roletype;
        }
        if(item.role.roletype ==2){
          this.isTechinical =true;
          this.RoleType = item.role.roletype;
        }
        if(item.role.roletype ==3){
              this.RoleType = item.role.roletype;
        }
        if(item.role.roletype == 4){
              this.isCoordenator =true;
              this.RoleType = item.role.roletype;
        }
    });
    
    this.getMaterials();
    this.getOrganizationLists();
    this.getCanInformCalledClosingStatusByOrgId()
    this.getCalledOrganizationMaterials();
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  hideOverflowStyle() {
    return withStyles({
      paper: {
        overflow: 'hidden!important',
        overflowX: 'hidden!important',
        overflowY: 'hidden!important',
        zIndex: 1000,
      }
    })
  }

  useStyles() {
    return withStyles({
        ImageList: {
            width: "100%",
            height: 100,
        },
    });
  }

  closeTextEvidenceModal = () => this.setState({ checklistTextEvidenceModalOpen: false, evTextInput: '', evTextTitle: '', evTextId: null })

  closeImageEvidenceModal = () => this.setState({ checklistImageEvidenceModalOpen: false, evAttachTitle: '', evAttachId: null, evAttachFile: {}, evAttachFileData: null })

  closeCalledOrganizationMaterialDeleteModal = () => this.setState({ calledOrganizationMaterialDeleteModalOpen: false })

  closeCalledOrganizationMaterialInsertModal = () => this.setState({ calledOrganizationMaterialInsertModalOpen: false, formCallOrgMatOrganiaztionMaterial: null, formCallOrgMatQuantity: 0 })

  closeNewOrganizationMaterialModal = () => this.setState({ organizationMaterialInsertModalOpen: false })

  checkSLAAttendOverflow = () => {
    const slaAttend = this.props.slaAttend
    if(slaAttend > 100) {
      //this.getReasons()
      this.setState({ slaOverflowContent: true })
    }
  }

  setMessage = (variant, message, isOpen) => {
    this.setState({
      notificationVariant: variant,
      notificationMessage: message,
      openNotification:isOpen
    })
  }

  setNavValue = (value, prevValue = "") => {
    if(value != prevValue) {
      this.setState({ navPrevValue: prevValue })
      switch(prevValue) {
        case "sla":

      }
    }
    this.setState({ navValue: value })
  }

  changeSLAOverflowStepReasonId(value) {
    this.setState(prevState => ({
      slaOverflow: {
        ...prevState.slaOverflow,
        reasonOther: value
      }
    }))
  }

  changeSLAOverflowStepReasonDesc(value) {
    this.setState(prevState => ({
      slaOverflow: {
        ...prevState.slaOverflow,
        calledReasonId: value
      }
    }))
  }

  setFinishStep = (stepNumber) => {
    switch (stepNumber) {
      case 1:
        this.setState({ finishStepSLA: true })
        break
      case 2:
        this.setState({ finishStepAction: true })
        break
      case 3:
        this.setState({ finishStepStatus: true })
      case 4:
        this.setState({ finishStepChecklist: true })
        break
      case 5:
        this.setState({ finishStepMaterial: true })
        break
    }
  }

  getReasons = async _ => {
    const intl = this.props.intl;
    await Api.get(`/reasons`)
      .then(result => {
        if(result && result.data && result.data.data) {
          this.setState({
            reasonsList: result.data.data,
            loading: false
          })
        }else {
          this.setState({
            loading: false
          })
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(err);
      });
  }

  async openNewOrganizationMaterialModal() {
    await this.getMaterialsFromService()
    this.setState({
      organizationMaterialInsertModalOpen: true,
      OrganizationMaterialId: 0,
      OrganizatioMaterialText: '',
      InsertOrganizationMaterialName: "",
      InsertOrganizationMaterialGaranty : 0, 
      InsertOrganizationMaterialValue : '',
      invoicedate: moment().format('YYYY/MM/DD HH:mm:ss'),
      templateorganizationmaterial: false,
      loading: false
    })
  }

  async openCalledOrganizationMaterialInsertModal() {
    await this.getOrganizationMaterials()
    
    this.setState({
      calledOrganizationMaterialInsertModalOpen: true
    })
  }

  async openCalledOrganizationMaterialDeleteModal() {
    const intl = this.props.intl;
    if(this.state.materialFinishCalledDeleteItems && this.state.materialFinishCalledDeleteItems.length > 0) {
      this.setState({
        calledOrganizationMaterialDeleteModalOpen: true
      })
    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"called.organization.material.list.select.material"})
      })
    }
  }

  async openChecklistTextEvidenceModal(id, name, evidencetext) {
    const intl = this.props.intl;
    this.setState({ loading: true })
    await Api.get(`/calledorganizationchecklists/${id}`)
      .then(result => {
        if(result && result.data && result.data.data) {
          this.setState({
            evTextTitle: name,
            evTextInput: result.data.data.evidencetext,
            evTextId: id,
            loading: false,
            checklistTextEvidenceModalOpen: !this.state.checklistTextEvidenceModalOpen
          })
        }else {
          this.setState({
            evTextTitle: name,
            evTextInput: evidencetext,
            evTextId: id,
            loading: false,
            checklistTextEvidenceModalOpen: !this.state.checklistTextEvidenceModalOpen
          })
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(err);
      });
  }

  async openChecklistImageEvidenceModal(id, name, evidenceattachmentid) {
    const intl = this.props.intl;
    this.setState({ loading: true })
      await Api.get(`/calledorganizationchecklists/${id}`)
      .then(result => {
        if(result && result.data && result.data.data) {
          if(result.data.data.attachments && result.data.data.attachments.length > 0) {
            this.setState({
              evAttachTitle: name,
              evAttachId: id,
              evAttachFileData: result.data.data.attachments[0].attachment,
              loading: false,
              checklistImageEvidenceModalOpen: !this.state.checklistImageEvidenceModalOpen
            })
          } else {
            this.setState({
              evAttachTitle: name,
              evAttachId: id,
              evAttachFileData: null,
              loading: false,
              checklistImageEvidenceModalOpen: !this.state.checklistImageEvidenceModalOpen
            })
          }
        }else {
          this.setState({
            evAttachTitle: name,
            evAttachId: id,
            loading: false,
            checklistImageEvidenceModalOpen: !this.state.checklistImageEvidenceModalOpen
          })
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(err);
      });
  }

  async sendTextEvidence() {
    const intl = this.props.intl
    this.setState({ loading: true, checklistTextEvidenceModalOpen: false })

    const { userSession } = this.props
    let data = new FormData()
    data.append('id', this.state.evTextId)
    data.append('value', false)
    data.append('evidencetext', this.state.evTextInput)
    data.append('updateduser', userSession.user.id)

    Api.put("/calledorganizationchecklists/ev/text", data)
      .then(result => {
        if (result && result.data && result.data.success && result.data.success == true) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({id:"checklist.modal.evidence.text.updated"}),
            ['chk-'+this.state.evTextId]: true
          });
          PapiroConsole.log("=== sendTextEvidence ===")
          PapiroConsole.log(this.state.evTextId)
          PapiroConsole.log(result.data)
          this.createCheckState(this.state.evTextId, result.data.data)
          this.closeTextEvidenceModal()
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          checklistTextEvidenceModalOpen: true,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
      });
  }

  async sendImageEvidence() {
    const intl = this.props.intl
    if(this.state.evAttachFile && this.state.evAttachFile.name) {
      this.setState({ loading: true })
    const { userSession } = this.props
    let data = new FormData()
    data.append('id', this.state.evAttachId)
    data.append('value', false)
    data.append('filesUpload', this.state.evAttachFile)
    data.append('updateduser', userSession.user.id)

    let config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    Api.put("/calledorganizationchecklists/ev/attach", data, config)
      .then(result => {
        if (result && result.data && result.data.success && result.data.success == true) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({id:"checklist.modal.evidence.file.updated"}),
            ['chk-'+this.state.evAttachId]: true
          });
          PapiroConsole.log("=== sendImageEvidence ===")
          PapiroConsole.log(this.state.evAttachFile)
          PapiroConsole.log(result.data)
          this.createCheckState(this.state.evAttachId, result.data.data)
          this.closeImageEvidenceModal()
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
      });

    this.setState({ loading: false })
    }else {
      this.closeImageEvidenceModal()
    }
  }

  validateImagesAtachments(files) {
    const intl = this.props.intl;
    if(files.length > 1) {
      this.setState({
        evAttachFile: {},
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"checklist.modal.select.image.error"}),
    })
    return
    }
    for (const file of files) {
      if (!file.type.includes("image")) {
        this.setState({
          evAttachFile: {},
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: intl.formatMessage({id:"checklist.modal.image.field.error"}),
        })
        return
      }
    }
    
    const image = new Image();
    image.addEventListener('load', () => {
      this.setState({ evAttachFile: files[0] })
    });
    image.src = URL.createObjectURL(files[0])
  }

  removeFileFromDropzone() {
    this.setState({ evAttachFile: {} })
  }

  async getOrganizationMaterials() {
    const intl = this.props.intl;
    this.setState({ loading: true });
    let orgId = this.state.called.organization.id;
    await Api.get(`/organizationmaterials/organization/${orgId}`)
      .then(result => {
        this.setState({ loading: false, OrganizationMaterialList: result.data.data });
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(err);
      });
  }

  async createOrganizationMaterial() {

  }

  async createCalledOrganizationMaterial() {
    if(this.state.formCallOrgMatOrganiaztionMaterial && this.state.formCallOrgMatOrganiaztionMaterial.length > 0) {
      const intl = this.props.intl;
      const { user } = this.props;
      this.setState({ loading: true, calledOrganizationMaterialInsertModalOpen: false });
      let dataSet = new FormData();
      dataSet.append('CalledId', this.state.called.id);
      //dataSet.append('OrganizationMaterialId', this.state.formCallOrgMatOrganiaztionMaterial.id);
      console.log("formCallOrgMatQuantity")
      console.log(this.state.formCallOrgMatQuantity)
      if(this.state.formCallOrgMatQuantity > 0)
        dataSet.append('Quantity', this.state.formCallOrgMatQuantity);
      else
        dataSet.append('Quantity', 0);
      dataSet.append('CreatedUser', user.user.id);

      let OrganizationMaterialIds = ""
      this.state.formCallOrgMatOrganiaztionMaterial.forEach(item => {
        OrganizationMaterialIds += `${item.id},`
      })
      dataSet.append('OrganizationMaterialIds', OrganizationMaterialIds);

      let config = {
        headers: {
            "Content-Type": "multipart/form-data"
          }
        };
      await Api.post(`/calledorganizationmaterials/multiple`, dataSet, config)
        .then(result => {
          if (result.data.success) {
            let data = result.data.data;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: this.state.formCallOrgMatOrganiaztionMaterial.length > 1 ? intl.formatMessage({id:"called.organization.material.list.added.material.plural"}) : intl.formatMessage({id:"called.organization.material.list.added.material"}),
              calledOrganizationMaterialInsertModalOpen: false,
              formCallOrgMatQuantity: 0,
              formCallOrgMatOrganiaztionMaterial: null,
            });
            this.getCalledOrganizationMaterials();
          }
        })
        .catch(err => {
          this.setState({
            calledOrganizationMaterialInsertModalOpen: true,
            formCallOrgMatOrganiaztionMaterial: null,
            formCallOrgMatQuantity: 0,
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          this.getCalledOrganizationMaterials();
        });
    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"called.organization.material.list.select.material"})
      });
    }
  }

  async updateCalledOrganizationMaterialQuantity(e) {
    const intl = this.props.intl;
    const { user } = this.props;
    this.setState({ loading: true });

    console.log("=== E ===")
    console.log(e)

    var regex = /^[0-9]+$/;
    if(e && e.field == "quantity") {
      if(!regex.test(e.value)) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"called.organization.material.list.invalid.material.amount"})
        });
        return false
      }
    } else {
      regex = /^[0-9.]+$/;
      if(!regex.test(e.value)) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"called.organization.material.list.invalid.material.value"})
        });
        return false
      }
    }

    let link = `/calledorganizationmaterials/quantity`

    let dataSet = new FormData();
    dataSet.append('Id', e.id)
    dataSet.append('UpdatedUser', user.user.id)

    if (e && e.field == "quantity") {
      link = `/calledorganizationmaterials/quantity`
      dataSet.append('Quantity', e.value)
    } else {
      link = `/calledorganizationmaterials/value`
      dataSet.append('Quantity', 1)
      dataSet.append('Value', e.value)
    }
    

    let config = {
      headers: {
          "Content-Type": "multipart/form-data"
        }
      };
    await Api.put(link, dataSet, config)
      .then(result => {
        if (result.data.success) {
          let data = result.data.data;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({id:"called.organization.material.list.updated.material.count"})
          });
          this.getCalledOrganizationMaterials();
        }
      })
      .catch(err => {
        this.setState({
          formCallOrgMatOrganiaztionMaterial: null,
          formCallOrgMatQuantity: 0,
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        this.getCalledOrganizationMaterials();
      });
  }

  async getCalledOrganizationMaterials() {
    const intl = this.props.intl;
    this.setState({ loading: true });
    let calledId = this.state.called.id;
    await Api.get(`/calledorganizationmaterials/called/${calledId}`)
      .then(result => {
        let formatedList = []
        if(result.data.data.length > 0) {
          formatedList = result.data.data.map(item => {
            return {
              ...item,
              MaterialText: item.organizationmaterial ? item.organizationmaterial.name : '',
              MaterialValue: item.organizationmaterial ? item.organizationmaterial.value : '',
              MaterialTotalValue: item.organizationmaterial ? item.organizationmaterial.value ? item.organizationmaterial.value * item.quantity : 0 : 0
            }
          })
        }
        this.setState({ loading: false, CalledOrganizationMaterialList: formatedList });
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(err);
      });
  }

  async deleteCalledOrganizationMaterials() {
    const intl = this.props.intl
    this.setState({ loading: true });

    let ids = this.state.materialFinishCalledDeleteItems
    if(ids != null) {
      let idsString = ""
      ids.forEach(function(item, i) {
        idsString += `${item.id},`
      })


      Api.delete(`/calledorganizationmaterials/multiple/${ids}`)
        .then(result => {
          if (result && result.data && result.data.success && result.data.success == true) {
            this.setState({
              evAttachFileData: result.data.data.evidenceattachment,
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({id:"called.organization.material.deleted"}),
              calledOrganizationMaterialDeleteModalOpen: false,
              materialFinishCalledDeleteItems: null
            });
          }
          this.getCalledOrganizationMaterials();
        })
        .catch(err => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          this.getCalledOrganizationMaterials();
        });
    }else {
      this.setState({ loading: false });
    }
  }

  async deleteDocument(id) {
    const intl = this.props.intl
    this.setState({ loading: true });
    
    Api.delete(`/calledorganizationchecklists/ev/attach/${id}`)
      .then(result => {
        if (result && result.data && result.data.success && result.data.success == true) {
          this.setState({
            evAttachFileData: result.data.data.evidenceattachment,
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({id:"checklist.evidence.file.deleted"})
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
      });
  }

  async getCalledFile(attachment,fileName) {
   /*
	let fName = fileName
    if(fName.startsWith('/wwwroot/', 0))
      fName = fName.substring(9)
    
    let apiUrl = Api.apiurlbase()
    if (apiUrl.endsWith("/"))
      apiUrl = apiUrl.slice(0, -1)

    window.open(`${apiUrl}/${fName.replace("///", "/").replace("//", "/").replace("/files", "files")}`, '_blank');
	*/

	const intl = this.props.intl;
	this.setState({ loading: true });
	
	
	let url = await FileService.getFile(attachment.hirerid,attachment.id,fileName,attachment.filerepositorymethod); 
	//alert(JSON.stringify(url.data));
	this.setState({ loading: false });
	if(url &&  url.data && url.data.data && url.data.success){
		window.open(`${url.data.data}`, '_blank');
	}
	else{
		this.setState({
			loading: false, openNotification: true, notificationVariant: "error",
			notificationMessage: intl.formatMessage({ id: "file.not.found" })
		});

	}	


  };

  getCanInformCalledClosingStatusByOrgId = async _ => {
    let result = await getCanInformCalledClosingStatusByOrgId(this.state.called.organization.id)
    if(result && result.success) {
      if(result && result.data)
        this.setState({ caninformcalledclosingstatus: true })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  async getOrganizationLists() {
    const intl = this.props.intl;
    const { user } = this.props;
    this.setState({ loading: true });
    let calledId = this.state.called.id;
    let orgId = this.state.called.organization.id;
    let orgCategoryId = this.state.called.organizationcategory.id;

    let dataSet = new FormData();
    dataSet.append('CalledId', calledId);
    dataSet.append('OrganizationId', orgId);
    dataSet.append('CategoryId', orgCategoryId);
    dataSet.append('CreatedUser', user.user.id);
    dataSet.append('Type', 2);
    await Api.post(`/calledorganizationchecklists/called`, dataSet)
      .then(result => {
        if(result && result.data && result.data.data) {
        this.setState({ loading: false, CalledOrganizationChecklistList: result.data.data, getChecklists: true });
        } else {
          this.setState({ loading: false, getChecklists: true })
        }
      })
      .catch(err => {
        this.setState({ loading: false, getChecklists: true })
      });
  }

  async insertCalledOrganizationLists(calledId, id) {
    const intl = this.props.intl;
    const { user } = this.props;
    this.setState({ loading: true });
    let dataSet = new FormData();
    dataSet.append('CalledId', calledId);
    dataSet.append('OrganizationChecklistId', id);
    dataSet.append('Value', false);
    dataSet.append('CreatedUser', user.user.id);

    let config = {
      headers: {
          "Content-Type": "multipart/form-data"
        }
      };
    await Api.post(`/calledorganizationchecklists`, dataSet)
      .then(result => {
        if (result.data.success) {
          let data = result.data.data;
          this.setState({ loading: false });
          this.getCalledOrganizationLists();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.getCalledOrganizationLists();
      });
  }

  async getCalledOrganizationLists() {
    const intl = this.props.intl;
    this.setState({ loading: true });
    await Api.get(`/calledorganizationchecklists/called/${this.state.called.id}`)
      .then(result => {
        if(result && result.data && result.data.data && result.data.data.length > 0) {
          this.setState({ loading: false, CalledOrganizationChecklistList: result.data.data ? result.data.data : [], StepCalledOrganizationChecklist: true });
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(err);
      });
  }

  async getMaterials(){
    const intl = this.props.intl;
    this.setState({ loading: true });
    await Api.get(`/organizationmaterials/organization/${this.state.called.organization.id}`)
    .then(result => {
        this.setState({ loading: false, MaterialList: result.data.data });
     })
     .catch(err => {
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(err);
    });
  };

  async getMaterialsFromService(){
    this.setState({ loading: true });
    var result = await Service.getMaterials();
      if (result.success) {
        this.setState({ InsertMaterialList: result.data });
                      
      } else {
        this.setState({
          openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
};

  async getMaterialsSelect(){
    const intl = this.props.intl;

    this.setState({ 
      loading: true
    });
    
    Api.get(`/calledorganizationmaterials/called/${this.state.called.id}`)
    .then(result => {
      let materialSelectedObj = [];

      for(var obj of result.data.data){
        materialSelectedObj.push(obj.organizationmaterial);
      }
        this.setState({ 
          loading: false,
          materialSelected: materialSelectedObj
        });
     })
     .catch(err => {
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(err);
    });
  };

  async componentDidMount() {
    await this.getMaterials();
    await this.getMaterialsSelect();
    await this.checkSLAAttendOverflow()
    await this.getCanInformCalledClosingStatusByOrgId()

    if(this.props.slaAttend > 100)
      this.setState({ SLAStepIsPrevious: true, ActionStepIsPrevious: true })
    if(this.state.caninformcalledclosingstatus)
      this.setState({ StatusIsPrevious: true })
  };
  
  componentDidUpdate() {
    //console.log('updated');
  }

  createOrganizationMaterial = async e => {
    e.preventDefault();

    const { intl } = this.props;

    this.setState({ loading: true });

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    let data = new FormData();
      if (this.isClient) {
        this.setState(prevState => ({
          ...prevState
        }));
      } else {
        this.setState(prevState => ({
          ...prevState
        }));
      }

      data.append("OrganizationId", this.state.called.organization.id)
      let orgMatList = this.state.OrganizationMaterialId
      if(orgMatList != null && orgMatList != ""){
        let result = "";
        orgMatList.map(item => {
          result = item.id+","+result
        })
        data.append("MaterialLot", result);
        data.append("name", "Materiais em Lote");
      }else {
        data.append("name", this.state.InsertOrganizationMaterialName)
      }

      let oldVSplit = this.state.InsertOrganizationMaterialValue.split(',')
      let oldV = oldVSplit[0]
      if(oldV.length > 0) {
        oldV = oldV.replaceAll('.', ',')
        if('1' in oldVSplit)
        oldV += `.${oldVSplit[1]}`
        data.append("value", oldV)
      } else {
        data.append("value", 0)
      }

      if(this.state.invoicedate.length > 0)
        data.append("invoicedate", moment(this.state.invoicedate).format('YYYY/MM/DD HH:mm:ss'))
      else
        data.append("invoicedate", moment().format('YYYY/MM/DD HH:mm:ss'))
      data.append("garanty", this.state.InsertOrganizationMaterialGaranty)
      data.append("createdAt", moment().format('YYYY/MM/DD HH:mm:ss'))
      data.append("createdUser", this.state.createdUser)
      
        Api.post('/organizationmaterials', data, config)

        .then(result => {
          if (result.data.success) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: [],
              organizationMaterialInsertModalOpen: false
            });
            this.getOrganizationMaterials();
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });
  };

  handleSubmit = async e => {
    const intl = this.props.intl;
    
    if(this.props.slaAttend > 100 && this.state.finishStepSLA == false) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"calledfinish.select.motive.sla.overflow"})
      });
      return
    }
    console.log("=== HandleSubmit - (Finish Called) ===")
    
    let data = new FormData();
    const { user } = this.props;
    this.setState({ loading: true });

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    if(this.state.calledorganizationfields) {
      this.state.calledorganizationfields.forEach((item) => {
        let dataCallOrgField = new FormData();
        dataCallOrgField.append('Id', item.id);
        dataCallOrgField.append('OrganizationFieldId', item.organizationfieldid);
        dataCallOrgField.append('CalledId', item.calledid);
        dataCallOrgField.append('Value', item.value);
        dataCallOrgField.append('UpdatedUser', user.user.id);
        Api.put("/calledorganizationfields", dataCallOrgField)
        .then(result => {
            if (result.data.success) {
            }
        })
        .catch(err => {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
            return
        });
      })
    }

    Object.keys(this.state).forEach((key) => {
      if(key.includes("chk-")) {
        
        let val = this.state[key]
        let explode = key.split('-')
        let calledorganizationchecklistId = explode[1];
        let organizationChecklistId = 0

        let orgcheck = this.state.CalledOrganizationChecklistList;
        orgcheck.map(value => {
          if(value.id == calledorganizationchecklistId) {
            organizationChecklistId = value.organizationchecklist.id
          }
        })

        let datacallorgcheck = new FormData();
        datacallorgcheck.append('Id', calledorganizationchecklistId);
        datacallorgcheck.append('CalledId', this.state.called.id);
        datacallorgcheck.append('OrganizationChecklistId', organizationChecklistId);
        datacallorgcheck.append('Value', val);
        datacallorgcheck.append('UpdatedUser', user.user.id);
        Api.put("/calledorganizationchecklists", datacallorgcheck, config)
        .then(result => {
          if (result.data.success) {
            // console.log(result.data.data)
          }
        })
        .catch(err => {
          const intl = this.props.intl;
          this.setState({
            //loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          return
        });
      }
    })

    if(this.state['template3'] != this.state['template4']) {
      data.append('id', this.state.called.id);
      data.append('therewasmaterialsubstitution', this.state['template']);
      data.append('quantityreama4', 0);
      data.append('updateduser', user.user.id);
      data.append('therewasmaterialsubstitution', this.state['template']);
      data.append('baduse', this.state['template3']);
     
      if(this.state['template'] == true){
        let list = this.state['material'];
            
        if(list != null && list != ""){
          let result = "";

          for (let value in this.state['material']) {
            result = this.state['material'][value].id+","+result
          }
          
          data.append("calledmaterials", result);
        }
      }
      
      Api.put("/calleds/tipping", data, config)
          .then(result => {
              if (result.data.success) {
                const intl = this.props.intl;
                this.props.calledFinishOk();
              }
          })
          .catch(err => {
              const intl = this.props.intl;
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              this.closeModal();
          });
      }else {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"calledfinish.bad.material.usage"})
        });

        this.closeModal();
      }
  }

  changeValues(stateName, value) {
    
    if(stateName == 'template'){
      if(value == true){
        this.setState({
          ['template']: value,
          ['template2']: false,
        });
      }else{
        this.setState({
          ['template']: value,
        });
      }
      
    }else if(stateName == 'template2'){
      if(value == true){
        this.setState({
          ['template2']: value,
          ['template']: false,
        });
      }else{
        this.setState({
          ['template2']: value,
        })
      }
    }

 
    else if(stateName == 'template3'){
      
      if(value == true){
        this.setState({
          ['template3']: value,
          ['template4']: false,
        });
      }else{
        this.setState({
          ['template3']: value,
        })
      }
     
    }
    
    else if(stateName == 'template4'){
      if(value == true){
        this.setState({
          ['template4']: value,
          ['template3']: false,
        });
      }else{
        this.setState({
          ['template4']: value,
        })
      }
      
    }
    else if(stateName.includes("chk-")) {
      this.setState({
        [stateName]: value
      })
      let splitChk = stateName.split('-')
      let checkId = splitChk[1]


    }
    else{
      this.setState({
        [stateName]: value
      });
    }
    
    //this.submitAction();
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
  
    if(name == "formCallOrgMatQuantity") {
      if (value.match('.') || value.match(',')) {
        this.setState({
            [name]: parseInt(value.replaceAll(",", ""))
        });
      }
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  submitAction(){
    this.props.handleSubmit();
  }

  closeModal(){
    //this.props.handleClose();
    this.props.closeModalCalledFinish();
  }

  createCheckState(itemId, itemData = null) {
    PapiroConsole.log("=== createCheckState ===")
    PapiroConsole.log(itemId)
    let item = this.state.CalledOrganizationChecklistList.filter(checklist => (checklist.id == itemId))
    PapiroConsole.log(item)
    if(item.length > 0) {
      item[0].value = true
      var stateNameVar = `chk-${itemId}`
      //this.setState({ [stateNameVar]: true })

      if (itemData != null) {
        item[0].value = false
        let completedEvidence = false
        
        PapiroConsole.log("=== itemData ===")
        PapiroConsole.log(itemData)

        if (itemData && itemData.evidencetext && itemData.evidencetext.length > 0) {
          PapiroConsole.log("=== itemData.evidencetext ===")
          PapiroConsole.log(itemData.evidencetext)
          completedEvidence = true
          item[0].evidencetext = itemData.evidencetext
        } else
          item[0].evidencetext = ''
        
        if (itemData && itemData.calledorganizationchecklistattachments && itemData.calledorganizationchecklistattachments.length > 0) {
          PapiroConsole.log("=== itemData.calledorganizationchecklistattachments ===")
          PapiroConsole.log(itemData.calledorganizationchecklistattachments)
          completedEvidence = true
          item[0].calledorganizationchecklistattachments = itemData.calledorganizationchecklistattachments
        } else
          item[0].calledorganizationchecklistattachments = []

        if (completedEvidence)
          item[0].value = true
      }

      let items = this.state.CalledOrganizationChecklistList.filter(checklist => (checklist.id != itemId))
      items.push(item[0])

      this.setState({ CalledOrganizationChecklistList: items, [stateNameVar]: item[0].value })
      PapiroConsole.log("=== createCheckState items ===")
      PapiroConsole.log(items)
    }
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  deleteCalledOrganizationMaterialsFunc = itm => {
    if(itm.length > 0) {
      this.setState({ 
        materialFinishCalledDeleteButton: 'flex',
        materialFinishCalledDeleteItems: itm
      })
    }else {
      this.setState({
        materialFinishCalledDeleteButton: 'none',
        materialFinishCalledDeleteItems: null,
      })
    }
  }

  saveCalledReason = async (calledReasonId, reasonOther) => {
    const { userSession, intl } = this.props

    this.setState({ loading: false })

    if(calledReasonId == null) {
      this.setState({ loading: false })
      this.setMessage("error", intl.formatMessage({id:"called.accompaniment.select.reason"}), true)
      return false
    }

    if (calledReasonId.id == 4) {
      if (reasonOther.length == 0) {
        this.setState({ loading: false })
        this.setMessage("error", intl.formatMessage({ id: "other.select" }), true)
        return false
      }
    }

    let data = new FormData();
    data.append('calledid', this.state.called.id)
    data.append('reasonid', calledReasonId.id)
    data.append('description', reasonOther)
    data.append('createduser', userSession.user.id)

    let config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    await Api.post("/calledreasons", data, config)
      .then(result => {
        if (result.data.success) {
          this.setState({ loading: false })
          this.setFinishStep(1)
          this.setMessage("success", intl.formatMessage({id:"called.accompaniment.reason.saved"}), true)
          return true
        } else {
          this.setState({ loading: false })
          this.setMessage("error", result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }), true)
          return false
        }
      }).catch(err => {
        this.setState({ loading: false })
        this.setMessage("error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }), true)
      })
  }

  render() {
    const custom = {
      enterRight: { opacity: 1, animationName: "fadeInOpacity", animationIterationCount: 1, animationTimingFunction: "ease-in", animationDuration: "2s" },
      enterLeft : { opacity: 1, animationName: "fadeInOpacity", animationIterationCount: 1, animationTimingFunction: "ease-in", animationDuration: "2s" },
      exitRight : { opacity: 1, animationName: "fadeInOpacity", animationIterationCount: 1, animationTimingFunction: "ease-in", animationDuration: "2s" },
      exitLeft  : { opacity: 1, animationName: "fadeInOpacity", animationIterationCount: 1, animationTimingFunction: "ease-in", animationDuration: "2s" }
    }
    const classes2 = this.useStyles();
    const intl = this.props.intl;
    const classes = this.props.classes;
    const CalledOrganizationChecklistList = this.state.CalledOrganizationChecklistList;

    const hideOverflowStyle = this.hideOverflowStyle
    if(this.state.getChecklists == true) {
    return (
      <div>
        <Dialog open={this.state.calledOrganizationMaterialDeleteModalOpen} onClose={this.closeCalledOrganizationMaterialDeleteModal} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            { intl.formatMessage({ id: "sure.delete.multiple" }) }
            <ClearIcon onClick={this.closeCalledOrganizationMaterialDeleteModal}  style={{ float: 'right', cursor : 'pointer'}}/> 
          </DialogTitle>
          <Grid container justify="flex-end" style={{ padding: 15, marginTop: 0 }}>
                  <Grid item xs={12} sm={4}>
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      className={classes.button}
                      onClick={ this.closeCalledOrganizationMaterialDeleteModal }
                    >
                      {<FormattedMessage id="cancel" />}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={1}></Grid>
                  <Grid item xs={12} sm={4}>
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={ this.deleteCalledOrganizationMaterials }
                    >
                      {<FormattedMessage id="delete" />}
                    </Button>
                  </Grid>
          </Grid>     
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.organizationMaterialInsertModalOpen}
          onClose={this.closeNewOrganizationMaterialModal}
          aria-labelledby="form-dialog-title-org-mat"
          PaperProps={{
            style: {
              overflow: 'hidden'
            }
          }} >
          <DialogTitle id="form-dialog-title-org-mat">
            {intl.formatMessage({id:"new.materials"})}
            <ClearIcon onClick={this.closeNewOrganizationMaterialModal}  style={{ float: 'right', cursor : 'pointer'}}/> 
          </DialogTitle>
          <DialogContent>
            <Grid container style={{ padding: 15 }}>
              <Grid item xs={12} sm={12}>
                <SimpleCheckBox label={intl.formatMessage({id:"organization.material.inform.material"})}  name='templateorganizationmaterial' stateName='templateorganizationmaterial' 
                  changeSelect={(event, newValue) => {
                    this.setState({
                      disableValueInformation : newValue,
                      templateorganizationmaterial: newValue
                    });
                  }}/>
              </Grid>
              </Grid> 
              {this.state.templateorganizationmaterial && (
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"menu.organizationmaterials"})} options={this.state.InsertMaterialList} stateName='OrganizationMaterialId' changeSelect={this.changeValues} selected={this.state.OrganizationMaterialId} />
                  </Grid>
                </Grid>
              )}
              {!this.state.templateorganizationmaterial && (
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField fullWidth label={intl.formatMessage({id:"name"})}  
                      inputProps={{ maxLength: 250 }} autoComplete='fname' variant='outlined' name='InsertOrganizationMaterialName' onChange={this.handleInputChange} value={this.state.InsertOrganizationMaterialName} required />
                  </Grid>
                </Grid>
              )}
                <Grid container spacing={4}>
                  <Grid item xs={5} sm={5}>
                    <TextField
                      label={intl.formatMessage({id:"value"})}
                      variant='outlined'
                      value={this.state.InsertOrganizationMaterialValue}
                      onChange={this.handleInputChange}
                      name="InsertOrganizationMaterialValue"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      autoComplete='fname'
                      required />  
                  </Grid>
                  {/*<Grid item xs={2} sm={2} style={{marginLeft: "-10px"}}>
                    <SimpleHelp message={"Usar . como separador decimal"}/>
                    </Grid>*/}
                </Grid>
              {!this.state.templateorganizationmaterial && (
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={3}>
                    <SimpleDatePicker label="Data da Nota" name='invoicedate' stateName='invoicedate' changeDate={this.changeValues} currentDate={this.state.invoicedate}  required/>
                  </Grid>
                <Grid item xs={12} sm={9}></Grid>
                </Grid>
              )}
              {!this.state.templateorganizationmaterial && (
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField id='filled-number' label={intl.formatMessage({id:"garanty"})} type='number' variant='filled' InputLabelProps={{shrink: true}} name='InsertOrganizationMaterialGaranty' onChange={this.handleInputChange} value={this.state.InsertOrganizationMaterialGaranty} required />
                  </Grid>
                </Grid>
              )}
          </DialogContent>
          <Grid spacing={2} container justify="flex-end" style={{ padding: 15 }}>
            <Grid item xs={12} sm={4}>
              <Button
                variant='contained'
                type="button"
                onClick={this.closeNewOrganizationMaterialModal}
                fullWidth >
                  {this.props.intl.formatMessage({id:"cancel"})}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button 
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                onClick={ this.createOrganizationMaterial }
              >
                {<FormattedMessage id="save" />}
              </Button>
            </Grid>
          </Grid>     
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.calledOrganizationMaterialInsertModalOpen}
          onClose={this.closeCalledOrganizationMaterialInsertModal}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflow: 'hidden'
            }
          }} >
          <DialogTitle id="form-dialog-title">
            {intl.formatMessage({id:"called.organization.material.insert"})}
            <ClearIcon onClick={this.closeCalledOrganizationMaterialInsertModal}  style={{ float: 'right', cursor : 'pointer'}}/> 
          </DialogTitle>
          <DialogContent>
            <Grid container style={{ padding: 15 }}>
              <Grid item xs={12} sm={12}>
                <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"components"})}
                  options={this.state.OrganizationMaterialList}
                  stateName='formCallOrgMatOrganiaztionMaterial'
                  changeSelect={this.changeValues}
                  selected={this.state.formCallOrgMatOrganiaztionMaterial } />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Link component="button"
                  onClick={() => {
                    this.openNewOrganizationMaterialModal()
                  }} variant="body2">
                  {intl.formatMessage({id:"new.material"})}
                </Link>
              </Grid>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <TextField
                  name="formCallOrgMatQuantity"
                  autoComplete="fname"
                  label={intl.formatMessage({id:"amount"})}
                  fullWidth
                  value={this.state.formCallOrgMatQuantity}
                  type="number"
                  variant="outlined"
                  onChange={(e) => this.handleInputChange(e)} />
              </Grid>
            </Grid>
          </DialogContent>
          <Grid spacing={2} container justify="flex-end" style={{ padding: 15 }}>
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant='contained'
                      type="button"
                      onClick={this.closeCalledOrganizationMaterialInsertModal}
                      fullWidth >
                        {this.props.intl.formatMessage({id:"cancel"})}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={ this.createCalledOrganizationMaterial }
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
          </Grid>     
        </Dialog>
        <Dialog open={this.state.checklistTextEvidenceModalOpen} onClose={this.closeTextEvidenceModal} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            {intl.formatMessage({id:"menu.organization.checklists"})} - { intl.formatMessage({ id: "evidence.text" }) } - { this.state.evTextTitle }
            <ClearIcon onClick={this.closeTextEvidenceModal}  style={{ float: 'right', cursor : 'pointer'}}/> 
          </DialogTitle>
          <DialogContent>
            <DialogContent>
              <TextField
                name="evTextInput"
                autoComplete="fname"
                label={intl.formatMessage({ id: "evidence.text" })}
                multiline
                rows="4"
                fullWidth
                value={this.state.evTextInput}
                variant="outlined"
                onChange={(e) => this.handleInputChange(e)}
                inputProps={{ maxLength: 255 }}
              />
            </DialogContent>
          </DialogContent>
          <Grid container justify="flex-end" style={{ justifyContent: 'flex-end', paddingRight: 15, paddingBottom: 15 }}>
                  <Grid item xs={12} sm={4}>
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      onClick={ this.sendTextEvidence }
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
          </Grid>     
        </Dialog>
        <Dialog open={this.state.checklistImageEvidenceModalOpen} onClose={this.closeImageEvidenceModal} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            {intl.formatMessage({id:"menu.organization.checklists"})} - { intl.formatMessage({ id: "evidence.attachment" }) } - { this.state.evAttachTitle }
            <ClearIcon onClick={this.closeImageEvidenceModal}  style={{ float: 'right', cursor : 'pointer'}}/> 
          </DialogTitle>
          <DialogContent>
            <DialogContent>
              <Grid container spacing={0} style={{ marginBottom: '12px' }}>
                        <section style={{ width: '100%', marginTop: '0', marginBottom: '10px' }}>
                            <div className={classes.dropzoneArea} style={{ marginTop: '0' }}>
                                <Dropzone onDrop={acceptedFiles =>  this.validateImagesAtachments(acceptedFiles)}>
                                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p>{intl.formatMessage({id:"drag.image.or.click.to.select"})}</p>
                                        </div>
                                    )}
                                </Dropzone>
                                {this.state.evAttachFile && this.state.evAttachFile.name && (
                                    <div className={classes.dropzoneItem} key={this.state.evAttachFile.name}>
                                        {this.state.evAttachFile.name}
                                        <DeleteForever className={classes.deleteItem} onClick={() => { this.removeFileFromDropzone() }} />
                                    </div>
                                )}
                            </div>
                        </section>
              </Grid>
              {this.state.evAttachFileData && this.state.evAttachFileData.path && (

                <Grid container spacing={0} style={{marginTop: "5px"}}>
                    <Grid item xs={12}>
                        <div className={classes.attachmentsBox}>
                            <label>{intl.formatMessage({id:"attachments"})}</label>
                            <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                <ul>
                                    <li key={this.state.evAttachFileData.path}>
                                        <AttachFile /> <Button onClick={() => this.getCalledFile(this.state.evAttachFileData, this.state.evAttachFileData.path)}>{this.state.evAttachFileData.description}</Button>
                                        <DeleteForeverIcon onClick={ () => this.deleteDocument(this.state.evAttachId) } style={{ cursor: "pointer" }}/>
                                    </li>
                                </ul>
                            </ImageList>
                        </div>
                    </Grid>
                </Grid>
                )}
            </DialogContent>
          </DialogContent>
          <Grid container justify="flex-end" style={{ justifyContent: 'flex-end', paddingRight: 15, paddingBottom: 15 }}>
                  <Grid item xs={12} sm={4}>
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      onClick={ this.sendImageEvidence }
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
          </Grid>      
        </Dialog>
        <div className="container main-window">
          <Grid container spacing={4} style={{ marginBottom: 15, marginTop: 0 }}>
            <Grid xs={12} sm={12} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <Typography component='h1' variant='h5'>
                {intl.formatMessage({id:"calledfinish.info.for.finishing.called"})}
              </Typography>
              <ClearIcon onClick={this.closeModal}  style={{ float: 'right', cursor : 'pointer'}}/>
            </Grid>
          </Grid>
        </div>
    <StepWizard
      nav={
        <StepNav
        intl={this.props.intl}
        classes={classes}
        slaAttend={this.props.slaAttend}
        actionDescription={this.props.actionDescription}
        actionFinishId={this.props.actionDescription}
        CalledOrganizationChecklistList={this.state.CalledOrganizationChecklistList}
        caninformcalledclosingstatus={this.state.caninformcalledclosingstatus}
        isClient={this.isClient}
        value={this.state.navValue}
        setNavValue={this.setNavValue}
        caninformutilizedmaterialsincalledfinish={this.props.caninformutilizedmaterialsincalledfinish} />
      }
      transitions={custom}>
        {this.props.slaAttend > 100 && <SLAOverflowStep
          stepName={"sla"}
          setFinishStep={this.setFinishStep}
          closeModal={this.closeModal}
          called={this.state.called}
          setMessage={this.setMessage}
          setLoading={this.setLoading}
          setNavValue={this.setNavValue}
          navValue={this.state.navValue}
          saveCalledReason={this.saveCalledReason}
          changeSLAOverflowStepReasonId={this.changeSLAOverflowStepReasonId}
          changeSLAOverflowStepReasonDesc={this.changeSLAOverflowStepReasonDesc}
          caninformutilizedmaterialsincalledfinish={this.props.caninformutilizedmaterialsincalledfinish} />}
          <ActionStep
            stepName={"action"}
            setFinishStep={this.setFinishStep}
            closeModal={this.closeModal}
            called={this.props.calledData}
            calledProps={this.state.called}
            files={this.props.files}
            addAccompanimentsList={this.props.addAccompanimentsList}
            setFinishAccompanimentId={this.props.setFinishAccompanimentId}
            setActionDescription={this.props.setActionDescription}
            actionDescription={this.props.actionDescription}
            actionFinishId={this.props.actionFinishId}
            setMessage={this.setMessage}
            setLoading={this.setLoading}
            previous={this.state.SLAStepIsPrevious}
            setNavValue={this.setNavValue}
            navValue={this.state.navValue}
            CalledOrganizationChecklistList={this.state.CalledOrganizationChecklistList}
            canInformCalledClosingStatus={this.state.caninformcalledclosingstatus}
            isClient={this.isClient}
            removeItem={this.props.removeItem}
            addFileItem={this.props.addFileItem}
            defaultActionList={this.props.defaultActionList}
            caninformutilizedmaterialsincalledfinish={this.props.caninformutilizedmaterialsincalledfinish}
            handleSubmit={this.handleSubmit} />
        {this.state.caninformcalledclosingstatus && (
          <CalledClosingStatusStep
            stepName={"status"}
            setFinishStep={this.setFinishStep}
            closeModal={this.closeModal}
            called={this.props.calledData}
            calledProps={this.state.called}
            setMessage={this.setMessage}
            setLoading={this.setLoading}
            previous={this.state.ActionStepIsPrevious}
            setNavValue={this.setNavValue}
            navValue={this.state.navValue}
            CalledOrganizationChecklistList={this.state.CalledOrganizationChecklistList}
            caninformutilizedmaterialsincalledfinish={this.props.caninformutilizedmaterialsincalledfinish}
            handleSubmit={this.handleSubmit} />
        )}
        {this.state.CalledOrganizationChecklistList.length > 0 && this.context.can('Add', 'OrganizationChecklist') && !this.isClient && (
        <CalledOrganizationChecklistStep
          stepName={"check"}
          setFinishStep={this.setFinishStep}
          CalledOrganizationChecklistList={this.state.CalledOrganizationChecklistList}
          closeModal={this.closeModal}
          called={this.props.calledData}
          calledProps={this.state.called}
          setMessage={this.setMessage}
          setLoading={this.state.setLoading}
          previous={this.state.ActionStepIsPrevious}
          StatusPrevious={this.state.StatusIsPrevious}
          SLAPrevious={this.state.SLAStepIsPrevious}
          openChecklistTextEvidenceModal={this.openChecklistTextEvidenceModal}
          openChecklistImageEvidenceModal={this.openChecklistImageEvidenceModal}
          changeValues={this.changeValues}
          Checkstate={this.state}
          setNavValue={this.setNavValue}
          navValue={this.state.navValue}
          caninformutilizedmaterialsincalledfinish={this.props.caninformutilizedmaterialsincalledfinish}
          handleSubmit={this.handleSubmit}
        />
        )}
        {this.props.caninformutilizedmaterialsincalledfinish && (
        <CalledOrganizationMaterialStep
          stepName={"material"}
          setFinishStep={this.setFinishStep}
          previous={this.state.ActionStepIsPrevious}
          StatusPrevious={this.state.StatusIsPrevious}
          SLAPrevious={this.state.SLAStepIsPrevious}
          StepCalledOrganizationChecklist={this.StepCalledOrganizationChecklist}
          openCalledOrganizationMaterialDeleteModal={this.openCalledOrganizationMaterialDeleteModal}
          openCalledOrganizationMaterialInsertModal={this.openCalledOrganizationMaterialInsertModal}
          NoDataRows={NoDataRows}
          CalledOrganizationMaterialList={this.state.CalledOrganizationMaterialList}
          updateCalledOrganizationMaterialQuantity={this.updateCalledOrganizationMaterialQuantity}
          changeValues={this.changeValues}
          template3={this.state.template3}
          template4={this.state.template4}
          handleSubmit={this.handleSubmit}
          setNavValue={this.setNavValue}
          navValue={this.state.navValue}
          CalledOrganizationChecklistList={this.state.CalledOrganizationChecklistList}
          isClient={this.isClient}
          disabled={!this.context.can('Add', 'OrganizationMaterial')}
          deleteCalledOrganizationMaterialsFunc={this.deleteCalledOrganizationMaterialsFunc} />
        )}
    </StepWizard>
    {this.state.loading && (
            <Overlay>
                <CircularProgress color="secondary" />
            </Overlay>
        )}

        <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
        />
      </div>
    );
    }else {
      return (
        <div classs="Teste">
          <div className="container main-window">
            <Grid container spacing={4} style={{ marginBottom: 15, marginTop: 0 }}>
              <Grid xs={12} sm={12} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Typography component='h1' variant='h5'>
                  {intl.formatMessage({id:"calledfinish.info.for.finishing.called"})}
                </Typography>
                <ClearIcon onClick={this.closeModal}  style={{ float: 'right', cursor : 'pointer'}}/>
              </Grid>
              <Grid xs={12} sm={12} style={{ height: 300, minHeight: 300, maxHeight: 300 }}>
              </Grid>
            </Grid>
          </div>
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        </div>
      )
    }
  }
}


CalledFinishPatrimony.propTypes = {
  classes: PropTypes.object.isRequired,
};



const mapStateToProps = state => ({
  userSession: state.userSession,
  calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledFinishPatrimony))));
CalledFinishPatrimony.contextType = AbilityContext;
