import Api from "./api";


export const getFile = async (hirerid,attachmentid,filename,filerepositorymethod) => {
	let data = [];
	await Api.get(`/attachments/file/${hirerid}/${attachmentid}?path=${filename}&filerepositorymethod=${filerepositorymethod}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  });
	return data;
  };

  
export const getFileByPath = async (hirerid,filename,filerepositorymethod) => {
	let data = [];
	await Api.get(`/attachments/file/${hirerid}?path=${filename}&filerepositorymethod=${filerepositorymethod}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  });
	return data;
  };

  export const getFileById = async (id) => {
	let data = [];
	await Api.get(`/attachments/file/get/${id}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  });
	return data;
  };