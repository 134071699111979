
import React, { Component } from "react"
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from "prop-types"
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material"
import { withStyles } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Overlay } from "../../styles/global"
import CustomizedSnackbars from "../../components/material-snackbars"
import { getOrganizationsByRole } from "../../services/called.service";
import Budget from './components/budget/budget';

import HeaderMenu from "../../components/header-menu"
import Api from "../../services/api"
import * as CalledService from '../../services/called.service'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as calledsActions from '../../redux/actions/calleds'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer'
import moment from "moment"
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import * as Service from "../../services/user.service";
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import CategoryIcon from '@mui/icons-material/Category';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import SimpleCheckBox from '../../components/checkbox/check'

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  PieChart, Pie, Sector, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import SimpleAutoComplete from '../../components/auto-complete/autocomplete'






class CostReport extends Component {

  constructor(props) {
    super(props)

    this.isClient = true
    this.isAdm = false

    this.organizationId = null
    this.attendanceHours = null
    this.attendanceHoursRemote = null;
    this.quantityOfCalleds = null
    this.quantityOfHours = null
    this.valueOfAttendance = null



    const { userSession } = this.props

    if (userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {

      if (item.role.roletype != 3)
        this.isClient = false
      if (item.role.roletype == 0 || item.role.roletype == 1)
        this.isAdm = true
    });

    /*if(userSession.user.organization)
      this.organizationId = userSession.user.organization.id
        */
    this.state = {
      dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
      dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
      showStatistics: false,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      calledsList: [],
      totalCalled: 0,
      comparation: 0,
      activeIndex: 0,
      positiveMonth: true,
      StatusSelected: [],

      OrganizationSelected: 0,
      DepartamentSelected: 0,
      CategorySelected: 0,
      GroupIdSelected: 0,
      OrganizationByRole: [],
      OrganizationIdSelected: 0,
      StatusIdSelected: 0,
      DepartamentIdSelected: 0,
      CategoryIdSelected: 0,
      GroupList: [],
      filtered: false,
      OrganizationList: [],
      StatusList: [],
      CalledsMaterials: [],
      organizationDepartamentsList: [],
      CategoryList: [],
      SlaCalleds: [],
      filterCalleds: [],
      renderGraphic: false

    }

    this.changeValues = this.changeValues.bind(this);

  }

  async componentDidMount() {

    await this.loadItems();

  }




  async loadItems(organizationId = '') {


    //await this.getCalledTotal(organizationId);


    await this.getAllGrous();
    await this.getStatus(false)





  }

  async getStatus(finalizationexternalstatus) {

    this.setState({
      loading: true
    });


    Api.get(`/statuses/getstatus/${finalizationexternalstatus}`)
      .then(result => {
        console.log(result)
        console.log("result status aqui")
        var hasFinishedStatus = false
        var getFinishedStatus = null
        if (result && result.data && result.data.data && result.data.success) {


          if (result.data.data.length > 0) {

            result.data.data.forEach(element => {

              if (element.id == 4) {
                hasFinishedStatus = true
                getFinishedStatus = element
              }


            });

            if (hasFinishedStatus == true) {

              this.setState({
                StatusSelected: getFinishedStatus,
                StatusIdSelected : getFinishedStatus.id
              });

            }

          }


          this.setState({
            StatusList: result.data.data,
            loading: false,

          });
        }

      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }),
            loading: false
          });

        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "process.error" }),
            loading: false
          });



        }
      });


  };
  renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  onPieEnter = (_, index) => {

    console.log("entrei aqui no pieEnter")
    console.log(index)
    this.setState({
      activeIndex: index,
    });
  };

  /*renderActiveShape = ({
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value
  }) => {

    console.log("Linha 160")
    console.log(cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value)
    
    const RADIAN = Math.PI / 180;
    
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };*/

  renderActiveShape = (props) => {

    console.log(props)
    console.log("props aqui")
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  async changeValues(stateName, value) {

    var Value = [value]

    console.log(value)


    this.setState({ loading: true });


    if (stateName == "GroupFilter") {


      this.setState({ ["GroupIdSelected"]: value.id, OrganizationIdSelected: 0, OrganizationSelected: 0, DepartamentSelected: [], CategorySelected: [] });
      await this.getAllOrganizations(Value);

    }
    else if (stateName == 'OrganizationFilter') {
      this.setState({ ["OrganizationIdSelected"]: value.id, DepartamentSelected: 0, CategorySelected: 0, OrganizationSelected: value });

    }

    else if (stateName == 'StatusFilter') {
      this.setState({ ["StatusIdSelected"]: value.id, StatusSelected: value });

    }

    else {

      this.setState({ [stateName]: value });

    }


    this.setState({ loading: false });






  };






  async getCalledTotal(organizationId = '') {
    const intl = this.props.intl;
    this.setState({ loading: true });

    let url = `/dashboard/costReports`;

    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')


    if (dateStart > dateEnd) {

      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
        loading: false
      });
    }
    else {

      if (organizationId != null || organizationId != '' || organizationId != 0) {
        url += "/" + organizationId;
      }

      console.log(this.state.GroupIdSelected)
      console.log(this.state.OrganizationIdSelected)


      url += `?&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&group=${this.state.GroupIdSelected}&orgId=${this.state.OrganizationIdSelected}&statusId=${this.state.StatusIdSelected} `;

      this.setState({ showStatistics: true });



      Api.get(url)
        .then(result => {

          console.log("result aqui")
          console.log(result)
          if (result.data.success) {
            this.setState({
              loading: false,
              filterCalleds: result.data.data.calledsresponse,
              TotalHoursInloco: result.data.data.totalhoursinloco,
              TotalHoursRemote: result.data.data.totalhoursremote,
              TotalHoursDisplacement: result.data.data.totalhoursdisplacement,
              TotalValueCostDisplacement: result.data.data.totalvaluecostdisplacement,
              TotalValueCostInloco: result.data.data.totalvaluecostinloco,
              TotalValueCostHybrid : result.data.data.totalvaluecosthybrid,
              TotalValueCostRemote: result.data.data.totalvaluecostremote,
              TotalRemoteCalleds: result.data.data.totalremotecalleds,
              TotalInlocoCalleds: result.data.data.totalinlococalleds,
              TotalHybridCalleds : result.data.data.totalhybridcalleds,
              SumAllTotalRemoteCalleds: result.data.data.sumalltotalvaluecostremote,
              SumAllTotalInlocoCalleds: result.data.data.sumalltotalvaluecostinloco,
              SumAllTotalHybridCalleds : result.data.data.sumalltotalvaluecosthybrid,
              TotalValueReplacedMaterialsRemote : result.data.data.totalvaluereplacedmaterialsremote,
              TotalValueReplacedMaterialsInloco: result.data.data.totalvaluereplacedmaterialsinloco,
              TotalValueReplacedMaterialsHybrid: result.data.data.totalvaluereplacedmaterialshybrid,
              TotalValueCostDisplacementRemote: result.data.data.totalvaluecostdisplacementremote,
              TotalValueCostDisplacementHybrid: result.data.data.totalvaluecostdisplacementhybrid,
              TotalValueCostDisplacementInloco: result.data.data.totalvaluecostdisplacementinloco,
              filtered: result.data.data
            });
            console.log(result.data.data.totalvaluecostdisplacementhybrid)
            console.log("deslocamento hybrido aqui")
            this.FilterSlaCalled()
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false, openNotification: true, notificationVariant: "error",
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
        })
        .catch(err => {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        });

    }






  }




  async getAllOrganizations(ids) {


    var orgs = null

    if (ids.length > 0) {

      console.log("entrei aqui")
      this.setState({ loading: true });
      let urlids = ids.map((item) => item.id);

      var result = await Service.getAllOrganizationsByGroup(urlids);

      console.log("result aqui")
      console.log(result)

      if (result.success) {

        orgs = result.data.map((org) => {

          return {
            ...org,
            name: `( ${org.group.name} ) ${org.name}`,
          };
        });

      } else {

        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(result);
      }

      this.setState({ loading: false, OrganizationList: orgs });

    }
  }



  getPercent(value, arraytotal) {

    var total = 0
    arraytotal.forEach(element => {

      total += element.value
    });

    if (total == 0) {
      return 0
    }


    return Math.round((value * 100) / total);

  }


  async getAllGrous() {
    this.setState({ loading: true });
    var result = await Service.getUsersGroups();
    if (result.success) {
      this.setState({ loading: false, GroupList: result.data });
    } else {

      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }

    this.setState({ loading: false });


  }


  convertTohoursFormat(minutos) {
    const horas = Math.floor(minutos / 60);
    const min = Math.round(minutos % 60)
    var textoHoras = (`00${horas}`).slice(-2);
    const textoMinutos = (`00${min}`).slice(-2);
    if(horas >= 100) {
      textoHoras = (`00${horas}`).slice(-3);
      let tamanhoHoras = horas.toString().length * -1
      textoHoras = (`00${horas}`).slice(tamanhoHoras);
    }


    return `${textoHoras}:${textoMinutos}`;
  }


  async FilterSlaCalled() {

    var SlaCalleds = this.state.filterCalleds;
    this.setState({ loading: true, renderGraphic: false, SlaCalleds: SlaCalleds });
    const { classes } = this.props

    this.showArrayOfQuantityCalleds()
    this.showArrayOfQuantityHours()
    this.showArrayOfValueAttendance()

    this.setState({ loading: false, renderGraphic: true });
  }

  showArrayOfQuantityCalleds() {
    const intl = this.props.intl;
    const arrayOfQuantityCalleds = [
      {
        "name": "Atendimentos remotos",
        "value": this.state.TotalRemoteCalleds,
        "color": "#3f50b5"

      },
      {
        "name": "Atendimentos em Campo",
        "value": this.state.TotalInlocoCalleds,
        "color": "#FC2361"

      },
      {
        "name": "Atendimentos Híbridos(remotos + em Campo)",
        "value": this.state.TotalHybridCalleds,
        "color": "#F294AF"

      },

    ]
    var TotalquantityOfCalleds = this.state.TotalInlocoCalleds + this.state.TotalRemoteCalleds + this.state.TotalHybridCalleds

    this.quantityOfCalleds =
      <div style={{ marginTop: '49px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PieChart width={300} height={250}>
            <Pie data={arrayOfQuantityCalleds} labelLine={false} label={this.renderCustomizedLabel} outerRadius={100} fill="#8884d8" dataKey="value"
            >
              {
                arrayOfQuantityCalleds.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))
              }
            </Pie>
            <Tooltip />
          </PieChart>

        </div>





        <TableContainer component={Paper} >
          <Table size="small" aria-label="a dense table" >
            <caption style={{ textAlign: 'right', alignSelf: 'stretch' }}> Quantidade total de atendimentos : {TotalquantityOfCalleds}  </caption>
            <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
              <TableRow>
                <TableCell component="th" scope="row" style={{ backgroundColor: '#8884d8', color: 'white' }}>
                  {intl.formatMessage({id:"subtitle"})}
                </TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"type"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>Quantidade de atendimentos</TableCell>
                
              </TableRow>
              {
                arrayOfQuantityCalleds.map((entry, index) => (
                  <TableRow>
                    <TableCell component="th" scope="row" style={{ backgroundColor: `${entry.color}` }}>
                    </TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.name}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.value}</TableCell>
                   
                  </TableRow>
                ))
              }



            </TableBody>
          </Table>
        </TableContainer>
      </div>

  }
  showArrayOfQuantityHours() {
    const intl = this.props.intl;
    const arrayOfQuantityHours = [
      {
        "name": intl.formatMessage({id:"remote.attendance.total.hours"}),
        "value": this.state.TotalHoursRemote,
        "color": "#3f50b5",
        "calleds": this.state.TotalRemoteCalleds,



      },
      {
        "name": intl.formatMessage({id:"in.loco.total.hours"}),
        "value": this.state.TotalHoursInloco,
        "color": "#FC2361",
        "calleds": this.state.TotalInlocoCalleds

      },
      {
        "name": intl.formatMessage({id:"displacement.hours"}),
        "value": this.state.TotalHoursDisplacement,
        "color": "#F294AF",
        "calleds": this.state.TotalInlocoCalleds

      },

    ]

    var TotalquantityOfHoursCalleds = this.state.TotalHoursRemote + this.state.TotalHoursInloco + this.state.TotalHoursDisplacement

    this.quantityOfHours =
      <div style={{ marginTop: '49px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PieChart width={300} height={250}>
            <Pie data={arrayOfQuantityHours} labelLine={false} label={this.renderCustomizedLabel} outerRadius={100} fill="#8884d8" dataKey="value"
            >
              {
                arrayOfQuantityHours.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))
              }
            </Pie>

          </PieChart>

        </div>





        <TableContainer component={Paper} >
          <Table size="small" aria-label="a dense table" >
            <caption style={{ textAlign: 'right', alignSelf: 'stretch' }}> Quantidade total de horas de atendimentos : {this.convertTohoursFormat(TotalquantityOfHoursCalleds)} Horas  </caption>
            <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
              <TableRow>
                <TableCell component="th" scope="row" style={{ backgroundColor: '#8884d8', color: 'white' }}>
                  {intl.formatMessage({id:"subtitle"})}
                </TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"type"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"amount.hours.hh.mm"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"amount.calleds"})}</TableCell>
              </TableRow>
              {
                arrayOfQuantityHours.map((entry, index) => (
                  <TableRow>
                    <TableCell component="th" scope="row" style={{ backgroundColor: `${entry.color}` }}>
                    </TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.name}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.convertTohoursFormat(entry.value)}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.calleds}</TableCell>

                  </TableRow>
                ))
              }



            </TableBody>
          </Table>
        </TableContainer>
      </div>

  }
  showArrayOfValueAttendance() {
    const intl = this.props.intl;

    console.log("deslocamento hybrido aqui")
    console.log(this.state.TotalValueCostDisplacementHybrid)
    const arrayOfValueAttendance = [
      {
        "name": intl.formatMessage({id:"total.cost.remote.attendance"}),
        "value": this.state.TotalValueCostRemote,
        "sumallvalue": this.state.SumAllTotalRemoteCalleds,
        "color": "#3f50b5",
        "calleds": this.state.TotalRemoteCalleds,
        "materialcalledsvalue": this.state.TotalValueReplacedMaterialsRemote,
        "displacementcalledsvalue" : intl.formatMessage({id:"not.applicable"})

      },
      {
        "name": intl.formatMessage({id:"total.cost.on.site"}),
        "value": this.state.TotalValueCostInloco,
        "sumallvalue": this.state.SumAllTotalInlocoCalleds,
        "color": "#FC2361",
        "calleds": this.state.TotalInlocoCalleds,
        "materialcalledsvalue": this.state.TotalValueReplacedMaterialsInloco,
        "displacementcalledsvalue" : this.state.TotalValueCostDisplacementInloco

      },
     /* {
        "name": "Custo total de deslocamento",
        "value": this.state.TotalValueCostDisplacement,
        "color": "#F294AF",
        "calleds": this.state.TotalInlocoCalleds

      },*/
      {
        "name": intl.formatMessage({id:"total.cost.on.site.remote"}),
        "value": this.state.TotalValueCostHybrid,
        "sumallvalue": this.state.SumAllTotalHybridCalleds,
        "color": "#F294AF",
        "calleds": this.state.TotalHybridCalleds,
        "materialcalledsvalue": this.state.TotalValueReplacedMaterialsHybrid,
        "displacementcalledsvalue" : this.state.TotalValueCostDisplacementHybrid
        

      },

    ]

    var totHH = this.state.TotalValueCostRemote + this.state.TotalValueCostInloco + this.state.TotalValueCostHybrid
    var totAllCalleds = this.state.TotalRemoteCalleds + this.state.TotalInlocoCalleds + this.state.TotalHybridCalleds
    var totMaterial = this.state.TotalValueReplacedMaterialsRemote + this.state.TotalValueReplacedMaterialsInloco + this.state.TotalValueReplacedMaterialsHybrid
    var totDisplacement = this.state.TotalValueCostDisplacementRemote + this.state.TotalValueCostDisplacementInloco + this.state.TotalValueCostDisplacementHybrid

    console.log("custos totais aqui")
    console.log(this.state.SumAllTotalHybridCalleds,this.state.SumAllTotalInlocoCalleds,  this.state.SumAllTotalRemoteCalleds)
    var TotalCoustOfCalleds = this.state.SumAllTotalRemoteCalleds + this.state.SumAllTotalInlocoCalleds + this.state.SumAllTotalHybridCalleds


    this.valueOfAttendance =
      <div style={{ marginTop: '49px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PieChart width={300} height={250}>
            <Pie data={arrayOfValueAttendance} labelLine={false} label={this.renderCustomizedLabel} outerRadius={100} fill="#8884d8" dataKey="sumallvalue"
            >
              {
                arrayOfValueAttendance.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))
              }
            </Pie>

          </PieChart>

        </div>





        <TableContainer component={Paper} >
          <Table size="small" aria-label="a dense table" >
            {/*<caption style={{ textAlign: 'right', alignSelf: 'stretch' }}> Custo total de atendimentos {TotalCoustOfCalleds.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}  </caption>*/}
            <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
              <TableRow>
                <TableCell component="th" scope="row" style={{ backgroundColor: '#8884d8', color: 'white' }}>
                  {intl.formatMessage({id:"subtitle"})}
                </TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"type"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"amount.calleds"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"total.personal.value.hh"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"total.replaced.materials.value"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"total.displacement.value.km"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"total.value"})}</TableCell>
              </TableRow>
              {
                arrayOfValueAttendance.map((entry, index) => (
                  <TableRow>
                    <TableCell component="th" scope="row" style={{ backgroundColor: `${entry.color}` }}>
                    </TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.name}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.calleds}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.materialcalledsvalue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.displacementcalledsvalue == intl.formatMessage({id:"not.applicable"}) ? intl.formatMessage({id:"not.applicable"}) : entry.displacementcalledsvalue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.sumallvalue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>

                  </TableRow>
                ))
              }
              <TableRow>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2', fontWeight: 'bolder' }}>Totais</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{totAllCalleds}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{totHH.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{totMaterial.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{totDisplacement.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{TotalCoustOfCalleds.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });

    await this.getCalledTotal();
  }

  closeNotification = _ => {
    this.setState({ openNotification: false });
  }

  render() {

    console.log(this.state)
    console.log("state aqui")
    const { classes, headerMenu } = this.props

    const intl = this.props.intl

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }


    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })} >
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='xl' style={{ paddingLeft: 0, marginLeft: 0 }}>
            <CssBaseline />


            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <Card >
                  <CardContent className="background-title-search">
                    <Typography variant="h5">
                      {<FormattedMessage id="cost.report" />}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
              <Accordion defaultExpanded={true} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>{intl.formatMessage({id:"filters"})}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container style={{ marginBottom: 20 }} spacing={2} >
                    <Grid item xs={12} sm={6} md={3}  >
                      <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} >
                      <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} required />
                    </Grid>



                    <Grid item xs={12} sm={6} md={3}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "menu.groups" })} options={this.state.GroupList} stateName='GroupFilter' changeSelect={this.changeValues} />
                    </Grid>




                    <Grid item xs={12} sm={6} md={3}  >
                      <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.OrganizationList} stateName='OrganizationFilter' changeSelect={this.changeValues} selected={this.state.OrganizationSelected} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "menu.statuses" })} options={this.state.StatusList} stateName="StatusFilter" changeSelect={this.changeValues} selected={this.state.StatusSelected} />
                    </Grid>

                  </Grid>
                </AccordionDetails>
              </Accordion>


              <Grid container style={{ marginBottom: 10 }} justify="flex-end">


                {console.log(this.context)}
                {console.log("context")}
                <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }} >
                  <Button
                    type='submit'
                    //disabled
                    fullWidth
                    variant='contained'
                    color='secondary'
                    disabled={!this.context.can('Add', 'CostManagement')}
                  >
                    {<FormattedMessage id="filter" />}
                  </Button>
                </Grid>


              </Grid>
            </form>


            {this.state.filtered &&

              <Grid container style={{ marginTop: '30px' }} spacing={2} alignItems="stretch">


                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }} >
                  <Card style={{ width: '100%' }}>
                    <CardHeader classes={{ title: classes.cardheadercolor }} style={{ backgroundColor: "#8884d8", color: "white" }} title="Total de atendimentos" />
                    <Divider />
                    <CardContent>

                      {this.quantityOfCalleds}




                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }} >
                  <Card style={{ width: '100%' }}>
                    <CardHeader classes={{ title: classes.cardheadercolor }} style={{ backgroundColor: "#8884d8", color: "white" }} title="Total de horas de atendimentos (HH:mm)" />
                    <Divider />
                    <CardContent>

                      {this.quantityOfHours}




                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }} >
                  <Card style={{ width: '100%' }}>
                    <CardHeader classes={{ title: classes.cardheadercolor }} style={{ backgroundColor: "#8884d8", color: "white" }} title={intl.formatMessage({id:"total.calleds.cost"})} />
                    <Divider />
                    <CardContent>

                      {this.valueOfAttendance}




                    </CardContent>
                  </Card>
                </Grid>












              </Grid>





            }





            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

CostReport.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(calledsActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CostReport)))
CostReport.contextType = AbilityContext