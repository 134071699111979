import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/history';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple';
import * as Service from '../../services/organizationmaterial.service';
import SimpleCheckBox from '../../components/checkbox/check';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import NumberFormat from 'react-number-format';
import SimpleHelp from '../../components/help';
import { DataGrid, GridToolbarFilterButton  } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogConfirmation from '../../components/dialog-confirmation'

const NoDataRows = _ => {
  const intl = this.props.intl;
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >{this.props.intl.formatMessage({id:"rows.show"})}</div>
  )
}

class OrganizationMaterialRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession, organizations } = this.props;

    this.fromProps = false
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
      this.fromProps = true

    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

    const { intl } = this.props;
 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
     OrganizationId:  0,
     OrganizationText: '',
     MaterialId:  0,
     MaterialText: '',
     name : '', 
     garanty : 0, 
     value : '', 
     invoicedate : moment().format('YYYY/MM/DD HH:mm:ss'),
     };         
    this.state = {
      item: this.initialState,
      organizations: organizations,
      files: [],
      loading: false,
      openNotification: false,
      disableValueInformation : false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      MaterialList: [],
      template: false,
      thousandSeparator: intl.formatMessage({ id: "thousand.separator.symbol" }),
      decimalSeparator: intl.formatMessage({ id: "decimal.separator.symbol" }),

      OrgListForDelete: "",
      OrgListForSelect: [],
      OrgList: [],
      OrgListSelect: [],
      openOrgInsertModal: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.NoDataRows = this.NoDataRows.bind(this)
  }

  componentDidMount() {
    
    if (this.state.organizations && this.state.organizations.organization && this.state.organizations.organization.id) {
      this.getOrganizationsById(this.state.organizations.organization.id);
    } else {
      this.getOrganizations();
    }
    this.getMaterials();
  };
     async getOrganizations(){
      const intl = this.props.intl;
          this.setState({ loading: true });
          var result = await Service.getOrganizations();
            if (result.success) {
              this.setState({ loading: false, OrganizationList: result.data });
                            
            } else {
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };

  NoDataRows = _ => {
    const intl = this.props.intl;
    return (
      <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >{this.props.intl.formatMessage({id:"rows.show"})}</div>
    )
  }
  
  getOrganizationsById = async (id) => {
    const intl = this.props.intl;
    this.setState({ loading: true });
    var result = await Service.getOrganizationsByIdLight(id);
    if (result.success) {
        this.setState({ loading: false, OrganizationList: [result.data] });

    } else {
        this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
    }
  };

  async getMaterials(){
    const intl = this.props.intl;
          this.setState({ loading: true });
          var result = await Service.getMaterials();
            if (result.success) {
              this.setState({ loading: false, MaterialList: result.data });
                            
            } else {
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    
    if(name == 'value'){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['value']: value
        }
      }));
      
    }else{
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));
    }
    
  };

  mtel(num){
    num=num.toString().replace(/\D/g,"");
    //
    if(num.toString().length > 5 && num.toString().length < 7){
      num = num.replace(/(\d{1}?)((\d{1})+)$/, "$1.$2");
      num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    }else if (num.toString().length >= 7) {
      num=num.replace(/(\d{1,2})$/, ',$1');  
      num=num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');  
      num=num.replace(/^0+/, '');

    }else if (num.toString().length == 4){
        num = num.replace(/(\d{2})(?=\d)/g, "$1.");
    }else{
        num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    }
    return num;
  }

  handleSubmit = async e => {
    e.preventDefault();

    const { intl } = this.props;

    if (this.state.OrgList.length == 0) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "organization.required.message" })
      })
      return
    }
    var regex = /^[0-9]+$/;
    if(!regex.test(this.state.item.garanty)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: this.props.intl.formatMessage({id:"organization.material.edit.warranty.field.invalid"})
      });
      return false
    }

    this.setState({ loading: true });

    let data = new FormData();

      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }

      let idsStringOrg = "";
    if (this.state.OrgList.length > 0) {
      this.state.OrgList.forEach(
        (organization) => (idsStringOrg += organization.id + ",")
      );
    }
    
    var newStr = idsStringOrg.slice(0, -1);
    data.append("Organizations", newStr);

      for (let key in this.state.item) {

        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1 || key.toString().toLowerCase().indexOf('invoicedate') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('material') > -1) {
          let list = this.state.item[key];
          
          if(list != null && list != ""){
            let result = "";

            for (let value in this.state.item[key]) {
              result = this.state.item[key][value].id+","+result
            }
            
            data.append("MaterialLot", result);
            data.append("name", this.props.intl.formatMessage({id:"organization.material.registration.material.lot"}));
          }

        } else if (key.toString().toLowerCase().indexOf('organizationid') > -1) {
          data.append("OrganizationId", 0);
        } else {
          data.append(key, this.state.item[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      
        Api.post('/organizationmaterials', data, config)

        .then(result => {
          if (result.data.success) {
            this.setState({
              OrgList: [],
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: this.props.intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });
        this.getOrganizations();
        this.getMaterials();


          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));        if(stateName === 'OrganizationId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OrganizationText']: text,
            }
          }));
        }
        if(stateName === 'MaterialId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['MaterialText']: text,
            }
          }));
        }
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  orgDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.OrgListForDelete && this.state.OrgListForDelete.length > 0) {

      this.setState({ loading: true })

      var orgList = [];
      var orgDeleteList = this.state.OrgListForDelete.split(",");

      orgDeleteList.map(organizationDelete => {
        if(organizationDelete != ""){
          let returnok = this.state.OrgList.filter( el => el.id != organizationDelete)
          returnok.map(item => {
            if(orgList.filter(el => el.id != item.id )){
              orgList.push(item);
            }
          })
        }
      })
      
      const intl = this.props.intl;
      this.setState({
        loading: false,
        OrgList: orgList,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
      })

    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: this.props.intl.formatMessage({id:"organization.material.registration.select.organization"})
      })
    }
  }

  openOrgInsertModal = async () => {
    this.setState({
      openOrgInsertModal: !this.state.openOrgInsertModal
    })
  }

  saveOrgInsertModal = async () => {

    const intl = this.props.intl;
    this.setState({
      loading: false,
      OrgList: this.state.item.OrganizationId,
      openNotification: true,
      notificationVariant: 'success',
      notificationMessage: intl.formatMessage({ id: "add.success" }),
      openOrgInsertModal: !this.state.openOrgInsertModal,
    })

  }

  setOrgDelete = async (org) => {
    this.setState({
      OrgListForDelete: org + "," + this.state.OrgListForDelete
    })
  }

  closeOrgModal = () => this.setState({ openOrgInsertModal: !this.state.openOrgInsertModal })

    render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    console.log(this.state)

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'   onClick={() => {
                      this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 10 }) : browserHistory.goBack()}
                    }>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />} 
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header" >
                    <Typography className={classes.heading}>{this.props.intl.formatMessage({id:"menu.organizations"})}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container xs={12} sm={12} >
                      <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                        <Grid xs={12} sm={4} ></Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <DialogConfirmation
                            msgButton={this.props.intl.formatMessage({id:"organization.material.registration.remove.organization"})}
                            msg={this.props.intl.formatMessage({id:"organization.material.registration.confirm.remove"})}
                            msgDisagree={this.props.intl.formatMessage({id:"cancel"})}
                            msgAgree={this.props.intl.formatMessage({id:"yes"})}
                            handleConfirmation={e => this.orgDeleteModal()}
                            classes={classes}
                            icon={<DeleteIcon />} />
                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.button}
                            startIcon={<AddIcon />}
                            onClick={e => this.openOrgInsertModal()} >
                            {this.props.intl.formatMessage({id:"organization.material.registration.add.organization"})}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid xs={12} sm={12}
                        style={{
                          marginLeft: 5,
                          marginBottom: 5,
                          border: '3px solid #eee'
                        }} >
                        <div style={{ height: 350 }}>
                          <DataGrid
                            style={{ fontSize: 14, color: '#000' }}
                            className="DataGridStyle"
                            components={{
                              NoRowsOverlay: this.NoDataRows,
                              Toolbar: GridToolbarFilterButton,
                            }}
                            rows={this.state.OrgList}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            onSelectionModelChange={itm => {
                              if (itm.length > 0) {
                                this.setOrgDelete(itm);
                              } else {
                                this.setState({
                                  OrgListForDelete: [],
                                })
                              }
                            }}
                            columns={[
                              { headerName: this.props.intl.formatMessage({id:"menu.organization"}), field: 'name', width: 500 },
                            ]}
                            HeadersVisibility="None" />
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.material.inform.material"})}  name='template' stateName='template' 
                      changeSelect={(event, newValue) => {
                        this.setState({
                          disableValueInformation : newValue,
                          template: newValue
                        });
                      }}/>
                    </Grid>
                  {this.state.template && (
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"menu.organizationmaterials"})} options={this.state.MaterialList} stateName='MaterialId' changeSelect={this.changeValues} selected={item.MaterialId} />
                    </Grid>
                  )}
                  {!this.state.template && (
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})}  
                      inputProps={{ maxLength: 250 }} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                    </Grid>
                  )}
                    <Grid item xs={3} sm={3}>
                          <TextField
                          label={this.props.intl.formatMessage({id:"value"})}
                          variant='outlined'
                          value={item.value}
                          onChange={this.handleInputChange}
                          name="value"
                          //disabled = {this.state.disableValueInformation}
                          id="formatted-numberformat-input"
                          InputProps={{
                            inputComponent: this.NumberFormatCustom,
                          }}
                          autoComplete='fname'
                          required
                          />  
                    </Grid>
                  
                {!this.state.template && (
                      <Grid item xs={3} sm={3}>
                        <SimpleDatePicker label={this.props.intl.formatMessage({id:"material.registration.note.date"})} name='invoicedate' stateName='invoicedate' changeDate={this.changeValues} currentDate={item.invoicedate}  required/>
                      </Grid>)}
                {!this.state.template && (
                    <Grid item xs={3} sm={3}></Grid>
                )}
                {!this.state.template && (
                    <Grid item xs={12} sm={12}>
                      <TextField id='filled-number' label={intl.formatMessage({id:"garanty"})} type='number' variant='filled' InputLabelProps={{shrink: true}} name='garanty' onChange={this.handleInputChange} value={item.garanty} required />
                    </Grid>
                )}

                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button 
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            <Dialog
              fullWidth
              maxWidth="md"
              open={this.state.openOrgInsertModal}
              onClose={this.closeOrgModal}
              aria-labelledby="form-dialog-title-org-mat"
              PaperProps={{
                style: {
                  overflow: 'hidden'
                }
              }} >
              <DialogTitle id="form-dialog-title-org-mat">
                {this.props.intl.formatMessage({id:"organization.material.registration.new.organizations"})}
                <ClearIcon onClick={this.openOrgInsertModal} style={{ float: 'right', cursor: 'pointer' }} />
              </DialogTitle>
              <Grid item xs={12} sm={12} >
                <Grid container spacing={4} style={{ padding:"5px" }}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label={intl.formatMessage({ id: "organization" })}
                      options={this.state.OrganizationList}
                      stateName="OrganizationId"
                      changeSelect={this.changeValues}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid spacing={2} container justify="flex-end" style={{ padding: 15 }}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant='contained'
                    type="button"
                    onClick={this.openOrgInsertModal}
                    fullWidth >
                    {this.props.intl.formatMessage({id:"cancel"})}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={this.saveOrgInsertModal}
                  >
                    {<FormattedMessage id="save" />}
                  </Button>
                </Grid>
              </Grid>
            </Dialog>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}
OrganizationMaterialRegistration.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    organizations: state.organizations
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationMaterialRegistration)));
