import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteInt from '../../components/auto-complete/autocomplete-int';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import * as Service from "../../services/organizationpatrimony.service";
import { WithContext as ReactTags } from 'react-tag-input';
import '../knowledgebase-registration/style.css';
import ImageList from '@mui/material/ImageList';
import { AttachFile } from '@mui/icons-material';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import { PapiroConsole } from '../../utils/papiroConsole';
import * as FileService from '../../services/attachment.service';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class KnowledgeBaseEdit extends Component {

  constructor(props) {
    super(props);

    const { userSession, knowledgebases } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }

    if(!knowledgebases || (knowledgebases && !knowledgebases.knowledgebase)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );
 
    var patrimony = []
    if(knowledgebases.knowledgebase.knowledgebasepatrimonies != null){
      knowledgebases.knowledgebase.knowledgebasepatrimonies.map(item => {
        patrimony.push(item.patrimony)
      });  
    }

    this.initialState = {
      id: knowledgebases.knowledgebase.id,
      UpdatedUser: userSession.user.id,
      updatedAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      CalledId:  knowledgebases.knowledgebase.CalledId ? knowledgebases.knowledgebase.CalledId : 0,
      accompanimentid:  knowledgebases.knowledgebase.accompanimentid ? knowledgebases.knowledgebase.accompanimentid : 0,
      description : knowledgebases.knowledgebase.description == null ? '' : knowledgebases.knowledgebase.description, 
      PatrimonySubgroupId: knowledgebases.knowledgebase.knowledgebasepatrimonies.length > 0 ? knowledgebases.knowledgebase.knowledgebasepatrimonies[0].patrimony.patrimonysubgroupid : 0,
      PatrimonySubgroupText: "",
      PatrimonyGroupId: knowledgebases.knowledgebase.knowledgebasepatrimonies.length > 0 ? knowledgebases.knowledgebase.knowledgebasepatrimonies[0].patrimony.patrimonygroupid : 0,
      PatrimonyGroupText: "",
      PatrimonyMaterialId: [],
      keeppatrimony: true,
      PatrimonyMaterialText: "",
      title: knowledgebases.knowledgebase.title,
      knowledgebaseattachments: knowledgebases.knowledgebase.knowledgebaseattachments ? knowledgebases.knowledgebase.knowledgebaseattachments : [],
    };    
    
    var knowledgebasekeywords = []
    knowledgebases.knowledgebase.knowledgebaseknowledgebasekeywords.map(item => {
      var obj = {
        id: item.knowledgebasekeyword.word,
        text: item.knowledgebasekeyword.word
      }
      knowledgebasekeywords.push(obj)
    });

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      CalledList: [],
      PatrimonySubgroupList: [],
      PatrimonyGroupList: [],
      PatrimonyMaterialList: [],
      KeywordsList: [],
      files: [],
      tags: knowledgebasekeywords,
      patrimonySelect: patrimony
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.useStyles = this.useStyles.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }

  componentDidMount() {
    this.getPatrimonyGroups();
    this.getPatrimonySubgroups(this.state.item.PatrimonyGroupId);
  };

  handleDelete(i) {
    let abl = this.context.can('Edit', 'KnowledgeBase');
    
    if (abl != false)
    {
      const { tags } = this.state;
      this.setState({
        tags: tags.filter((tag, index) => index !== i),
      });
    }
  }

  useStyles() {
    return withStyles({
        ImageList: {
            width: "100%",
            height: 100,
        },
    });
  }

  handleAddition(tag) {
    let abl = this.context.can('Edit', 'KnowledgeBase');
    if (abl != false)
    {
      this.setState(state => ({ tags: [...state.tags, tag] }));
    }
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleTagClick(index) {
    // console.log('The tag at index ' + index + ' was clicked');
  }

    async getPatrimonySubgroups(patrimonyGroupId) {
      this.setState({ loading: true });
      var result = await Service.getPatrimonySubgroups(patrimonyGroupId);
      if (result.success) {
        result.data.forEach((item) => {
          item.parentname = item.name
          if(this.state.item.PatrimonySubgroupId === item.id){
            let patrimonyMaterial = this.state.item
            patrimonyMaterial.PatrimonySubgroupId = item
            this.setState({ item: patrimonyMaterial});
            this.getPatrimonyMaterials(item.id);
          }
        });
        this.setState({ loading: false, PatrimonySubgroupList: result.data });
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
              ? result.data.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
        Api.kickoff(result);
      }
    }

    async getPatrimonyGroups() {
      this.setState({ loading: true });
      var result = await Service.getPatrimonyGroups();
      if (result.success) {
        result.data.forEach((item) => {
          item.parentname = item.name;
          if(item.id === this.state.item.PatrimonyGroupId){
            let group = this.state.item
            group.PatrimonyGroupId = item
            this.setState({ item: group});
          }
        });
        this.setState({ loading: false, PatrimonyGroupList: result.data });
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
              ? result.data.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
        Api.kickoff(result);
      }
    }
    async getPatrimonyMaterials(subgroupid) {
      this.setState({ loading: true });
      var result = await Service.getPatrimonyMaterials(subgroupid);
      if (result.success) {
        this.setState({ loading: false, PatrimonyMaterialList: result.data });
        
        let organizations = this.state.patrimonySelect;
        if (organizations.length > 0) {
          for (var i = 0; i < organizations.length; i++) {
            for (var j = 0; j < result.data.length; j++) {
              if (organizations[i].id === result.data[j].id) {
                organizations[i].name = result.data[j].name;
              }
            }
          }
          this.setState({
            loading: false,
            item: {
              ...this.state.item,
              PatrimonyMaterialId: organizations,
            },
          });
        }
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
              ? result.data.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
        Api.kickoff(result);
      }
    }
  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  async getFile(attachment,fileName,filerepositorymethod) {
    /*
	let fName = fileName
    if(fName.startsWith('/wwwroot/', 0))
      fName = fName.substring(9)
    
    let apiUrl = Api.apiurlbase()
    if (apiUrl.endsWith("/"))
      apiUrl = apiUrl.slice(0, -1)

    window.open(`${apiUrl}/${fName.replace("///", "/").replace("//", "/").replace("/files", "files")}`, '_blank');
	*/

	const intl = this.props.intl;
	this.setState({ loading: true });
	
	
	let url = await FileService.getFile(attachment.hirerid,attachment.id,fileName,filerepositorymethod); 
	//alert(JSON.stringify(url.data));
	this.setState({ loading: false });
	if(url &&  url.data && url.data.data && url.data.success){
		window.open(`${url.data.data}`, '_blank');
	}
	else{
		this.setState({
			loading: false, openNotification: true, notificationVariant: "error",
			notificationMessage: intl.formatMessage({ id: "file.not.found" })
		});

	}	
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();

      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }

      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('description') > -1) {
          data.append(key, this.state.item[key] == null || typeof this.state.item[key] === 'undefined' ? "" : this.state.item[key]);
        } else {
          data.append(key, this.state.item[key]);
        }
      }

      var word = ""
      for (let key in this.state.tags) {
        word = this.state.tags[key].text+","+word
      }

      var patrimony = ""
      for (let key in this.state.item.PatrimonyMaterialId) {
        patrimony = this.state.item.PatrimonyMaterialId[key].id+","+patrimony
      }

      data.append("words", word);
      data.append("patrimonyid", patrimony);

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      Api.put('/knowledgebases', data, config)

        .then(result => {
          if (result.data.success) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: this.props.intl.formatMessage({id:"edit.success"}),
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: this.props.intl.formatMessage({id:"process.error"})
            });
          }
          Api.kickoff(err);
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));

    if (stateName === "PatrimonySubgroupId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonySubgroupText"]: text,
          ["PatrimonyMaterialId"]: "",
        },
      }));

      if (value.id != 0) {
        this.getPatrimonyMaterials(value.id);
      }
    }

    if (stateName === "PatrimonyGroupId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonyGroupText"]: text,
          ["PatrimonySubgroupId"]: "",
          ["PatrimonyMaterialId"]: "",
        },
      }));
      if (value.id != 0) {
        this.getPatrimonySubgroups(value.id);
      }
    }
    if (stateName === "PatrimonyMaterialId") {
      
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonyMaterialText"]: text,
        },
      }));
    }
 
    this.setState({ loading: false });

  };

  handleChangeEditor = (value) => {
		this.setState(prevState => ({
			item: {
			  ...prevState.item,
			  description: value
			}
		  }));
	}

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, intl } = this.props;
    const { item } = this.state;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    const abl = this.context.can('Edit', 'KnowledgeBase');

    const classes2 = this.useStyles();

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'   onClick={browserHistory.goBack}>{this.props.intl.formatMessage({id:"back"})}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              
              <Typography component='h1' variant='h5'>
                  Editar um Item
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <TextField 
                        fullWidth
                        label={intl.formatMessage({id:'title'})} 
                        autoComplete='fname' 
                        variant='outlined' 
                        name='title' 
                        onChange={this.handleInputChange} 
                        disabled={abl == false ? true : false}
                        value={item.title} />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginBottom: 50 }}>
                      <Typography>
                        <FormattedMessage id="description" />
                      </Typography>
                      <ReactQuill name="description" value={item.description} style={{ height: 200 }}
                        fullWidth
                        readOnly={abl == false ? true : false}
                        required
                        InputLabelProps={{ shrink: item.description && item.description.length > 0 ? true : false }}
                        variant="outlined"
                        id="outlined-multiline-static"
                        onChange={this.handleChangeEditor} />
                    </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteInt
                      label={intl.formatMessage({ id: "menu.patrimonygroups" })}
                      options={this.state.PatrimonyGroupList}
                      stateName="PatrimonyGroupId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonyGroupId}
                      disabled={abl == false ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteInt
                      label={intl.formatMessage({
                        id: "menu.patrimonysubgroups",
                      })}
                      options={this.state.PatrimonySubgroupList}
                      stateName="PatrimonySubgroupId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonySubgroupId}
                      disabled={abl == false ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label={intl.formatMessage({
                        id: "menu.patrimonymaterials",
                      })}
                      options={this.state.PatrimonyMaterialList}
                      stateName="PatrimonyMaterialId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonyMaterialId}
                      disabled={abl == false ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ReactTags
                      placeholder={intl.formatMessage({ id: "keywords.press.enter" })}
                      tags={this.state.tags}
                      delimiters={delimiters}
                      handleDelete={this.handleDelete}
                      handleAddition={this.handleAddition}
                    />
                  </Grid>
                </Grid>
                <Can I='Edit' a='KnowledgeBase'>
                  <Grid item xs={12} sm={12}>
                    <section style={{ width: '100%' }}>
                      <div className={classes.dropzoneArea}>
                        <Dropzone onDrop={acceptedFiles => { this.setState({ files: acceptedFiles }) }}>
                          {({ getRootProps, getInputProps, acceptedFiles }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>{<FormattedMessage id="drag.and.drop.files" />}</p>
                            </div>
                          )}
                        </Dropzone>
                        {this.state.files.map(file => {
                          return (
                            <div className={classes.dropzoneItem} key={file.name}>
                              {file.name}
                              <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                            </div>
                          )
                        })}
                      </div>
                    </section>
                  </Grid>
                </Can>
                
                {item.knowledgebaseattachments.length > 0  && (
                  <Grid item xs={12} style={{ marginTop: 15 }}>
                          <div className={classes.attachmentsBox}>
                              <label>{<FormattedMessage id="attachments" />}</label>
                              <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                  <ul>
                                      {item.knowledgebaseattachments.map(file => {
                                          return (<li key={file.attachment.path}>
                                              <AttachFile /> <Button onClick={() => this.getFile(file.attachment, file.attachment.path, file.attachment.filerepositorymethod)}>{file.attachment.description}</Button>
                                          </li>)
                                      })}
                                  </ul>
                              </ImageList>
                          </div>
                  </Grid>
                )}
                <Can I='Edit' a='KnowledgeBase'>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
                          <Button
                              type='submit'
                              fullWidth
                              variant='contained'
                              color='primary'
                              className={classes.submit}
                          >
                              {this.props.intl.formatMessage({id:"save"})}
                          </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Can>
                
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}

KnowledgeBaseEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  knowledgebases: state.knowledgebases
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(KnowledgeBaseEdit)));
KnowledgeBaseEdit.contextType = AbilityContext;
