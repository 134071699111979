import React, { PureComponent } from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import { Card, CardContent, Grid, CardHeader } from '@mui/material'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { injectIntl, FormattedMessage } from 'react-intl'

const COLORS = ['#E74C3C', '#FFBB28', '#00C49F']

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

class PizzaNps extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/c9pL8k61/'

  totalIndex() {
    return this.props.data[0].value + this.props.data[1].value + this.props.data[2].value
  }

  getPercent(indexValue) {
    let total = this.totalIndex()

    if (indexValue <= 0) {
      return 0
    } else {
      return Math.round((indexValue * 100) / total)
    }
  }

  calculate() {
    if (this.props.data[0].value + this.props.data[1].value + this.props.data[2].value === 0) {
      return 0
    }
    if (this.props.data[2].value - this.props.data[0].value == 0) {
      return 0
    }
    let valor = (this.props.data[2].value - this.props.data[0].value) / (this.props.data[0].value + this.props.data[1].value + this.props.data[2].value)

    valor = valor * 100
    return Math.round(valor)
  }

  render() {
    let colorBackNPS = 'white'

    if (this.calculate() < 0 || this.calculate() === 0) {
      colorBackNPS = '#E74C3C '
      colorBackNPS = '#E74C3C'
    }

    else if(this.calculate() > 0 && this.calculate() <= 50 ){
      colorBackNPS = '#FFBB28'
    }

    else if(this.calculate() > 50 && this.calculate() <= 75 ){
      colorBackNPS = '#ece637'
    }

    else if(this.calculate() > 75 ){
      colorBackNPS = '#00C49F'
    }

    return (

      <Card style={{ minHeight: '100%' }}>
        <CardHeader
          title="NPS"
        />
        <Divider />
        <CardContent
         style={{ height: 530, display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }} >
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={8} style={{ float: 'left' }}>
                <PieChart width={300} height={250}>
                  <Pie
                    data={this.props.data}
                    cx={150}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {
                      this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index]} />)
                    }
                  </Pie>
                </PieChart>
              </Grid>

              <Grid item xs={4} style={{ float: 'right' }}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" style={{ backgroundColor: 'DarkGray', color: 'white', fontSize: '15px' }}><strong><FormattedMessage id="quality.dashboard.nps.score" /></strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" style={{ fontSize: '15px', backgroundColor: colorBackNPS, color: 'white'  }}><strong>{this.calculate()}</strong></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={6} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} >
              <span><FormattedMessage id="quality.dashboard.nps.result" /></span>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table" >
                  <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#E74C3C' }}>
                        <SentimentVeryDissatisfiedIcon style={{ color: 'white', textAlign: 'center' }} />
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}><FormattedMessage id="quality.dashboard.nps.detractor" /></TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[0].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[0].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#FFBB28' }}>
                        <SentimentSatisfiedIcon style={{ color: 'white', textAlign: 'center' }} />
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}><FormattedMessage id="quality.dashboard.nps.neutral" /></TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[1].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[1].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#00C49F' }}>
                        <SentimentSatisfiedAltIcon style={{ color: 'white', textAlign: 'center' }} />
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}><FormattedMessage id="quality.dashboard.nps.promoter" /></TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[2].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[2].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" align="center" style={{ backgroundColor: 'darkgrey', color: 'white' }} colSpan={3}>
                        <strong><FormattedMessage id="quality.dashboard.nps.total" /></strong>
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.totalIndex()}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item sm={1} md={1}>

            </Grid>

            <Grid item sm={5} md={5} >
              <span><FormattedMessage id="quality.dashboard.nps.subtitle" /></span>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ backgroundColor: 'DarkGray', color: 'white' }}><strong><FormattedMessage id="quality.dashboard.nps.zone" /></strong></TableCell>
                      <TableCell align="right" style={{ backgroundColor: 'DarkGray', color: 'white' }}><strong><FormattedMessage id="quality.dashboard.nps.range" /></strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#E74C3C', color: 'white' }} align="left">
                        <FormattedMessage id="quality.dashboard.nps.critique" />
                      </TableCell>
                      <TableCell align="right">-100 <FormattedMessage id="to" /> 0</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#FFBB28', color: 'white' }} align="left">
                        <FormattedMessage id="quality.dashboard.nps.improvement" />
                      </TableCell>
                      <TableCell align="right">1 <FormattedMessage id="to" /> 50</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#ece637', color: 'white' }} align="left">
                        <FormattedMessage id="quality.dashboard.nps.quality" />
                      </TableCell>
                      <TableCell align="right">51 <FormattedMessage id="to" /> 75</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#00C49F', color: 'white' }} align="left">
                        <FormattedMessage id="quality.dashboard.nps.excellence" />
                      </TableCell>
                      <TableCell align="right">76 <FormattedMessage id="to" /> 100</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

          </Grid>



        </div>
        </CardContent>
      </Card>


    )
  }
}

export default injectIntl(PizzaNps)