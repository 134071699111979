import React, { Component } from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import MaterialTable from 'material-table';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import MyMaterialTable from '../../components/table';
import Api from "../../services/api";
import * as calledsActions from '../../redux/actions/calleds';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer';
import moment from "moment";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { getSlaAtendimento, getSlaSolucao } from './called.functions';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';


class Calleds extends Component {

    constructor(props) {
        super(props);
        this.Reload = this.Reload.bind(this);
        this.Reload();

        this.typingTimeout = null;

        const { userSession } = this.props;
        // console.log(this.props);
        const intl = this.props.intl;


        let filters = [
            { name: "ID", id: "id" },
            { name: intl.formatMessage({ id: "title" }), id: "title" },
            { name: intl.formatMessage({ id: "organization" }), id: "org" },
            { name: intl.formatMessage({ id: "category" }), id: "category" },
            { name: intl.formatMessage({ id: "type" }), id: "type" },
            { name: intl.formatMessage({ id: "origin.request" }), id: "origin" },
            { name: intl.formatMessage({id:"menu.statuses"}), id: "status" },
            { name: intl.formatMessage({ id: "requestuser" }), id: "requestuser" },
            { name: intl.formatMessage({ id: "assigned" }), id: "assigneduser" },

        ];

        this.isClient = true;
        this.isAdm = false;

        if (userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0 || item.role.roletype == 1 || item.role.roletype == 4) {
                this.isAdm = true;
            }
        }
        );
        this.organizationId = null;
        /*
        if(userSession.user.organization){
            this.organizationId = userSession.user.organization.id;
        }*/
        if (userSession.user.organizationusers) {
            this.organizationId = userSession.user.organizationlistid;
        }

        this.state = {
            organizationId: this.organizationId,
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            calledsList: [],

            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            filterSelected: 0,
            filters: filters
        };
        this.changeValues = this.changeValues.bind(this);
        this.closeNotification = this.closeNotification.bind(this);

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }


    Reload = () => {
        const reload = localStorage.getItem('reload');
        if (reload == "true") {
            localStorage.setItem('reload', false);


        }
        return null;
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getCalleds('', page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;



                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {


        this.setState({ [event.target.name]: event.target.value, page: 1 });

    }

    callSearch() {
        if (this.state.filterSelected != 0) {
            this.getCalleds('', this.state.page);
        }
    }

    async clearFields() {
        await this.setState({ filterSelected: 0, searchValue: '', page: 1 })
        this.getCalleds('', 1);
    }

    async changeValues(stateName, value, text = '') {

        this.setState({ filterSelected: value });
    }


    deleteItem(data, resolve) {
        const intl = this.props.intl;
        //console.log("DEL");
        let abl = this.context.can('Delete', 'Called');
        //console.log(abl);
        if (abl == false) {
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"}) });
            resolve(); return false;
        }
        Api.delete(`/calleds/${data.id}`)

            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"called.list.notification.delete.success"}),
                        resultList: []
                    });

                    this.getCalleds('', 1);
                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);
            });


    };



    componentDidMount() {
        //        console.log(this.props);
        this.getCalleds(this.props.location.state, 1);
    }
    componentWillReceiveProps(nextProps) {
        //console.log(nextProps);

        //if (nextProps.location.search.indexOf("assignedcalleds") > -1) {
        if (nextProps.location.state === "calleds") {
            // do stuffs
            this.getCalleds('', 1);
        }
        else if (nextProps.location.state === "assignedcalleds") {
            this.getCalleds(nextProps.location.state, 1);
        }
        else if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    getCalleds(filter = '', page = 1) {
        this.setState({ loading: true });
        let url = `/calleds/assigned/paginate?page=${page}`;


        if (this.isClient) {
            url = `/calleds/org/paginate/${this.state.organizationId}?page=${page}`;
        }

        if (this.state.searchValue != '' && this.state.filterSelected != 0) {

            url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`

        }

        if (filter != '') {
            url = url + '&filter=' + filter;

        }

        Api.get(url)
            .then(result => {
                if (result.data.success) {

                    let count = result.data.count;
                    this.setState({ count: count });
                    this.setPagination(count, page);

                    const formattedList = result.data.data.map(item => {
                        // console.log(item);
                        return {
                            ...item,
                            typeName: item.type.name,
                            originRequestName: item.originrequest.name,
                            statusName: item.status.name,
                            OrganizationCategory: item.organizationcategory.parentname,
                            Organization: item.organization.name,
                            slaAtendimento: getSlaAtendimento(item, this.props.userSession) + "%",
                            slaSolucao: getSlaSolucao(item, this.props.userSession) + "%",
                            assignedUser: item.assigneduser ? item.assigneduser.name : "",
                            requestingUser: item.requestinguser ? item.requestinguser.name : ""
                        }
                    });
                    //console.log(formattedList);
                    this.setState({ loading: false, calledsList: formattedList });
                }
                else {
                    // console.log("Aqui 3");
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    // console.log(result);              
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                // console.log("Aqui 4");
                // console.log(err);
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }

    render() {
        const { classes, userSession, headerMenu } = this.props;
        var link = `/${this.props.match.params.lang}/called-registration`;
        if (this.isClient) {
            link = `/${this.props.match.params.lang}/called-registration-client`;
        }
        const intl = this.props.intl;
        const tableList = () => {
            if (this.isAdm == true) {
                return (

                    <MyMaterialTable
                        title=""
                        columns={[{ title: intl.formatMessage({id:"id"}), field: 'id' }, { title: intl.formatMessage({ id: "organization" }), field: 'Organization' }, { title: intl.formatMessage({ id: "title" }), field: 'title' }, { title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' }, { title: intl.formatMessage({ id: "type" }), field: 'typeName' }, { title: intl.formatMessage({ id: "origin.request" }), field: 'originRequestName' }, { title: intl.formatMessage({id:"menu.statuses"}), field: 'statusName' }, { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' }, { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' }
                            , { title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' }, { title: intl.formatMessage({ id: "assigned" }), field: 'assignedUser' }]}
                        data={this.state.calledsList}
                        deleteItem={null}
                        rowClick={(event, rowData) => {
                            let abl = this.context.can('Answer', 'Called');
                            let abl2 = this.context.can('Edit', 'Called');
                            if (abl == false && abl2 == false) {
                                this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"}) });
                                return false;
                            }
                            if (rowData.assigneduser != null) {
                                if (rowData.assigneduser.id != userSession.user.id) {
                                    if (rowData.organization.organizationsettings.canviewcalledfromothertechnical == false && this.isAdm == false) {
                                        this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: intl.formatMessage({id:"caled.list.message.unallowed.user"}) });
                                        return false;
                                    }
                                } else {
                                    rowData.assigneduser = rowData.assigneduser.name + "(" + rowData.assigneduser.email + ")";
                                }
                            }
                            //console.log(rowData);
                            this.props.addCurrentCalled(rowData);
                            this.props.addAccompanimentsList(rowData.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)));
                            
                            this.props.addCalled(rowData)
                            this.props.addAccompanimentsListInCalled(rowData.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)), rowData.id)
                            this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${rowData.id}`);
                        }}
                    />
                );
            } else {
                return (
                    <MyMaterialTable
                        title=""
                        columns={[{ title: intl.formatMessage({id:"id"}), field: 'id' }, { title: 'Título', field: 'title' }, { title: intl.formatMessage({id:"category"}), field: 'OrganizationCategory' }, { title: intl.formatMessage({id:"menu.statuses"}), field: 'statusName' }, { title: intl.formatMessage({id:"sla.service"}), field: 'slaAtendimento' }, { title: intl.formatMessage({id:"sla.solution"}), field: 'slaSolucao' }
                            , { title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' }, { title: intl.formatMessage({id:"assigned"}), field: 'assignedUser' }]}
                        data={this.state.calledsList}
                        deleteItem={null}
                        rowClick={(event, rowData) => {
                            let abl = this.context.can('Answer', 'Called');
                            let abl2 = this.context.can('Edit', 'Called');
                            if (abl == false && abl2 == false) {
                                this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"}) });
                                return false;
                            }

                            if (rowData.assigneduser != null) {
                                if (rowData.assigneduser.id != userSession.user.id) {
                                    if (rowData.organization.organizationsettings.canviewcalledfromothertechnical == false && this.isClient == false) {
                                        this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: intl.formatMessage({id:"caled.list.message.unallowed.user"}) });
                                        return false;
                                    }
                                }
                            }
                            //console.log(rowData);
                            this.props.addCurrentCalled(rowData);
                            this.props.addAccompanimentsList(rowData.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)));

                            this.props.addCalled(rowData)
                            this.props.addAccompanimentsListInCalled(rowData.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)), rowData.id)
                            this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${rowData.id}`);
                        }}
                    />
                );
            }
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{ paddingLeft: 0, marginLeft: 0 }}>
                        <CssBaseline />
                        <Grid container className={classes.mb20} spacing={0}>
                            <Can I="Open" a="Called">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    className={classes.button}
                                    startIcon={<AddIcon />}
                                    component={Link} {...{ to: link }}
                                >
                                    {<FormattedMessage id="called.new" />}
                                </Button>
                            </Can>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>

                                <Card className="background-title-search">
                                    <CardContent>

                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h5">
                                                    {<FormattedMessage id="called.header" />}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                                <SimpleSelect label={intl.formatMessage({ id: "field" })} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected} />
                                            </Grid>
                                            <Grid item>
                                                <Search />
                                            </Grid>
                                            <Grid item >
                                                <TextField type="text"
                                                    name="searchValue"
                                                    value={this.state.searchValue}
                                                    placeholder={intl.formatMessage({ id: "search" })}
                                                    onChange={this.onFieldChange} />

                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='large'
                                                    className={classes.button}
                                                    style={{ marginRight: 4, marginLeft: 8 }}
                                                    onClick={this.callSearch}
                                                >
                                                    <Search />
                                                </Button>

                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    size='large'
                                                    onClick={this.clearFields}
                                                    className={classes.button}

                                                >
                                                    <ClearIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                            </Grid>

                        </Grid>
                        <Can I="List" a="Called">
                            {tableList()}
                        </Can>

                        <Can I='List' a='Called'>

                            <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                <Grid item xs={12} md={3}>
                                    <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                </Grid>
                            </Grid>

                        </Can>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}

Calleds.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Calleds)));
Calleds.contextType = AbilityContext;