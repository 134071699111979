import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import { QueuePlayNext, DeleteForever, Label } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import Dropzone from "react-dropzone";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleSelect from "../../components/select/simple";
import MyTransferList from "../../components/transfer-list/transferlist";
import MyCheckList from "../../components/checklist/checklist";
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";

import SimpleAutoComplete from "../../components/auto-complete/autocomplete-group";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import { FormattedMessage, injectIntl } from "react-intl";
import Title from "../../components/title-name";
import SimpleCheckBox from "../../components/checkbox/check";
import Autocomplete from "@mui/material/Autocomplete";

import { saveAs } from "file-saver";
import { Input } from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { getOrg } from "../../services/organization.service";
import * as Service from "../../services/user.service";
import { DataGrid, GridToolbarFilterButton  } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogConfirmation from '../../components/dialog-confirmation'
import NumberFormat from "react-number-format"
import SimpleHelp from "../../components/help"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

const NoDataRows = _ => {
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
  )
}

class UserRegistration extends Component {
  constructor(props) {
    super(props);
    
    const { userSession } = this.props;
    const intl = this.props.intl;
    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    this.startPageItems = [
      { id: 'calleds', name: intl.formatMessage({id:"called.header"}) },
      { id: 'dashboard', name: intl.formatMessage({id:"home"}) }
    ]

    this.initialState = {
      startPage: 'calleds',
      createdUser: userSession.user.id,
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      Organizations: 0,
      Location: 0,
      Groups: 0,
      OrganizationText: "",
      GroupText: "",
      LocationText:"",
      name: "",
      email: "",
      document: "",
      active: false,
      canCreateCalledInMobile: false,
      canInteractWithCalledWhereAreNotRequesterOrAssigned: false,
      phone: "",
      employeehourvalue: 0,
      valueforcalled: 0,
      kmvaluerotated: 0,
      UserRoles: [],
      LocationId: [],
      WorkHourId: 0,
      WorkHourText: "",
      OrganizationDepartamentId: 0,
      perhour: false,
      calledunit: false
    };
    this.state = {
      startPageItems: this.startPageItems,
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      GroupList: [],
      RoleList: [],
      WorkHoursList: [],
      DepartamentList: [],
      departamentName: "",
      file: null,

      OrgListForDelete: "",
      OrgListForSelect: [],
      OrgList: [],
      OrgListSelect: [],
      OrgListIds: "",

      LocationListForDelete: "",
      LocationListForSelect: [],
      LocationList: [],
      LocationListSelect: [],

      openOrgInsertModal: false,
      openLocationInsertModal: false,

      openDialogNewAgents: false,
      valueNewAgents: 0.00,
      newAgentsAccept: false,

      selectOrganizations: false,
      selectLocations: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this)
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.importModelFile = this.importModelFile.bind(this);
    this.emailExists = this.emailExists.bind(this);
    this.closeLocationModal = this.closeLocationModal.bind(this)
    this.closeOrgModal = this.closeOrgModal.bind(this)
  }

  closeLocationModal = () => this.setState({ openLocationInsertModal: false })

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    this.getOrganizations();
    this.getRoles();
    this.getWorkHours();
    this.getAdresses();
  }

  async getAllGrous() {
    this.setState({ loading: true });
    var groups = await Service.getGroups();
    this.setState({ loading: false, GroupList: groups });
  }

  async getAdressesById(){

    let id = 4001
    Api.get(`/addresses/organizations/${id}`).then(result => {
      if (result.data.success) {
        
        const intl = this.props.intl;
        this.setState({
          loading: false,
        });
      }
      else{
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

        });
        Api.kickoff(result);

      }          
    })
    .catch(err => {
      if(err.response && err.response.data && err.response.data.errors){
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
      }
      else{
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: intl.formatMessage({id:"process.error"})
        });
      }
    });
  }

  getWorkHoursByOrgIds = async (ids) => {
    this.setState({ loading: true });

    let data = new FormData();
    data.append('organizationids', ids)

    var result = await Service.getWorkHoursListByOrgs(data);
    if (result.success) {
      this.setState({ loading: false, WorkHoursList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  async getAdresses(){
    
    Api.get('/addresses').then(result => {
      if (result.data.success) {
        
        const intl = this.props.intl;
     
        this.setState({
          loading: false,
        });

      }
      else{
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

        });
        Api.kickoff(result);

      }          
    })
    .catch(err => {
      if(err.response && err.response.data && err.response.data.errors){
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
      }
      else{
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: intl.formatMessage({id:"process.error"})
        });
      }
    });

    
  }

  async getAllOrganizations(ids) {
    if (ids.length > 0) {
      this.setState({ loading: true });
      let urlids = ids.map((item) => item.id);
      //this.setState({ loading: true });

      var orgs = await Service.getOrganizationsByGroup(urlids);

      
      orgs = orgs.map((org) => {
        
        return {
          ...org,
          name: `( ${org.group.name} ) ${org.name}`,
        };
      });
      
      this.setState({ loading: false, OrganizationList: orgs });
      //this.setState({ loading: false, GroupList: groups });
    }
  }

  async GetLocationsByOrganizationId(ids)
  {
    if (ids.length > 0) {
      let urlids = ids.map((item) => item.id);
      
      var locations = await Service.getLocationsByOrganizations(urlids);
	  if(locations != null &&  Array.isArray(locations)){
		locations = locations.map((location) => {
			return {
			...location,
			name: `( ${location.organization.name} ) ${location.name}`,
		}});

		this.setState({ loading: false, LocationListForSelect: locations });
      }
	  else{
		this.setState({ loading: false});
	  }
    }
  }

  async getAllLocations(ids) {

    if (ids.length > 0) {
      this.setState({ loading: true });
      let id = ids.map((item) => item.id);
    
      let locations = []
      
      
        Api.get(`/addresses/`).then(result => {

          if (result.data.success) {
            result.data.data.forEach(element => {
              id.forEach(idteste =>
              {
                
                if(element.organization.id === idteste)
                {
                  locations = [...locations, element]
                  
                }
              })
            });

            locations = locations.map((location) => {
              
                  return {
                ...location,
                name: `( ${location.organization.name} ) ${location.name}`,
              };
             });
            this.setState({ loading: false, LocationList: locations });
                
                
                  
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
        
            });
            Api.kickoff(result);
        
          }          
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
             });
          }
              
         });
        
    }
      
  }

  async getAllDepartaments(ids) {
    if (ids.length > 0) {
      this.setState({ loading: true });
      let urlids = ids.map((item) => item.id);
      //this.setState({ loading: true });

      var depts = await Service.getDepartaments(urlids);
	  if(depts != null &&  Array.isArray(depts)){
			depts = depts.map((dept) => {
			return {
			...dept,
			parentname: dept.name,
			order: dept.organization.name,
			};
		});

		this.setState({ loading: false, DepartamentList: depts });
      }
	  else{
	    this.setState({ loading: false});
	  }
    }
  }

  async getWorkHours() {
    this.setState({ loading: true });

    var result = await Service.getWorkHoursList();
    
    if (result.success) {
      this.setState({ loading: false, WorkHoursList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  onFormSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();

    //data.append(key, this.state.item[key]);
    if (this.state.file != null) {
      data.append("file", this.state.file);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      Api.post("/users/planilhas", data, config)

        .then((result) => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({ id: "add.success" }),
              resultList: [],
            });
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                result.data && result.data.errors
                  ? result.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
            Api.kickoff(result);
          }
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }) + err.response,
            });
          }
        });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "excel" }),
      });
    }
  };

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  importModelFile() {
    this.setState({ loading: true });
    var url = "/users/importfile";

    Api.get(url, {
      responseType: "blob",
    }).then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      
      saveAs(url, "modelo.xlsx");
      this.setState({ loading: false });
    });
  }

  async getDepartament(orgId) {
    this.setState({ loading: true });
    var result = await Service.getDepartamentList(orgId);
    if (result.success) {
      this.setState({ loading: false, DepartamentList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await getOrg();
    if (result.success) {
      this.setState({ loading: false, OrgListForSelect: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  async getRoles() {
    this.setState({ loading: true });
    var result = await Service.getRoles();
    if (result.success) {
      
      this.setState({ loading: false, RoleList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  handleChangePhone(e) {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        ['phone']: e
      }
    }));
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));



    if (e.target.name === "phone") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["phone"]: this.mtel(value),
        },
      }));
    }
  }

  onChangeFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  handleSubmitAccept = async (e) => {
    await this.setState({ newAgentsAccept: true });
    this.handleSubmit(e);
  }

  handleSubmit = async (e) => {
    const intl = this.props.intl;
    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();
    
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
      },
    }));

    if(this.state.item.Organizations == 0  ){

        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"user.client.registration.usergroup.missing.organizations"}),
        });
        return
    }

    if (this.state.item.startPage == null || (this.state.item.startPage && this.state.item.startPage.length === 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"user.client.edit.invalid.initial.screen.field"}),
      });
      return
    } else {
      let setStartPage = new Set()
      this.state.startPageItems.map(item => setStartPage.add(item.id))
      let setStartPageStatus = setStartPage.has(this.state.item.startPage)
      if(!setStartPageStatus) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"user.client.edit.invalid.initial.screen.field"}),
        });
        return
      }
    }

    let idsStringOrg = "";
    if (this.state.OrgList.length > 0) {
      this.state.OrgList.forEach(
        (organization) => (idsStringOrg += organization.id + ",")
      );
    }
    
    var newStr = idsStringOrg.slice(0, -1);
    data.append("Organizations", newStr);

    data.append("userroles", "client");

    let idsStringLocation = "";
    if (this.state.LocationList.length > 0) {
      this.state.LocationList.forEach(
        (organization) => (idsStringLocation += organization.id + ",")
      );
    }

    newStr = idsStringLocation.slice(0, -1);
    data.append("LocationId", newStr);

    if(this.state.newAgentsAccept){
      data.append("acceptNewAgents", true);
      this.setState({ newAgentsAccept: false });
    }

    if(this.state.item.perhour){
      if(this.state.item.employeehourvalue.length > 0) {
        data.append("costparameter", 1)
        data.append("valueforcalled", 0)
        data.append("employeehourvalue", this.state.item.employeehourvalue)
      } else{
        data.append("employeehourvalue", 0)
        data.append("valueforcalled", 0)
      }
    }else if(this.state.item.calledunit){
      if(this.state.item.valueforcalled.length > 0) {
        data.append("costparameter", 2)
        data.append("valueforcalled", this.state.item.valueforcalled)
        data.append("employeehourvalue", 0)
      } else{
        data.append("employeehourvalue", 0)
        data.append("valueforcalled", 0)
      }
    }else{
      data.append("employeehourvalue", 0)
      data.append("valueforcalled", 0)
      
    }

    data.append("kmvaluerotated", this.state.item.kmvaluerotated)

    for (let key in this.state.item) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("userroles") > -1
      ) {

        data.append(key, this.state.item[key]);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("locationid") > -1
      ) {
        data.append(key, this.state.item[key]);
      }else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("phone") > -1
      ) {
        data.append(key, this.state.item[key].replace(/\D/gim, ""));
      } else if(key
        .toString()
        .toLowerCase()
        .indexOf("Organizations") > -1 || key
        .toString()
        .toLowerCase()
        .indexOf("LocationId") > -1){

      } else if(
        key
        .toString()
        .toLowerCase()
        .indexOf("document") > -1) {
          data.append(key, typeof this.state.item[key] !== 'undefined' || this.state.user[key] !== null ? this.state.item[key] : "");
      } else if(
        key
        .toString()
        .toLowerCase()
        .indexOf("startpage") > -1) {
          if(this.state.item[key] && this.state.item[key].id)
            data.append(key, this.state.item[key].id);
          else
            data.append(key, this.state.item[key]);
      } else {
        data.append(key, this.state.item[key]);
      }
      
    }
    
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        dataType: "json",
      },
    };
    
    Api.post("/users", data, config)

      .then((result) => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "add.success" }),
            item: this.initialState,
            files: [],
            openDialogNewAgents: false,
            OrgList: [],
            LocationList: []
          });
          this.getOrganizations();
          this.getRoles();
          this.getWorkHours();

        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);  
        }
      })
      .catch((err) => {
        console.log("err")
        console.log(err)
        console.log(err.response.data.errors[0])
        
        if(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]){
          if(!isNaN(err.response.data.errors[0])){
            this.setState({
              loading: false,
              openDialogNewAgents: true,
              valueNewAgents: err.response.data.errors[0],
            });

          }else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
          }
          
        }else if (err.response && err.response.data && err.response.data.errors) {
          
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
         
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }) + err.response,
          });
        }
      });


  };

  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = "") {
    this.setState({ loading: true });

    console.log("stateName")
    console.log(stateName)
    console.log(value)

    if(stateName == "perhour"){
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ['calledunit']: false,
        },
      }));
    }

    if(stateName == "calledunit"){
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ['perhour']: false,
        },
      }));
    }

    if (stateName == 'selectLocations') {
      if (value == true) {
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            ["LocationText"]: text,
            Location: this.state.LocationListForSelect,
            LocationId: this.state.LocationListForSelect,
            OrganizationDepartamentId: 0,
          },
          departamentName: "",
        }));
      } else {
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            ["LocationText"]: text,
            Location: [],
            LocationId: [],
          }
        }));
      }
    } else if (stateName == 'selectOrganizations') {
      if (value == true) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            Organizations: this.state.OrgListForSelect,
            OrganizationId: this.state.OrgListForSelect,
            ["OrganizationText"]: text,
            OrganizationDepartamentId: 0,
            LocationId: [],
            locationReset:[],
            Locations: [],
            Location: [],
            DepartamentList: []
          },
          DepartamentList: [],
          LocationList: []
        }));
        await this.GetLocationsByOrganizationId(this.state.OrgListForSelect)
        await this.getAllDepartaments(this.state.OrgListForSelect);
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            Organizations: [],
            OrganizationId: [],
            ["OrganizationText"]: text,
            OrganizationDepartamentId: 0,
            LocationId: [],
            locationReset:[],
            Locations: [],
            Location: [],
            DepartamentList: []
          },
          DepartamentList: [],
          LocationList: []
        }));
        await this.GetLocationsByOrganizationId([])
        await this.getAllDepartaments([]);
      }
    }

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));

    if (stateName === "OrganizationId") {
      
      //await this.getAllLocations(value);
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["OrganizationText"]: text,
          Organizations: value,
          Location: [],
          OrganizationDepartamentId: 0,
          LocationId: [],
          locationReset:[],
        },
        departamentName: "",
      }));

      this.setState({ DepartamentList: [], LocationList: [] });
      await this.GetLocationsByOrganizationId(value)
      await this.getAllDepartaments(value);

      //this.getDepartament(value);
    }

    if (stateName === "GroupId") {
      
      //console.log(this.state.item.Organizations, this.state.item.OrganizationId, this.state.item.OrganizationDepartamentId)
      this.setState({ DepartamentList: [] });
      this.setState({ OrganizationList: [] });
      this.setState({LocationList: [] })

      console.log("value aqui")
      console.log(value)
      await this.getAllOrganizations(value);
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["GroupText"]: text,
          Groups: value,
          Organizations: [],
          OrganizationId: [],
          Location: [],
          LocationId: [],
          OrganizationDepartamentId: 0,
          locationReset:[],
        },
        departamentName: "",
      }));
    }
    
    if (stateName === "Location") {
      let idAddresses = []
      
      value.forEach(element => {
        idAddresses = [...idAddresses, element.id]
      });
      
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["LocationText"]: text,
          Location: value,
          LocationId: idAddresses,
          OrganizationDepartamentId: 0,
        },
        departamentName: "",
      }));
    }
    
    if (stateName === "WorkHourId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["WorkHourText"]: text,
        },
      }));
    }

    this.setState({ loading: false });
  }
  getRolesIds() {
    const idList = this.state.RoleList.map((item) => {
      return item.id;
    });
    return idList;
  }
  getRolesNames() {
    const idList = this.state.RoleList.map((item) => {
      return item.name;
    });
    return idList;
  }
  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  orgDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.OrgListForDelete && this.state.OrgListForDelete.length > 0) {

      this.setState({ loading: true })

      var orgList = [];
      let organizationIds = "";
      var orgDeleteList = this.state.OrgListForDelete.split(",");

      orgDeleteList.map(organizationDelete => {
        if(organizationDelete != ""){
          let returnok = this.state.OrgList.filter( el => el.id != organizationDelete)
          returnok.map(item => {
            if(orgList.filter(el => el.id != item.id )){
              orgList.push(item);
              organizationIds = `${item.id},${organizationIds}`
            }
          })
        }
      })
      
      this.setState({
        loading: false,
        OrgList: orgList,
        OrgListIds: organizationIds,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
      }, () => {
        this.getWorkHoursByOrgIds(organizationIds)
      })

    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"organization.material.registration.select.organization"})
      })
    }
  }

  openOrgInsertModal = async () => {
    this.setState({
      openOrgInsertModal: !this.state.openOrgInsertModal
    })
  }

  saveOrgInsertModal = async () => {

    const intl = this.props.intl;

    let organizationIds = ""
    if (this.state.item.OrganizationId && this.state.item.OrganizationId.length > 0) {
      this.state.item.OrganizationId.map(item => {
        if(item && item.id && item.id > 0)
          organizationIds = `${item.id},${organizationIds}`
      })
    }

    this.setState({
      loading: false,
      OrgList: this.state.item.OrganizationId,
      OrgListIds: organizationIds,
      openNotification: true,
      notificationVariant: 'success',
      notificationMessage: intl.formatMessage({ id: "add.success" }),
      openOrgInsertModal: !this.state.openOrgInsertModal,
    }, () => {
      this.getWorkHoursByOrgIds(organizationIds)
    })

  }

  setOrgDelete = async (org) => {
    this.setState({
      OrgListForDelete: org + "," + this.state.OrgListForDelete
    })
  }

  closeOrgModal = () => this.setState({ openOrgInsertModal: false })

  locationDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.LocationListForDelete && this.state.LocationListForDelete.length > 0) {

      this.setState({ loading: true });

      var locationList = [];
      var locationDeleteList = this.state.LocationListForDelete.split(",");

      locationDeleteList.map(itemDelete => {
        if(itemDelete != ""){
          let returnok = this.state.OrgList.filter( el => el.id != itemDelete)
          returnok.map(item => {
            if(locationList.filter(el => el.id != item.id )){
              locationList.push(item);
            }
          })
        }
      })

      this.setState({
        loading: false,
        LocationList: locationList,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
      })

    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"select.location"})
      })
    }
  }

  emailExists = e => {
    const intl = this.props.intl;
    this.setState({ loading: true });
    const name = e.target.name;
    const value = e.target.value;

    if(name === "email") {
      if(value.length > 0) {
        let data = new FormData();
        data.append("email", value)

        Api.post("/users/emailexists", data)

        .then((result) => {
          if (result.data.success) {
            this.setState({ loading: false });
            if(result.data.data && result.data.data.id && result.data.data.id > 0) {
              let errorMessage = intl.formatMessage({id:"email.already.registered"})

              if(result.data.data.userroles && result.data.data.userroles.length > 0) {
                if(result.data.data.userroles.length > 1) {
                  errorMessage += " Perfis: "
                  result.data.data.userroles.map((item, idx) => {
                    if(idx == 0)
                      errorMessage += item.role.displayname
                    else
                    errorMessage += ` ,${item.role.displayname}`
                  })
                } else {
                  errorMessage +=  intl.formatMessage({id:"profile.text"}) + ` ${result.data.data.userroles[0].role.displayname}`
                }
              }

              this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: errorMessage
              });
            }
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                result.data && result.data.errors
                  ? result.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
            Api.kickoff(result);  
          }
        })
        .catch((err) => {
          if(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]){
            if(!isNaN(err.response.data.errors[0])){
              this.setState({
                loading: false,
                openDialogNewAgents: true,
                valueNewAgents: err.response.data.errors[0],
              });
            }else{
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data && err.response.data.errors
                    ? err.response.data.errors[0]
                    : intl.formatMessage({ id: "process.error" }),
              });
            }
          }else if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }) + err.response,
            });
          }
        });
      } else {
        this.setState({ loading: false });
      }
    }
  }

  openLocationInsertModal = async () => {
    this.setState({
      openLocationInsertModal: !this.state.openLocationInsertModal
    })
  }

  saveLocationInsertModal = async () => {

    const intl = this.props.intl;

    this.setState({
      loading: true
    })

    this.setState({
      loading: false,
      LocationList: this.state.item.LocationId,
      openNotification: true,
      notificationVariant: 'success',
      notificationMessage: intl.formatMessage({ id: "add.success" }),
      openLocationInsertModal: !this.state.openLocationInsertModal,
    })

  }

  setLocationDelete = async (org) => {
    this.setState({
      LocationListForDelete: org + "," + this.state.LocationListForDelete
    })
  }

  openDialogNewAgents = () => {
    this.setState({
      openDialogNewAgents: !this.state.openDialogNewAgents
    })
  }

  render() {

    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    let checkIsActive = false
    if (item.UserRoles && item.UserRoles.length > 0) {
      let techFilter = item.UserRoles.filter(item => item === 5);
      if(techFilter.length > 0) {
        checkIsActive = true
      }
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />

            <Dialog
              open={this.state.openDialogNewAgents}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{intl.formatMessage({id:"dialog.title.warning.max.users.reached"})}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {intl.formatMessage({id:"warning.max.users.reached.first.half"})} R$ {this.state.valueNewAgents} {intl.formatMessage({id:"warning.max.users.reached.second.half"})}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.openDialogNewAgents} variant="contained" color="secondary" autoFocus >
                  {intl.formatMessage({id:"cancel"})}
                </Button>
                <Button variant="contained" onClick={this.handleSubmitAccept} color="primary" >
                  {intl.formatMessage({id:"accept"})}
                </Button>
              </DialogActions>
            </Dialog>

            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid xs={12} sm={4}></Grid>
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                
                {/*<Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label={intl.formatMessage({ id: "organization" })}
                      options={this.state.OrganizationList}
                      stateName="OrganizationId"
                      changeSelect={this.changeValues}
                      selected={item.OrganizationId}
                    />
                  </Grid>
                </Grid>*/}

                {/*<Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label="Localizações"
                      name="LocationId"
                      options={this.state.LocationList}
                      stateName="Location"
                      changeSelect={this.changeValues}
                      selected={item.locationReset}
                    />
                  </Grid>
              </Grid>*/}

                <Accordion defaultExpanded ={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{intl.formatMessage({id:"select.organizations"})}*</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} xs={12} sm={12} >

                      <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                        <Grid xs={12} sm={4} >

                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <DialogConfirmation
                            msgButton={intl.formatMessage({id:"organization.material.registration.remove.organization"})}
                            msg={intl.formatMessage({id:"user.client.registration.confirm.remove.organization.from.user"})}
                            msgDisagree={this.props.intl.formatMessage({id:"cancel"})}
                            msgAgree={this.props.intl.formatMessage({id:"yes"})}
                            handleConfirmation={e => this.orgDeleteModal()}
                            classes={classes}
                            icon={<DeleteIcon />}
                          />
                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.button}
                            startIcon={<AddIcon />}
                            onClick={e => this.openOrgInsertModal()} >
                            {intl.formatMessage({id:"organization.material.registration.add.organization"})}
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid xs={12} sm={12}
                        style={{
                          marginLeft: 5,
                          marginBottom: 5,
                          border: '3px solid #eee'
                        }} >
                        <div style={{ height: 350 }}>
                          <DataGrid
                            style={{ fontSize: 14, color: '#000' }}
                            className="DataGridStyle"
                            components={{
                              NoRowsOverlay: NoDataRows,
                              Toolbar: GridToolbarFilterButton,
                            }}
                            rows={this.state.OrgList}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            onSelectionModelChange={itm => {
                              if (itm.length > 0) {
                                this.setOrgDelete(itm);
                              } else {
                                this.setState({
                                  OrgListForDelete: [],
                                })
                              }
                            }}
                            columns={[
                              { headerName: this.props.intl.formatMessage({id:"menu.organization"}), field: 'name', width: 500 },
                            ]}
                            HeadersVisibility="None"
                          />
                        </div>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>

                <Accordion style={{marginTop: "6px", marginBottom: "10px"}}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{intl.formatMessage({id:"user.registration.select.locations"})}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} xs={12} sm={12} >

                      <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                        <Grid xs={12} sm={4} >

                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <DialogConfirmation
                            msgButton={intl.formatMessage({id:"user.client.edit.remove.location"})}
                            msg={intl.formatMessage({id:"user.client.edit.confirm.remove.user.location"})}
                            msgDisagree={this.props.intl.formatMessage({id:"cancel"})}
                            msgAgree={this.props.intl.formatMessage({id:"yes"})}
                            handleConfirmation={e => this.locationDeleteModal()}
                            classes={classes}
                            icon={<DeleteIcon />}
                          />
                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.button}
                            startIcon={<AddIcon />}
                            onClick={e => this.openLocationInsertModal()} >
                            {intl.formatMessage({id:"user.client.edit.add.locations"})}
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid xs={12} sm={12}
                        style={{
                          marginLeft: 5,
                          marginBottom: 5,
                          border: '3px solid #eee'
                        }} >
                        <div style={{ height: 350 }}>
                          <DataGrid
                            style={{ fontSize: 14, color: '#000' }}
                            className="DataGridStyle"
                            components={{
                              NoRowsOverlay: NoDataRows,
                              Toolbar: GridToolbarFilterButton,
                            }}
                            rows={this.state.LocationList}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            onSelectionModelChange={itm => {
                              if (itm.length > 0) {
                                this.setLocationDelete(itm);
                              } else {
                                this.setState({
                                  LocationListForDelete: [],
                                })
                              }
                            }}
                            columns={[
                              { headerName: intl.formatMessage({id:"locations"}), field: 'name', width: 500 },
                            ]}
                            HeadersVisibility="None"
                          />
                        </div>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      id="grouped-demo"
                      options={this.state.DepartamentList.sort(
                        (a, b) => -b.order.localeCompare(a.order)
                      )}
                      groupBy={(option) => option.order}
                      inputValue={this.state.departamentName}
                      onInputChange={(event, newInputValue) => {
                        this.setState({ departamentName: newInputValue });
                      }}
                      getOptionLabel={(option) => option.parentname}
                      style={{ minWidth: "100%", width: "100%" }}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState((prevState) => ({
                            item: {
                              ...prevState.item,
                              OrganizationDepartamentId: value.id,
                            },
                          }));
                        } else {
                          this.setState((prevState) => ({
                            item: {
                              ...prevState.item,
                              OrganizationDepartamentId: 0,
                            },
                          }));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ minWidth: "100%", width: "100%" }}
                          label="Departamentos"
                          variant="outlined"
                        />
                      )}
                    />
                    {/* <SimpleAutoComplete label={intl.formatMessage({id:"departament"})} options={this.state.DepartamentList} stateName='OrganizationDepartamentId' changeSelect={this.changeValues} selected={item.OrganizationDepartamentId} /> */}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={item.name}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "email" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      type="email"
                      variant="outlined"
                      name="email"
                      onChange={this.handleInputChange}
                      onBlur={this.emailExists}
                      value={item.email}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "document" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      type="document"
                      variant="outlined"
                      name="document"
                      onChange={this.handleInputChange}
                      value={item.document}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    {/*<TextField
                      fullWidth
                      label={intl.formatMessage({ id: "cellphone" })}
                      autoComplete="fname"
                      variant="outlined"
                      name="phone"
                      onChange={this.handleInputChange}
                      value={item.phone}
                      inputProps={{ maxLength: 15, minLength:15 }}
                      required
                    />*/}
                    <PhoneInput
                      inputStyle={{
                        padding: '23.5px 14px 23.5px 58px',
                        width: '100%'
                      }}
                      style={{
                          marginTop: 16,
                          marginBottom: 8
                      }}
                      id="phone"
                      name="phone"
                      country={'br'}
                      value={item.phone}
                      onChange={this.handleChangePhone}
                      InputLabel={intl.formatMessage({id:"phone"})}
                      specialLabel={intl.formatMessage({id:"phone"})}
                      inputProps={{
                        name: intl.formatMessage({ id: "cellphone" })
                      }} />
                  </Grid>

                {/*<Grid container spacing={4}>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      label="Valor por KM Rodado"
                      variant="outlined"
                      value={item.kmvaluerotated}
                      onChange={this.handleInputChange}
                      name="kmvaluerotated"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      autoComplete="fname"
                      required
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} style={{ marginLeft: "-10px" }}>
                    <SimpleHelp message={"Usar . como separador decimal"} />
                  </Grid>
                </Grid>

                <Grid xs={12} sm={12} style={{ marginTop: 10, marginLeft: 5, marginBottom: 5 }}>
                  <Typography component='h1' variant='h5'>
                    Parâmetro de Custo
                  </Typography>
                </Grid>
                <Grid container spacing={4} style={{ marginTop: 5, marginLeft: 5, marginBottom: 15 }}>
                  <Grid xs={2} sm={2}>
                    <SimpleCheckBox icon="true" changeSelect={this.changeValues} stateName='perhour' fullWidth label='Por hora' autoComplete='fname' name="perhour" selected={item.perhour} variant='outlined' />
                  </Grid> 
                  <Grid xs={6} sm={6}>
                    <SimpleCheckBox icon="true" changeSelect={this.changeValues} stateName='calledunit' fullWidth label='Por unidade de chamado' autoComplete='fname' name="calledunit" selected={item.calledunit} variant='outlined' />
                  </Grid>
                </Grid>

                {this.state.item.calledunit && (
                <Grid container spacing={4}>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      label="Valor por Chamado"
                      variant="outlined"
                      value={item.valueforcalled}
                      onChange={this.handleInputChange}
                      name="valueforcalled"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      autoComplete="fname"
                      required
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} style={{ marginLeft: "-10px" }}>
                    <SimpleHelp message={"Usar . como separador decimal"} />
                  </Grid>
                </Grid>)}

                {this.state.item.perhour && (
                <Grid container spacing={4}>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      label="Valor H/H"
                      variant="outlined"
                      value={item.employeehourvalue}
                      onChange={this.handleInputChange}
                      name="employeehourvalue"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      autoComplete="fname"
                      required
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} style={{ marginLeft: "-10px" }}>
                    <SimpleHelp message={"Usar . como separador decimal"} />
                  </Grid>
                    </Grid>)}*/}
                  <Grid item xs={12} sm={12}>
                    <SimpleSelect
                      label={intl.formatMessage({id:"startpage"})}
                      name="startPage"
                      options={this.state.startPageItems}
                      stateName="startPage"
                      changeSelect={this.changeValues}
                      selected={item.startPage}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "active" })}
                      name="active"
                      stateName="active"
                      changeSelect={this.changeValues}
                      selected={item.active}
                    />
                  </Grid>
                { checkIsActive === true && (
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "can.create.calleds.in.2move" })}
                      name={intl.formatMessage({ id: "can.create.calleds.in.2move" })}
                      stateName="canCreateCalledInMobile"
                      changeSelect={this.changeValues}
                      selected={item.canCreateCalledInMobile}
                    />
                  </Grid>)}
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "customer.can.join.another.customers.call" })}
                      name={intl.formatMessage({ id: "customer.can.join.another.customers.call" })}
                      stateName="canInteractWithCalledWhereAreNotRequesterOrAssigned"
                      changeSelect={this.changeValues}
                      selected={item.canInteractWithCalledWhereAreNotRequesterOrAssigned}
                    />
                </Grid>
                {/*<Grid container spacing={4}>
                  <Typography component="h5" variant="h5">
                    {<FormattedMessage id="user.profile" />}
                  </Typography>

                  <Grid item xs={12} sm={12}>
                    <MyCheckList
                      label={intl.formatMessage({ id: "menu.groups" })}
                      name="UserRoles"
                      optionsText={this.getRolesNames()}
                      options={this.getRolesIds()}
                      stateName="UserRoles"
                      changeSelect={this.changeValues}
                      selected={item.UserRoles}
                    />
                  </Grid>
                    </Grid>*/}
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button 
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />

            <Dialog
              fullWidth
              maxWidth="md"
              open={this.state.openOrgInsertModal}
              onClose={this.closeOrgModal}
              aria-labelledby="form-dialog-title-org-mat"
              PaperProps={{
                style: {
                  overflow: 'hidden'
                }
              }} >
              <DialogTitle id="form-dialog-title-org-mat">
                {intl.formatMessage({id:"organization.material.registration.new.organizations"})}
                <ClearIcon onClick={this.closeOrgModal} style={{ float: 'right', cursor: 'pointer' }} />
              </DialogTitle>
              <Grid item xs={12} sm={12} >
                <Grid container spacing={4} style={{ padding:"5px" }}>
                  <Grid item xs={12} sm={12} >
                    <SimpleCheckBox label={intl.formatMessage({id:"technical.team.edit.select.all.organizations"})} name='selectOrganizations' stateName='selectOrganizations' changeSelect={this.changeValues} selected={this.state.selectOrganizations} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label={intl.formatMessage({ id: "organization" })}
                      options={this.state.OrgListForSelect}
                      stateName="OrganizationId"
                      changeSelect={this.changeValues}
                      selected={this.state.item.OrganizationId}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid spacing={2} container justify="flex-end" style={{ padding: 15 }}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant='contained'
                    type="button"
                    onClick={this.closeOrgModal}
                    fullWidth >
                    {this.props.intl.formatMessage({id:"cancel"})}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={this.saveOrgInsertModal}
                  >
                    {<FormattedMessage id="save" />}
                  </Button>
                </Grid>
              </Grid>
            </Dialog>

            <Dialog
              fullWidth
              maxWidth="md"
              open={this.state.openLocationInsertModal}
              onClose={this.closeLocationModal}
              aria-labelledby="form-dialog-title-org-mat"
              PaperProps={{
                style: {
                  overflow: 'hidden'
                }
              }} >
              <DialogTitle id="form-dialog-title-org-mat">
                {intl.formatMessage({id:"new.locations"})}
                <ClearIcon onClick={this.closeLocationModal} style={{ float: 'right', cursor: 'pointer' }} />
              </DialogTitle>
              <Grid item xs={12} sm={12} >
                <Grid container spacing={4} style={{ padding:"5px" }}>
                  <Grid item xs={12} sm={12} >
                    <SimpleCheckBox label={intl.formatMessage({id:"select.all.locations"})} name='selectLocations' stateName='selectLocations' changeSelect={this.changeValues} selected={this.state.selectLocations} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    < SimpleAutoCompleteMultiple
                      label={intl.formatMessage({id:"locations"})}
                      options={this.state.LocationListForSelect}
                      stateName="LocationId"
                      changeSelect={this.changeValues}
                      selected={this.state.item.Location}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid spacing={2} container justify="flex-end" style={{ padding: 15 }}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant='contained'
                    type="button"
                    onClick={this.closeLocationModal}
                    fullWidth >
                    {this.props.intl.formatMessage({id:"cancel"})}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={this.saveLocationInsertModal}
                  >
                    {<FormattedMessage id="save" />}
                  </Button>
                </Grid>
              </Grid>
            </Dialog>

          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
UserRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(UserRegistration))
);
