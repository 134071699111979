import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@mui/material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tab.css'

import Base from '../../components/layout-base/base';
import NavTabs from '../../components/tab/tab';
import {Grid} from '@mui/material';

import OrganizationEditContent from './index.content';
import OrganizationSettingsContent from '../organizationsetting-list/index.content';
import AddressesContent from '../address-list/index.content';
import OrganizationCalendarsContent from '../organizationcalendar-list/index.content';
import OrganizationFieldsContent from '../organizationfield-list/index.content';
import OrganizationChecklistsContent from '../organizationchecklist-list/index.content';
import OrganizationEmailAccountConfig from '../organizationaccountemail-list/index.content';
import OrganizationSlasContent from '../organizationsla-list/index.content'
import OrganizationCategoriesContent from '../organizationcategory-list/index.content'
import OrganizationDepartamentsContent from '../organizationdepartament-list/index.content'
import OrganizationPatrimoniesContent from '../organizationpatrimony-list/index.content'
import OrganizationMaterialsContent from '../organizationmaterial-list/index.content'
import * as componentActions from '../../redux/actions/organizations';
import EventIcon from '@mui/icons-material/Event';
import InputIcon from '@mui/icons-material/Input';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import EmailIcon from '@mui/icons-material/Email';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import Wizard from '../wizard-organization/wizard';
import { PapiroConsole } from '../../utils/papiroConsole';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CategoryIcon from '@mui/icons-material/Category';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ComputerIcon from '@mui/icons-material/Computer';
import ViewListIcon from '@mui/icons-material/ViewList';
import CalledListDatasContent from '../calledlistdata-list/index.content'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AdditionalCalledCostItemsTabContent from '../additional-service-config-list/index.content'
import CalledReportConfigRegistration from '../called-report-config-registration/index.content'
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';

class OrganizationEdit extends Component {

  constructor(props) {
    super(props);
    //console.log('edit');
    //console.log(this.props.organizations.organization);
    //console.log('edit');
    this.accountEmail = false;

    PapiroConsole.log("=== OrganizationEdit ===")
    PapiroConsole.log("=== PROPS ===")
    PapiroConsole.log(this.props)

    let propDefaultTabIndex = 0
    let propTabIndex = 0
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.propTab && this.props.location.state.propTab > 0) {
      propTabIndex = this.props.location.state.propTab
      propDefaultTabIndex = this.props.location.state.propTab
    }

    this.state = {
      tabIndex: propTabIndex,
      defaultTabIndex: propDefaultTabIndex,
      orgId: this.props.organizations && this.props.organizations.organization && this.props.organizations.organization.id ? this.props.organizations.organization.id : 0,
    }

    if(!this.props.organizations || (this.props.organizations && !this.props.organizations.organization)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    if(this.props.organizations.organization.organizationsettings != null){
      if(this.props.organizations.organization.organizationsettings.allowscreateanonymouscalledsbyemail == true){
        this.accountEmail = true;
      }
    }
    
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }    

  componentDidMount() {
    if (this.props.location && /*this.props.location.state.internal === true &&*/ this.props.location.state != undefined) {
      PapiroConsole.log("=== Teste ===")
    }
  }


    render() {
      const { intl, history, match } = this.props;
     return (
        <Base >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={() => this.props.history.push(`/${this.props.match.params.lang}/organizations`)}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={11} sm={12} >
                <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })}>
                  <TabList style={{borderColor:'#BB8FCE'}}>
                    <Tab  style={{fontSize: "14px"}}>< SettingsApplicationsIcon/> {<FormattedMessage id="organization.generalconfigurations" />} </Tab>
                   <Tab  style={{fontSize: "14px"}}><AccountBalanceIcon  /> {<FormattedMessage id="organization.data" />}</Tab>
                   {/* <Tab  style={{fontSize: "14px"}}><SettingsIcon  /> {<FormattedMessage id="organization.configuration" />} </Tab>*/}
                    <Tab  style={{fontSize: "14px"}}><ContactMailIcon   /> {<FormattedMessage id="organization.address" />}</Tab>
                    <Tab  style={{fontSize: "14px"}}><EventIcon /> {<FormattedMessage id="organization.holiday" />}</Tab>
                    <Tab  style={{fontSize: "14px"}}><InputIcon /> {<FormattedMessage id="organization.field" />}</Tab>
                    <Tab  style={{fontSize: "14px"}}><CheckBoxIcon /> {<FormattedMessage id="organization.checklist" />}</Tab>
                    <Tab style={{ fontSize: '14px' }}><HourglassEmptyIcon /> {<FormattedMessage id="menu.organization.sla" />}</Tab>
                    <Tab style={{ fontSize: '14px' }}><CategoryIcon /> {<FormattedMessage id="organization.category.header" />}</Tab>
                    <Tab style={{ fontSize: '14px' }}><BusinessIcon /> {<FormattedMessage id="organization.departament" />}</Tab>
                    <Tab style={{ fontSize: '14px' }}><BusinessCenterIcon /> {<FormattedMessage id="menu.organizationpatrimonies.name" />}</Tab>
                    <Tab style={{ fontSize: '14px' }}><ComputerIcon /> {<FormattedMessage id="organization.material" />}</Tab>
                    <Tab style={{ fontSize: '14px' }}><ViewListIcon /> {<FormattedMessage id="called.list.data" />}</Tab>
                    <Tab style={{ fontSize: '14px' }}><AccountBalanceWalletIcon /> {<FormattedMessage id="additional.called.cost.config" />}</Tab>
                    <Tab style={{ fontSize: '14px' }}><QueuePlayNextIcon /> {<FormattedMessage id="called.report.config" />}</Tab>
                    {this.accountEmail && ( <Tab  style={{fontSize: "14px"}}><EmailIcon /> {<FormattedMessage id="organization.email" />}</Tab>)}

                  </TabList>
            
                  <TabPanel>
                  
                      <Wizard/>

                 </TabPanel>

                  <TabPanel>
                  
                    <OrganizationEditContent />

                  </TabPanel>

                 {/* <TabPanel>
                    <OrganizationSettingsContent/>
                  
                 </TabPanel>*/}
                  
                  <TabPanel>
                    <AddressesContent from="orgEdit" />
                  </TabPanel>

                  <TabPanel>
                    <OrganizationCalendarsContent from="orgEdit" />
                  </TabPanel>

                  <TabPanel>
                    <OrganizationFieldsContent from="orgEdit" />
                  </TabPanel>

                  <TabPanel>
                    <OrganizationChecklistsContent from="orgEdit" />
                  </TabPanel>
                  
                  <TabPanel>
                    <OrganizationSlasContent from="orgEdit" />
                  </TabPanel>

                  <TabPanel>
                    <OrganizationCategoriesContent from="orgEdit" />
                  </TabPanel>

                  <TabPanel>
                    <OrganizationDepartamentsContent from="orgEdit" />
                  </TabPanel>

                  <TabPanel>
                    <OrganizationPatrimoniesContent from="orgEdit" />
                  </TabPanel>

                  <TabPanel>
                    <OrganizationMaterialsContent from="orgEdit" />
                  </TabPanel>

                  <TabPanel>
                    <CalledListDatasContent from="orgEdit" />
                  </TabPanel>

                  <TabPanel>
                    <AdditionalCalledCostItemsTabContent from="orgEdit" />
                  </TabPanel>

                  <TabPanel>
                    <CalledReportConfigRegistration orgId={this.state.orgId} from="orgEdit" />
                  </TabPanel>

                  {this.accountEmail && (
                    <TabPanel>
                    <OrganizationEmailAccountConfig />
                  </TabPanel>
                  )}

                </Tabs>
                </Grid>
              </Grid>





              

        </Base>  
    );
  }
}
OrganizationEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    organizations: state.organizations
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);

            export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(OrganizationEdit)));


            // <NavTabs style={{minWidth:'550px'}} components={[<OrganizationEditContent internal={true} displayName={intl.formatMessage({id:"organization.data"})}/>,
            //         <OrganizationSettingsContent internal={true}  displayName={intl.formatMessage({id:"organization.configuration"})}/>,
            //         <AddressesContent  internal={true}  displayName={intl.formatMessage({id:"organization.address"})} />,
            //          <OrganizationCalendarsContent internal={true} displayName={intl.formatMessage({id:"organization.holiday"})} />]}

            //         icons= {[<AccountBalanceIcon  />,
            //           <SettingsIcon  />,
            //           <ContactMailIcon   />, <EventIcon />]}
            //   />