import Api from './api';

export const getLastFeedbacks = async (organizationId = null) => { 
  let data = [];
  await Api.get(`/dashboard/lastFeedback${(organizationId != null || organizationId != '' || organizationId != 0) ? `/${organizationId}` : '' }`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
  });
  return data;
};
