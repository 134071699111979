import React, { useEffect, useState, memo } from 'react'
import { ColorfyLinearProgress } from './linear-progress'
import { Avatar, Grid } from "@mui/material";
import CallMadeIcon from '@mui/icons-material/CallMade';
import { TablePopup } from '../../../../components/table/table-popup';
import PanToolIcon from '@mui/icons-material/PanTool';
import IconButton from '@mui/material/IconButton';
import { PapiroConsole } from '../../../../utils/papiroConsole'
import BarChartIcon from '@mui/icons-material/BarChart';
import './styles.css'
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { withStyles } from "@mui/styles"
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { injectIntl } from 'react-intl';

export const ItemCard = memo(function ItemCard(props) {
  
  const { called, isAdm, isClient, isTechnical, userSession, intl, currentPopupData, setCurrentPopupData, context, popupColumns, openConfirmAssignCalledDialogFinal } = props

  const [showId, setShowId] = useState(false)
  const [showcalledorganization, setShowcalledorganization] = useState(false)
  const [showcalledtitle, setShowcalledtitle] = useState(false)
  const [showcalledcategory, setShowcalledcategory] = useState(false)
  const [showcalledtype, setShowcalledtype] = useState(false)
  const [showcalleddepartament, setShowcalleddepartament] = useState(false)
  const [showcalledslaservice, setShowcalledslaservice] = useState(false)
  const [showcalledslasolution, setShowcalledslasolution] = useState(false)
  const [showcalledstatus, setShowcalledstatus] = useState(false)
  const [showcalledchangestatus, setShowcalledchangestatus] = useState(false)
  const [showcalledrequester, setShowcalledrequester] = useState(false)
  const [showcalledassigner, setShowcalledassigner] = useState(false)
  const [showcalledaddress, setShowcalledaddress] = useState(false)
  const [showcalledclassification, setShowcalledclassification] = useState(false)
  const [showcalledopendate, setShowcalledopendate] = useState(false)
  const [showcalledduedate, setShowcalledduedate] = useState(false)
  const [showcalledslaserviceexpired, setShowcalledslaserviceexpired] = useState(false)
  const [showcalledmenu, setShowcalledmenu] = useState(false)
  const [showcalledchangeassign, setShowcalledchangeassign] = useState(false)

  const [variant, setVariant] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    ruleTableList()
  }, [called])

  useEffect(() => {
    setVariant(variant);
  }, [variant]);

  useEffect(() => {
    setMessage(message);
  }, [message]);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const closeNotification = () => {
	  setOpen(false);
  }

  const showMessage = async ( openNotification , notificationVariant , notificationMessage) =>  {
		setVariant(notificationVariant);
		setMessage(notificationMessage);
		setOpen(openNotification);
  }

  const openConfirmAssignCalledDialog = async (rowData) =>  {
    console.log("=== openConfirmAssignCalledDialog ===")
    console.log(rowData)
    if(rowData.assigneduserid != null && typeof rowData.assigneduserid !== 'undefined' && rowData.assigneduserid > 0) {
      props.showMessage( false, true, "info", "O chamado já possui um técnico responsável");
    } else {
      openConfirmAssignCalledDialogFinal(rowData);
    }
  }

  const ruleTableList = () => {
    if (isAdm) {
      if (called && called.calledlistdata != null) {
        setShowId(called.calledlistdata.calledid === true ? true : false)
        setShowcalledorganization(called.calledlistdata.calledorganization === true ? true : false)
        setShowcalledtitle(called.calledlistdata.calledtitle === true ? true : false)
        setShowcalledcategory(called.calledlistdata.calledcategory === true ? true : false)
        setShowcalledtype(called.calledlistdata.calledtype === true ? true : false)
        setShowcalleddepartament(called.calledlistdata.calleddepartament === true ? true : false)
        setShowcalledslaservice(called.calledlistdata.calledslaservice === true ? true : false)
        setShowcalledslasolution(called.calledlistdata.calledslasolution === true ? true : false)
        setShowcalledstatus(called.calledlistdata.calledstatus === true ? true : false)
        setShowcalledchangestatus(called.calledlistdata.calledchangestatus === true ? true : false)
        setShowcalledrequester(called.calledlistdata.calledrequester === true ? true : false)
        setShowcalledassigner(called.calledlistdata.calledassigner === true ? true : false)
        setShowcalledaddress(called.calledlistdata.calledaddress === true ? true : false)
        setShowcalledclassification(called.calledlistdata.calledclassification === true ? true : false)
        setShowcalledopendate(called.calledlistdata.calledopendate === true ? true : false)
        setShowcalledduedate(called.calledlistdata.calledduedate === true ? true : false)
        setShowcalledslaserviceexpired(called.calledlistdata.calledslaserviceexpired === true ? true : false)
        setShowcalledmenu(called.calledlistdata.calledmenu === true ? true : false)
        setShowcalledchangeassign(called.calledlistdata.calledchangeassign === true ? true : false)
      } else {
        setShowId(true)
        setShowcalledorganization(true)
        setShowcalledtitle(true)
        setShowcalledcategory(true)
        setShowcalledtype(false)
        setShowcalleddepartament(true)
        setShowcalledstatus(true)
        setShowcalledslaservice(true)
        setShowcalledslasolution(true)
        setShowcalledrequester(true)
        setShowcalledaddress(true)
        setShowcalledclassification(true)
        setShowcalledopendate(true)
        setShowcalledduedate(true)
        setShowcalledassigner(true)
        setShowcalledchangeassign(true)
        setShowcalledchangestatus(true)
        setShowcalledslaserviceexpired(false)
        setShowcalledmenu(false)
      }
    } else if (isAdm == false && isClient) {
      if (called && called.calledlistdata != null) {
        setShowId(called.calledlistdata.calledid === true ? true : false)
        setShowcalledorganization(called.calledlistdata.calledorganization === true ? true : false)
        setShowcalledtitle(called.calledlistdata.calledtitle === true ? true : false)
        setShowcalledcategory(called.calledlistdata.calledcategory === true ? true : false)
        setShowcalledtype(called.calledlistdata.calledtype === true ? true : false)
        setShowcalleddepartament(false)
        setShowcalledslaservice(called.calledlistdata.calledslaservice === true ? true : false)
        setShowcalledslasolution(called.calledlistdata.calledslasolution === true ? true : false)
        setShowcalledstatus(called.calledlistdata.calledstatus === true ? true : false)
        setShowcalledchangestatus(false)
        setShowcalledrequester(called.calledlistdata.calledrequester === true ? true : false)
        setShowcalledassigner(called.calledlistdata.calledassigner === true ? true : false)
        setShowcalledaddress(called.calledlistdata.calledaddress === true ? true : false)
        setShowcalledclassification(false)
        setShowcalledopendate(called.calledlistdata.calledopendate === true ? true : false)
        setShowcalledduedate(called.calledlistdata.calledduedate === true ? true : false)
        setShowcalledslaserviceexpired(called.calledlistdata.calledslaserviceexpired === true ? true : false)
        setShowcalledmenu(false)
        setShowcalledchangeassign(false)
      } else {
        setShowId(true)
        setShowcalledorganization(true)
        setShowcalledtitle(true)
        setShowcalledcategory(true)
        setShowcalledtype(false)
        setShowcalleddepartament(false)
        setShowcalledslaservice(true)
        setShowcalledslasolution(true)
        setShowcalledstatus(false)
        setShowcalledchangestatus(false)
        setShowcalledrequester(true)
        setShowcalledassigner(true)
        setShowcalledaddress(false)
        setShowcalledclassification(false)
        setShowcalledopendate(false)
        setShowcalledduedate(false)
        setShowcalledslaserviceexpired(false)
        setShowcalledmenu(false)
        setShowcalledchangeassign(false)
      }
    } else {
      if (called && called.calledlistdata != null) {
        setShowId(called.calledlistdata.calledid === true ? true : false)
        setShowcalledorganization(called.calledlistdata.calledorganization === true ? true : false)
        setShowcalledtitle(called.calledlistdata.calledtitle === true ? true : false)
        setShowcalledcategory(called.calledlistdata.calledcategory === true ? true : false)
        setShowcalledtype(called.calledlistdata.calledtype === true ? true : false)
        setShowcalleddepartament(called.calledlistdata.calleddepartament === true ? true : false)
        setShowcalledslaservice(called.calledlistdata.calledslaservice === true ? true : false)
        setShowcalledslasolution(called.calledlistdata.calledslasolution === true ? true : false)
        setShowcalledstatus(called.calledlistdata.calledstatus === true ? true : false)
        setShowcalledchangestatus(called.calledlistdata.calledchangestatus === true ? true : false)
        setShowcalledrequester(called.calledlistdata.calledrequester === true ? true : false)
        setShowcalledassigner(called.calledlistdata.calledassigner === true ? true : false)
        setShowcalledaddress(called.calledlistdata.calledaddress === true ? true : false)
        setShowcalledclassification(called.calledlistdata.calledclassification === true ? true : false)
        setShowcalledopendate(called.calledlistdata.calledopendate === true ? true : false)
        setShowcalledduedate(called.calledlistdata.calledduedate === true ? true : false)
        setShowcalledslaserviceexpired(called.calledlistdata.calledslaserviceexpired === true ? true : false)
        setShowcalledmenu(called.calledlistdata.calledmenu === true ? true : false)
        setShowcalledchangeassign(called.calledlistdata.calledchangeassign === true ? true : false)
      } else {
        setShowId(true)
        setShowcalledduedate(true)
        setShowcalledorganization(true)
        setShowcalledtitle(true)
        setShowcalledcategory(true)
        setShowcalledtype(false)
        setShowcalleddepartament(false)
        setShowcalledstatus(true)
        setShowcalledslaservice(true)
        setShowcalledslasolution(true)
        setShowcalledchangestatus(false)
        setShowcalledrequester(true)
        setShowcalledaddress(true)
        setShowcalledclassification(false)
        setShowcalledslaserviceexpired(false)
        setShowcalledmenu(false)
        setShowcalledopendate(true)
        setShowcalledassigner(true)
        setShowcalledchangeassign(true)
      }
    }
    console.log("=== ruleTableList ===")
    console.log(called.id)
    console.log(showcalledstatus)
  }

  const handleClickGoToCalled = (called, newTab = false) => {
    console.log("=== handleClickGoToCalled ===")
    console.log(userSession)
    let abl = context.can('Answer', 'Called');
		let abl2 = context.can('Edit', 'Called');
		if (abl == false && abl2 == false) {
      console.log("=== handleClickGoToCalled if 1 ===")		
			props.showMessage( false, true, 'error', this.props.intl.formatMessage({id:"operation.notAllowed"}));
			return false;
		}
    
    if (!isClient && called.assigneduser != null) {
      console.log("=== handleClickGoToCalled if 2 ===")
			if (called.assigneduser.id != userSession.user.id) {
        console.log("=== handleClickGoToCalled if 2.1 ===")
				if (called.organization.organizationsettings.canviewcalledfromothertechnical == false && isAdm == false) {
          if (!isClient && called.requestinguser && called.requestinguser.id != userSession.user.id) {
            console.log("=== handleClickGoToCalled if 2.2 ===")
            props.showMessage( false, true, 'error', intl.formatMessage({id:"caled.list.message.unallowed.user"}));
            return false;
          }
				}
			}
		}/* else if (isClient && (called.requestinguser != null)) {
      if (called.requestinguser.id != userSession.user.id) {
        props.showMessage( false, true, 'error', intl.formatMessage({id:"caled.list.message.unallowed.user"}));
					return false;
      }
    }*/
		props.goToCalled(called, newTab)
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
  
    },
  }))(Tooltip);

  return (
    <Grid item lg={12} md={12} sm={12} xs={12} className='calledItem' style={{ borderLeft: `8px solid ${called.priority && called.priority.colorhex ? called.priority.colorhex : '#43A047'}` }}>
      <Grid container spacing={0}>
        { showcalledrequester && (<Grid item lg={2} md={1} sm={3} xs={3} className='requestingUser'>
          <Grid container spacing={0}>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Avatar alt={called.requestingUser}>
                {called.requestingUser.charAt(0)}
              </Avatar>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <span>{called.requestingUser}</span>
            </Grid>
          </Grid>
        </Grid>)}
        <Grid item lg={1} md={2} className='moreActions'>
          <Tooltip title={<h5>Nova guia</h5>}>
            <IconButton aria-label="call-made" onClick={() => handleClickGoToCalled(called, true)}>
              <CallMadeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={<h5>{intl.formatMessage({id:"more.details"})}</h5>}>
          <IconButton aria-label="table-popup" onClick={() => setCurrentPopupData(called.id, popupColumns)} >
            <TablePopup data={currentPopupData} />
          </IconButton>
          </Tooltip>
          { (!isClient && called.assigneduserid == null) && (<Tooltip title={<h5>{intl.formatMessage({id:"assume.called"})}</h5>}><IconButton aria-label="table-popup"  onClick={() => openConfirmAssignCalledDialog(called)} ><PanToolIcon style={{ color: '#58595B', fill: '#58595B' }} /></IconButton></Tooltip>)}
        </Grid>
        <Grid item lg={6} md={6} sm={7} xs={7} className='mainContent'>
          {(showId || showcalledtitle) && (<div className='boldLine-two' onClick={() => handleClickGoToCalled(called)} style={{ cursor: 'pointer' }}>
            { showId && (<span>ID {called.id}</span>)}
            {(showId && showcalledtitle) && (<span> | </span>)}
            { showcalledtitle && (<span>{called.title}</span>)}
          </div>)}
          {(showcalledorganization || showcalledcategory) && (<div className='boldLine-two' onClick={() => handleClickGoToCalled(called)} style={{ cursor: 'pointer' }}>
            { showcalledorganization && (<span>{called.Organization}</span>)}
            { (showcalledorganization && showcalledcategory) && (<span> | </span>)}
            { showcalledcategory && (<span>{called.OrganizationCategory}</span>)}
          </div>)}
          {(showcalledtype || showcalleddepartament) && (<div className='lightLine'>
            { showcalledtype && (<span>{called.typeName}</span>)}
            { (showcalledtype && showcalleddepartament) && (<span> | </span>)}
            { showcalledtype && (<span>{called.DepartamentName}</span>)}
          </div>)}
          {(showcalledaddress || showcalledclassification) && (<div className='lightLine' style={{ display: 'flex' }}>
            { showcalledaddress && (<span>{called.addressname}</span>)}
            { (showcalledaddress && showcalledclassification) && (<span> | </span>)}
            { showcalledclassification && (<span>{called.classification}</span>)}
          </div>)}
          {(showcalledassigner || showcalledopendate || showcalledslaserviceexpired) && (<div className='lightLine' style={{ lineHeight: '25px' }}>
            { showcalledassigner && (<span>{called.assignedUser}</span>)}
            {(showcalledassigner && showcalledopendate && called.assignedUser && called.assignedUser.length > 0) && (<span> - </span>)}
            { showcalledopendate && (<span>{intl.formatMessage({id:"open.date"})} {called.opening} | {called.openingHour}</span>)}
            {(showcalledduedate && showcalledopendate ) && (<span> - </span>)}
            { showcalledduedate && (<span>{intl.formatMessage({id:"estimated.solution.date"})} {called.dueDt} | {called.dueHour}</span>)}
            { (showcalledslaserviceexpired && called.solution >= 100) && (<span style={{
              marginLeft: 10,
              border: '1px solid rgba(243, 32, 42)',
              padding: '8px 5px',
              color: 'rgb(243, 32, 42)',
              borderRadius: 15
            }}>{intl.formatMessage({id:"expired"})}</span>)}
          </div>)}
        </Grid>
        <Grid item sm={2} xs={2} className='moreActionsSmall'>
          <Tooltip title={<h5>Nova guia</h5>}>
            <IconButton aria-label="call-made" onClick={() => handleClickGoToCalled(called, true)}>
              <CallMadeIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={<h5>{intl.formatMessage({id:"more.details"})}</h5>}>
          <IconButton aria-label="table-popup" onClick={() => setCurrentPopupData(called.id, popupColumns)} >
            <TablePopup data={currentPopupData} fontSize="small" />
          </IconButton>
          </Tooltip>
          { (!isClient && called.assigneduserid == null) && (<Tooltip title={<h5>{intl.formatMessage({id:"assume.called"})}</h5>}><IconButton aria-label="table-popup"  onClick={() => openConfirmAssignCalledDialog(called)} ><PanToolIcon style={{ color: '#58595B', fill: '#58595B' }} fontSize="small" /></IconButton></Tooltip>)}
        </Grid>
        { (showcalledstatus || showcalledslaservice || showcalledslasolution) && (<Grid item lg={3} md={3} sm={12} xs={12} className='moreContent' style={{ display: 'flex' }} >
          <Grid container spacing={0}>
            { (showcalledslaservice || showcalledslasolution) && (<Grid item sm={3} xs={3} className="slaTooltip">
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Grid container spaging={2} style={{ margin: 10 }}>
                      { showcalledslaservice && (<Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={0} alignItems="center">
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <span>{intl.formatMessage({id:"item.card.sla.service"})}</span>
                          </Grid>
                          <Grid item lg={6} md={6} sm={5} xs={5}>
                            <ColorfyLinearProgress 
                              progress={called.service}/>
                          </Grid>
                          <Grid item lg={2} md={2} sm={3} xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span >{called.service}%</span>
                          </Grid>
                        </Grid>
                      </Grid>)}
                      { showcalledslasolution && (<Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={0} alignItems="center">
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <span>{intl.formatMessage({id:"sla.solution"})}</span>
                          </Grid>
                          <Grid item lg={6} md={6} sm={5} xs={5}>
                            <ColorfyLinearProgress 
                              progress={called.solution}/>
                          </Grid>
                          <Grid item lg={2} md={2} sm={3} xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span >{called.solution}%</span>
                          </Grid>
                        </Grid>
                      </Grid>)}
                    </Grid>
                  </React.Fragment>
                }
              >
                <BarChartIcon />
              </HtmlTooltip>
            </Grid>)}
            { showcalledstatus && (<Grid item lg={12} md={12} sm={9} xs={9}>
              <Grid container spacing={0}>
                <Grid item lg={4} md={4} sm={3} xs={3}>
                  {intl.formatMessage({id:"menu.statuses"})}
                </Grid>
                <Grid item lg={8} md={8} sm={9} xs={9}>
                    <span className='boldLine' style={{
                      border: `1px solid rgba(0, 0, 0, 0.4)`,
                      padding: '8px 5px',
                      borderRadius: 15,
                      display: 'block'
                    }}>
                      <span
                      style={{
                        height: 5,
                        width: 5,
                        backgroundColor: `${called && called.status && called.status.colorhex ? called.status.colorhex : '#000' }`,
                        border: `3px solid ${called && called.status && called.status.colorhex ? called.status.colorhex : '#000' }`,
                        borderRadius: 5,
                      }}></span>
                      <span style={{ marginLeft: 10 }}>{ called && called.status && called.status.name ? called.status.name : '' }</span>
                    </span>
                    {/*(called && called.status && called.status.counttimesla != null && called.status.counttimesla) && (<span>
                      <Tooltip title={<h5>Tempo sendo contabilizado</h5>}>
                        <HourglassEmptyIcon style={{ fill: '#619E16', color: '#619E16' }} />
                      </Tooltip>
                    </span>)*/}
                </Grid>
              </Grid>
            </Grid>)}
            { showcalledslaservice && (<Grid item lg={12} md={12} className="slaBlock">
              <Grid container spacing={0} alignItems="center">
                <Grid item lg={4} md={4}>
                  <span className='boldLine'>{intl.formatMessage({id:"item.card.sla.service"})}</span>
                </Grid>
                <Grid item lg={6} md={6} style={{ paddingRight: 20 }}>
                  <ColorfyLinearProgress 
                    progress={called.service}/>
                </Grid>
                <Grid item lg={2} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <span >{called.service}%</span>
                </Grid>
              </Grid>
            </Grid>)}
            { showcalledslasolution && (<Grid item lg={12} md={12} className="slaBlock">
              <Grid container spacing={0} alignItems="center">
                <Grid item lg={4} md={4}>
                  <span className='boldLine'>{intl.formatMessage({id:"sla.solution"})}</span>
                </Grid>
                <Grid item lg={6} md={6} style={{ paddingRight: 20 }}>
                  <ColorfyLinearProgress 
                    progress={called.solution}/>
                </Grid>
                <Grid item lg={2} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <span >{called.solution}%</span>
                </Grid>
              </Grid>
            </Grid>)}
          </Grid>
        </Grid>)}
      </Grid>
    </Grid>
  )

})

export default injectIntl(ItemCard);