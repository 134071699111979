import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, Box } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import * as componentActions from '../../redux/actions/calledclassifications';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import If, { Else } from '../../components/conditionals/if'

class CalledClassificationRegistration extends Component {

  constructor(props) {
    super(props);
    const { userSession, calledclassifications } = this.props;
    this.globalAdm = false;
    this.AdmOrganization = false;
    this.isCoordenator = false;
    this.isClient = true;
    this.isTec = true
    this.authenticatedUserID = userSession.user.id;
    this.noteassigned = null
    this.noterequest = null

    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }
    
    if(!calledclassifications || (calledclassifications && !calledclassifications.calledclassification)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype != 2) {
        this.isTec = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
      if (item.role.roletype == 1) {
        this.AdmOrganization = true;
      }
      if (item.role.roletype == 4) {
          this.isCoordenator = true;
      }
    }
    );

    console.log("=== userSession ===")
    console.log(userSession.user.userrole)
    console.log(this.isClient)
    
    //console.log(calledclassifications.calledclassification.calledclassification);
    this.initialState = {
      assigneduserid: calledclassifications.calledclassification.assigneduserid,
      updatedUser: userSession.user.id,
      id: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.id : calledclassifications.calledclassification.CalledClassificationsId,
      CalledId: calledclassifications.calledclassification.CalledId,
      CalledText: calledclassifications.calledclassification.CalledText,
      AssignedUserId: calledclassifications.calledclassification.AssignedUserId,
      RequestingUserId: calledclassifications.calledclassification.requestinguserid,
      RequestingUserName: calledclassifications.calledclassification.requestinguser ? calledclassifications.calledclassification.requestinguser.name : "",
      AssignedUserText: calledclassifications.calledclassification.AssignedUserText,
      noteassigned: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.noteassigned : 0,
      observationassigned: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.observationassigned : '',
      noteRequest: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.noterequest : 0,
      observationRequest: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.observationrequest : '',
      OpeningDate: calledclassifications.calledclassification.OpeningDate,
      Type: calledclassifications.calledclassification.Type,
    };

    if(this.isClient) {
      this.initialState = {
        assigneduserid: calledclassifications.calledclassification.assigneduser ? calledclassifications.calledclassification.assigneduser.id : 0,
        updatedUser: userSession.user.id,
        id: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.id : calledclassifications.calledclassification.CalledClassificationsId,
        CalledId: calledclassifications.calledclassification.CalledId,
        CalledText: calledclassifications.calledclassification.CalledText,
        AssignedUserId: calledclassifications.calledclassification.AssignedUserId ? calledclassifications.calledclassification.AssignedUserId : calledclassifications.calledclassification.assigneduser ? calledclassifications.calledclassification.assigneduser.id : 0,
        RequestingUserId: calledclassifications.calledclassification.requestinguserid ? calledclassifications.calledclassification.requestinguserid : calledclassifications.calledclassification.requestinguser ? calledclassifications.calledclassification.requestinguser.id : 0,
        RequestingUserName: calledclassifications.calledclassification.requestinguser ? calledclassifications.calledclassification.requestinguser.name : "",
        AssignedUserText: calledclassifications.calledclassification.AssignedUserText,
        noteassigned: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.noteassigned : 0,
        observationassigned: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.observationassigned : '',
        noteRequest: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.noterequest : 0,
        observationRequest: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.observationrequest : '',
        OpeningDate: calledclassifications.calledclassification.OpeningDate,
        Type: calledclassifications.calledclassification.Type,
      };
    }

    console.log("=== InitialState ===")
    console.log(this.initialState)
    console.log(calledclassifications)

    this.state = {
      calledclassification: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      CalledList: [],
      AssignedUserList: [],
      starRating: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.noteassigned : 0,
      starRatingRequest: calledclassifications.calledclassification.calledclassification ? calledclassifications.calledclassification.calledclassification.noterequest : 0,
      mnoteassigned: null,
      mnoterequest: null
    };
    // console.log("calledclassifications >>");
    // console.log(calledclassifications);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.checkTechnicalPermission = this.checkTechnicalPermission.bind(this)
    this.checkPermission = this.checkPermission.bind(this);
    this.resendEmailTech = this.resendEmailTech.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {

  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      calledclassification: {
        ...prevState.calledclassification,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    this.setState({ loading: true });
    const { calledclassification } = this.state;
    var data = "";

    if (this.isClient) {
      data = {
        adm: (this.globalAdm || this.AdmOrganization) ? true : false,
        Id: calledclassification.id, ObservationAssigned: calledclassification.observation,
        CalledId: calledclassification.CalledId, noteAssigned: calledclassification.noteassigned
      };

      // console.log(data);

      Api.put('/calledclassifications', calledclassification)
        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"called.classification.edit.classified"})
            });

            if(result.data.data) {
              if(result.data.data.noteassigned != null) {
                this.setState({ mnoteassigned: result.data.data.noteassigned })
              }
            }

            this.setState({ loading: false })

            this.componentDidMount();
          } else {
            this.setState({ loading: false })
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "operation.notAllowed" })
            });
          }
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
        });

    } else {
      const { calledclassifications } = this.props

      data = {
        Id: calledclassification.id,
        ObservationAssigned: calledclassifications.calledclassification.calledclassification.observationassigned != calledclassification.observation ? calledclassification.observation : "",
        noteAssigned: calledclassifications.calledclassification.calledclassification.noteassigned != calledclassification.noteassigned ? calledclassification.noteassigned : null,
        ObservationRequest: calledclassification.observationRequest,
        CalledId: calledclassification.CalledId,
        noteRequest: calledclassification.noteRequest,

      };

      Api.put('/calledclassifications/request', data)
        .then(result => {
          if (result.data.success) {
            this.setState({
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"called.classification.edit.classified"})
            });

            if(result.data.data) {
              if(result.data.data.noteassigned != null) {
                this.setState({ mnoterequest: result.data.data.noteassigned })
              }
            }

            this.setState({ loading: false })

            this.componentDidMount();
          } else {
            // console.log("Erro");
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "operation.notAllowed" })
            });
          }
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          this.setState({ loading: false })
        });
    }
  };

  async resendEmailTech(){
    const intl = this.props.intl;
    const { calledclassifications } = this.props;

     var calledId =  calledclassifications.calledclassification? calledclassifications.calledclassification.CalledId : 0
     var hirerId = calledclassifications.calledclassification? calledclassifications.calledclassification.hirerid : 0

     
    this.setState({ loading: true});
  
    
  
  Api.put(`feedback/resendFeedbackAssignedInside/${calledId}/${hirerId}`)
    .then(result => {
      if (result.data.success) {
  
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "success",
          notificationMessage: intl.formatMessage({id:"nps.email.rating.feedback.link.sent"}),
        });
  
        
      }
    })
    .catch(err => {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        disabledResendButton : true,
        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        
      });
    })
  
    
    
  
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      calledclassification: {
        ...prevState.calledclassification,
        [stateName]: value
      }
    }));

    if (stateName === 'CalledId') {
      this.setState(prevState => ({
        calledclassification: {
          ...prevState.calledclassification,
          ['CalledText']: text,
        }
      }));
    }

    this.setState({ loading: false });

  };

  checkPermission = () => {
    const { calledclassifications } = this.props
    const RequestingUserId = calledclassifications && calledclassifications.calledclassification && calledclassifications.calledclassification.calledclassification && calledclassifications.calledclassification.requestinguser && calledclassifications.calledclassification.requestinguser.id ? calledclassifications.calledclassification.requestinguser.id : 0
    if(calledclassifications && calledclassifications.calledclassification && calledclassifications.calledclassification.calledclassification && calledclassifications.calledclassification.calledclassification.noteassigned && calledclassifications.calledclassification.calledclassification.noteassigned > 0) {
      return false
    }

    if(this.state.mnoteassigned != null) {
      return false
    }

    if (this.authenticatedUserID != RequestingUserId) {
      return false
    } else {
      return true
    }
  }

  checkTechnicalPermission = () => {
    const { calledclassifications } = this.props
    const AssignedUserId = calledclassifications && calledclassifications.calledclassification && calledclassifications.calledclassification.calledclassification && calledclassifications.calledclassification.assigneduser && calledclassifications.calledclassification.assigneduser.id ? calledclassifications.calledclassification.assigneduser.id : 0
    if(calledclassifications && calledclassifications.calledclassification && calledclassifications.calledclassification.calledclassification && calledclassifications.calledclassification.calledclassification.noterequest && calledclassifications.calledclassification.calledclassification.noterequest > 0) {
      return false
    }

    if(this.state.mnoterequest != null) {
      return false
    }

    if (this.authenticatedUserID != AssignedUserId) {
      return false
    } else {
      return true
    }
  }

  render() {
    const { classes, headerMenu, calledclassifications } = this.props;
    const { calledclassification, value, setValue } = this.state;
    const intl = this.props.intl;

  
    

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{this.props.intl.formatMessage({id:"back"})}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="edit.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={intl.formatMessage({id:"tab.called.report.called.number"})}
                      name="CalledId"
                      value={calledclassification.CalledId}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={intl.formatMessage({ id: "called" })}
                      name="CalledText"
                      value={calledclassification.CalledText}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={intl.formatMessage({ id: "assigned" })}
                      name="AssignedUserText"
                      value={calledclassification.AssignedUserText}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={intl.formatMessage({ id: "client" })}
                      name="RequestingUserName"
                      value={calledclassification.RequestingUserName}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      variant="outlined"
                      label={intl.formatMessage({ id: "open.date" })}
                      name="OpeningDate"
                      value={calledclassification.OpeningDate}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={intl.formatMessage({ id: "type" })}
                      name="type"
                      value={calledclassification.Type}
                      disabled
                    />
                  </Grid>
                </Grid>
                  <Grid item xs={12} sm={4} />
                  <Grid item xs={12} sm={4} />
                  <Grid item xs={12} sm={4} />
                {this.isClient === true && (<Grid item xs={12} sm={12} container alignItems="flex-start" justify="center" direction="row">
                      <Box component="fieldset" mb={3} borderColor="transparent">
                        <Typography component='h1' variant='h5'>&nbsp;&nbsp;&nbsp;{<FormattedMessage id="csat.requester.to.assigned" />}</Typography><br />
                        <Rating
                          style={{ marginLeft: 45 }}
                          name="rating"
                          required
                          readOnly={ !this.checkPermission() }
                          value={this.state.calledclassification.noteassigned}
                          onChange={(event, newValue) => {
                            this.setState(prevState => ({
                              calledclassification: {
                                ...prevState.calledclassification,
                                ['noteassigned']: newValue,
                              }
                            }));
                          }}
                        />
                      </Box>
                    </Grid>)}
                    {this.isClient === true && (<Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: "observation.requester" })}
                        variant='outlined'
                        name='observationassigned'
                        onChange={this.handleInputChange}
                        value={calledclassification.observationassigned}
                        multiline
                        disabled={ !this.checkPermission() }
                        rows="8"
                      />
                    </Grid>)}
                  {(this.isClient === false && this.isTec === true) && (
                      <Grid item xs={12} sm={12} container alignItems="flex-start" justify="center" direction="row">
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <Typography component='h1' variant='h5'>&nbsp;&nbsp;&nbsp;{<FormattedMessage id="csat.assigned.to.requester" />}</Typography><br />
                          <Rating
                            style={{ marginLeft: 45 }}
                            name="ratingRequest"
                            required
                            readOnly={ !this.checkTechnicalPermission() }
                            value={this.state.calledclassification.noteRequest}
                            onChange={(event, newValue) => {
                              this.setState(prevState => ({
                                calledclassification: {
                                  ...prevState.calledclassification,
                                  ['noteRequest']: newValue,
                                }
                              }));
                            }}
                          />
                        </Box>
                      </Grid>)}
                    {(this.isClient === false && this.isTec === true) && (
                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          label={intl.formatMessage({ id: "observation.assigned" })}
                          variant='outlined'
                          name='observationRequest'
                          onChange={this.handleInputChange}
                          value={calledclassification.observationRequest}
                          multiline
                          disabled={ !this.checkTechnicalPermission() }
                          rows="8"
                        />
                      </Grid>
                  )}
                  {(this.isClient === false && this.isTec === false) &&
                    (<Grid item xs={12} sm={12} container alignItems="flex-start" justify="center" direction="row" style={{ marginTop: 15 }}>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <Typography component='h1' variant='h5'>&nbsp;&nbsp;&nbsp;{<FormattedMessage id="csat.requester.to.assigned" />}</Typography><br />
                          <Rating
                            style={{ marginLeft: 45 }}
                            name="rating"
                            required
                            readOnly={ !this.checkPermission() }
                            value={this.state.calledclassification.noteassigned} />
                        </Box>
                      </Grid>)}
                      {(this.isClient === false && this.isTec === false) &&
                    (<Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          label={intl.formatMessage({ id: "observation.requester" })}
                          variant='outlined'
                          name='observationassigned'
                          disabled
                          value={calledclassification.observationassigned}
                          multiline
                          rows="8"
                        />
                      </Grid>)}
                  {(this.isClient === false && this.isTec === false) &&
                    (<Grid item xs={12} sm={12} container alignItems="flex-start" justify="center" direction="row" style={{ marginTop: 15 }}>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <Typography component='h1' variant='h5'>&nbsp;&nbsp;&nbsp;{<FormattedMessage id="csat.assigned.to.requester" />}</Typography><br />
                          <Rating
                            style={{ marginLeft: 45 }}
                            name="ratingRequest"
                            required
                            readOnly={ !this.checkTechnicalPermission() }
                            value={this.state.calledclassification.noteRequest}
                            onChange={(event, newValue) => {
                              this.setState(prevState => ({
                                calledclassification: {
                                  ...prevState.calledclassification,
                                  ['noteRequest']: newValue,
                                }
                              }));
                            }}
                          />
                        </Box>
                      </Grid>)}
                  {(this.isClient === false && this.isTec === false) &&
                    (<Grid item xs={12} sm={12} style={{ marginBottom: 15 }}>
                        <TextField
                          fullWidth
                          label={intl.formatMessage({ id: "observation.assigned" })}
                          variant='outlined'
                          name='observationRequest'
                          onChange={this.handleInputChange}
                          value={calledclassification.observationRequest}
                          multiline
                          disabled={ !this.checkTechnicalPermission() }
                          rows="8"
                        />
                      </Grid>)}
                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                {(this.isClient === false && this.isTec === false)  &&
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      xl={6}
                      align-items-xs-center={"true"}
                      
                    >
                      <Button
                        
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={this.resendEmailTech}
                        
                      >
                        {<FormattedMessage id="resend.csat.assigned.to.requester" />}
                      </Button>
                    </Grid>
                  }
                  <Grid item xs={12} sm={6} md={4} xl={4}>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      disabled={ !(this.checkPermission() || this.checkTechnicalPermission()) }
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
CalledClassificationRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  calledclassifications: state.calledclassifications
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledClassificationRegistration)));
