export function toggle(open) {
  return {
    type: 'TOGGLE',
    open,
  }
}
export function reload(refresh) {
  return {
    type: 'RELOAD',
    refresh,
  }
}
