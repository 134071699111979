import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import { PapiroConsole } from '../../utils/papiroConsole';
import { QueuePlayNext, DeleteForever } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import * as CalledService from "../../services/called.service";
import * as CalledOrganizationChecklistService from '../../services/calledorganizationchecklist.service'
import { getDepartamentByUserId } from '../../services/user.service'
import { getDepartamentsByOrganizationCategoryIdNew, getCategoriesByOrganizationDepartamentId } from '../../services/organizationcategory.service';
import * as OrgDepartamentService from '../../services/organizationdepartament.service';
import CustomizedSnackbars from "../../components/material-snackbars";
import SimpleSelect from "../../components/select/simple";
import SimpleAutoCompleteByName from '../../components/auto-complete/autocomplete-by-name';
import SimpleDatePicker from "../../components/date-pickers/inline";
import Dropzone from "react-dropzone";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleTreeView from "../../components/tree-view/tree";
import SimpleAutoComplete from "../../components/auto-complete/autocomplete";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import { FormattedMessage, injectIntl } from "react-intl";
import ModalRating from "../../components/ModalRating";
import If from '../../components/conditionals/if'
import Autocomplete from "@mui/material/Autocomplete";
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6

class CalledRegistration extends Component {
  constructor(props) {
    super(props);

    const { userSession } = this.props;

    //this.isClient = userSession.user.role.roleType === 3;
    this.categoryId = null;
    this.isClient = true;
    this.modalRating = null;
    this.idCalled = null;
    this.descriptionCalled = undefined;
    this.requiredratingcalleds = false

    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    this.userid = userSession.user.id;
    //console.log(JSON.stringify(userSession.user.userrole));
    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
    });
    //console.log(userSession);

    // console.log(userSession.user.organizationusers[0]);
    if (
      userSession.user.organizationusers[0].organization.organizationsettings ==
      null
    ) {
      userSession.user.organization.organizationsettings = {
        createdUser: userSession.user.id,
        createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
        OrganizationId: 0,
        OrganizationText: "",
        TypeId: 0,
        TypeText: "",
        OriginRequestId: 0,
        OriginRequestText: "",
        StatusId: 0,
        StatusText: "",
        estimatedamountdays: 0,
        roleclientcanselectcategory: 0,
        canclientselectpatrimonyincalled: 0,
        canclientselecttypeincalled: 0,
        estimatedanwserhours: 24,
        restrictviewcalleddepartament: false,
      };
    }

    this.organizationId = null;
    this.userDepartamentId = 0;
    this.roleClientCanSelectCategory = false;
    this.canClientSelectPatrimonyInCalled = false;
    this.canClientSelectTypeInCalled = false;
    this.restrictviewcalleddepartament = false;
    this.canselectpatrimonyincalled = false;

    if (userSession.user.organizationdepartamentid && userSession.user.organizationdepartamentid > 0) {
      this.userDepartamentId = userSession.user.organizationdepartamentid;
    }

    /*if (userSession.user.organizationusers[0] && userSession.user.organizationusers.length <= 1) {
      this.organizationId = userSession.user.organizationusers[0].organizationid;
      this.canselectpatrimonyincalled = userSession.user.organizationusers[0].organization.organizationsettings.canselectpatrimonyincalled;
      this.roleClientCanSelectCategory = userSession.user.organizationusers[0].organization.organizationsettings.roleclientcanselectcategory;
      this.restrictviewcalleddepartament = userSession.user.organizationusers[0].organization.organizationsettings.restrictviewcalleddepartament
    }*/

    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }
  
    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]

    const defaultvalue = { id: 0, name: "", parentname: "" };

    this.initialCalledState = {
      requestingUser: userSession.user.id,
      createdUser: userSession.user.id,
      organization: this.organizationId,
      type: 0,
      CategoryId: defaultvalue,
      categoryText: "",
      dueDate: moment()
        .add("days", 1)
        .format("YYYY/MM/DD HH:mm:ss"),
      title: "",
      status: 0,
      description: "",
      AddressId: 0,
      originRequest: 0,
      observantUser: 0,
      assignedUser: 0,
      patrimonyId: "",
      departamentId: 0
    };

    this.levelCategoriesInitialState = {
      rootCategoriesList: [],
    }

    this.state = {
      countLoading: 0,
      called: this.initialCalledState,
      files: [],
      loading: true,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      defaultCategory: defaultvalue,
      typesList: [],
      categoriesList: [],
      categoriesListAll: [],
      slaList: [],
      technicalUsersList: [],
      usersList: [],
      clientUsersList: [],
      organizationAddressesList: [],
      organizationsList: [],
      patrimonyList: [],
      userId: userSession.user.id,
      calledList: [],
      organizationFieldList: [],
      modalRatingOpen: false,
      userDepartamentId: 0,
      DepartamentList: [],
      newCategoryDepartament: false,
      organizationLength: this.organizationId,
      organizationDisabled: false,
      locationDisabled: false,
      levelCategories: this.levelCategoriesInitialState,
      levelCategoryId: 0,
      categoryChildrens: [ defaultvalue ],
      categoryLists: [],
      canselectorganizationcategoriesbylevels: false,
      addresstype: 1,
      disabledSaveButton: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
	  this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getItens = this.getItens.bind(this);
    this.convertHtmlOption = this.convertHtmlOption.bind(this)
    this.setCountLoading = this.setCountLoading.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.setMessage = this.setMessage.bind(this)
    this.createUi = this.createUi.bind(this)
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      await this.setState({ countLoading: 0, });
      this.LoadUI();
    }
  }
  setCountLoading() {


    /*this.setState({countLoading: this.state.countLoading+1,});
    console.log(this.state.countLoading)
    console.log(this.state.countLoading)
    if(this.state.countLoading >=8 ){
      this.setState({
          loading: false,
      });
    }*/

  }
  async componentDidMount() {
    this.LoadUI();
  };
  LoadUI = async () => {

    this.setState({
      loading: true,
    });

    await this.getOrganizations();
    await this.getTypes();
    await this.getSLAs();
    await this.getDepartamentByUserId();
    console.log(this.organizationId)
    console.log("this.organizationId")
    if (this.organizationId != null) {
      //await this.getCategoriesByOrganization(this.organizationId);
      await this.getCategoryOrganizationDefault(this.organizationId);
      await this.getOrganizationFields(this.organizationId);
      await this.getPatrimonios(this.organizationId);
      await this.getOrganizationAddresses();
      await this.getOrganizationSettings();
      await this.getItens();
    }

    //this.getParentCalleds();

    if (this.roleClientCanSelectCategory == 0) {
      this.setState((prevState) => ({
        called: {
          ...prevState.called,
          CategoryId: this.state.defaultCategory,
          categoryText: this.state.defaultCategory.parentname,
        },
      }));
      await this.getDepartamentsByOrganizationCategoryId(this.state.defaultCategory.id)
    }

    this.setState({
      loading: false,
    });
  }

  getPatrimonios = async (id) => {

    const result = await CalledService.getPatrimonyByOrg(id);

    if (result.success) {
      this.setState({ patrimonyList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
      this.setCountLoading();
    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      this.setCountLoading();
      Api.kickoff(result);
    }
  }

  getDepartamentsByOrganizationCategoryId = async (id) => {

    var result = await getDepartamentsByOrganizationCategoryIdNew(id);
    if (result.success) {
      if (result.data && result.data.length > 0) {
        if (this.restrictviewcalleddepartament === true && this.state.userDepartamentId && this.state.userDepartamentId.id && this.state.userDepartamentId.id > 0) {
          console.log("getDepartamentsByOrganizationCategoryId 1")
          var updatedList = result.data.filter(item => item.id == this.state.userDepartamentId.id)
          if (updatedList)
            updatedList = updatedList[0]

          if (result.data.length == 1) {
            this.setState(prevState => ({
              called: {
                ...prevState.called,
                departamentId: result.data[0]
              }
            }))
          }

          this.setState({ loading: false, DepartamentList: [updatedList] });
        } else {
          console.log("getDepartamentsByOrganizationCategoryId 2")
          if (this.state.called.departamentId && this.state.called.departamentId.id) {
            console.log("getDepartamentsByOrganizationCategoryId 3")
            var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)
            if (updatedList)
              updatedList = updatedList[0]

            if (result.data.length == 1) {
              this.setState(prevState => ({
                called: {
                  ...prevState,
                  departamentId: result.data[0]
                }
              }))
            }

            this.setState({ loading: false, DepartamentList: updatedList && updatedList.length > 1 ? updatedList.sort((a, b) => a.name.localeCompare(b.name)) : updatedList });
          } else {
            console.log("getDepartamentsByOrganizationCategoryId 4")
            this.setState({ loading: false, DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
          }
        }
        this.setState({ newCategoryDepartament: false })
      } else {
        console.log("getDepartamentsByOrganizationCategoryId 5")
        console.log(this.organizationId)
        this.getOrganizationDepartaments(this.organizationId)
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  getOrganizationDepartaments = async (id) => {
    const { userSession } = this.props;

    //this.setState({ loading: true });
    
    var result = await OrgDepartamentService.getOrganizationsDepartaments(id);
    if (result.success) {
      if (this.state.called.departamentId && this.state.called.departamentId.id) {
        var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)

        this.setState({
          DepartamentList: updatedList.length > 0 ? (updatedListAtt && updatedListAtt.length > 1 ? updatedListAtt.sort((a, b) => a.name.localeCompare(b.name)) : updatedListAtt) : (result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data),
          newCategoryDepartament: true
        });
      } else {
        let userDepartaments = this.state.userDepartamentId
        console.log(userDepartaments)
        console.log("getDepartamentsByOrganizationCategoryId 6");
        console.log(result)
        if (userDepartaments != null) {
          var updatedListAtt = result.data.filter(item => item.id == userDepartaments.id)
          console.log(updatedListAtt)
          this.setState({
            DepartamentList: updatedListAtt.length > 0 ? (updatedListAtt && updatedListAtt.length > 1 ? updatedListAtt.sort((a, b) => a.name.localeCompare(b.name)) : updatedListAtt) : (result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data),
            newCategoryDepartament: true
          }, () => {
            if(updatedListAtt.length > 0) {
              this.setState(prevState => ({
                called: {
                  ...prevState.called,
                  departamentId: updatedListAtt[0]
                }
              }))
            }
          });
        } else {
          this.setState({
            DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data,
            newCategoryDepartament: true
          });
        }
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        newCategoryDepartament: false,
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  getDepartamentByUserId = async () => {
    const { userSession } = this.props;
    this.setState({ loading: true });

    var result = await getDepartamentByUserId(userSession.user.id);
    if (result.success) {
      this.setState({ loading: false, userDepartamentId: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  }

  getTypes = async () => {

    const result = await CalledService.getTypes();

    if (result.success) {
      this.setState({ typesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
      this.setCountLoading();
    } else {
      const intl = this.props.intl;
      this.setState({

        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
      this.setCountLoading();
    }
  }

  async getSLAs() {
    this.setState({ loading: true });

    const result = await CalledService.getSLAs();

    if (result.success) {
      this.setState({ slaList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({

        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
    }
  }

  getCategories = async () => {
    this.setState({ loading: true });

    var result = await CalledService.getAllCategories();

    if (result.success) {
      this.setState({ loading: false, categoriesListAll: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
    }

    result = await CalledService.getCategories();

    if (result.success) {
      this.setState({ loading: false, categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
    }
  }

  getCategoriesByOrganization = async (organizationId) => {

    if (organizationId == null) {
      this.setCountLoading();
      return null;
    }

    const { userSession } = this.props;

    if (this.restrictviewcalleddepartament === true && this.state.userDepartamentId && this.state.userDepartamentId.id) {
      console.log("=== CatTest ===")
      var result = await getCategoriesByOrganizationDepartamentId(this.state.userDepartamentId.id)
      if (result.success) {
        this.setState({ categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
        this.setCountLoading();
      } else {
        const intl = this.props.intl;
        this.setState({

          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        this.setCountLoading();
      }
    } else {
      var result = await CalledService.getCategoriesByOrganization(
        organizationId
      );

      if (result.success) {
        this.setState({ categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
        this.setCountLoading();
      } else {
        const intl = this.props.intl;
        this.setState({

          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        this.setCountLoading();
      }
    }
  }

  async getCategoryOrganizationDefault(organizationId) {
    if (organizationId == null) {
      this.setCountLoading();
      return null;
    }

    var result = await CalledService.getCategoryOrganizationDefault(
      organizationId
    );

    if (result.success) {
      // console.log(result.data);
      this.setState({ defaultCategory: result.data });
      this.setCountLoading();
    } else {
      const intl = this.props.intl;
      this.setState({

        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      this.setCountLoading();
    }
  }

  async getOrganizations() {
    this.setState({ loading: true });
    const { userSession } = this.props
    let orgData = []
    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      orgData = userSession.user.userorganizations
    } else {
      const result = await CalledService.getOrganizations()
      if (result.success) {
        orgData = result.data

        if(result.data && result.data.length > 1)
          this.setState({ organizationDisabled: false })
        else
          this.setState({ organizationDisabled: true })
      }
      else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);
        this.setCountLoading();
      }
    }

    if(orgData != null) {
      this.setState({ loading: false, organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
      
      if(orgData && orgData.length == 1) {
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            organization: orgData[0]
          },
          organizationDisabled: true,
        }))

        this.organizationId = orgData[0].id;
        
        await this.getUsersExceptClients(orgData[0].id);
        await this.getRequestingUsers(orgData[0].id);
      }

      this.setCountLoading();
    } else {
      orgData = []
      this.setState({ loading: false, organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
      this.setCountLoading();
    }
  }

  async getRequestingUsers(organizationId) {
    this.setState({ loading: true });

    const result = await CalledService.getRequestingUsers(organizationId);

    if (result.success) {
      this.setState({ clientUsersList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
    }
  }

  async getTechnicalUsers(organizationId) {
    this.setState({ loading: true });

    const result = await CalledService.getTechnicalUsers(organizationId);

    if (result.success) {
      this.setState({ loading: false, technicalUsersList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
      // console.log(result);
      // console.log("aqui");
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
    }
  }

  async getUsersExceptClients(organizationId) {

    const result = await CalledService.getUsersExceptClients(organizationId);

    if (result.success) {
      this.setState({ usersList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
    }
  }

  getOrganizationAddresses = async () => {

    const result = await CalledService.getOrganizationAddresses(
      this.organizationId
    );

    if (result.success) {
      this.setState({ organizationAddressesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });

      if(result.data && result.data.length == 1) {
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            AddressId: result.data[0]
          },
          locationDisabled: true
        }))
      }

      if(result.data && result.data.length > 1)
          this.setState({ locationDisabled: false })
      else
        this.setState({ locationDisabled: true })

      this.setCountLoading();
    } else {
      if (result.response) {
        const intl = this.props.intl;
        this.setState({

          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response &&
              result.response.data &&
              result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
      } else {
        const intl = this.props.intl;
        this.setState({

          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response &&
              result.response.data &&
              result.response.data.errors
              ? result.response.data.errors[0]
              : "Erro ao processar a solicitação: " + result.response,
        });
      }
      this.setCountLoading();
    }
  }

  createUi = () => {
    const intl = this.props.intl;

    return this.state.categoryChildrens.map((el, i) =>
      <Grid key={i} item xs={4} sm={4}>
        { i > 0 ?
        (<Grid container spacing={4}>
          <Grid item xs={1} sm={1} style={{ display: 'flex', alignItems: 'center', fontSize: '2em' }}>&gt;</Grid>
          <Grid item xs={10} sm={10}>
            <SimpleAutoCompleteByName
              intl={intl}
              label=""
              options={this.state.categoryLists[i] || []}
              stateName={`lcat-${i}`}
              name={`lcat-${i}`}
              changeSelect={this.handleChangeCategoryChildrens.bind(this, i)}
              selected={el}
               />
          </Grid>
        </Grid>)
        :
        (<Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
          <SimpleAutoCompleteByName
            intl={intl}
            label=""
            options={this.state.categoryLists[i] || []}
            stateName={`lcat-${i}`}
            name={`lcat-${i}`}
            changeSelect={this.handleChangeCategoryChildrens.bind(this, i)}
            selected={el}
             />
          </Grid>
        </Grid>
        )}
      </Grid>
    )
  }

  handleAddChildrenCategory = (listCategory) => {
    this.setState(prevState => ({
      categoryChildrens: [
        ...prevState.categoryChildrens,
        { id: 0, name: '' }
      ],
      categoryLists: [
        ...prevState.categoryLists,
        listCategory
      ]
    }))
  }

  handleRemoveChildrenCategory = async (i) => {
    this.setState({ loading: true })

    let categoryChildrens = [...this.state.categoryChildrens]
    let categoryLists = [...this.state.categoryLists]
    categoryChildrens.splice(i+1)
    categoryLists.splice(i+1)

    await this.getPreviousCategory(i)

    this.setState({ categoryChildrens, categoryLists })
    this.setState({ loading: false })
  }

  getPreviousCategory = async (i) => {
    if(i > 0) {
      if (this.state.categoryChildrens[i-1] !== undefined) {
        if(this.state.categoryChildrens[i-1].cancreatecalled === true) {
          this.categoryId = this.state.categoryChildrens[i-1]
          this.setState(prevState => ({
            called: {
              ...prevState.called,
              categoryId: this.state.categoryChildrens[i-1]
            }
          }))
          await this.categoryCanCreateCalled(this.state.categoryChildrens[i-1])
        } else {
          await this.getPreviousCategory(i-1)
        }
      }
    } else {
      if (this.state.categoryChildrens[i] !== undefined && this.state.categoryChildrens[i].cancreatecalled === true) {
        this.categoryId = this.state.categoryChildrens[i]
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            categoryId: this.state.categoryChildrens[i]
          }
        }))
      } else {
        this.categoryId = null
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            categoryId: null
          }
        }))
      }
    }
  }

  async handleChangeCategoryChildrens(i, stateName, value) {
    this.setState(prevState => ({
      called: {
        ...prevState.called,
        categoryId: value,
      },
      loading: true
    }))
    
    let categoryChildrens = [...this.state.categoryChildrens]
    if (i < categoryChildrens.length -1 )
      this.handleRemoveChildrenCategory(i)
    else {
      categoryChildrens[i] = value
      this.setState({ categoryChildrens })
    }

    if(value && value.id && value.id > 0) {
      await this.getCategoryChildrenByParentOrganizationCategoryId(value.id, stateName)
      if(value.cancreatecalled === true) {
        await this.categoryCanCreateCalled(value)
      }
    }
    this.setState({ loading: false })
  }

  categoryCanCreateCalled = async (value) => {
    this.categoryId = value;
    let data = await CalledService.getTypeById(value.id, this.organizationId);
    if (data.data.typeid == null) {
      data.data.typeid = 0;
    }
    let initDate = moment().endOf('day').add('days', 1).format("YYYY/MM/DD HH:mm:ss")
    let newDate = initDate
    if(value.useslatime == true) {
      if(value.organizationsla && value.organizationsla.solutiontime && value.organizationsla.solutiontime > 0) {
        newDate = moment().add(value.organizationsla.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
        //this.changeDueDateByOrganizationCagetoryMessage(1, moment().add(value.organizationsla.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
      } else if(value.solutiontime && value.solutiontime > 0) {
        newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
        //this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
      } else
        newDate = initDate
    } else if(value.useslatime == false) {
      if(value.solutiontime && value.solutiontime > 0) {
        newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
        //this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
      } else
        newDate = initDate
    } else
      newDate = initDate

    this.setState(prevState => ({
      called: {
        ...prevState.called,
        ["CategoryId"]: value,
        /*type: value.typeid && value.typeid > 0 ? value.typeid : this.state.called.type && this.state.called.type > 0 ? this.state.called.type : 0,
        typeDisabled: false,*/
        dueDate: newDate
      }
    }));
  }

  getCategoryChildrenByParentOrganizationCategoryId = async (parentId, stateName = '') => {
    const intl = this.props.intl;
    
    var result = await CalledService.getByParentOrganizationCategoryId(parentId);
    if (result && result.success) {
      if(result.data && result.data.length > 0) {
        this.handleAddChildrenCategory(result.data)
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  getRootCategoriesByOrganization = async (organizationId) => {
    const intl = this.props.intl;
    var result = await CalledService.getRootCategoriesByOrganization(organizationId);
    if (result.success) {
      if (this.isTec == false) {
        if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
          if (this.AdmOrganization) {
            const intl = this.props.intl;
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"}),
              notificationLinkMove: "organizationcategories"
            });
          } else {
            const intl = this.props.intl;
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"})
            });
          }
        }
      }
      this.setState({
        levelCategories: {
          rootCategoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
        },
        categoryLists: [
          result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
        ]
      });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  async getOrganizationSettings() {

    let infos;
    let categoryLevel = false
    await Api.get(`/organizationsettings/organization/${this.organizationId}`)
      .then((result) => {
        if (result.data.success) {
          this.requiredratingcalleds = result.data.data.requiredratingcalleds
          infos = result.data.data;
          this.canselectpatrimonyincalled = result.data.data.canselectpatrimonyincalled;
          this.roleClientCanSelectCategory = result.data.data.roleclientcanselectcategory;
          this.canClientSelectPatrimonyInCalled = result.data.data.canclientselectpatrimonyincalled;
          this.canClientSelectTypeInCalled = result.data.data.canclientselecttypeincalled;
          this.restrictviewcalleddepartament = result.data.data.restrictviewcalleddepartament
          categoryLevel = result.data.data.canselectorganizationcategoriesbylevels
          this.setState({
            canselectorganizationcategoriesbylevels: result.data.data.canselectorganizationcategoriesbylevels
          })
        }
      })
      .catch((err) => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        this.setCountLoading();
      });
    if(this.organizationId && this.organizationId > 0) {
      if(categoryLevel === true)
        await this.getRootCategoriesByOrganization(this.organizationId);
      else
        await this.getCategoriesByOrganization(this.organizationId)
    }
    return infos;
  }

  async getOrganizationSLA() {
    this.setState({ loading: true });
    let infos;
    await Api.get(`/organizationslas/organization/${this.organizationId}`)
      .then((result) => {
        if (result.data.success) {
          infos = result.data.data;
        }
      })
      .catch((err) => {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
      });
    return infos;
  }

  async getParentCalleds() {
    this.setState({ loading: true });

    var result = await CalledService.getParentCalled();

    if (result.success) {
      let parents = result.data.map((item) => {
        return {
          ...item,
          name: `${item.id}: ${item.title}`,
          parentname: `${item.id}: ${item.title}`
        };
      });
      await this.setState({ parentCalledsList: parents });
      // console.log(this.state.parentCalledsList);
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  async getOrganizationFields(organizationId) {
    if (organizationId == null) {
      this.setCountLoading();
      return null;
    }

    var result = await CalledOrganizationChecklistService.getActiveOrganizationFieldsByOrganizationCustomerEdit(organizationId);

    if (result.success) {
      this.setState({ organizationFieldList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
      this.setCountLoading();
    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
      this.setCountLoading();
    }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      called: {
        ...prevState.called,
        [name]: value,
      },
    }));
  }
  handleChangeEditor(value) {
    //this.setState({ description: value });

	this.setState(prevState => ({
		called: {
		  ...prevState.called,
		  description: value
		}
	  }));


  }

  convertHtmlOption = value => {
    let body = value
    let temp = document.createElement("div")
    temp.innerHTML = body
    let sanitized = temp.textContent || temp.innerText

    PapiroConsole.log("=== convertHtmlOption ===")
    PapiroConsole.log(temp.textContent)
    PapiroConsole.log(temp.textContent.length)
    PapiroConsole.log(temp.innerText)
    PapiroConsole.log(temp.innerText.length)
    
    return sanitized
  }

  checkSubmit = () => {
    const intl = this.props.intl;

    let calledDesc = this.convertHtmlOption(this.state.called.description)
    calledDesc = calledDesc.trim()
    if(calledDesc == null || (calledDesc != null && calledDesc.length == 0 )) {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        loadingTwo: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.called.description"})
      });
      return false;
    }

    return true
  }

  handleSubmit = async e => {
    e.preventDefault();

    await this.setState({ disabledSaveButton: true })

    if(this.checkSubmit()) {
      let data = new FormData();

      data.append("organization", this.organizationId);
      const clientInfos = await this.getOrganizationSettings();
      
      if (clientInfos) {
        await this.setState({ loading: true });
        //console.log(clientInfos);
        this.setState((prevState) => ({
          called: {
            ...prevState.called,
            originRequest: clientInfos.originrequest.id,
            sla: this.state.called.CategoryId.organizationslaid,
            status: clientInfos.status.id,
            type: this.canClientSelectTypeInCalled != 0 ? this.state.called.type.id : clientInfos.type && clientInfos.type.id ? clientInfos.type.id : 0,
            OrganizationCategory: this.state.called.CategoryId.id
              ? this.state.called.CategoryId.id
              : 0,
          },
          loading: true
        }));

        this.state.files.forEach((file) => data.append("filesUpload", file));
        let calledOrganizationFieldList = []

        for (let key in this.state.called) {
          if (key === "dueDate") {
            data.append(
              key,
              moment(this.state.called[key]).format("YYYY/MM/DD HH:mm:ss")
            );
          } else if (
            key
              .toString()
              .toLowerCase()
              .indexOf("categoryid") > -1
          ) {
            data.append("Category", this.state.called[key].id);
          } else if (key.toString().toLowerCase().indexOf('addressid') > -1) {
            if (this.state.called[key].id) {
              data.append("AddressId", this.state.called[key].id);
            } else {
              data.append("AddressId", 0);
            }
          } else if (key.toString().toLowerCase().indexOf('departamentid') > -1) {
            let deptVal = this.state.called[key].id
            if (deptVal == null || typeof deptVal === 'undefined')
              deptVal = 0
            data.append("OrganizationDepartamentId", deptVal);
          } else {
            if (key.toString().toLowerCase().indexOf("ofield-") > -1) {
              calledOrganizationFieldList.push(this.state.called.key)
            } else
              data.append(key, this.state.called[key]);
          }
        }

        let list = this.state.called["patrimonyId"];

        if (list != null && list != "") {
          let result = "";

          for (let value in this.state.called["patrimonyId"]) {
            result = this.state.called["patrimonyId"][value].id + "," + result;
          }

          data.append("calledOrganizationPatrimonyId", result);
        }
        
        data.append("isClientCreator", true);
        data.append("newCategoryDepartament", this.state.newCategoryDepartament)
        data.append("priorityid", 1)

        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        let orgFields = ""
        let orgFieldValues = ""
        Object.keys(this.state.called).forEach((key) => {
          if (key.includes("ofield-")) {
            let val = this.state.called[key]
            let explode = key.split('-')
            let organizationFieldId = explode[1];

            orgFields = `${organizationFieldId},${orgFields}`
            orgFieldValues = `${val},${orgFieldValues}`
            data.append("OrgFieldIdsList", parseInt(organizationFieldId))
            data.append("OrgFieldValuesList", val)
          }
        })
        data.append("orgfields", orgFields)
        data.append("orgfieldvalues", orgFieldValues)

        Api.post("/calleds", data, config)
          .then((result) => {
            if (result.data.success) {
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({ id: "add.success" }),
                called: this.initialCalledState,
                files: [],
                disabledSaveButton: false
              });
            }
          })
          .catch((err) => {
            const intl = this.props.intl;
            this.setState({
              disabledSaveButton: false,
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
          });
      } else {
        const intl = this.props.intl;
        this.setState({
          disabledSaveButton: false,
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"called.registration.client.organization.no.config.contact.admin"}),
        });
      }
    }
  };

  setLoading = (isLoad) => {
    this.setState({
      loading: isLoad
    })
  }

  setMessage = (open, variant, message) => {
    this.setState({
      openNotification: open,
      notificationVariant: variant,
      notificationMessage: message
    });
  }

  getItens = async (page = 1) => {

    let idOrganization = null;
    let unratedCalledsList = []
    const { userSession } = this.props;

    let url = this.organizationId != null && this.organizationId > 0 ? `/calleds/classifications/organization/${this.organizationId}/user/${this.state.userId}` : `/calleds/classifications/all/${this.state.userId}`;
    
    await Api.get(url)
      .then((result) => {
        if (result.data.success) {
          PapiroConsole.log("=== result.data.success ===")
          PapiroConsole.log(result.data.success)
          result.data.data.forEach((element) => {
            if (this.modalRating === null) {
              PapiroConsole.log(element)
              if (element.calledclassification == null) {
                PapiroConsole.log("element.calledclassification === null")
                unratedCalledsList.push(element)

                let technicianCalled = null
                if (element.assigneduser !== null) {
                  technicianCalled = element.assigneduser.name
                }

                this.idCalled = element.id;
                this.descriptionCalled = element.description;
                idOrganization = element.organizationid;
              }
            }
          });
          
          PapiroConsole.log(`unratedCalledsList -> ${unratedCalledsList.length} items`)
          if (unratedCalledsList.length !== 0) {
            this.setState({ modalRatingOpen: true }, () => {
                this.modalRating = <ModalRating
                    data={unratedCalledsList}
                    onClose={() => this.closeModalRating()}
                    requiredratingcalleds={this.requiredratingcalleds}
                    organizationId={this.organizationId}
                    createdUser={this.userid}
                    setLoading={this.setLoading}
                    setMessage={this.setMessage}
                  />
            });
          }
            this.setCountLoading();
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data &&
                result.data.response &&
                result.data.response.data &&
                result.data.response.data.errors &&
                result.data.response.data.errors[0]
                ? result.data.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
          this.setCountLoading();

        }
      })
      .catch((err) => {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response &&
              err.response.data &&
              err.response.data.errors &&
              err.response.data.errors[0]
              ? err.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(err);
        this.setCountLoading();

      });
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeDueDateByOrganizationCagetoryMessage = (type, date) => {
    const intl = this.props.intl;
    switch (type) {
      case 1:
        this.setState({
          openNotification: true,
          notificationVariant: "info",
          notificationMessage: intl.formatMessage({id:"called.registration.notification.date.changed.sla.category"})
        });
        break;
      case 2:
        this.setState({
          openNotification: true,
          notificationVariant: "info",
          notificationMessage: intl.formatMessage({id:"called.registration.notification.date.changed.sla.category"})
        });
        break;
      default:
        break;
    }
  }

  changeValues = async (stateName, value, text = "") => {
    this.setState({
      loading: true,
    });

    this.setState((prevState) => ({
      called: {
        ...prevState.called,
        [stateName]: value,
      },
    }));

    if (stateName === "organization") {

      await this.setState((prevState) => ({
        called: {
          ...prevState.called,
          CategoryId: { id: 0, name: "", parentname: "" },
          departamentId: 0,
          AddressId: 0
        },
      }));
      if(value && value.id && value.id > 0) {
        this.organizationId = value.id;

        await this.getUsersExceptClients(value.id);
        await this.getRequestingUsers(value.id);
        await this.getOrganizationDepartaments(value.id)
        await this.getOrganizationFields(value.id);
        //await this.getCategoriesByOrganization(value.id);
        await this.getPatrimonios(value.id);
        await this.getOrganizationAddresses();
        await this.getOrganizationSettings();
        await this.getItens();
      } else {
        this.setState({
          canselectorganizationcategoriesbylevels: false,
          levelCategories: this.levelCategoriesInitialState,
        })
      }
    } else if (stateName === "CategoryId") {
      this.categoryId = value;

      let initDate = moment().endOf('day').add('days', 1).format("YYYY/MM/DD HH:mm:ss")
      let newDate = initDate
      if(value) {
        if(value.useslatime == true) {
          if(value.organizationsla && value.organizationsla.solutiontime && value.organizationsla.solutiontime > 0) {
            newDate = moment().add(value.organizationsla.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
            //this.changeDueDateByOrganizationCagetoryMessage(1, moment().add(value.organizationsla.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
          } else if(value.solutiontime && value.solutiontime > 0) {
            newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
            //this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
          } else
            newDate = initDate
        } else if(value.useslatime == false) {
          if(value.solutiontime && value.solutiontime > 0) {
            newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
            //this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
          } else
            newDate = initDate
        } else
          newDate = initDate
      } else
        newDate = initDate

      this.setState((prevState) => ({
        called: {
          ...prevState.called,
          ["categoryText"]: text,
          ["departamentId"]: 0,
          dueDate: newDate
        },
      }));

      await this.getDepartamentsByOrganizationCategoryId(this.categoryId.id)
    }

    this.setState({
      loading: false,
    });
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  closeModalRating() {
    this.setState({
      modalRatingOpen: false,
    });
  }

  render() {
    const { classes, headerMenu } = this.props;
    const { called } = this.state;
    var categoriesId =
      "'0'," +
      this.state.categoriesListAll.map((item) => `'${item.id}'`).join(",");
    const intl = this.props.intl;

    if (this.state.modalRatingOpen === false) {
      this.modalRating = null;
    }

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    //array.forEach((element) => {});

    return (
      <div className={classes.root}>
        <HeaderMenu />
        {this.modalRating}
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="called.new" />}
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "organization" })}
                      options={this.state.organizationsList}
                      stateName="organization"
                      changeSelect={this.changeValues}
                      selected={called.organization}
                      disabled={this.state.organizationDisabled}
                      isRequired
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "location" })}
                      options={this.state.organizationAddressesList}
                      stateName="AddressId"
                      changeSelect={this.changeValues}
                      selected={called.AddressId}
                      disabled={this.state.locationDisabled}
                      isRequired
                      required
                    />
                  </Grid>
                    { (this.roleClientCanSelectCategory != 0 && this.state.canselectorganizationcategoriesbylevels === false) &&
                      (<Grid item xs={12} sm={12} md={12} lg={12}>
                        <SimpleAutoComplete
                          label={intl.formatMessage({ id: "category" })}
                          options={this.state.categoriesList}
                          stateName='CategoryId'
                          changeSelect={this.changeValues}
                          selected={called.CategoryId}
                          required />
                      </Grid>)}
                    { (this.roleClientCanSelectCategory != 0 && this.state.canselectorganizationcategoriesbylevels === true) && (<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 20, marginTop: 20 }}>
                      <fieldset style={{ padding: '10px 10px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                        <legend style={{ fontSize: '0.8rem', marginBottom: '0!important' }}><FormattedMessage id="category" />*</legend>
                        <Grid container spacing={2} id="categoriesByLevels" className="categoriesByLevels">
                          {this.createUi()}
                        </Grid>
                      </fieldset>
                    </Grid>
                    )}
                {this.canClientSelectTypeInCalled != 0 && (
                      <Grid item xs={12} sm={12}>
                        <SimpleAutoComplete
                          label={intl.formatMessage({ id: "type" })}
                          options={this.state.typesList}
                          stateName="type"
                          changeSelect={this.changeValues}
                          selected={called.type}
                          isRequired
                          required
                        />
                      </Grid>
                )}
                {this.canClientSelectPatrimonyInCalled && (
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoCompleteMultiple
                        label={intl.formatMessage({id:"called.registration.patrimonies.associated"})}
                        options={this.state.patrimonyList}
                        stateName="patrimonyId"
                        changeSelect={this.changeValues}
                        selected={called.patrimonyId}
                      />
                    </Grid>
                )}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      autoComplete="fname"
                      name="title"
                      variant="outlined"
                      fullWidth
                      id="title"
                      label={intl.formatMessage({ id: "title" })}
                      value={called.title}
                      onChange={this.handleInputChange}
                      inputProps={{ maxLength: 250 }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ marginBottom: "20px" }}>
					  {/*
                    <TextField
                      autoComplete="fname"
                      id="outlined-multiline-static"
                      label={intl.formatMessage({ id: "description" })}
                      name="description"
                      multiline
                      rows="8"
                      fullWidth
                      value={called.description}
                      variant="outlined"
                      onChange={this.handleInputChange}
                      required
                    />
					  */}
            <Typography>
              <FormattedMessage id="description" />*
            </Typography>
						<ReactQuill name="description" value={called.description} style={{ height: 200 }}
                modules={this.reactQuillModules}
                formats={this.reactQuillFormats}
								fullWidth
								required
								variant="outlined"
								id="outlined-multiline-static"
												onChange={this.handleChangeEditor} />							  
                  </Grid>
                <If test={this.state.organizationFieldList.length > 0} >
                  <Grid item xs={12} sm={12}>
                  <fieldset style={{
                    marginTop: 25,
                    marginBottom: 25,
                    border: '1px solid #c0c0c0',
                    padding: '0 0 15px 0',
                    borderLeft: 'none',
                    borderRight: 'none'
                  }}>
                    <legend style={{
                      paddingTop: 15,
                      fontSize: '1.3rem',
                      border: '1px solid #c0c0c0',
                      borderBottom: 'none',
                      borderLeft: 'none',
                      borderRight: 'none'
                    }}>{intl.formatMessage({ id: "organization.custom.fields" })}</legend>
                    <Grid container spacing={2}>
                      {this.state.organizationFieldList.map(item => {
                        return (
                          <Grid item xs={6} sm={6}>
                            <TextField
                              autoComplete="fname"
                              name={'ofield-' + item.id}
                              variant="outlined"
                              id={'ofield-' + item.id}
                              label={item.name}
                              fullWidth
                              onChange={this.handleInputChange}
                              inputProps={{ maxLength: 250 }}
                              required={item.isrequired}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </fieldset>
                  </Grid>
                </If>
                <Grid item xs={12} sm={12}>
                  <section style={{ width: "100%" }}>
                    <div className={classes.dropzoneArea}>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          this.setState({ files: acceptedFiles });
                        }}
                      >
                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              {<FormattedMessage id="drag.and.drop.files" />}
                            </p>
                          </div>
                        )}
                      </Dropzone>
                      {this.state.files.map((file) => {
                        return (
                          <div className={classes.dropzoneItem} key={file.name}>
                            {file.name}
                            <DeleteForever
                              className={classes.deleteItem}
                              onClick={() => {
                                this.removeItem(file);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </section>
                </Grid>
                <Grid item xs={12} sm={12}>
                <Grid container justify="flex-end" style={{ justifyContent: 'flex-end', marginBottom: '10px' }}  >
                  <Grid item xs={12} sm={4} style={{ justifyContent: 'flex-end' }}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={this.state.disabledSaveButton}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
                </Grid>
                </Grid>
                
                {this.state.loading && (
                  <Overlay>
                    <CircularProgress color="secondary" />
                  </Overlay>
                )}
              </form>
            </div>

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}

CalledRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(CalledRegistration))
);
