import React, { Component } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Typography, Container, Grid, CssBaseline, Button, ButtonGroup } from '@mui/material';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoSmall2 from '../logo/logo-small-2';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import { Can } from '../../config/ability-context';
import { AbilityContext } from '../../config/ability-context';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import QRCodeReader from "../qrcode-reader/qrcode-reader";
import TransitionsModal  from '../modal/modal';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import CustomizedSnackbars from "../../components/material-snackbars";

import { AvailabilityConfigChat } from '../../components/availability-chat';
import { ClientChat } from '../../components/client-chat';
import TechnicianChat from '../../components/technician-chat';
import { ChatListButton } from "../chatListButton";

import * as componentActions from "../../redux/actions/users";
import { bindActionCreators } from "redux";
import { getOrganizations } from "../../services/called.service";
import { isThisSecond } from "date-fns";
import Api from "../../services/api";
import { PapiroConsole } from '../../utils/papiroConsole';
import ReactGA from 'react-ga';
import DialogHelp from '../dialog-help';

var isLoggedIn=true;

const fabButton = {
  position: 'absolute',
  zIndex: 1,
  top: -35,
  left: '76%',
  right: 0,
  margin: '0 auto'
};

const fabHelpButton = {
  position: 'absolute',
  zIndex: 1,
  top: -35,
  left: '85%',
  right: 0,
  margin: '0 auto'
};

const chatConfigStyle = {
  position: 'absolute',
  zIndex: 1,
  top: -35,
  left: '58%',
  right: 0,
  margin: '0 auto'
};

const ChatListButtonStyle = {
  position: 'absolute',
  zIndex: 1,
  top: -35,
  left: '67%',
  right: 0,
  margin: '0 auto'
};


const footerStyle = {
    backgroundColor: "#f1f3f4",
    fontSize: "20px",
    color: "white",
    borderTop: "1px solid #E7E7E7",
    padding: "10px",
    position: "fixed",
    textAlign:"center",
    left: "0",
    bottom: "0",
    height: "25px",
    width: "100%",
    zIndex: 0,
  };

  const flex = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
  const footerStyleMobile = {
    backgroundColor: "#f1f3f4",
    fontSize: "20px",
    color: "white",
    borderTop: "1px solid #E7E7E7",
    padding: "10px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "25px",
    width: "100%",
    zIndex: 0,
    
  };

  const phantomStyle = {
    display: "block",
    padding: "50px",
    height: "60px",
    width: "100%",
    marginTop:"200px",

  };

  const styles = {
    container: isRowBased => ({
      top:'300%' ,
      backgroundColor: "#f1f3f4",
      fontSize: "20px",
      color: "white",
      borderTop: "1px solid #E7E7E7",
      textAlign: "center",
      padding: "10px",
      position: "absolute",
      left: "0",
      bottom: "0",
      height: "40px",
      width: "100%",
      zIndex: 0,      
      flexDirection: isRowBased ? 'row' : 'column',
    })
  };

  function FooterContent({ children }) {
    const matches = useMediaQuery('(max-width:530px)');

    if(isLoggedIn){
      return (
        <div>
                  <div>{children}</div>
        </div>
      );
    }
    else{
     return (<div></div>);
    }
    
  }
  

class Footer extends Component {

    constructor(props) {
      super(props);
      if(props.userSession == null){
        isLoggedIn=false;
      }
     
      this.state = {
        chatHubConnection: null,
        modalopen:false,
        openNotification: false,
        notificationVariant: "error",
        notificationMessage: "",
        openAvailabilityConfigChat: false,
        matches: window.matchMedia("(max-width: 599px)").matches,
        showChatConfig: false,
        showClientChat: false,
        showTechnicianChat: false,
        showChatButton: false,
        showHelp: false,
        organizations: this.props.organizations,
      };
      this.chatHub = this.props.userSession.user.chatHub;

      this.QRCode = this.QRCode.bind(this);
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.openAvailabilityConfigChatModal = this.openAvailabilityConfigChatModal.bind(this);
      this.closeAvailabilityConfigChatModal = this.closeAvailabilityConfigChatModal.bind(this);
      this.showChatConfigMount = this.showChatConfigMount.bind(this);
      this.closeTechnicianChat = this.closeTechnicianChat.bind(this);
      this.toggleTechnicianChat = this.toggleTechnicianChat.bind(this);
      
      this.closeClientChat = this.closeClientChat.bind(this);
      this.toggleClientChat = this.toggleClientChat.bind(this);
      this.closeNotification = this.closeNotification.bind(this);
    
    
    }

    closeTechnicianChat() {
      this.setState({ showTechnicianChat: false })
    }
    toggleTechnicianChat() {
      this.setState({ showTechnicianChat: !this.state.showTechnicianChat })
    }

    closeNotification() {
      this.setState({ openNotification: false });
    }

    closeClientChat(notify = false) {
      const intl = this.props.intl;
      this.setState({ showClientChat: false })
      if (notify == true) {
        this.setState({
          openNotification: true, 
          notificationVariant: "success",
          notificationMessage: this.props.intl.formatMessage({id:"footer.chat.attendance.finished"})
        });
      }
    }
    toggleClientChat() {
      this.setState({ showClientChat: !this.state.showClientChat })
    }

    toggleHelp = () => {
      this.setState({ showHelp: !this.state.showHelp })
    }
  
    componentWillReceiveProps(nextProps){
        if(nextProps.location.state === "reload"){
            this.componentDidMount();
        }
    } 
    
    async componentDidMount() {
      var ga_code = process.env.REACT_APP_GACODE;
      if (ga_code == null || ga_code == "") {
        ga_code="UA-198557600-1";
      }    

      ReactGA.initialize(ga_code, {
        debug: false,
        titleCase: false,
        gaOptions: {
          userId: this.props.userSession && this.props.userSession.user ? this.props.userSession.user.id : 0,
        }
      });
      ReactGA.pageview(window.location.pathname + window.location.search);
      PapiroConsole.log("Google Analytics Initialized");
      const handler = e => this.setState({matches: e.matches});
      window.matchMedia("(max-width: 599px)").addListener(handler);
      this.showChatConfigMount();
     //console.log("DUAS VEZES?");
    }

    async showChatConfigMount() {
      try {
        let orgData = []
        const { userSession } = this.props;
        if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
          orgData = userSession.user.userorganizations
        }

        if(orgData == null || (orgData && orgData.length == 0)) {
          let response = await getOrganizations();
          if(response && response.data)
            orgData = response.data
        }
        for (let item of orgData) {
          if (item.organizationsettings) {
            if (item.organizationsettings.allowattendanceviachat) {
              this.setState({ showChatButton: true });
              this.setState({ showChatConfig: this.isClient ? false : true });
              return
            }
          }
        }
        this.setState({ showChatConfig: false });
        this.setState({ showChatConfig: false });
      } catch(err) {
        PapiroConsole.log(err);
        this.setState({ showChatConfig: false });
        this.setState({ showChatConfig: false });
      }
    }
  
    QRCode(){
      this.openModal();
    }

    openAvailabilityConfigChatModal() {
      this.setState({ openAvailabilityConfigChat: true })
    }
    closeAvailabilityConfigChatModal() {
      this.setState({ openAvailabilityConfigChat: false })
    }
  
    openModal(){
      this.setState({  modalopen:true });
  
    }
    async closeModal(){
        this.setState({  modalopen:false });
    }
  
    render() {
        const { classes, userSession } = this.props;
        const intl = this.props.intl;
        this.isClient = true;
        var link = `/${this.props.match.params.lang}/called-registration`;
        if(userSession == null){
           isLoggedIn=false;
        }
        else{
          userSession.user.userrole.map((item, key) => {
                  if(item.role.roletype !=3){
                      this.isClient = false;
                  }
              }
          );
        }
        if(this.isClient){
            link = `/${this.props.match.params.lang}/called-registration-client`;
        }

        var version = process.env.REACT_APP_VERSION;
        if (version == null || version == "") {
            version="1.0.0";
        }    
        return (
            <FooterContent >
              {this.state.matches && 
              <div style={footerStyleMobile}>
              <Container component='main' maxWidth='xl'>
                  <CssBaseline />
                  <div className={classes.paper} style={{ marginTop: 0, paddingTop: 0, height: "25px" }}>
                  <Grid container xs style={{ paddingTop: 0, ...flex }}>
                      <Grid item style={{ paddingTop: 0, ...flex }}>
                          <Typography variant="h6" className={classes.title}>
                              <div className={classes.title} style={{ paddingLeft: '30px', color:'#3e3c3c', fontSize:'11px' }}>
                                  CMTECH - Mexx 2DO <FormattedMessage id="version"/> {version} - <FormattedMessage id="copyright"/>
                              </div>
                          </Typography>
                          <ButtonGroup>
                          <Can I="Open" a="Called">
                            <Tooltip title={intl.formatMessage({id:"called.new"})} aria-label="add">                            
                              <Fab color="secondary" aria-label="add" style={fabButton} component={Link} {...{ to: link }}>
                                    <AddIcon />
                              </Fab>
                            </Tooltip>
                          </Can>  
                          </ButtonGroup>
                      </Grid>
                  </Grid>    
                  </div>
              </Container>  
        </div>} {!this.state.matches && 
            <>
              <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
              />
              { this.isClient && this.state.showClientChat &&
                <ClientChat closeChat={ this.closeClientChat } userId={ userSession.user.id } userSession={ userSession } intl={intl} /> 
              }
              { !this.isClient && this.state.showTechnicianChat &&
                <TechnicianChat closeChat={ this.closeTechnicianChat } userId={ userSession.user.id }  />
              }
              {this.state.showHelp && (
                <DialogHelp handleClose={this.toggleHelp} />
              )}
              <div style={{...footerStyle, position: "fixed", zIndex: 2, padding: 0}}>
                <Container component='main' maxWidth='xl'>
                    <CssBaseline />
                    <div className={classes.paper} style={{ marginTop: 0, paddingTop: 0, height: "25px" }}>
                    <Grid container style={{ paddingTop: 0, ...flex }}>
                        <Grid item xs style={{ paddingTop: 0, ...flex }}>
                            <Typography variant="h6" className={classes.title}>
                                <div className={classes.title} style={{ paddingLeft: '30px', color:'#3e3c3c', fontSize:'11px' }}>
                                    CMTECH - Mexx 2DO <FormattedMessage id="version"/> {version} - <FormattedMessage id="copyright"/>
                                    <span style={{marginLeft:'15px'}}>
										{/*
                                      <a href="https://dpo.privacytools.com.br/policy-view/lm3JvwDqN/1/poli%CC%81tica-de-privacidade/pt_BR?s=1622720326739" target="_blank"><FormattedMessage id="privacypolicy"/></a> |&nbsp;
                                      <a href="https://dpo.privacytools.com.br/policy-view/G0zQY4kdY/2/poli%CC%81tica-de-cookies/pt_BR?s=1622720365335" target="_blank"><FormattedMessage id="cookiepolicy"/></a> |&nbsp; 
                                      <a href="https://dpo.privacytools.com.br/public_api/dsar/gvSNdhCIcgJy1dunSD630LIRwHbyNagMC0qFst6zYRXQzKWNZ__bar__YLQsk6IW__plus__5MO1oLVBiN43sMaak9zpqQxKyFg__equal____equal__/form.html" target="_blank"><FormattedMessage id="talkdpo"/></a> |&nbsp;
										*/}  
                                      <a href="https://dpo.privacytools.com.br/policy-view/nzVRBwDmQ/3/termos-e-condic%CC%A7o%CC%83es-gerais-de-uso-plataforma-mexx/pt_BR" target="_blank"><FormattedMessage id="useterm"/></a>
                                    </span>
                                </div>
                            </Typography>
                            <ButtonGroup>
                              <Can I="Open" a="Called">
                                <Tooltip title={intl.formatMessage({id:"called.new"})} aria-label="add">                            
                                  <Fab color="secondary" aria-label="add" style={fabButton} component={Link} {...{ to: link }}>
                                        <AddIcon />
                                  </Fab>
                                </Tooltip>
                              </Can>  
                            </ButtonGroup>
                            
                            <Tooltip title={intl.formatMessage({id:"problems.with.the.platform"})} aria-label="add">                            
                              <Fab color="secondary" aria-label="add" style={fabHelpButton} onClick={this.toggleHelp }>
                                  <HelpOutlineIcon />
                              </Fab>
                            </Tooltip>

                            { !this.isClient && this.state.showChatConfig &&
                              <>
                                <Tooltip title="Configurações do chat" aria-label="add">                            
                                  <Fab color="secondary" aria-label="add" style={chatConfigStyle} onClick={ this.openAvailabilityConfigChatModal }  disabled={!this.context.can('Add', 'RealtimeChat') && !this.context.can('View', 'RealtimeChat')} >
                                        <SettingsIcon />
                                  </Fab>
                                </Tooltip>
                                <AvailabilityConfigChat 
                                  onClose={ this.closeAvailabilityConfigChatModal }
                                  open={ this.state.openAvailabilityConfigChat }
                                  userSession={ this.props.userSession }
                                  intl={this.props.intl}
                                  />
                              </>
                            }
                            { this.state.showChatButton && 
                             <>
                              <ChatListButton 
                                clientChatIsOpen={ this.state.showClientChat }
                                buttonStyle={ ChatListButtonStyle } 
                                handleOnClick={ this.isClient ? this.toggleClientChat : this.toggleTechnicianChat }
                                disabled={!this.context.can('Add', 'RealtimeChat') && !this.context.can('View', 'RealtimeChat')}
                                />
                              </>
                            }
                        </Grid>
                    </Grid>    
                    </div>
              </Container>  
          </div>
        </> 
        }

          </FooterContent>
        );
      }
    }
    
    Footer.propTypes = {
      classes: PropTypes.object.isRequired,
    };
    
    const mapStateToProps = state => ({
      Footer: state.Footer,
      userSession: state.userSession,
      users: state.users,
      chatHubConnection: state.chatHubconnection
    });

    const mapDispatchToProps = (dispatch) =>
      bindActionCreators(componentActions, dispatch);
    
    export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Footer))));
    Footer.contextType = AbilityContext;