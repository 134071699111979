import React from 'react';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';

import { useChatHubContext } from '../../contexts/chatHubContext';

export function ChatListButton({ handleOnClick, buttonStyle, clientChatIsOpen, disabled }) {
    const { chatHub, openChatList, currentClientChat } = useChatHubContext();
    const userSession = useSelector((state) => state.userSession);

    const resetNotSeenMessages = async () => {
        if (currentClientChat) {
            await chatHub.invoke("ResetNotSeenMessages", currentClientChat.id, userSession.user.id);
        }
    } 

    const onClick = () => {
        handleOnClick()
        resetNotSeenMessages()
    };

    const numberOfUnseenMessages = () => {
        if (currentClientChat) return currentClientChat.notSeenByRequestingUser;
        if (!openChatList || openChatList.length < 1) return 0;

        let total = 0;
        openChatList.forEach(chat => {
            if (chat && chat.notSeenByAssignedUser > 0) {
                total += chat.notSeenByAssignedUser;
            }
        });
        return total
    };

    return (
        <Tooltip title="abrir chat" aria-label="add">                            
            <Fab disabled={disabled} color="secondary" aria-label="add" style={buttonStyle} onClick={ onClick }>
                <Badge badgeContent={numberOfUnseenMessages()} color="error">
                    <QuestionAnswerIcon />
                </Badge>
            </Fab>
        </Tooltip>
    )
}