import Api from './api';
export const getUsers = async () => { 
  let data = [];
  await Api.get('/users/techinical')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getTechinicalTeams = async () => { 
  let data = [];
  await Api.get('/techinicalteams')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllByTechnicalTeamId = async (id) => {
  let data = []
  await Api.get(`/techinicalteamusers/technicalteam/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}