import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch,InputLabel, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, Container, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userSessionActions from '../../redux/actions/user-session';
import Logo from '../../components/logo/logo-svg';
import LoginGoogle from '../../components/google/index';
import DialogTerm from '../../components/dialog-term';
import DialogUserLogged from '../../components/dialog-user-logged';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactFlagsSelect from "react-flags-select";
import LogoCMTechSmall from '../../components/logo/logoCMTechSmall'
import './styles-new.css'

//import {AsyncStorage} from 'react';

const defaultProps = {
  borderColor: 'text.secondary',
  bgcolor: "text.secondary",
  color:"secondary.contrastText",
  m: 1,
  border: 1,
  style: { width: '15rem', height: '15rem' },
};


class LoginNew extends Component {
  constructor(props) {
    super(props);

    let langCode = 'BR'
    let language = window.location.pathname.split('/')[1]
    if (language === 'en')
      langCode = 'US'
    else if (language === 'es')
      langCode = 'ES'
    else
      langCode = 'BR'

    this.state = {
      signInForm: {
        email: "",
        password: "",
      },
      rememberMe:false,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      debug:false,
      openDialogUserLogged: false,
      openDialogTerm: false,
      userAcceptedLogged: false,
      languageCode: langCode
    };
    
    this.headers = {
      'Content-Type': 'application/json',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.toggleRememberMe = this.toggleRememberMe.bind(this);
    this.rememberUser = this.rememberUser.bind(this);
    this.getRememberedUser = this.getRememberedUser.bind(this);
    this.forgetUser = this.forgetUser.bind(this);

    this.loginGoogleSuccess = this.loginGoogleSuccess.bind(this);
    this.loginGoogleError = this.loginGoogleError.bind(this);
    this.logoutGoogle = this.logoutGoogle.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
  }
  
  async componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

    async componentDidMount() {
      if(Api == null) {
        var l = this.props.match.params.lang;
        document.location.href=`/${l}/unavailable`
      }
      if((document.location.href.indexOf("localhost") > -1) 
      || (document.location.href.indexOf("demo.cube") > -1)  
      || (document.location.href.indexOf("192.168.1.168") > -1)  
      || (document.location.href.indexOf("127.0.0.1") > -1)  
      || (document.location.href.indexOf("192.168.") > -1)  
      ){
        this.setState({ debug: true });
        //alert(document.location.href+'\n'+this.state.debug);

      }
      const userlogin = await this.getRememberedUser();
    const chatContext = this.context;

    //this.setState({ 
       //signInForm: { ['email']: userlogin ? userlogin :  "", },
       //rememberMe: userlogin ? true : false });
       this.setState(prevState => ({
        signInForm: {
          ...prevState.signInForm,
          ['email']: userlogin ? userlogin :  ""
        },
        rememberMe: userlogin ? true : false
      }));
  }

  async getTutorial(){
    Api.get(`/notification/tutorial`)
      .then(result => {
        if (result.data.success) {
          let listNotification = result.data.data;
          let count = 0;
          let countNot = 0;
          let redirection = true;
          let l = this.props.match.params.lang;
          listNotification.map((item) => {
            item.notification.map((itemDois) => {
              if(itemDois.required == true && itemDois.finish == false){
                count++;
              }
              if(itemDois.required == true){
                countNot++;
              }
            });
            if(countNot == count){
              //document.location.href=`/${l}/steps`
              // console.log("Aqui agora 2");
              return item.id;
            }
            count = 0;
            countNot = 0;
            return 0;
          });
        }
    });
    
  }

  openDialogTerm = () => {
    this.setState({openDialogTerm: !this.state.openDialogTerm });
  }

  openDialogUserLogged = () => {
    this.setState({openDialogUserLogged: !this.state.openDialogUserLogged });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      signInForm: {
        ...prevState.signInForm,
        [name]: value
      }
    }));
  }

  showProblem = () => {

  }

  handleSubmit = async e => {
    if(e && e.preventDefault){
      e.preventDefault();
    }
    
    this.setState({ loading: true });
    var globalAdm = false

    const { email, password } = this.state.signInForm;

    Api.post("/users/login", { email, password, useracceptedlogged: this.state.userAcceptedLogged, ismobile: false}, {
      headers: this.headers
    })
      .then(async result => {
        if (result.data.success) {

          if(result.data.data.firstaccess == false && result.data.data.system == true)
          {
            if(result.data.data.userrole && result.data.data.userrole.length > 0 ){
              var userRole = result.data.data.userrole

              userRole.forEach(element => {
                  if(element.role.id == 1){

                    globalAdm = true

                  }
              });

              if(globalAdm == true){
                this.props.addUserSession(result.data.data);

                this.props.history.push({
                  pathname: `/pt/adm-password-reset`,
                  state: {
                      user : result.data.data
                  }
                });
                //document.location.href=`/${l}/adm-password-reset`
              }

            }

            
          }

          if(globalAdm == false){

            localStorage.setItem("refreshTokenTime", "0");
            this.props.addUserSession(result.data.data);
            localStorage.setItem('accesstoken', result.data.data.accesstoken);
            localStorage.setItem('reload', true);
      
          
            var l = this.props.match.params.lang;
            if(result.data.data.orgid != 0 && result.data.data.orgid != null){
            }else{
              if(result.data.data.startpage && result.data.data.startpage.length > 0) {
                document.location.href=`/${l}/${result.data.data.startpage}`
              } else {
                document.location.href=`/${l}/calleds`
              }
            }

          }
          //document.location.href="/calleds";
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        if(err.response && err.response.data && err.response.data.errors){
          if(err.response.data.errors && err.response.data.errors[0] == "Error: TermAccepted"){
            this.setState({
              loading: false,
              openDialogTerm: true
            });
          }else if(err.response.data.errors && err.response.data.errors[0] == "Error: UserLogged"){
            console.log("UserLogged")
            this.setState({
              loading: false,
              openDialogUserLogged: true
            });
          }else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          
        }
        else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: this.props.intl.formatMessage({id:"process.error"})
          });

        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }
  
  toggleRememberMe = (event,value) => {
    // console.log(value);
    this.setState({ rememberMe: value })
      if (value === true) {
    //user wants to be remembered.
      this.rememberUser();
    } else {
      this.forgetUser();
    }
  }

  rememberUser = async () => {
        try {
          // console.log(this.state.signInForm.email);
          await localStorage.setItem('field-service-cmetch', this.state.signInForm.email);
        } catch (error) {
          // Error saving data
          // console.log(error);
        }
  };
  getRememberedUser = async () => {
        try {
          const userlogin = await localStorage.getItem('field-service-cmetch');
          if (userlogin !== null) {
            // We have username!!
            return userlogin;
          }
        } catch (error) {
          // Error retrieving data
          // console.log(error);
        }
  };
  forgetUser = async () => {
      try {
        await localStorage.removeItem('field-service-cmetch');
      } catch (error) {
       // Error removing
      //  console.log(error);
      }
  };
  loginGoogleSuccess = async (u) => {
   
    this.setState({ loading: true });

    const  email = u.ct.Mt;
    const token = u.tokenObj.access_token;
    Api.post("/users/loginOAuth", { email , token }, {
      headers: this.headers
    })
      .then(async result => {
        if (result.data.success) {
          
          localStorage.setItem("refreshTokenTime", "0");

          

          //result.data.data.language=this.props.match.url;

          this.props.addUserSession(result.data.data);
          localStorage.setItem('accesstoken', result.data.data.accesstoken);
          localStorage.setItem('reload', true);
      
          var globalAdm = false
          var l = this.props.match.params.lang;
          if(result.data.data.orgid != 0 && result.data.data.orgid != null){
          }else{
            document.location.href=`/${l}/calleds`
          }

          if(result.data.data.firstaccess == false && result.data.data.system == true)
          {
            
            if(result.data.data.userrole && result.data.data.userrole.length > 0 ){
              


              var userRole = result.data.data.userrole

              userRole.forEach(element => {
                  if(element.role.id == 1){

                    globalAdm = true

                  }
              });

              if(globalAdm == true){
                document.location.href=`/${l}/adm-password-reset`
              }

            }

            
          }
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        if(err.response && err.response.data && err.response.data.errors){
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          });
        }
        else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: this.props.intl.formatMessage({id:"process.error"})
          });

        }
      });

  };
  loginGoogleError = async (u) => {
    const intl = this.props.intl;
    try {
      this.setState({
        loading: false,
        notificationVariant: "error",
        openNotification: true,
        notificationMessage: this.props.intl.formatMessage({id:"login.google.auth.fail"})
      });
      
    } catch (error) {
      // Error removing
    //  console.log(error);
    }
  };
  logoutGoogle = async (u) => {
    const intl = this.props.intl;
    try {
      this.setState({
        loading: false,
        notificationVariant: "info",
        openNotification: true,
        notificationMessage: this.props.intl.formatMessage({id:"login.logout.success"})
      });
    } catch (error) {
     // Error removing
    //  console.log(error);
    }
  };
  
  saveTermAndContinue = async (e) => {

    this.setState({
      loading: true,
      openDialogTerm: false
    });

    const { email } = this.state.signInForm;
    const applicationid = 1;

    Api.post("/users/saveUseTerm", { email, applicationid }, {
      headers: this.headers
    }).then(result => {
      if (result.data.success) {
        this.handleSubmit(e)
      }
    }).catch(err => {
      const intl = this.props.intl;
      if(err.response && err.response.data && err.response.data.errors){
        this.setState({
          loading: false,
          openNotification: true,
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
      }
      else{
        this.setState({
          loading: false,
          openNotification: true,
          notificationMessage: this.props.intl.formatMessage({id:"process.error"})
        });

      }
    });

  }

  saveUserLoggedAndContinue = async (e) => {

    await this.setState({
      loading: true,
      openDialogUserLogged: false,
      userAcceptedLogged: true
    });

    this.handleSubmit(e)

  }

  setMessageUseTerm = () => {
    const intl = this.props.intl;
    this.setState({
      notificationVariant: "error",
      openNotification: true,
      notificationMessage: this.props.intl.formatMessage({id:"login.accept.terms"})
    });
  }

  selectLanguage = async (code) => {
    console.log("=== selectLanguage ===")
    console.log(code)
    await this.setState({ languageCode: code })
    let urlCode = 'pt'
    let headerCode = 'pt-BR'
    if (code === "US") {
      urlCode = 'en'
      headerCode = 'en-US'
    } else if (code === "ES") {
      urlCode = 'es'
      headerCode = 'es-ES'
    }

    Api.setHeaderAcceptLanguage(headerCode)
    this.props.history.push(`/${urlCode}/`);
    window.location.reload()
  }

  render() {
    const { classes, intl } = this.props;
    const { signInForm } = this.state;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div style={{ background: "white", height: '100%' }} >
        <CssBaseline />
        <Container
          component="main"
          maxWidth="xl"
          className="mainContainer"
          style={{ 
            paddingRight: 0,
            paddingLeft: '5%'
          }}>
        <div className='reactFlagsSelectCustom'>
          <ReactFlagsSelect
            style={{ color: 'white', position: 'fixed', top: '3%', right: '3%' }}
            countries={["BR", "US", "ES"]}
            customLabels={{ BR: "PT-BR", US: "EN-US", ES: "ES" }}
            placeholder={intl.formatMessage({ id: "select.language" })}
            onSelect={this.selectLanguage}
            selected={this.state.languageCode} />
        </div>
        <Grid container spacing={0} columns={12} direction="row" alignItems="flex-start" justifyContent="flex-start" style={{ minHeight: '100vh' }}>
            <Grid item xs={12} sm={12} style={{ width: '100vh' }}>
              <div className="svgMd">
                <svg style={{ width: "inherit", height: "inherit" }} width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 648.739">
                  <g id="Img-login-md-960px" transform="translate(0 0)">
                    <path id="União_1" data-name="União 1" d="M319.568,960,446.313,756.368l-.09-.133a32.239,32.239,0,0,0,3.306-14.34,31.5,31.5,0,0,0-4.852-17.063l.016-.031L0,0H648.739V960Z" transform="translate(0 648.739) rotate(-90)" fill="#EE7202"/>
                  </g>
                </svg>
              </div>
              <div className="svgSm">
                <svg style={{ width: "inherit", height: "inherit" }} width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 364.462">
                  <g id="bg-login-sm-600px" transform="translate(0 0)">
                    <path id="União_1" data-name="União 1" d="M179.534,600l71.205-127.27-.051-.083a22.037,22.037,0,0,0,1.857-8.962,21.351,21.351,0,0,0-2.726-10.664l.009-.02L0,0H364.462V600Z" transform="translate(0 364.462) rotate(-90)" fill="#EE7202"/>
                  </g>
                </svg>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Grid
                  container
                  spacing={0}
                  columns={12}
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  className="loginGrid" >
                  <Grid item xs={1} sm={2} md={3} lg={3} xl={3} style={{ paddingTop: '4%' }} className="mediumLargeLogos">
                    <Grid
                      container
                      spacing={0}
                      columns={12}
                      direction="column"
                      alignItems="center"
                      justifyContent="space-between"
                      style={{ minHeight: '90vh', justifyContent: 'space-between' }} >
                      <Grid item lg={12} xl={12}>
                        <Logo width="140px" key="groups-fieldservice-awrar6aw15" />
                      </Grid>
                      <Grid item lg={12} xl={12}>
                        <LogoCMTechSmall width="93px" key="groups-fieldservice-awrar6aw15" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10} sm={10} md={7} lg={9} xl={9} className="loginFormCenter">
                    <Grid
                      container
                      spacing={0}
                      columns={12}
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      className="loginInsideGrid"
                      style={{ justifyContent: 'center' }} >
                      <form style={{ width: '100%' }} onSubmit={this.handleSubmit} >
                        <Grid item xs={12} sm={12} md={12} style={{ marginBottom: '10%', display: 'flex', justifyContent: 'center' }} className="smallLogo" >
                          <Logo key="groups-fieldservice-awrar6aw15" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
                          <Typography component="h1" variant="h5">
                            {Api.applicationName()}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '4%' }}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={intl.formatMessage({ id: "email" })}
                            name="email"
                            autoComplete="email"
                            type="email"
                            autoFocus
                            value={signInForm.email}
                            onChange={this.handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={intl.formatMessage({ id: "password" })}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            inputProps={{ maxLength: 32, minLength: 6 }}
                            value={signInForm.password}
                            onChange={this.handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            //onClick={this.handlePasswordSubmit}
                            type="link"
                            color="primary"
                            style={{
                              fontSize: ".8rem",
                              textTransform: "initial",
                              paddingLeft: 0,
                              paddingTop: 0
                            }} >
                            {<FormattedMessage id="forgot.password" />}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Switch
                            value={this.state.rememberMe}
                            checked={this.state.rememberMe}
                            onChange={this.toggleRememberMe}
                            /><FormattedMessage id="remember.me" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            style={{ marginTop: '15px', color: '#fff', padding: 14, textTransform: 'captalize!important' }}
                          >
                            <FormattedMessage id="log.in" />
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Link href="/pt/forgot-password/" variant="body2">
                            <FormattedMessage id="forgot.password" />
                          </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <LoginGoogle success={this.loginGoogleSuccess} error={this.loginGoogleError} logout={this.logoutGoogle}/>
                        </Grid>
                        {this.state.debug && (<Grid item xs={12} sm={12} md={12} style={{ marginTop: '30%', display: 'flex', justifyContent: 'center' }} className="smallLogo" >
                          <LogoCMTechSmall key="groups-fieldservice-awrar6aw15" />
                        </Grid>)}
                      </form>
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} xl={6} style={{ height: '100vh' }}>
              <div className="svgXL" style={{ height: '100vh' }}>
                <svg style={{ width: "inherit", height: "inherit" }} version="1.1" height="100%" width="100%" viewBox="0 0 932.2 946" xmlns="http://www.w3.org/2000/svg">
                  <g transform="translate(-989.16)">
                    <path transform="translate(989.16)" d="m369.72 946 146.64-200.66-0.1-0.132c5.6878-9.7342 4.9827-21.93-1.789-30.944l0.019-0.031-514.49-714.23h932.2v946z" fill="#EE7202"/>
                  </g>
                </svg>
              </div>
              <div className="svdLg" style={{ height: '100vh', display: 'flex', justifyContent: 'flex-end' }}>
                <svg style={{ width: "inherit", height: "inherit", position: 'absolute', right: '-10%' }} xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 709.497 720">
                  <g id="bg-login-lg-1280px" transform="translate(-570.503)">
                    <g id="Grupo_411" data-name="Grupo 411">
                      <g id="Grupo_385" data-name="Grupo 385" transform="translate(570.503)">
                        <path id="União_1" data-name="União 1" d="M281.4,720,393,567.276l-.08-.1a21.312,21.312,0,0,0-1.362-23.552l.015-.024L0,0H709.5V720Z" transform="translate(0)" fill="#EE7202"/>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </Grid>
          </Grid>
          {this.state.openDialogTerm && (
            <DialogTerm
              handleConfirmation={e => this.saveTermAndContinue(e)}
              classes={classes}
              openDialogTermFunc={this.openDialogTerm}
              openDialogTerm={this.state.openDialogTerm}
              setMessageUseTerm={this.setMessageUseTerm}
            />
          )}
          {this.state.openDialogUserLogged && (
            <DialogUserLogged
              handleConfirmation={e => this.saveUserLoggedAndContinue(e)}
              openDialogUserLoggedFunc={this.openDialogUserLogged}
            />
          )}
        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
    </div>
    );
  }
}

LoginNew.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => 
    bindActionCreators(userSessionActions, dispatch)

export default injectIntl(connect(null, mapDispatchToProps)(withStyles(Styles)(LoginNew)));
