import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Budget from "../dashboard/components/budget";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import HomeIcon from '@mui/icons-material/Home'
import { Grid, CircularProgress, DialogContent } from '@mui/material';
import Api from '../../services/api';
import moment from 'moment';
import { Overlay } from "../../styles/global";
import { getDistance } from 'geolib';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MiniMap from '../../components/minimap/fixed'
import { PapiroConsole } from '../../utils/papiroConsole';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  const intl = this.props.intl;
  return [intl.formatMessage({id:"called.mexx.2move.user.displacement.check.in"}), intl.formatMessage({id:"called.mexx.2move.user.on.field.check.in"}), intl.formatMessage({id:"called.mexx.2move.user.on.field.check.out"}), intl.formatMessage({id:"called.mexx.2move.user.displacement.check.out"})];
}

function getLabelContent(item) {
  const intl = this.props.intl;
  switch (item.historychecktypeid) {
    case 7: //checkin deslocamento
      return intl.formatMessage({id:"called.mexx.2move.user.displacement.check.in"});
    case 3: //checkin inloco
      return intl.formatMessage({id:"called.mexx.2move.user.on.field.check.in"});
    case 4: //checkiout inloco
      return intl.formatMessage({id:"called.mexx.2move.user.on.field.check.out"});
    case 8: //checkout deslocamento
      return intl.formatMessage({id:"called.mexx.2move.user.displacement.check.out"});
    case 5: //checkout deslocamento
      return intl.formatMessage({id:"called.mexx.2move.user.signed"});
    default:
      return ''
  }
}

export default function VerticalLinearStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [called, setCalled] = React.useState(null);
  const [costKmRotated, setCostKmRotated] = React.useState("R$00,00");
  const [costUser, setCostUser] = React.useState("R$00,00");
  const [timeAttendence, setTimeAttendence] = React.useState(null);
  const [timeInLocoAttendance, setTimeInLocoAttendance] = React.useState(null)
  const [timeDisplacementAttendance, setTimeDisplacementAttendance] = React.useState(null)
  const [totalKMRotated, setTotalKMRotated] = React.useState(0)
  const [loading, setLoading] = React.useState(false);
  const [attendIsDisplacement, setAttendIsDisplacement] = React.useState([])
  const [userName, setUserName] = React.useState("")
  const calledHistory = new Array();
  const [openDialogGeolocalization, setOpenDialogGeolocalization] = React.useState(false);
  const [latitude, setLatitude] = React.useState(0);
  const [longitude, setLongitude] = React.useState(0);

  const intl = this.props.intl;
  
  const handleOpenDialogGeolocalization = (letLatitude, letLongitude) => {
    setOpenDialogGeolocalization(!openDialogGeolocalization)
    setLatitude(letLatitude)
    setLongitude(letLongitude)
    console.log(letLatitude)
    console.log(letLongitude)
  }

  const steps = getSteps();

  var elapsedTime = null;
  var elapsedTimeCheckout = null;
  var elapsedAttendence = null;
  var distanceCheckInLatitude = null;
  var distanceCheckInLongitude = null;
  var distanceCheckOutLatitude = null;
  var distanceCheckOutLongetude = null;

  const getStepContent = (item) => {
    switch (item.historychecktypeid) {
      case 7: //checkin deslocamento
        elapsedTime = item.createdat;
        distanceCheckInLatitude = item.latitude;
        distanceCheckInLongitude = item.longitude;
        return <span>Saiu de {item.addresscomplete} ás {moment(item.createdat).format('DD/MM/YYYY HH:mm:ss')} 
          <LocationOnIcon style={{cursor: 'pointer', color: 'blue' }} onClick={() => handleOpenDialogGeolocalization(item.latitude, item.longitude) }/> 
        </span>;
      case 3: //checkin inloco
        elapsedAttendence = item.createdat;
        let startTime = elapsedTime;
        let endTime = item.createdat;
        let time = startTime != null && endTime != null ? moment.duration(moment(endTime).diff(moment(startTime))).asHours() : '00:00:00';
        if (startTime != null && endTime != null)
          time = moment.utc(time*3600*1000).format('HH:mm:ss')
        if(item.attendanceid in attendIsDisplacement) {
          let calcDistance = (distanceCheckInLatitude && distanceCheckInLatitude.length > 0 && distanceCheckInLongitude && distanceCheckInLongitude.length > 0 && item.latitude && item.latitude.length > 0 && item.longitude && item.longitude.length > 0) ? (getDistance({ latitude: distanceCheckInLatitude, longitude: distanceCheckInLongitude},{ latitude: item.latitude, longitude: item.longitude }) / 1000).toFixed(2) : 0;
          return <span>Chegou em {item.addresscomplete} ás {moment(item.createdat).format('DD/MM/YYYY HH:mm:ss')} <br /> - Tempo Percorrido: {time} <br />  - Distância Aproximada Percorrida: {calcDistance}KM <LocationOnIcon style={{cursor: 'pointer', color: 'blue' }} onClick={() => handleOpenDialogGeolocalization(item.latitude, item.longitude) }/> </span>;
        } else {
          return <span>Iniciou o atendimento em {item.addresscomplete} ás {moment(item.createdat).format('DD/MM/YYYY HH:mm:ss')} <LocationOnIcon style={{cursor: 'pointer', color: 'blue' }} onClick={() => handleOpenDialogGeolocalization(item.latitude, item.longitude) }/> </span>;
        }
      case 4: //checkiout inloco
        elapsedTimeCheckout = item.createdat;
        let startTimeAttendence = elapsedAttendence;
        let endTimeAttendence = item.createdat;
        let timeAttendence = startTimeAttendence != null && endTimeAttendence != null ? moment.duration(moment(endTimeAttendence).diff(moment(startTimeAttendence))).asHours() : '00:00:00';
        distanceCheckOutLatitude = item.latitude;
        distanceCheckOutLongetude = item.longitude;
        if (startTimeAttendence != null && endTimeAttendence != null)
          timeAttendence = moment.utc(timeAttendence*3600*1000).format('HH:mm:ss')
        return <span>Saiu de {item.addresscomplete} ás {moment(item.createdat).format('DD/MM/YYYY HH:mm:ss')} <br /> - Tempo do Atendimento: {timeAttendence} <LocationOnIcon style={{cursor: 'pointer', color: 'blue' }} onClick={() => handleOpenDialogGeolocalization(item.latitude, item.longitude) }/> </span>;
      case 8: //checkout deslocamento
        let startTimeCheckout = elapsedTimeCheckout;
        let endTimeCheckout = item.createdat;
        let timeCheckout = startTimeCheckout != null && endTimeCheckout != null ? moment.duration(moment(endTimeCheckout).diff(moment(startTimeCheckout))).asHours() : '00:00:00';
        if (startTimeCheckout != null && endTimeCheckout != null)
          timeCheckout = moment.utc(timeCheckout*3600*1000).format('HH:mm:ss')
        let calcDistanceCheckout = (distanceCheckOutLatitude && distanceCheckOutLatitude.length > 0 && distanceCheckOutLongetude && distanceCheckOutLongetude.length > 0 && item.latitude && item.latitude.length > 0 && item.longitude && item.longitude.length > 0) ? (getDistance({ latitude: distanceCheckOutLatitude, longitude: distanceCheckOutLongetude},{ latitude: item.latitude, longitude: item.longitude }) / 1000).toFixed(2) : 0;
        return <span>Chegou em {item.addresscomplete} ás {moment(item.createdat).format('DD/MM/YYYY HH:mm:ss')} <br /> - Tempo Percorrido: {timeCheckout} <br /> - Distância Aproximada Percorrida: {calcDistanceCheckout}KM <LocationOnIcon style={{cursor: 'pointer', color: 'blue' }} onClick={() => handleOpenDialogGeolocalization(item.latitude, item.longitude) }/> </span>;
      case 5: //assinatura
        let signaturePath = item && item.attachment && item.attachment.path ? item.attachment.path : ''
        return <span>{intl.formatMessage({id:"user"})} {userName} realizou assinatura do laudo ás {moment(item.createdat).format('DD/MM/YYYY HH:mm:ss')} <br /> <img id ="attachment" width="170px" src={Api.apiurlbase()+signaturePath}/> <LocationOnIcon style={{cursor: 'pointer', color: 'blue' }} onClick={() => handleOpenDialogGeolocalization(item.latitude, item.longitude) }/>  </span>;
      default:
        return ''
    }
  }

  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    if (called == null) {
      setLoading(true);
      Api.get(`/calleds/getinfoformexx2move/${props.calledid}`)
        .then(result => {
          setLoading(false);
          console.log(result)
          console.log("useEffect")
          if (result && result.data && result.data.data && result.data.success) {

            if(result.data.data.requestinguser && result.data.data.requestinguser.name){
              setUserName(result.data.data.requestinguser.name);
            }
            
            let costKmRotatedFormated = result.data.data.calledcosts[0].totalvaluecostdisplacement;
            costKmRotatedFormated = 'R$' + costKmRotatedFormated.toFixed(2)
            costKmRotatedFormated = costKmRotatedFormated.replaceAll(".", ",")

            let costUserFormated = result.data.data.calledcosts[0].totalvaluecostinloco;
            costUserFormated = 'R$' + costUserFormated.toFixed(2)
            costUserFormated = costUserFormated.replaceAll(".", ",")

            let totalKMRotated = result.data.data.calledcosts[0].totalkilometersdisplacement;
            totalKMRotated = totalKMRotated.toFixed(2)
            totalKMRotated = totalKMRotated.replaceAll(".", ",")

            let calledHourLoc = result.data.data.calledcosts[0].totalhoursdisplacement + result.data.data.calledcosts[0].totalhoursinloco;
            if (calledHourLoc && calledHourLoc > 0) {
              var splitCalledHoutLoc = [
                (calledHourLoc > 0) ? Math.floor(calledHourLoc) : Math.ceil(calledHourLoc),
                calledHourLoc % 1
              ]
              let nDate = new Date(0, 0)
              nDate.setSeconds(+splitCalledHoutLoc[1] * 60 * 60)
              let calledHourLocResult = nDate.toTimeString().slice(2, 8)
              calledHourLocResult = splitCalledHoutLoc[0] == 0 ? "00" + calledHourLocResult : splitCalledHoutLoc[0] < 10 ? "0" + splitCalledHoutLoc[0] + calledHourLocResult : splitCalledHoutLoc[0] + calledHourLocResult
              calledHourLoc = calledHourLocResult

              if (calledHourLoc == '0') {
                calledHourLoc = "00:00:00"
              }

            } else if (calledHourLoc == '0') {
              calledHourLoc = "00:00:00"
            }

            let calledHourDisplacement = result.data.data.calledcosts[0].totalhoursdisplacement
            if (calledHourDisplacement && calledHourDisplacement > 0) {
              var splitCalledHourDisplacement = [
                (calledHourDisplacement > 0) ? Math.floor(calledHourDisplacement) : Math.ceil(calledHourDisplacement),
                calledHourDisplacement % 1
              ]
              let nDate = new Date(0, 0)
              nDate.setSeconds(+splitCalledHourDisplacement[1] * 60 * 60)
              let calledHourDisplacementResult = nDate.toTimeString().slice(2, 8)
              calledHourDisplacementResult = splitCalledHourDisplacement[0] == 0 ? "00" + calledHourDisplacementResult : splitCalledHourDisplacement[0] < 10 ? "0" + splitCalledHourDisplacement[0] + calledHourDisplacementResult : splitCalledHourDisplacement[0] + calledHourDisplacementResult
              calledHourDisplacement = calledHourDisplacementResult

              if (calledHourDisplacement == '0') {
                calledHourDisplacement = "00:00:00"
              }

            } else if (calledHourDisplacement == '0') {
              calledHourDisplacement = "00:00:00"
            }

            let calledHourInLoco = result.data.data.calledcosts[0].totalhoursinloco;
            if (calledHourInLoco && calledHourInLoco > 0) {
              var splitCalledHoutInLoco = [
                (calledHourInLoco > 0) ? Math.floor(calledHourInLoco) : Math.ceil(calledHourInLoco),
                calledHourInLoco % 1
              ]
              let nDate = new Date(0, 0)
              nDate.setSeconds(+splitCalledHoutInLoco[1] * 60 * 60)
              let calledHourInLocoResult = nDate.toTimeString().slice(2, 8)
              calledHourInLocoResult = splitCalledHoutInLoco[0] == 0 ? "00" + calledHourInLocoResult : splitCalledHoutInLoco[0] < 10 ? "0" + splitCalledHoutInLoco[0] + calledHourInLocoResult : splitCalledHoutInLoco[0] + calledHourInLocoResult
              calledHourInLoco = calledHourInLocoResult

              if (calledHourInLoco == '0') {
                calledHourInLoco = "00:00:00"
              }

            } else if (calledHourInLoco == '0') {
              calledHourInLoco = "00:00:00"
            }
            
            let maxAttendance = 1
            if (result.data.data.calledhistorychecks.length > 0) {
              result.data.data.calledhistorychecks.forEach(element => {
                if (element.attendance && element.attendance > maxAttendance)
                  maxAttendance = element.attendance
              });

              let finalList = []
              for (let i = 1; i <= maxAttendance; i++) {
                let checkInDisplacement = result.data.data.calledhistorychecks.filter(element => {
                  return (element.attendance && element.attendance === i && element.historychecktypeid && element.historychecktypeid === 7)
                })
                if(checkInDisplacement != null)
                  finalList.push(...checkInDisplacement)

                let checkIn = result.data.data.calledhistorychecks.filter(element => {
                  return (element.attendance && element.attendance === i && element.historychecktypeid && element.historychecktypeid === 3)
                })
                if(checkIn != null)
                  finalList.push(...checkIn)

                let signature = result.data.data.calledhistorychecks.filter(element => {
                  return (element.attendance && element.attendance === i && element.historychecktypeid && element.historychecktypeid === 5 && element.clientsignature === true)
                })
                if(signature != null)
                  finalList.push(...signature)

                let checkOut = result.data.data.calledhistorychecks.filter(element => {
                  return (element.attendance && element.attendance === i && element.historychecktypeid && element.historychecktypeid === 4)
                })
                if(checkOut != null)
                  finalList.push(...checkOut)

                let checkOutDisplacement = result.data.data.calledhistorychecks.filter(element => {
                  return (element.attendance && element.attendance === i && element.historychecktypeid && element.historychecktypeid === 8)
                })
                if(checkOutDisplacement != null)
                  finalList.push(...checkOutDisplacement)
              }

              PapiroConsole.log("Called List")
              PapiroConsole.log(finalList)
              setCalled(finalList);
            } else {
              setCalled(result.data.data.calledhistorychecks);
            }
            setCostKmRotated(costKmRotatedFormated)
            setCostUser(costUserFormated)
            setTimeAttendence(calledHourLoc)
            setTimeInLocoAttendance(calledHourInLoco)
            setTimeDisplacementAttendance(calledHourDisplacement)
            setTotalKMRotated(totalKMRotated)
            let activeStepLet = 0;
            if(result.data.data.calledhistorychecks){
              result.data.data.calledhistorychecks.map(item => {
                if (item.historychecktypeid == 7 || item.historychecktypeid == 3 || item.historychecktypeid == 4 || item.historychecktypeid == 8) {
                  activeStepLet++;
                }
              })
              setActiveStep(activeStepLet);
              

              let attendIsDisplace = []
              let displacementsFilter = result.data.data.calledhistorychecks.filter(item => item.historychecktypeid == 7 || item.historychecktypeid == 8)

              displacementsFilter.map(item => {
                attendIsDisplace[item.attendanceid] = true
              })
              setAttendIsDisplacement(attendIsDisplace)
            }
            
          }else{
            var list = [];
            setCalled(list);
          }

        }).catch(err => {
          setLoading(false);
          var list = [];
          setCalled(list);
        })
    }

  }, []);

  return (
    <div className={classes.root}>
      <Grid container >
        <Dialog
          open={openDialogGeolocalization}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'md'}
        >
          <DialogTitle id="alert-dialog-title">Geolocalização da ação</DialogTitle>
          <DialogContent> 
            <MiniMap
              style={{
                width: 350, minWidth: 350, maxWidth: 350,
                height:250, minHeight: 250, maxHeight: 250
              }}
              lat={latitude}
              long={longitude}
              //address={action.addresscomplete}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialogGeolocalization(false) } color="primary">
              {intl.formatMessage({id:"close"})}
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item lg={4} sm={6} xl={4} xs={12} >
          <Budget
            className="cardbudget"
            total={timeAttendence}
            comparation={0}
            positiveMonth={0}
            customTitle={intl.formatMessage({id:"called.mexx.2move.attendance.time"})}
            hideComparation={true}
            icon={<WatchLaterIcon />}
            colorPrimary={true} />
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12}  >
          <Budget
            className="cardbudget"
            total={costKmRotated}
            comparation={0}
            positiveMonth={0}
            customTitle={intl.formatMessage({id:"called.mexx.2move.cost.per.km"})}
            hideComparation={true}
            icon={<AttachMoneyIcon />} />
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12} >
          <Budget
            className="cardbudget"
            total={costUser}
            comparation={0}
            positiveMonth={0}
            customTitle={intl.formatMessage({id:"called.mexx.2move.cost.on.field"})}
            hideComparation={true}
            icon={<AttachMoneyIcon />} />
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12} style={{ marginTop: 20 }}>
          <Budget
            className="cardbudget"
            total={totalKMRotated}
            comparation={0}
            positiveMonth={0}
            customTitle={intl.formatMessage({id:"total.km.covered"})}
            hideComparation={true}
            icon={<LocationOnIcon />}
            colorPrimary={true} />
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12} style={{ marginTop: 20 }}>
          <Budget
            className="cardbudget"
            total={timeDisplacementAttendance}
            comparation={0}
            positiveMonth={0}
            customTitle={intl.formatMessage({id:"displacement.hours"})}
            hideComparation={true}
            icon={<WatchLaterIcon />} />
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12} style={{ marginTop: 20 }}>
          <Budget
            className="cardbudget"
            total={timeInLocoAttendance}
            comparation={0}
            positiveMonth={0}
            customTitle={intl.formatMessage({id:"inloco.attendance.hours"})}
            hideComparation={true}
            maxWidthTitle={true}
            icon={<HomeIcon />} />
        </Grid>
        
      </Grid>
      <Stepper activeStep={activeStep} orientation="vertical">
        {called && called.map(item => {
          
          if (item.historychecktypeid == 7 || item.historychecktypeid == 3 || item.historychecktypeid == 4 || item.historychecktypeid == 8 || item.historychecktypeid == 5) {
            calledHistory.push(<Step active={true}>
              <StepLabel>{getLabelContent(item)}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(item)}</Typography>
              </StepContent>
            </Step>)
          }
        })}
        {calledHistory.sort().reverse().map(item => {
          return item;
        })}
      </Stepper>
      {loading && (
        <Overlay>
          <CircularProgress color="secondary" />
        </Overlay>
      )}
    </div>
  );
}