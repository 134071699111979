import React from 'react';
import LogoMarca from '../../images/logo-cmtech-login.png';
import './styles.css'

const Logo = props => (
    <div>
        <img src={LogoMarca} title="Logomarca" className='App-logo' />
    </div>
);

export default Logo;
