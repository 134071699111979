import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import SimpleCheckBox from '../../components/checkbox/check';

import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';

import { FormattedMessage,injectIntl } from 'react-intl';

import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import 'react-phone-input-2/lib/material.css'
import { getOrg } from "../../services/organization.service";
import * as Service from "../../services/user.service";
import { DataGrid, GridToolbarFilterButton  } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogConfirmation from '../../components/dialog-confirmation'
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";

import Dialog from '@mui/material/Dialog';

import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const NoDataRows = _ => {
    return (
      <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
    )
  }

class NpsSearchRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
        if(item.role.roletype !=3){
            this.isClient = false;
        }
        if(item.role.roletype ==0){
          this.globalAdm =true;
        }
    }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      npstitle : '', 
      npsdescription : '',
      active : false,
      OrgListsId: [],
      dateEnd: moment().add(1, 'days').format('YYYY/MM/DD HH:mm:ss'),
      
      
     };

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrgListForDelete: "",
      OrgListForSelect: [],
      OrgList: [],
      OrgListSelect: [],
      openOrgInsertModal: false,
      openModal: false,
      

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.location.state === "reload"){
        this.componentDidMount();
    }
  }    

  componentDidMount() {

    this.getOrganizations();

  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  openModal = async () => {
    this.setState({
      openModal: !this.state.openModal
    })
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    var returnReponse = this.checkValidations();

    if(returnReponse == true){

      let data = new FormData();

      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }
      let idsStringOrg = "";
      console.log(this.state.item.OrgListsId)
      console.log("org list id aqui")
      if (this.state.item.OrgListsId.length > 0) {
        this.state.item.OrgListsId.forEach(
          (organization) => (idsStringOrg += organization.id + ",")
        );
      }
      
      var newStr = idsStringOrg.slice(0, -1);
      console.log(newStr)
      data.append("Organizations", newStr);

      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else {
          data.append(key, this.state.item[key]);
        }
      }
      
    
      

      console.log(data)

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.post('/nps', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              OrgList: [],
              item: this.initialState,
              files: [],
              openModal: false
              
            });


          }
          else{

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openModal: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);

          }          
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              openModal: false,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openModal: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
          }
        });


    }

    
  };

  checkValidations() {
    const intl = this.props.intl;
    console.log(moment(this.state.item.dateEnd).format('YYYY-MM-DD'))
    console.log(moment().format('YYYY-MM-DD'))
    if(moment(this.state.item.dateEnd).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')){
      console.log("entrei aqui")

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"nps.search.registration.invalid.date.field"})

      });

      return false
      
    }
    if(this.state.item.OrgListsId == null ||  this.state.item.OrgListsId.length == 0 ){

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"nps.search.registration.missing.organization.field"})

      });

      return false
      
    }

    if(this.state.item.npsdescription =="" ){

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.checklist.registration.missing.description"})

      });

      return false
      
    }

    if(this.state.item.npstitle =="" ){

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"missing.title.field"})

      });

      return false
      
    }
   
    return true

    
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  openErrorNotification(){
    const intl = this.props.intl;
    this.setState({
      openNotification: true,
      notificationVariant: "error",
      notificationMessage: intl.formatMessage({id:"select.user"})
    })

  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });


    if(stateName == "dateEnd"){
        this.setState(prevState => ({
            item: {
              ...prevState.item,
              [stateName]: moment(value).format('YYYY/MM/DD HH:mm:ss')
            }
          })); 
    }

    
    else{

        this.setState(prevState => ({
            item: {
              ...prevState.item,
              [stateName]: value
            }
          })); 

    }

    
    this.setState({ loading: false });

  };

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await getOrg();
    if (result.success) {
      this.setState({ loading: false, OrgListForSelect: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  saveOrgInsertModal = async () => {

    const intl = this.props.intl;

    console.log(this.state.item.OrganizationId)
    console.log("aqui a org")
    this.setState(prevState => ({
        loading: false,
        OrgList: this.state.item.OrganizationId,
        item: {
            ...prevState.item,
            OrgListsId: this.state.item.OrganizationId,
        },
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "add.success" }),
        openOrgInsertModal: !this.state.openOrgInsertModal,
       
      }));
   

  }

  openOrgInsertModal = async () => {
    this.setState({
      openOrgInsertModal: !this.state.openOrgInsertModal
    })
  }

  setOrgDelete = async (org) => {
    this.setState({
      OrgListForDelete: org + "," + this.state.OrgListForDelete
    })
  }

  orgDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.OrgListForDelete && this.state.OrgListForDelete.length > 0) {

      this.setState({ loading: true })

      var orgList = [];
      var orgDeleteList = this.state.OrgListForDelete.split(",");

      orgDeleteList.map(organizationDelete => {
        if(organizationDelete != ""){
          let returnok = this.state.OrgList.filter( el => el.id != organizationDelete)
          returnok.map(item => {
            if(orgList.filter(el => el.id != item.id )){
              orgList.push(item);
            }
          })
        }
      })
      
      const intl = this.props.intl;
      this.setState({
        loading: false,
        OrgList: orgList,
        item: {
            OrgListsId: this.state.item.OrganizationId,
          },
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
      })

      this.setState(prevState => ({
        loading: false,
        OrgList: orgList,
        item: {
            ...prevState.item,
            OrgListsId: this.state.item.OrganizationId,
          },
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
       
      }));

    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"organization.material.registration.select.organization"})
      })
    }
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    console.log(this.state.item.OrganizationId)
    console.log("aqui a org")
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    console.log(this.state)
    console.log("item aqui")

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'  onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form}  encType='multipart/form-data'>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='npstitle' onChange={this.handleInputChange} value={item.npstitle} required />
                    </Grid>
                                  
                    <Grid item xs={12} sm={12}>
                      <TextField  rows="8"  multiline  fullWidth label={intl.formatMessage({id:"description"})} autoComplete='fname' variant='outlined' name='npsdescription' onChange={this.handleInputChange} value={item.npsdescription} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Accordion defaultExpanded ={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>{intl.formatMessage({id:"select.organizations"})}</Typography>
                        </AccordionSummary>
                            <AccordionDetails>
                                <Grid container xs={12} sm={12} >

                                  <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                                    <Grid container spacing={0}>
                                      <Grid xs={12} sm={4} ></Grid>
                                      <Grid xs={12} sm={4} style={{ paddingRight: 15 }}>
                                        <DialogConfirmation
                                            msgButton={intl.formatMessage({id:"organization.material.registration.remove.organization"})}
                                            msg={intl.formatMessage({id:"user.client.registration.confirm.remove.organization.from.user"})}
                                            msgDisagree={this.props.intl.formatMessage({id:"cancel"})}
                                            msgAgree={this.props.intl.formatMessage({id:"yes"})}
                                            handleConfirmation={e => this.orgDeleteModal()}
                                            classes={classes}
                                            icon={<DeleteIcon />}
                                            //userListForDelete={this.state.UserListForDelete}
                                            //openErrorNotification={ () => this.openErrorNotification()}
                                        />
                                      </Grid>
                                      <Grid xs={12} sm={4} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={classes.button}
                                            startIcon={<AddIcon />}
                                            onClick={e => this.openOrgInsertModal()} >
                                            {intl.formatMessage({id:"organization.material.registration.add.organization"})}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid xs={12} sm={12}
                                      style={{
                                      marginLeft: 5,
                                      marginBottom: 5,
                                      border: '3px solid #eee'
                                      }} >
                                      <div style={{ height: 350 }}>
                                      <DataGrid
                                          style={{ fontSize: 14, color: '#000' }}
                                          className="DataGridStyle"
                                          components={{
                                          NoRowsOverlay: NoDataRows,
                                          Toolbar: GridToolbarFilterButton,
                                          }}
                                          rows={this.state.OrgList}
                                          rowsPerPageOptions={[5]}
                                          checkboxSelection
                                          onSelectionModelChange={itm => {
                                          if (itm.length > 0) {
                                              this.setOrgDelete(itm);
                                          } else {
                                              this.setState({
                                              OrgListForDelete: [],
                                              })
                                          }
                                          }}
                                          columns={[
                                          { headerName: this.props.intl.formatMessage({id:"menu.organization"}), field: 'name', width: 500 },
                                          ]}
                                          HeadersVisibility="None"
                                      />
                                      </div>
                                  </Grid>
                                </Grid>
                                        
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                      <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={item.dateEnd} required />
                    </Grid>
                    <Grid item xs={1} sm={1} >
                      <SimpleCheckBox 
                        label={intl.formatMessage({id:"active"})}
                        name='active' 
                        stateName='active' 
                        changeSelect={this.changeValues} 
                        selected={item.active} />
                    </Grid>
                    <Grid item xs={1} sm={1} >
                      <Tooltip title={intl.formatMessage({id:"nps.search.registration.send.emails"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>

                    
               
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4} >
                        <Button 
                          
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                
                          onClick={e => this.openModal()} 
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />

            <Dialog
              fullWidth
              maxWidth="md"
              open={this.state.openOrgInsertModal}
              onClose={this.closeOrgModal}
              aria-labelledby="form-dialog-title-org-mat"
              PaperProps={{
                style: {
                  overflow: 'hidden'
                }
              }} >
              <DialogTitle id="form-dialog-title-org-mat">
                {intl.formatMessage({id:"organization.material.registration.new.organizations"})}
                <ClearIcon onClick={this.openOrgInsertModal} style={{ float: 'right', cursor: 'pointer' }} />
              </DialogTitle>
              <Grid item xs={12} sm={12} >
                <Grid container spacing={4} style={{ padding:"5px" }}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label={intl.formatMessage({ id: "organization" })}
                      options={this.state.OrgListForSelect}
                      stateName="OrganizationId"
                      changeSelect={this.changeValues}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid spacing={2} container justify="flex-end" style={{ padding: 15 }}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant='contained'
                    type="button"
                    onClick={this.openOrgInsertModal}
                    fullWidth >
                    {this.props.intl.formatMessage({id:"cancel"})}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={this.saveOrgInsertModal}
                  >
                    {<FormattedMessage id="save" />}
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
          </Container>

          <Dialog
              fullWidth
              maxWidth="sm"
              open={this.state.openModal}
              onClose={this.closeModalSendMail}
              aria-labelledby="form-dialog-title-org-mat"
              PaperProps={{
                style: {
                  overflow: 'hidden'
                }
              }} >
              <DialogTitle id="form-dialog-title-org-mat">
                {intl.formatMessage({id:"nps.search.registration.confirm.nps.search"})}
                <ClearIcon onClick={this.openModal} style={{ float: 'right', cursor: 'pointer' }} />
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      {intl.formatMessage({id:"nps.search.registration.confirm.nps.search.save"})}
                  </DialogContentText>
              </DialogContent>
              <Grid spacing={2} container justify="flex-end" style={{ padding: 15 }}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant='contained'
                    type="button"
                    onClick={this.openModal}
                    
                    fullWidth >
                    {this.props.intl.formatMessage({id:"cancel"})}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={this.handleSubmit}
                  >
                    {<FormattedMessage id="save" />}
                  </Button>
                </Grid>
                
              </Grid>
            </Dialog>
        </main>
        <Footer />                    
     </div>
    );
  }
}

NpsSearchRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(NpsSearchRegistration)));
