import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

export function InitChatDialog({ openChat, open, handleClose, organizations, onStartChat, intl }) {

    const classes = useStyles();
    const [selectedOrganizationId, setSelectedOrganizationId] = useState('');
    const [autoSelectOrganization, setAutoSelectOrganization] = useState(false);

    const handleSelectedOrganization = (event) => {
        setSelectedOrganizationId(event.target.value);
    };

    useEffect(() => {
        if (organizations && organizations.length === 1) {
          setSelectedOrganizationId(organizations[0].id);
          setAutoSelectOrganization(true);
        }
    }, [organizations]);

    const handleSubmit = (e, orgId) => {
        e.preventDefault()
        if (orgId) {
            onStartChat(orgId);
        }
        handleClose();
        openChat()
    } 

    return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Iniciar atendimento via chat</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({id:"initChatDialog.no.attendances.in.progress"})}
          </DialogContentText>
          {/* //TODO mudar para > 1 nas duas condicionais mais tarde */}
          {/* //TODO mudar para > 1 nas duas condicionais mais tarde */}
          <form onSubmit={ (e) => handleSubmit(e, selectedOrganizationId) }>
            { organizations && organizations.length > 1 &&
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel id="demo-simple-select-filled-label">{intl.formatMessage({id:"organization"})}</InputLabel>
                <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={selectedOrganizationId}
                onChange={handleSelectedOrganization}
                >
                { organizations && organizations.length > 1 && organizations.map(org => (
                    <MenuItem key={org.id} value={org.id}>{ org.name }</MenuItem>
                )) }

                </Select>
              </FormControl>
            }
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                {intl.formatMessage({id:"no"})}
            </Button>
            <Button type="submit" color="primary">
                {intl.formatMessage({id:"yes"})}
            </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
