import React, { Component } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import {
  CssBaseline,
  CircularProgress,
  Container,
  Button,
  Grid,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Api from "../../services/api";
import MyMaterialTable, { MTableCell } from "../../components/table";
import { Can } from "../../config/ability-context";
import { AbilityContext } from "../../config/ability-context";
import * as componentActions from "../../redux/actions/calledclassifications";
import moment from "moment";
import Footer from "../../components/footer";
import { FormattedMessage, injectIntl } from "react-intl";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import Pagination from "@mui/material/Pagination";
import Search from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import SimpleSelect from "../../components/select/simple-search";
import ClearIcon from "@mui/icons-material/Clear";

class CalledClassifications extends Component {
  constructor(props) {
    super(props);

    this.typingTimeout = null;

    const { userSession } = this.props;
    var attr = "Atribuído para";
    var bool = true;
    this.globalAdm = false;
    this.AdmOrganization = false;
    this.isCoordenator = false;
    this.isTechinical = false;
    this.isClient = true;
    const intl = this.props.intl;

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
          this.isClient = false;
          attr = intl.formatMessage({ id: "client" });
          bool = false;
      }
      if (item.role.roletype == 0) {
          this.globalAdm = true;
      }
      if (item.role.roletype == 1) {
          this.AdmOrganization = true;
      }
      if (item.role.roletype == 4) {
          this.isCoordenator = true;
      }
      if (item.role.roletype == 2) {
          this.isTechinical = true;
      }
  });

    let filters = [
      { name: intl.formatMessage({ id: "title" }), id: "title" },
      { name: intl.formatMessage({ id: "type" }), id: "type" },
      { name: intl.formatMessage({ id: "client" }), id: "both" },
    ];

    this.state = {
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      resultList: [],
      userId: userSession.user.id,
      userRoleName: attr,
      userRoleBool: bool,

      totalPages: 0,
      page: 1,
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      searchValue: "",
      filterSelected: 0,
      filters: filters,
    };
    this.closeNotification = this.closeNotification.bind(this);
    this.getItens = this.getItens.bind(this);
    this.changeValues = this.changeValues.bind(this);

    this.callSearch = this.callSearch.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.clearFields = this.clearFields.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    this.getItens();
  }

  onChangePaginate = (e, page) => {
    this.setState({ page: page });
    this.getItens(page);
  };

  setPagination(count, page) {
    let totalPage = Math.ceil(count / 10);
    this.setState({ totalPages: totalPage });
    if (count > 0) {
      if (page == 1) {
        if (count < 10) {
          this.setState({ pageStart: 1, pageEnd: count });
        } else {
          this.setState({ pageStart: 1, pageEnd: 10 });
        }
      } else {
        let pageStart = (page - 1) * 10 + 1;

        if (count >= page * 10) {
          this.setState({ pageStart: pageStart, pageEnd: page * 10 });
        } else {
          this.setState({ pageStart: pageStart, pageEnd: count });
        }
      }
    } else {
      this.setState({ totalPages: 0, page: 1, count: 0 });
    }
  }

  onFieldChange(event) {
    this.setState({ [event.target.name]: event.target.value, page: 1 });
  }

  callSearch() {
    if (this.state.filterSelected != 0) {
      this.getItens(this.state.page);
    }
  }

  async clearFields() {
    await this.setState({ filterSelected: 0, searchValue: "", page: 1 });
    this.getItens(1);
  }

  async changeValues(stateName, value, text = "") {
    this.setState({ filterSelected: value });
  }

  async getItens(page = 1) {
    const { userSession } = this.props;
    this.setState({ loading: true });

    let url = `/calleds/classifications/paginate/${this.state.userId}?page=${page}`;

    if (this.state.searchValue != "" && this.state.filterSelected != 0) {
      url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`;
    }
    await Api.get(url)
      .then((result) => {
        if (result.data.success) {
          let count = result.data.count;
          this.setState({ count: count });
          this.setPagination(count, page);

          const formattedList = result.data.data.map((item) => {

            if(item.calledclassification) {
              console.log("item.calledclassification")
              console.log(item.calledclassification)
              console.log(item.calledclassification.noteassigned)
              console.log(typeof item.calledclassification.noteassigned)
            }

            return {
              ...item,
              CalledClassificationsId: item.calledclassification
                ? item.calledclassification.id
                : 0,
              CalledId: item ? item.id : 0,
              Note: item.calledclassification && item.calledclassification.noteassigned && item.calledclassification.noteassigned > 0 ? <Rating name="read-only" value={item.calledclassification.noteassigned} readOnly /> : 'N/A',
              NoteRequest: item.calledclassification && item.calledclassification.noterequest && item.calledclassification.noterequest > 0 ? <Rating name="read-only" value={item.calledclassification.noterequest} readOnly /> : 'N/A',
              CalledText: item.title ? item.title : "",
              OpeningDate: item
                ? moment(item.openingdate).format("DD/MM/YYYY HH:mm")
                : "",
              Type: item ? result.data.data[0].type.name : "",
              AssignedUserText: item.assigneduser ? item.assigneduser.name : "",
              Requestinguser: item.requestinguser
                ? item.requestinguser.name
                : "",
            };
          });

          this.setState({ loading: false, resultList: formattedList });
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data &&
              result.data.response &&
              result.data.response.data &&
              result.data.response.data.errors &&
              result.data.response.data.errors[0]
                ? result.data.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response &&
            err.response.data &&
            err.response.data.errors &&
            err.response.data.errors[0]
              ? err.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(err);
      });
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes, headerMenu } = this.props;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container
            component="main"
            maxWidth="xl"
            style={{ paddingLeft: 0, marginLeft: 0 }}
          >
            <CssBaseline />

            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <Card className="background-title-search">
                  <CardContent>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={12} md={12} lg={3}>
                        <Typography variant="h5">
                          {
                            <FormattedMessage id="menu.called.classifications" />
                          }
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6} md={4} lg={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <SimpleSelect
                          label={intl.formatMessage({ id: "field" })}
                          options={this.state.filters}
                          stateName="filter"
                          changeSelect={this.changeValues}
                          selected={this.state.filterSelected}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={4} lg={3}>
                        <TextField
                          type="text"
                          name="searchValue"
                          value={this.state.searchValue}
                          placeholder={intl.formatMessage({ id: "search" })}
                          onChange={this.onFieldChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2} lg={1}>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          className={classes.button}
                          style={{ marginRight: 4, marginLeft: 8 }}
                          onClick={this.callSearch}
                        >
                          <Search />
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={6} md={2} lg={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={this.clearFields}
                          className={classes.button}
                        >
                          <ClearIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Can I="List" a="CalledClassification">
              <MyMaterialTable
                title=""
                rowClick={(event, rowData) => {
                  this.props.addCurrent(rowData);
                  if (rowData.CalledClassificationsId == 0) {
                    this.props.history.push(
                      `/${this.props.match.params.lang}/calledclassification-registration`
                    );
                  } else {
                    this.props.history.push(
                      `/${this.props.match.params.lang}/calledclassification-edit`
                    );
                  }
                }}
                columns={this.isClient ? ([
                  {
                    title: "ID",
                    field: "id",
                  },
                  {
                    title: intl.formatMessage({ id: "called" }),
                    field: "CalledText",
                  },
                  { title: intl.formatMessage({ id: "type" }), field: "Type" },
                  {
                    title: intl.formatMessage({ id: "open.date" }),
                    field: "OpeningDate",
                  },
                  {
                    title: "Atribuído para",
                    field: "AssignedUserText",
                  },
                  {
                    title: intl.formatMessage({ id: "csat.requester.to.assigned" }),
                    field: "Note"
                  },
                  {
                    title: intl.formatMessage({ id: "client" }),
                    field: "Requestinguser"
                  },
                ])
                : 
                (!this.globalAdm && !this.AdmOrganization && !this.isCoordenator) ? ([
                  {
                    title: "ID",
                    field: "id",
                  },
                  {
                    title: intl.formatMessage({ id: "called" }),
                    field: "CalledText",
                  },
                  { title: intl.formatMessage({ id: "type" }), field: "Type" },
                  {
                    title: intl.formatMessage({ id: "open.date" }),
                    field: "OpeningDate",
                  },
                  {
                    title: "Atribuído para",
                    field: "AssignedUserText",
                  },
                  {
                    title: intl.formatMessage({ id: "client" }),
                    field: "Requestinguser"
                  },
                  {
                    title: intl.formatMessage({ id: "csat.assigned.to.requester" }),
                    field: "NoteRequest"
                  },
                ])
                :
                ([
                  {
                    title: "ID",
                    field: "id",
                  },
                  {
                    title: intl.formatMessage({ id: "called" }),
                    field: "CalledText",
                  },
                  { title: intl.formatMessage({ id: "type" }), field: "Type" },
                  {
                    title: intl.formatMessage({ id: "open.date" }),
                    field: "OpeningDate",
                  },
                  {
                    title: "Atribuído para",
                    field: "AssignedUserText",
                  },
                  {
                    title: intl.formatMessage({ id: "csat.requester.to.assigned" }),
                    field: "Note"
                  },
                  {
                    title: intl.formatMessage({ id: "client" }),
                    field: "Requestinguser"
                  },
                  {
                    title: intl.formatMessage({ id: "csat.assigned.to.requester" }),
                    field: "NoteRequest"
                  },
                ])
                }
                data={this.state.resultList}
              />
            </Can>
            <Can I="List" a="CalledClassification">
              <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                <Grid item xs={12} md={3}>
                  <Pagination
                    count={this.state.totalPages}
                    page={this.state.page}
                    onChange={this.onChangePaginate}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                  {this.state.pageStart} {<FormattedMessage id="to" />}{" "}
                  {this.state.pageEnd} {<FormattedMessage id="of" />}{" "}
                  {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>
            </Can>
            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}

CalledClassifications.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(Styles)(CalledClassifications))
);
CalledClassifications.contextType = AbilityContext;
