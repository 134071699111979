import Api from "./api";

export const getById = async (id) => {
  let data = [];
  await Api.get(`/accompaniments/${id}`)
    .then((result) => {
      data = result;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};


export const getFile = async (hirerid,calledid,accompanimentid,filename,filerepositorymethod) => {
	let data = [];
	await Api.get(`/accompaniments/file/${hirerid}/${calledid}/${accompanimentid}?path=${filename}&filerepositorymethod=${filerepositorymethod}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  });
	return data;
  };