import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, InputLabel, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, Container, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import ApiSaaS from '../../../services/apiSaaS';
import CustomizedSnackbars from '../../../components/material-snackbars';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userSessionActions from '../../../redux/actions/user-session';
import Logo from '../../../components/logo/logo';

//import {AsyncStorage} from 'react';

const defaultProps = {
  borderColor: 'text.secondary',
  bgcolor: "text.secondary",
  color: "secondary.contrastText",
  m: 1,
  border: 1,
  style: { width: '15rem', height: '15rem' },
};


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInForm: {
        email: "",
        password: ""
      },
      rememberMe: false,
      loading: true,
      variant: "error",
      openNotification: false,
      notificationMessage: "",
      available: false,
      step: 1 //-> Aguardando o retorno do saas
    };

    this.headers = {
      'Content-Type': 'application/json',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.toggleRememberMe = this.toggleRememberMe.bind(this);
    this.rememberUser = this.rememberUser.bind(this);
    this.getRememberedUser = this.getRememberedUser.bind(this);
    this.forgetUser = this.forgetUser.bind(this);

    
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    const userlogin = await this.getRememberedUser();
    this.setState(prevState => ({
      signInForm: {
        ...prevState.signInForm,
        ['email']: userlogin ? userlogin :  ""
      },
      rememberMe: userlogin ? true : false
    }));

    /*ApiSaaS.get("/hirer/verifyHirerByHost/" + document.location.host+"/1")
      .then(async result => {

        if (result && result.data && result.data != null && result.data !== false && result.data.data != null) {
          if(result.data.data.phoneconfirmation && result.data.data.emailconfirmation && result.data.data.hirerconfig && result.data.data.hirerconfig.urlfrontcube == document.location.host){
            this.setState({
              step: 2
            });
          }else{
            this.setState({
              step: 1
            });
          }
          this.setState({
            available: true,
            loading: false
          });
        }else{
          this.setState({
            available: true,
            loading: false,
            step: 1
          });
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: this.props.intl.formatMessage({id:"process.error"})
          });

        }
      });*/
      this.setState({
        available: true,
        loading: false
      });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      signInForm: {
        ...prevState.signInForm,
        [name]: value
      }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    const intl = this.props.intl;
    const { email, password } = this.state.signInForm;

    Api.post("/hirer/login", { email, password }, {
      headers: this.headers
    })
      .then(async result => {
        if(result && result.data && result.data.data && result.data.success){
          localStorage.setItem('accesstoken', result.data.data.accesstoken);
          localStorage.setItem('reload', true);
          localStorage.setItem('multihirer', true);
          this.props.addUserSession(result.data.data);
          document.location.href=`/pt/admin/hirer`
        }else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: intl.formatMessage({id:"email.and.or.password.invalid"})
          });
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: this.props.intl.formatMessage({id:"process.error"})
          });

        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  toggleRememberMe = (event, value) => {
    this.setState({ rememberMe: value })
    if (value === true) {
      this.rememberUser();
    } else {
      this.forgetUser();
    }
  }

  rememberUser = async () => {
    try {
      await localStorage.setItem('field-service-cmetch-admin', this.state.signInForm.email);
    } catch (error) {
    }
  };
  getRememberedUser = async () => {
    try {
      const userlogin = await localStorage.getItem('field-service-cmetch-admin');
      if (userlogin !== null) {
        return userlogin;
      }
    } catch (error) {
    }
  };
  forgetUser = async () => {
    try {
      await localStorage.removeItem('field-service-cmetch-admin');
    } catch (error) {
    }
  };

  verify = async () => {
    this.setState({ loading: true })

    const { email } = this.state.signInForm;

    var data = new FormData();

    data.append("responsiblemail", email)
    data.append("host", document.location.host)
    data.append("applicationid", 1)

    ApiSaaS.post("/hirer/verifyHirerByHostAndRespEmail", data)
    .then(result => {
      if(result && result.data && result.data.data !== false){
        this.setState({
          step: 2,
          loading: false
        });
      }else {
        this.setState({ loading: false })
        this.nextStep();
      }
    })
    .catch(err => {
      if (err.response && err.response.data && err.response.data.errors) {
        this.setState({
          loading: false,
          openNotification: true,
          variant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
      }
      else {
        this.setState({
          loading: false,
          openNotification: true,
          variant: "error",
          notificationMessage: this.props.intl.formatMessage({id:"process.error"})
        });

      }
    })
  }

  nextStep = async () => {

    this.setState({ loading: true });
    const intl = this.props.intl;
    const { email } = this.state.signInForm;

    Api.post("/hirer/sendConfirmationEmail", { email }, {
      headers: this.headers
    })
    .then(async result => {
      if(result && result.data && result.data.data !== false){
        this.setState({
          loading: false,
          openNotification: true,
          variant: "success",
          notificationMessage: intl.formatMessage({id:"email.sent"}),
          step: 3
        });
      }else{
        this.setState({
          loading: false,
          openNotification: true,
          variant: "error",
          notificationMessage: intl.formatMessage({id:"mail.not.found"})
        });
      }
    })
    .catch(err => {
      if (err.response && err.response.data && err.response.data.errors) {
        this.setState({
          loading: false,
          openNotification: true,
          variant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
      }
      else {
        this.setState({
          loading: false,
          openNotification: true,
          variant: "error",
          notificationMessage: this.props.intl.formatMessage({id:"process.error"})
        });

      }
    });
  }

  render() {
    const { classes } = this.props;
    const { signInForm } = this.state;
    const intl = this.props.intl;
    
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Logo key="groups-fieldservice-awrar6aw15" />

          {this.state.available && (
            <>

              <Typography component="h1" variant="h5">
                {Api.applicationName()}
              </Typography>

              <Typography style={{ marginTop: "8px" }} component="h1" variant="h5">
                {intl.formatMessage({id:"admin.login.admin.sector"})}
              </Typography>
              <form className={classes.form} onSubmit={this.handleSubmit}>
                {this.state.step == 3 && (
                  <Typography style={{color: "#31ac29", textAlign: "center", marginBottom: "8px" }} component="h1" variant="h5">
                    {intl.formatMessage({id:"admin.login.mailbox.confirm.email"})}
                  </Typography>
                )}
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={this.props.intl.formatMessage({id:"email"})}
                  name="email"
                  autoComplete="email"
                  type="email"
                  autoFocus
                  value={signInForm.email}
                  onChange={this.handleInputChange}
                />

                {this.state.step == 2 && (
                  <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={intl.formatMessage({id:"passwords"})}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    inputProps={{ maxLength: 32, minLength: 6 }}
                    value={signInForm.password}
                    onChange={this.handleInputChange}
                  />
                  </>
                )}

                <Switch
                  value={this.state.rememberMe}
                  checked={this.state.rememberMe}
                  onChange={this.toggleRememberMe}
                />{intl.formatMessage({id:"remember.me"})}

                {this.state.step == 2 && (
                  <>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="admin"
                      className={classes.submit}
                    >
                      {intl.formatMessage({id:"log.in"})}
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Link href="/pt/admin/forgot-password/" variant="body2">
                          {intl.formatMessage({id:"forgot.password"})}
                        </Link>
                      </Grid>
                    </Grid>
                  </>
                )}

                {(this.state.step == 1 || this.state.step == 3 ) && (
                  <>
                    <Button
                      onClick={this.verify}
                      fullWidth
                      variant="contained"
                      color="admin"
                      className={classes.submit}
                    >
                      {intl.formatMessage({id:"send"})}
                    </Button>
                  </>
                )}

                
              </form>
            </>
          )}

          {!this.state.available && (
            <>
              <Typography style={{ marginTop: "8px" }} component="h1" variant="h5">
                {intl.formatMessage({id:"unauthorized.access.page"})}
              </Typography>
            </>
          )}

        </div>
        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.variant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(userSessionActions, dispatch)

export default connect(null, mapDispatchToProps)(withStyles(Styles)(Login));
