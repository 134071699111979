import { createStore,applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import thunk from 'redux-thunk';
import timeentries from './reducers/timeentries';


const persistConfig = {
    key: "root",
    storage,
    whitelist: ['userSession', 'calleds','organizations','categories','slas','types',
    'segments', 'reasons','activitys','npssearchs','timeentries','groups','addresses','organizationsettings','originrequests','calledclassifications',
    'users','techinicalteams','techinicalteamusers','techinicalteamorganizationcategories','techinicalteamorganizations',
    'categoryorganizations','roles','permissions','organizationcalendars','assignedcalleds','statuses',
    'configs','calledflows','calledflowstatuses','distributionstrategies','organizationcategories',
    'organizationslas','organizationslacalleds','scheduleworkhours','slacounttypes','workhours','calledvideocalls', 
    'holidays', 'calledstatuses','materials','organizationdepartaments','organizationmaterials',
    'organizationpatrimonies','patrimonygroups','patrimonymaterials','patrimonysubgroups', 'calledorganizationmaterials',
    'organizationaccountemails', 'organizationfields', 'organizationchecklists','organization', 'knowledgebases', 
    'calledorganizationchecklists', 'calledorganizationfields', 'additionalcalledcosts', 'multihirer', 'defaultactions',
    'priorities', 'userOrganizations', 'calledclosingstatuses', 'calledlistdatas', 'calledreportconfig', "additionalcalledcostitems", "additionalcalledcostitemtypes", "additionalcalledcostitemreasons", 'organizationfieldreportblocks',]
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = applyMiddleware(thunk)( createStore )(persistedReducer);

const persistor = persistStore(store);

export { store, persistor };
