import React, { Component, Fragment } from "react";
import clsx from 'clsx'
import PropTypes from "prop-types";
import { Button, CssBaseline, TextField, Grid, CircularProgress, Container, Radio, RadioGroup, FormControl, FormControlLabel, Tabs, CardHeader, CardActions, Collapse, TableContainer, TableBody, Table, TableCell, TableRow, InputLabel } from "@mui/material";
import { Menu, MenuItem, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import { withStyles, useTheme } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import * as CalledService from '../../services/called.service';
import * as AccompanimentService from '../../services/accompaniment.service';
import CustomizedSnackbars from "../../components/material-snackbars";
import SimpleAutoCompleteAsynchronousKnowledgebase from '../called-registration/components/auto-complete-async-knowledgebase';
import * as AddressService from '../../services/address.service'
import SimpleSelect from "../../components/select/simple";
import SimpleDatePicker from "../../components/date-pickers/inline";
import { DeleteForever, AttachFile } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClearIcon from '@mui/icons-material/Clear';
import AccompanimentAction from '../../components/accompaniment-actions';
import AccompanimentActionVideo from '../../components/accompaniment-actions/video';
import * as calledsActions from '../../redux/actions/calleds';
import * as headerMenuActions from '../../redux/actions/header-menu';
import ImageList from '@mui/material/ImageList';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import Add from '@mui/icons-material/Add';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteByName from '../../components/auto-complete/autocomplete-by-name';
import SimpleAutoCompleteAsynchronousOrganizationCategory from './components/autocomplete-asynchronous-organization-category'
import SimpleAutoCompleteAsynchronousTechnicalUsers from './components/autocomplete-asynchronous-technical-users'
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import { FormattedMessage, injectIntl } from 'react-intl';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import TransitionsModal from '../../components/modal/modal';
import CalledFinish from '../../calledFinish/src/js/CalledFinish';
import DuoIcon from '@mui/icons-material/Duo';
import moment from 'moment';
import * as Service from "../../services/organizationpatrimony.service";
import { getDepartamentByUserId } from '../../services/user.service'
import { getDepartamentsByOrganizationCategoryId } from '../../services/organizationcategory.service';
import { getOrganizationDepartamentsById, getOrganizationsDepartaments } from '../../services/organizationdepartament.service';
import * as CalledOrganizationChecklistService from '../../services/calledorganizationchecklist.service'
import { addAccompanimentToKnowledgeBase, deleteKnowledgeBaseByAccompanimentId } from "../../services/knowledgebase.service";
import { getByCalledIdAndHistoryCheckType, getByAccompanimentIdAndHistoryCheckType, getAccompanimentsToFinish } from '../../services/called.service'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import MiniMap from '../../components/minimap/external-state';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ModalPatrimonio from "../../components/ModalPatrimonio";
import ModalVideoCall from "../../components/modal-video-call";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SimpleHelp from '../../components/help';
import { IconButton } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PauseIcon from '@mui/icons-material/Pause';
import BusinessIcon from '@mui/icons-material/Business';
import SaveIcon from '@mui/icons-material/Save';
import { PapiroConsole } from '../../utils/papiroConsole';
import If, { Else } from '../../components/conditionals/if'
import NumberFormat from "react-number-format"
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Tab as RTab, Tabs as RTabs, TabList as RTabList, TabPanel as RTabPanel } from 'react-tabs';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SimpleCheckBox from "../../components/checkbox/check";
import { Cell, Pie, PieChart } from "recharts";
import { Tooltip as PieToolTip } from "recharts";
import { styled } from "@mui/styles";
import Budget from "../dashboard/components/budget";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import './style.css'
import { Can } from '../../config/ability-context';
import DialogConfirmation from '../../components/dialog-confirmation-not-button'
import DeleteIcon from '@mui/icons-material/Delete';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'
import * as PriorityService from '../../services/priority.service'
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import InfoIcon from '@mui/icons-material/Info';
import SimpleSelectWithColor from "../../components/select/simple-with-color";
import { withRouter } from "react-router-dom";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Chip from '@mui/material/Chip';
import { useParams } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ChecklistModal from "../../components/checklist-modal";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileOpenIcon from '@mui/icons-material/FileOpen';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`finish-accompaniment-tabs-${index}`}
        aria-labelledby={`finish-accompaniment-tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box variant="fullWidth" p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `finish-accompaniment-tabs-${index}`,
      'aria-controls': `finish-accompaniment-tabpanel-${index}`,
    };
  }

class CalledRegistration extends Component {

    constructor(props) {
        super(props);
        const { id } = this.props.match.params
        const { userSession, calleds } = this.props;

        this.urlId = id

        PapiroConsole.log("=== CalledRegistration -> constructor ===")
        PapiroConsole.log(this.props.match)
        PapiroConsole.log(calleds)
        PapiroConsole.log(id)
        PapiroConsole.log(userSession)

        this.phone = "";
        this.canstartcallcenter = false;
        this.globalAdm = false;
        this.AdmOrganization = false;
        this.isCoordenator = false;
        this.isTechinical = false;
        this.isClient = true;
        this.OrganizationCategoryId = null;

        this.valueCostAttendance = null
        this.valueTimeAttendance = null

        if (userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }
        if(!calleds || (calleds && !calleds[`called-${this.urlId}`])) {
            var l = this.props.match.params.lang;
            //document.location.href=`/${l}/calleds`
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0) {
                this.globalAdm = true;
            }
            if (item.role.roletype == 1) {
                this.AdmOrganization = true;
            }
            if (item.role.roletype == 4) {
                this.isCoordenator = true;
            }
            if (item.role.roletype == 2) {
                this.isTechinical = true;
            }
        });

        this.sametechinical = false;
        this.samePerson = false;
        this.canselectpatrimonyincalled = false;
        this.canclientselectpatrimonyincalled = false;
        this.canclientselecttypeincalled = false;
        this.caninformzeroincalledtechnicalcost = false;
        this.caninforminitialchecklist = false;
        this.caninitialchecklistisrequired = false;
        this.caninformutilizedmaterialsincalledfinish = false;
        this.canclientfinishcalled = false
        this.canclientreopencalled = false
        this.canclientautomaticchangestatusaftersendaccompaniment = false
        this.infoRequest = [];
        this.otherClientInteractsWithCalled = false;

        var dataNPS = null;
        var msgNPS = null;

        const defaultvalue = { id: 0, name: "", parentname: "" };

        this.reactQuillModules = {
            toolbar: [
              [{ 'header': [1, 2, false] }],
              ['bold', 'italic', 'underline','strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image'],
              ['clean']
            ],
        }
        
        this.reactQuillFormats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image'
        ]

        this.levelCategoriesInitialState = {
            rootCategoriesList: [],
        }

        this.calledGridData = calleds[`called-${this.urlId}`]

        this.orgCatPriority = calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory && calleds[`called-${this.urlId}`].organizationcategory.priorityid && calleds[`called-${this.urlId}`].organizationcategory.priorityid > 0 ? calleds[`called-${this.urlId}`].organizationcategory.priorityid : 0

        this.addressInitialState = {
            updatedUser: userSession.user.id,
            updatedAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            createdUser: userSession.user.id,
            createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            UserText: '',
            template: true,
            template2: false,
            id: 0,
            name: '',
            street: '',
            zipcode: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
            country: '',
            longitude: '',
            latitude: '',
            keepaddress: false,
            iscustomaddress : true, 
            saveToAllOrganizationsInGroup: false,
        };

        this.state = {
            clientClickFinishCalled: false,
            addActionAnchorEl: null,
            addActionOpen: false,
            address: this.addressInitialState,
            levelCategoriesReverse: this.levelCategoriesInitialState,
            categoryChildrensReverse: [ defaultvalue ],
            categoryListsReverse: [],
            levelCategories: this.levelCategoriesInitialState,
            categoryChildrens: [ defaultvalue ],
            categoryLists: [],
            search: {
                technicalUsersList: `/users/category/${calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategoryid ? calleds[`called-${this.urlId}`].organizationcategoryid : 0}/organization/${calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationid ? calleds[`called-${this.urlId}`].organizationid : 0}`,
                categoriesList: `/organizationcategories/organization/${calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationid ? calleds[`called-${this.urlId}`].organizationid : 0}`,
                knowledgebase: '/knowledgebases'
            },
            isDisabled : true,
            countLoading: 0,
            userId: userSession.user.id,
            organizationFieldList: [],
            MapCalledsToKnowledgeBase : null,
            savedReason: false,
            priority: null,
            sectableStatus: [],
            called: {
                id: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].id ? calleds[`called-${this.urlId}`].id : 0,
                CreatedUser: userSession.user.id,
                requestingUser: 0,
                organization: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.id,
                organizationkey: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.organizationkey,
                orgname: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.name,
                organizationsettings: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.organizationsettings ? calleds[`called-${this.urlId}`].organization.organizationsettings : {
                    id: 0,
                    canviewcalledfromothertechnical: false,
                    canclientselecttypeincalled: false,
                    roleclientcanselectcategory: 0
                },
                type: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].type && calleds[`called-${this.urlId}`].type.id,
                OrganizationCategoryId: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory,
                ParentCalledId: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].parentcalledid,
                ParentCalledText: '',
                OrganizationCategoryText: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory && calleds[`called-${this.urlId}`].organizationcategory.name,
                parentCategory:  calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory.parentorganizationcategory && calleds[`called-${this.urlId}`].organizationcategory.parentorganizationcategory.id,
                dueDate: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].duedate,
                openingDate: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].openingdate,
                title: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].title,
                addressid: 0,
                addresstype: "1",
                status: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].status && calleds[`called-${this.urlId}`].status.id,
                description: '',
                priorityid: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].priorityid && calleds[`called-${this.urlId}`].priorityid > 0 ? calleds[`called-${this.urlId}`].priorityid : this.orgCatPriority,
                priority: null,
                CallDueDate: null,
                originRequest: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].originrequest.id,
                observantUser: 0,
                assignedUser: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].assigneduser,
                updatedUser: userSession.user.id ? userSession.user.id : 0,
                quantityreama4: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].quantityreama4 ? calleds[`called-${this.urlId}`].quantityreama4 : 0,
                securitypassword: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].securitypassword,
                patrimonyId: "",
                classification: dataNPS,
                departamentIdN: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationdepartamentid ? calleds[`called-${this.urlId}`].organizationdepartamentid : 0,
                departamentId: 0,
                employeehourvalue: (calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].employeehourvalue && calleds[`called-${this.urlId}`].employeehourvalue > 0) ? calleds[`called-${this.urlId}`].employeehourvalue : (calleds.defaulttechnicalemployeehourvalue && calleds.defaulttechnicalemployeehourvalue > 0) ? calleds.defaulttechnicalemployeehourvalue : 0,
                valueforcalled: (calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].valueforcalled && calleds[`called-${this.urlId}`].valueforcalled > 0) ? calleds[`called-${this.urlId}`].valueforcalled : (calleds.defaulttechnicalvalueforcalled && calleds.defaulttechnicalvalueforcalled > 0) ? calleds.defaulttechnicalvalueforcalled : 0,
                costparameter: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].costparameter ? calleds[`called-${this.urlId}`].costparameter : 1,
                hasdisplacement: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].hasdisplacement,
                //gasvalueperliter: calleds[`called-${this.urlId}`].gasvalueperliter > 0 ? calleds[`called-${this.urlId}`].gasvalueperliter : calleds.configdisplacement ? calleds.configdisplacement.gasvalueperliter : 0,
                kmvaluerotated: (calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].kmvaluerotated && calleds[`called-${this.urlId}`].kmvaluerotated > 0 ) ? calleds[`called-${this.urlId}`].kmvaluerotated : (calleds.defaulttechnicalkmvaluerotated && calleds.defaulttechnicalkmvaluerotated > 0) ? calleds.defaulttechnicalkmvaluerotated : (calleds.configdisplacement && calleds.configdisplacement.kmvaluerotated > 0 ) ? calleds.configdisplacement.kmvaluerotated : 0,
                finishaccompanimentid: calleds.finishaccompanimentid ? calleds.finishaccompanimentid > 0 ? calleds.finishaccompanimentid : null : null,
                previousfinishaccompanimentid: calleds.finishaccompanimentid ? calleds.finishaccompanimentid > 0 ? calleds.finishaccompanimentid : null : null,
                hasdisplacementTrue: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].hasdisplacement == true ? true : false,
                hasdisplacementFalse: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].hasdisplacement == false ? true : false,
                hasParentCalledTrue: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].parentcalledid != null ? true : false,
                hasParentCalledFalse: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].parentcalledid == null ? true : false,
                hasPatrimoniesTrue: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].calledorganizationpatrimonies && calleds[`called-${this.urlId}`].calledorganizationpatrimonies.length > 0 ? true : false,
                hasPatrimoniesFalse: (calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].calledorganizationpatrimonies == null) || (calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].calledorganizationpatrimonies && calleds[`called-${this.urlId}`].calledorganizationpatrimonies.length == 0) ? true : false,
                calledstatus: [],
                calledattachments: [],
                knowledgebaseattachments: [],
                calledclosingstatusname: null,
            },
            action: {
                description: "",
                privacy: "false"
            },
            cost: {
                values: {
                    totalValueCostInLoco: 0.0,
                    totalValueCostRemote: 0.0,
                    totalValueCostDisplacement: 0.0,
                    totalValueCostTimeEntries: 0.0,
                    totalValueReplacedMaterials: 0.0,
                    totalValueCost: 0.0,
                },
                hours: {
                    totalHoursDisplacement: 10.0,
                    totalHoursInLoco: 30.0,
                    totalHoursRemote: 10.0,
                },
                totalCalledUsers: 0,
                totalTimeEntries: 0,
                totalAccompaniments: 0,
                totalAttachments: 0,
                totalMB: 0.0,
                totalKilometersDisplacement: 0.0,
                countReplacedMaterials: 0
            },
            costBody: {
                attendanceHours: null,
                attendanceRemote: null,
                attendanceCost: null,
                attendanceAdditionalInfo: null,
                attendanceHoursExpanded: false
            },
            files: [],
            loading: true,
            loadingTwo: false, //Criei pois o loading acima não está mudando o state
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            categoriesList: [],
            parentCalledsList: [],
            categoriesListAll: [],
            technicalUsersList: [],
            clientUsersList: [],
            userAddressesList: [],
            typesList: [],
            organizationAddressesList: [],
            calledValue: 'R$ 0,00',
            calledTime: '00:00:00',
            statusList: [],
            anchorEl: null,
            FinishCalledId: 0,
            ReopenCalledId: 0,
            samePerson: this.samePerson,
            reloadBtn: true,
            disablebutton: false,
            cloneCalledModalOpen: false,
            CalledModalOpen: false,
            locationModalOpen: false,
            positionCalled: [-8.06224030, -34.87254230],
            addressCalled: '',
            accompanimentLocationModalOpen: false,
            positionAccompCalled: [-8.06224030, -34.87254230],
            addressAccompCalled: '',
            cloneCalledAmount: null,
            calledorganizationmaterial: false,
            calledusers: false,
            calledorganizationFields: false,
            calledtimeentries: false,
            calledadditionalservices: false,
            initialcalledorganizationchecklists: false,
            finalcalledorganizationchecklists: false,
            calledorganizationpatrimonies: false,
            modalopen: false,
            user: userSession.user,
            modalopenFinish: false,
            patrimonyList: [],
            statusisFinish: false,
            modalPatrimonio: false,
            msgClassification: msgNPS,
            requestingUser: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].requestinguser,
            orgSettings: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.organizationsettings,
            organization: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization,
            organizationkey: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization && calleds[`called-${this.urlId}`].organization.organizationkey ? calleds[`called-${this.urlId}`].organization.organizationkey : '',
            disabledvertex: true,
            hirerid: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`] ? calleds[`called-${this.urlId}`].hirerid : 0,
            slaOverflowContentModalOpen: false,
            reasonsList: [],
            calledReasonId: null,
            reasonOther: '',
            userDepartamentId: 0,
            DepartamentList: [],
            departamentId: 0,
            finishAccompaniment: false,
            finishAccompanimentModalOpen: false,
            defaultActionModalOpen: false,
            defaultActionList: [],
            defaultActionId: 0,
            finishAccompanimentList: [],
            finishAccompanimentId: 0,
            finishAccompanimentValue: 0,
            directSaveCalled: false,
            rTabIndex: 0,
            directFinishCalled: false,
            todayDate: moment().format('YYYY/MM/DD'),

            openDialogNewDisk: false,
            valueNewDisk: 0.00,
            newDiskAccept: false,
            priorityList: [],
            typeDisabled: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory && calleds[`called-${this.urlId}`].organizationcategory.typeid && calleds[`called-${this.urlId}`].organizationcategory.typeid > 0 ? true : false,
            priorityDisabled: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory && calleds[`called-${this.urlId}`].organizationcategory.priorityid && calleds[`called-${this.urlId}`].organizationcategory.priorityid > 0 ? true : false,
            caneditcalledtitleanddescription: true,
            canselectorganizationcategoriesbylevels: false,
            caninforminitialchecklist: false,
            caninitialchecklistisrequired: false,
            calledorganizationid: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationid,
            knowledgebase: null,
            knowledgeBaseList: [],
            knowledgeBaseDialogOpen: false,
            initialChecklistModalOpen: false,
            confirmReopenCalledClientDialog: false,
            totalInitialChecklists: 0,
            totalCompletedInitialChecklists: 0,
            clientStatus: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].status,
            oldStatus: null
        };

        //this.theme = useTheme()
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getCalled = this.getCalled.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.getAccompainmentFile = this.getAccompainmentFile.bind(this);
        this.useStyles = this.useStyles.bind(this);
        this.videoCallClick = this.videoCallClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getOrganizationAddresses = this.getOrganizationAddresses.bind(this)
        this.reopenCalledClient = this.reopenCalledClient.bind(this);
        this.finishCalledClient = this.finishCalledClient.bind(this);
        this.checkFinishCalled = this.checkFinishCalled.bind(this);
        this.openModal = this.openModal.bind(this);
        this.pauseCalled = this.pauseCalled.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeModalFinish = this.closeModalFinish.bind(this);
        this.cancellCalled = this.cancellCalled.bind(this);
        this.cloneCalled = this.cloneCalled.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkRoleToDisable = this.checkRoleToDisable.bind(this)
        this.closeCloneCalledModal = this.closeCloneCalledModal.bind(this);
        this.closeCalledModal = this.closeCalledModal.bind(this);
        this.refreshAccompaniments = this.refreshAccompaniments.bind(this);
        this.submitAction = this.submitAction.bind(this);
        this.getPatrimonySubgroups = this.getPatrimonySubgroups.bind(this);
        this.addAccompanimentActionToKnowledgeBase = this.addAccompanimentActionToKnowledgeBase.bind(this);
        this.submitCalled = this.submitCalled.bind(this);
        this.saveCalled = this.saveCalled.bind(this);
        this.finishCalled = this.finishCalled.bind(this)
        this.openLocationModal = this.openLocationModal.bind(this)
        this.closeLocationModal = this.closeLocationModal.bind(this)
        this.openAccompanimentLocationModal = this.openAccompanimentLocationModal.bind(this)
        this.closeAccompanimentLocationModal = this.closeAccompanimentLocationModal.bind(this)
        this.openFinishAccompanimentModal = this.openFinishAccompanimentModal.bind(this)
        this.closeFinisAccompanimentModal = this.closeFinisAccompanimentModal.bind(this)
        this.setCountLoading = this.setCountLoading.bind(this)
        this.openPatrimonioModal = this.openPatrimonioModal.bind(this)
        this.LoadUI = this.LoadUI.bind(this)
        this.renderCustomizedLabel = this.renderCustomizedLabel.bind(this)
        this.showCalledReport = this.showCalledReport.bind(this)
        this.showCostReport = this.showCostReport.bind(this)
        this.showHoursReport = this.showHoursReport.bind(this)
        this.showInLocoReport = this.showInLocoReport.bind(this)
        this.handleExpandAttendanceHoursCard = this.handleExpandAttendanceHoursCard.bind(this)
        this.getCalledCostToCard = this.getCalledCostToCard.bind(this)
        this.detailCardsCost = this.detailCardsCost.bind(this)
        this.openDefaultActionDialog = this.openDefaultActionDialog.bind(this)
        this.closeDefaultActionDialog = this.closeDefaultActionDialog.bind(this)
        this.saveDefaultAction = this.saveDefaultAction.bind(this)
        this.getDefaultActions = this.getDefaultActions.bind(this)
        this.getPriorities = this.getPriorities.bind(this)
		this.handleChangeEditor = this.handleChangeEditor.bind(this)
        this.handleChangeEditorAction = this.handleChangeEditorAction.bind(this)
        this.openKnowledgeBaseDialog = this.openKnowledgeBaseDialog.bind(this)
        this.closeKnowledgeBaseDialog = this.closeKnowledgeBaseDialog.bind(this)
        this.convertHtmlOption = this.convertHtmlOption.bind(this)
        this.changeValuesKnowledgebase = this.changeValuesKnowledgebase.bind(this)
        this.insertKnowledgebase = this.insertKnowledgebase.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getClientsByOrganizationId = this.getClientsByOrganizationId.bind(this)
        this.getUserAddresses = this.getUserAddresses.bind(this)
        this.getCep = this.getCep.bind(this)
        this.handleInputChangeAddress = this.handleInputChangeAddress.bind(this)
        this.setCheckConfig = this.setCheckConfig.bind(this)
        this.addActionHandleClick = this.addActionHandleClick.bind(this)
        this.addActionHandleClose = this.addActionHandleClose.bind(this)
        this.getCalledGridById = this.getCalledGridById.bind(this)
        this.convertCalled = this.convertCalled.bind(this)
        this.openConfirmReopenCalledClientDialog = this.openConfirmReopenCalledClientDialog.bind(this)
        this.closeConfirmReopenCalledClientDialog = this.closeConfirmReopenCalledClientDialog.bind(this)
        this.confirmReopenCalledClient = this.confirmReopenCalledClient.bind(this)
		//this.LoadUI();
    };
    

    addActionHandleClick = (event) => {
        this.setState({ addActionAnchorEl: event.currentTarget, addActionOpen: true })
    }

    addActionHandleClose = (id = 0) => {
        this.setState({ addActionAnchorEl: null, addActionOpen: false })

        if (id > 0) {
            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    status: id
                }
            }), () => {
                this.submitAction()
            })
        }
    }

    setCheckConfig = (totalChecklists, completedChecklists) => {
        if (totalChecklists == this.state.totalInitialChecklists)
            this.setState({ totalCompletedInitialChecklists: completedChecklists})
    }

    openKnowledgeBaseDialog = () => this.setState({ knowledgeBaseDialogOpen: true })

    closeKnowledgeBaseDialog = () => this.setState({ knowledgeBaseDialogOpen: false })

    convertHtmlOption = value => {
        let body = value
        let temp = document.createElement("div")
        temp.innerHTML = body
        let sanitized = temp.textContent || temp.innerText

        PapiroConsole.log("=== convertHtmlOption ===")
        PapiroConsole.log(temp.textContent)
        PapiroConsole.log(temp.textContent.length)
        PapiroConsole.log(temp.innerText)
        PapiroConsole.log(temp.innerText.length)
        
        return sanitized
    }

    changeValuesKnowledgebase = (stateName, value, text = '') => {
        this.setState({ [stateName]: value });
    }

    insertKnowledgebase = (e) => {
        e.preventDefault()

        if(this.state.knowledgebase != null) {
          if(this.state.knowledgebase.knowledgebaseattachments != null && this.state.knowledgebase.knowledgebaseattachments.length > 0) {
            let attachmentList = []
            this.state.knowledgebase.knowledgebaseattachments.forEach(element => {
              if(element != null && element.attachment != null) {
                attachmentList.push(element.attachment)
              }
            });
            this.setState(prevState => ({
              called: {
                ...prevState.called,
                knowledgebaseattachments: attachmentList
              }
            }))
          }
          if(this.state.knowledgebase.description != null) {
            let convKnowldgbs = this.convertHtmlOption(this.state.knowledgebase.description)
            convKnowldgbs = convKnowldgbs.trim()
            if(convKnowldgbs.length > 0) {
              this.setState(prevState => ({
                action: {
                  ...prevState.action,
                  description: this.state.knowledgebase.description || ""
                }
              }))
            }
          }
        }
        this.closeKnowledgeBaseDialog()
    }

    deleteItem = (file) => {
        if(file != null && file.id != null) {
          let newListItem = this.state.called.knowledgebaseattachments.filter(item => item.id != file.id)
          this.setState(prevState => ({
            called: {
              ...prevState.called,
              knowledgebaseattachments: newListItem
            }
          }))
        }
    }

    saveDefaultAction = () => {
        if(this.state.defaultActionId && this.state.defaultActionId.text && this.state.defaultActionId.text.length > 0) {
            this.setState(prevState => ({
                action: {
                    ...prevState.action,
                    description: this.state.defaultActionId.text || ""
                },
                defaultActionModalOpen: !this.state.defaultActionModalOpen
            }))
        }
    }

    openDefaultActionDialog = async () => {
        const intl = this.props.intl;
        this.setState({ loading: true });
        await Api.get(`/defaultaction`)
            .then(result => {
                if (result && result.data && result.data.data) {

                    let formmatedList = result.data.data.map(item => {
                        return {
                            ...item,
                            name: item.text
                        }
                    })

                    this.setState({
                        defaultActionModalOpen: !this.state.defaultActionModalOpen,
                        defaultActionList: formmatedList && formmatedList.length > 0 ? formmatedList.sort((a, b) => a.name.localeCompare(b.name)) : formmatedList,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
        });
    }

    getDefaultActions = async () => {
        const intl = this.props.intl;
        this.setState({ loading: true });
        await Api.get(`/defaultaction`)
            .then(result => {
                if (result && result.data && result.data.data) {
                    let formmatedList = result.data.data.map(item => {
                        let body = item.text
                        let temp = document.createElement("div")
                        temp.innerHTML = body
                        let sanitized = temp.textContent || temp.innerText

                        return {
                            ...item,
                            name: sanitized,
                            Name: item.text
                        }
                    })

                    this.setState({
                        defaultActionList: formmatedList && formmatedList.length > 0 ? formmatedList.sort((a, b) => a.name.localeCompare(b.name)) : formmatedList,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
        });
    }

    getPriorities = async () => {
        let result = await PriorityService.getAllByHirerId()
        if(result && result.success) {
            if(result.data != null) {
                this.setState({ priorityList: result.data && result.data.length > 0 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data })
                if(this.state.called.priorityid && this.state.called.priorityid > 0 && this.state.called.priority == null) {
                    let priorityFilter = result.data.filter(item => item.id == this.state.called.priorityid)
                    if(priorityFilter && priorityFilter.length > 0)
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                priority: priorityFilter[0]
                            }
                        }))
                }
            }
        } else {
            const intl = this.props.intl
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
        }
    }

    closeDefaultActionDialog = () => this.setState({ defaultActionModalOpen: !this.state.defaultActionModalOpen })

    closeSLAOverflowModal = () => this.setState({ slaOverflowContentModalOpen: !this.state.slaOverflowContentModalOpen })

    closeSLAOverflowModaButton = () => this.setState({ modalopenFinish: !this.state.modalopenFinish, slaOverflowContentModalOpen: !this.state.slaOverflowContentModalOpen })

    closeCloneCalledModal = () => this.setState({ cloneCalledModalOpen: !this.state.cloneCalledModalOpen })

    closeCalledModal = () => this.setState({ CalledModalOpen: !this.state.CalledModalOpen })

    closeLocationModal = () => this.setState({ locationModalOpen: !this.state.locationModalOpen })

    closeAccompanimentLocationModal = () => this.setState({ accompanimentLocationModalOpen: !this.state.accompanimentLocationModalOpen })

    closeFinisAccompanimentModal = () => {
        let prev = this.state.called.previousfinishaccompanimentid
        this.setState(prevState => ({
            finishAccompanimentModalOpen: !this.state.finishAccompanimentModalOpen,
            called: {
                ...prevState.called,
                finishaccompanimentid: prev
            }
        }))
    }

    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
          <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        );
    };

    setFinishAccompanimentId = (item) => {
        this.setState({ rTabIndex: 1 },
            () => {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        finishaccompanimentid: item
                    }
                }))
            }
        )
    }

    setActionDescription = (item) => {
        this.setState({ rTabIndex: 0 },
            () => {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        finishaccompanimentid: null
                    },
                    action: {
                        description: item || "",
                        privacy: "false"
                    }
                }))
            }
        )
    }

    handleChangeTab = (event, newValue) => {
        //console.log("=== handleChangeTab ===")
        //console.log(newValue)
        this.setState({ finishAccompanimentValue: newValue })
    }

    handleChangeTabIndex = (index) => {
        //console.log("=== handleChangeTabIndex ===")
        //console.log(index)
        this.setState({ finishAccompanimentValue: index })
    }

    setCountLoading(message = "") {


        this.setState({ countLoading: this.state.countLoading + 1 }, () => {
            if (!this.isClient) {
                // verificar porque não está batendo 11 no contador - Eduardo | Porto
                if (this.state.countLoading >= 11) {
                    this.setState({
                        loading: false,
                    });
                }
            }
            else {
                if (this.state.countLoading >= 5) {
                    this.setState({
                        loading: false,
                    });
                }
            }

        });
    }

    getOrganizationFields = async calledId => {

        var result = await CalledOrganizationChecklistService.getCalledOrganizationFieldsByCalled(calledId);

        if (result.success) {
            this.setState({ organizationFieldList: result.data && result.data.length > 0 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
            result.data.map(item => {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['ofield-' + item.id]: item.value
                    }
                }))
            })
            this.setCountLoading("getOrganizationFields");
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            this.setCountLoading("getOrganizationFields");
            Api.kickoff(result);
        }
    };

    async componentDidMount() {
        await this.LoadUI()
        this.checkRoleToDisable()
        this.showCalledReport()

    };

    convertTohoursFormat = (minutos) => {
        let calledHourLoc = minutos ? minutos : "00:00:00"
        if(calledHourLoc && calledHourLoc > 0) {
            var splitCalledHoutLoc = [
                (calledHourLoc > 0) ? Math.floor(calledHourLoc) : Math.ceil(calledHourLoc),
                calledHourLoc % 1
            ]
            let nDate = new Date(0,0)
            nDate.setSeconds(+splitCalledHoutLoc[1] * 60 * 60)
            if(nDate.getSeconds() >= 58 ) {
                nDate.setSeconds(0)
                nDate.setMinutes(nDate.getMinutes() + 1)
            }
            let calledHourLocResult = nDate.toTimeString().slice(2, 8)
            calledHourLocResult = splitCalledHoutLoc[0] == 0 ? "00" + calledHourLocResult : splitCalledHoutLoc[0] < 10 ? "0" + splitCalledHoutLoc[0] + calledHourLocResult : splitCalledHoutLoc[0] + calledHourLocResult
            calledHourLoc = calledHourLocResult

            if(calledHourLoc == '0')
                calledHourLoc = "00:00:00"
        } else if(calledHourLoc == '0')
            calledHourLoc = "00:00:00"

        return calledHourLoc
    }

    detailCardsCost = (data) => {
        const intl = this.props.intl;
        let totalCost = data.totalvaluecostprincipalremote + data.totalvaluecostcalleduserremote + data.totalvaluecosttimeentries + data.totalvaluecostinloco + data.totalvaluecostdisplacement + data.totalvaluereplacedmaterials + data.totalvalueadditionalcost

        let totalAttendance = data.totalhoursprincipalremote + data.totalhourscalleduserremote + data.totaltimeentries + data.totalhoursinloco + data.totalhoursdisplacement

        this.valueTimeAttendance = <Grid item xs={12} sm={12} md={12} lg={12}>
            <TableContainer component={Paper} >
                <Table size="small" aria-label="a dense table" >
                {/*<caption style={{ textAlign: 'right', alignSelf: 'stretch' }}> Custo total de atendimentos {TotalCoustOfCalleds.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}  </caption>*/}
                <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
                    <TableRow>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"remote.attendance"})}</TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"professionals.involved"})}</TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"appointment.hours"})}</TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"on.site.attendance"})}</TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"displacement"})}</TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"replaced.materials"})}</TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"called.accompaniment.aditional.costs"})}</TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"total.value"})}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluecostprincipalremote.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluecostcalleduserremote.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluecosttimeentries.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluecostinloco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluecostdisplacement == intl.formatMessage({id:"not.applicable"}) ? intl.formatMessage({id:"not.applicable"}) : data.totalvaluecostdisplacement.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluereplacedmaterials.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvalueadditionalcost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                        <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{totalCost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                    </TableRow>
                </TableBody>
                </Table>
            </TableContainer>
        </Grid>

        this.valueCostAttendance = <Grid item xs={12} sm={12} md={12} lg={12}>
        <TableContainer component={Paper} >
            <Table size="small" aria-label="a dense table" >
            {/*<caption style={{ textAlign: 'right', alignSelf: 'stretch' }}> Custo total de atendimentos {TotalCoustOfCalleds.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}  </caption>*/}
            <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
                <TableRow>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"remote.attendance"})}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"professionals.involved"})}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"appointment.hours"})}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"on.site.attendance"})}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"displacement"})}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"total.value"})}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.convertTohoursFormat(data.totalhoursprincipalremote)}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.convertTohoursFormat(data.totalhourscalleduserremote)}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.convertTohoursFormat(data.totaltimeentries)} </TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.convertTohoursFormat(data.totalhoursinloco)} </TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalhoursdisplacement == intl.formatMessage({id:"not.applicable"}) ? intl.formatMessage({id:"not.applicable"}) : this.convertTohoursFormat(data.totalhoursdisplacement)} </TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.convertTohoursFormat(totalAttendance)} </TableCell>
                </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    }

    convertCalled = async (item) => {
        const { userSession, calleds, intl } = this.props;

        let times = "";
        let cardItem ="";
        let DepartamentName = ""
        if(item && item.organizationdepartament && item.organizationdepartament.name) {
            DepartamentName = item.organizationdepartament.name
        }

        if (item && item.organizationcategory && item.organizationcategory.techinicalteamorganizationcategories != null) {
            item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
                times = times + item2.techinicalteam.name + ","
            });
            times = times.substring(0, times.length - 1);
        }

		let service = getSlaAtendimento(item, this.props.userSession)
		let solution = getSlaSolucao(item, this.props.userSession)

        let slaSolution = item.slasolution ? item.slasolution.toFixed(2) + "%" : solution + "%"
        let slaService = item.slaservice ? item.slaservice.toFixed(2) + "%" : service + "%"

        let slaSolutionN = item.slasolution ? item.slasolution.toFixed(2) : solution
        let slaServiceN = item.slaservice ? item.slaservice.toFixed(2) : service
                        
        if(item && item.organization && item.organization.organizationsettings && item.organization.organizationsettings.canclientviewslametricsincalled && item.organization.organizationsettings.canclientviewslametricsincalled === true) {
            let slaSol = ""
        } else {
            if(this.isClient) {
                slaSolution = "----"
                slaService = "----"
            }
        }
						
        let newitem={
			...item,
			statusName: item.status.name,
            OrganizationCategory: item.organizationcategory.parentname ? item.organizationcategory.parentname : item.organizationcategory.name,
            Organization: item.organization.name,
            slaAtendimento: slaService,
            slaSolucao: slaSolution,
            service: slaServiceN,
            solution: slaSolutionN,
            assignedUser: item.assigneduser ? item.assigneduser.name : "",
            requestingUser: item.requestinguser ? item.requestinguser.name : "",
            time: times,
            addressname: item.address.name,
            opening: moment(item.openingdate).format("DD/MM/YYYY"),
            openingHour: moment(item.openingdate).format("HH:mm"),
            dueDt: moment(item.duedate).format("DD/MM/YYYY"),
            dueHour: moment(item.duedate).format("HH:mm"),
            DateCreatedAt: moment(item.openingdate).format("DD/MM/YYYY HH:mm"),
            typeName: item.type.name,
            originRequestName: item.originrequest.name,
            cardItem: cardItem,
            DepartamentName: DepartamentName,	
		}

        let newassigned = newitem.assigneduser;
        if(newitem.assigneduser){
            if(newitem.assigneduser.email == null || (newitem.assigneduser.email && (newitem.assigneduser.email.length ===  0 || newitem.assigneduser.email.length === 'undefined'))) {
                newassigned.name = `${newitem.assigneduser.name}`
            } else {
                newassigned.name = `${newitem.assigneduser.name} - ${newitem.assigneduser.email}`
            }
        }

        newitem.assigneduser = newassigned
        this.calledGridData = newitem
        await this.props.addCurrentCalled(newitem);
        await this.props.addCalled(newitem);

        var dataNPS = null;

        this.orgCatPriority = newitem.organizationcategory.priorityid

        await this.setState({
            search: {
                technicalUsersList: `/users/category/${newitem.organizationcategoryid}/organization/${newitem.organizationid}`,
                categoriesList: `/organizationcategories/organization/${newitem.organizationid}`,
                knowledgebase: '/knowledgebases'
            },
            called: {
                id: newitem.id,
                CreatedUser: userSession.user.id,
                requestingUser: 0,
                organization: newitem.organization.id,
                organizationkey: newitem.organization.organizationkey,
                orgname: newitem.organization.name,
                organizationsettings: newitem.organization.organizationsettings,
                type: newitem.type.id,
                OrganizationCategoryId: newitem.organizationcategory,
                ParentCalledId: newitem.parentcalledid,
                ParentCalledText: '',
                OrganizationCategoryText: newitem.organizationcategory.name,
                parentCategory:  newitem.organizationcategory && newitem.organizationcategory.parentorganizationcategory && newitem.organizationcategory.parentorganizationcategory.id,
                dueDate: newitem.duedate,
                openingDate: newitem.openingdate,
                title: newitem.title,
                addressid: 0,
                addresstype: "1",
                status: newitem.status.id,
                description: '',
                priorityid: newitem.priorityid && newitem.priorityid > 0 ? newitem.priorityid : this.orgCatPriority,
                priority: null,
                CallDueDate: null,
                originRequest: newitem.originrequest.id,
                observantUser: 0,
                assignedUser: newitem.assigneduser,
                updatedUser: userSession.user.id ? userSession.user.id : 0,
                quantityreama4: newitem.quantityreama4 ? newitem.quantityreama4 : 0,
                securitypassword: newitem.securitypassword,
                patrimonyId: "",
                classification: dataNPS,
                departamentIdN: newitem.organizationdepartamentid,
                departamentId: 0,
                employeehourvalue: (newitem.employeehourvalue && newitem.employeehourvalue > 0) ? newitem.employeehourvalue : (calleds.defaulttechnicalemployeehourvalue && calleds.defaulttechnicalemployeehourvalue > 0) ? calleds.defaulttechnicalemployeehourvalue : 0,
                valueforcalled: (newitem.valueforcalled && newitem.valueforcalled > 0) ? newitem.valueforcalled : (calleds.defaulttechnicalvalueforcalled && calleds.defaulttechnicalvalueforcalled > 0) ? calleds.defaulttechnicalvalueforcalled : 0,
                costparameter: newitem.costparameter ? newitem.costparameter : 1,
                hasdisplacement: newitem.hasdisplacement,
                kmvaluerotated: (newitem.kmvaluerotated && newitem.kmvaluerotated > 0 ) ? newitem.kmvaluerotated : (calleds.defaulttechnicalkmvaluerotated && calleds.defaulttechnicalkmvaluerotated > 0) ? calleds.defaulttechnicalkmvaluerotated : (calleds.configdisplacement && calleds.configdisplacement.kmvaluerotated > 0 ) ? calleds.configdisplacement.kmvaluerotated : 0,
                finishaccompanimentid: newitem.finishaccompanimentid ? newitem.finishaccompanimentid > 0 ? newitem.finishaccompanimentid : null : null,
                previousfinishaccompanimentid: newitem.finishaccompanimentid ? newitem.finishaccompanimentid > 0 ? newitem.finishaccompanimentid : null : null,
                hasdisplacementTrue: newitem.hasdisplacement == true ? true : false,
                hasdisplacementFalse: newitem.hasdisplacement == false ? true : false,
                hasParentCalledTrue: newitem.parentcalledid != null ? true : false,
                hasParentCalledFalse: newitem.parentcalledid == null ? true : false,
                hasPatrimoniesTrue: newitem.calledorganizationpatrimonies && newitem.calledorganizationpatrimonies.length > 0 ? true : false,
                hasPatrimoniesFalse: (newitem.calledorganizationpatrimonies == null) || (newitem.calledorganizationpatrimonies && newitem.calledorganizationpatrimonies.length == 0) ? true : false,
                calledstatus: [],
                calledattachments: [],
                knowledgebaseattachments: [],
                calledclosingstatusname: null,
            },
            requestingUser: newitem.requestinguser,
            orgSettings: newitem.organization.organizationsettings,
            organization: newitem.organization,
            organizationkey: newitem.organization && newitem.organization.organizationkey ? newitem.organization.organizationkey : '',
            hirerid: newitem.hirerid,
            typeDisabled: newitem.organizationcategory && newitem.organizationcategory.typeid && newitem.organizationcategory.typeid > 0 ? true : false,
            priorityDisabled: newitem.organizationcategory && newitem.organizationcategory.priorityid && newitem.organizationcategory.priorityid > 0 ? true : false,
            calledorganizationid: newitem.organizationid,
            clientStatus: newitem.status,
        })

        return newitem
    }

    getCalledGridById = async () => {
        const { userSession, calleds, intl } = this.props;

        let calledData = null

        const result = await CalledService.getCalledGridById(this.urlId);
        if (result.success) {
          if (result.data && result.data.id) {
            calledData = await this.convertCalled(result.data)
          } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.does.not.exist" })
            });
            this.props.history.push(`/${this.props.match.params.lang}/calleds`)
          }
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }

        return calledData
    }

    LoadUI = async () => {
        const { userSession, calleds } = this.props;
        const intl = this.props.intl;
        const { id } = this.props.match.params
        this.urlId = id

        await this.setState(prevState => ({
            called: {
                ...prevState.called,
                id: id
            }
        }))

        let calledGridData = null

        if (isNaN(id)) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.does.not.exist" })
            });
            this.props.history.push(`/${this.props.match.params.lang}/calleds`)
        }

        if ((calleds[`called-${id}`] == null || typeof calleds[`called-${id}`] === 'undefined') || id != this.state.called.id)
            calledGridData = await this.getCalledGridById()

        this.VertexIsAvaliable();

        let orgCat = (calleds[`called-${id}`] && calleds[`called-${id}`].organizationcategory && calleds[`called-${id}`].organizationcategory.id) ? calleds[`called-${id}`].organizationcategory.id : (calledGridData && calledGridData.organizationcategory && calledGridData.organizationcategory.id) ? calledGridData.organizationcategory.id : 0
        PapiroConsole.log("=== LoadUI -> organizationcategory.id ===")
        PapiroConsole.log(calleds)
        PapiroConsole.log(id)
        PapiroConsole.log(calleds[`called-${id}`])
        PapiroConsole.log(orgCat)
        PapiroConsole.log(this.state.called)
        if(orgCat && orgCat > 0) {
            await this.getCategoryParentByChildrenOrganizationCategoryId(orgCat, '', true)
        }

        let CallDueDate = null

        let service = getSlaAtendimento((calleds[`called-${id}`] != null && typeof calleds[`called-${id}`] !== 'undefined') ? calleds[`called-${id}`] : calledGridData, this.props.userSession)

        if(service && service >= 100)
            CallDueDate = <Chip style={{ color: 'red', borderColor: 'red', fontWeight: 600 }} label={intl.formatMessage({id:"expired"})} color="error" variant="outlined" />


        this.setState(prevState => ({
            called: {
                ...prevState.called, CallDueDate : CallDueDate
            }
        }))

        Api.get(`/calleds/new/${id}`)
        .then(result => {
            if (result && result.data && result.data.success && result.data.data) {
                this.phone = result.data.data.called.organization && result.data.data.called.organization.phone != null ? result.data.data.called.organization.phone : "";
                this.canstartcallcenter = result.data.data.called.organization.organizationsettings.canstartcallcenter;
                if (this.isTechinical && result.data.data.called.assigneduser && result.data.data.called.assigneduser.id == userSession.user.id) {
                    this.sametechinical = true;
                }

                PapiroConsole.log("=== otherClientInteractsWithCalled ===")
                let userIsClient = userSession.user.userrole.find(userrole => userrole.role.roletype == 3)
                PapiroConsole.log(userIsClient)
                PapiroConsole.log(userSession.user.caninteractwithcalledwherearenotrequesterorassigned)
                if (userIsClient != null && typeof userIsClient !== 'undefined' && userSession.user.caninteractwithcalledwherearenotrequesterorassigned != null && userSession.user.caninteractwithcalledwherearenotrequesterorassigned == true) {
                    userSession.user.userorganizations.map(item => {
                        if(item.id == result.data.data.called.organization.id) {
                            this.otherClientInteractsWithCalled = true
                        }
                    })
                }

                this.setState({
                    organizationkey: result.data.data.called.organization && result.data.data.called.organization.organizationkey,
                    canselectorganizationcategoriesbylevels: result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.canselectorganizationcategoriesbylevels ? result.data.data.called.organization.organizationsettings.canselectorganizationcategoriesbylevels : false,
                    caninforminitialchecklist: result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.caninforminitialchecklist ? result.data.data.called.organization.organizationsettings.caninforminitialchecklist : false,
                    caninitialchecklistisrequired: result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.caninitialchecklistisrequired ? result.data.data.called.organization.organizationsettings.caninitialchecklistisrequired : false,
                    totalInitialChecklists: result.data.data.totalinitialchecklists ? result.data.data.totalinitialchecklists : 0,
                    totalCompletedInitialChecklists: result.data.data.totalcompletedinitialchecklists ? result.data.data.totalcompletedinitialchecklists : 0,
					hirerid: result.data.data.called.hirerid
                })

                if (result.data.data.called && result.data.data.called.requestinguser && result.data.data.called.requestinguser.id == userSession.user.id) {
                    this.samePerson = true;
                    this.setState({ samePerson: true })
                } else {
                    this.samePerson = false;
                    this.setState({ samePerson: false })
                }

                this.canclientfinishcalled = result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.canclientfinishcalled ? result.data.data.called.organization.organizationsettings.canclientfinishcalled : false

                this.canclientreopencalled = result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.canclientreopencalled ? result.data.data.called.organization.organizationsettings.canclientreopencalled : false
                
                this.canclientautomaticchangestatusaftersendaccompaniment = result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.canclientautomaticchangestatusaftersendaccompaniment ? result.data.data.called.organization.organizationsettings.canclientautomaticchangestatusaftersendaccompaniment : false

                this.caninforminitialchecklist = result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.caninforminitialchecklist ? result.data.data.called.organization.organizationsettings.caninforminitialchecklist : false
                this.caninitialchecklistisrequired = result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.caninitialchecklistisrequired ? result.data.data.called.organization.organizationsettings.caninitialchecklistisrequired : false
                this.canselectpatrimonyincalled = result.data.data.called && result.data.data.called.organization.organizationsettings.canselectpatrimonyincalled;
                this.caninformzeroincalledtechnicalcost = result.data.data.called && result.data.data.called.organization.organizationsettings.caninformzeroincalledtechnicalcost
                this.canclientselectpatrimonyincalled = result.data.data.called && result.data.data.called.organization.organizationsettings.canclientselectpatrimonyincalled;
                this.canclientselecttypeincalled = result.data.data.called && result.data.data.called.organization.organizationsettings.canclientselecttypeincalled;
                this.caninformutilizedmaterialsincalledfinish = result.data.data.called && result.data.data.called.organization.organizationsettings.caninformutilizedmaterialsincalledfinish;
                this.infoRequest = result.data.data.called && result.data.data.called.requestinguser;
                if(this.infoRequest && this.infoRequest.email && this.infoRequest.phone) {
                    localStorage.setItem("infoRequest", `${this.infoRequest.email};${this.infoRequest.phone}`)
                } else {
                    localStorage.setItem("infoRequest", "")
                }

                var dataNPS = null;
                var msgNPS = null;
                const intl = this.props.intl;

                if (result.data.data.called.calledclassification) {
                    if (result.data.data.called.calledclassification.noteassigned == 1 || result.data.data.called.calledclassification.noteassigned == 2) {
                        dataNPS = <SentimentVeryDissatisfiedIcon style={{ color: '#E74C3C', textAlign: 'center' }} />
                        msgNPS = intl.formatMessage({id:"called.accompaniment.attendance.classified.with"}) + result.data.data.called.calledclassification.noteassigned + intl.formatMessage({id:"called.accompaniment.attendance.classified.with.stars"});
                    } else if (result.data.data.called.calledclassification.noteassigned == 3) {
                        dataNPS = <SentimentSatisfiedIcon style={{ color: '#FFBB28', textAlign: 'center' }} />
                        msgNPS = intl.formatMessage({id:"called.accompaniment.attendance.classified.with"}) + result.data.data.called.calledclassification.noteassigned + intl.formatMessage({id:"called.accompaniment.attendance.classified.with.stars"});
                    } else if (result.data.data.called.calledclassification.noteassigned == 4 || result.data.data.called.calledclassification.noteassigned == 5) {
                        dataNPS = <SentimentSatisfiedAltIcon style={{ color: '#00C49F', textAlign: 'center' }} />
                        msgNPS = intl.formatMessage({id:"called.accompaniment.attendance.classified.with"}) + result.data.data.called.calledclassification.noteassigned + intl.formatMessage({id:"called.accompaniment.attendance.classified.with.stars"});
                    }

                    this.setState({ msgClassification: msgNPS })
                }

                let materialSelectedObj = [];
                if(result.data.data.called.calledorganizationpatrimonies){
                    for (var obj of result.data.data.called.calledorganizationpatrimonies) {
                        materialSelectedObj.push(obj.organizationpatrimony);
                    }
                }

                if(result.data.data.called.calledstatus != null) {
                    this.setState(prevState =>  ({
                        called: {
                            ...prevState.called,
                            calledstatus: result.data.data.called.calledstatus
                        }
                    }))
                }

                if(result.data.data.called && result.data.data.called.accompaniments) {
                    this.props.addAccompanimentsList(result.data.data.called.accompaniments);
                    this.props.addAccompanimentsListInCalled(result.data.data.called.accompaniments, result.data.data.called.id);
                }

                if(!this.isClient){

                    if(result.data.data.called.parentcalled){
                        result.data.data.called.parentcalled.parentname = `${result.data.data.called.parentcalled.id}: ${result.data.data.called.parentcalled.title}`
                    }

                    this.setState({
                        called: {
                            ...this.state.called,
                            ParentCalledId: result.data.data.called.parentcalled,
                            patrimonyId: materialSelectedObj,
                            hasParentCalledTrue: result.data.data.called.parentcalled != null ? true : false,
                            hasParentCalledFalse: result.data.data.called.parentcalled == null ? true : false,
                            hasPatrimoniesTrue: materialSelectedObj && materialSelectedObj.length > 0 ? true : false,
                            hasPatrimoniesFalse: materialSelectedObj == null || (materialSelectedObj && materialSelectedObj.length == 0) ? true : false,
                            status: result.data.data.called.status ? result.data.data.called.status.id : null,
                            calledclosingstatusname: result.data.data.called.calledclosingstatus && result.data.data.called.calledclosingstatus.name && result.data.data.called.calledclosingstatus.name.length > 0 ? result.data.data.called.calledclosingstatus.name : null,
                        },
                        clientStatus: result.data.data.called.status
                    });

                    if(result.data.data.called.calledorganizationfields){
                        this.setState({ organizationFieldList: result.data.data.called.calledorganizationfields &&result.data.data.called.calledorganizationfields.length > 1 ? result.data.data.called.calledorganizationfields.sort((a, b) => a.organizationfield.name.localeCompare(b.organizationfield.name)) : result.data.data.called.calledorganizationfields });
                        result.data.data.called.calledorganizationfields.map(item => {
                            this.setState(prevState => ({
                                called: {
                                    ...prevState.called,
                                    ['ofield-' + item.id]: item.value
                                }
                            }))
                        })
                    }

                    let statuses = result.data.data.statuslist.map(item => {
                        return {
                            ...item,
                            enable: item.system == false ? true : (this.getAvaliableStatus().indexOf(item.name.toLowerCase().trim()) > -1)
                        }
                    })

                    PapiroConsole.log("=== result.data.data.statuslist ===")
                    let sectableStatus = result.data.data.statuslist.map(item => {
                        
                        PapiroConsole.log(item)
                        if (item.system == false || (item.id == 1 || item.id == 2 || item.id == 4 || item.id == 9))
                            return item
                    })
                    let getStatusById = statuses.filter(item => item.id == this.state.called.status)
                    if(getStatusById != null) {
                        if(getStatusById.length > 0) {
                            if(getStatusById[0].statustypeid == 5 || getStatusById[0].finished == true)
                                this.setState({ finishAccompaniment: true })
                            else
                                this.setState({ finishAccompaniment: false })
                        } else
                            this.setState({ finishAccompaniment: false })
                    } else
                        this.setState({ finishAccompaniment: false })

                    let newarrayuserteclist = result.data.data.userstechnicallist;

                    if (result.data.data.userstechnicallist) {
                        if (Array.isArray(result.data.data.userstechnicallist)){
                            if (result.data.data.userstechnicallist.length == 0) { // porto
                                if(result.data.data.called && result.data.data.called.assigneduser == null){
                                    /*this.setState({
                                        openNotification: true, notificationVariant: "error",
                                        notificationMessage: intl.formatMessage({id:"called.accompaniment.error.technician.not.found"})
                                    });*/
                                }else{
                                    newarrayuserteclist.push(result.data.data.called.assigneduser)
                                }

                            }
                        }
                    }

                    if(result.data.data.called && result.data.data.called.assigneduser &&  result.data.data.userstechnicallist){
                        let verifyTecExist = false;
                        result.data.data.userstechnicallist.map(item => {
                            if(item.id == result.data.data.called.assigneduser.id){
                                verifyTecExist = true;
                            }
                        })
                        if(!verifyTecExist){
                            newarrayuserteclist.push(result.data.data.called.assigneduser);
                        }
                    }


                    console.log(newarrayuserteclist)
                    console.log("newarrayuserteclist")

                    let calledValueLoc = `R$0,00`
                    if(result.data.data.calledvaue != null && result.data.data.calledvaue > 0) {
                        calledValueLoc = `R$${result.data.data.calledvaue.toFixed(2)}`
                        calledValueLoc = calledValueLoc.replaceAll(".", ",")
                    }

                    let calledHourLoc = result.data.data.calledtime && !isNaN(result.data.data.calledtime) ? result.data.data.calledtime : "00:00:00"
                    if(calledHourLoc && calledHourLoc > 0) {
                        var splitCalledHoutLoc = [
                            (calledHourLoc > 0) ? Math.floor(calledHourLoc) : Math.ceil(calledHourLoc),
                            calledHourLoc % 1
                        ]
                        let nDate = new Date(0,0)
                        nDate.setSeconds(+splitCalledHoutLoc[1] * 60 * 60)
                        if(nDate.getSeconds() >= 58 ) {
                            nDate.setSeconds(0)
                            nDate.setMinutes(nDate.getMinutes() + 1)
                        }
                        let calledHourLocResult = nDate.toTimeString().slice(2, 8)
                        calledHourLocResult = splitCalledHoutLoc[0] == 0 ? "00" + calledHourLocResult : splitCalledHoutLoc[0] < 10 ? "0" + splitCalledHoutLoc[0] + calledHourLocResult : splitCalledHoutLoc[0] + calledHourLocResult
                        calledHourLoc = calledHourLocResult

                        if(calledHourLoc == '0')
                            calledHourLoc = "00:00:00"
                    } else if(calledHourLoc == '0')
                        calledHourLoc = "00:00:00"

                    let reordernewarrayuserteclist = new Array();
                    if(newarrayuserteclist != null) {
                        newarrayuserteclist.map(item => {
                            item.name =  item.name + " ("+item.email+")";
                            reordernewarrayuserteclist.push(item);
                        })
                    }
                    reordernewarrayuserteclist = reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name))

                    if(result.data.data.called.priorityid && result.data.data.called.priorityid > 0 && result.data.data.called.priorityid == null) {
                        let priorityFilter = result.data.data.prioritylist.filter(item => item.id == result.data.data.called.priorityid)
                        if(priorityFilter && priorityFilter.length > 0)
                            this.setState(prevState => ({
                                called: {
                                    ...prevState.called,
                                    priority: priorityFilter[0]
                                }
                            }))
                    }

                    this.setState(prevState => ({
                        organizationAddressesList: result.data.data.organizationaddresseslist && result.data.data.organizationaddresseslist.length > 0 ? result.data.data.organizationaddresseslist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.organizationaddresseslist,
                        userAddressesList: result.data.data.useraddresseslist && result.data.data.useraddresseslist.length > 0 ? result.data.data.useraddresseslist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.useraddresseslist,
                        sectableStatus: sectableStatus && sectableStatus.length > 1 ? sectableStatus.sort((a, b) => a.name.localeCompare(b.name)) : sectableStatus,
                        address: {
                            ...prevState.address,
                            id: result.data.data.customaddress && result.data.data.customaddress.id != null ? result.data.data.customaddress.id : 0,
                            userid: result.data.data.customaddress && result.data.data.customaddress.userid != null ? result.data.data.customaddress.userid : result.data.data.called.requestinguser.id,
                            name: result.data.data.customaddress && result.data.data.customaddress.name != null ? result.data.data.customaddress.name : '',
                            street: result.data.data.customaddress && result.data.data.customaddress.street != null ? result.data.data.customaddress.street : '',
                            zipcode: result.data.data.customaddress && result.data.data.customaddress.zipcode != null ? result.data.data.customaddress.zipcode : '',
                            number: result.data.data.customaddress && result.data.data.customaddress.number != null ? result.data.data.customaddress.number : '',
                            complement: result.data.data.customaddress && result.data.data.customaddress.complement != null ? result.data.data.customaddress.complement : '',
                            neighborhood: result.data.data.customaddress && result.data.data.customaddress.neighborhood != null ? result.data.data.customaddress.neighborhood : '',
                            city: result.data.data.customaddress && result.data.data.customaddress.city != null ? result.data.data.customaddress.city : '',
                            state: result.data.data.customaddress && result.data.data.customaddress.state != null ? result.data.data.customaddress.state : '',
                            country: result.data.data.customaddress && result.data.data.customaddress.country != null ? result.data.data.customaddress.country : '',
                            longitude: result.data.data.customaddress && result.data.data.customaddress.longitude != null ? result.data.data.customaddress.longitude : '',
                            latitude: result.data.data.customaddress && result.data.data.customaddress.latitude != null ? result.data.data.customaddress.latitude : '',
                            iscustomaddress : result.data.data.customaddress && result.data.data.customaddress.iscustomaddress != null ? result.data.data.customaddress.iscustomaddress : false
                        },
                        categoriesList: result.data.data.organizationcategorylist && result.data.data.organizationcategorylist.length > 0 ? result.data.data.organizationcategorylist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.organizationcategorylist,
                        typesList: result.data.data.typeslist && result.data.data.typeslist.length > 0 ? result.data.data.typeslist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.typeslist,
                        technicalUsersList: reordernewarrayuserteclist,
                        DepartamentList: result.data.data.organizationdepartamentlist && result.data.data.organizationdepartamentlist.length > 0 ? result.data.data.organizationdepartamentlist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.organizationdepartamentlist,
                        statusList: statuses && statuses.length > 0 ? statuses.sort((a, b) => a.name.localeCompare(b.name)) : statuses,
                        departamentId: result.data.data.called.organizationdepartament,
                        priorityList: result.data.data.prioritylist && result.data.data.prioritylist.length > 0 ? result.data.data.prioritylist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.prioritylist,
                        loading: false,
                        calledValue: calledValueLoc,
                        calledTime: calledHourLoc
                    }));

                    if(result.data.data.calledcost)
                        this.detailCardsCost(result.data.data.calledcost)
                }else{

                    let statuses = result.data.data.statuslist.map(item => {
                        return {
                            ...item,
                            enable: item.system == false ? true : (this.getAvaliableStatus().indexOf(item.name.toLowerCase().trim()) > -1)
                        }
                    })

                    this.setState({
                        called: {
                            ...this.state.called,
                            status: result.data.data.called.status ? result.data.data.called.status.id : null,
                        },
                        clientStatus: result.data.data.called.status,
                        statusList: statuses && statuses.length > 0 ? statuses.sort((a, b) => a.name.localeCompare(b.name)) : statuses,
                        categoriesList: result.data.data.organizationcategorylist && result.data.data.organizationcategorylist.length > 0 ? result.data.data.organizationcategorylist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.organizationcategorylist,
                        typesList: result.data.data.typeslist && result.data.data.typeslist.length > 0 ? result.data.data.typeslist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.typeslist,
                        departamentId: result.data.data.called.organizationdepartament
                    });

                    if(result.data.data.called.calledorganizationfields){
                        this.setState({ organizationFieldList: result.data.data.called.calledorganizationfields });
                        result.data.data.called.calledorganizationfields.map(item => {
                            this.setState(prevState => ({
                                called: {
                                    ...prevState.called,
                                    ['ofield-' + item.id]: item.value
                                }
                            }))
                        })
                    }

                    result.data.data.statuslist.map((item, key) => {
                        if (item.statustype.id == 5) {
                            this.setState({ FinishCalledId: item.id });
                        }
                        if (item.id == 2) { // em andamento
                            this.setState({ ReopenCalledId: item.id });
                        }

                        if (item.id == this.state.called.status && item.statustype.id == 5) {
                            this.setState({ FinishCalled: true });
                        }
                        if (item.id == this.state.called.status && item.statustype.id == 2) {
                            this.setState({ FinishCalled: false });
                        }
                    });

                    this.setState({
                        loading: false,
                    });
                }

                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        description: result.data.data.called.description || "",
                        ['hasdisplacement']: result.data.data.hasdisplacement,
                        priorityid: result.data.data.called.priorityid && result.data.data.called.priorityid > 0 ? result.data.data.called.priorityid : this.orgCatPriority,
                        priority: result.data.data.called.priority
                    }
                }))

                let newassigneduser = result.data.data.called.assigneduser;
                if(newassigneduser != null){
                    newassigneduser.name = newassigneduser.name + " ("+newassigneduser.email+")"
                }

                let editTitleAndDescriptionCalled = true
                if(result.data.data.called.organizationcategory && result.data.data.called.organizationcategory.caneditcalledtitleanddescription != null) {
                    if(this.state.isDisabled == true && result.data.data.called.organizationcategory.           caneditcalledtitleanddescription == true)
                        editTitleAndDescriptionCalled = false
                    else
                        editTitleAndDescriptionCalled = result.data.data.called.organizationcategory.caneditcalledtitleanddescription
                }

                PapiroConsole.log("=== result.data.data.called.status ===")
                PapiroConsole.log(result.data.data.called.status)

                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        description: result.data.data.called.description || "",
                        classification: dataNPS,
                        requestingUser: result.data.data.called.requestinguser,
                        organizationsettings: result.data.data.called.organization.organizationsettings,
                        calledattachments: result.data.data.called.calledattachments && result.data.data.called.calledattachments.length > 0 ? result.data.data.called.calledattachments : [],
                        addressid: result.data.data.called.address ? result.data.data.called.address.id : 0,
                        addresstype: result.data.data.called.addresstype ? `${result.data.data.called.addresstype}` : "1",
                        status: result.data.data.called.status ? result.data.data.called.status.id : this.props.calleds[`called-${this.urlId}`] && this.props.calleds[`called-${this.urlId}`].status && this.props.calleds[`called-${this.urlId}`].status.id,
                        observantUser: result.data.data.called.observantuser ? result.data.data.called.observantuser.id : 0,
                        assignedUser: newassigneduser,
                        quantityreama4: result.data.data.called.quantityreama4 ? result.data.data.called.quantityreama4 : 0,
                        departamentIdN: result.data.data.called.organizationdepartamentid ? result.data.data.called.organizationdepartamentid : 0,
                        employeehourvalue: (result.data.data.called.employeehourvalue && result.data.data.called.employeehourvalue > 0) ? result.data.data.called.employeehourvalue : (result.data.data.defaulttechnicalemployeehourvalue && result.data.data.defaulttechnicalemployeehourvalue > 0) ? result.data.data.defaulttechnicalemployeehourvalue : 0,
                        valueforcalled: (result.data.data.called.valueforcalled && result.data.data.called.valueforcalled > 0) ? result.data.data.called.valueforcalled : (result.data.data.defaulttechnicalvalueforcalled && result.data.data.defaulttechnicalvalueforcalled > 0) ? result.data.data.defaulttechnicalvalueforcalled : 0,
                        costparameter: result.data.data.costparameter,
                        hasdisplacement: result.data.data.called.hasdisplacement,
                        kmvaluerotated: result.data.data.called.kmvaluerotated > 0 ? result.data.data.called.kmvaluerotated : result.data.data.configdisplacement ? result.data.data.configdisplacement.kmvaluerotated : 0,
                        //finishaccompanimentid: result.data.data.finishaccompanimentid ? result.data.data.finishaccompanimentid > 0 ? result.data.data.finishaccompanimentid : null : null,
                        previousfinishaccompanimentid: result.data.data.finishaccompanimentid ? result.data.data.finishaccompanimentid > 0 ? result.data.data.finishaccompanimentid : null : null,
                        hasdisplacementTrue: result.data.data.called.hasdisplacement == true ? true : false,
                        hasdisplacementFalse: result.data.data.called.hasdisplacement == false ? true : false,
                    },
                    clientStatus: result.data.data.called.status ? result.data.data.called.status : this.props.calleds[`called-${this.urlId}`] && this.props.calleds[`called-${this.urlId}`].status && this.props.calleds[`called-${this.urlId}`].status,
                    caneditcalledtitleanddescription: editTitleAndDescriptionCalled,
                }))

                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['securitypassword']: result.data.data.called.securitypassword,
                        ['hasdisplacementTrue']: result.data.data.called.hasdisplacement == true ? true : false,
                        ['hasdisplacementFalse']: result.data.data.called.hasdisplacement == false ? true : false,
                        ['kmvaluerotated']: result.data.data.called.kmvaluerotated > 0 ? result.data.data.called.kmvaluerotated : result.data.data.defaulttechnicalkmvaluerotated > 0 ? result.data.data.defaulttechnicalkmvaluerotated : result.data.data.configdisplacement && result.data.data.configdisplacement.kmvaluerotated > 0 ? result.data.data.configdisplacement.kmvaluerotated : 0,
                        ['employeehourvalue']: (result.data.data.called.employeehourvalue && result.data.data.called.employeehourvalue > 0) ? result.data.data.called.employeehourvalue : (result.data.data.defaulttechnicalemployeehourvalue && result.data.data.defaulttechnicalemployeehourvalue > 0) ? result.data.data.defaulttechnicalemployeehourvalue : 0,
                        ['valueforcalled']: (result.data.data.called.valueforcalled && result.data.data.called.valueforcalled > 0) ? result.data.data.called.valueforcalled : (result.data.data.defaulttechnicalvalueforcalled && result.data.data.defaulttechnicalvalueforcalled > 0) ? result.data.data.defaulttechnicalvalueforcalled : 0,
                        ['costparameter']: (result.data.data.called.costparameter) ? result.data.data.called.costparameter : (result.data.data.costparameter) ? result.data.data.costparameter : 1
                    }
                }))

            }else{
                this.setState({
                    loading: false,
                });
            }
            PapiroConsole.log("=== Client Validation ===")
            PapiroConsole.log(this.isClient && this.state.called.assignedUser != null && this.state.clientStatus && (this.state.clientStatus.statustypeid == 2 || this.state.clientStatus.statustypeid == 7) && this.canclientfinishcalled)
            PapiroConsole.log(this.isClient)
            PapiroConsole.log(this.state.called.assignedUser != null)
            PapiroConsole.log(this.canclientfinishcalled)
            PapiroConsole.log(this.state.clientStatus.statustypeid == 2 || this.state.clientStatus.statustypeid == 7)
            PapiroConsole.log(this.state.clientStatus)

            this.getDefaultActions();
            //this.getPriorities()
        })
        .catch(err => {
            console.error(err)
            if (err.response && err.response.data && err.response.data.errors) {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });

            }
            else {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "process.error" })
                });
            }
        });
    }
    async VertexIsAvaliable() {
        var result = await Api.VertexIsAvaliable();

        this.setState({ disabledvertex: !result });
    }

    async addAccompanimentActionToKnowledgeBase(accompanimentId) {
        this.setState({ loading: true })
        const intl = this.props.intl;

        const response = await addAccompanimentToKnowledgeBase(accompanimentId);
        if (response && response.success && response.data) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({ id: "add.knowledgebase" })
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: response && response.data && response.data.errors ? response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
        }

        this.setState({ loading: false })
    }

    async getOrganizationDepartaments(id) {
        var result = await getOrganizationsDepartaments(id);
        //console.log(result)
        //console.log("getOrganizationDepartaments")
        if (result.success) {
            if (result.data != null && result.data.length > 0) {
                this.setState({ DepartamentList: result.data && result.data.length > 0 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data});
                this.getOrganizationDepartamentsById(this.state.called.departamentIdN)
            }
            this.setCountLoading("getOrganizationDepartaments");
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            this.setCountLoading("getOrganizationDepartaments");
            Api.kickoff(result);
        }
    }

     checkRoleToDisable() {



        if(this.AdmOrganization || this.isCoordenator || this.isClient || this.globalAdm  ){

            console.log("entrei aqui no certo ")

            this.setState({
                isDisabled : false

            })


        }
        else{
            console.log("entrei aqui no errado ")
            this.setState({
                isDisabled: true
              });
        }



    }

    async deleteKnowledgeBase(accompanimentId) {
        this.setState({ loading: true })
        const intl = this.props.intl;

        const response = await deleteKnowledgeBaseByAccompanimentId(accompanimentId);
        if (response && response.success) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({ id: "delete.knowledgebase" })
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "process.error" })
            })
        }

        this.setState({ loading: false })
    }

    getTechnicalPublicAccompaniment = async _ => {
        const intl = this.props.intl;
        this.setState({ loading: true });
        let result = await getAccompanimentsToFinish(this.state.called.id, this.state.called.requestingUser.id)
        if (result && result.data && result.data.data) {
            let finishAccompaniments = result.data.data.map(item => {
                let body = item.description
                let temp = document.createElement("div")
                temp.innerHTML = body
                let sanitized = temp.textContent || temp.innerText

                return {
                    ...item,
                    name: sanitized,
                    Name: item.description
                }
            })

            this.setState({
                finishAccompanimentList: finishAccompaniments && finishAccompaniments ? finishAccompaniments.sort((a, b) => a.name.localeCompare(b.name)) : finishAccompaniments,
                loading: false,
                finishAccompanimentModalOpen: !this.state.finishAccompanimentModalOpen
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
        }
    }

    async getCalledCostToCard() {
        this.setState({ loading: true })
        Api.get(`/calledCost/called/${this.state.called.id}`)
        .then(result => {
            if (result && result.data && result.data.success && result.data.data) {
                const intl = this.props.intl;

                let calledValueLoc = `R$0,00`

                if(result.data.data.calledvalue != null && result.data.data.calledvalue > 0) {
                    calledValueLoc = `R$${result.data.data.calledvalue.toFixed(2)}`
                    calledValueLoc = calledValueLoc.replaceAll(".", ",")
                }

                let calledHourLoc = result.data.data.calledtime && !isNaN(result.data.data.calledtime) ? result.data.data.calledtime : 0
                if(calledHourLoc && calledHourLoc > 0) {
                    var splitCalledHoutLoc = [
                        (calledHourLoc > 0) ? Math.floor(calledHourLoc) : Math.ceil(calledHourLoc),
                        calledHourLoc % 1
                    ]
                    let nDate = new Date(0,0)
                    nDate.setSeconds(+splitCalledHoutLoc[1] * 60 * 60)
                    if(nDate.getSeconds() >= 58 ) {
                        nDate.setSeconds(0)
                        nDate.setMinutes(nDate.getMinutes() + 1)
                    }
                    let calledHourLocResult = nDate.toTimeString().slice(2, 8)
                    calledHourLocResult = splitCalledHoutLoc[0] == 0 ? "00" + calledHourLocResult : splitCalledHoutLoc[0] < 10 ? "0" + splitCalledHoutLoc[0] + calledHourLocResult : splitCalledHoutLoc[0] + calledHourLocResult
                    calledHourLoc = calledHourLocResult

                    if(calledHourLoc == '0')
                        calledHourLoc = "00:00:00"
                } else if(calledHourLoc == '0')
                    calledHourLoc = "00:00:00"

                this.setState({
                    calledValue: calledValueLoc,
                    calledTime: calledHourLoc,
                    loading: false
                });
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.errors) {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                this.setState({
                    loading: false,
                });

            }
            else {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "process.error" })
                });
                this.setState({
                    loading: false,
                });

            }
        });
    }

    async refreshAccompaniments() {
        this.setState({
            loading: true,
        });
        Api.get(`/accompaniments/called/${this.state.called.id}`)
            .then(result => {
                if (result && result.data && result.data.success && result.data.data) {
                    //alert('ATUALIZAR CHAMADOS');
                    this.props.addAccompanimentsList(result.data.data);
                    this.props.addAccompanimentsListInCalled(result.data.data, this.state.called.id);
                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    this.setState({
                        loading: false,
                    });

                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                    this.setState({
                        loading: false,
                    });

                }
            });

    }

    async componentWillReceiveProps(nextProps) {
        PapiroConsole.log("=== componentWillReceiveProps ===")
        PapiroConsole.log(nextProps)
        if (nextProps.headerMenu.refresh === true) {
            this.props.reload(false)
            PapiroConsole.log("nextProps.headerMenu.refresh === true")
            await this.setState({ loading: true, countLoading: 0, });
            await this.LoadUI();
        }
    }

    async getPatrimonySubgroups(patrimonyGroupId) {
        var result = await Service.getPatrimonySubgroups(patrimonyGroupId);
        if (result.success) {
            return result.data;
        } else {
            return [];
        }
    }




    handleClick = (event, value) => {
        //console.log(event.currentTarget);
        this.setState({ anchorEl: event.currentTarget });
    };

    async getParentCalledsById() {


        var result = await CalledService.getParentCalledById(this.state.called.ParentCalledId);

        if (result.success) {
            result.data.parentname = `${result.data.id}: ${result.data.title}`
            await this.setState({ called: { ...this.state.called, ParentCalledId: result.data } });
            this.setCountLoading("getParentCalledsById");
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
            this.setCountLoading("getParentCalledsById");

        }
    };

    async getOrganizationDepartamentsById(id) {
        var result = await getOrganizationDepartamentsById(id);
        //console.log("getOrganizationDepartamentsById")
        //console.log(result)
        if (result.success) {
            if (result.data) {
                this.setState({ departamentId: result.data });
            }
            this.setCountLoading("getOrganizationDepartamentsById");
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            this.setCountLoading("getOrganizationDepartamentsById");
            Api.kickoff(result);

        }
    }

    async getDepartamentsByOrganizationCategoryId(id) {
        this.setState({ loading: true });
        var result = await getDepartamentsByOrganizationCategoryId(id);
        if (result.success) {
            if (result.data && result.data.length > 0) {
                if (this.isClient) {
                    if (this.state.called.departamentId && this.state.called.departamentId.id) {
                        var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)
                        if (updatedList)
                            updatedList = updatedList[0]

                        if (result.data.length == 1) {
                            this.setState({ departamentId: result.data[0] })
                        }

                        this.setState({ loading: false, DepartamentList: updatedList && updatedList.length > 1 ? updatedList.sort((a, b) => a.name.localeCompare(b.name)) : updatedList });
                    }else{
                        this.setState({ loading: false });
                    }
                } else if (this.isTec) {
                    if (this.state.called.departamentId && this.state.called.departamentId.id) {
                        var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)

                        if (result.data.length == 1) {
                            this.setState({ departamentId: result.data[0] })
                        }

                        this.setState({ loading: false, DepartamentList: updatedList && updatedList.length > 1 ? updatedList.sort((a, b) => a.name.localeCompare(b.name)) : updatedList });
                    } else {

                        this.setState({ loading: false, DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
                    }
                } else {

                    this.setState({ loading: false, DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
                }
            } else {
                this.setState({ loading: false, DepartamentList: [] });
            }
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }

    async getDepartamentByUserId() {
        const { userSession } = this.props;
        this.setState({ loading: true });

        var result = await getDepartamentByUserId(userSession.user.id);
        if (result.success) {
            this.setState({ loading: false, userDepartamentId: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    }

    openPatrimonioModal() {
        this.setState({
            modalPatrimonio: true,
        });
    }

    fecharModalPatrimonio() {
        this.setState({
            modalPatrimonio: false,
        });
    }

    openInitialChecklistModal = () => {
        this.setState({ loading: false, initialChecklistModalOpen: true })
    }

    closeInitialChecklistModal = () => {
        this.setState({ initialChecklistModalOpen: false })
    }

    openConfirmReopenCalledClientDialog = () => {
        const { intl } = this.props
        this.setState({ loading: true });

        let sanitized = this.convertHtmlOption(this.state.action.description)
        if (sanitized != null && sanitized.length > 0)
            sanitized = sanitized.trim()
            
        if ((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.state.files.length == 0) {
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.reopen.error.missing.action.client"})
            });
            return false;
        }

        this.setState({ loading: false, loadingTwo: false, confirmReopenCalledClientDialog: true })
    }

    closeConfirmReopenCalledClientDialog = () => {
        this.setState({ confirmReopenCalledClientDialog: false })
    }

    confirmReopenCalledClient = async () => {
        this.setState({ confirmReopenCalledClientDialog: false })
        await this.submitAction(true, false, true)
    }

    async reopenCalledClient() {
        this.setState({ loading: true });

        const { id } = this.props.match.params
        let updatedUser = this.state.called.updatedUser;

        let data = new FormData();
        data.append("id", parseInt(id))
        data.append("updateduser", updatedUser)

        await Api.put("/calleds/reopen/client", data)
            .then(result => {
                if (result.data.success) {
                    this.setState({ FinishCalled: true });
                    this.props.addCurrentCalled(result.data.data);
                    this.props.addCalled(result.data.data)
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "edit.called.success" }),
                        files: [],
                        action: {
                            description: "",
                            privacy: "false"
                        },
                        clientStatus: result.data.data.status
                    });
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
            });
    }

    async getOrganizationAddresses(organizationId) {

        if (organizationId == null) {
            this.setCountLoading("getOrganizationAddresses");
            return false;
        }
        const result = await CalledService.getOrganizationAddresses(organizationId);
        const intl = this.props.intl;

        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
                    if (this.AdmOrganization) {
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.address"}),
                            notificationLinkMove: "organizations"
                        });
                    } else {
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.address"})
                        });
                    }
                }
            }

            this.setState({ organizationAddressesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
            this.setCountLoading("getOrganizationAddresses");

        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
            this.setCountLoading("getOrganizationAddresses");


        }
    };

    async getPatrimonios(id) {
        if(this.state.patrimonyList && this.state.patrimonyList.length > 0)
            return false

        this.setState({ loading: true })
        if (id == null) {
            //this.setCountLoading("getPatrimonios");
            return false;
        }

        const result = await CalledService.getPatrimonyByOrg(id);
        //console.log(result);
        if (result.success) {
            this.setState({ loading: false, patrimonyList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
            //this.setCountLoading("getPatrimonios");

        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
            //this.setCountLoading("getPatrimonios");


        }
        this.setState({ loading: false })
    };

    async getCalledPatrimonios(id) {
        if (id == null) {
            this.setCountLoading("getCalledPatrimonios");
            return false;
        }
        const result = await CalledService.getOrgPatrimony(id);
        //console.log(result);
        if (result.success) {

            let materialSelectedObj = [];

            for (var obj of result.data) {
                materialSelectedObj.push(obj.organizationpatrimony);
            }

            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    ["patrimonyId"]: materialSelectedObj
                }
            }));
            this.setCountLoading("getCalledPatrimonios");

        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
            this.setCountLoading("getCalledPatrimonios");

        }
    };

    async getCep(cepNumber) {
        return new Promise(function(resolve, reject) {
          
          fetch("https://viacep.com.br/ws/".concat(cepNumber, "/json/")).then(function (response) {
            return response.json();
          }).then(function (data) {
            // console.log(data);
            resolve(data);
          })["catch"](function (err) {
            // console.log(err);
            reject(err);
          })["finally"](function (data) {
            return data;
          });
    
        });
      }
    
      async handleInputChangeAddress(e) {
        const name = e.target.name;
        const value = e.target.value;
    
        if(name == 'zipcode'){
          if(e.target.value === '' || /^\d+$/.test(e.target.value)) { 
            this.setState(prevState => ({
              address: {
                ...prevState.address,
                [name]: value
              }
            }));
            if(value.length == 8){
              this.setState({ loading: true });
              var data = await this.getCep(value);
              this.setState(prevState => ({
                address: {
                  ...prevState.address,
                  ['street']: data.logradouro,
                  ['complement']: data.complemento,
                  ['neighborhood']: data.bairro,
                  ['city']: data.localidade,
                  ['state']: data.uf,
                  ['country']: 'BRASIL',
                }
              }));
              this.setState({ loading: false });
            }
          } else { 
              return false; 
          } 
        } else {
          this.setState(prevState => ({
            address: {
              ...prevState.address,
              [name]: value
            }
          }));
        }
      }

    NumberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
          <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
              onChange({
                target: {
                  name: props.name,
                  value: values.value,
                },
              });
            }}
            thousandSeparator={false}
            decimalSeparator=","
            isNumericString
            decimalScale={2}
            prefix={'R$'}
          />
        );
    }

    checkAccompaniment(itsGoTheCalled = false, closeFinishModal = false, directFinish = false) {
        this.setState({
            loading: true,
            loadingTwo: true
        });

        const intl = this.props.intl;

        let statusData = this.state.statusList.filter(item => item.id ==this.state.called.status)
        if((((this.state.called.status && this.state.called.status == 4) || (statusData && statusData.statustypeid == 5)) || this.state.directFinishCalled ) && this.state.called.calledstatus != null) {
            if (!this.isClient) {
                let calledIsRun = this.state.called.calledstatus.filter(item => item.nextstatusid == 2)
                PapiroConsole.log("=== calledIsRun ===")
                PapiroConsole.log(this.state.called.calledstatus)
                PapiroConsole.log(calledIsRun)
                if((calledIsRun && calledIsRun.length == 0)) {
                    let runningStatusName = "Em andamento"
                    let runningStatusNameFilter = this.state.statusList.filter(item => item.id == 2)
                    PapiroConsole.log("=== runningStatusNameFilter ===")
                    PapiroConsole.log(runningStatusNameFilter)
                    if(runningStatusNameFilter && runningStatusNameFilter.length > 0) {
                        runningStatusName = runningStatusNameFilter[0].name
                    }
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.finish.error.missing.status"}) + `${runningStatusName}`
                    });
                    return false
                }
                if (this.caninforminitialchecklist && this.caninitialchecklistisrequired && this.state.totalInitialChecklists != this.state.totalCompletedInitialChecklists) {
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.finish.error.missing.activities.checklist"})
                    })
                    return false
                }
            }
        }

        this.setState({
            loading: false,
            loadingTwo: false
        });

        return true;
    }

    checkFinishCalled(itsGoTheCalled = false, closeFinishModal = false, directFinish = false) {
        this.setState({
            loading: true,
            loadingTwo: true
        });
        const intl = this.props.intl;

        let calledDesc = this.convertHtmlOption(this.state.called.description)
        calledDesc = calledDesc.trim()
        if(calledDesc == null || (calledDesc != null && calledDesc.length == 0 )) {
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.called.description"})
            });
            return false;
        }
        if (this.state.called.OrganizationCategoryId == null || typeof this.state.called.OrganizationCategoryId === 'undefined') {
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.accompaniment.associate.category.error"})
            });
            return false;
        }
        if (this.state.called.ParentCalledId !== 0 && this.state.called.ParentCalledId != null && this.state.called.ParentCalledId != undefined) {
            if (this.state.called.id === this.state.called.ParentCalledId.id) {

                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.parent.error"})
                });
                return false;
            }
        }
        PapiroConsole.log(this.state.called.assignedUser)
        PapiroConsole.log("this.state.called.assignedUser")
        if(this.state.called.status && this.state.called.status == 2 && !this.state.called.assignedUser && !this.state.directFinishCalled) {
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.attendance.error.missing.responsible"})
            });
            return false
        }

        if(((this.state.called.status && this.state.called.status == 4) || this.state.directFinishCalled ) && this.state.called.calledstatus != null) {
            if (this.caninforminitialchecklist && this.caninitialchecklistisrequired && this.state.totalInitialChecklists != this.state.totalCompletedInitialChecklists) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.finish.error.missing.activities.checklist"})
                })
                return false
            }
            
            /*let calledIsRun = this.state.called.calledstatus.filter(item => item.nextstatusid == 2)
            PapiroConsole.log("=== calledIsRun ===")
            PapiroConsole.log(this.state.called.calledstatus)
            PapiroConsole.log(calledIsRun)
            if(calledIsRun && calledIsRun.length == 0) {
                let runningStatusName = "Em andamento"
                let runningStatusNameFilter = this.state.statusList.filter(item => item.id == 2)
                PapiroConsole.log("=== runningStatusNameFilter ===")
                PapiroConsole.log(runningStatusNameFilter)
                if(runningStatusNameFilter && runningStatusNameFilter.length > 0) {
                    runningStatusName = runningStatusNameFilter[0].name
                }
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.finish.error.missing.status"}) + `${runningStatusName}`
                });
                return false
            }*/
        }

        if (!this.isClient) {

            let status = this.state.called.status;
            let cancelledStatus = false;

            let theStatusIsFinish = false;

            let clientStatus = this.state.clientStatus;
            for (let value in this.state['statusList']) {
                if (this.state['statusList'][value].id == status) {

                    if (this.state['statusList'][value].finished == true) {
                        theStatusIsFinish = true;
                        if (this.state['statusList'][value].id == 6) // cancelado
                        {
                            cancelledStatus = true
                        }
                    }
                    if (this.state['statusList'][value].id == 4)
                        clientStatus = this.state['statusList'][value]
                }
            }
            if(directFinish == true || this.state.directFinishCalled == true) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        status: 4
                    },
                    clientStatus: clientStatus
                }))
                theStatusIsFinish = true
            }

            let sanitized = this.convertHtmlOption(this.state.action.description)
            if (sanitized != null && sanitized.length > 0)
                sanitized = sanitized.trim()

            let sanitizedCalled = this.convertHtmlOption(this.state.called.description)
            if (sanitizedCalled != null && sanitizedCalled.length > 0)
                sanitizedCalled = sanitizedCalled.trim()

            if ((sanitized == null || (sanitized != null && sanitized.length == 0)) &&
                itsGoTheCalled == true &&
                theStatusIsFinish == true &&
                this.statusDataBase != status) {
                if((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.isClient == true && (this.globalAdm == false && this.AdmOrganization == false && this.isCoordenator == false && this.isTechinical == false)) {
                    if(this.state.called.finishaccompanimentid == null ||
                        (this.state.called.finishaccompanimentid && this.state.called.finishaccompanimentid.id && this.state.called.finishaccompanimentid.id == 0) ||
                        (this.state.called.finishaccompanimentid && this.state.called.finishaccompanimentid.id == null)) {
                        this.setState({
                            loading: false,
                            loadingTwo: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.finish.error.missing.action"})
                        });
                        return false;
                    }
                }
            } else if(this.state.called.priorityid == null || (this.state.called.priorityid != null && this.state.called.priorityid == 0)) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.empty.field.priority"})
                });
                return false;
            } else if (theStatusIsFinish == true && this.state.called.assignedUser == 0 && cancelledStatus == false) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.finish.error.missing.responsible"})
                });
                return false;
            } else if (this.state.called.addressid == 0 && theStatusIsFinish && cancelledStatus == false) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.location"})
                })
                return false
            } else if ((sanitized == null || (sanitized != null && sanitized.length == 0)) && theStatusIsFinish) {
                if((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.isClient == true && (this.globalAdm == false && this.AdmOrganization == false && this.isCoordenator == false && this.isTechinical == false)) {
                    if(this.state.called.finishaccompanimentid == null ||
                        (this.state.called.finishaccompanimentid && this.state.called.finishaccompanimentid.id && this.state.called.finishaccompanimentid.id == 0) ||
                        (this.state.called.finishaccompanimentid && this.state.called.finishaccompanimentid.id == null)) {
                        this.setState({
                            loading: false,
                            loadingTwo: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.action.existing"})
                        })
                        return false
                    }
                }
            } else if ((sanitizedCalled == null || (sanitizedCalled != null && sanitizedCalled.length == 0))) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.description"})
                })
                return false
            } else if(theStatusIsFinish == false && (sanitizedCalled == null || (sanitizedCalled != null && sanitizedCalled.length == 0)) && this.state.directSaveCalled == false && directFinish == false) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.action"}),
                    directSaveCalled: false
                })
                return false
            }
            if(this.state.directSaveCalled == true) {
                this.setState({ directSaveCalled: false })
            }
            if (theStatusIsFinish == true && !closeFinishModal) {
                this.setState({
                    modalopenFinish: true,
                    loading: false,
                    loadingTwo: false
                });
                return false;
            } else {
                this.setState({
                    modalopenFinish: false,
                });
                return true;
            }
        }else{
            let clientClickFinishCalled = this.state.clientClickFinishCalled

            if (this.state.clientClickFinishCalled == true)
                this.setState({ clientClickFinishCalled: false })
            
            let sanitized = this.convertHtmlOption(this.state.action.description)
            if (sanitized != null && sanitized.length > 0)
                sanitized = sanitized.trim()
            
            if ((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.state.files.length == 0) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: clientClickFinishCalled == true ? intl.formatMessage({id:"called.accompaniment.notification.called.finish.error.missing.action.client"}) : intl.formatMessage({id:"called.accompaniment.notification.missing.action"})
                });
                return false;
            }
            
            let status = this.state.called.status;
            let cancelledStatus = false;

            let theStatusIsFinish = false;

            let clientStatus = this.state.clientStatus;
            for (let value in this.state['statusList']) {
                if (this.state['statusList'][value].id == status) {

                    if (this.state['statusList'][value].finished == true) {
                        theStatusIsFinish = true;
                        if (this.state['statusList'][value].id == 6) // cancelado
                        {
                            cancelledStatus = true
                        }
                    }
                    if (this.state['statusList'][value].id == 4)
                        clientStatus = this.state['statusList'][value]
                }
            }
            
            if(directFinish == true || this.state.directFinishCalled == true) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        status: 4
                    },
                    clientStatus: clientStatus
                }))
                theStatusIsFinish = true
            }

            if(this.state.directSaveCalled == true)
                this.setState({ directSaveCalled: false })
            
            this.setState({
                modalopenFinish: false,
            });
            return true;
        }
    }
    

    finishCalledClient() {
        this.setState({
            loading: true,
        });
        var idCalled = this.state.called.id;
        var status = this.state.FinishCalledId;
        var updatedUser = this.state.called.updatedUser;

        var data = { Id: idCalled, status: status, updatedUser: updatedUser };

        Api.put("/calleds/CalledClientAlter", data)
            .then(result => {
                if (result.data.success) {
                    this.setState({ FinishCalled: false });
                    this.props.addCurrentCalled(result.data.data);
                    this.props.addCalled(result.data.data)
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "edit.called.success" }),
                        files: [],
                        action: {
                            description: "",
                            privacy: "false"
                        },
                    });
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
            });

        setTimeout(function () { window.location.reload(); }, 3000);
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    useStyles() {
        return withStyles({
            ImageList: {
                width: "100%",
                height: 100,
            },
            icon: {
                height: 32,
                width: 32
              }
        });
    }

    async getCategories() {

        var result = await CalledService.getAllCategories();

        if (result.success) {
            this.setState({ categoriesListAll: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }

        result = await CalledService.getCategories();
        if (result.success) {
            this.setState({ categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };

    getParentCalleds = async (organizationid) => {
        if(this.state.parentCalledsList && this.state.parentCalledsList.length > 0)
            return false;

        this.setState({ loading: true })
        if (organizationid == null) {
            //this.setCountLoading("getParentCalleds");
            return false;
        }

        var result = await CalledService.getParentCalled(organizationid);

        if (result.success) {

            let parents = result.data.map(item => {
                return {
                    ...item,
                    name: `${item.id}: ${item.title}`,
                    parentname: `${item.id}: ${item.title}`
                }

            })
            this.setState({ loading: false, parentCalledsList: parents && parents.length > 1 ? parents.sort((a, b) => a.localeCompare(b.name)) : parents });
            //this.setCountLoading("getParentCalleds");

        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
            //this.setCountLoading("getParentCalleds");

        }
    };

    async getCategoriesByOrganization(organizationId) {
        if (organizationId == null) {
            this.setCountLoading("getCategoriesByOrganization");
            return false;
        }

        var result = await CalledService.getCategoriesByOrganization(organizationId);

        if (result.success) {
            this.setState({ categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
            this.setCountLoading("getCategoriesByOrganization");


        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });

            this.setCountLoading("getCategoriesByOrganization");

        }
    };

    async migrateResm(calledId) {
        const result = await CalledService.migrateResm(calledId)
        if (result.success) {
            this.setCountLoading("migrateResm");
        }else {
            this.setCountLoading("migrateResm");
        }
    }

    setErrorMessage = (open, variant, message) => {
        this.setState({
            openNotification: open,
            notificationVariant: variant,
            notificationMessage: message
        });
    }

    errorTechnicalMessage = () => {
        const intl = this.props.intl;
        this.setErrorMessage(true, "error", intl.formatMessage({id:"called.accompaniment.error.technician.not.found"}))
    }

    async getTechnicalUsers() {


        const result = await CalledService.getTechnicalUsers(this.state.called.OrganizationCategoryId.id, this.state.called.organization);

        if (result.success) {
            this.setState({ technicalUsersList: result.data && result.data.lenght > 0 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
            console.log("this.state.called")
            console.log(this.state.called)
            this.setCountLoading("getTechnicalUsers");
            if (!this.isClient) {
                if (result && result.data) {
                    if (Array.isArray(result.data))
                        if (result.data.length == 0) { // porto
                            /*this.setState({
                                openNotification: true, notificationVariant: "error",
                                notificationMessage: intl.formatMessage({id:"called.accompaniment.error.technician.not.found"})
                            });*/
                        }
                }
            }


        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            this.setCountLoading("getTechnicalUsers");
            Api.kickoff(result);

        }
    }

    cancellCalled() {
        const intl = this.props.intl;
        this.setState({ loading: true });
        Api.put(`/calleds/cancel/${this.state.called.id}/${this.state.userId}`)
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({id:"called.accompaniment.called.canceled.success"})
                    });
                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            status: result.data.data.statusid
                        },
                        clientStatus: result.data.data.status
                    }));
                }
                this.closeCalledModal()

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
                this.closeCalledModal()
            });

    }


    cloneCalled() {
        this.setState({ loading: true });
        const intl = this.props.intl;
        let error = '';
        const { cloneCalledAmount } = this.state;

        if (!cloneCalledAmount) {
            error = intl.formatMessage({ id: "null.amount.notification" });
        } else if (isNaN(cloneCalledAmount)) {
            error = intl.formatMessage({ id: "notANumber.amount.notification" });
        } else if (cloneCalledAmount < 1) {
            error = intl.formatMessage({ id: "negative.amount.notification" });
        }

        if (error !== '') {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: error,
                cloneCalledAmount: null
            });
            return
        }

        const successNotification = `${intl.formatMessage({ id: "clone.called.create.left.notification" })} ${cloneCalledAmount} ${intl.formatMessage({ id: "clone.called.create.right.notification" })}`;
        error = false;

        var cloneFormData = new FormData()
        cloneFormData.append("calledid", this.props.calleds[`called-${this.urlId}`].id)
        cloneFormData.append("amount", cloneCalledAmount)
        cloneFormData.append("calledorganizationmaterial", this.state.calledorganizationmaterial)
        cloneFormData.append("calledusers", this.state.calledusers)
        cloneFormData.append("calledorganizationFields", this.state.calledorganizationFields)
        cloneFormData.append("calledtimeentries", this.state.calledtimeentries)
        cloneFormData.append("calledadditionalservices", this.state.calledadditionalservices)
        cloneFormData.append("initialcalledorganizationchecklists", this.state.initialcalledorganizationchecklists)
        cloneFormData.append("finalcalledorganizationchecklists", this.state.finalcalledorganizationchecklists)
        cloneFormData.append("calledorganizationpatrimonies", this.state.calledorganizationpatrimonies)

        Api.post(`/calleds/clone`, cloneFormData)
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: successNotification,
                        calledorganizationmaterial: false,
                        calledusers: false,
                        calledorganizationFields: false,
                        calledtimeentries: false,
                        calledadditionalservices: false,
                        initialcalledorganizationchecklists: false,
                        finalcalledorganizationchecklists: false,
                        calledorganizationpatrimonies: false,
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    error = true;
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    error = true;
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
            });
        this.closeCloneCalledModal();
    }

    async getTypes() {

        const result = await CalledService.getTypes();

        if (result.success) {
            this.setState({ typesList: result.data && result.data.length > 0 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });

            this.setCountLoading("getTypes");

        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            this.setCountLoading("getTypes");
            Api.kickoff(result);



        }
    };

    getAvaliableStatus() {
        const intl = this.props.intl;
        var statuses = [
            "novo", "new", "nuevo",
            "em andamento", "in progress", "en proceso",
            "encerrado", "closed", "cerrado",
            "agendado", "scheduled", "programado"
        ]
        return statuses;
    }

    async getStatus(finalizationexternalstatus) {

        if (finalizationexternalstatus == null) {
            this.setCountLoading("getStatus");
            return false;
        }
        Api.get(`/statuses/getstatus/${finalizationexternalstatus}`)
            .then(result => {
                if (result && result.data && result.data.data && result.data.success) {

                    let statuses = result.data.data.map(item => {
                        return {
                            ...item,
                            enable: item.system == false ? true : (this.getAvaliableStatus().indexOf(item.name.toLowerCase().trim()) > -1)
                        }

                    })

                    this.setState({ statusList: statuses && statuses.length > 1 ? statuses.sort((a, b) => a.name.localeCompare(b.name)) : statuses });
                    this.setCountLoading("getStatus");


                }

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    this.setCountLoading("getStatus");


                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                    this.setCountLoading("getStatus");


                }
            });


    };

    saveCalledReason = async e => {
        e.preventDefault()
        const intl = this.props.intl
        const { userSession } = this.props

        this.setState({ loading: true })

        if(this.state.calledReasonId == null) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.accompaniment.select.reason"}),
            })
            return
        }

        if (this.state.calledReasonId.id == 4) {
            if (this.state.reasonOther.length == 0) {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "other.select" }),
                })
                return
            }
        }

        let data = new FormData();
        data.append('calledid', this.state.called.id)
        data.append('reasonid', this.state.calledReasonId.id)
        data.append('description', this.state.reasonOther)
        data.append('createduser', userSession.user.id)

        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        //SLA aqui
        await Api.post("/calledreasons", data, config)
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        modalopenFinish: true,
                        slaOverflowContentModalOpen: false,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({id:"called.accompaniment.reason.saved"})
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
            })

        this.setState({ loading: false, savedReason: true })
    }

    saveFinishAccompaniment = e => {
        e.preventDefault()
        const intl = this.props.intl;
        const { userSession } = this.props

        this.setState({ loading: true })
        if(this.state.called.finishaccompanimentid == null || this.state.called.finishaccompanimentid == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"select.action"}),
            })
            return
        }

        let finish = this.state.called.finishaccompanimentid;

        this.setState(prevState => ({
            loading: false,
            loadingTwo: false,
            openNotification: true,
            finishAccompanimentModalOpen: false,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({id:"action.selected"}),
            called: {
                ...prevState.called,
                previousfinishaccompanimentid: finish
            }
        }))

    }

    handleSubmitDiskAccept = async (e) => {
        await this.setState({ newDiskAccept: true });
        this.handleSubmit(e);
    }

    openDialogNewDisk = async () => {
        this.setState({ openDialogNewDisk: !this.state.openDialogNewDisk });
    }

    handleSubmit = async e => {
        e.preventDefault();
        await this.setState({ clientClickFinishCalled: false, loading: true, loadingTwo: true, directFinishCalled: false });
        this.submitAction();
    }

    finishCalled = async e => {
        const intl = this.props.intl;
        e.preventDefault()
        if(this.state.called.addressid == 0) {
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.location"})
            })
            return false
        }
        this.setState({ clientClickFinishCalled: this.isClient ? true : false, loading: true, directFinishCalled: true, directSaveCalled: true }, () => this.submitAction())
    }

    saveCalled = async e => {
        const intl = this.props.intl;
        e.preventDefault();
        if(this.state.called.status && this.state.called.status == 2 && !this.state.called.assignedUser) {
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.attendance.error.missing.responsible"})
            });
            return false
        }
        if(this.state.called.addressid == 0) {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.location"})
            })
            return false
        }
        this.setState({ loading: true, directFinishCalled: false, directSaveCalled: true }, () => this.submitAction());
    }
    async postAccompaniment(data, config , closeModalFinish, bSaveCalled = true){
		const intl = this.props.intl;
		Api.post("/accompaniments", data, config)
		.then(result => {
			if (result.data.success) {
				if(result && result.data && result.data.data && result.data.data.length > 0){
					this.props.addAccompanimentsList(result.data.data);
					this.props.addAccompanimentsListInCalled(result.data.data, this.state.called.id);
				}

				this.setState({
					openDialogNewDisk: false,
				});

				this.setState(prevState => ({
					called: {
						...prevState.called,
						knowledgebaseattachments: []
					},
				}))

				if (this.isClient == false) {
					if(bSaveCalled){
						this.submitCalled(closeModalFinish);
					}
				} else {
					const intl = this.props.intl;
					this.setState({
						loading: false,
						loadingTwo: false,
						openNotification: true,
						notificationVariant: "success",
						notificationMessage: intl.formatMessage({ id: "add.action.success" }),
						files: [],
						action: {
							description: "",
							privacy: "false"
						},
						directFinishCalled: false,
					});
				}
			} else {
				this.setState({
					loading: false,
					loadingTwo: false,
					openNotification: true,
					notificationVariant: "error",
					notificationMessage: intl.formatMessage({id:"called.accompaniment.error.finish.called"})
				})
			}
		})
		.catch(err => {
			if(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && !isNaN(err.response.data.errors[0])){
				this.setState({
					loading: false,
					loadingTwo: false,
					openDialogNewDisk: true,
					valueNewDisk: err.response.data.errors[0],
				});
			}else{
				const intl = this.props.intl;
				this.setState({
					openDialogNewDisk: false,
					loading: false,
					loadingTwo: false,
					openNotification: true,
					notificationVariant: "error",
					notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
				});
			}

		});
	} 
    submitAction(closeModalFinish = false, directFinish = false, clientReopen = false) {
        PapiroConsole.log("=== submitAction ===")
        const intl = this.props.intl;
        this.setState({ loading: true, loadingTwo: true });

        if (this.props.calleds[`called-${this.urlId}`].organization.organizationsettings.requireattachedimage) {
            if (this.state.files.length === 0) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "files.required" }),
                })
                return;
            }
        }

        let sanitized = this.convertHtmlOption(this.state.action.description)
        if (sanitized != null && sanitized.length > 0)
            sanitized = sanitized.trim()

        if(sanitized != null && sanitized.length > 0) {
            console.log("=== this.state.called.calledstatus ===")
            console.log(this.state.called.calledstatus)
            const intl = this.props.intl;
            let calledStarted = null
            if (this.state.called.calledstatus && this.state.called.calledstatus.length > 0)
                calledStarted = this.state.called.calledstatus.filter(c => c.nextstatus && c.nextstatus.statustypeid == 2)
            if (this.state.called.status && (this.state.called.status == 1 || this.state.called.status == 8 || this.state.called.status == 9) && (this.state.called.assignedUser == null || typeof this.state.called.assignedUser === 'undefined' || (this.state.called.assignedUser && this.state.called.assignedUser == 0))) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.accompaniment.add.error.missing.attendance.responsible"}),
                })
                return;
            }
            
            if ((this.isClient == true && this.state.called.status && this.state.called.status == 1 && (calledStarted == null || (calledStarted && calledStarted.length == 0))) || (this.isClient == false && this.state.called.status && this.state.called.status == 1)) {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.accompaniment.add.error.missing.attendance.responsible"}),
                })
                return;
            }
        }

        let check = this.checkAccompaniment(false, closeModalFinish, directFinish);
        if (check == true) {
            this.setState({ loading: true });
            const { userSession } = this.props;
            const { action, called } = this.state;

            let statusData = this.state.statusList.filter(item => item.id ==this.state.called.status)
            if(
                ((
                    ((statusData && statusData.statustypeid == 5) || (statusData && statusData.length > 0 && statusData[0] && statusData[0].statustypeid == 5)) || ((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.state.files.length == 0)
                )
                && (
                    this.globalAdm == true || this.AdmOrganization == true || this.isCoordenator == true || this.isTechinical == true
                )) && !closeModalFinish
            ) {
                PapiroConsole.log("checkAccompaniment -> 1 ===")
                this.submitCalled(closeModalFinish);
            }else if ((sanitized && sanitized.length > 0) || this.state.files.length > 0) {
                PapiroConsole.log("checkAccompaniment -> 2 ===")
                let data = new FormData();

                data.append('description', sanitized && sanitized.length > 0 ? action.description : `#${intl.formatMessage({id:"attachments"})}`);
                data.append('calledTitle', called.title);
                data.append('privacy', action.privacy);
                data.append('called', called.id);
                data.append('userAction', userSession.user.id);
                data.append('finish', true);
                data.append('originid', 2);

                if(this.state.newDiskAccept){
                    data.append("acceptNewDisk", true);
                    this.setState({ newDiskAccept: false });
                }

                for (let key in this.state.called) {
                    let name = `called${key}`;

                    if (key === 'dueDate') {
                        data.append(name.toLowerCase(), moment(this.state.called[key]).endOf('day').format("YYYY/MM/DD HH:mm:ss"));
                    }
                    else if (key.toString().toLowerCase().indexOf('organizationcategoryid') > -1) {
                        data.append("OrganizationCategory", this.state.called[key].id);
                    }
                    else if (key.toString().toLowerCase().indexOf('calledassigneduser') > -1 || key.toString().toLowerCase().indexOf('assigneduser') > -1) {
                        if(this.state.called[key] && this.state.called[key].id){
                            data.append("calledassigneduser", this.state.called[key].id);
                        }else{
                            data.append("calledassigneduser", 0);
                        }

                    }
                    else {
                        data.append(name.toLowerCase(), this.state.called[key]);
                    }
                }

                let listKnowledgebaseAttachments = this.state.called.knowledgebaseattachments
                if(listKnowledgebaseAttachments != null && listKnowledgebaseAttachments != "") {
                    let knowledgeBaseAttachmentsIds = ""
                    listKnowledgebaseAttachments.map(item => {
                    knowledgeBaseAttachmentsIds = `${item.id},${knowledgeBaseAttachmentsIds}`
                    })

                    data.append("knowledgeBaseAttachmentsIds", knowledgeBaseAttachmentsIds)
                }

                this.state.files.forEach(file => data.append('filesUpload', file));

                let config = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };
                var aux = 0
                Api.post("/accompaniments", data, config)
                    .then(result => {
                        if (result.data.success) {
                            if(result && result.data && result.data.data && result.data.data.length > 0){
                                this.props.addAccompanimentsList(result.data.data);
                                this.props.addAccompanimentsListInCalled(result.data.data, this.state.called.id);
                            }

                            this.setState({
                                openDialogNewDisk: false,
                            });

                            this.setState(prevState => ({
                                called: {
                                    ...prevState.called,
                                    knowledgebaseattachments: []
                                },
                            }))
                            let pendingStatus = false
                            if (this.state.called.status != null && this.state.called.status > 0) {
                                this.state.statusList.map(item => {
                                    if (item.id == this.state.called.status && item.statustypeid == 7)
                                        pendingStatus = true
                                })
                            }
                            if (this.isClient == true && clientReopen == true)
                                this.reopenCalledClient()

                            if (this.isClient == false || (((this.state.called.requestingUser && this.state.called.requestingUser.id == userSession.user.id) || this.otherClientInteractsWithCalled == true) && pendingStatus)) {
                                this.submitCalled(closeModalFinish, true);
                            } else if((this.isClient == true && this.state.called.assignedUser != null && ((this.state.clientStatus && ((this.state.clientStatus.statustypeid == 2 || this.state.clientStatus.statustypeid == 7) && this.canclientfinishcalled == true && this.state.clientClickFinishCalled == true)) || (this.state.clientStatus.statustypeid != 7 && this.canclientautomaticchangestatusaftersendaccompaniment == true)))) {
                                this.submitCalled(closeModalFinish);
                            } else {
                                const intl = this.props.intl;
                                this.setState({
                                    loading: false,
                                    loadingTwo: false,
                                    openNotification: true,
                                    notificationVariant: "success",
                                    notificationMessage: intl.formatMessage({ id: "add.action.success" }),
                                    files: [],
                                    action: {
                                        description: "",
                                        privacy: "false"
                                    },
                                    directFinishCalled: false,
                                });
                            }
                        } else {
                            this.setState({
                                loading: false,
                                loadingTwo: false,
                                openNotification: true,
                                notificationVariant: "error",
                                notificationMessage: intl.formatMessage({id:"called.accompaniment.error.finish.called"})
                            })
                        }
                    })
                    .catch(err => {
                        if(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && !isNaN(err.response.data.errors[0])){
                            this.setState({
                                loading: false,
                                loadingTwo: false,
                                openDialogNewDisk: true,
                                valueNewDisk: err.response.data.errors[0],
                            });
                        }else{
                            const intl = this.props.intl;
                            this.setState({
                                openDialogNewDisk: false,
                                loading: false,
                                loadingTwo: false,
                                openNotification: true,
                                notificationVariant: "error",
                                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                            });
                        }

                    });
            } else {
                let sanitizedAction = this.convertHtmlOption(this.state.action.description)
                if (sanitizedAction != null && sanitizedAction.length > 0)
                    sanitizedAction = sanitizedAction.trim()
                
                if ((sanitizedAction == null || (sanitizedAction != null && sanitizedAction.length == 0)) && this.state.files.length == 0) {
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: clientReopen == true ? intl.formatMessage({id:"called.accompaniment.notification.called.reopen.error.missing.action.client"}) : this.state.clientClickFinishCalled == true ? intl.formatMessage({id:"called.accompaniment.notification.called.finish.error.missing.action.client"}) : intl.formatMessage({id:"called.accompaniment.notification.missing.action"})
                    });
                    return false;
                }
                this.setState({ loading: false, loadingTwo: false })
            }
        }
    }

    createUi = () => {
        return this.state.categoryChildrens.map((el, i) =>
          <Grid key={i} item xs={4} sm={4}>
            { i > 0 ?
            (<Grid container spacing={4}>
              <Grid item xs={1} sm={1} style={{ display: 'flex', alignItems: 'center', fontSize: '2em' }}>&gt;</Grid>
              <Grid item xs={10} sm={10}>
                <SimpleAutoCompleteByName
                  intl={this.props.intl}
                  label=""
                  options={this.state.categoryLists[i] || []}
                  stateName={`lcat-${i}`}
                  name={`lcat-${i}`}
                  changeSelect={this.handleChangeCategoryChildrens.bind(this, i)}
                  selected={el}
                  disabled={(this.isClient && !this.state.called.organizationsettings.roleclientcanselectcategory) ? true : (this.isClient && this.state.requestingUser.id != this.props.userSession.user.id) ? true : false} />
              </Grid>
            </Grid>)
            :
            (
              <SimpleAutoCompleteByName
                intl={this.props.intl}
                label=""
                options={this.state.categoryLists[i] || []}
                stateName={`lcat-${i}`}
                name={`lcat-${i}`}
                changeSelect={this.handleChangeCategoryChildrens.bind(this, i)}
                selected={el}
                disabled={(this.isClient && !this.state.called.organizationsettings.roleclientcanselectcategory) ? true : (this.isClient && this.state.requestingUser.id != this.props.userSession.user.id) ? true : false} />
            )}
          </Grid>
        )
      }

      handleAddChildrenCategory = (listCategory) => {
        this.setState(prevState => ({
          categoryChildrens: [
            ...prevState.categoryChildrens,
            { id: 0, name: '' }
          ],
          categoryLists: [
            ...prevState.categoryLists,
            listCategory
          ]
        }))
      }

      handleAddReverseChildrenCategory = async (listCategory, first, category = null) => {
        if(first) {
            this.setState({
                categoryChildrens: [
                    category != null ? category :{ id: 0, name: '' },
                ],
                categoryLists: [
                    listCategory,
                ]
            })

            if(category && category.cancreatecalled != null && category.cancreatecalled === true) {
                this.OrganizationCategoryId = category
                this.setState(prevState => ({
                    called: {
                      ...prevState.called,
                      OrganizationCategoryId: category
                    }
                }))
            }
        } else {
            this.setState(prevState => ({
            categoryChildrens: [
                category != null ? category :{ id: 0, name: '' },
                ...prevState.categoryChildrens,
            ],
            categoryLists: [
                listCategory,
                ...prevState.categoryLists,
            ]
            }))
        }
      }

      handleRemoveChildrenCategory = async (i) => {
        PapiroConsole.log("=== handleRemoveChildrenCategory ===")
        PapiroConsole.log(i)
        this.setState({ loading: true })

        let categoryChildrens = [...this.state.categoryChildrens]
        let categoryLists = [...this.state.categoryLists]
        categoryChildrens.splice(i+1)
        categoryLists.splice(i+1)

        await this.getPreviousCategory(i)

        this.setState({ categoryChildrens, categoryLists })
        this.setState({ loading: false })
      }

      getPreviousCategory = async (i) => {
        PapiroConsole.log("=== getPreviousCategory ===")
        PapiroConsole.log(i)
        if(i > 0) {
          if (this.state.categoryChildrens[i-1] !== undefined) {
            if(this.state.categoryChildrens[i-1].cancreatecalled === true) {
              this.OrganizationCategoryId = this.state.categoryChildrens[i-1]
              this.setState(prevState => ({
                called: {
                  ...prevState.called,
                  OrganizationCategoryId: this.state.categoryChildrens[i-1]
                }
              }))
              await this.categoryCanCreateCalled(this.state.categoryChildrens[i-1])
            } else {
              await this.getPreviousCategory(i-1)
            }
          }
        } else {
            if (this.state.categoryChildrens[i] !== undefined && this.state.categoryChildrens[i].cancreatecalled === true) {
                this.OrganizationCategoryId = this.state.categoryChildrens[i]
                this.setState(prevState => ({
                    called: {
                    ...prevState.called,
                    OrganizationCategoryId: this.state.categoryChildrens[i]
                    }
                }))
            } else {
                this.OrganizationCategoryId = null
                this.setState(prevState => ({
                    called: {
                    ...prevState.called,
                    OrganizationCategoryId: null
                    }
                }))
            }
        }
      }

      async handleChangeCategoryChildrens(i, stateName, value) {
        PapiroConsole.log("=== handleChangeCategoryChildrens ===")
        PapiroConsole.log("OrganizationCategoryId")
        PapiroConsole.log(value)

        this.setState(prevState => ({
          called: {
            ...prevState.called,
            OrganizationCategoryId: value,
          },
          loading: true
        }))

        let categoryChildrens = [...this.state.categoryChildrens]
        if (i < categoryChildrens.length -1 )
          this.handleRemoveChildrenCategory(i)
        else {
          categoryChildrens[i] = value
          this.setState({ categoryChildrens })
        }

        if(value && value.id && value.id > 0) {
          await this.getCategoryChildrenByParentOrganizationCategoryId(value.id, stateName)
          if(value.cancreatecalled === true) {
            await this.categoryCanCreateCalled(value)
          }
        }
        this.setState({ loading: false })
      }

      categoryCanCreateCalled = async (value) => {
        this.categoryId = value;
      }

      getCategoryChildrenByParentOrganizationCategoryId = async (parentId, stateName = '') => {
        const intl = this.props.intl;

        var result = await CalledService.getByParentOrganizationCategoryId(parentId);
        if (result && result.success) {
          if(result.data && result.data.length > 0) {
            this.handleAddChildrenCategory(result.data)
          }
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }
      }

      getCategoryParentByChildrenOrganizationCategoryId = async (childrenId, stateName = '', first = false) => {
        const intl = this.props.intl;

        var result = await CalledService.getCategoryParentByChildrenOrganizationCategoryId(childrenId);
        if (result && result.success) {
          if(result.data && result.data.listitems && result.data.listitems.length > 0) {
            if(result.data && result.data.organizationcategory && result.data.organizationcategory.id) {
                await this.handleAddReverseChildrenCategory(result.data.listitems, first, result.data.organizationcategory)
                await this.getCategoryParentByChildrenOrganizationCategoryId(result.data.organizationcategory.parentorganizationcategoryid)
            } else {
                let parentIdFilter = result.data.listitems.filter(item => item.id == childrenId)
                if(parentIdFilter && parentIdFilter.length > 0) {
                    await this.handleAddReverseChildrenCategory(result.data.listitems, first, parentIdFilter[0])
                    await this.getCategoryParentByChildrenOrganizationCategoryId(parentIdFilter[0].parentorganizationcategoryid)
                } else {
                    await this.handleAddReverseChildrenCategory(result.data.listitems, first)
                }
            }
          }
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }
      }

      getRootCategoriesByOrganization = async (organizationId) => {
        const intl = this.props.intl;
        var result = await CalledService.getRootCategoriesByOrganization(organizationId);
        if (result.success) {
          if (this.isTec == false) {
            if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
              if (this.AdmOrganization) {
                this.setState({
                  openNotification: true, notificationVariant: "error",
                  notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"}),
                  notificationLinkMove: "organizationcategories"
                });
              } else {
                this.setState({
                  openNotification: true, notificationVariant: "error",
                  notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"})
                });
              }
            }
          }
          this.setState({
            levelCategories: {
              rootCategoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
            },
            categoryLists: [
                result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
            ]
          });
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }
      };

    getDefaultEmployeeHourValue = async value => {
        const intl = this.props.intl;
        this.setState({ loading: true });
        Api.get(`/users/employeehourvalue/${value}`)
            .then(result => {
                if (result && result.data && result.data.data && result.data.success) {
                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            employeehourvalue: !this.caninformzeroincalledtechnicalcost ? result.data.data.employeehourvalue : prevState.called.employeehourvalue,
                            valueforcalled: !this.caninformzeroincalledtechnicalcost ? result.data.data.valueforcalled : prevState.called.valueforcalled,
                            costparameter: result.data.data.costparameter ? result.data.data.costparameter : 1,
                            kmvaluerotated: result.data.data.kmvaluerotated,
                        },
                        loading: false
                    }))
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                } else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
            });
    }

    async submitCalled(closeModalFinish, clientChangeStatus = false) {
        //console.log("=== submitCalled ===")
        //console.log(this.props.calleds[`called-${this.urlId}`].submitaction);
        this.setState({
            loading: true,
            loadingTwo: true,
        });
        let check = this.checkFinishCalled(true, closeModalFinish);
        if (check) {

            if(this.state.called.hasdisplacementTrue == true) {
                await this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        hasdisplacement: true
                    }
                }))
            } else if(this.state.called.hasdisplacementFalse == true) {
                await this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        hasdisplacement: false
                    }
                }))
            }

            let addressData = null
            if (this.state.called.addresstype == "3") {
                const intl = this.props.intl;

                if(this.state.address.name == null || !this.state.address.name) {
                    this.setState({
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({id:"missing.name.field"})
                    })
                    return false
                }
                
                let addressConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                };
        
                let addressData = new FormData()
                for (let key in this.state.address) {
                    if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
                        addressData.append(key, moment(this.state.address[key]).format('YYYY/MM/DD HH:mm:ss'));
                    } else if (key.toString().toLowerCase().indexOf('id') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : 0);
                    } else if (key.toString().toLowerCase().indexOf('name') > -1) {
                        addressData.append(key, this.state.address[key]);
                    } else if (key.toString().toLowerCase().indexOf('street') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('zipcode') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('number') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('complement') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('neighborhood') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('city') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('state') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('country') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('longitude') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('latitude') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('iscustomaddress') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : true);
                    } else {
                    }
                }

                addressData.append("organization", this.state.called.organization.id)

                if (this.state.address.id > 0) {
                    const intl = this.props.intl;

                    addressData.append("updateduser", this.state.address.updatedUser);
                    addressData.append("updatedat", this.state.address.updatedAt);
              
                    let result = await AddressService.editItem(addressData, addressConfig)
                    if (result && result.success) {
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                addressid: result.data.id,
                            },
                            address: {
                            ...prevState.address,
                            id: result.data.id ? result.data.id : 0
                            },
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'success',
                            notificationMessage: intl.formatMessage({ id: "edit.success" })
                        }))
                    } else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        })
                        Api.kickoff(result)
                    }
                } else {
                    const intl = this.props.intl;

                    addressData.append("createduser", this.state.address.createdUser);
                    addressData.append("createdat", this.state.address.createdAt);
              
                    let result = await AddressService.addItem(addressData, addressConfig)
                    if (result && result.success) {
                      this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            addressid: result.data.id,
                        },
                        address: {
                          ...prevState.address,
                          id: result.data.id ? result.data.id : 0
                        },
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({ id: "add.success" })
                      }))
                    } else {
                      const intl = this.props.intl
                      this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                      })
                      Api.kickoff(result)
                    }
                }
            }

            let data = new FormData();

            for (let key in this.state.called) {

                if (key === 'dueDate') {
                    data.append(key, moment(this.state.called[key]).format("YYYY/MM/DD HH:mm:ss"));
                }
                else if (key === "ParentCalledId") {
                    if (this.state.called.ParentCalledId !== 0 && this.state.called.ParentCalledId !== null && this.state.called.ParentCalledId != undefined) {
                        data.append("ParentCalledId", this.state.called.ParentCalledId.id);
                    } else {
                        data.append("ParentCalledId", 0);
                    }

                } else if (key.toString().toLowerCase().indexOf('requestinguser') > -1) {
                    data.append("requestinguser", this.state.called[key].id);
                } else if (key.toString().toLowerCase().indexOf('assigneduser') > -1) {
                    if(this.state.called[key] && this.state.called[key].id){
                        data.append("assigneduser", this.state.called[key].id);
                    }else{
                        data.append("assigneduser", 0);
                    }

                } else if (key.toString().toLowerCase().indexOf('organizationcategoryid') > -1) {
                    PapiroConsole.log("=== submitcalled ===")
                    PapiroConsole.log("organizationcategoryid")
                    PapiroConsole.log(this.state.called[key])
                    PapiroConsole.log(this.state.called)
                    data.append("OrganizationCategory", this.state.called[key].id);
                }
                else if (key.toString() === 'departamentId') {
                    let deptVal = this.state.called[key].id
                    if (deptVal == null || typeof deptVal === 'undefined')
                        deptVal = 0
                    data.append("OrganizationDepartamentId", deptVal);
                } else if(
                    key
                    .toString()
                    .toLowerCase()
                    .indexOf("employeehourvalue") > -1) {
                      if(this.state.called[key].length > 0) {
                        /* Este código funciona local, ms não em DEMO! Explica essa ateu!
                        let employeehourvalueNotComma = this.state.called[key].replaceAll(",", "")
                        let employeehourvalueFloat = parseFloat(employeehourvalueNotComma) + ""
                        let employeehourvalueFinal = employeehourvalueFloat.replace(".", ",")
                        data.append(key, employeehourvalueFinal)*/
                        data.append(key, this.state.called[key])
                      } else
                        data.append(key, 0)
                } else if(key.toString().toLowerCase().indexOf("valueforcalled") > -1) {
                    if(this.state.called[key].length > 0 || this.state.called[key] > 0) {
                      /* Este código funciona local, ms não em DEMO! Explica essa ateu!
                      let gasvalueperliterNotComma = this.state.called[key].replaceAll(",", "")
                      let gasvalueperliterFloat = parseFloat(gasvalueperliterNotComma) + ""
                      let gasvalueperliterFinal = gasvalueperliterFloat.replace(".", ",")
                      data.append(key, gasvalueperliterFinal)*/
                      data.append(key, this.state.called[key])
                    } else
                      data.append(key, 0)
                }else if(key.toString().toLowerCase().indexOf("finishaccompanimentid") > -1) {
                    if(this.state.called[key] != null && this.state.called[key].id > 0) {
                        data.append(key, this.state.called[key].id)
                    }
                }else if(key.toString().toLowerCase().indexOf("priorityid") > -1) {
                    if(this.state.called[key] && this.state.called[key] > 0) {
                        data.append(key, this.state.called[key])
                    } else if(typeof this.state.called[key] === 'undefined') {
                        if(this.state.called.priority && this.state.called.priority.id) {
                            data.append(key, this.state.called.priority.id)
                        }
                    } else {
                        data.append(key, 0)
                    }
                } else if (key.toString().toLowerCase().indexOf('addressid') > -1) {
                    PapiroConsole.log("=== addressid ===")
                    PapiroConsole.log(this.state.called[key])
                    PapiroConsole.log("this.state.called.addresstype =>")
                    PapiroConsole.log(this.state.called.addresstype)
                    PapiroConsole.log(typeof this.state.called.addresstype)
                    PapiroConsole.log("addressData =>")
                    PapiroConsole.log(addressData)
                    if (this.state.called[key]) {
                      if (this.state.called[key] > 0)
                        data.append("addressid", this.state.called[key]);
                      else if (this.state.called.addresstype == "3" && addressData != null) {
                        data.append("addressid", addressData.id);
                      }
                    } else {
                      if (this.state.called.addresstype == "3" && addressData != null)
                        data.append("addressid", addressData.id);
                      else
                        data.append("addressid", 0);
                    }
                } else if (key.toString().toLowerCase().indexOf('addresstype') > -1) {
                    let addresstypeNumber = parseInt(this.state.called[key])
                    if (!isNaN(addresstypeNumber))
                        data.append("addresstype", addresstypeNumber);
                    else
                        data.append("addresstype", 1);
                } else {
                    data.append(key, this.state.called[key]);
                }
            }
            data.append("applicationoriginid", 2)

            let list = this.state.called['patrimonyId'];

            if (list != null && list != "") {

                let result = "";

                for (let value in this.state.called['patrimonyId']) {
                    result = this.state.called['patrimonyId'][value].id + "," + result
                }

                data.append("calledOrganizationPatrimonyId", result);
            }
            data.append("directfinishcalled", this.state.directFinishCalled)
            data.append("clientchangestatus", clientChangeStatus)

            Api.put("/calleds", data)
                .then(result => {
                    if (result.data.success) {
                        const finishedNow = this.state.called.status && this.state.called.status.finished;
                        const calledFinish = result.data.data && result.data.data.status && result.data.data.status.finished
                        const CalledFinishAccompanimentId = result.data.data && result.data.data.calledstatusfinishaccompanimentaccompanimentid
                        const originId = this.props.calleds && this.props.calleds[`called-${this.urlId}`] && this.props.calleds[`called-${this.urlId}`].originrequest && this.props.calleds[`called-${this.urlId}`].originrequest.id;

                        var showSphereMessage = false
                        if (originId && (originId == 2 || originId == 6 || originId == 5 || originId == 4) && (finishedNow || calledFinish)) {
                            showSphereMessage = true;
                        }

                        if(CalledFinishAccompanimentId != null && CalledFinishAccompanimentId > 0) {
                            this.setState({ finishAccompanimentId : CalledFinishAccompanimentId }, () => this.refreshAccompaniments())
                        }
                        var propCalled = result.data.data
                        propCalled.originRequestName = result.data.data.originrequest.name
                        propCalled.assignedUser = result.data.data.originrequest.name

                        const intl = this.props.intl;
                        this.props.addCurrentCalled(propCalled);
                        this.props.addCalled(propCalled)
                        this.statusDataBase = result.data.data.status.id;
                        this.setState(prevState => ({
                            loading: false,
                            loadingTwo: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: showSphereMessage ? intl.formatMessage({ id: "finish.called" }) + '. ' + intl.formatMessage({ id: "close.called.sphere" }) : intl.formatMessage({ id: "edit.called.success" }),
                            //notificationMessage: "Finalizado aqui",
                            files: [],
                            action: {
                                description: "",
                                privacy: "false"
                            },
                            called: {
                                ...prevState.called,
                                finishaccompanimentid: null,
                                calledstatus: result.data.data.calledstatus != null ? result.data.data.calledstatus : [],
                                priority: result.data.data.priority != null ? result.data.data.priority : null,
                                status: result.data.data.status && result.data.data.status.id
                            },
                            clientStatus: result.data.data.status
                        }));

                        Object.keys(this.state.called).forEach((key) => {
                            if (key.includes("ofield-")) {

                                let val = this.state.called[key]
                                let explode = key.split('-')
                                let organizationFieldId = explode[1];

                                let dataCallOrgField = new FormData();
                                dataCallOrgField.append('Id', organizationFieldId)
                                dataCallOrgField.append('Value', val);
                                dataCallOrgField.append('UpdatedUser', this.state.userId);
                                Api.put("/calledorganizationfields", dataCallOrgField)
                                    .then(result => { })
                                    .catch(err => {
                                        const intl = this.props.intl;
                                        this.setState({
                                            loading: false,
                                            openNotification: true,
                                            notificationVariant: "error",
                                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                                        });
                                    });
                            }
                        })
                        this.getCalledCostToCard()
                        if(result.data.data.cost != null)
                            this.detailCardsCost(result.data.data.cost)
                    }
                })
                .catch(err => {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                });
        } else {
            this.setState({
                loading: false,
                loadingTwo: false,
            });
        }
    }
	handleChangeEditor(value) {
		this.setState(prevState => ({
			called: {
			  ...prevState.called,
			  description: value || ""
			}
		  }));
	}

    handleChangeEditorAction(value) {
		this.setState(prevState => ({
			action: {
			  ...prevState.action,
			  description: value || ""
			}
		  }));
	}

    handleInputChange(e, origin) {
        const name = e.target.name;
        const value = e.target.value;
        const formName = origin ? 'action' : 'called';

        //PapiroConsole.log(`=== handleInputChange(e, origin) ===`)
        //PapiroConsole.log(`origin => ${origin}`)
        //PapiroConsole.log(e)
        //PapiroConsole.log(e.target)
        //PapiroConsole.log(e.target.name)
        //PapiroConsole.log(e.target.value)

        if (name === "cloneCalledAmount") {
            this.setState({ cloneCalledAmount: value })
            return
        }
        if (name === "reasonOther") {
            this.setState({ reasonOther: value })
        }

        if(name === "description") {
            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    finishaccompanimentid: null
                }
            }))
        }

        if (name === 'kmvaluerotated') {
            const re = /^[0-9\b]+$/;
            if (re.test(value)) {
              this.setState(prevState => ({
                called: {
                  ...prevState.called,
                  [name]: value
                }
              }));
            }else if(value.length == 0) {
                this.setState(prevState => ({
                    called: {
                      ...prevState.called,
                      [name]: value
                    }
                }))
            }
        } else {
            this.setState(prevState => ({
                [formName]: {
                    ...prevState[formName],
                    [name]: value
                }
            }));
        }

        PapiroConsole.log(this.state)
    };

    changeCloneValues = (stateName, value, text = '') => {
        this.setState({
            [stateName]: value
        })
    }

    changeValues = async (stateName, value, text = '') => {
        this.setState(prevState => ({
            called: {
                ...prevState.called,
                [stateName]: value

            }
        }));

        if(stateName === "status") {
            let getStatusById = this.state.statusList.filter(item => item.id == value)
            if(getStatusById != null) {
                if(getStatusById.length > 0) {
                    this.setState({ clientStatus: getStatusById[0] })
                    if(getStatusById[0].statustypeid == 5 || getStatusById[0].finished == true)
                        this.setState({ finishAccompaniment: true })
                    else
                        this.setState({ finishAccompaniment: false })
                } else
                    this.setState({ finishAccompaniment: false })
            } else
                this.setState({ finishAccompaniment: false })
        }

        if (stateName === "calledReasonId") {
            this.setState({
                ["calledReasonId"]: value
            })
        }

        if (stateName === "defaultActionId") {
            if(value && value.Name && value.Name.length > 0) {
                this.setState(prevState => ({
                    action: {
                        ...prevState.action,
                        description: this.state.action.description + value.Name || ""
                    },
                    defaultActionId: 0
                }))
            } else {
                this.setState({ defaultActionId: 0 })
            }
        }

        if (stateName === 'addressid') {
            PapiroConsole.log('=== addressid ===')
            PapiroConsole.log(stateName)
            PapiroConsole.log(value)
            PapiroConsole.log(this.state.called.addressid)
            //console.log(value)
            /*this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    ['addressid']: value.id
                }
            }))*/
        }
        if (stateName === "departamentId") {
            this.setState({ departamentId: value })
        }

        if (stateName === "OrganizationCategoryId") {
            PapiroConsole.log("=== changeValues ===")
            PapiroConsole.log("OrganizationCategoryId")
            PapiroConsole.log(value)
            this.OrganizationCategoryId = value;
            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    ["OrganizationCategoryText"]: text
                },
                technicalUsersList: [],
                search: {
                    ...prevState.search,
                    technicalUsersList: `/users/category/${value.id}/organization/${this.state.calledorganizationid}`
                }
            }));
            if (this.OrganizationCategoryId && this.OrganizationCategoryId.id && this.OrganizationCategoryId.id > 0)
                await this.getDepartamentsByOrganizationCategoryId(this.OrganizationCategoryId.id)
            if(value && value.typeid && value.typeid > 0) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        type: value.typeid
                    },
                    typeDisabled: true
                }));
            } else {
                this.setState({ typeDisabled: false });
            }
            if(value && value.priorityid && value.priorityid > 0) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        priorityid: value.priorityid,
                        priority: value.priority && value.priority.id ? value.priority : null
                    },
                    priorityDisabled: true
                }));
            } else {
                this.setState({ priorityDisabled: false });
            }
            if(value && value.caneditcalledtitleanddescription != null) {
                let canEdit = true
                if(this.state.isDisabled == true && value.caneditcalledtitleanddescription == true)
                    canEdit = false;
                else
                    canEdit = value.caneditcalledtitleanddescription
                this.setState({ caneditcalledtitleanddescription: canEdit });
            }
        }
        if (stateName === "ParentCalledId") {
            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    ["ParentCalledText"]: text
                }
            }));
        }
        if(stateName === "assignedUser") {
            if(value > 0)
                await this.getDefaultEmployeeHourValue(value.id)
        }
        if(stateName === "finishaccompanimentid") {
            this.setState({
                action: {
                    description: "",
                    privacy: "false"
                }
            })
        }
        if(stateName == 'hasdisplacementTrue'){
            if(value == true){
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasdisplacementTrue']: value,
                        ['hasdisplacementFalse']: false,
                    }
                }));
            }else{
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasdisplacementTrue']: value,
                        ['hasdisplacementFalse']: true,
                    }
                }))
            }
        }
        if(stateName == 'hasdisplacementFalse'){
            if(value == true){
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasdisplacementFalse']: value,
                        ['hasdisplacementTrue']: false,
                    }
                }));
            }else{
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasdisplacementFalse']: value,
                        ['hasdisplacementTrue']: true,
                    }
                }))
            }
        }
        if(stateName == 'hasParentCalledTrue'){
            if(value == true){
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasParentCalledTrue']: value,
                        ['hasParentCalledFalse']: false,
                    }
                }));
                await this.getParentCalleds(this.state.called.organization)
            }else{
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasParentCalledTrue']: value,
                        ['hasParentCalledFalse']: true,
                    }
                }))
            }
        }
        if(stateName == 'hasParentCalledFalse'){
            if(value == true){
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasParentCalledFalse']: value,
                        ['hasParentCalledTrue']: false,
                    }
                }));
            }else{
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasParentCalledFalse']: value,
                        ['hasParentCalledTrue']: true,
                    }
                }))
                await this.getParentCalleds(this.state.called.organization)
            }
        }
        if(stateName == 'hasPatrimoniesTrue'){
            if(value == true){
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasPatrimoniesTrue']: value,
                        ['hasPatrimoniesFalse']: false,
                    }
                }));
                await this.getPatrimonios(this.state.called.organization)
            }else{
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasPatrimoniesTrue']: value,
                        ['hasPatrimoniesFalse']: true,
                    }
                }))
            }
        }
        if(stateName == 'hasPatrimoniesFalse'){
            if(value == true){
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasPatrimoniesFalse']: value,
                        ['hasPatrimoniesTrue']: false,
                    }
                }));
            }else{
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['hasPatrimoniesFalse']: value,
                        ['hasPatrimoniesTrue']: true
                    }
                }))
                await this.getPatrimonios(this.state.called.organization)
            }
        }
        if(stateName == 'priorityid') {
            let priorityItem = null
            if(this.state.priorityList && this.state.priorityList.length > 0) {
                let priorityFilter = null
                if(value && value.id && value.id > 0)
                    priorityFilter = this.state.priorityList.filter(item => item.id == value.id)
                else
                    priorityFilter = this.state.priorityList.filter(item => item.id == value)
                if(priorityFilter && priorityFilter.length > 0)
                    priorityItem = priorityFilter[0]
            }


            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    ['priorityid']: value && value.id ? value.id : value,
                    ['priority']: priorityItem
                }
            }));
        }
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    pauseCalled() {
        this.setState({ loading: true });
        Api.put(`/calleds/pause/${this.state.called.id}/${this.state.userId}`)
            .then(result => {
                if (result.data.success) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "pause.success" })
                    });
                    this.setState(prevState => ({
                        called: {

                            ...prevState.called,
                            status: result.data.data.statusid,
                            loading: false,
                        },
                        clientStatus: result.data.data.status
                    }));
                }

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
            });


            this.setState({ loading: false });
    }

    getCalledByParent() {
        if (this.state.called.ParentCalledId !== 0 && this.state.called.ParentCalledId != null && this.state.called.ParentCalledId != undefined) {
            this.setState({ loading: true });
            Api.get(`/calleds/calledInfo/${this.state.called.ParentCalledId.id}`)
                .then(result => {
                    if (result.data.success) {
                        const intl = this.props.intl;
                        let parent = result.data.data;
                        parent.parentname = `${parent.id}: ${parent.title}`;
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                ParentCalledId: result.data.data
                            },
                            loading: false
                        }));
                    }

                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    }
                    else {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "process.error" })
                        });
                    }
                });
        }

    }


    getCalled() {

        Api.get(`/calleds/calledInfo/${this.state.called.id}`)
            .then(result => {
                if (result.data.success) {
                    const intl = this.props.intl;
                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            status: result.data.data.statusid
                        },
                        clientStatus: result.data.data.status
                    }));
                }
                this.setCountLoading("getCalled");

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({

                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({

                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
                this.setCountLoading("getCalled");

            });
    }

    addFileItem = (acceptedFiles) => {
        this.setState({ files: acceptedFiles })
    }

    removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file);
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }
    videoCallClick() {
        //console.log(value);
        this.setState({ modalopen: true });
    };
    openModal() {

    }
    async closeModal() {

        this.setState({ modalopen: false });

    }

    async closeModalFinish() {

        //this.setState({ modalopenFinish: false });

        /*if (this.props.calleds[`called-${this.urlId}`].submitaction == true) {
            this.submitAction(true);
        }*/

        this.setState({ modalopenFinish: false });
    }

    calledFinishOk = async () => {
        //this.submitAction(true);
        this.submitAction(true);
    }

    closeModalCalledFinish = () => {

        this.setState({ modalopenFinish: false });

    }

    async openAccompanimentLocationModal(id, type = 2) {
        const intl = this.props.intl;
        this.setState({ loading: true });
        let result = await getByAccompanimentIdAndHistoryCheckType(id, type)
        //console.log(result)
        if (result && result.data && result.data.data) {
            this.setState({
                positionAccompCalled: [result.data.data.latitude ? result.data.data.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '') : '', result.data.data.longitude ? result.data.data.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '') : ''],
                addressAccompCalled: result.data.data.addresscomplete ? result.data.data.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '') : '',
                loading: false,
                accompanimentLocationModalOpen: !this.state.accompanimentLocationModalOpen
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
        }
    }

    async openFinishAccompanimentModal() {
        const intl = this.props.intl;
        this.setState({ loading: true });
        let result = await getAccompanimentsToFinish(this.state.called.id, this.state.called.requestingUser.id)
        if (result && result.data && result.data.data) {
            let finishAccompaniments = result.data.data.map(item => {
                let body = item.description
                let temp = document.createElement("div")
                temp.innerHTML = body
                let sanitized = temp.textContent || temp.innerText

                return {
                    ...item,
                    name: sanitized,
                    Name: item.description
                }
            })

            this.setState({
                finishAccompanimentList: finishAccompaniments && finishAccompaniments.length > 1 ? finishAccompaniments.sort((a, b) => a.name.localeCompare(b.name)) : finishAccompaniments,
                loading: false,
                finishAccompanimentModalOpen: !this.state.finishAccompanimentModalOpen
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
        }

        //console.log(" === this.state.called.finishaccompanimentid === ")
        //console.log(this.state.called.finishaccompanimentid)
    }

    async openLocationModal() {
        const intl = this.props.intl;
        this.setState({ loading: true });
        let result = await getByCalledIdAndHistoryCheckType(this.state.called.id, 1)
        //console.log(result)
        if (result && result.data && result.data.data) {
            this.setState({
                positionCalled: [result.data.data.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', ''), result.data.data.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')],
                addressCalled: result.data.data.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', ''),
                loading: false,
                locationModalOpen: !this.state.locationModalOpen
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
        }
    }

    openSLAOverflowModal = async _ => {
        const intl = this.props.intl;
        this.setState({ loading: true });
        await Api.get(`/reasons`)
            .then(result => {
                if (result && result.data && result.data.data) {
                    this.setState({
                        slaOverflowContentModalOpen: !this.state.slaOverflowContentModalOpen,
                        reasonsList: result.data.data.length > 1 ? result.data.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }

    async getCalledFile(fileName,filerepositorymethod) {
		//window.open(`${Api.apiurlbase()}api/calleds/file/${this.state.hirerid}/${this.state.called.id}/${fileName}`, '_blank');
		const intl = this.props.intl;
		this.setState({ loading: true });
        
		
		let url = await CalledService.getFile(this.state.hirerid,this.state.called.id,fileName,filerepositorymethod); 
		//alert(JSON.stringify(url.data));
		this.setState({ loading: false });
		if(url &&  url.data && url.data.data && url.data.success){
			window.open(`${url.data.data}`, '_blank');
		}
		else{
			this.setState({
				loading: false, openNotification: true, notificationVariant: "error",
				notificationMessage: intl.formatMessage({ id: "file.not.found" })
			});

		}
        /*
        if (fileName.indexOf("files/") == -1) {
            window.open(`${Api.apiurlbase()}api/calleds/downloadold/${fileName}/${this.state.called.id}/${this.state.hirerid}`, '_blank');
        } else {
            let fName = fileName
            if(fName.startsWith('/wwwroot/', 0))
            fName = fName.substring(9)
            
            let apiUrl = Api.apiurlbase()
            if (apiUrl.endsWith("/"))
            apiUrl = apiUrl.slice(0, -1)

            window.open(`${apiUrl}/${fName.replace("///", "/").replace("//", "/").replace("/files", "files")}`, '_blank');
        }
		*/
    };

    async getAccompainmentFile(fileName, accompanimentId,filerepositorymethod) {
		const intl = this.props.intl;
		this.setState({ loading: true });

		let url = await AccompanimentService.getFile(this.state.hirerid,this.state.called.id,accompanimentId,fileName,filerepositorymethod); 
        this.setState({ loading: false });

		if(url &&  url.data && url.data.data && url.data.success){
			window.open(`${url.data.data}`, '_blank');
		}
		else{
			this.setState({
				loading: false, openNotification: true, notificationVariant: "error",
				notificationMessage: intl.formatMessage({ id: "file.not.found" })
			});

		}

		//window.open(`${Api.apiurlbase()}api/accompaniments/file/${this.state.hirerid}/${this.state.called.id}/${accompanimentId}/${fileName}`, '_blank');
        /*
        if (fileName.indexOf("files/") == -1) {
            window.open(`${Api.apiurlbase()}api/accompaniments/downloadold/${fileName}/${this.state.called.id}/${accompanimentId}/${this.state.hirerid}`, '_blank');
        } else {
            window.open(`${Api.apiurlbase()}/${fileName}`, '_blank');
        }
		*/
    };

    handleExpandAttendanceHoursCard = () => {
        let expand = !this.state.costBody.attendanceHoursExpanded

        this.setState(prevState => ({
            costBody: {
                ...prevState.costBody,
                attendanceHoursExpanded: expand
            }
        }))
    }

    showCostReport = () => {

    }

    showHoursReport = () => {
        const { classes } = this.props
        const intl = this.props.intl;

        const hoursReportColumns = [
            {
                "name": intl.formatMessage({id:"call.solution.hours"}),
                "value": this.state.cost.hours.totalHoursRemote,
                "color": "#3f50b5"
            },
            {
              "name": intl.formatMessage({id:"inloco.attendance.hours"}),
              "value": this.state.cost.hours.totalHoursInLoco,
              "color": "#FC2361"
            },
            {
              "name": intl.formatMessage({id:"displacement.hours"}),
              "value": this.state.cost.hours.totalHoursDisplacement,
              "color": "#F294AF"
            }
        ]

        console.log("=== Report ===")
        console.log(hoursReportColumns)

        let attendanceHours =
            <Card>
                <CardHeader
                title={intl.formatMessage({id:"attendance.hours"})}
                subheader="HH:mm" />
                <CardContent>
                    <div className={classes.chartContainer}>
                        <PieChart width={300} height={250} >
                            <Pie
                                data={hoursReportColumns}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={this.renderCustomizedLabel}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="sumallvalue" >
                                {
                                    hoursReportColumns.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))
                                }
                            </Pie>
                        </PieChart>
                    </div>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton
                        className={clsx(classes.expand, {
                        [classes.expandOpen]: this.state.costBody.attendanceHoursExpanded,
                        })}
                        onClick={this.handleExpandAttendanceHoursCard}
                        aria-expanded={this.state.costBody.attendanceHoursExpanded}
                        aria-label="attendance card hours - show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={this.state.costBody.attendanceHoursExpanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        Teste
                    </CardContent>
                </Collapse>
            </Card>

        this.setState(prevState => ({
            costBody: {
                ...prevState.costBody,
                attendanceHours: attendanceHours
            }
        }))
    }

    showInLocoReport = () => {

    }

    showCalledReport = () => {
        //this.showCostReport()
        //this.showHoursReport()
        //this.showInLocoReport()
    }

    handleChange = async (e) => {
        let value = e.target.value
    
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            addresstype: value,
          }
        }))
    }

    async getUserAddresses(userId) {
        this.setState({ loading: true })
        const result = await CalledService.getUserAddresses(userId);
        const intl = this.props.intl;
        if (result.success) {
          if (this.globalAdm || this.AdmOrganization) {
            if (result.data.length < 1) {
              if (this.AdmOrganization) {
                this.setState({
                  openNotification: true, notificationVariant: "error",
                  notificationMessage: intl.formatMessage({id:"user.no.registered.addresses"}),
                  notificationLinkMove: "organizations"
                });
              } else {
                this.setState({
                  openNotification: true, notificationVariant: "error",
                  notificationMessage: intl.formatMessage({id:"user.no.registered.addresses"})
                });
              }
            }
          }
    
          if(this.globalAdm || this.AdmOrganization) {
            if(result.data && result.data.length == 1) {
              PapiroConsole.log("=== addressid ===")
              PapiroConsole.log(this.state.called)
              PapiroConsole.log(this.organizationId)
    
              this.setState(prevState => ({
                called: {
                  ...prevState.called,
                  addressid: result.data[0].id
                }
              }))
            }
          }
    
          this.setState({ loading: false, userAddressesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
    
        }
      }

    async getClientsByOrganizationId() {
        const { calleds } = this.props
        const intl = this.props.intl;

        this.setState({ loading: true })
    
        const result = await CalledService.getClientsByOrganizationId(calleds[`called-${this.urlId}`].organization.id);
        if (result.success) {
          if (this.isTec == false) {
            if (result.data.length < 1 && calleds[`called-${this.urlId}`].organization.id.toString().indexOf(",") == -1) {
              if (this.AdmOrganization) {
                this.setState({
                  openNotification: true, notificationVariant: "error",
                  notificationMessage:intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.requester"}),
                  notificationLinkMove: "users"
                });
              } else {
                this.setState({
                  openNotification: true, notificationVariant: "error",
                  notificationMessage:intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.requester"})
                });
              }
            }
          }
          let reordernewarrayuserteclist = new Array();
          result.data.map(item => {
            item.name =  item.name + " ("+item.email+")";
            reordernewarrayuserteclist.push(item);
          })
    
          this.setState({ loading: false, clientUsersList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
    
        }
    }

    render() {
        const { classes, headerMenu, calleds } = this.props;
        const { id } = this.props.match.params

        //console.log("calleds aqui")
        //console.log(calleds)
        const { address, called, action } = this.state;
        const classes2 = this.useStyles();
        const intl = this.props.intl;
        //console.log(this.state.isDisabled)
        //console.log("disabled")
        let modalP = null;
        let modalVideo = null;
        let modalCheck = null;

        PapiroConsole.log("=== Status ===")
        PapiroConsole.log(called.status)

        if (this.state.modalPatrimonio === true) {
            modalP = (
                <ModalPatrimonio
                    onClose={() => this.fecharModalPatrimonio()}
                    loadingPatrimony={() =>
                        this.getPatrimonios(this.state.called.organization)
                    }
                    id={this.state.called.organization}
                    notification={() => {
                        this.openNotificacao();
                    }}
                />
            );

        }

        if (this.state.initialChecklistModalOpen === true) {
            modalCheck = (
                <ChecklistModal
                    calledId={this.state.called.id}
                    setCheckConfig={this.setCheckConfig}
                    open={this.state.initialChecklistModalOpen}
                    onClose={this.closeInitialChecklistModal} />
            );
        }

        if (this.state.modalopen === true) {
            modalVideo = (
                <ModalVideoCall
                    onClose={() => this.closeModal()}
                    requestUser={this.infoRequest}
                    orgSettings={this.state.orgSettings}
                    called={this.state.called}
                    organization={this.state.organization}
                    organizationkey={this.state.organizationkey}
                />
            );

        }




        const HtmlTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                minWidth: 'max-content',
                fontSize: theme.typography.pxToRem(12),
                border: '1px solid #dadde9',
            },
            [`& .${tooltipClasses.popper}`]: {
                position: 'absolute',
                inset: '0 auto auto 0',
                margin: 0,
                left: '-37%',
                transform: 'translate(1147px, 286px)'
            }
        }));

        const calledBody = () => {
            return (
                <Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={intl.formatMessage({ id: "organization" })}
                                name="organizacao"
                                value={this.state.called.orgname}
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography>{intl.formatMessage({id:"phone"})}: {this.phone}</Typography>
                                                    </React.Fragment>
                                                }
                                            >
                                                <Add />
                                            </HtmlTooltip>
                                        </InputAdornment>
                                    ),
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={intl.formatMessage({ id: "requestuser" })}
                                name="solicitante"
                                value={calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].requestinguser.name ? calleds[`called-${this.urlId}`].requestinguser.name : ""}
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography>{intl.formatMessage({id:"email"})}: {this.infoRequest && this.infoRequest.email ? this.infoRequest.email : '---'}</Typography>
                                                        <Typography>{intl.formatMessage({id:"phone"})}: {this.infoRequest && this.infoRequest.phone ? this.infoRequest.phone : '---'}</Typography>
                                                    </React.Fragment>
                                                }
                                            >
                                                <Add />
                                            </HtmlTooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <SimpleAutoCompleteAsynchronousTechnicalUsers
                                label={intl.formatMessage({ id: "assigned" })}
                                linkToSearch={this.state.search.technicalUsersList}
                                toSearchProp={true}
                                nameAndEmail={true}
                                options={this.state.technicalUsersList}
                                stateName="assignedUser"
                                changeSelect={this.changeValues}
                                selected={this.state.called.assignedUser}
                                disabled={(((!called.organizationsettings.canassingusercalled && !this.isCoordenator) && !this.globalAdm) && !this.AdmOrganization)}
                                setErrorMessage={this.setErrorMessage}
                                isNotClientErrorMessage={true}
                                isClient={this.isClient}
                            />
                        </Grid>
                        {/*(this.isTechinical || this.globalAdm) && (
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <SimpleSelect label={intl.formatMessage({ id: "location" })} options={this.state.organizationAddressesList} stateName="addressid" changeSelect={this.changeValues} selected={this.state.called.addressid} />
                            </Grid>
                        )*/}

                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <SimpleSelect label={intl.formatMessage({ id: "type" })} options={this.state.typesList} stateName="type" changeSelect={this.changeValues} selected={called.type} disabled={ this.state.typeDisabled } />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <SimpleSelectWithColor label={intl.formatMessage({ id: "menu.statuses" })} options={this.state.statusList} stateName="status" changeSelect={this.changeValues} selected={called.status} />
                        </Grid>

                    {(this.isTechinical || this.globalAdm) && (
                    <>
                        <Grid item xs={12} sm={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{intl.formatMessage({ id: "location" })}</FormLabel>
                            <RadioGroup row aria-label="addresstype" name="addresstype" defaultValue="1" value={called.addresstype} onChange={this.handleChange}>
                            <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label={intl.formatMessage({id:"organization.location"})} />
                            <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label={intl.formatMessage({id:"requester.location"})} />
                            <FormControlLabel
                                value="3"
                                control={<Radio />}
                                label={intl.formatMessage({id:"location.new"})} />
                            </RadioGroup>
                        </FormControl>
                        </Grid>
                        {(called.addresstype != null && called.addresstype === "1") && (<Grid item xs={12} sm={12}>
                            <SimpleSelect
                                label={intl.formatMessage({ id: "location" })}
                                options={this.state.organizationAddressesList}
                                stateName="addressid"
                                changeSelect={this.changeValues}
                                selected={called.addressid}
                                hasLocationIcon={true}
                                required />
                        </Grid>)}
                        {(called.addresstype != null && called.addresstype === "2") && (<Grid item xs={12} sm={12}>
                            <SimpleSelect
                                label={intl.formatMessage({ id: "location" })}
                                options={this.state.userAddressesList}
                                stateName="addressid"
                                changeSelect={this.changeValues}
                                selected={called.addressid}
                                hasLocationIcon={true}
                                required />
                        </Grid>)}
                        {(called.addresstype != null && called.addresstype === "3") && (<Grid item xs={12} sm={12}>
                        <Accordion style={{marginTop: "6px", marginBottom: "10px"}}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography className={classes.heading}>{intl.formatMessage({id:"location.new"})}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={`${intl.formatMessage({id:"name"})}*`} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChangeAddress} value={address.name} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField inputProps={{ maxLength: 100 }} fullWidth label={intl.formatMessage({id:"cep"})} autoComplete='fname' variant='outlined' name='zipcode' onChange={this.handleInputChangeAddress} value={address.zipcode} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({id:"street"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='street' onChange={this.handleInputChangeAddress} value={address.street} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({id:"number"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='number' onChange={this.handleInputChangeAddress} value={address.number} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({id:"complement"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='complement' onChange={this.handleInputChangeAddress} value={address.complement} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({id:"neighborhood"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='neighborhood' onChange={this.handleInputChangeAddress} value={address.neighborhood} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({id:"city"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='city' onChange={this.handleInputChangeAddress} value={address.city} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({id:"state"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='state' onChange={this.handleInputChangeAddress} value={address.state} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({id:"country"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='country' onChange={this.handleInputChangeAddress} value={address.country}  />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({id:"longitude"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='longitude' onChange={this.handleInputChangeAddress} value={address.longitude}  />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({id:"latitude"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='latitude' onChange={this.handleInputChangeAddress} value={address.latitude}  />
                                </Grid>
                            </Grid>
                            </AccordionDetails>
                        </Accordion>
                        </Grid>)}
                    </>
                    )}
                    {/*this.globalAdm ||this.AdmOrganization || this.isCoordenator || this.isClient
                            ? <LogoutButton onClick={this.handleLogoutClick} />
                            : <LoginButton onClick={this.handleLoginClick} />
                    */}
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="fname"
                                name="title"
                                variant="outlined"
                                fullWidth
                                id="title"
                                InputLabelProps={{ shrink: true }}
                                disabled={!this.state.caneditcalledtitleanddescription}
                                label={intl.formatMessage({ id: "title" })}
                                value={called.title}
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                            <Typography>
                                <FormattedMessage id="description" />
                            </Typography>
							<ReactQuill name="description" value={called.description || ''} style={{ minHeight: 200 }}
                                modules={this.reactQuillModules}
                                formats={this.reactQuillFormats}
								fullWidth
								readOnly={!this.state.caneditcalledtitleanddescription ? true : false}
								required
								InputLabelProps={{ shrink: called.description && called.description.length > 0 ? true : false }}
								variant="outlined"
								id="outlined-multiline-static"
								onChange={this.handleChangeEditor} />
                        </Grid>
                    {this.state.called.calledattachments && this.state.called.calledattachments.length > 0 && (
                        <Grid item xs={12} style={{ marginBottom: 50 }}>
                            <div className={classes.attachmentsBox}>
                                <label>{<FormattedMessage id="attachments" />}</label>
                                <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                    <ul>
                                        {this.state.called.calledattachments.map(file => {
                                            return (<li key={file.attachment.path}>
                                                <AttachFile /> <Button onClick={() => this.getCalledFile(file.attachment.path, file.attachment.filerepositorymethod)}>{file.attachment.description}</Button>
                                            </li>)
                                        })}
                                    </ul>
                                </ImageList>
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <SimpleDatePicker label={intl.formatMessage({ id: "solution.forecast" })} stateName="dueDate" changeDate={this.changeValues} currentDate={called.dueDate} />
                    </Grid>
                    <If test={this.isCoordenator || this.globalAdm || this.AdmOrganization} >
                        <If test={this.state.called.costparameter == 2} >
                            <Grid item xs={3} sm={3}>
                                <TextField
                                    label={intl.formatMessage({id:"value.per.called"})}
                                    variant="outlined"
                                    value={called.valueforcalled}
                                    onChange={this.handleInputChange}
                                    name="valueforcalled"
                                    id="formatted-numberformat-input"
                                    InputProps={{
                                        inputComponent: this.NumberFormatCustom,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    autoComplete="fname"
                                    required />
                            </Grid>
                            {/*<Grid item xs={2} sm={2} style={{ marginLeft: "-10px" }}>
                                <SimpleHelp message={"Valor Hora Técnico (Usar . como separador decimal)"} />
                            </Grid>*/}
                        </If>
                        <If test={this.state.called.costparameter == 1} >
                            <Grid item xs={3} sm={3}>
                                <TextField
                                    label={intl.formatMessage({id:"called.employee.hour.value"})}
                                    variant="outlined"
                                    value={called.employeehourvalue}
                                    onChange={this.handleInputChange}
                                    name="employeehourvalue"
                                    id="formatted-numberformat-input"
                                    InputProps={{
                                        inputComponent: this.NumberFormatCustom,
                                    }}
                                    autoComplete="fname"
                                    required
                                    InputLabelProps={{ shrink: true }} />
                            </Grid>
                            {/*<Grid item xs={2} sm={2} style={{ marginLeft: "-10px" }}>
                                <SimpleHelp message={"Valor Hora Técnico (Usar . como separador decimal)"} />
                                </Grid>*/}
                        </If>



                    </If>
                    </Grid>
                    <If test={this.isCoordenator || this.globalAdm || this.AdmOrganization} >
                            <Grid xs={12} sm={12} style={{ marginTop: 10, marginLeft: 5, marginBottom: 5 }}>
                                <Typography component='h1' variant='h5'>
                                    {intl.formatMessage({id:"called.accompaniment.will.have.displacement"})}
                                </Typography>
                            </Grid>
                            <Grid container spacing={4} style={{ marginTop: 5, marginLeft: 5, marginBottom: 15 }}>
                                <Grid xs={4} sm={1}>
                                    <SimpleCheckBox changeSelect={this.changeValues} stateName='hasdisplacementTrue' fullWidth label={this.props.intl.formatMessage({id:"yes"})} autoComplete='fname' name="hasdisplacementTrue" selected={this.state.called.hasdisplacementTrue} variant='outlined' />
                                </Grid>
                                <Grid xs={4} sm={1}>
                                    <SimpleCheckBox changeSelect={this.changeValues} stateName='hasdisplacementFalse' fullWidth label={this.props.intl.formatMessage({id:"no"})} autoComplete='fname' name="hasdisplacementFalse" selected={this.state.called.hasdisplacementFalse} variant='outlined' />
                                </Grid>
                            </Grid>
                            {called.hasdisplacementTrue && (
                                <fieldset style={{
                                    marginTop: 25,
                                    marginBottom: 25,
                                    border: '1px solid #c0c0c0',
                                    padding: '0 0 15px 0',
                                    borderLeft: 'none',
                                    borderRight: 'none'
                                }}>
                                    <legend style={{
                                        paddingTop: 15,
                                        fontSize: '1.3rem',
                                        border: '1px solid #c0c0c0',
                                        borderBottom: 'none',
                                        borderLeft: 'none',
                                        borderRight: 'none'
                                    }}>{intl.formatMessage({id:"called.accompaniment.displacement.config"})}</legend>
                                    <Grid container spacing={6}>
                                        <Grid item xs={4} sm={4}>
                                            <TextField
                                                label={intl.formatMessage({id:"called.distance.value"})}
                                                variant="outlined"
                                                value={called.kmvaluerotated}
                                                onChange={this.handleInputChange}
                                                name="kmvaluerotated"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: this.NumberFormatCustom,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                autoComplete="fname"
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            )}
                    </If>

                    <If test={this.state.organizationFieldList.length > 0} >
                        <fieldset style={{
                            marginTop: 25,
                            marginBottom: 25,
                            border: '1px solid #c0c0c0',
                            padding: '0 0 15px 0',
                            borderLeft: 'none',
                            borderRight: 'none'
                        }}>
                            <legend style={{
                                paddingTop: 15,
                                fontSize: '1.3rem',
                                border: '1px solid #c0c0c0',
                                borderBottom: 'none',
                                borderLeft: 'none',
                                borderRight: 'none'
                            }}>{intl.formatMessage({ id: "organization.custom.fields" })}</legend>
                            <Grid container spacing={4}>
                                {this.state.organizationFieldList.map(item => {
                                    return (
                                        <Grid item xs={6} sm={6}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                autoComplete="fname"
                                                name={'ofield-' + item.id}
                                                variant="outlined"
                                                id={'ofield-' + item.id}
                                                label={item.organizationfield.name}
                                                fullWidth
                                                onChange={this.handleInputChange}
                                                inputProps={{ maxLength: 250 }}
                                                required={item.organizationfield.isrequired}
                                                value={this.state.called[`ofield-${item.id}`]} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </fieldset>
                    </If>

                    {/*this.state.called.quantityreama4 > 0 && (
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <TextField id='filled-number' disabled name="quantityreama4" label="Qtd Resma" value={this.state.called.quantityreama4} />
                            </Grid>
                        </Grid>
                    )*/}
                </Fragment>
            )
        };

        const actionBody = () => {
            return (
                <Fragment>
                    <If test={this.state.finishAccompaniment == true}>
                            <RTabs selectedIndex={this.state.rTabIndex} onSelect={ index => this.setState({ rTabIndex: index }) } >
                                <RTabList style={{ borderColor: '#BB8FCE' }}>
                                    <RTab onClick={() => {
                                        this.setState(prevState => ({
                                            called: {
                                                ...prevState.called,
                                                finishaccompanimentid: null
                                            }
                                        }))
                                    } }>{intl.formatMessage({id:"action.new"})}</RTab>
                                    <RTab onClick={() => {
                                        this.getTechnicalPublicAccompaniment()
                                        this.setState({
                                            action: {
                                                description: "",
                                                privacy: "false"
                                            }
                                        })
                                    }}>{intl.formatMessage({id:"action.existing"})}</RTab>
                                </RTabList>
                                <RTabPanel style={{ padding: 10 }}>
                                    <Grid container spacing={0} style={{ marginTop: 15 }}>
                                    { (called.organizationsettings.defaultaccompaniment && !this.isClient) && (
                                        <Grid item xs={12} style={{ marginBottom: 15 }}>
                                            <SimpleAutoComplete
                                                label={intl.formatMessage({ id: "add.default.action" })}
                                                options={this.state.defaultActionList}
                                                stateName='defaultActionId'
                                                changeSelect={this.changeValues}
                                                selected={this.state.defaultActionId}
                                                htmlOptions={true} />
                                        </Grid>
                                    )}
                                        <Grid item xs={12} style={{ marginBottom: 20 }}>
                                            {/*<TextField
                                                name="description"
                                                autoComplete="fname"
                                                label={intl.formatMessage({ id: "action" })}
                                                multiline
                                                rows="8"
                                                fullWidth
                                                value={action.description}
                                                variant="outlined"
                                                onChange={(e) => this.handleInputChange(e, "action")}
                                                inputProps={{ maxLength: 255 }}
                                            // disabled={this.isClient ? true : false}
                                            />*/}
                                                <Typography>
                                                    <FormattedMessage id="action" />
                                                </Typography>
                                                <ReactQuill
                                                    id="action"
                                                    name="action"
                                                    modules={this.reactQuillModules}
                                                    formats={this.reactQuillFormats}
                                                    value={action.description || ''}
                                                    style={{ height: 200 }}
                                                    readOnly={this.isClient ? true : false}
                                                    fullWidth
                                                    required
                                                    InputLabelProps={{ shrink: action.description && action.description.length > 0 ? true : false }}
                                                    variant="outlined"
                                                    id="outlined-multiline-static"
                                                    onChange={this.handleChangeEditorAction} />
                                        </Grid>
                                        { !this.isClient && <Grid item xs={12} sm={12} justify="flex-end" style={{ marginTop: 40, display: 'flex' }}>
                                            <Tooltip title={<h5><FormattedMessage id="insert.knowledge.base" /></h5>}>
                                                <IconButton aria-label="insert-knowledge-base"
                                                    onClick={this.openKnowledgeBaseDialog}
                                                    style={{
														marginBottom: '30px' ,
                                                        backgroundColor: '#277afe',
                                                        color: '#FFF'
                                                    }}>
                                                    <EmojiObjectsIcon style={{ fontSize: '2rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid> }
                                    </Grid>
                                </RTabPanel>
                                <RTabPanel style={{ padding: 10 }}>
                                <Grid container spacing={0} style={{ marginTop: 15 }}>
                                        <Grid item xs={12}>
                                            <SimpleAutoComplete
                                                label={intl.formatMessage({ id: "select.action" })}
                                                options={this.state.finishAccompanimentList}
                                                stateName='finishaccompanimentid'
                                                changeSelect={this.changeValues}
                                                selected={this.state.called.finishaccompanimentid}
                                                htmlOptions={true} />
                                        </Grid>
                                    </Grid>
                                </RTabPanel>
                            </RTabs>
                    </If>
                    <If test={this.state.finishAccompaniment == false}>
                        <Grid container spacing={0} style={{ marginTop: 15 }}>
                            { (called.organizationsettings.defaultaccompaniment && !this.isClient) && (
                            <Grid item xs={12} style={{ marginBottom: 15 }}>
                                <SimpleAutoComplete
                                    label={intl.formatMessage({ id: "add.default.action" })}
                                    options={this.state.defaultActionList}
                                    stateName='defaultActionId'
                                    changeSelect={this.changeValues}
                                    selected={this.state.defaultActionId}
                                    htmlOptions={true} />
                            </Grid>
                            )}
                            {(((this.isClient && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) || (!this.isClient && (this.isTechinical || this.AdmOrganization || this.isCoordenator || this.globalAdm))) && (<Grid item xs={12} style={{ marginBottom: 20 }}>
                                {/*<TextField
                                    name="description"
                                    autoComplete="fname"
                                    label={intl.formatMessage({ id: "action" })}
                                    multiline
                                    rows="8"
                                    fullWidth
                                    value={action.description}
                                    variant="outlined"
                                    onChange={(e) => this.handleInputChange(e, "action")}
                                    inputProps={{ maxLength: 255 }}
                                // disabled={this.isClient ? true : false}
                                />*/}
                                    <Typography>
                                        <FormattedMessage id="action" />
                                    </Typography>
                                    <ReactQuill name="action"
                                        value={action.description || ''}
                                        style={{ height: 200 }}
                                        fullWidth
                                        modules={this.reactQuillModules}
                                        formats={this.reactQuillFormats}
                                        required
                                        InputLabelProps={{ shrink: action.description && action.description.length > 0 ? true : false }}
                                        variant="outlined"
                                        id="outlined-multiline-static"
                                        onChange={this.handleChangeEditorAction} />
                            </Grid>)}
                            { !this.isClient && <Grid item xs={12} sm={12} justify="flex-end" style={{ marginTop: 40, display: 'flex' }}>
                                <Tooltip title={<h5><FormattedMessage id="insert.knowledge.base" /></h5>}>
                                    <IconButton aria-label="insert-knowledge-base"
                                        onClick={this.openKnowledgeBaseDialog}
                                        style={{
											marginBottom: '30px',
                                            backgroundColor: '#277afe',
                                            color: '#FFF'
                                        }}>
                                        <EmojiObjectsIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid> }
                        </Grid>
                    </If>
                    {(((this.isClient && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) || (!this.isClient && (this.isTechinical || this.AdmOrganization || this.isCoordenator || this.globalAdm))) && (<Grid container spacing={0}>
                        <section style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
                            <div className={classes.dropzoneArea}>
                                <Dropzone onDrop={acceptedFiles => { this.setState({ files: acceptedFiles }) }}>
                                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p>{<FormattedMessage id="drag.and.drop.files" />}</p>
                                        </div>
                                    )}
                                </Dropzone>
                                {this.state.files.map(file => {
                                    return (
                                        <div className={classes.dropzoneItem} key={file.name}>
                                            {file.name}
                                            <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                    </Grid>)}
                    {called.knowledgebaseattachments.length > 0  && (
                    <Grid container spacing={0} style={{marginTop: "5px"}}>
                        <Grid item xs={12}>
                            <div className={classes.attachmentsBox}>
                                <label>{<FormattedMessage id="knowledgebase.attachments" />}</label>
                                <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                    <ul>
                                        {called.knowledgebaseattachments.map(file => {
                                            return (<li key={file.path}>
                                                <DeleteForever onClick={() => this.deleteItem(file)} style={{ cursor: "pointer" }} />
                                                <AttachFile /> <Button onClick={() => this.getCalledFile(file.path, file.filerepositorymethod)}>{file.description}</Button>
                                            </li>)
                                        })}
                                    </ul>
                                </ImageList>
                            </div>
                        </Grid>
                    </Grid>
                    )}
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={8}>
                            {!this.isClient && (
                                <RadioGroup aria-label="privacy" name="privacy" value={action.privacy} onChange={(e) => this.handleInputChange(e, "action")} row>
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio color="primary" />}
                                        label={intl.formatMessage({ id: "public.action" })}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio />}
                                        label={intl.formatMessage({ id: "private.action" })}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            )}
                        </Grid>
                        {(((this.isClient && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) || (!this.isClient && (this.isTechinical || this.AdmOrganization || this.isCoordenator || this.globalAdm))) && (<Grid item xs={12} sm={4}>
                            <div style={ !this.isClient ? { float: 'right', display: 'flex' } : { display: 'flex', justifyContent: 'flex-end' }} >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ width: '100%',  borderRadius: '4px 0 0 4px !important' }}
                                    disabled={(this.state.disablebutton) ? true : false} >
                                    {<FormattedMessage id="add.action" />}
                                </Button>
                                {!this.isClient && (<Button
                                    id="add-action-more-button"
                                    aria-controls={this.state.addActionOpen ? 'add-action-more-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={this.state.addActionOpen ? 'true' : undefined}
                                    onClick={this.addActionHandleClick}
                                    variant="contained"
                                    color="primary"
                                    style={{ width: '5%', paddingLeft: 0, paddingRight: 0, borderRadius: '0 4px 4px 0 !important' }}
                                    disabled={(this.state.disablebutton) ? true : false}
                                    startIcon={<ArrowDropDownIcon />} >
                                    &nbsp;</Button>)}
                                    <Menu
                                        id="add-action-more-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'add-action-more-button',
                                        }}
                                        anchorEl={this.state.addActionAnchorEl}
                                        open={this.state.addActionOpen}
                                        onClose={() => this.addActionHandleClose()}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }} >
                                        { this.state.sectableStatus.map((stts, i) => {
                                            if (stts != null && stts.name != null)
                                                return <MenuItem key={i} onClick={() => this.addActionHandleClose(stts.id)}>{intl.formatMessage({id:"send.and.set.as"})} {stts.name}</MenuItem>
                                        }) }
                                    </Menu>
                            </div>
                        </Grid>)}
                    </Grid>
                    <div className={classes.actionItems}>
                        <IconButton color="inherit"
                            aria-label="open drawer"
                            onClick={this.refreshAccompaniments}
                            edge="start"
                        >
                            <CachedIcon />
                        </IconButton>
                        { (calleds[`accompaniments-${this.urlId}`] && calleds[`accompaniments-${this.urlId}`].length > 0) && <ImageList cellHeight={60} className={classes2.ImageList} cols={1} >

                            {calleds[`accompaniments-${this.urlId}`].map((action, i) => {
                                if (this.isClient && action.privacy) {

                                } else {

                                    if(action.description.indexOf("https://") > -1 && (action.description.endsWith(".webm") > -1 || action.description.endsWith(".pdf") > -1 || action.description.endsWith(".png") > -1 || action.description.endsWith(".jpeg") > -1 || action.description.endsWith(".jpg") > -1 || action.description.indexOf("@@{") > -1)){
                                        return <AccompanimentActionVideo
                                            key={i}
                                            action={action}
                                            showPrivacy={!this.isClient}
                                            client={this.isClient}
                                            knowledgebage={action.knowledgebase && action.knowledgebase.deleted == false }
                                            attachments={action.accompanimentattachments}
                                            addAccompanimentToKnowledgeBase={() => this.addAccompanimentActionToKnowledgeBase(action.id)}
                                            deleteKnowledgeBase={() => this.deleteKnowledgeBase(action.id)}
                                            getFile={this.getAccompainmentFile}
                                            id={action.id}
                                            originId={action.originid}
                                            finishAccompanimentId={this.state.finishAccompanimentId}
                                            openAccompanimentLocationModal={() => {
                                                if (action.calledstatusid == 4 && action.originid == 6) {
                                                    if (action.description.search(/realizou a assinatura da OS/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 5)
                                                    else if (action.description.search(/realizou Check-out de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 8)
                                                    else
                                                        this.openAccompanimentLocationModal(action.id)
                                                } else {
                                                    if (action.description.search(/realizou Check-in .s/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 3)
                                                    else if (action.description.search(/realizou Check-out .s/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 4)
                                                    else if (action.description.search(/realizou Check-in de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 7)
                                                    else if (action.description.search(/realizou Check-..t de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 8)
                                                    else
                                                        this.openAccompanimentLocationModal(action.id)
                                                }
                                            }}
                                        />
                                    }else{
                                        return <AccompanimentAction
                                            key={i}
                                            action={action}
                                            showPrivacy={!this.isClient}
                                            client={this.isClient}
                                            knowledgebage={action.knowledgebase && action.knowledgebase.deleted == false }
                                            attachments={action.accompanimentattachments}
                                            addAccompanimentToKnowledgeBase={() => this.addAccompanimentActionToKnowledgeBase(action.id)}
                                            deleteKnowledgeBase={() => this.deleteKnowledgeBase(action.id)}
                                            getFile={this.getAccompainmentFile}
                                            id={action.id}
                                            originId={action.originid}
                                            finishAccompanimentId={this.state.finishAccompanimentId}
                                            openAccompanimentLocationModal={() => {
                                                if (action.calledstatusid == 4 && action.originid == 6) {
                                                    if (action.description.search(/realizou a assinatura da OS/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 5)
                                                    else if (action.description.search(/realizou Check-out de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 8)
                                                    else
                                                        this.openAccompanimentLocationModal(action.id)
                                                } else {
                                                    if (action.description.search(/realizou Check-in .s/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 3)
                                                    else if (action.description.search(/realizou Check-out .s/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 4)
                                                    else if (action.description.search(/realizou Check-in de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 7)
                                                    else if (action.description.search(/realizou Check-..t de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 8)
                                                    else
                                                        this.openAccompanimentLocationModal(action.id)
                                                }
                                            }}
                                            />
                                    }

                                }
                            })}
                        </ImageList>
                        }
                    </div>
                </Fragment>
            );
        };

        const headerContent = () => {
            const intl = this.props.intl;
            return (
                <Fragment>

                    <Grid container>
                        <Grid item xs={3}  > <h3>Nº {(id && id > 0) ? id : this.state.called.id}</h3> </Grid>
                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }} > {called.CallDueDate} </Grid>
                        {this.state.called.calledclosingstatusname && (
                        <Grid item xs={1}>
                            <Tooltip
                                title={<h5>{intl.formatMessage({id:"closingstatus"})}: {this.state.called.calledclosingstatusname}</h5>}
                                aria-label="called-closing-status">
                                    <IconButton aria-label="called-closing-status">
                                        <InfoIcon style={{ color: '#3f51b4', fill: '#3f51b4', fontSize: '2rem' }} />
                                    </IconButton>
                            </Tooltip>
                        </Grid>
                        )}
                        {((!this.isClient) && (this.state.called.priority && this.state.called.priority.colorhex && this.state.called.priority.name) ) && (
                        <Grid item xs={6} style={{ marginBottom: 0, fontSize: '0.8em' }}>
                            <Card className={classes.card} style={{ width: 'max-content' }}>
                                <CardContent style={{ backgroundColor: `${this.state.called.priority.colorhex}`, color: '#fff', fontSize: '1.1em', padding: 15, display: 'flex', justifyContent: 'center' ,width: 'max-content' }}>
                                    {this.state.called.priority.name}
                                </CardContent>
                            </Card>
                        </Grid>)}

                    </Grid>
                    {this.state.CalledModalOpen && (
                        <DialogConfirmation
                            msgButton={intl.formatMessage({id:"confirm"})}
                            msg={intl.formatMessage({id:"called.accompaniment.confirm.finish.called"})}
                            msgDisagree={intl.formatMessage({id:"cancel"})}
                            msgAgree={intl.formatMessage({id:"yes"})}
                            handleConfirmation={e => this.cancellCalled()}
                            handleClose={e => this.closeCalledModal()}
                            classes={classes}
                            disabled={false}
                            icon={<DeleteIcon />}
                        />
                    )}

                    {/*<Dialog open={this.state.CalledModalOpen} onClose={this.closeCalledModal} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">
                            Cancelamento do chamado
                            <ClearIcon onClick={this.closeCalledModal} style={{ marginLeft: '64px', cursor: 'pointer' }} />
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText>
                                Você deseja realmente cancelar o chamado ?
                            </DialogContentText>

                        </DialogContent>
                        <Button onClick={this.cancellCalled} color="secondary" type="submit" style={{ textAlign: 'center' }}>
                            Confirmar
                        </Button>
            </Dialog>*/}
                    <Dialog
                        maxWidth="md"
                        open={this.state.confirmReopenCalledClientDialog}
                        onClose={this.closeConfirmReopenCalledClientDialog}
                        aria-labelledby="confirm-reopen-client-dialog-title" >
                        <DialogTitle id="confirm-reopen-client-dialog-title">
                            <FormattedMessage id="reopen.called" />
                            <ClearIcon onClick={this.closeConfirmReopenCalledClientDialog} style={{ float: 'right', cursor: 'pointer' }} />
                            <DialogContent>
                                <DialogContentText>
                                    {intl.formatMessage({ id: "confirm.reopen.called" })}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeConfirmReopenCalledClientDialog} color="secondary">
                                    {intl.formatMessage({ id: "cancel" })}
                                </Button>
                                <Button onClick={this.confirmReopenCalledClient} color="secondary" type="submit">
                                    {intl.formatMessage({ id: "confirm" })}
                                </Button>
                            </DialogActions>
                        </DialogTitle>
                    </Dialog>
                    <Dialog
                        maxWidth="md"
                        open={this.state.knowledgeBaseDialogOpen}
                        onClose={this.closeKnowledgeBaseDialog}
                        aria-labelledby="knowledgebase-dialog-title" >
                            <DialogTitle id="knowledgebase-dialog-title">
                            <FormattedMessage id="insert.knowledge.base" />
                            <ClearIcon onClick={this.closeKnowledgeBaseDialog} style={{ float: 'right', cursor: 'pointer' }} />
                            </DialogTitle>
                            <DialogContent style={{ width: "800px" }} >
                            <Grid container spacing={0} style={{ marginTop: 15 }}>
                                <Grid item xs={12}>
                                <SimpleAutoCompleteAsynchronousKnowledgebase
                                    label={intl.formatMessage({ id: "knowledgebase" })}
                                    linkToSearch={this.state.search.knowledgebase}
                                    toSearchProp={true}
                                    nameAndEmail={false}
                                    stateToName="title"
                                    options={this.state.knowledgeBaseList}
                                    stateName="knowledgebase"
                                    changeSelect={this.changeValuesKnowledgebase}
                                    selected={this.state.knowledgebase}
                                    setErrorMessage={this.setErrorMessage}
                                    isNotClientErrorMessage={true} />
                                </Grid>
                            </Grid>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={this.closeKnowledgeBaseDialog} variant="contained" color="secondary" autoFocus >
                                {intl.formatMessage({id:"cancel"})}
                            </Button>
                            <Button variant="contained" onClick={this.insertKnowledgebase} color="primary" >
                                <FormattedMessage id="insert" />
                            </Button>
                            </DialogActions>
                        </Dialog>
                    <Dialog
                        open={this.state.locationModalOpen}
                        onClose={this.closeLocationModal}
                        aria-labelledby="form-dialog-title"
                        maxWidth={705}>
                        <DialogTitle id="form-dialog-title">
                            {intl.formatMessage({id:"called.location"})}
                            <ClearIcon onClick={this.closeLocationModal} style={{ float: 'right', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent>
                            <Card style={{
                                width: 705, minWidth: 705, maxWidth: 705,
                                height: 400, minHeight: 400, maxHeight: 400
                            }}>
                                <CardContent>
                                    <MiniMap
                                        style={{
                                            width: 705, minWidth: 705, maxWidth: 705,
                                            height: 400, minHeight: 400, maxHeight: 400
                                        }}
                                        lat={this.state.positionCalled[0]}
                                        long={this.state.positionCalled[1]}
                                        address={this.state.addressCalled}
                                    />
                                </CardContent>
                            </Card>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.accompanimentLocationModalOpen}
                        onClose={this.closeAccompanimentLocationModal}
                        aria-labelledby="form-dialog-title"
                        maxWidth={705}>
                        <DialogTitle id="form-dialog-title">
                            {intl.formatMessage({id:"called.accompaniment.location"})}
                            <ClearIcon onClick={this.closeAccompanimentLocationModal} style={{ float: 'right', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent>
                            <Card style={{
                                width: 705, minWidth: 705, maxWidth: 705,
                                height: 400, minHeight: 400, maxHeight: 400
                            }}>
                                <CardContent>
                                    <MiniMap
                                        style={{
                                            width: 705, minWidth: 705, maxWidth: 705,
                                            height: 400, minHeight: 400, maxHeight: 400
                                        }}
                                        lat={this.state.positionAccompCalled[0]}
                                        long={this.state.positionAccompCalled[1]}
                                        address={this.state.addressAccompCalled}
                                    />
                                </CardContent>
                            </Card>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        scroll="paper"
                        style={{ overflow: 'hidden', padding: 15 }}
                        open={this.state.slaOverflowContentModalOpen}
                        onClose={this.closeSLAOverflowModal}
                        aria-labelledby="sla-over-dialog-title"
                        aria-describedby="sla-over-dialog-description"
                        PaperProps={{
                            style: {
                              overflow: 'hidden'
                            }
                        }} >
                        <DialogTitle id="sla-over-dialog-title">
                            {intl.formatMessage({id:"called.accompaniment.sla.overflow"})}
                            <ClearIcon onClick={this.closeSLAOverflowModal} style={{ float: 'right', marginLeft: '64px', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent style={{ overflow: 'hidden' }} id="sla-over-dialog-description">
                            <Grid container spacing={4} style={{ marginTop: 5 }}>
                                <Grid item xs={12} sm={12}>
                                    <FormattedMessage id="sla.overflow" />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <SimpleAutoComplete
                                        label={intl.formatMessage({ id: "reason" })}
                                        options={this.state.reasonsList}
                                        stateName='calledReasonId'
                                        changeSelect={this.changeValues}
                                        selected={this.state.calledReasonId} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        autoComplete="fname"
                                        id="outlined-multiline-static"
                                        label={intl.formatMessage({ id: "others" })}
                                        name="reasonOther"
                                        multiline
                                        rows="8"
                                        fullWidth
                                        value={this.state.reasonOther}
                                        variant="outlined"
                                        onChange={this.handleInputChange} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                        <Grid container spacing={4} justify="flex-end" style={{ paddingRight: 15 }}>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant='contained'
                                    type="button"
                                    onClick={this.closeSLAOverflowModal}
                                    fullWidth
                                >
                                    {intl.formatMessage({id:"cancel"})}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    onClick={this.saveCalledReason} >
                                    {<FormattedMessage id="save" />}
                                </Button>
                            </Grid>
                        </Grid>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        scroll="paper"
                        style={{ overflow: 'hidden', padding: 15 }}
                        open={this.state.defaultActionModalOpen}
                        onClose={this.closeDefaultActionDialog}
                        aria-labelledby="defualt-action-dialog-title"
                        aria-describedby="defualt-action-dialog-description"
                        PaperProps={{
                            style: {
                              overflow: 'hidden'
                            }
                        }} >
                        <DialogTitle id="defualt-action-dialog-title">
                            {intl.formatMessage({id:"default.actions"})}
                            <ClearIcon onClick={this.closeDefaultActionDialog} style={{ float: 'right', marginLeft: '64px', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent style={{ overflow: 'hidden' }} id="defualt-action-dialog-description">
                            <Grid container spacing={4} style={{ marginTop: 5 }}>
                                <Grid item xs={12} sm={12}>
                                    <SimpleAutoComplete
                                        label={intl.formatMessage({ id: "action" })}
                                        options={this.state.defaultActionList}
                                        stateName='defaultActionId'
                                        changeSelect={this.changeValues}
                                        selected={this.state.defaultActionId} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                        <Grid container spacing={4} justify="flex-end" style={{ paddingRight: 15 }}>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant='contained'
                                    type="button"
                                    onClick={this.closeDefaultActionDialog}
                                    fullWidth
                                >
                                    {intl.formatMessage({id:"cancel"})}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    onClick={this.saveDefaultAction} >
                                    {intl.formatMessage({id:"add.text"})}
                                </Button>
                            </Grid>
                        </Grid>
                        </DialogActions>
                    </Dialog>
                    <Grid container>
                        <Grid item xs={8} >
                            <h2 style={{ textAlign: 'start' }}>{called.title}</h2>
                        </Grid>

                        <Grid item xs={8} >
                            <p style={{ textAlign: 'start' }}>{<FormattedMessage id="open.call.via" />} {this.props.calleds[`called-${this.urlId}`] && this.props.calleds[`called-${this.urlId}`].originRequestName ? this.props.calleds[`called-${this.urlId}`].originRequestName : ""} {<FormattedMessage id="in" />} {moment(this.state.called.openingDate).format("DD/MM/YYYY HH:mm")} {called.originRequest == 6 && (<IconButton style={{ padding: 0 }} aria-label="LocationIcon"><LocationOnIcon onClick={this.openLocationModal} style={{ fontColor: "#CC1717", fill: "#CC1717" }} /></IconButton>)}</p>
                        </Grid>
                        <Grid item xs={8} >
                            <p style={{ textAlign: 'start' }}>{<FormattedMessage id="security.token" />} {this.state.called?.securitypassword}</p>
                        </Grid>
                        <Grid item xs={8} >
                            <p style={{ textAlign: 'start' }}>Solicitado por: {calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].requestinguser.name ? calleds[`called-${this.urlId}`].requestinguser.name : ""}{this.infoRequest && this.infoRequest.email ? `, ${this.infoRequest.email}` : ''}{this.infoRequest && this.infoRequest.phone ? `, ${this.infoRequest.phone}` : ''}</p>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} >
                            {<FormattedMessage id="sla.service" />}: {getSlaAtendimento((calleds[`called-${this.urlId}`] != null && typeof calleds[`called-${this.urlId}`] !== 'undefined') ? calleds[`called-${this.urlId}`] : this.calledGridData, this.props.userSession)}%
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ marginBottom: 20 }}>
                            <LinearProgress variant="determinate" value={getSlaAtendimento((calleds[`called-${this.urlId}`] != null && typeof calleds[`called-${this.urlId}`] !== 'undefined') ? calleds[`called-${this.urlId}`] : this.calledGridData, this.props.userSession) > 100 ? 100 : getSlaAtendimento((calleds[`called-${this.urlId}`] != null && typeof calleds[`called-${this.urlId}`] !== 'undefined') ? calleds[`called-${this.urlId}`] : this.calledGridData, this.props.userSession)} />
                        </Grid>

                        <Grid item xs={12} sm={12} >
                            {<FormattedMessage id="sla.solution" />}: {getSlaSolucao((calleds[`called-${this.urlId}`] != null && typeof calleds[`called-${this.urlId}`] !== 'undefined') ? calleds[`called-${this.urlId}`] : this.calledGridData, this.props.userSession)}%
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <LinearProgress variant="determinate" value={getSlaSolucao((calleds[`called-${this.urlId}`] != null && typeof calleds[`called-${this.urlId}`] !== 'undefined') ? calleds[`called-${this.urlId}`] : this.calledGridData, this.props.userSession) > 100 ? 100 : getSlaSolucao((calleds[`called-${this.urlId}`] != null && typeof calleds[`called-${this.urlId}`] !== 'undefined') ? calleds[`called-${this.urlId}`] : this.calledGridData, this.props.userSession)} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>

                        <Grid item md={12} sm={12} xs={12}>



                            {(((this.isClient && this.state.requestingUser && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) || (!this.isTechinical && (this.globalAdm || this.AdmOrganization || this.isCoordenator))) && (
                                <Tooltip title={<h5>{intl.formatMessage({id:"called.cancel"})}</h5>}>
                                    <IconButton aria-label="delete-called"
                                        style={{ float: 'right', marginBottom: '30px' }}
                                        onClick={this.closeCalledModal}
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#CC1717',
                                            color: '#FFF'
                                        }}>
                                        <CloseIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {this.globalAdm && this.isTechinical && (
                                <Tooltip title={<h5>{intl.formatMessage({id:"called.cancel"})}</h5>}>
                                    <IconButton aria-label="delete-called"
                                        style={{ float: 'right', marginBottom: '30px' }}
                                        onClick={this.closeCalledModal}
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#CC1717',
                                            color: '#FFF'
                                        }}>
                                        <CloseIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {!this.isClient && (
                                <Tooltip title={<h5><FormattedMessage id="clone.called" /></h5>}>
                                    <IconButton aria-label="clone-called"
                                        style={{ float: 'right', marginBottom: '30px' }}
                                        onClick={this.closeCloneCalledModal}
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }}>
                                        <FileCopyIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}


                            <Dialog open={this.state.cloneCalledModalOpen} onClose={this.closeCloneCalledModal} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">
                                    {intl.formatMessage({ id: "clone.called" })}
                                </DialogTitle>

                                <DialogContent>
                                    <DialogContentText>
                                        {intl.formatMessage({ id: "clone.called.description" })}
                                    </DialogContentText>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                name="cloneCalledAmount"
                                                label={intl.formatMessage({ id: "clone.called.amount" })}
                                                fullWidth
                                                onChange={this.handleInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                            changeSelect={this.changeCloneValues}
                                            stateName='calledorganizationmaterial'
                                            fullWidth label={intl.formatMessage({id:"replaced.materials"})}
                                            autoComplete='fname'
                                            name="calledorganizationmaterial"
                                            selected={this.state.calledorganizationmaterial}
                                            variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                            changeSelect={this.changeCloneValues}
                                            stateName='calledusers'
                                            fullWidth
                                            label={intl.formatMessage({id:"professionals.involved"})}
                                            autoComplete='fname'
                                            name="calledusers"
                                            selected={this.state.calledusers}
                                            variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                            changeSelect={this.changeCloneValues}
                                            stateName='calledorganizationFields'
                                            fullWidth
                                            label={intl.formatMessage({id:"organization.custom.fields"})}
                                            autoComplete='fname'
                                            name="calledorganizationFields"
                                            selected={this.state.calledorganizationFields}
                                            variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                            changeSelect={this.changeCloneValues}
                                            stateName='calledtimeentries'
                                            fullWidth
                                            label={intl.formatMessage({id:"appointment.hours"})}
                                            autoComplete='fname'
                                            name="calledtimeentries"
                                            selected={this.state.calledtimeentries}
                                            variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                            changeSelect={this.changeCloneValues}
                                            stateName='calledadditionalservices'
                                            fullWidth
                                            label='Serviços adicionais'
                                            autoComplete='fname'
                                            name="calledadditionalservices"
                                            selected={this.state.calledadditionalservices}
                                            variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                            changeSelect={this.changeCloneValues}
                                            stateName='initialcalledorganizationchecklists'
                                            fullWidth
                                            label={intl.formatMessage({id:"checklist.previous.to.attendance"})}
                                            autoComplete='fname'
                                            name="initialcalledorganizationchecklists"
                                            selected={this.state.initialcalledorganizationchecklists}
                                            variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                            changeSelect={this.changeCloneValues}
                                            stateName='finalcalledorganizationchecklists'
                                            fullWidth
                                            label={intl.formatMessage({id:"checklist.post.attendance"})}
                                            autoComplete='fname'
                                            name="finalcalledorganizationchecklists"
                                            selected={this.state.finalcalledorganizationchecklists}
                                            variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                            changeSelect={this.changeCloneValues}
                                            stateName='calledorganizationpatrimonies'
                                            fullWidth
                                            label={intl.formatMessage({id:"menu.organizationpatrimonies"})}
                                            autoComplete='fname'
                                            name="calledorganizationpatrimonies"
                                            selected={this.state.calledorganizationpatrimonies}
                                            variant='outlined' />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.closeCloneCalledModal} color="secondary">
                                        {intl.formatMessage({ id: "cancel" })}
                                    </Button>
                                    <Button onClick={this.cloneCalled} color="secondary" type="submit">
                                        {intl.formatMessage({ id: "clone" })}
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {!this.isClient && (
                                <Tooltip title={<h5><FormattedMessage id="pause.called" /></h5>}>
                                    <IconButton aria-label="pause-called"
                                        onClick={this.pauseCalled}
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }}>
                                        <PauseIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {!this.isClient && this.canstartcallcenter && (
                                <Tooltip title={<h5><FormattedMessage id="video.call" /></h5>}>
                                    <IconButton aria-label="video-call"
                                        onClick={this.videoCallClick}
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }}
                                        disabled={this.state.disabledvertex} >
                                        <VideoCallIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {!this.isClient && this.canselectpatrimonyincalled && (
                                <Tooltip title={<h5>{intl.formatMessage({id:"add.patrimony"})}</h5>}>
                                    <IconButton aria-label="add-patrimony"
                                        onClick={this.openPatrimonioModal}
                                        color='secondary'
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }} >
                                        <BusinessIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {!this.isClient && this.caninforminitialchecklist && (
                                <Tooltip title={<h5>{intl.formatMessage({id:"inform.initial.checklist"})}</h5>}>
                                    <IconButton aria-label="inform-initial-checklist"
                                        onClick={this.openInitialChecklistModal}
                                        color='secondary'
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }} >
                                        <CheckBoxIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}


                            {!this.isClient && (
                                <Tooltip title={<h5><FormattedMessage id="save.called" /></h5>}>
                                    <IconButton aria-label="save-called"
                                        onClick={this.saveCalled}
                                        color='primary'
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#3F51B5',
                                            color: '#FFF'
                                        }}
                                        disabled={this.state.disablebutton} >
                                        <SaveIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {((((this.isClient && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) && this.state.called.assignedUser != null && this.state.clientStatus && (this.state.clientStatus.statustypeid == 2 || this.state.clientStatus.statustypeid == 7) && this.canclientfinishcalled) || (!this.isClient && this.state.called.assignedUser != null)) && (
                                <Tooltip title={<h5><FormattedMessage id="done.called" /></h5>}>
                                    <IconButton aria-label="done-called"
                                        onClick={this.finishCalled}
                                        color='primary'
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#3F51B5',
                                            color: '#FFF'
                                        }} >
                                        <DoneAllIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {(((this.isClient && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) && this.state.clientStatus && this.state.clientStatus.statustypeid == 5 && this.canclientreopencalled) && (
                                <Tooltip title={<h5><FormattedMessage id="reopen.called" /></h5>}>
                                    <IconButton aria-label="reopen-called"
                                        onClick={this.openConfirmReopenCalledClientDialog}
                                        color='primary'
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#3F51B5',
                                            color: '#FFF'
                                        }} >
                                        <FileOpenIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            <div>
                                <form name="myForm" className={classes.form} onSubmit={this.handleSubmit}>
                                    <Grid container>
                                        <Grid item xs={4} sm={4} ></Grid>
                                        <Grid item xs={4} sm={4} ></Grid>
                                        <Grid item xs={4} sm={4}>
                                            <Menu
                                                id="video-menu"
                                                anchorEl={this.state.anchorEl}
                                                keepMounted
                                                open={Boolean(this.state.anchorEl)}
                                                onClose={this.handleClose}
                                            >
                                                <MenuItem onClick={this.videoCallClick}><DuoIcon /> <span style={{ marginLeft: '10px' }}>Iniciar Manualmente</span></MenuItem>

                                            </Menu>

                                            {this.state.modalopenFinish && (
                                                <TransitionsModal isopen={this.state.modalopenFinish} handleOpen={this.openModal} handleClose={this.closeModalFinish}>
                                                    <CalledFinish
                                                        called={this.props.calleds[`called-${this.urlId}`]}
                                                        calledData={this.state.called}
                                                        actionDescription={this.state.action.description}
                                                        actionFinishId={this.state.called.finishaccompanimentid}
                                                        files={this.state.files}
                                                        addAccompanimentsList={this.props.addAccompanimentsList}
                                                        user={this.props.userSession}
                                                        slaAttend={getSlaAtendimento(this.props.calleds[`called-${this.urlId}`], this.props.userSession)}
                                                        slaSolution={getSlaSolucao(this.props.calleds[`called-${this.urlId}`], this.props.userSession)}
                                                        handleClose={this.closeModalFinish}
                                                        calledFinishOk={this.calledFinishOk}
                                                        closeModalCalledFinish={this.closeModalCalledFinish}
                                                        setFinishAccompanimentId={this.setFinishAccompanimentId}
                                                        setActionDescription={this.setActionDescription}
                                                        removeItem={this.removeItem}
                                                        addFileItem={this.addFileItem}
                                                        defaultActionList={this.state.defaultActionList}
                                                        caninformutilizedmaterialsincalledfinish={this.caninformutilizedmaterialsincalledfinish}
                                                    />
                                                </TransitionsModal>
                                            )}

                                        </Grid>

                                        {/*(this.globalAdm ||this.isCoordenator || this.AdmOrganization) && (<Grid item xs={12} sm={12} style={{ marginBottom: 20, marginTop: 70 }}>
                                            <fieldset style={{
                                                marginTop: 25,
                                                marginBottom: 25,
                                                border: '1px solid #c0c0c0',
                                                padding: '0 0 15px 0',
                                                borderLeft: 'none',
                                                borderRight: 'none'
                                            }} >
                                                <legend style={{
                                                    paddingTop: 15,
                                                    fontSize: '1.3rem',
                                                    border: '1px solid #c0c0c0',
                                                    borderBottom: 'none',
                                                    borderLeft: 'none',Por
                                                    borderRight: 'none'
                                                }} >Relatório Gerencial</legend>
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }} >
                                                    {this.state.costBody.attendanceHours}
                                                    {<Card>
                                                        <CardHeader
                                                          title="Total de {intl.formatMessage({id:"remote.attendance"})}"
                                                          subheader="HH:mm" />
                                                        <CardContent>

                                                        </CardContent>
                                                    </Card>
                                                    <Card>
                                                        <CardHeader
                                                          title="Custo Total de atendimento" />
                                                        <CardContent>

                                                        </CardContent>
                                                    </Card>
                                                    <Card>
                                                        <CardHeader
                                                          title="Informações adicionais" />
                                                        <CardContent>

                                                        </CardContent>
                                                    </Card>}
                                                </div>
                                            </fieldset>
                                        </Grid>)*/}
                                        <Grid item xs={12} sm={12} md={4} lg={4} style={!this.isClient ? { marginBottom: 20, marginTop: 70 } : { display: 'none' } }>
                                            <SimpleSelectWithColor label={intl.formatMessage({ id: "priority" })} options={this.state.priorityList} stateName="priorityid" changeSelect={this.changeValues} selected={this.state.called.priorityid} required disabled={ this.state.priorityDisabled } />
                                        </Grid>
                                        { this.state.canselectorganizationcategoriesbylevels === false ?
                                        (<Grid item xs={12} sm={12} md={12} lg={12} style={!this.isClient ? { marginBottom: 20 } : { marginBottom: 20, marginTop: 70 } }>
                                            <SimpleAutoComplete
                                            label={intl.formatMessage({ id: "category" })}
                                            options={this.state.categoriesList}
                                            stateName='OrganizationCategoryId'
                                            changeSelect={this.changeValues}
                                            selected={called.OrganizationCategoryId}
                                            disabled={(this.isClient && !called.organizationsettings.roleclientcanselectcategory) ? true : (this.isClient && this.state.requestingUser.id != this.props.userSession.user.id) ? true : false}
                                            required />
                                        </Grid>)
                                        :
                                        (
                                        <Grid item xs={12} sm={12} md={12} lg={12} style={!this.isClient ? { marginBottom: 20 } : { marginBottom: 20, marginTop: 70 } }>
                                            <fieldset style={{ padding: '10px 10px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                                                <legend style={{ fontSize: '0.8rem', marginBottom: '0!important' }}><FormattedMessage id="category" />*</legend>
                                                <Grid container spacing={4} id="categoriesByLevels" className="categoriesByLevels">
                                                {this.createUi()}
                                                </Grid>
                                            </fieldset>
                                        </Grid>
                                        ) }
                                        {/*<Grid item xs={12} sm={12} style={!this.isClient ? { marginBottom: 20 } : { marginBottom: 20, marginTop: 70 } }>
                                            <SimpleAutoCompleteAsynchronousOrganizationCategory
                                                label={intl.formatMessage({ id: "category" })}
                                                linkToSearch={this.state.search.categoriesList}
                                                toSearchProp={true}
                                                options={this.state.categoriesList}
                                                stateName="OrganizationCategoryId"
                                                changeSelect={this.changeValues}
                                                selected={called.OrganizationCategoryId}
                                                disabled={(this.isClient && !called.organizationsettings.roleclientcanselectcategory) ? true : false}
                                                setErrorMessage={this.setErrorMessage}
                                            />
                                        </Grid>*/}
                                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 20 }}>
                                            <SimpleAutoComplete label={intl.formatMessage({ id: "departament" })} options={this.state.DepartamentList} stateName='departamentId' changeSelect={this.changeValues} selected={this.state.departamentId}
                                                disabled={this.isClient ? true : false}
                                            />
                                        </Grid>
                                        {(this.canclientselecttypeincalled && ((this.isClient && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true)) && <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 20 }}>
                                            <SimpleSelect label={intl.formatMessage({ id: "type" })} options={this.state.typesList} stateName="type" changeSelect={this.changeValues} selected={called.type} disabled={true} />
                                        </Grid>}
                                        {this.isClient && <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                                        <Typography>
                                            <FormattedMessage id="description" />
                                        </Typography>
                                        <ReactQuill name="description" value={called.description || ''} style={{ minHeight: 200 }}
                                            fullWidth
                                            modules={this.reactQuillModules}
                                            formats={this.reactQuillFormats}
                                            readOnly={true}
                                            required
                                            InputLabelProps={{ shrink: called.description && called.description.length > 0 ? true : false }}
                                            variant="outlined"
                                            id="outlined-multiline-static"
                                            onChange={this.handleChangeEditor} />
                                        </Grid>}
                                        {(this.isClient && this.state.called.calledattachments && this.state.called.calledattachments.length > 0) && (
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <div className={classes.attachmentsBox}>
                                                    <label>{<FormattedMessage id="attachments" />}</label>
                                                    <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                                        <ul>
                                                            {this.state.called.calledattachments.map(file => {
                                                                return (<li key={file.attachment.path}>
                                                                    <AttachFile /> <Button onClick={() => this.getCalledFile(file.attachment.path, file.attachment.filerepositorymethod)}>{file.attachment.description}</Button>
                                                                </li>)
                                                            })}
                                                        </ul>
                                                    </ImageList>
                                                </div>
                                            </Grid>
                                        )}
                                    
                                    { !this.isClient && (<Grid container spacing={4} style={ called.hasPatrimoniesFalse && called.hasPatrimoniesFalse ? { marginBottom: 20 } : {}}>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} >
                                                    <Typography component='h1' variant='h5'>
                                                        {intl.formatMessage({id:"parent.calleds"})}?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <SimpleCheckBox changeSelect={this.changeValues} stateName='hasParentCalledTrue' fullWidth label={this.props.intl.formatMessage({id:"yes"})} autoComplete='fname' name="hasParentCalledTrue" selected={this.state.called.hasParentCalledTrue} variant='outlined' />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <SimpleCheckBox changeSelect={this.changeValues} stateName='hasParentCalledFalse' fullWidth label={this.props.intl.formatMessage({id:"no"})} autoComplete='fname' name="hasParentCalledFalse" selected={this.state.called.hasParentCalledFalse} variant='outlined' />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        { this.canselectpatrimonyincalled && (<Grid item xs={12} sm={6}>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} >
                                                    <Typography component='h1' variant='h5'>
                                                        {intl.formatMessage({id:"inform.patrimonies"})}?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <SimpleCheckBox changeSelect={this.changeValues} stateName='hasPatrimoniesTrue' fullWidth label={this.props.intl.formatMessage({id:"yes"})} autoComplete='fname' name="hasPatrimoniesTrue" selected={this.state.called.hasPatrimoniesTrue} variant='outlined' />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <SimpleCheckBox changeSelect={this.changeValues} stateName='hasPatrimoniesFalse' fullWidth label={this.props.intl.formatMessage({id:"no"})} autoComplete='fname' name="hasPatrimoniesFalse" selected={this.state.called.hasPatrimoniesFalse} variant='outlined' />
                                                </Grid>
                                            </Grid>
                                        </Grid>)}
                                    </Grid>)}
                                    {called.hasParentCalledTrue && (
                                        <Grid item xs={12} sm={12} style={{ marginBottom: 20 }}>
                                            <SimpleAutoComplete label={intl.formatMessage({ id: "parent.called" })} options={this.state.parentCalledsList} stateName='ParentCalledId' changeSelect={this.changeValues} selected={called.ParentCalledId}
                                                disabled={(this.isClient) ? true : false}
                                            />
                                        </Grid>
                                    )}

                                    {(this.canselectpatrimonyincalled && called.hasPatrimoniesTrue) && (
                                            <Grid item xs={12} sm={12} style={{ marginBottom: 20 }}>
                                                <SimpleAutoCompleteMultiple disabled={this.isClient ? true : false} label="Patrimônios Associados ao Chamado:" options={this.state.patrimonyList} stateName='patrimonyId' changeSelect={this.changeValues} selected={called.patrimonyId} />
                                            </Grid>
                                    )}
                                    </Grid>
                                </form>
                            </div>

                        </Grid>

                    </Grid>
                </Fragment>
            );
        };

        return (

            <Container style={{ position: 'relative' }} component="main" maxWidth="md">

            {/*((this.globalAdm || this.AdmOrganization || this.isCoordenator) && (this.state.called.priority && this.state.called.priority.colorhex && this.state.called.priority.name) ) && (
                <div className="priority-div" >
                    <Card className={classes.card}>
                        <CardContent style={{ backgroundColor: `${this.state.called.priority.colorhex}`, color: '#fff', fontSize: '1.5em', padding: 15 }}>
                            Prioridade: {this.state.called.priority.name}
                        </CardContent>
                    </Card>
                </div>
            )*/}

                {(this.globalAdm || this.AdmOrganization || this.isCoordenator) && (
                    <div className="cardbudget-div" >
                        <Budget
                            className="cardbudget"
                            style={{ marginBottom: 10 }}
                            total={this.state.calledValue}
                            comparation={0}
                            positiveMonth={0}
                            customTitle="CUSTO TOTAL DO CHAMADO"
                            hideComparation={true}
                            icon={<AttachMoneyIcon className={classes2.icon} />}
                            detailsLink
                            detailsLinkFunction={this.valueTimeAttendance} />
                        <Budget
                            className="cardbudget cardbudget-time"
                            total={this.state.calledTime}
                            comparation={0}
                            positiveMonth={0}
                            customTitle="TEMPO DE ATENDIMENTO"
                            hideComparation={true}
                            icon={<WatchLaterIcon className={classes2.icon} />}
                            colorPrimary={true}
                            detailsLink
                            detailsLinkFunction={this.valueCostAttendance} />
                    </div>
                )}

            <Dialog
              open={this.state.openDialogNewDisk}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{intl.formatMessage({id:"dialog.title.warning.max.files.reached"})}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {intl.formatMessage({id:"warning.max.files.reached.first.half"})} {this.state.valueNewDisk} {intl.formatMessage({id:"warning.max.files.reached.second.half"})} 
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.openDialogNewDisk} variant="contained" color="secondary" autoFocus >
                  {this.props.intl.formatMessage({id:"cancel"})}
                </Button>
                <Button variant="contained" onClick={this.handleSubmitDiskAccept} color="primary" >
                  Aceitar
                </Button>
              </DialogActions>
            </Dialog>

                <div>
                    {<div>{modalP}</div>}
                    {<div>{modalVideo}</div>}
                    {modalCheck}
                    <form name="myForm" className={classes.form} onSubmit={this.handleSubmit}>

                        {!this.isClient && (<div>
                            <Grid container style={{ fontSize: 16 }}>
                                <Grid item xs={12} sm={5}>
                                </Grid>
                                <Grid item xs={12} sm={1} ></Grid>
                                <Grid item xs={12} sm={12}>
                                    {headerContent()}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    {calledBody()}
                                </Grid>
                                <Grid item xs={12} sm={1} />

                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {actionBody()}
                            </Grid>
                        </div>)}
                        {this.isClient && (<div>

                            <Grid container style={{ fontSize: 16 }}>
                                {headerContent()}
                            </Grid>
                            <Grid container>
                                {actionBody()}
                            </Grid>

                        </div>)}


                    </form>
                </div>

                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                    isOpen={this.state.openNotification}
                    toClose={this.closeNotification}
                />

                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color="secondary" />
                    </Overlay>
                )}

                {this.state.loadingTwo && (
                    <Overlay>
                        <CircularProgress color="secondary" />
                    </Overlay>
                )}

            </Container>
        );
    }
}

CalledRegistration.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    calleds: state.calleds,
    reload: state.reload
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, calledsActions, headerMenuActions), dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledRegistration))));
