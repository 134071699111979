import React, { Component } from 'react';
                import clsx from 'clsx';
                import { connect } from 'react-redux';
                import { bindActionCreators } from 'redux';
                import PropTypes from 'prop-types';
                import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import { Overlay } from '../../styles/global';
                import CustomizedSnackbars from '../../components/material-snackbars';
                import HeaderMenu from '../../components/header-menu';
                import AddIcon from '@mui/icons-material/Add';
                import { Link } from 'react-router-dom';
                import Api from '../../services/api';
                import MyMaterialTable from '../../components/table';
                import { Can } from '../../config/ability-context'
                import { AbilityContext } from '../../config/ability-context'
                import Card from '@mui/material/Card';
                import CardContent from '@mui/material/CardContent';
                import Typography from '@mui/material/Typography';
                import Pagination from '@mui/material/Pagination';  
                import Search from '@mui/icons-material/Search';
                import TextField from '@mui/material/TextField';
                import SimpleSelect from '../../components/select/simple-search';
                import FirstElement from "../../components/first-element";
                import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
                import AddBoxIcon from '@mui/icons-material/AddBox';
                
                import { FormattedMessage,injectIntl } from 'react-intl';
                import Footer from '../../components/footer';
                import ClearIcon from '@mui/icons-material/Clear';
                import { withRouter } from 'react-router-dom';
                import SimpleAutoComplete from '../../components/auto-complete/autocomplete';

                    import * as componentActions from '../../redux/actions/organizationpatrimonies';
                    class OrganizationPatrimoniesContent extends Component {

                    constructor(props) {
                        super(props);

                        let fromProps = ""
                        if(this.props && this.props.from && this.props.from.length > 0 && this.props.from == 'orgEdit')
                            fromProps = this.props.from

                        this.typingTimeout = null;
                        this.emptyArray = null;

                        const intl = this.props.intl;

                        let filters = [
                            {name:intl.formatMessage({id:"name"}),id:"name"},
                            {name:intl.formatMessage({id:"menu.patrimonygroups"}),id:"group"},
                            {name:intl.formatMessage({id:"menu.patrimonysubgroups"}),id:"subgroup"},
                            {name:intl.formatMessage({id:"menu.patrimonymaterials"}),id:"material"},
                            {name:intl.formatMessage({id:"value"}),id:"value"},
                            {name:intl.formatMessage({id:"tipping"}),id:"tomb"},
                            {name:intl.formatMessage({id:"departament"}),id:"dep"},
                            {name:intl.formatMessage({id:"serial.number"}),id:"serial"}
                            ];

                        const { userSession, organizations } = this.props;
                        this.state = {
                            organizations: organizations,
                            loading: false,
                            openNotification: false,
                            notificationVariant: 'error',
                            notificationMessage: '',
                            resultList: [],
                            dataFilter: false,
                            organizationList: [],
                            totalPages:0,
                            page:1,
                            pageStart:0,
                            pageEnd:0,
                            count:0,
                            searchValue: '',
                            filterSelected: 0,
                            filters:filters,
                            from: fromProps,
                            OrganizationFilter: 0,
                            OrganizationFilterItem: null
                        };

                        this.callSearch = this.callSearch.bind(this);
                        this.onFieldChange = this.onFieldChange.bind(this);
                        this.changeValues = this.changeValues.bind(this);

                        this.closeNotification = this.closeNotification.bind(this);
                        this.deleteItem = this.deleteItem.bind(this);
                        this.clearFields = this.clearFields.bind(this);

                    }

                    onChangePaginate = (e,page) =>{
                        this.setState({ page:page });
                        localStorage.setItem('organizationPatrimoniesTablePage', `${page}`)
                        this.getItens(page);
                    }

                    mtel(num){
                        num=num.toString().replace(/\D/g,"");
                        //
                        if(num.toString().length > 5 && num.toString().length < 7){
                          num = num.replace(/(\d{1}?)((\d{1})+)$/, "$1.$2");
                          num = num.replace(/(\d{3})(?=\d)/g, "$1,");
                        }else if (num.toString().length >= 7) {
                          num=num.replace(/(\d{1,2})$/, ',$1');  
                          num=num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');  
                          num=num.replace(/^0+/, '');
                    
                        }else if (num.toString().length == 4){
                            num = num.replace(/(\d{2})(?=\d)/g, "$1.");
                        }else{
                            num = num.replace(/(\d{3})(?=\d)/g, "$1,");
                        }
                        return num;
                    }

                    setPagination(count,page){
                                            
                        let totalPage = Math.ceil (count/10);
                        this.setState({ totalPages:totalPage })
                        if(count > 0){
                            if(page == 1){
                                if(count < 10){
                                    this.setState({pageStart:1,pageEnd:count});
                                } else{
                                    this.setState({pageStart:1,pageEnd:10});
                                }
                            } else{
                                let pageStart = ((page - 1) * 10) + 1;
                                
                                
                                
                                if(count >= page * 10){
                                    this.setState({pageStart:pageStart,pageEnd:page * 10});
                                } else{
                                    this.setState({pageStart:pageStart,pageEnd:count});
                                }
                            }
                        } else {
                            this.setState({totalPages:0,page:1,count:0});
                        }
                                            
                    }
                
                    onFieldChange(event) {
    
        
                        this.setState({ [event.target.name]: event.target.value, page:1 });

                    }

                    callSearch(){
                        if(this.state.filterSelected != 0){
                            localStorage.setItem('organizationPatrimoniesTableFilter', `${this.state.filterSelected}=${this.state.searchValue}`)
                            this.getItens(this.state.page);
                        }                        
                    }

                    async clearFields(){
                        await   this.setState({ OrganizationFilter: 0, OrganizationFilterItem: null, filterSelected: 0, searchValue: '', page:1, loading: true})
                        localStorage.setItem('organizationPatrimoniesTableFilter', '')
                        localStorage.setItem('organizationPatrimoniesTableOrganizationFilter', '0')
                        localStorage.setItem('organizationPatrimoniesTablePage', '1')
                        this.getItens(1);
                    }

                    async changeValues(stateName, value, text = '') {
                        if(stateName === 'OrganizationFilter') {
                            this.setState({ [stateName]: value.id })
                            this.getItens()
                        }else
                            this.setState({filterSelected : value})
                    }


                    deleteItem(data, resolve) {
                        const intl = this.props.intl;
                        let abl = this.context.can('Delete', 'OrganizationPatrimony');
                        if (abl == false){
                            this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"})});
                            resolve(); return false;
                        }

                    Api.delete(`/organizationpatrimonies/${data.id}`)
    
                            .then(result => {
                                const intl = this.props.intl;
                                if (result.data.success) {
                                    this.setState({
                                        loading: false,
                                        openNotification: true,
                                        notificationVariant: 'success',
                                        notificationMessage: this.props.intl.formatMessage({id:"called.list.notification.delete.success"}),
                                        resultList: []
                                      });
          
                                    this.getItens();
                                }
                                else{
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                                resolve();
                            })
                            .catch(err => {
                                const intl = this.props.intl;
                                resolve();
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
                            });
    

                    };
    
                    componentWillReceiveProps(nextProps){
                           if(nextProps.location && nextProps.location.state === 'reload'){
                              this.componentDidMount();
                          }

                    }

                    getOrganizations = async _ => { 
                        this.setState({ loading: true })
                        let data = [];
                        const { userSession } = this.props
                        if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
                            data = userSession.user.userorganizations
                        } else {
                            await Api.get('/organizations/tech')
                                .then(result => {
                                    data = result.data.data
                                })
                                .catch(err => {
                                    data = err;
                                })
                        }
                        this.setState({ organizationList: data, loading: false })
                    }

                    async componentDidMount() {
                        await this.getOrganizations()
                        let sessionFilter = localStorage.getItem('organizationPatrimoniesTableFilter')
                        if(sessionFilter == null) {
                            sessionFilter = [0, '']
                            localStorage.setItem('organizationPatrimoniesTableFilter', '')
                        } else {
                            if(sessionFilter && sessionFilter.length > 0) {
                                let sessionFilterSplit = sessionFilter.split("=")
                                if(sessionFilterSplit && sessionFilterSplit.length > 1) {
                                    if(!isNaN(sessionFilterSplit[0]))
                                        sessionFilter = [parseInt(sessionFilterSplit[0]), sessionFilterSplit[1]]
                                    else
                                        sessionFilter = [sessionFilterSplit[0], sessionFilterSplit[1]]
                                }
                            }
                        }
                        if(isNaN(sessionFilter[0])) {
                            let sessionFilterItem = this.state.filters.filter(item => item.id == sessionFilter[0])
                            if(sessionFilterItem && sessionFilterItem.length > 0) {
                                await this.setState({
                                    filterSelected: sessionFilterItem[0]
                                }, () => this.setState({ filterSelected: sessionFilter[0], searchValue: sessionFilter[1] }))
                            }
                        } else {
                            await this.setState({ filterSelected: sessionFilter[0], searchValue: sessionFilter[1] })
                        }

                        let sessionOrgFilter = localStorage.getItem('organizationPatrimoniesTableOrganizationFilter')
                        if(sessionOrgFilter == null) {
                            sessionOrgFilter = 0
                            localStorage.setItem('organizationPatrimoniesTableOrganizationFilter', '0')
                        }
                        let orgFilterItem = null
                        if(!isNaN(sessionOrgFilter) && typeof sessionOrgFilter === 'string') {
                            sessionOrgFilter = parseInt(sessionOrgFilter)
                            if(sessionOrgFilter > 0 && this.state.organizationList != null) {
                                orgFilterItem = this.state.organizationList.filter(item => item.id == sessionOrgFilter)
                                if(orgFilterItem && orgFilterItem.length > 0) {
                                    await this.setState({
                                        OrganizationFilterItem: orgFilterItem[0]
                                    })
                                }
                            }
                        }
                        await this.setState({ OrganizationFilter: sessionOrgFilter })

                        let sessionPage = localStorage.getItem('organizationPatrimoniesTablePage')
                        if(sessionPage == null) {
                            sessionPage = 1
                            localStorage.setItem('organizationPatrimoniesTablePage', '1')
                        }
                        if(!isNaN(sessionPage) && typeof sessionPage === 'string')
                            sessionPage = parseInt(sessionPage)
                        this.setState({ page: sessionPage })
                        this.getItens(sessionPage);
                    }

                    getItens(page = 1) {
                        this.setState({ loading: true });

                        let url = ''

                        if (this.props.location && this.props.location.pathname === `/${this.props.match.params.lang}/organizationpatrimonies`) {
                            url = `/organizationpatrimonies`;
                            url = `/organizationpatrimonies/paginate?page=${page}&org=${this.state.OrganizationFilter && this.state.OrganizationFilter > 0 ? this.state.OrganizationFilter : 0}`;

                            if (this.state.searchValue != '' && this.state.filterSelected != 0) {
                                url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`
                                this.setState({ dataFilter: true });
                            }
                        } else {
                            url = `/organizationpatrimonies/organization/${this.state.organizations.organization.id}`;
                            url = `/organizationpatrimonies/organization/paginate?page=${page}&id=${this.state.organizations.organization.id}`;
                                            
                            if (this.state.searchValue != '' && this.state.filterSelected != 0) {
                                url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`
                                this.setState({ dataFilter: true });
                            }
                        }

                                            Api.get(url)
                            
                                    .then(result => {
                                        if (result.data.success) {

                                            let count = result.data.count;
                                            this.setState({count:count});
                                            this.setPagination(count,page);
                                            if(result.data.data.length === 0)
                                            {
                                                this.emptyArray = true 
                                            }
                                            else
                                            {
                                                this.emptyArray = false
                                            }

                                            const formattedList = result.data.data.map(item => {
                                                return {
                                                    ...item,
                                                     DepartamentId: item.departament ? item.departament.id : 0,
                                                     DepartamentText: item.departament ? item.departament.name : '',
                                                     PatrimonySubgroupId: item.patrimonysubgroup ? item.patrimonysubgroup.id : 0,
                                                     PatrimonySubgroupText: item.patrimonysubgroup ? item.patrimonysubgroup.name : '',
                                                     OrganizationId: item.organization ? item.organization.id : 0,
                                                     OrganizationText: item.organization ? item.organization.name : '',
                                                     PatrimonyGroupId: item.patrimonygroup ? item.patrimonygroup.id : 0,
                                                     PatrimonyGroupText: item.patrimonygroup ? item.patrimonygroup.name : '',
                                                     PatrimonyMaterialId: item.patrimonymaterial ? item.patrimonymaterial.id : 0,
                                                     PatrimonyMaterialText: item.patrimonymaterial ? item.patrimonymaterial.name : '',

                                          }
                                    });
                                    this.setState({ loading: false, resultList: formattedList });
                                    // console.log(formattedList);
                                }
                                else{
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                            })
                            .catch(err => {
                                const intl = this.props.intl;
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
            
                            });
                    };


                    closeNotification() {
                        this.setState({ openNotification: false });
                    }
                     render() {
                        const { classes, headerMenu } = this.props;
                        const intl = this.props.intl;

                        if(Api == null) {
                            var l = this.props.match.params.lang;
                            document.location.href=`/${l}/unavailable`
                          }

                        return (
                                    <Container component='main' maxWidth='xl' style={{paddingLeft:0, marginLeft:0}}>
                                    {(!this.emptyArray || this.state.dataFilter) ? (
                                        <div>
                                            <CssBaseline />
                                            <Grid container className={classes.mb20}  spacing={0}>
                                                    <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        size='large'
                                                        className={classes.button}
                                                        startIcon={<AddIcon />}
                                                        disabled={!this.context.can('Add', 'OrganizationPatrimony')}
                                                        component={Link} {...{ to: {
                                                            pathname: `/${this.props.match.params.lang}/organizationpatrimony-registration`,
                                                            state: {
                                                                internal: this.props.internal,
                                                                from: this.state.from
                                                        } }}} >
                                                        {<FormattedMessage id="add.item" />}
                                                    </Button>
                                            </Grid>
                                            <Grid container spacing={6}> 
                                            <Grid item xs={12} sm={12}>

                                            <Card className="background-title-search">
                                                <CardContent>
                                                    
                                                    <Grid container spacing={1} alignItems="flex-end">
                                                            <Grid item xs={12} md={4}>
                                                            <Typography variant="h5">
                                                            {<FormattedMessage id="menu.organizationpatrimonies.name" />}
                                                            </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                {(this.props.location && this.props.location.pathname === `/${this.props.match.params.lang}/organizationpatrimonies`) && (<SimpleAutoComplete label={intl.formatMessage({id:"organization"})} options={this.state.organizationList} stateName='OrganizationFilter'
                                                                    selected={this.state.OrganizationFilterItem}
                                                                    changeSelect={(stateName, value, text = '') => {
                                                                    this.setState({
                                                                        OrganizationFilter: value.id,
                                                                        OrganizationFilterItem: value
                                                                    }, () => {
                                                                        if(value && value.id && value.id > 0)
                                                                            localStorage.setItem('organizationPatrimoniesTableOrganizationFilter', `${value.id}`)
                                                                        else
                                                                            localStorage.setItem('organizationPatrimoniesTableOrganizationFilter', '0')
                                                                        this.getItens()
                                                                    })
                                                                    }}/>)}
                                                                    </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <SimpleSelect label={intl.formatMessage({id:"field"})} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected}/>
                                                                    </Grid>
                                                                <Grid item >
                                                                    <TextField type="text"
                                                                    name="searchValue"
                                                                    value={this.state.searchValue}
                                                                    placeholder={intl.formatMessage({id:"search"})}
                                                                    onChange={this.onFieldChange}/>

                                                    <Button
                                                            variant='contained'
                                                            color='secondary'
                                                            size='large'
                                                            className={classes.button}
                                                            style={{marginRight:4,marginLeft:8}}
                                                            onClick={this.callSearch}
                                                        >
                                                            <Search />
                                                    </Button>

                                                    <Button
                                                            variant='contained'
                                                            color='primary'
                                                            size='large'
                                                            onClick={this.clearFields}
                                                            className={classes.button}
                                                
                                                        >
                                                            <ClearIcon />
                                                    </Button>
                                                                </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>

                                                </Grid>
                                                                
                                            </Grid>
                                            <Can I='List' a='OrganizationPatrimony'>      
                                            <MyMaterialTable
                                                            title=""
                                                                    rowClick={(event, rowData) => {
                                                                    let abl = this.context.can('Edit', 'OrganizationPatrimony');
                                                                        if (abl == false)
                                                                        {
                                                                            this.setState({ loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"})});
                                                                            return false;
                                                                        }
                                                                        this.props.addCurrentOrganization(rowData, this.props.organizations.organization);
                                                                        this.props.history.push({
                                                                            pathname: `/${this.props.match.params.lang}/organizationpatrimony-edit`,
                                                                            state: {
                                                                                internal: this.props.internal,
                                                                                from: this.state.from
                                                                            }
                                                                        });
                                                                }}
                                                columns={[
                                                        { title: intl.formatMessage({id:"name"}), field: 'name' },
                                                        { title: intl.formatMessage({id:"menu.organization"}), field: 'OrganizationText' },
                                                        { title: intl.formatMessage({id:"departament"}), field: 'DepartamentText' },
                                                        { title: intl.formatMessage({id:"menu.patrimonygroups"}), field: 'PatrimonyGroupText' },
                                                        { title: intl.formatMessage({id:"menu.patrimonysubgroups"}), field: 'PatrimonySubgroupText' },
                                                        { title: intl.formatMessage({id:"menu.patrimonymaterials"}), field: 'PatrimonyMaterialText' },
                                                        { title: intl.formatMessage({id:"tipping"}), field: 'tipping' },
                                                        { title: intl.formatMessage({id:"value"}), field: 'value', render: rowData => this.mtel(rowData.value) },
                                                        { title: intl.formatMessage({id:"serial.number"}), field: 'serialnumber' },
                                                ]}
                                                data={this.state.resultList}
                                                deleteItem={this.deleteItem} 
                                            />
                                            </Can>

                                            <Can I='List' a='OrganizationPatrimony'>
                                                
                                                <Grid container style={{marginBottom:12,marginTop:8}}>
                                                    <Grid item xs={12} md={3}>
                                                    <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} style={{marginTop:6}}>
                                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                                    </Grid>
                                                </Grid>

                                                </Can> 
                                            {this.state.loading && (
                                                <Overlay>
                                                    <CircularProgress color='secondary' />
                                                </Overlay>
                                            )}
                        

                                            <CustomizedSnackbars
                                                variant={this.state.notificationVariant}
                                                message={this.state.notificationMessage}
                                                isOpen={this.state.openNotification}
                                                toClose={this.closeNotification}
                                            />
                                        </div>)
                                        :(
                                            <div>
                                               <FirstElement
                                               title = {this.props.intl.formatMessage({id:"organization.checklist.list.create.patrimony"})}
                                               subtitle = {this.props.intl.formatMessage({id:"organization.checklist.list.create.patrimony.subtitle"})}
                                               //buttonText = "Novo Chamado"
                                               buttonText = 
                                               {
                                                <Button
                                                variant='contained'
                                                color='secondary'
                                                size='large'
                                                className={classes.button}
                                                startIcon={<AddIcon />}
                                                component={Link} {...{ to: {
                                                    pathname: `/${this.props.match.params.lang}/organizationpatrimony-registration`,
                                                    state: {
                                                        internal: this.props.internal,
                                                        from: this.state.from
                                                }}}}
                                            >
                                                {<FormattedMessage id="add.item" />}
                                                </Button>
                                               }
                                            
                                               icon = {<AccountBalanceIcon style={{fontSize: "45px"}} />}
                                               
                                               />
                                            </div>
                                    )}
                                        
                                </Container>
                        );
                    }
                }
OrganizationPatrimoniesContent.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    organizations: state.organizations
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);
            export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationPatrimoniesContent))));
            OrganizationPatrimoniesContent.contextType = AbilityContext;
