import Api from './api';
export const getCategories = async () => { 
  let data = [];
  await Api.get('/categories')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
  
export const getAllCategoriesWithParent = async () => {
  let data = [];
  await Api.get("/categories/parent")
      .then(result => {
          data = result.data
      })
      .catch(err => {
          data = err;
      });
  return data;
};

export const getByOrganizationWithoutIncludes = async (orgId) => {
  let data = []
  await Api.get(`/organizationsettings/organization/${orgId}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
  });
  return data
}

export const getOrganizations = async (id = 0) => { 
  let data = [];
  if(id != 0 && id != null && id != NaN){
    await Api.get(`/organizationsettings/organizations/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
  }else{
    await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
  }
  
  return data;
};

export const getSlas = async () => { 
  let data = [];
  await Api.get('/slas')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getTypes = async () => { 
  let data = [];
  await Api.get('/types')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOriginRequests = async () => { 
  let data = [];
  await Api.get('/originrequests')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getStatuses = async () => { 
  let data = [];
  await Api.get('/statuses')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

