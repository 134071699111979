import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from "prop-types"
import { withStyles } from "@mui/styles"
import Api from '../../../services/api'
import * as calledsActions from '../../../redux/actions/calleds'
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete'
import { Styles } from "../../../styles/material-styles"
import { Overlay } from '../../../styles/global'
import CustomizedSnackbars from '../../../components/material-snackbars'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Tab as RTab, Tabs as RTabs, TabList as RTabList, TabPanel as RTabPanel } from 'react-tabs'
import { getAccompanimentsToFinish } from '../../../services/called.service'
import Dropzone from 'react-dropzone';
import { DeleteForever } from '@mui/icons-material';
import moment from 'moment';
import SimpleCheckBox from '../../../components/checkbox/check';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import MyMaterialTable from '../../../components/table';
import AddIcon from '@mui/icons-material/Add';
import { AbilityContext } from '../../../config/ability-context'

const calledOrganizationMaterialStep = props => {

  const intl = props.intl
  const classes = props.classes
  const called = props.called

  const previousStep = _ => {
    if (props.CalledOrganizationChecklistList && props.CalledOrganizationChecklistList.length > 0 && !props.isClient)
      props.setNavValue("check")
    else if(props.StatusPrevious)
      props.setNavValue("status")
    else
      props.setNavValue("action")
    props.previousStep()
  }

  return (
    <form name='CalledOrganizationMaterialFrm' onSubmit={props.handleSubmit} encType='multipart/form-data' className={classes.form} >
      <div className="container main-window">
        <Grid container spacing={2} style={{ marginBottom: 15 }}>
          <Grid container>
            <Grid xs={12} sm={12} style={{ marginLeft: 5, marginTop: 20, padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
              <Grid xs={12} sm={3} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                <Typography component='h1' variant='h5'>{intl.formatMessage({id:"materials.used"})}</Typography>
              </Grid>
              <Grid xs={12} sm={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className={classes.button}
                  startIcon={<DeleteIcon />}
                  disabled={props.disabled}
                  onClick={e => props.openCalledOrganizationMaterialDeleteModal()} >
                  {intl.formatMessage({id:"called.organization.material.delete"})}
                </Button>
              </Grid>
              <Grid xs={12} sm={1} ></Grid>
              <Grid xs={12} sm={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  startIcon={<AddIcon />}
                  disabled={props.disabled}
                  onClick={e => props.openCalledOrganizationMaterialInsertModal()} >
                  {intl.formatMessage({id:"called.organization.material.new"})}
                </Button>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12}
              style={{
                marginLeft: 5,
                marginBottom: 5,
                border: '3px solid #eee'
              }} >
              <div style={{ height: 200 }}>
                <DataGrid
                  style={{ fontSize: 14, color: '#000' }}
                  className="DataGridStyle"
                  components={{
                    NoRowsOverlay: props.NoDataRows
                  }}
                  rows={props.CalledOrganizationMaterialList}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  onCellEditCommit={props.updateCalledOrganizationMaterialQuantity}
                  onSelectionModelChange={itm => {
                    props.deleteCalledOrganizationMaterialsFunc(itm)
                  }}
                  columns={[
                    { headerName: intl.formatMessage({id:"components"}), field: 'MaterialText', width: 500 },
                    { headerName: intl.formatMessage({id:"amount"}), field: 'quantity', width: 200, editable: true },
                    { headerName: intl.formatMessage({id:"value"}), field: 'value', width: 150, editable: true },
                    { headerName: intl.formatMessage({id:"total.value"}), field: 'valueamount', width: 150 }
                  ]}
                />
              </div>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} style={{ marginLeft: 5, marginBottom: 5 }}>
            <Typography component='h1' variant='h5' style={{ fontSize: "0.7rem" }} >
              {intl.formatMessage({id:"called.organization.material.double.click.edit"})}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} style={{ marginLeft: 5, marginBottom: 5 }}>
            <Typography component='h1' variant='h5'>
              {intl.formatMessage({id:"called.organization.material.step.bad.material.usage"})}
            </Typography>
          </Grid>
          <Grid container spacing={4} style={{ marginTop: 5, marginLeft: 5 }}>
            <Grid xs={4} sm={1}>
              <SimpleCheckBox changeSelect={props.changeValues} stateName='template3' fullWidth label={intl.formatMessage({id:"yes"})} autoComplete='fname' name="template3" selected={props.template3} variant='outlined' />
            </Grid>
            <Grid xs={4} sm={1}>
              <SimpleCheckBox changeSelect={props.changeValues} stateName='template4' fullWidth label={intl.formatMessage({id:"no"})} autoComplete='fname' name="template4" selected={props.template4} variant='outlined' />
            </Grid>
          </Grid>
        
        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
          <Grid item xs={3} sm={3}>
            <Button
              variant='contained'
              type="button"
              onClick={previousStep}
              fullWidth >
              {intl.formatMessage({id:"previous"})}
            </Button>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Button
              type='button'
              variant='contained'
              color='primary'
              onClick={props.handleSubmit}
              fullWidth
            >
              {intl.formatMessage({id:"end.called"})}
            </Button>
          </Grid>
        </Grid>
        </Grid>
        </Grid>
      </div>
    </form>
  )
}

calledOrganizationMaterialStep.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userSession: state.userSession,
  calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(calledsActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(calledOrganizationMaterialStep))));
calledOrganizationMaterialStep.contextType = AbilityContext;