import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import CustomizedSnackbars from '../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../../components/select/simple';
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete';
import SimpleAutoCompleteMultiple from '../../../components/auto-complete/autocomplete-multiple-create';
import SimpleCheckBox from '../../../components/checkbox/check';
import { browserHistory } from '../../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import SimpleHelp from '../../../components/help';
import * as Service from '../../../services/organizationcategory.service';

class OrganizationCategoryRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession, organizationid } = this.props;
    this.globalAdm = false;
    this.arrayLenght = 0;
    this.isClient = true;
    if (userSession.user.userrole == null) {
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );


    this.initialState = {
      id: 0,
      updatedUser: 0,
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      OrganizationId: organizationid,
      OrganizationText: '',
      OrganizationSlaId: 0,
      OrganizationSlaText: '',
      CalledFlowId: 0,
      CalledFlowText: '',
      SlaCountTypeId: 0,
      SlaCountTypeText: '',
      CategoryId: 0,
      CategoryText: '',
      ParentOrganizationCategoryId: 0,
      ParentOrganizationCategoryText: '',
      name: '',
      cancreatecalled: false,
      imageicon: '',
      parentname: '',
      servicetime: '',
      solutiontime: '',
      useslatime: false,
      serviceslanotification: false,
      isdefaultcategory: false,

    };
    this.state = {
      item: this.initialState,
      files: [],
      loading: true,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      OrganizationSlaList: [],
      CalledFlowList: [],
      SlaCountTypeList: [],
      CategoryList: [],
      ParentOrganizationCategoryList: [],
      template: true,
      template2: true,
      disableFields: false,
      update: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getTemplate = this.getTemplate.bind(this);
  }

  verifyItems() {
    // console.log('utilizar sla '+ this.state.item.useslatime);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getOrganizations();
    await this.getOrganizationSlas();
    //this.getCalledFlows();
    await this.getSlaCountTypes();
    await this.getCategories();
    await this.getDados();
    this.setState({ loading: false });
    //this.getParentOrganizationCategories();
  };

  async getDados(){
    this.setState({ loading: true });
    var result = await Service.getOrganizations(this.props.organizationid);
      if (result.success) {
        this.arrayLenght = result.data.length;
        if(result.data.length > 0){
          this.setState({
            template: false
          });
          let resultData = result.data[0];
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['id']: resultData.id,
              ['name'] : resultData.name,
              ['updatedUser'] : resultData.id,
              ['OrganizationSlaId']: resultData.organizationslaid,
              ['CalledFlowId']:  resultData.calledflowid,
              ['SlaCountTypeId']:  resultData.slacounttypeid,
              ['CategoryId']:  resultData.categoryid,
              ['ParentOrganizationCategoryId']:  resultData.parentorganizationcategoryid,
              ['name'] : resultData.name, 
              ['cancreatecalled'] : resultData.cancreatecalled, 
              ['imageicon'] : resultData.imageicon, 
              ['parentname'] : resultData.parentname, 
              ['servicetime'] : resultData.servicetime, 
              ['solutiontime'] : resultData.solutiontime, 
              ['useslatime'] : resultData.useslatime, 
              ['serviceslanotification'] : resultData.serviceslanotification, 
              ['isdefaultcategory'] : resultData.isdefaultcategory,
            },
            update: true
          }));
        }
        
        this.setState({ loading: false });
                      
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
  };

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
    if (result.success) {
      this.setState({ loading: false, OrganizationList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };
  async getOrganizationSlas() {
    this.setState({ loading: true });
    var result = await Service.getOrganizationSlas(this.props.organizationid);
    if (result.success) {
      this.setState({ loading: false, OrganizationSlaList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };
  async getCalledFlows() {
    this.setState({ loading: true });
    var result = await Service.getCalledFlows();
    if (result.success) {
      this.setState({ loading: false, CalledFlowList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };
  async getSlaCountTypes() {
    this.setState({ loading: true });
    var result = await Service.getSlaCountTypes();
    if (result.success) {
      this.setState({ loading: false, SlaCountTypeList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };
  async getCategories() {
    this.setState({ loading: true });
    var result = await Service.getCategoriesList();
    if (result.success) {
      this.setState({ loading: false, CategoryList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  async getParentOrganizationCategories(id) {
    this.setState({ loading: true });
    var result = await Service.getParentOrganizationCategoriesList(id);
    if (result.success) {
      this.setState({ loading: false, ParentOrganizationCategoryList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));

    this.verifyItems();

  };

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();
    
    if (!this.state.template) {
      
      this.setState({ loading: true });
      
      var data = {
        Id : this.state.item.id,
        Name: this.state.item.name,
        ParentName : this.state.item.ParentOrganizationCategoryId ? this.state.item.ParentOrganizationCategoryId.name : '',
        ServiceTime: this.state.item.servicetime,
        SolutionTime: this.state.item.solutiontime,
        CanCreateCalled: this.state.item.cancreatecalled,
        UseSLATime: this.state.item.useslatime,
        ServiceSLANotification: this.state.item.serviceslanotification,
        IsDefaultCategory: this.state.item.isdefaultcategory,
        OrganizationId: this.state.item.OrganizationId,
        OrganizationSLAId: this.state.item.OrganizationSlaId,
        CalledFlowId: this.state.item.CalledFlowId,
        SLACountTypeId: this.state.item.SlaCountTypeId,
        CategoryId: this.state.item.CategoryId,
        ParentOrganizationCategoryId: this.state.item.ParentOrganizationCategoryId ? this.state.item.ParentOrganizationCategoryId.id : 0,
        CreatedAt: this.state.item.createdAt,
        CreatedUser: this.state.item.createdUser,
      }

      Api.put('/organizationcategories', data)
        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });
            this.getOrganizations();
            this.getOrganizationSlas();
            this.getCalledFlows();
            this.getSlaCountTypes();
            this.getCategories();
            this.props.handleComplete();
            //this.getParentOrganizationCategories(data.OrganizationId);
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });
    } else {

      if(this.state.item.CategoryId != null && this.state.item.CategoryId.length > 0){
        var categoryIds = "";
        this.state.item.CategoryId.forEach(element => {
          categoryIds = categoryIds + element.id + ",";
        });

        var data = {
          Name: intl.formatMessage({id:"menu.categories"}),
          ParentName: intl.formatMessage({id:"tutorial.pages.categories.categories.father"}),
          CategoryId: 0,
          ServiceTime: this.state.item.servicetime,
          SolutionTime: this.state.item.solutiontime,
          CanCreateCalled: this.state.item.cancreatecalled,
          UseSLATime: this.state.item.useslatime,
          ServiceSLANotification: this.state.item.serviceslanotification,
          IsDefaultCategory: this.state.item.isdefaultcategory,
          OrganizationId: this.state.item.OrganizationId,
          OrganizationSLAId: this.state.item.OrganizationSlaId,
          CalledFlowId: this.state.item.CalledFlowId,
          SLACountTypeId: this.state.item.SlaCountTypeId,
          ParentOrganizationCategoryId: this.state.item.ParentOrganizationCategoryId ? this.state.item.ParentOrganizationCategoryId.id : 0,
          CreatedAt: this.state.item.createdAt,
          CreatedUser: this.state.item.createdUser,
          Categories: categoryIds,
          CreateChildren: this.state.template2
        }


        Api.post('/organizationcategories', data)
          .then(result => {
            if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({ id: "add.success" }),
                item: this.initialState,
                files: []
              });
              //this.getParentOrganizationCategories(data.OrganizationId);
              this.props.handleComplete();
              this.props.handleNextBarProgress();
            }
            else {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

              });
              Api.kickoff(result);
            }

          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.errors) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
              });
            }
            else {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data
              });
            }
            Api.kickoff(err);
          });
        }else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({id:"wizard.pages.categories.select.category.import"}),
          });
        }

      
    }
    

    //data['ParentOrganizationCategoryId'] = this.state.ParentOrganizationCategoryId;
    //console.log(this.state.ParentOrganizationCategoryId);
    //data['ParentOrganizationCategoryId'] = data['ParentOrganizationCategoryId']['id'];

  };

  getTemplate(id) {

    Api.get(`/categories/${id}`)
      .then(result => {
        if (result.data.success) {
          this.setState({ loading: false });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);

        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);

      });
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
    if (stateName === 'OrganizationId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['OrganizationText']: text,
        }
      }));
      this.getParentOrganizationCategories(value);
    }
    if (stateName === 'OrganizationSlaId') {
      if (value !== 0 && this.state.item.useslatime) {
        let result = await Service.getSlaById(value);
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['servicetime']: result.data.servicetime,
            ['solutiontime']: result.data.solutiontime
          },
          disableFields: true
        }));
        //this.state.item.solutiontime = result
      } else {
        this.setState(prevState => ({
          disableFields: false
        }));
      }
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['OrganizationSlaText']: text,
        }
      }));
    }
    if (stateName === 'CalledFlowId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['CalledFlowText']: text,
        }
      }));
    }
    if (stateName === 'SlaCountTypeId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['SlaCountTypeText']: text,
        }
      }));
    }
    if (stateName === 'CategoryId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['CategoryText']: text,
        }
      }));
    }
    if (stateName === 'ParentOrganizationCategoryId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['ParentOrganizationCategoryText']: text,
        }

      }));
    }

    if (stateName == 'CategoryId' && this.state.template === true) {
      this.getTemplate(value.id);
    }
    if (stateName === 'useslatime') {
      if (value && this.state.item.OrganizationSlaId !== 0) {
        let result = await Service.getSlaById(this.state.item.OrganizationSlaId);
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['servicetime']: result.data.servicetime,
            ['solutiontime']: result.data.solutiontime
          },
          disableFields: true
        }));
      } else {
        this.setState(prevState => ({
          disableFields: false
        }));
      }

    }

    if (stateName == "template") {
      this.setState({ template: value });
    }

    this.setState({ loading: false });

    //console.log(value)
  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
  render() {
    const { classes, headerMenu, back } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    return (
      <div className={classes.root}>

        <div className={classes.drawerHeader} />

        <Container maxWidth='md'>
          <CssBaseline />
          <div className={classes.paper} style={{ marginTop: 0 }}>

            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>

              <Grid container spacing={2} >
                <Grid item xs={11} sm={11} style={{ display: 'none' }}>
                  <SimpleSelect label={intl.formatMessage({ id: "organization" })} options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={item.OrganizationId} isRequired />
                </Grid>
                <Grid item xs={11} sm={11} style={{ textAlign: 'left' }}>
                  <SimpleCheckBox
                    label={intl.formatMessage({ id: "organization.category.inform.category" })}
                    selected={this.state.template}
                    name='template'
                    stateName='template'
                    changeSelect={this.changeValues}
                  />
                </Grid>

              {this.state.template && (
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox label={intl.formatMessage({id:"organization.category.registration.register.filters"})} selected={true} name='template2' stateName='template2'
                      changeSelect={(event, newValue) => {
                        this.setState({
                          template2: newValue
                        });
                      }} />
                  </Grid>
              )}

              {this.state.template && (
                  <Grid item xs={11} sm={11} style={{ textAlign: 'left' }}>
                    <SimpleAutoCompleteMultiple label={intl.formatMessage({ id: "category" })} options={this.state.CategoryList} stateName='CategoryId' changeSelect={this.changeValues} />
                  </Grid>)}
              {this.state.template && (
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={intl.formatMessage({id:"tutorial.pages.categories.categories.to.import"})} />
                  </Grid>
              )}

              {!this.state.template && (
                  <Grid item xs={11} sm={11} style={{ textAlign: 'left' }}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "organization.category.parent" })} options={this.state.ParentOrganizationCategoryList} stateName='ParentOrganizationCategoryId' created={true} changeSelect={this.changeValues} />
                  </Grid>
              )}
              {!this.state.template && (
                  <Grid item xs={11} sm={11} >
                    <TextField fullWidth label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                  </Grid>
              )}

                <Grid item xs={11} sm={11} style={{ textAlign: 'left' }}>
                  <SimpleSelect label={intl.formatMessage({ id: "organization.category.sla" })} options={this.state.OrganizationSlaList} stateName='OrganizationSlaId' changeSelect={this.changeValues} selected={item.OrganizationSlaId} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <SimpleHelp message={intl.formatMessage({id:"tutorial.pages.categories.what.sla.category"})} />
                </Grid>


                <Grid item xs={11} sm={11} style={{ textAlign: 'left' }}>
                  <SimpleCheckBox label={intl.formatMessage({ id: "organization.category.time.sla" })} name='useslatime' stateName='useslatime' changeSelect={this.changeValues} selected={item.useslatime} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <SimpleHelp message={intl.formatMessage({id:"tutorial.pages.categories.sla.solution.fields.same.organization.sla"})} />
                </Grid>
                <Grid item xs={11} sm={11} style={{ display: 'none', textAlign: 'left' }}>
                  <SimpleSelect label={intl.formatMessage({ id: "flow" })} options={this.state.CalledFlowList} stateName='CalledFlowId' changeSelect={this.changeValues} selected={item.CalledFlowId} />
                </Grid>
                <Grid item xs={11} sm={11} style={{ textAlign: 'left' }}>
                  <SimpleSelect label={intl.formatMessage({ id: "organization.category.count.type" })} options={this.state.SlaCountTypeList} stateName='SlaCountTypeId' changeSelect={this.changeValues} selected={item.SlaCountTypeId} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <SimpleHelp message={intl.formatMessage({id:"tutorial.pages.categories.what.sla.count.type"})} />
                </Grid>
                <Grid item xs={11} sm={11}>
                  <TextField fullWidth type='number' label={intl.formatMessage({ id: "organization.category.service.sla.min" })} disabled={this.state.disableFields} autoComplete='fname' variant='outlined' name='servicetime' onChange={this.handleInputChange} value={item.servicetime} required />
                </Grid>
                <Grid item xs={11} sm={11}>
                  <TextField fullWidth type='number' label={intl.formatMessage({ id: "organization.category.solution.sla.min" })} disabled={this.state.disableFields} autoComplete='fname' variant='outlined' name='solutiontime' onChange={this.handleInputChange} value={item.solutiontime} required />
                </Grid>
              {!this.state.template && (
                  <Grid item xs={11} sm={11} style={{ textAlign: 'left' }}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "organization.category.create.call" })} name='cancreatecalled' stateName='cancreatecalled' changeSelect={this.changeValues} selected={item.cancreatecalled} />
                  </Grid>)}
                <Grid item xs={11} sm={11} style={{ display: 'none', textAlign: 'left' }}>
                  <SimpleCheckBox label={intl.formatMessage({ id: "notification" })} name='serviceslanotification' stateName='serviceslanotification' changeSelect={this.changeValues} selected={item.serviceslanotification} />
                </Grid>
                <Grid item xs={11} sm={11} style={{ textAlign: 'left' }}>
                  <SimpleCheckBox label={intl.formatMessage({ id: "organization.category.time.standard" })} name='isdefaultcategory' stateName='isdefaultcategory' changeSelect={this.changeValues} selected={item.isdefaultcategory} />
                </Grid>
              </Grid>

              <Grid container spacing={0} style={{ marginBottom: '60px', display: 'flex', marginTop: '20px' }}>

                <Grid item xs={12} sm={2} >
                  {this.props.backButton}


                </Grid>

                <Grid item xs={12} sm={2} >
                  {this.props.nextButton}


                </Grid>
                <Grid item xs={12} sm={6} style={{ marginLeft: 'auto' }}>
                  <Button


                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'

                  >
                    {<FormattedMessage id="save" />}
                  </Button>
                </Grid>


              </Grid>

            </form>
          </div>

          {this.state.loading && (
            <Overlay>
              <CircularProgress color='secondary' />
            </Overlay>
          )}

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />
        </Container>

      </div>
    );
  }
}
OrganizationCategoryRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationid: PropTypes.string,
  back: PropTypes.func,
  next: PropTypes.func
};
const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationCategoryRegistration)));