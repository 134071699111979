import Api from './api';
export const getTechinicalTeams = async () => { 
  let data = [];
  await Api.get('/techinicalteams')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getOrganizations = async () => { 
  let data = [];
  await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllByTechnicalTeamId = async (id) => {
  let data = []
  await Api.get(`/techinicalteamorganizations/technicalteam/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}