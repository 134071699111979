import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table/index-report';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from "moment";
import * as componentActions from '../../redux/actions/calledstatuses';
import DialogConfirmation from '../../components/dialog-confirmation'
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import * as ServiceUser from '../../services/techinicalteamuser.service';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear'
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import { Typography, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from "@mui/material";
import SimpleCheckBox from "../../components/checkbox/check";

const NoDataRows = _ => {
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
  )
}

class CalledStatuses extends Component {

  constructor(props) {
    super(props);

    const { userSession, calleds } = this.props;

    this.initialState = {
      UserId: [],
    };

    this.state = {
      calledId: calleds.called.id,
      assignedUser: calleds.called.assigneduser ? calleds.called.assigneduser.id : 0,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      resultList: [],

      UserListForDelete: [],
      openUserInsertModal: false,
      UserListForSelect: [],
      item: this.initialState,
      fulltime: true,
      createddate: false,
      costValue: "1"
    };

    this.closeNotification = this.closeNotification.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    await this.getItens();
    await this.getUsers();
  }

  getItens = () => {
    this.setState({ loading: true });


    Api.get(`/calleduser/called/${this.state.calledId}`)

      .then(result => {
        if (result.data.success) {
          // console.log(result.data.data);
          const formattedList = result.data.data.map(item => {
            return {
              ...item,
              UserName: item.user ? item.user.name : "",
              Participation: item.typecostcalc == 1 ? 'Total' : 'A partir da data/hora de inserção',
              DateCreated: moment(item.createdat).format("DD/MM/YYYY HH:mm"),
            }
          });

          this.setState({ loading: false, resultList: formattedList });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);
      });
  };

  setUserDelete = (user) => {

    console.log("entrei aqui agora")
    this.setState({
      UserListForDelete: user + "," + this.state.UserListForDelete
    })
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  openUserInsertModal = async () => {
    this.setState({
      openUserInsertModal: !this.state.openUserInsertModal
    })
  }

  saveUserInsertModal = async () => {

    if (this.state.item.UserId != null && this.state.item.UserId.length > 0) {
      this.setState({
        loading: true
      })

      let data = new FormData();

      var userids = this.state.item.UserId.map(item => {
        return item.id + ","
      })

      data.append("User", userids);
      data.append("CalledId", this.state.calledId);
      data.append("TypeCostCalc", this.state.costValue);

      const intl = this.props.intl;
      await Api.post('/calleduser', data)
        .then(result => {
          if (result.data.success) {
            this.setState({
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
            })
            this.componentDidMount();
          }
          else {

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });

      this.setState({
        openUserInsertModal: !this.state.openUserInsertModal,
        loading: false
      })
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "Selecione algum usuário"
      });
    }

  }

  async getUsers() {

    console.log("entrei aqui de novo")
    this.setState({ loading: true });
    var result = await ServiceUser.getUsers();
    if (result.success) {

      if(result.data && result.data.length > 0) {
        var list = result.data.filter(item => item.id != this.state.assignedUser)
        this.setState({ UserListForSelect: list });
      }
      this.setState({ loading: false });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  userDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.UserListForDelete && this.state.UserListForDelete.length > 0) {

      this.setState({ loading: true });

      let data = new FormData();
      data.append("User", this.state.UserListForDelete);
      data.append("CalledId", this.state.calledId);

      const intl = this.props.intl;
      await Api.post('/calleduser/delete', data)
        .then(result => {

          if (result.data.success) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
              UserListForDelete: [],
            })
            this.componentDidMount();
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });
    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"select.user"})
      })
    }
  }

  
  openErrorNotification(){
    const intl = this.props.intl;
    this.setState({
      openNotification: true,
      notificationVariant: "error",
      notificationMessage: intl.formatMessage({id:"select.user"})
    })

  }
  handleChangeRadio = (event) => {
    this.setState({ costValue: event.target.value })
  }

  changeValues = (stateName, value, text = '') => {
    this.setState({ loading: true });

    if(stateName == "fulltime"){
      this.setState({ createddate: false });
    }

    if(stateName == "createddate"){
      this.setState({ fulltime: false });
    }

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
    if (stateName === 'OrganizationId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['CategoryId']: []
        }
      }));

      this.getCategories(value);
    }

    if (stateName == 'selectCategories') {

      if (value == true) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['CategoryId']: this.state.CategoryListForSelect,
          }
        }));

      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['CategoryId']: [],
          }
        }));
      }

    }
    this.setState({ loading: false });

  };

  render() {
    const { classes } = this.props;
    const intl = this.props.intl;

    return (
      <Container component="main" maxWidth='xl'>
        <Grid item>
          <Grid item xs={12} sm={12} style={{ padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
            <Grid xs={12} sm={6} >

            </Grid>
            <Grid xs={12} sm={3} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
              <DialogConfirmation
                msgButton={"Remover usuário"}
                openErrorNotification={ () => this.openErrorNotification()}
                msg={"Você tem certeza que desejar remover este usuário deste chamado?"}
                msgDisagree={this.props.intl.formatMessage({id:"cancel"})}
                msgAgree={this.props.intl.formatMessage({id:"yes"})}
                handleConfirmation={e => this.userDeleteModal()}
                userListForDelete={this.state.UserListForDelete}
                classes={classes}
                disabled={!this.context.can('Delete', 'ProfessionalsInvolved')}
                icon={<DeleteIcon />}
              />
            </Grid>
            <Grid xs={12} sm={3} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}
                startIcon={<AddIcon />}
                disabled={!this.context.can('Add', 'ProfessionalsInvolved')}
                onClick={e => this.openUserInsertModal()} >
                {intl.formatMessage({id:"add.user"})}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12}
            style={{
              border: '3px solid #eee'
            }} >
            <div style={{ height: 350 }}>
              <DataGrid
                style={{ fontSize: 14, color: '#000' }}
                className="DataGridStyle"
                components={{
                  NoRowsOverlay: NoDataRows,
                  Toolbar: GridToolbarFilterButton,
                }}
                rows={this.state.resultList}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={itm => {
                  if (itm.length > 0) {
                    this.setUserDelete(itm);
                  } else {
                    this.setState({
                      UserListForDelete: [],
                    })
                  }
                }}
                columns={[
                  { headerName: intl.formatMessage({id:"user"}), field: 'UserName', width: 500 },
                  { headerName: intl.formatMessage({id:"participation"}), field: 'Participation', width: 500 },
                  { headerName: intl.formatMessage({id:"user.error.report.date.time"}), field: 'DateCreated', width: 500 },
                ]}
              />
            </div>
          </Grid>

          <Dialog
            fullWidth
            maxWidth="md"
            open={this.state.openUserInsertModal}
            aria-labelledby="form-dialog-title-org-mat"
            PaperProps={{
              style: {
                overflow: 'hidden'
              }
            }}
          >
            <DialogTitle id="form-dialog-title-org-mat">
              Adicionar profissionais ao chamado
              <ClearIcon onClick={this.openUserInsertModal} style={{ float: 'right', cursor: 'pointer' }} />
            </DialogTitle>
            <div style={{ padding: '0 24px' }}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={4} style={{ padding: "5px" }}>
                  <Grid item xs={12} sm={12} >
                    <SimpleAutoCompleteMultiple
                      label="Técnicos"
                      options={this.state.UserListForSelect}
                      stateName='UserId'
                      changeSelect={this.changeValues} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} style={{ marginTop: 10, marginLeft: 5, marginBottom: 5 }}>
                <FormControl component="fieldset">
                  <FormLabel style={{ color: '#263238', border: 'none', marginBottom: 10 }} component="legend">Participação do profissional no chamado</FormLabel>
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="controlled-radio-buttons-group"
                    value={this.state.costValue}
                    onChange={this.handleChangeRadio} >
                    <FormControlLabel value="1" control={<Radio />} label={intl.formatMessage({id:"total"})} />
                    <FormControlLabel value="2" control={<Radio />} label={intl.formatMessage({id:"from.date.hour.of.insertion"})} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid spacing={2} container justify="flex-end" style={{ padding: 15 }}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant='contained'
                    type="button"
                    onClick={this.openUserInsertModal}
                    fullWidth >
                    {this.props.intl.formatMessage({id:"cancel"})}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={this.saveUserInsertModal}
                  >
                    {<FormattedMessage id="save" />}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Dialog>
        </Grid>

        {this.state.loading && (
          <Overlay>
            <CircularProgress color='secondary' />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>

    );
  }
}
CalledStatuses.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  userSession: state.userSession,
  calleds: state.calleds,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledStatuses)));

CalledStatuses.contextType = AbilityContext;