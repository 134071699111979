import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch,InputLabel, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, Container, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userSessionActions from '../../redux/actions/user-session';
import Logo from '../../components/logo/logo';
import LoginGoogle from '../../components/google/index';
import DialogTerm from '../../components/dialog-term';
import DialogUserLogged from '../../components/dialog-user-logged';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactFlagsSelect from "react-flags-select";
import './styles.css'

//import {AsyncStorage} from 'react';

const defaultProps = {
  borderColor: 'text.secondary',
  bgcolor: "text.secondary",
  color:"secondary.contrastText",
  m: 1,
  border: 1,
  style: { width: '15rem', height: '15rem' },
};


class Login extends Component {
  constructor(props) {
    super(props);

    let langCode = 'BR'
    let language = window.location.pathname.split('/')[1]
    if (language === 'en')
      langCode = 'US'
    else if (language === 'es')
      langCode = 'ES'
    else
      langCode = 'BR'

    this.state = {
      signInForm: {
        email: "",
        password: "",
      },
      rememberMe:false,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      debug:false,
      openDialogUserLogged: false,
      openDialogTerm: false,
      userAcceptedLogged: false,
      languageCode: langCode
    };
    
    this.headers = {
      'Content-Type': 'application/json',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.toggleRememberMe = this.toggleRememberMe.bind(this);
    this.rememberUser = this.rememberUser.bind(this);
    this.getRememberedUser = this.getRememberedUser.bind(this);
    this.forgetUser = this.forgetUser.bind(this);

    this.loginGoogleSuccess = this.loginGoogleSuccess.bind(this);
    this.loginGoogleError = this.loginGoogleError.bind(this);
    this.logoutGoogle = this.logoutGoogle.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
  }
  
  async componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

    async componentDidMount() {
      if(Api == null) {
        var l = this.props.match.params.lang;
        document.location.href=`/${l}/unavailable`
      }
      if((document.location.href.indexOf("localhost") > -1) 
      || (document.location.href.indexOf("demo.cube") > -1)  
      || (document.location.href.indexOf("192.168.1.168") > -1)  
      || (document.location.href.indexOf("127.0.0.1") > -1)  
      || (document.location.href.indexOf("192.168.") > -1)  
      ){
        this.setState({ debug: true });
        //alert(document.location.href+'\n'+this.state.debug);

      }
      const userlogin = await this.getRememberedUser();
    const chatContext = this.context;

    //this.setState({ 
       //signInForm: { ['email']: userlogin ? userlogin :  "", },
       //rememberMe: userlogin ? true : false });
       this.setState(prevState => ({
        signInForm: {
          ...prevState.signInForm,
          ['email']: userlogin ? userlogin :  ""
        },
        rememberMe: userlogin ? true : false
      }));
  }

  async getTutorial(){
    Api.get(`/notification/tutorial`)
      .then(result => {
        if (result.data.success) {
          let listNotification = result.data.data;
          let count = 0;
          let countNot = 0;
          let redirection = true;
          let l = this.props.match.params.lang;
          listNotification.map((item) => {
            item.notification.map((itemDois) => {
              if(itemDois.required == true && itemDois.finish == false){
                count++;
              }
              if(itemDois.required == true){
                countNot++;
              }
            });
            if(countNot == count){
              //document.location.href=`/${l}/steps`
              // console.log("Aqui agora 2");
              return item.id;
            }
            count = 0;
            countNot = 0;
            return 0;
          });
        }
    });
    
  }

  openDialogTerm = () => {
    this.setState({openDialogTerm: !this.state.openDialogTerm });
  }

  openDialogUserLogged = () => {
    this.setState({openDialogUserLogged: !this.state.openDialogUserLogged });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      signInForm: {
        ...prevState.signInForm,
        [name]: value
      }
    }));
  }

  showProblem = () => {

  }

  handleSubmit = async e => {
    if(e && e.preventDefault){
      e.preventDefault();
    }
    
    this.setState({ loading: true });
    var globalAdm = false

    const { email, password } = this.state.signInForm;

    Api.post("/users/login", { email, password, useracceptedlogged: this.state.userAcceptedLogged, ismobile: false}, {
      headers: this.headers
    })
      .then(async result => {
        if (result.data.success) {

          if(result.data.data.firstaccess == false && result.data.data.system == true)
          {
            if(result.data.data.userrole && result.data.data.userrole.length > 0 ){
              var userRole = result.data.data.userrole

              userRole.forEach(element => {
                  if(element.role.id == 1){

                    globalAdm = true

                  }
              });

              if(globalAdm == true){
                this.props.addUserSession(result.data.data);

                this.props.history.push({
                  pathname: `/pt/adm-password-reset`,
                  state: {
                      user : result.data.data
                  }
                });
                //document.location.href=`/${l}/adm-password-reset`
              }

            }

            
          }

          if(globalAdm == false){

            localStorage.setItem("refreshTokenTime", "0");
            this.props.addUserSession(result.data.data);
            localStorage.setItem('accesstoken', result.data.data.accesstoken);
            localStorage.setItem('reload', true);
      
          
            var l = this.props.match.params.lang;
            if(result.data.data.orgid != 0 && result.data.data.orgid != null){
            }else{
              if(result.data.data.startpage && result.data.data.startpage.length > 0 && result.data.data.startpage != 'undefined' && result.data.data.startpage != 'null') {
                document.location.href=`/${l}/${result.data.data.startpage}`
              } else {
                document.location.href=`/${l}/calleds`
              }
            }

          }
          //document.location.href="/calleds";
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        if(err.response && err.response.data && err.response.data.errors){
          if(err.response.data.errors && err.response.data.errors[0] == "Error: TermAccepted"){
            this.setState({
              loading: false,
              openDialogTerm: true
            });
          }else if(err.response.data.errors && err.response.data.errors[0] == "Error: UserLogged"){
            console.log("UserLogged")
            this.setState({
              loading: false,
              openDialogUserLogged: true
            });
          }else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          
        }
        else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: this.props.intl.formatMessage({id:"process.error"})
          });

        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }
  
  toggleRememberMe = (event,value) => {
    // console.log(value);
    this.setState({ rememberMe: value })
      if (value === true) {
    //user wants to be remembered.
      this.rememberUser();
    } else {
      this.forgetUser();
    }
  }

  rememberUser = async () => {
        try {
          // console.log(this.state.signInForm.email);
          await localStorage.setItem('field-service-cmetch', this.state.signInForm.email);
        } catch (error) {
          // Error saving data
          // console.log(error);
        }
  };
  getRememberedUser = async () => {
        try {
          const userlogin = await localStorage.getItem('field-service-cmetch');
          if (userlogin !== null) {
            // We have username!!
            return userlogin;
          }
        } catch (error) {
          // Error retrieving data
          // console.log(error);
        }
  };
  forgetUser = async () => {
      try {
        await localStorage.removeItem('field-service-cmetch');
      } catch (error) {
       // Error removing
      //  console.log(error);
      }
  };
  loginGoogleSuccess = async (u) => {
   
    this.setState({ loading: true });

    const  email = u.ct.Mt;
    const token = u.tokenObj.access_token;
    Api.post("/users/loginOAuth", { email , token }, {
      headers: this.headers
    })
      .then(async result => {
        if (result.data.success) {
          
          localStorage.setItem("refreshTokenTime", "0");

          

          //result.data.data.language=this.props.match.url;

          this.props.addUserSession(result.data.data);
          localStorage.setItem('accesstoken', result.data.data.accesstoken);
          localStorage.setItem('reload', true);
      
          var globalAdm = false
          var l = this.props.match.params.lang;
          if(result.data.data.orgid != 0 && result.data.data.orgid != null){
          }else{
            document.location.href=`/${l}/calleds`
          }

          if(result.data.data.firstaccess == false && result.data.data.system == true)
          {
            
            if(result.data.data.userrole && result.data.data.userrole.length > 0 ){
              


              var userRole = result.data.data.userrole

              userRole.forEach(element => {
                  if(element.role.id == 1){

                    globalAdm = true

                  }
              });

              if(globalAdm == true){
                document.location.href=`/${l}/adm-password-reset`
              }

            }

            
          }
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        if(err.response && err.response.data && err.response.data.errors){
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          });
        }
        else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: this.props.intl.formatMessage({id:"process.error"})
          });

        }
      });

  };
  loginGoogleError = async (u) => {
    const intl = this.props.intl;
    try {
      this.setState({
        loading: false,
        notificationVariant: "error",
        openNotification: true,
        notificationMessage: this.props.intl.formatMessage({id:"login.google.auth.fail"})
      });
      
    } catch (error) {
      // Error removing
    //  console.log(error);
    }
  };
  logoutGoogle = async (u) => {
    const intl = this.props.intl;
    try {
      this.setState({
        loading: false,
        notificationVariant: "info",
        openNotification: true,
        notificationMessage: this.props.intl.formatMessage({id:"login.logout.success"})
      });
    } catch (error) {
     // Error removing
    //  console.log(error);
    }
  };
  
  saveTermAndContinue = async (e) => {

    this.setState({
      loading: true,
      openDialogTerm: false
    });

    const { email } = this.state.signInForm;
    const applicationid = 1;

    Api.post("/users/saveUseTerm", { email, applicationid }, {
      headers: this.headers
    }).then(result => {
      if (result.data.success) {
        this.handleSubmit(e)
      }
    }).catch(err => {
      const intl = this.props.intl;
      if(err.response && err.response.data && err.response.data.errors){
        this.setState({
          loading: false,
          openNotification: true,
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
      }
      else{
        this.setState({
          loading: false,
          openNotification: true,
          notificationMessage: this.props.intl.formatMessage({id:"process.error"})
        });

      }
    });

  }

  saveUserLoggedAndContinue = async (e) => {

    await this.setState({
      loading: true,
      openDialogUserLogged: false,
      userAcceptedLogged: true
    });

    this.handleSubmit(e)

  }

  setMessageUseTerm = () => {
    const intl = this.props.intl;
    this.setState({
      notificationVariant: "error",
      openNotification: true,
      notificationMessage: this.props.intl.formatMessage({id:"login.accept.terms"})
    });
  }

  selectLanguage = async (code) => {
    console.log("=== selectLanguage ===")
    console.log(code)
    await this.setState({ languageCode: code })
    let urlCode = 'pt'
    let headerCode = 'pt-BR'
    if (code === "US") {
      urlCode = 'en'
      headerCode = 'en-US'
    } else if (code === "ES") {
      urlCode = 'es'
      headerCode = 'es-ES'
    }

    Api.setHeaderAcceptLanguage(headerCode)
    this.props.history.push(`/${urlCode}/`);
    window.location.reload()
  }

  render() {
    const { classes, intl } = this.props;
    const { signInForm } = this.state;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className='reactFlagsSelectCustom'>
          <ReactFlagsSelect
            style={{ color: 'white', position: 'fixed', top: '3%', right: '3%' }}
            countries={["BR", "US", "ES"]}
            customLabels={{ BR: "PT-BR", US: "EN-US", ES: "ES" }}
            placeholder={intl.formatMessage({ id: "select.language" })}
            onSelect={this.selectLanguage}
            selected={this.state.languageCode} />
        </div>
        <div className={classes.paper}>
        
            <Logo key="groups-fieldservice-awrar6aw15" />
         
          <Typography component="h1" variant="h5">
            {Api.applicationName()}
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={intl.formatMessage({ id: "email" })}
              name="email"
              autoComplete="email"
              type="email"
              autoFocus
              value={signInForm.email}
              onChange={this.handleInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={intl.formatMessage({ id: "password" })}
              type="password"
              id="password"
              autoComplete="current-password"
              inputProps={{ maxLength: 32, minLength: 6 }}
              value={signInForm.password}
              onChange={this.handleInputChange}
            />

            <Switch
              value={this.state.rememberMe}
              checked={this.state.rememberMe}
              onChange={this.toggleRememberMe}
              /><FormattedMessage id="remember.me" />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              <FormattedMessage id="log.in" />
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/pt/forgot-password/" variant="body2">
                  <FormattedMessage id="forgot.password" />
                </Link>
              </Grid>
            </Grid>
            {this.state.debug && (
            <Grid container>
              <Grid item xs>
                <LoginGoogle success={this.loginGoogleSuccess} error={this.loginGoogleError} logout={this.logoutGoogle}/>
              </Grid>
            </Grid>
            )}

          {this.state.openDialogTerm && (
            <DialogTerm
              handleConfirmation={e => this.saveTermAndContinue(e)}
              classes={classes}
              openDialogTermFunc={this.openDialogTerm}
              openDialogTerm={this.state.openDialogTerm}
              setMessageUseTerm={this.setMessageUseTerm}
            />
          )}
          {this.state.openDialogUserLogged && (
            <DialogUserLogged
              handleConfirmation={e => this.saveUserLoggedAndContinue(e)}
              openDialogUserLoggedFunc={this.openDialogUserLogged}
            />
          )}
          </form>
        </div>
        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => 
    bindActionCreators(userSessionActions, dispatch)

export default injectIntl(connect(null, mapDispatchToProps)(withStyles(Styles)(Login)));
