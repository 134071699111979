import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { useChatHubContext } from '../../contexts/chatHubContext';
import Badge from '@mui/material/Badge';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';

import "./styles.scss";

export default function ChatListOptions() {
  const userSession = useSelector((state) => state.userSession);
  const { 
    openChatList,
    chatHub,
    currentTechnicianChat,
  } = useChatHubContext();

  const updateCurrentChat = async (chat) => {
    if (chat) {
      await chatHub.invoke("ResetNotSeenMessages", chat.id, userSession.user.id);
      await chatHub.invoke("LoadChatMessages", chat.id);
    }
  };

  const currentChatItemStyle = (chatId) => {
    if (currentTechnicianChat && chatId === currentTechnicianChat.id) {
      return "current-chat-style"
    } else {
      return ""
    }
  }

  return (
    <List>
      { openChatList && openChatList.length > 0 && openChatList.map((chat) => (
      <ListItem className={`chat-list-item ${currentChatItemStyle(chat.id)}`} key={ chat.id } onClick={ () => updateCurrentChat(chat) } >
        <ListItemIcon>
          {/* <Avatar> */}
            <Badge badgeContent={chat.notSeenByAssignedUser} color="primary">
              <AccountCircleIcon />
            </Badge>
          {/* </Avatar> */}
        </ListItemIcon>
        {/* //TODO colocar a última mensagem em secondary */}
        <ListItemText primary={ chat.requestingUser.name } />
        {/* <ListItemText primary={ chat.requestingUser.name } secondary={ "last message" } /> */}
      </ListItem>
      )) }
    </List>
  );
}