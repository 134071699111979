import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { PapiroConsole } from '../utils/papiroConsole'
import CustomizedSnackbars from "../components/material-snackbars";

export const ChatHubContext = createContext();

//PapiroConsole.log("=== ChatHubContext===")
//PapiroConsole.log(ChatHubContext)

const isClient = (userSession) => {
  let isClient = false;
  userSession.user.userrole.map(item => {
    if (item.role.roletype === 3) {
        isClient = true
    }
  })
  return isClient
};

export function ChatHubProdiver({ children }) {
  const userSession = useSelector((state) => state.userSession);

  const [chatHub, setChatHub] = useState({});
  const [currentTechnicianChat, setCurrentTechnicianChat] = useState();
  const [currentClientChat, setCurrentClientChat] = useState();
  const [openChatList, setOpenChatList] = useState([]);
  const [currentMessages, setCurrentMessages] = useState(null);
  const [removeChat, setRemoveChat] = useState(null);
  const [alertNotification, setAlertNotification] = useState({ variant: "error", isOpen: false, message: "" });
  const closeAlertMessage = () => setAlertNotification({...alertNotification, isOpen: false});
  const openAlertMessage = (variant, message) => setAlertNotification({variant, message, isOpen: true});
  const [error, setError] = useState(false);


  //? Serve para quando algum chat tem o número de mensagens não vistas alterado
  //? dessa forma, podemos alterar a badge de mensagens não lidas de uma chat na lista
  const [chatUpdated, setChatUpdated] = useState(null);

  const [newChat, setNewChat] = useState(null);
  const [newMessage, setNewMessage] = useState(null);

  const setChatHubConnection = connection => setChatHub(connection);
  const setErrorMessage = ErrorMessage => setErrorMessage(ErrorMessage);

  const setNewPrivateMessage = (message, selectedChat, senderId) => {
    if(message && selectedChat) {
      setNewMessage(message)
      setChatUpdated(selectedChat)
    }
  };
  const ShowError = (message, selectedChat, senderId) => {
    if(message && selectedChat) {
      PapiroConsole.log("SHOW ERROR -> "+message);
      //setErrorMessage(message);
      setAlertNotification({variant: "error", message: message.toString(), isOpen: true});
      setError(true); 
    }
  };
  const Ok = (message, selectedChat, senderId) => {
    if(message) {
      PapiroConsole.log("OK -> "+message);
      setError(false); 
    }
  };

  const UpdateChatInTheList = (chat) => {
    if(chat) {
      setChatUpdated(chat)
    }
  };

  const setOpenClientChat = (messages, chat) => {
    PapiroConsole.log(messages)
    PapiroConsole.log(chat)
    if (messages && chat) {
      setCurrentMessages(messages);
      setCurrentClientChat(chat);
    }
  };

  const setOpenChatListInitialValue = (chats) => {
    setOpenChatList(chats);
  };

  const loadChatMessages = (messages, chat) => {
    PapiroConsole.log("loading messages")
    if (messages && chat) {
      setCurrentMessages(messages);
      setCurrentTechnicianChat(chat)
    }
  };

  const handleNewChat =  (chat, username, organization) => {
    PapiroConsole.log(chat)
    if (isClient(userSession)) {
      setCurrentClientChat(chat);
      // setCurrentMessages([{ ...initialMessage }]);
    } else {
      //* Colocar alguma notificação para o tecnico saber que há um novo chat
      setAlertNotification({variant: "info", message: "Uma nova mensagem no chat chegou para você!", isOpen: true});
      setNewChat(chat);
    }
  };

  const closeChat = (chatId, userId) => {
    setRemoveChat({chatId, userId});
  }
  useEffect(() => {
    
  }, [error]);

  useEffect(() => {
      if (newMessage) {
        if (isClient(userSession)) {
            if (currentMessages && currentMessages.length > 0) {
              setCurrentMessages([...currentMessages, newMessage]);
            } else {
              setCurrentMessages([newMessage]);
            }
        } else if (currentTechnicianChat && currentTechnicianChat.id === newMessage.chatId) {
          if (currentMessages && currentMessages.length > 0) {
            setCurrentMessages([...currentMessages, newMessage]);
          } else {
            setCurrentMessages([newMessage]);
          }
        }
      }
      return () => setNewMessage(null);
    }, [newMessage])

  useEffect(() => {
      if (newChat) {
        // setOpenChatList([...openChatList, newChat])
        setOpenChatList([newChat, ...openChatList])
      }
      return () => setNewChat(null);
    }, [newChat])

  useEffect(() => {
    //? Validações para evitar que um chat errado seja excluído equivocadamente
      if (removeChat) {
        if (isClient(userSession)) {
          if (currentClientChat) {
            if (userSession.user.id === removeChat.userId && removeChat.chatId === currentClientChat.id) {
              setCurrentClientChat(null);
              setCurrentMessages([]);
            }
          }
        } else {
          if (userSession.user.id === removeChat.userId) {
            setOpenChatList(openChatList.filter(chat => chat.id !== removeChat.chatId))
          }
          if (currentTechnicianChat && currentTechnicianChat.id === removeChat.chatId) {
            setCurrentTechnicianChat(null);
            setCurrentMessages([]);
          }
        }
      }
      return () => setRemoveChat(null);
    }, [removeChat])
  
  useEffect(() => {
    if (chatUpdated) {
      if (openChatList && !isClient(userSession)) {
        const updatedChatList = openChatList.map(chat => {
          if (chat.id === chatUpdated.id) {
            return chatUpdated
          }
          return chat
        });
        setOpenChatList(updatedChatList)
      } else if (isClient(userSession)) {
        setCurrentClientChat(chatUpdated);
      }
    }
    return () => setChatUpdated(null);
  }, [chatUpdated])
  
  return (
    <ChatHubContext.Provider value={{ 
      chatHub, 
      setChatHubConnection,
      openChatList,
      setOpenChatListInitialValue,
      setCurrentMessages,
      currentMessages,
      currentClientChat,
      setCurrentClientChat,
      setNewPrivateMessage,
      setOpenClientChat,
      handleNewChat,
      loadChatMessages,
      UpdateChatInTheList,
      currentTechnicianChat,
      closeChat,
      ShowError,
      Ok,
      setErrorMessage,
      error, setError,

    }}>
      { children }

      <CustomizedSnackbars
        variant={alertNotification.variant}
        message={alertNotification.message}
        isOpen={alertNotification.isOpen}
        toClose={closeAlertMessage}
      />

    </ChatHubContext.Provider>
  )
}

export function useChatHubContext() {
  const context = useContext(ChatHubContext);

  //PapiroConsole.log("=== useChatHubContext ===")
  //PapiroConsole.log(ChatHubContext)
  //PapiroConsole.log(context)

  return context;
}