import clsx from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import { QueuePlayNext, DeleteForever } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";

import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as componentActions from "../../redux/actions/calledclassifications";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import { FormattedMessage, injectIntl } from "react-intl";
import Title from "../../components/title-name";
import React, { Component } from "react";
import "./modalRating.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Rating from "@mui/material/Rating";
import Api from "../../services/api";
import { Overlay } from "../../styles/global";
import SnackbarsModal from "../snackbarsModal";
import * as CalledService from "../../services/called.service";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ReactHtmlParser from 'react-html-parser';
import { getSlaAtendimento, getSlaSolucao } from '../../pages/called-list/called.functions';
import * as calledsActions from '../../redux/actions/calleds';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
  Box,
} from "@mui/material";
import { PapiroConsole } from "../../utils/papiroConsole";

class ModalRating extends Component {
  constructor(props) {
    super(props);

    this.listItems = null
    this.state = {
      unratedCalleds : [...this.props.data],
      starRating: 0,
      Observations:"",
      calledclassification: this.initialState,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      CalledList: [],
      AssignedUserList: [],
      starRating: 0,
      observationCalled: undefined,
      lastAccompaniment:null,
      selectedCalled: {Rating: 0,  },
      technicalName: null,
      lastAccompaniments: null,
      title : null,
      idCalled: null,
      selectedIndex : 0,
      createdUser: this.props.createdUser,
    };
    this.closeNotification = this.closeNotification.bind(this);
    this.getById = this.getById.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.getTechnicalUsers = this.getTechnicalUsers.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.changeValues = this.changeValues.bind(this)
    this.FirstLoading = this.FirstLoading.bind(this)
    this.selectCalled = this.selectCalled.bind(this)
    this.getAccompaniment = this.getAccompaniment.bind(this)
    this.listItens = this.listItens.bind(this)
  }

  componentDidMount() {
    this.getAccompaniment()
    this.listItens(this.state.unratedCalleds)
    this.FirstLoading(this.props.data)
  };

  closeNotification() { 
    this.setState({ openNotification: false });
  }

  handleClick = async () => {
    const intl = this.props.intl;
    PapiroConsole.log("=== this.state.selectedCalled.Rating ===")
    PapiroConsole.log(this.state.selectedCalled.Rating)
    if(typeof this.state.selectedCalled.Rating === 'undefined' || this.state.selectedCalled.Rating == null || (this.state.selectedCalled.Rating && this.state.selectedCalled.Rating == 0)) {
      this.props.setLoading(false)
      this.props.setMessage(true, "error", "Nota inválida")
      return false;
    }
    
    this.props.setLoading(true)

    let data = {
      ObservationAssigned: this.state.selectedCalled.Observations,
      CalledId: this.state.selectedCalled.id,
      NoteAssigned: this.state.selectedCalled.Rating,
      CreatedUser: this.state.createdUser
    };
    await Api.post("/calledclassifications", data)
      .then((result) => {
        if (result.data.success) {
          let newArray = this.state.unratedCalleds
          newArray.forEach( (element,index) => {
              if(this.state.selectedCalled.id === element.id)
                newArray.splice(index,1)
          });
          this.listItens(newArray)

          if(newArray.length === 0 )
            window.setTimeout(this.props.onClose(), 1500);
          else
            this.listItens(newArray)

            this.props.setLoading(false)
            this.props.setMessage(true, "success", this.props.intl.formatMessage({id:"modalRating.called.rating.success"}))
          this.setState({
            unratedCalleds : newArray
          });
          
          PapiroConsole.log("=== newArray ===");
          PapiroConsole.log(newArray);
          this.FirstLoading(newArray)
        }

        
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.props.setLoading(false)
          this.props.setMessage(true, "error", this.props.intl.formatMessage({id:"process.error"}))
        }
      });
      
    
    //alert("funcionou");
    
  };

  async getTechnicalUsers(organizationId) {
    this.props.setLoading(true)

    const result = await CalledService.getTechnicalUsers(organizationId);

    if (result.success) {
      this.props.setLoading(false)
      this.setState({ technicalUsersList: result.data });
    } else {
      const intl = this.props.intl;
      this.props.setLoading(false)
      this.props.setMessage(true, "error", result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  handleInputChange(e) {
    const value = e.target.value;
    this.setState({ observationCalled: value });
  }

  changeValues(event,newValue,name)
  {
    var selectedCalledCopy = null
    var unratedCalledsCopy = null
    const index = this.state.unratedCalleds.findIndex((i)=>{
      return i.id === this.state.selectedCalled.id;
    })
    if(event.target.name === "rating")
    {
      selectedCalledCopy = this.state.selectedCalled
      selectedCalledCopy.Rating = newValue
      unratedCalledsCopy = this.state.unratedCalleds
      unratedCalledsCopy[index].Rating = newValue
  
      this.setState(
        {
          unratedCalleds : unratedCalledsCopy,
          selectedCalled : selectedCalledCopy,
          starRating : newValue
        }
      )
    }
    if(event.target.name === "observation")
    {
      selectedCalledCopy = this.state.selectedCalled
      selectedCalledCopy.Observations = event.target.value
      unratedCalledsCopy = this.state.unratedCalleds
      unratedCalledsCopy[index].Observations = event.target.value
  
      this.setState(
        {
          unratedCalleds : unratedCalledsCopy,
          selectedCalled : selectedCalledCopy,
          Observations : event.target.value

        }
      )
    }

  }

  loading(choice) {
    this.props.setLoading(choice)
  }

  async getById(id) {
    
    let data = {}
    this.props.setLoading(true)

     await Api.get(`/calleds/calledInfoPaginate/${id}`)
      .then(async result => {
        if (result.data.success) {

          let item = result.data.data

          let times = "";
            if (item.organizationcategory.techinicalteamorganizationcategories != null) {
              item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
                  times = times + item2.techinicalteam.name + ","
              });
              times = times.substring(0, times.length - 1);
            }
            data = {
              ...item,
              statusName: item.status.name,
              OrganizationCategory: item.organizationcategory.parentname,
              Organization: item.organization.name,
              slaAtendimento: getSlaAtendimento(item, this.props.userSession) + "%",
              slaSolucao: getSlaSolucao(item, this.props.userSession) + "%",
              assignedUser: item.assigneduser ? item.assigneduser.name : "",
              requestingUser: item.requestinguser ? item.requestinguser.name : "",
              time: times,
              addressname: item.address.name,
              opening: moment(item.openingdate).format("DD/MM/YYYY"),
              classification: item.calledclassification
            }

            

            await this.props.addCurrentCalled(data);
            await this.props.addAccompanimentsList(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : null);

            await this.props.addCalled(data)
            await this.props.addAccompanimentsListInCalled(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : [], data.id)

            const urlRedirect = `/pt/called-accompaniment/${data.id}`;

            await setTimeout(()=>{ window.open(urlRedirect, "_blank");
              this.props.setLoading(false)
            }, 3000);
          } else {
            this.props.setLoading(false)
            this.props.setMessage(true, "error", result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"modalRating.send.rating"}))
            Api.kickoff(result);
        }
      })
      .catch(err => {
        this.props.setLoading(false)
        this.props.setMessage(true, "error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"}))
        Api.kickoff(err);

      });
  };

    
  FirstLoading(data)
  {
    PapiroConsole.log("=== FirstLoading ===")
    PapiroConsole.log(data)
    if(data[0] !== null && data[0] !== undefined  )
    {
      if (data[0] && data[0].id)
        this.selectCalled(data[0].id,0)
    }
    //console.log("não entrei")
  }
    

  async selectCalled(id,indexList){

    PapiroConsole.log("=== selectCalled ===")
    PapiroConsole.log(id)

    if(id == null){
      return false;
    }

    var lastAccompaniment = null

    await Api.get(`accompaniments/getAllByCalled/${id}`)
    .then(result => {

        if (result.data.success && result.data.data) {
            lastAccompaniment = result.data.data[0].description
            const index = this.state.unratedCalleds.findIndex((i)=>{
              return i.id === id;
            })

            let selectedCalledCopy = this.state.unratedCalleds[index] 
            if(selectedCalledCopy.Observations === undefined)
            {
              selectedCalledCopy.Observations = ""
            }
            
            
              
              
              this.setState({
                selectedCalled : selectedCalledCopy,
                selectedIndex : indexList,
                technicalName : this.state.unratedCalleds ? (this.state.unratedCalleds[index].assigneduser ? this.state.unratedCalleds[index].assigneduser.name : "") : "",
                lastAccompaniments : lastAccompaniment,
                title : this.state.unratedCalleds[index].title, 
                idCalled : id,
                starRating: 0,
                Observations:""
              })

              this.props.setLoading(false)

              this.listItens(this.state.unratedCalleds)
      
           
        

           
            } else {
                const intl = this.props.intl;
                this.setState({
                    openNotification: true,
                    notificationVariant: "success",
                    notificationMessage: intl.formatMessage({ id: "add.action.success" }),
                    files: [],
                    action: {
                        description: "",
                        privacy: "false"
                    }
                });
                this.props.setLoading(false)
            }
        }
    )

    this.props.setLoading(false)

  }

  async getAccompaniment(){
    if(this.props.idCalled == null){
      return false;
    }
    Api.get(`accompaniments/getAllByCalled/${this.props.idCalled}`)
    .then(result => {

        if (result.data.success) {
            //this.lastAccompaniment = result.data.data[0].description
            //console.log(this.lastAccompaniment)
            this.setState({ lastAccompaniment: result.data.data[0].description  });
           
            } else {
              this.props.setLoading(false)
              this.props.setMessage(true, "success", intl.formatMessage({ id: "add.action.success" }))
                const intl = this.props.intl;
                this.setState({
                    files: [],
                    action: {
                        description: "",
                        privacy: "false"
                    }
                });
            }
        }
    )
  }


  listItens(data){
    
    let items = data
    let i = 0 

    if(data.length > 10)
    {
      items = data.slice(0, 9)
    }
   
     
          this.listItems = (
              <div>
                {items.map((element,index) => {
                  return(
                    <List component="nav">
                  
                      <ListItem button selected={this.state.selectedIndex === index} onClick={() => this.selectCalled(this,element.id,index)} id={element.id}>
                        <ListItemIcon>
                          <CallEndIcon/>
                        </ListItemIcon>
                      
                        <ListItemText  primary={`Chamado: ${element.id}`} />
                        
                      </ListItem>
                      <Divider />
                     
                    </List>
                    
                    
                  )
                
                })}
        
              </div>
      );

   

   this.setState({
    unratedCalleds: items
  })
}

  render() {
    return (
      <div className="modalRating">
        <div className="containerModal" align ="center">
         
            <h3 className="inlineDisplay" align ="center">Avalie seus chamados</h3> 
            {this.props.requiredratingcalleds === false ? (
              <button className="closeButton" onClick={this.props.onClose}>
                  <HighlightOffIcon className="closeModals" />
              </button>)
              : null}
          
          <Divider/>
          <div className="flexDisplay">
          <Divider/>
            <div className="listCalleds" >
            {this.state.unratedCalleds.length !== 1 ? (
                  <div align="center">
                    <h4 >{this.props.intl.formatMessage({id:"called.header"})}</h4> 
                    <Divider/>
                  </div>
              )  : null}
              {this.listItems}
            </div>
            <Divider orientation="vertical" flexItem />    
            <Container component="main" maxWidth="xl">
              <div className="content">
                
                <div className= "attributes">
                  <div className="title">
                    <h3 className="boldClass">{this.state.title}</h3>
                  </div>
                  <div className="technician">
                    <h4 className="boldClass"><span className="spantitle">{this.props.intl.formatMessage({id:"modalRating.called.user"})}</span><p>{this.state.technicalName}</p></h4>
                  </div>
                  <div>
                    <h4 className="boldClass"><span className="spantitle">{this.props.intl.formatMessage({id:"modalRating.called.user"})}</span> <p>{ReactHtmlParser(this.state.lastAccompaniments)}</p></h4>
                  </div>
                  <Grid container spacing={4} alignItems="flex-start"
                      justify="center" style={{ justifyContent: 'center' }}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      container
                      alignItems="flex-start"
                      justify="center"
                      direction="row"
                      style={{ justifyContent: 'center' }}
                    >
                      <Box component="fieldset" mt={3} mb={3} borderColor="transparent">
                        <Typography component="h1" variant="h5">
                          &nbsp;&nbsp;&nbsp; {this.props.intl.formatMessage({id:"classification"})}
                        </Typography>
                        <br />
                        <Rating className="margin-classification"
                          name="rating"
                          required
                          value={this.state.selectedCalled.Rating}
                          onChange={(event, newValue,name) => {
                            this.changeValues(event,newValue)
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </div>
                

                <div className="classification">
                  
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label={this.props.intl.formatMessage({id:"observation"})}
                        variant="outlined"
                        name="observation"
                        value={this.state.selectedCalled.Observations}
                        //onChange={this.handleInputChange}
                        multiline
                        rows="8"
                        onInput={(e, newValue,name) =>
                          //this.setState({ observationCalled: e.target.value })
                          this.changeValues(e,newValue)
                        }
                      />
                    </Grid>
                  
                  <Grid item xs={12} sm={12}>
                <Grid container justify="flex-end" style={{marginBottom:'10px', marginTop:'10px'}}  >
                  <Grid xs={6} sm={6}    >
                      <Button
                        //xs={4}
                         className="buttonSpacing"
                        variant="contained"
                        color="primary"
                        //onClick={this.getById.bind(this,this.state.selectedCalled.id,true)}
                        onClick={() => this.getById(this.state.selectedCalled.id,true)}
                      >
                        {this.props.intl.formatMessage({id:"modalRating.open.called"})} 
                      </Button>
                  </Grid>  
                  <Grid xs={6} sm={6}    >
                      <Button
                        //className="buttonSpacing"
                        type="submit"
                        //xs={4}
                        
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleClick(this,this.state.selectedCalled)}
                      >
                        {this.props.intl.formatMessage({id:"modalRating.send.rating"})}
                      </Button>
                   </Grid>
                </Grid>
                </Grid>
                </Grid>
                </div>
              </div>
            </Container>
          
          </div>
        </div>
      </div>
    );
  }
}

ModalRating.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  calleds: state.calleds,
  relationshipcalled: state.relationshipcalled.relationshipcalled
});


const mapDispatchToProps = dispatch =>
  //bindActionCreators({ addCurrentCalled, addAccompanimentsList }, dispatch);
  bindActionCreators(calledsActions, dispatch);
//export default ModalRating;

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ModalRating)));