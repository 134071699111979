import React, { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import 'react-chat-widget/lib/styles.css';
import CloseIcon from '@mui/icons-material/Close';
import ChatListOptions from '../../components/chat-list-options';
import CustomizedSnackbars from "../../components/material-snackbars";
import { ChatOptions } from "../../components/chat-options";

import { useChatHubContext } from '../../contexts/chatHubContext';

import "./styles.scss";
import { PapiroConsole } from '../../utils/papiroConsole';
import { injectIntl } from 'react-intl';

function TechnicianChat({ userId, closeChat, intl}) {
    const { 
      chatHub: chatHubConnection,
      currentTechnicianChat,
      currentMessages,
    } = useChatHubContext();
    const [inputMessage, setInputMessage] = useState(null);
    const [alertNotification, setAlertNotification] = useState({ variant: "error", isOpen: false, message: "" });

    const closeAlertMessage = () => setAlertNotification({...alertNotification, isOpen: false});
    const openAlertMessage = (variant, message) => setAlertNotification({variant, message, isOpen: true});

    useEffect(() => {
      scrollChatToBottom()
    }, [currentMessages])

    const addMessage = (e, message, sender) => {
      e.preventDefault();
      console.log(currentTechnicianChat)
      console.log("currentTechnicianChat")
      if (!message || !currentTechnicianChat){ 
        setAlertNotification({variant: "error", message:intl.formatMessage({id:"technician.chat.select.user"}), isOpen: true});

        return;
      }

      PapiroConsole.log("=== addMessage ===")
      PapiroConsole.log(message)
      PapiroConsole.log(currentTechnicianChat.id)
      PapiroConsole.log(userId)

      chatHubConnection.invoke("SendPrivateMessage", message, currentTechnicianChat.id, userId);
      setInputMessage("");
    }

    const closeChatAttendance = async () => {
      if (!currentTechnicianChat) return;

      await chatHubConnection.invoke("CloseChat", currentTechnicianChat.id, userId);
      openAlertMessage("success", intl.formatMessage({id:"footer.chat.attendance.finished"}));
    };

    const scrollChatToBottom = () => {
      const chatContent = document.querySelector(".chat-main-content");
      if (!chatContent) return;
      chatContent.scrollTo({ top: chatContent.scrollHeight, behavior: "smooth" })
    }
    return (
      <div className="technician-chat-container">
        {/* //TODO Transformar em outro componente */}
          <div className="header-container">
            <div className="chat-button">
              <CloseIcon onClick={ closeChat } />
            </div>
            <div className="header-content">
              {/* <div className="username">{ clientInfo ? clientInfo.name : "" }</div>
              <div className="organization">{ clientInfo ? clientInfo.organization : "" }</div> */}
              <div className="title">{intl.formatMessage({id:"technician.chat.attendance"})}</div>
              <div className="username">{ currentTechnicianChat ? currentTechnicianChat.requestingUser.name : "" }</div>
              {/* <div className="organization">{ currentTechnicianChat ? currentTechnicianChat.requestingUser.name : "" }</div> */}
            </div>
          </div>
        <div className="chat-list-container">
          <ChatListOptions />
          {/* <ChatListOptions /> */}
        </div>
        <div className="chat-container">
          <div className="chat-main-content">
            { currentMessages && currentMessages.length > 0 && currentMessages.map(({ id, message, senderUser }) => {
              if (senderUser && (senderUser.id === userId)) {
                return (
                  <div className="sender">
                    {/* <span key={id} style={{ backgroundColor: "#5f5fff", color: "white", justifyContent: "right" }} className="message sender">{ message }</span> */}
                    <span key={id} style={{ backgroundColor: "#5f5fff", color: "white" }} className="message sender">{ message }</span>
                  </div>
                )
              } else {
                return (
                  <div className="receiver" style={{display:'flex', justifyContent:'flex-start', flexDirection: 'column'}}>
                    <span>{ currentTechnicianChat ? currentTechnicianChat.requestingUser.name : ""}<br /></span>
                    <span key={id} className="message receiver">{ message }</span>
                  </div>
                )
              }
            }) }
          </div>
          <div className="chat-input-container">
            <form onSubmit={ (e) => addMessage(e, inputMessage, userId) } className="input-wrapper">
              <input onChange={ (e) => setInputMessage(e.target.value) } placeholder={intl.formatMessage({id:"technician.chat.type.something"})} className="chat-input" type="text" value={inputMessage}></input>
              <SendIcon onClick={ (e) => addMessage(e, inputMessage, userId) } className="send-icon"/>
              <ChatOptions closeChat={closeChatAttendance}/>
            </form>
          </div>
        </div>
        <CustomizedSnackbars
          variant={alertNotification.variant}
          message={alertNotification.message}
          isOpen={alertNotification.isOpen}
          toClose={closeAlertMessage}
        />
      </div>
    )
}

export default injectIntl(TechnicianChat)