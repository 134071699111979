import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import List from '@mui/material/List'
import { withRouter } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import AppMenuItem from './appmenuitem'
import Divider from '@mui/material/Divider'

const AppMenu = (props) => {
  const classes = useStyles();

  const appMenuItems = props.appMenuItems;

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {/* <AppMenuItem {...appMenuItems[0]} /> */}
      {appMenuItems.map((item, index) => (
        (item.link != "divider") ? ( 
            <AppMenuItem {...item} key={index} />
        )
        :
         ( 
            <Divider   key={index} />
        )

      ))}
    </List>
  )
}

const drawerWidth = 240

const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
  }),
)

export default injectIntl(withRouter(AppMenu))
