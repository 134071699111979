import React from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { ExitToApp, Person, CompareArrows, AccountBox } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
 
function GroupMenu(props) {

  //console.log(props);
  const intl = props.intl;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  console.error(props);
  console.error("propsxxxx");

  return (
  
    <div style={{display: 'inline'}}>
      <Typography variant="h6">
        <div style={{paddingLeft: '30px', fontSize:'20px',padding: '10px'}}>
          <strong>{props.group.groupName}</strong>
        </div>
      </Typography>
    </div>
  );
}

GroupMenu.propTypes = {
  group: PropTypes.object.isRequired,
};


export default GroupMenu;
