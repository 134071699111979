import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import { QueuePlayNext, DeleteForever } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import SimpleSelect from "../../components/select/simple";
import Dropzone from "react-dropzone";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from 'react-phone-input-2'
import ClearIcon from '@mui/icons-material/Clear'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class DialogHelp extends Component<Props> {
  constructor(props) {
    super(props);
    console.log(props)
    console.log("props")
    const { userSession } = this.props;

    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    this.userid = userSession;

    console.log(userSession);
    console.log("userSession");

    const defaultvalue = { id: 0, name: "", parentname: "" };

    this.initialCalledState = {
      name: userSession.user.name,
      phone: userSession.user.phone,
      email: userSession.user.email,
      typereport: 0,
      description: ""
    };

    this.state = {
      item: this.initialCalledState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      defaultCategory: defaultvalue,
      typereport: [{id: 1, name: this.props.intl.formatMessage({id:"error"})}, {id: 2, name: this.props.intl.formatMessage({id:"dialog.help.suggestion"})}, {id: 3, name: this.props.intl.formatMessage({id:"dialog.help.enhancement"})}]
    };

  }

  handleInputChange = (e) => {
    console.log(e)
    console.log(e.target.name)
    console.log(e.target.value)
    console.log("handleInputChange")
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();

    //if (this.state.called.departamentId == 0 || this.state.called.departamentId == null || typeof this.state.called.departamentId == 'undefined')
    if(!this.state.item.typereport || this.state.item.typereport == 0 ){

      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: this.props.intl.formatMessage({id:"dialog.help.missing.field.type"})
      });

    }else{
      await this.setState({ loading: true });

      let data = new FormData();
  
      data.append("description", this.state.item.description);
      data.append("typereport", this.state.item.typereport);
  
      this.state.files.forEach((file) => data.append("filesUpload", file));
  
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
  
      Api.post("/usersreporterros", data, config)
        .then((result) => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: this.props.intl.formatMessage({id:"dialog.help.report.success"}),
              item: this.initialCalledState,
              files: [],
            });
          }else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
              result && result.data && result.data.errors
                  ? result.data.errors[0]
                  : this.props.intl.formatMessage({id:"process.error"}),
            });
          }
        })
        .catch((err) => {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        });
    }
    
  };

  closeNotification = () => {
    this.setState({ openNotification: false });
  }

  changeValues = async (stateName, value, text = "") => {
    this.setState({
      loading: true,
    });

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));
    
    this.setState({
      loading: false,
    });
  }

  removeItem = (file) => {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  handleChangePhone = (e) => {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        ['phone']: e
      }
    }));
  }

  render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    //array.forEach((element) => {});

    return (
      <Dialog
        open={true}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
        style={{overflowX: 'hidden'}}
      >
        <DialogTitle id="customized-dialog-title">
          <ClearIcon onClick={this.props.handleClose} style={{ float: 'right', cursor: 'pointer' }} />
        </DialogTitle>
        <div className={classes.paper} style={{ margin: "10px", padding: "15px", overflowX: 'hidden'}}>
          <Avatar className={classes.avatar}>
            <QueuePlayNext />
          </Avatar>
          <Typography component="h1" variant="h5" style={{textAlign: 'center'}}>
          
          {this.props.intl.formatMessage({id:"dialog.help.problem.with.tool"})} <br/> {this.props.intl.formatMessage({id:"dialog.help.problem.with.tool.cont"})}
          </Typography>
          <form
            name="myForm"
            className={classes.form}
            onSubmit={this.handleSubmit}
            encType="multipart/form-data"
          >
            
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="fname"
                  name="name"
                  variant="outlined"
                  fullWidth
                  id="name"
                  label={this.props.intl.formatMessage({id:"name"})}
                  value={this.state.item.name}
                  inputProps={{ maxLength: 250 }}
                  onChange={this.handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <PhoneInput
                  inputStyle={{
                    padding: '23.5px 14px 23.5px 58px',
                    width: '100%'
                  }}
                  id="phone"
                  name="phone"
                  country={'br'}
                  value={this.state.item.phone}
                  InputLabel={this.props.intl.formatMessage({id:"phone"})}
                  specialLabel={this.props.intl.formatMessage({id:"phone"})}
                  required
                  inputProps={{
                    name: intl.formatMessage({ id: "cellphone" })
                  }} />
                  
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="fname"
                  name="email"
                  variant="outlined"
                  fullWidth
                  id="email"
                  label={this.props.intl.formatMessage({id:"email"})}
                  value={this.state.item.email}
                  inputProps={{ maxLength: 250 }}
                  onChange={this.handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SimpleSelect 
                  label={this.props.intl.formatMessage({id:"type"})}
                  options={this.state.typereport}
                  stateName='typereport'
                  changeSelect={this.changeValues} 
                  selected={this.state.item.typereport}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="fname"
                  id="outlined-multiline-static"
                  label={intl.formatMessage({ id: "description" })}
                  name="description"
                  multiline
                  rows="5"
                  fullWidth
                  value={item.description}
                  variant="outlined"
                  onChange={this.handleInputChange}
                  required
                />
              </Grid>
            </Grid>
            

            <Grid container spacing={0}>
              <section style={{ width: "100%" }}>
                <div className={classes.dropzoneArea}>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      this.setState({ files: acceptedFiles });
                    }}
                  >
                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>
                          {<FormattedMessage id="drag.and.drop.files" />}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                  {this.state.files.map((file) => {
                    return (
                      <div className={classes.dropzoneItem} key={file.name}>
                        {file.name}
                        <DeleteForever
                          className={classes.deleteItem}
                          onClick={() => {
                            this.removeItem(file);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </section>
            </Grid>

            <Grid container justify="flex-end" style={{ justifyContent: 'flex-end', marginBottom: '10px', marginTop: 10 }}  >

              <Grid item xs={12} sm={4} >
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  className={classes.submit}
                  onClick={this.props.handleClose}
                >
                  {intl.formatMessage({id:"close"})}
                </Button>
              </Grid>
              <Grid item xs={12} sm={1} ></Grid>
              <Grid item xs={12} sm={4} >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {<FormattedMessage id="save" />}
                </Button>
              </Grid>
              
            </Grid>

          </form>

          {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
        </div>
      </Dialog>
    );
  }
}

DialogHelp.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(DialogHelp))
);
