import Api from './api';

 export const getAllSelectionModel = async () => {
    let data = [];
    await Api.get(`/techinicalteams/selectionmodel`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
    });
    return data;
  }