import React from 'react';
import { Button, Menu, MenuItem, Typography, IconButton, Grid } from '@mui/material';
import { ExitToApp, Person, CompareArrows, AccountBox } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import SnackbarContent from '@mui/material/SnackbarContent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';
import SimpleHelp from '../help';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

 


function ItemMenu(props) {

  const intl = props.intl;
  const AccordionPanel = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(Accordion);

  const AccordionPanelSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(AccordionSummary);
  
  const AccordionPanelDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(AccordionDetails);
  
  //console.log(props);
  //{ resetSession },
  const [expanded, setExpanded] = React.useState(false);
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSteps = (id) =>  {
    props.handleSteps(id);
  };

  var color = '#ff9800';
  var warning = true;
  if(props.listNotification != null){
	{props.listNotification.map((item, key) => {
		if(item.finish == false){
		if(item.required == true){
			color = '#d32f2f';
			warning = false;
		}
		}
	})}
  }
  const btn = (redirection, colorBtn) => (
    <Button
      variant="contained" size="small" style={{backgroundColor: colorBtn, color: '#FFFFFF' }}
      component={Link} {...{ to: `/pt/${redirection}` }}>
        {intl.formatMessage({id:"verify"})}
    </Button>
  );

  const detailsNotification = (item, color, colorBtn) => (
    
    <AccordionPanelDetails>
    <Grid container style={{marginTop: '-5px', marginBottom: '-5px'}}>
      <Grid item xs={11} sm={11}>
      <SnackbarContent
        message={intl.formatMessage({id:"notification.menu.pendencies"})+ item.table+intl.formatMessage({id:"notification.menu.pendencies.cont"})}
        action={btn(item.redirection, colorBtn)}
        style={{background: color, color: '#FFFFFF' }}>
      </SnackbarContent>
      </Grid>
      <Grid item xs={1} sm={1}>
        <SimpleHelp message={item.info}/>
      </Grid>
    </Grid>
    </AccordionPanelDetails>
  )
  
  var required = false;
  return (
    <div style={{marginBottom: '5px', width: '400px', textAlign: 'right'}}>
      <AccordionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionPanelSummary style={{background: color, color: '#FFFFFF'}} expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
          {!warning && (
            <ErrorOutlineIcon />
          )}
          {warning && (
            <WarningIcon />
          )}
          <Grid container>
            <Grid sm={6} xs={6} style={{textAlign: 'left'}}>
              <Typography style={{display: 'inline', marginLeft: '5px',  color: '#FFFFFF'}}>{props.orgName}</Typography>
            </Grid>
			{(props.listNotification != null) && (
             props.listNotification.map((item, key) => {
              if(item.finish == false){
                if(item.required == true){
                  required = true;
                }
              }
            }))}
            {required && (
              <Grid sm={6} xs={6}>
              <Button
                variant="contained" 
                size="small" 
                style={{background: "rgb(210, 34, 34)", color: 'white'}}
                onClick={() => handleSteps(props.orgId)} >
                  {intl.formatMessage({id:"verify.all"})}
              </Button>
              </Grid>
            )}
          </Grid>
         
        </AccordionPanelSummary>
		{(props.listNotification != null) && (
		  props.listNotification.map((item, key) => {
            if(item.finish == false){
              if(item.required == true){
                return detailsNotification(item, '#d32f2f', 'rgb(210, 34, 34)');
              }else{  
                return detailsNotification(item, '#ff9800', 'rgb(249, 152, 4)');
              }
            }
          }))}
      </AccordionPanel>
    </div>
  );
}

ItemMenu.propTypes = {
  listNotification: PropTypes.array.isRequired,
  orgName: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  handleSteps: PropTypes.func
};


export default ItemMenu;
