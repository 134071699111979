import React, { Component } from "react"
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from "prop-types"
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material"
import { withStyles } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Overlay } from "../../styles/global"
import CustomizedSnackbars from "../../components/material-snackbars"
import HeaderMenu from "../../components/header-menu"
import MaterialTable from 'material-table'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import MyMaterialTable from '../../components/table'
import Api from "../../services/api"
import * as CalledService from '../../services/called.service'
import * as calledsActions from '../../redux/actions/calleds'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer'
import moment from "moment"
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import SimpleDatePicker from '../../components/date-pickers/inline-no-today'
import { saveAs } from 'file-saver'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import Pagination from '@mui/material/Pagination'
import Search from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import autoTable from 'jspdf-autotable'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import LogoMarca from '../../images/new-logo-cmtech.png'
import html2canvas from 'html2canvas'
import Divider from '@mui/material/Divider'
import * as ExcelJS  from 'exceljs'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class SendSMSReport extends Component {

  constructor(props) {
    super(props)

    this.isClient = true
    this.isAdm = false
    this.typingTimeout = null
    this.organizationId=null
    
    const { userSession } = this.props

    if(userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {
      if(item.role.roletype !=3)
        this.isClient = false
      if(item.role.roletype == 0 || item.role.roletype == 1)
        this.isAdm = true
    });

    if(userSession.user.organization)
      this.organizationId = userSession.user.organization.id
        
    this.state = {
      dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
      dateEnd:moment().format('YYYY/MM/DD HH:mm:ss'),
      organizationId: this.organizationId,
      OrganizationFilter: 0,
      location: 0,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      showGrid: false,
      calledsList: [],
      organizationList: [],
      organizationAddressesList: [],
      calledPdfList:[],
      totalPages:0,
      page:1,
      pageStart:0,
      pageEnd:0,
      count:0,
      searchValue: '',
      showHeader:false,
      strhtmlModalOpen: false,
      dialogHTMLString: '',
      message: ''
    }
  }

  openHTMLRenderModal = (message) => {
    this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, message: message })
  }

  closeHTMLRenderModal = () => {
    this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, message: '' })
  }

  changeValues = async (stateName, value, text = '') => {
    this.setState({ loading: true })
    
    if(stateName === 'location')
      this.setState({ [stateName]: value.id })
    else if(stateName === 'OrganizationFilter'){
      this.setState({ [stateName]: value.id })
      await this.getOrganizationAddresses(value.id)
    } else
      this.setState({ [stateName]: value });

    this.setState({ loading: false })
  };

  Reload = () => {
    const reload = localStorage.getItem('reload')
    if(reload == "true" )
      localStorage.setItem('reload', false)
    return null;
  }

  onChangePaginate = (e,page) =>{
    this.setState({ page:page })
    this.getCalleds('',page)
  }

  exportPdf = _ => {
    this.getCalledsToPdf()
  }

  setPagination = (count,page) => {
    let totalPage = Math.ceil (count/10)
    this.setState({ totalPages:totalPage })
    if(count > 0){
      if(page == 1){
        if(count < 10)
          this.setState({pageStart:1, pageEnd:count})
        else
          this.setState({pageStart:1, pageEnd:10})
      } else{
        let pageStart = ((page - 1) * 10) + 1
        if(count >= page * 10)
          this.setState({pageStart:pageStart, pageEnd:page * 10})
        else
          this.setState({pageStart:pageStart, pageEnd:count})
      }
    } else
      this.setState({totalPages:0, page:1, count:0})
    }

  getOrganizationAddresses = async organizationId => {
    this.setState({ loading: true })

    const result = await CalledService.getOrganizationAddresses(organizationId)
    if (result.success) {
      if(this.isTec == false){
        if(result.data.length < 1  ){
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: this.props.intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.address"})
          })
        }
      }
            
      this.setState({ loading: false, organizationAddressesList: result.data })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      })
      Api.kickoff(result)
    }
  };

  onFieldChange = event => {
    // Clears the previously set timer.
    clearTimeout(this.typingTimeout)
    // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
    this.typingTimeout = setTimeout(this.callSearch, 475);
    this.setState({ [event.target.name]: event.target.value, page:1 })
  }

  callSearch = _ => {
    this.getCalleds('',this.state.page)
  }

  getOrganizations = async _ => { 
    let data = [];
    const { userSession } = this.props
    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      data = userSession.user.userorganizations
    } else {
      await Api.get('/organizations/tech')
        .then(result => {
          data = result.data.data
        })
        .catch(err => {
          data = err
        });
    }
    this.setState({ organizationList: data })
  }
    
  deleteItem = (data, resolve) => {
    const intl = this.props.intl;
    let abl = this.context.can('Delete', 'Called')
    if(!abl){
      this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"})})
      resolve()
      return false
    }
    Api.delete(`/logmail/${data.id}`)
      .then(result => {
        if (result.data.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({id:"called.list.notification.delete.success"}),
            resultList: []
          })

          this.getCalleds()
        } else{
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          })
          Api.kickoff(result)
        }
          resolve()
      })
      .catch(err => {
        resolve()
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        })
        Api.kickoff(err)
      })
  }

  async componentDidMount() {
    this.setState({loading: true})
    await this.getOrganizations()
    this.setState({loading: false})  
  }
  
  componentWillReceiveProps(nextProps){
    if(nextProps.location.state === "SendSMSReports")
      this.getCalleds(this.props.location.state)
    else if(nextProps.location.state === "assignedcalleds")
      this.getCalleds(nextProps.location.state)
    else if(nextProps.location.state === "reload")
      this.componentDidMount()
  }    
  
  getFile = async () => {
    const intl = this.props.intl;
    this.setState({ loading: true })
    var url = '/logmail/getfile'
    url =url+ '?filter='+this.props.location.state
    
    url += `&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&loc=${this.state.location}`
    Api.get(url)
      .then(async (response) => {
        const formattedList = response.data.data.map(item => {
          return {
            ...item,
            Date: moment(item.createdat).format('DD/MM/YYYY HH:mm'),
            statusName: item.success && item.success == true ? intl.formatMessage({id:"yes"}) : intl.formatMessage({id:"no"})
          }
        })

        const wb = new ExcelJS.Workbook()

        const ws = wb.addWorksheet()
        //relatório de chamados
        ws.columns = [
          { header: intl.formatMessage({id:"date"}), key: 'Date',width: 25 },
          { header: intl.formatMessage({id:"phone"}), key: 'phone',width: 25 },
          { header: intl.formatMessage({id:"message"}), key: 'message',width: 25 },
          { header: intl.formatMessage({id:"send"}), key: 'statusName',width: 25 }
        ]

        ws.getCell('A1').style = {font:{bold:true}}
        ws.getCell('B1').style = {font:{bold:true}}
        ws.getCell('C1').style = {font:{bold:true}}
        ws.getCell('D1').style = {font:{bold:true}} 
        ws.addRows(formattedList);
    
        const buf = await wb.xlsx.writeBuffer()
    
        saveAs(new Blob([buf]), 'relatorio_chamados.xlsx')
        this.setState({ loading: false })
      });
  }

  getMessageBadUseFlag = message => {
    const intl = this.props.intl;
    let writing = ""
    if(message === true)
      writing = intl.formatMessage({id:"yes"})
    else if(message === false)
      writing = intl.formatMessage({id:"no"})
    else
      writing = this.props.intl.formatMessage({id:"undefined"})

    return writing
  }

   


  getCalleds = async (filter = '',page = 1) => {
    const intl = this.props.intl;
    this.setState({ loading: true });

    let url = `/logsmssend/paginate?page=${page}`
    
    if(this.isClient)
      url = `/logsmssend/paginate/${this.state.organizationId}`
    
    if(this.state.searchValue != '')
      url += `&search=${this.state.searchValue}`
    
    if(filter != '')
      url =url+ '&filter='+filter
        
    url += `&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&loc=${this.state.location}`

    this.setState({ showGrid: true })

    var writing = ""

    Api.get(url)
      .then(result => {
        if (result.data.success) {
          let count = result.data.count
          this.setState({count:count})
          this.setPagination(count,page)

          const formattedList = result.data.data.map(item => {
            return {
              ...item,
              Date: moment(item.createdat).format('DD/MM/YYYY HH:mm'),
              statusName: item.success && item.success == true ? intl.formatMessage({id:"yes"}) : intl.formatMessage({id:"no"}),
              Message: item.message && item.message.length > 0 ? <Tooltip title={intl.formatMessage({id:"send.sms.report.view.message.sent"})}>
                <IconButton aria-label="textevidence"
                  onClick={ e => this.openHTMLRenderModal(item.message) }>
                    <MailOutlineIcon />
                  </IconButton>
                </Tooltip> : ''
            }
          })

          console.log("=== formattedList ===")
          console.log(formattedList)

          this.setState({ calledsList: formattedList,loading:false })
        } else{
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {
        console.log("=== Catch(e) ===")
        console.log(err)
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        })
        Api.kickoff(err)
      })
    }

  getMessageExcellBadUseFlag = (message,status) => {
    const intl = this.props.intl;
    let writing = ""
    if(status === intl.formatMessage({id:"finished"})) {
      if(message === true)
        writing = intl.formatMessage({id:"yes"})
      else if(message === false)
        writing = intl.formatMessage({id:"no"})
    }else
      writing = this.props.intl.formatMessage({id:"undefined"})

    return writing
  }


  getCalledsToPdf = (filter = '') => {
    const intl = this.props.intl;
    this.setState({ loading: true })
    let url = `/logmail/pdf`
    if(this.state.searchValue != '')
      url += `?search=${this.state.searchValue}`
        
    url += `?dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&loc=${this.state.location}`

    Api.get(url)
      .then(async (result) => {
        if (result.data.success) {
          await this.setState({showHeader:true})
          const formattedList = result.data.data.map(item => {
            return {
              ...item,
              Date: moment(item.createdat).format('DD/MM/YYYY HH:mm'),
              statusName: item.success && item.success == true ? intl.formatMessage({id:"yes"}) : intl.formatMessage({id:"no"})
            }
          })

          const doc = new jsPDF("p", "mm", "a4")
          const header = document.getElementById('header-pdf')
          let headerCanvas = await html2canvas(header)
          const headerImg = headerCanvas.toDataURL('image/png')
          doc.addImage(headerImg, 'PNG', 0, 0)

          doc.autoTable({
            body: formattedList,
            margin: { top: 30 },
            bodyStyles: {valign: 'middle'},
            styles: {overflow: 'linebreak', columnWidth: 'wrap', fontSize: 7, halign: 'center'},
            theme: 'striped',
            columns: [
              { header: intl.formatMessage({id:"date"}), dataKey: 'Date' },
              { header: intl.formatMessage({id:"sender"}), dataKey: 'sender' },
              { header: intl.formatMessage({id:"recipient"}), dataKey: 'recipient' },
              { header: intl.formatMessage({id:"subject"}), dataKey: 'subject' },
              { header: intl.formatMessage({id:"send"}), dataKey: 'statusName' }
            ],
            columnStyles: {
              Date: {
                columnWidth: 20
              },
              sender: {
                columnWidth: 21
              },
              recipient: {
                columnWidth: 17
              },
              subject: {
                columnWidth: 21
              },
              statusName: {
                columnWidth: 14
              }
            }
          })

        doc.save("relatorio-emails-enviados.pdf")
        this.setState({ loading: false,showHeader:false })
        }else{
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        })
        Api.kickoff(err)
      })
  }

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();
    this.setState({ loading: true });

    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')
    if(dateStart > dateEnd){
        
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
                loading :false
                });
   
    }
    else
    {
      await this.getCalleds(this.props.location.state)
    }

    await this.getCalleds(this.props.location.state)
  }

  closeNotification = _ => {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes, headerMenu } = this.props
    const intl = this.props.intl

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    const tableList = () => {
      return (
        <>
          <Dialog open={this.state.strhtmlModalOpen} onClose={this.closeHTMLRenderModal} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
              Mensagem
              <ClearIcon onClick={this.closeHTMLRenderModal}  style={{ float: 'right', cursor : 'pointer'}}/> 
            </DialogTitle>
            <DialogContent style={{ marginBottom: 30 }}>
              <div>{ ReactHtmlParser(this.state.message) }</div>
            </DialogContent>
          </Dialog>
          <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'> 
          <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>{intl.formatMessage({id:"filters"})}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container style={{marginBottom:20}}>
                            <Grid item xs={12} sm={6} md={3}>
                              <SimpleDatePicker label={intl.formatMessage({id:"start.date"})} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <SimpleDatePicker label={intl.formatMessage({id:"end.date"})} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd}  required/>
                            </Grid>
                            { 5 == 2 && <Grid item xs={12} sm={6} md={3} style={{marginRight:5}}>
                              <SimpleAutoComplete label={intl.formatMessage({id:"organization"})} options={this.state.organizationList} stateName='OrganizationFilter' changeSelect={this.changeValues} />
                            </Grid> }
                            { 5 == 2 && <Grid item xs={12} sm={6} md={3}>
                              <SimpleAutoComplete label={intl.formatMessage({id:"location"})} options={this.state.organizationAddressesList} stateName='location' changeSelect={this.changeValues} />
                            </Grid> }
                          </Grid>  
                        </AccordionDetails>
              </Accordion>
            

            <Grid container style={{marginBottom:10}}>
              <Grid item xs={12} sm={2} md={2} style={{marginRight:10,marginTop:10}}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='secondary' >
                  {<FormattedMessage id="filter" />}
                </Button>
              </Grid>

              { this.state.showGrid && (5 == 2) && <Grid item xs={12} sm={5} md={2} style={{marginRight:10,marginTop:10}}>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.getFile} >
                  {<FormattedMessage id="export.plan" />}
                </Button>
              </Grid>}

              { this.state.showGrid && (5 == 2) && <Grid item xs={12} sm={5} md={2} style={{marginRight:10,marginTop:10}}>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.getCalledsToPdf} >
                {<FormattedMessage id="dash.pdf" />}
                </Button>
              </Grid>}
            </Grid>                                   
          </form>
                
        {
          this.state.showGrid && <> 
            <MyMaterialTable
              title=""
              columns={
                [
                  { title: intl.formatMessage({id:"date"}), field: 'Date' },
                  { title: intl.formatMessage({id:"phone"}), field: 'phone' },
                  { title: intl.formatMessage({id:"message"}), field: 'Message' },
                  { title: intl.formatMessage({id:"send"}), field: 'statusName' }
                ]}
              data={this.state.calledsList} />

            <Grid container style={{marginBottom:12,marginTop:8}}>
              <Grid item xs={12} md={3}>
                <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
              </Grid>
              <Grid item xs={12} sm={3} style={{marginTop:6}}>
                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
              </Grid>
            </Grid>
          </>
        }
        </>
      );
    }

    return (
      <div className={classes.root} style={{paddingLeft:0, marginLeft:0}}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })} >
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='xl' style={{paddingLeft:0, marginLeft:0}}>
            <CssBaseline />
          {this.state.showHeader && 
            <Grid container>
              <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{padding: '2px'}} style={{minWidth:600}}>
                <Card >
                  <CardContent>
                    <img src={LogoMarca} style={{width:"70px"}}/> <span style={{marginLeft:25, fontSize:18}}> {<FormattedMessage id="sendmail.report.header" />}</span>
                    <Divider />
                  </CardContent>
                </Card>  
              </Grid>
            </Grid>
          }
          
            <Grid container spacing={6}> 
              <Grid item xs={12} sm={12}>
                <Card >
                  <CardContent className="background-title-search">
                    <Typography variant="h5">
                      {<FormattedMessage id="sendsms.report.header" />}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          
            {tableList()}

          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
          </Container>
        </main>
      <Footer />                    
      </div>
    )
  }
}

SendSMSReport.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(SendSMSReport)))
SendSMSReport.contextType = AbilityContext