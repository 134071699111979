import React, { Component } from "react";
import QrReader from "react-qr-reader";
import * as calledsActions from '../../redux/actions/calleds';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl'
import { AbilityContext } from '../../config/ability-context';
import { withRouter } from "react-router-dom";
import { makeStyles, createStyles, withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import Api from '../../services/api';
import { Overlay } from "../../styles/global";
import {  CircularProgress } from "@mui/material";
import moment from 'moment';


class QRCodeReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 300,
      width:props.width,
      result: "Sem leitura"
    };
    if(props.width == null){
        props.width =300;
    }
    this.isClient = true;
    
    const { userSession } = this.props;

    userSession.user.userrole.map((item, key) => {
      if(item.role.roletype !=3){
          this.isClient = false;
      }}
    );
    this.loading = false;
    this.handleScan = this.handleScan.bind(this);
  }

  async handleScan(data) {
    const intl = this.props.intl;
    if (data && !this.loading) {
      //console.log(data);
      var jsonData = JSON.parse(data);
      let dataSet = new FormData();
      this.loading = true;
      
      const { userSession } = this.props;

      this.setState({
        ["called"]: "",
        ["result"]: "Aguarde...",
        ["loading"]: true,
        ["delay"]: 400
      });

      for (const [key, value] of Object.entries(jsonData)) {
        
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            [key]: value
          }
        }));

      }

      let dataOrg = [];
    
      await Api.get(`/organizationcategories/default/${this.state.called.organizationid}`)
      .then(result => {
        dataOrg = result.data.data
      })
      .catch(err => {
        dataOrg = err;
      });
      

      const clientInfos = await this.getOrganizationSettings();

      if(clientInfos){
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            originRequest: clientInfos.originrequest.id,
            status: clientInfos.status.id,
            type: clientInfos.type.id,
          }
        }));

        if(clientInfos.canselectpatrimonyincalled == false){
          this.setState({
            ["called"]: "",
            ["result"]: this.props.intl.formatMessage({id:"qrcode.reader.organization.patrimonies.error"}),
            ["loading"]: false,
            ["delay"]: 400
          });
          return false;
        }
      }

      let title = this.props.intl.formatMessage({id:"qrcode.reader.organization.patrimonies.problems"})+this.state.called.name;
      let description = this.props.intl.formatMessage({id:"qrcode.reader.organization.patrimonies.problems"})+this.state.called.name;

      dataSet.append('title', title);
      dataSet.append('description', description);
      dataSet.append('duedate', moment().add('days', 1).format("YYYY/MM/DD HH:mm:ss"));
      dataSet.append('organizationcategory', dataOrg.id);
      dataSet.append('organization', dataOrg.organization.id);
      dataSet.append('calledorganizationpatrimonyid', this.state.called.patrimonyid);
      dataSet.append('requestingUser', userSession.user.id);
      dataSet.append('createdUser', userSession.user.id);
      dataSet.append('addressid', dataOrg.organization.addresses[0].id);

      for (let key in this.state.called) {
        dataSet.append(key, this.state.called[key]);
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      let calledDate = "";

      if(clientInfos.automaticallycreatecalledwhenreadingqr == true){
        await Api.post("/calleds", dataSet, config)
        .then(result => {
          if (result.data.success) {
              calledDate = result.data.data;
          }
        })
        .catch(err => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"error"})
          });
        });
  
        var dataProps = [];
  
        if(calledDate){
          await Api.get(`/calleds/calledInfoRelation/${calledDate.id}`)
          .then(result => {
            this.props.addCurrentCalled(result.data.data);
            this.props.addCalled(result.data.data)
            this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${calledDate.id}`);
          })
          .catch(err => {
            dataProps = err;
          });
        }
      }else{
        let json = {};
        Array.from(dataSet.entries()).forEach(([key, value]) => {
          json[key] = value;
        });
        
        if(!this.isClient){
          this.props.addCurrentCalled(json);
          this.props.history.push(`/${this.props.match.params.lang}/called-registration`);
        }else{
          this.props.addCurrentCalled(json);
          this.props.history.push(`/${this.props.match.params.lang}/called-registration-client`);
        }
      }
      
      /*this.props.history.push({
        pathname: `/${this.props.match.params.lang}/called-registration`,
      });*/

    }
  }

  async getOrganizationSettings() {
    const intl = this.props.intl;
    let infos;

    await Api.get(`/organizationsettings/organization/${this.state.called.organizationid}`)
      .then(result => {
        if (result.data.success) {
          infos = result.data.data;
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: this.props.intl.formatMessage({id:"error"})
        });
        Api.kickoff(err);

      });
    return infos;
  };

  handleError(err) {
    console.error(err);
  }
  render() {

    const { handleClose } = this.state;

    return (
      <div>
        <QrReader
          delay={this.state.delay}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: this.state.width }}
        />
        <p>{this.state.result}</p>
        {this.state.loading && (
              <Overlay>
                  <CircularProgress color="secondary" />
              </Overlay>
          )}
      </div>
    );

  }

  
}

const mapStateToProps = state => ({
  userSession: state.userSession,
  calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(calledsActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(QRCodeReader))));
QRCodeReader.contextType = AbilityContext;
