import React, { Component } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { CssBaseline, CircularProgress, Container, Button, Grid, TextField } from '@mui/material'
import { withStyles, makeStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import CustomizedSnackbars from '../../components/material-snackbars'
import HeaderMenu from '../../components/header-menu'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import Api from '../../services/api'
import MyMaterialTable from '../../components/table'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import * as componentActions from '../../redux/actions/organizations'
import Footer from '../../components/footer'
import { FormattedMessage, injectIntl } from 'react-intl'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import Search from '@mui/icons-material/Search'
import SimpleSelect from '../../components/select/simple-search'
import ClearIcon from '@mui/icons-material/Clear'
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import './index.css'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as userSessionActions from '../../redux/actions/user-session';
import { getContract, getById, update, getByLoggedUser } from "../../services/hirer.service"
import { TramRounded } from '@mui/icons-material'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';

const cardStyles = makeStyles((theme) => ({
  action: {
    marginTop: 0
  }
}));

class MyPlan extends Component {

  constructor(props) {
    super(props)

    const { userSession } = this.props
    const intl = this.props.intl;
    this.admGlobal = false;

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype == 0) {
        this.admGlobal = true;
      }
    });

    this.initial = {
      applicationPlan: 'Aplicação: Plano',
      contractStatus: intl.formatMessage({id:"menu.statuses"}),
      contractStatusFlag: false,
      contractStatusColor: '#fff',
      periodicity: intl.formatMessage({ id: 'plan.periodicity' }),
      endDate: '00/00/0000 00:00',
      planValue: '0,00',
      planPeriod: 0,
      initDate: '00/00/0000 00:00',
      acceptDate: '00/00/0000 00:00',
      byllingDate: '00/00/0000 00:00',
      inativeDate: '00/00/0000 00:00',
      stts: intl.formatMessage({id:"menu.statuses"}),
      paymentDate: '00/00/0000 00:00',
      lastPayStatus: intl.formatMessage({id:"menu.statuses"}),
      userLimit: intl.formatMessage({ id: 'plan.user.limit' }) + ':  ',
      diskLimit: intl.formatMessage({ id: 'plan.disk.limit' }) + ': ',
      attendanceLimit: intl.formatMessage({ id: 'plan.attendance.limit.montly' }) + ': '
    }
    
    this.state = {
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      resultList: [],
      contract: {},
      contractPlanPeriodicity: {},
      contractPayment: {},
      expanded: false,
      item: this.initial,
      passwordTemp: '********',
      hirer: {
        id: 0,
        name: '',
        responseName: '',
        responsePhone: '',
        responseMail: '',
        mail: '',
        mailbefore: '',
        password: '',
        passwordNew: ''
      },
      display: 'none',
      displayMessage: false,
      dialogLogout: false
    }

    this.closeNotification = this.closeNotification.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this)
  }

  async componentDidMount() {
    if(window.location.search.indexOf("message") > 0 && !this.admGlobal){
      userSessionActions.removeUserSession();
      document.location.href='/?sessão expirada';
    }else if(window.location.search.indexOf("message") > 0 ){
      window.history.pushState({}, document.title, "/pt/my-plan" );
      this.setState({
        displayMessage: true,
        display: "true"
      })
    }else{
      this.setState({
        display: "true"
      })
    }
    await this.getContract()
    await this.getHirer()
  }

  async getContract() {
    this.setState({ loading: true })
    let result = await getContract()
    if(result && result.data && result.data.data) {
      if(result.data.data != 0) {
        this.setState({
          contractPlanPeriodicity: {},
          contract: result.data.data,
          loading: false
        })

        this.setContractPlanPeriodicity();
      }else 
        this.setState({ loading: false })
    }else {
      this.setState({ loading: false })
    }
  }

  async getHirer() {
    this.setState({ loading: true })
    let result = await getByLoggedUser()
    if(result && result.data && result.data.data) {
      if(result.data.data != 0) {
        this.setState((prevState) => ({
          hirer: {
            ...prevState.hirer,
            id: result.data.data.id,
            name: result.data.data.name,
            responseName: result.data.data.responsiblename,
            responsePhone: result.data.data.responsiblephone,
            responseMail: result.data.data.responsiblemail,
            mail: result.data.data.email,
            mailbefore: result.data.data.email,
            password: result.data.data.accesskey
          },
        }));
        this.setState({ loading: false })
      }else 
        this.setState({ loading: false })
    }else {
      this.setState({ loading: false })
    }
  }

  setContractPlanPeriodicity() {
    if(this.state.contract.contractplanperiodicities) {
      this.state.contract.contractplanperiodicities.map(item => {
        if(item.planperiodicity) {
          if(item.planperiodicity.application && item.planperiodicity.application.name && item.planperiodicity.applicationid == 1)
            this.setState({ contractPlanPeriodicity: item })
            this.configPlan()
        }
      })
    }

    //this.getHirer();
  }

  configPlan() {
    const intl = this.props.intl;

    if(this.state.contract && this.state.contract.contractstatus && this.state.contract.contractstatus.name) {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          stts: this.state.contract.contractstatus.name
        },
      }));
    }

    if(this.state.contract && this.state.contract.inactive != null) {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          contractStatus: this.state.contract.inactive == false ? 'ATIVO' : 'INATIVO',
          contractStatusFlag: this.state.contract.inactive,
          contractStatusColor: this.state.contract.inactive == false ? '#43a047' : '#b71c1c'
        },
      }));
    }

    let appPlan = ''
    if(this.state.contractPlanPeriodicity && this.state.contractPlanPeriodicity.planperiodicity && this.state.contractPlanPeriodicity.planperiodicity.application && this.state.contractPlanPeriodicity.planperiodicity.application.name)
      appPlan = this.state.contractPlanPeriodicity.planperiodicity.application.name
    if(this.state.contractPlanPeriodicity && this.state.contractPlanPeriodicity.planperiodicity && this.state.contractPlanPeriodicity.planperiodicity.plan && this.state.contractPlanPeriodicity.planperiodicity.plan.name)
      appPlan += `: ${this.state.contractPlanPeriodicity.planperiodicity.plan.name}`
    
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        applicationPlan: appPlan
      },
    }));

    if(this.state.contractPlanPeriodicity && this.state.contractPlanPeriodicity.planperiodicity && this.state.contractPlanPeriodicity.planperiodicity.periodicity && this.state.contractPlanPeriodicity.planperiodicity.periodicity.name) {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          periodicity: this.state.contractPlanPeriodicity.planperiodicity.periodicity.name
        },
      }));
    }

    if(this.state.contract && this.state.contract.enddate && this.state.contract.enddate.length > 10 && this.state.contract.enddate != '0001-01-01T00:00:00') {
      let date = `${this.state.contract.enddate.substring(8, 10)}/${this.state.contract.enddate.substring(5, 7)}/${this.state.contract.enddate.substring(0, 4)} ${this.state.contract.enddate.substring(11, 13)}:${this.state.contract.enddate.substring(14, 16)}`
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          endDate: date
        },
      }));
    }

    if(this.state.contractPlanPeriodicity && this.state.contractPlanPeriodicity.planperiodicity) {
      if(this.state.contractPlanPeriodicity.planperiodicity.periodicity) {
        if(this.state.contractPlanPeriodicity.planperiodicity.periodicity.daysexpire == 30) {
          let pVal = this.state.contractPlanPeriodicity.planperiodicity.planvaluepermonth.toFixed(2) + ' ' + intl.formatMessage({ id: 'period.monthly' })
          //let v = parseFloat(pVal.replace(',','.').replace(' ','')) + ' Mensal'
          this.setState((prevState) => ({
            item: {
              ...prevState.item,
              planValue: pVal,
              planPeriod: 1
            },
          }));
        }else if(this.state.contractPlanPeriodicity.planperiodicity.periodicity.daysexpire == 90) {
          let pVal = (this.state.contractPlanPeriodicity.planperiodicity.planvaluepermonth * 3).toFixed(2)
          pVal += ' ' + intl.formatMessage({ id: 'period.quarterly' })
          //let v = parseFloat(pVal.replace(',','.').replace(' ','')) + ' Mensal'
          this.setState((prevState) => ({
            item: {
              ...prevState.item,
              planValue: pVal,
              planPeriod: 3
            },
          }));
        }else if(this.state.contractPlanPeriodicity.planperiodicity.periodicity.daysexpire == 365) {
          let pVal = this.state.contractPlanPeriodicity.planperiodicity.planvalue.toFixed(2) + ' ' + intl.formatMessage({ id: 'period.yearly' })
          //let v = parseFloat(pVal.replace(',','.').replace(' ','')) + ' Anual'
          this.setState((prevState) => ({
            item: {
              ...prevState.item,
              planValue: pVal,
              planPeriod: 12
            },
          }));
        }
      }
    }

    if(this.state.contract && this.state.contract.startdate && this.state.contract.startdate.length > 10 && this.state.contract.startdate != '0001-01-01T00:00:00') {
      let date = `${this.state.contract.startdate.substring(8, 10)}/${this.state.contract.startdate.substring(5, 7)}/${this.state.contract.startdate.substring(0, 4)} ${this.state.contract.startdate.substring(11, 13)}:${this.state.contract.startdate.substring(14, 16)}`
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          initDate: date
        },
      }));
    }

    if(this.state.contract && this.state.contract.accepteddate && this.state.contract.accepteddate.length > 10 && this.state.contract.accepteddate != '0001-01-01T00:00:00') {
      let date = `${this.state.contract.accepteddate.substring(8, 10)}/${this.state.contract.accepteddate.substring(5, 7)}/${this.state.contract.accepteddate.substring(0, 4)} ${this.state.contract.accepteddate.substring(11, 13)}:${this.state.contract.accepteddate.substring(14, 16)}`
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          acceptDate: date
        },
      }));
    }

    if(this.state.contract && this.state.contract.billingstartdate && this.state.contract.billingstartdate.length > 10 && this.state.contract.billingstartdate != '0001-01-01T00:00:00') {
      let date = `${this.state.contract.billingstartdate.substring(8, 10)}/${this.state.contract.billingstartdate.substring(5, 7)}/${this.state.contract.billingstartdate.substring(0, 4)} ${this.state.contract.billingstartdate.substring(11, 13)}:${this.state.contract.billingstartdate.substring(14, 16)}`
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          byllingDate: date
        },
      }));
    }

    if(this.state.contract && this.state.contract.inactivedate && this.state.contract.inactivedate.length > 10 && this.state.contract.inactivedate != '0001-01-01T00:00:00') {
      let date = `${this.state.contract.inactivedate.substring(8, 10)}/${this.state.contract.inactivedate.substring(5, 7)}/${this.state.contract.inactivedate.substring(0, 4)} ${this.state.contract.inactivedate.substring(11, 13)}:${this.state.contract.inactivedate.substring(14, 16)}`
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          inativeDate: date
        },
      }));
    }

    if(this.state.contract && this.state.contract.contractpayments && this.state.contract.contractpayments.size > 0) {
      let pSize = this.state.contract.contractpayments.size
      this.state.contract.contractpayments.map((item, idx) => {
        if(pSize == (idx + 1)) {
          this.setState({ contractPayment: item })
          this.setPaymentData()
        }
      })
    }

    if(this.state.contractPlanPeriodicity && this.state.contractPlanPeriodicity.agentlimitquantity) {
      this.setState((prevState) => ({
          item: {
            ...prevState.item,
          userLimit: intl.formatMessage({ id: 'plan.user.limit' }) + ': ' + this.state.contractPlanPeriodicity.agentlimitquantity
          },
      }));
    }

    if(this.state.contractPlanPeriodicity && this.state.contractPlanPeriodicity.attendancelimitpermonth) {
      this.setState((prevState) => ({
          item: {
            ...prevState.item,
          attendanceLimit: intl.formatMessage({ id: 'plan.attendance.limit.montly' }) + ': ' + this.state.contractPlanPeriodicity.attendancelimitpermonth + ( this.state.item.planPeriod >= 3 ? `, ${this.state.contractPlanPeriodicity.attendancelimitpermonth * this.state.item.planPeriod} ` + ( this.state.item.planPeriod == 3 ? intl.formatMessage({ id: 'period.per.quarter' }) : intl.formatMessage({ id: 'period.per.year' }) ) : '' )
          },
      }));
    }

    if(this.state.contractPlanPeriodicity && this.state.contractPlanPeriodicity.disklimitusage) {
      this.setState((prevState) => ({
          item: {
            ...prevState.item,
          diskLimit: intl.formatMessage({ id: 'plan.disk.limit' }) + ': ' + this.state.contractPlanPeriodicity.disklimitusage + ' GB'
          },
      }));
    }
  }

  setPaymentData() {
    if(this.state.contractPayment){
      if(this.state.contractPayment.payementdate && this.state.contractPayment.payementdate.length > 10 && this.state.contractPayment.payementdate != '0001-01-01T00:00:00') {
        let date = `${this.state.contractPayment.payementdate.substring(8, 10)}/${this.state.contractPayment.payementdate.substring(5, 7)}/${this.state.contractPayment.payementdate.substring(0, 4)} ${this.state.contractPayment.payementdate.substring(11, 13)}:${this.state.contractPayment.payementdate.substring(14, 16)}`
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            paymentDate: date
          },
        }));
      }
      if(this.state.contractPayment.paymentstatus && this.state.contractPayment.paymentstatus.name) {
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            lastPayStatus: this.state.contractPayment.paymentstatus.name
          },
        }));
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.location.state === "reload")
      this.componentDidMount()
  }

  closeNotification() {
    this.setState({ openNotification: false })
  }

  renderListItem(planfeature) {
    if(planfeature.feature.application && planfeature.feature.applicationid == 1) {
      return (
        <ListItem key={planfeature.feature.id}>
          <ListItemIcon>
            <FiberManualRecordIcon fontSize='small' style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
          </ListItemIcon>
          <ListItemText primary={planfeature.feature.name} />
        </ListItem>
      )
    }
  }

  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  }

  handleChangePhone(e) {
    this.setState(prevState => ({
      hirer: {
        ...prevState.hirer,
        ['responsePhone']: e
      }
    }));
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if(name == 'responsePhone') {
        this.setState(prevState => ({
            hirer: {
                ...prevState.hirer,
                [name]: this.mtel(value)
            }
        }));
    }else {
        this.setState(prevState => ({
            hirer: {
                ...prevState.hirer,
                [name]: value
            }
        }));
    }
  };

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    this.setState({ loading: true });

    if (!this.state.hirer.mail && this.state.hirer.mail.length == "0") {
      this.requiredInputNotification("O campo e-mail é obrigatório");
      return;
    }

    let formData = new FormData();
    formData.append("id", this.state.hirer.id)
    formData.append("name", this.state.hirer.name)
    formData.append("email", this.state.hirer.mail)
    formData.append("accesskey", this.state.hirer.passwordNew)
    formData.append("responsiblename", this.state.hirer.responseName)
    formData.append("responsiblephone", this.state.hirer.responsePhone)
    formData.append("responsiblemail", this.state.hirer.responseMail)

    var result = await update(formData);
    if(result && result.data && result.data.success) {

      if(result.data.data && result.data.data.accesskey) {
        this.setState(prevState => ({
          hirer: {
              ...prevState.hirer,
              password: result.data.data.accesskey
          }
        }));
        this.getContract()
        if(this.state.hirer.passwordNew != '' || (this.state.hirer.mail != this.state.hirer.mailbefore)){
          this.setState({ dialogLogout: true });
        }
      }

      this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({id:"edit.success"})
        });
    }else {
      const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage:  result.errors && result.errors[0] ? result.errors[0] : intl.formatMessage({id:"process.error"})
              });
    }

    this.setState({ loading: false });
  };

  handleCloseDialogLogout = () => {
    this.setState({ dialogLogout: !this.state.dialogLogout });
  }

  handleConfirmationDialogLogout =  () => {
    this.setState({ loading: true });
    Api.logoff();
  }

  render() {
    const { classes, headerMenu } = this.props
    const intl = this.props.intl

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    const handleExpandClick = () => {
      this.setState({ expanded: !this.state.expanded })
    }

    const openModal = () => {
      this.setState({ passwordTemp: this.state.hirer.password })
    }

    const closeModal = () => {
      this.setState({ passwordTemp: '********' })
    }

    return (
      <div className={ classes.root } style={{ paddingLeft:0, marginLeft:0 }}>
        <HeaderMenu />
        <Dialog
          open={this.state.dialogLogout}
          onClose={this.handleCloseDialogLogout}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Reiniciar Sessão</DialogTitle>

          <DialogContent>
          {intl.formatMessage({id:"log.again.to.save.changes"})}
          </DialogContent>
          
          <DialogActions>
            <Button onClick={this.handleCloseDialogLogout} color="primary">
              {this.props.intl.formatMessage({id:"cancel"})}
            </Button>
            <Button
             onClick={this.handleConfirmationDialogLogout} color="primary" autoFocus>
              {this.props.intl.formatMessage({id:"confirm"})}
            </Button>
          </DialogActions>
        </Dialog>
        <main
          className={ clsx(classes.content, {
            [classes.contentShift]: headerMenu.open
          }) } >
          <div className={ classes.drawerHeader } />
          {this.state.display != "none" && (
            <Container component='main' maxWidth='xl' style={{ paddingLeft:0, marginLeft:0 }}>
              <CssBaseline />
              <Grid
              container
              spacing={0}
            >
              <Grid item xs={12} sm={2} ></Grid>
              <Grid item xs={12} sm={8} style={{ marginTop: 20 }} >
                {this.state.displayMessage && (
                  <div style={{ paddingLeft: 30 }}>
                    <h3 style={{ fontSize: '2em', color: 'black', fontWeight: 500, textAlign: 'center' }}>Seu contrato está expirado.</h3>
                    <h4 style={{ fontSize: '1.5em', color: '#37363D', fontWeight: 500 }}>Dúvidas ou deseja renovar? entre em contato com o comercial:</h4>
                    <h5 style={{ color: '#37363D', fontWeight: 100 }}>+55 81 3194-3000 | contato@cmtech.com.br</h5>
                  </div>
                )}
                <Card className='Card'>
                  <CardHeader
                    titleTypographyProps={{variant:'h1' }}
                    title={ intl.formatMessage({ id: 'hirer.info' }) }
                  />
                  <CardContent>
                    <form name="myForm" className={classes.form} encType="multipart/form-data">
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            name="name"
                            variant="outlined"
                            fullWidth
                            id="name"
                            label={intl.formatMessage({id:"name"})}
                            value={this.state.hirer.name}
                            inputProps={{ maxLength: 255 }}
                            onChange={this.handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            name="responseName"
                            variant="outlined"
                            fullWidth
                            id="responseName"
                            label={intl.formatMessage({id:"responsible.contractor.name"})}
                            value={this.state.hirer.responseName}
                            inputProps={{ maxLength: 255 }}
                            onChange={this.handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <PhoneInput
                            inputStyle={{
                              padding: '23.5px 14px 23.5px 58px',
                              width: '100%'
                            }}
                            id="responsePhone"
                            name="responsePhone"
                            country={'br'}
                            value={this.state.hirer.responsePhone}
                            onChange={this.handleChangePhone}
                            InputLabel={intl.formatMessage({id:"responsible.contractor.phone"})}
                            specialLabel={intl.formatMessage({id:"responsible.contractor.phone"})}
                            inputProps={{
                              name: intl.formatMessage({id:"responsible.contractor.phone"})
                            }} />
                          {/*<TextField
                            name="responsePhone"
                            variant="outlined"
                            fullWidth
                            id="responsePhone"
                            label={intl.formatMessage({id:"responsible.contractor.phone"})}
                            value={this.state.hirer.responsePhone}
                            inputProps={{ maxLength: 255 }}
                            onChange={this.handleInputChange}
                          />*/}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            name="responseMail"
                            variant="outlined"
                            fullWidth
                            id="responseMail"
                            label={intl.formatMessage({id:"responsible.contractor.email"})}
                            value={this.state.hirer.responseMail}
                            onChange={this.handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            name="mail"
                            variant="outlined"
                            fullWidth
                            id="mail"
                            label={intl.formatMessage({id:"contractor.email"})}
                            value={this.state.hirer.mail}
                            required
                            onChange={this.handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            name="passwordNew"
                            variant="outlined"
                            fullWidth
                            id="passwordNew"
                            label={intl.formatMessage({id:"access.key"})}
                            type='passwordNew'
                            placeholder='Deixe em branco para não alterar'
                            value={this.state.hirer.passwordNew}
                            onChange={this.handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
                            <Button
                            onClick={this.handleSubmit}
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            >
                            {intl.formatMessage({id:"update"})}
                            </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                    <Typography variant="body2" color="textPrimary" component="p">
                      {intl.formatMessage({id:"contractor.access.key"})} { this.state.passwordTemp } {this.state.passwordTemp == '********' ? <IconButton onClick={openModal}><VisibilityIcon /></IconButton> : <IconButton onClick={closeModal}><VisibilityOffIcon /></IconButton> }
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={2} ></Grid>
              <Grid item xs={12} sm={2} ></Grid>
              <Grid item xs={12} sm={8} >
              { (this.state.contractPlanPeriodicity && this.state.contractPlanPeriodicity.id) ? (
              <Card className='Card' style={{ marginTop: 20 }}>
                <CardHeader
                  action={
                    <span
                      className='CardStatusSpan'
                      style={{ backgroundColor: this.state.item.contractStatusColor }}>
                        { this.state.item.contractStatus }
                    </span>
                  }
                  titleTypographyProps={{variant:'h1' }}
                  title={ this.state.item.applicationPlan }
                  subheaderTypographyProps={{ variant: 'h3' }}
                  subheader={ this.state.item.periodicity }
                />
                <CardContent>
                  <List dense={true}>
                      <ListItem key={3}>
                        <ListItemIcon>
                          <CheckIcon style={{ color: '#43a047' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.state.item.userLimit}
                        />
                      </ListItem>
                      <ListItem key={2}>
                        <ListItemIcon>
                          <CheckIcon style={{ color: '#43a047' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.state.item.diskLimit}
                        />
                      </ListItem>
                      <ListItem key={1}>
                        <ListItemIcon>
                          <CheckIcon style={{ color: '#43a047' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.state.item.attendanceLimit}
                        />
                      </ListItem>
                  </List>
                  { this.state.item.contractStatusFlag != null && this.state.item.contractStatusFlag == false && this.state.item.endDate && this.state.item.endDate != '00/00/0000 00:00' && this.state.item.endDate != '0001-01-01T00:00:00' && (
                  <Typography variant="h5" style={{ color: 'rgba(0, 0, 0, 0.54)' }} component="h5">
                    {intl.formatMessage({id:"typography.plan.until"})} { this.state.item.endDate }
                  </Typography>
                )}
                  <Typography style={{ marginTop: 15, fontWeight: 'bolder' }} component="strong">
                    {intl.formatMessage({id:"menu.statuses"})} { this.state.item.stts }
                  </Typography>
                  <br />
                  <Typography style={{ marginTop: 5, fontWeight: 'bolder' }} component="strong">
                    R${ this.state.item.planValue.replaceAll('.', ',') }
                  </Typography>
                { this.state.item.initDate && this.state.item.initDate != '00/00/0000 00:00' && this.state.item.initDate != '0001-01-01T00:00:00' && (
                  <Typography style={{ marginTop: 10 }} variant="body2" color="textPrimary" component="p">
                    {intl.formatMessage({id:"begin.date"})} { this.state.item.initDate }
                  </Typography>
                )}
                { this.state.item.contractStatusFlag != null && this.state.item.contractStatusFlag == true && this.state.item.endDate && this.state.item.endDate != '00/00/0000 00:00' && this.state.item.endDate != '0001-01-01T00:00:00' && (
                  <Typography variant="body2" color="textPrimary" component="p">
                    {intl.formatMessage({id:"finish.date"})} { this.state.item.endDate }
                  </Typography>
                )}
                  <Typography variant="body2" color="textPrimary" component="p">
                    {intl.formatMessage({id:"acceptance.date"})} { this.state.item.acceptDate }
                  </Typography>
                { this.state.item.byllingDate && this.state.item.byllingDate != '00/00/0000 00:00' && this.state.item.byllingDate != '0001-01-01T00:00:00' && (
                  <Typography variant="body2" color="textPrimary" component="p">
                    {intl.formatMessage({id:"charge.payment.date"})} { this.state.item.byllingDate }
                  </Typography>
                )}
                { this.state.item.contractStatusFlag != null && this.state.item.contractStatusFlag == false && this.state.item.inativeDate && this.state.item.inativeDate != '00/00/0000 00:00' && this.state.item.inativeDate != '0001-01-01T00:00:00' && (
                  <Typography variant="body2" color="textPrimary" component="p">
                    {intl.formatMessage({id:"inactivity.date"})} { this.state.item.inativeDate }
                  </Typography>
                )}
                { this.state.item.paymentDate && this.state.item.paymentDate != '00/00/0000 00:00' && this.state.item.paymentDate != '0001-01-01T00:00:00' && (
                  <Typography style={{ marginTop: 15 }} variant="body2" color="textPrimary" component="p">
                    {intl.formatMessage({id:"last.payment.date"})} { this.state.item.paymentDate }
                  </Typography>
                )}
                { this.state.item.lastPayStatus && this.state.item.lastPayStatus != intl.formatMessage({id:"menu.statuses"}) && (
                  <Typography variant="body2" color="textPrimary" component="p">
                    {intl.formatMessage({id:"last.payment.status"})} { this.state.item.lastPayStatus }
                  </Typography>
                )}
                </CardContent>
                <CardActions disableSpacing>
                  <Typography style={{ marginBottom: 15, color: 'black' }} variant="h5" color="textPrimary" component="h5">
                    {intl.formatMessage({id:"resources"})}
                  </Typography>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: this.state.expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={this.state.expanded}
                    aria-label="Features"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    
                    <List dense={true}>
                      { this.state.contractPlanPeriodicity.planperiodicity.plan.planfeatures.map((item) => this.renderListItem(item)) }
                    </List>
                  </CardContent>
                </Collapse>
              </Card>

              ) : (
                <Typography style={{ marginBottom: 50, color: 'black' }} variant="h1" color="textPrimary" component="h1">
                  {intl.formatMessage({id:"my.plan.cant.recover.info"})}
                </Typography>
              )}
              </Grid>
              <Grid item xs={12} sm={2} ></Grid>
              </Grid>
            </Container>
          )}
            
          {this.state.loading && (
            <Overlay>
              <CircularProgress color='secondary' />
            </Overlay>
          )}

          <CustomizedSnackbars
            variant={ this.state.notificationVariant }
            message={ this.state.notificationMessage }
            isOpen={ this.state.openNotification }
            toClose={ this.closeNotification } />

        </main>
        <Footer />   
      </div>
    )
  }
}

MyPlan.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(MyPlan)))

MyPlan.contextType = AbilityContext