import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table/index-report';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from "moment";
import * as componentActions from '../../redux/actions/calledstatuses';
class CalledStatuses extends Component {

    constructor(props) {
        super(props);

        const { userSession, calleds } = this.props;

        this.state = {
            calledId: calleds.called.id,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
        };

        this.closeNotification = this.closeNotification.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        this.getItens();
    }

    getItens = () => {
        this.setState({ loading: true });


        Api.get(`/calleds/assignedusers/${this.state.calledId}`)

            .then(result => {
                if (result.data.success) {
                    // console.log(result.data.data);
                    const formattedList = result.data.data.map(item => {
                        return {
                            ...item,
                            NextUserText: item.nextuser ? item.nextuser.name : '',
                            PreviousUserText: item.previoususer ? item.previoususer.name == item.nextuser.name ? '' : item.previoususer.name : '',
                            StatusText: item.status ? item.status.name : "",
                            DateCreated: moment(item.createdat).format("DD/MM/YYYY HH:mm"),
                            UserName: item.called ? item.called.description : ""
                        }
                    });

                    this.setState({ loading: false, resultList: formattedList });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
        const { classes } = this.props;
        const intl = this.props.intl;

        return (
            <>
                <MyMaterialTable
                    title={intl.formatMessage({ id: "calledStatus.header" })}
                    columns={[
                        { title: intl.formatMessage({id:"previous.responsible"}), field: 'PreviousUserText' },
                        { title: intl.formatMessage({id:"updated.responsible"}), field: 'NextUserText' },
                        { title: intl.formatMessage({id:"menu.statuses"}), field: 'StatusText' },
                        { title: intl.formatMessage({ id: "date" }), field: 'DateCreated' },
                        { title: intl.formatMessage({ id: "user" }), field: 'UserName' }
                    ]}
                    data={this.state.resultList}
                />

                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}
            </>
        );
    }
}
CalledStatuses.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    userSession: state.userSession,
    calleds: state.calleds,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledStatuses)));

CalledStatuses.contextType = AbilityContext;