import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, CircularProgress, Container } from "@mui/material";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import Rating from '@mui/material/Rating';
import CustomizedSnackbars from "../../components/material-snackbars";
import CallEndIcon from '@mui/icons-material/CallEnd';
import CallIcon from '@mui/icons-material/Call';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ReactHtmlParser from 'react-html-parser';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { renderToString, renderToStaticMarkup } from "react-dom/server"
import { injectIntl } from "react-intl";

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.ButtonExpired = null
    this.state = {
      feedback: {
        description: "",
        accessToken: "",
        feedbackBo: "",
        title : null,
        technicalName : null,
        userName : null,
        calledId : null,
        calledDescription: "",
        acesstokenassigned: ""
      },
      calledId:null,
      buttonRender : false,
      loading: false,
      disabledButtons : true,
      openNotification: false,
      notificationVariant: "error",
      disabledResendButton : false,
      notificationMessage: "",
      starRating: 0,
      ratingVisible: true
    };
    this.closeNotification = this.closeNotification.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectedFeedback = this.selectedFeedback.bind(this);
    this.insertClassification = this.insertClassification.bind(this);
    this.resendEmail = this.resendEmail.bind(this);

  }

  selectedFeedback(event) {
    document.getElementById('ThumbUp').style.color = 'black';
    document.getElementById('ThumbDown').style.color = 'black';

    document.getElementById('ThumbUp').classList.remove("active");
    document.getElementById('ThumbDown').classList.remove("active");

    if (event.currentTarget.id == 'ThumbDown') {
      event.currentTarget.style.color = 'red';
      document.getElementById('ThumbDown').classList.add("active");

      this.setState({ ratingVisible: false });

    } else {
      event.currentTarget.style.color = 'green';
      document.getElementById('ThumbUp').classList.add("active");

      this.setState({ ratingVisible: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    if (this.props.match.params.token) {
      this.getFeedback(this.props.match.params.token);
    }

    document.getElementById('ThumbUp').style.color = 'black';
    document.getElementById('ThumbDown').style.color = 'black';

    if (this.props.match.params.type == 'positive') {
      document.getElementById('ThumbUp').style.color = 'green';
      document.getElementById('ThumbUp').classList.add("active");
    } else if (this.props.match.params.type == 'negative') {
      this.setState({ ratingVisible: false });
      document.getElementById('ThumbDown').style.color = 'red';
      document.getElementById('ThumbDown').classList.add("active");
    }
  }

  getFeedback(token) {
    this.setState({ loading: true });

    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    };

    if(this.props.location.search && this.props.location.search.length > 20){
      config.headers['Authorization'] = `Bearer ${this.props.location.search.substr(1)}`;
    }

    Api.get(`/feedback/${token}`, config)
      .then(result => {
        if (result.data.success) {
          const value = result.data.data.accesstoken;
        
          let ordenedAccompaniments = result.data.data.called.accompaniments ? result.data.data.called.accompaniments.sort((a, b) => b.id - a.id) : null

          let lastAccomp = ordenedAccompaniments ? ordenedAccompaniments[0].description : result.data.data.called.accompaniments[result.data.data.called.accompaniments.length-1].description

          if(lastAccomp.trim().startsWith("<p> &lt;h")) {
            lastAccomp = renderToStaticMarkup(lastAccomp)
          } else if(lastAccomp.trim().startsWith("<div style=\"") || lastAccomp.trim().startsWith("<div dir=") || lastAccomp.trim().startsWith("<p>") || lastAccomp.trim().startsWith("<h1>") || lastAccomp.trim().startsWith("<h2>") || lastAccomp.trim().startsWith("<h3>")) {
            
          } else {
            lastAccomp = renderToStaticMarkup(lastAccomp)
          }

          console.log("=== LastAccomp ===")
          console.log(lastAccomp)

          this.setState(prevState => ({
            loading: false,
            disabledButtons : false,
            feedback: {
              ...prevState.feedback,
              calledDescription : result.data.data.called.description,
              calledId : result.data.data.calledid,
              title : result.data.data.called.title,
              technicalName : result.data.data.called.assigneduser.name,
              feedbackBo: result.data.data.feedbackbo,
              lastaccompainiment : lastAccomp,
              'accesstoken': value
            }
          }));
        }
        else{
          this.setState({
            disabledButtons : true,
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          }); 
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true,disabledButtons : true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });

        if(err.response && err.response.data && err.response.data.errors &&  err.response.data.errors[0])
        {
          
            if(err.response.data.errors[0] == intl.formatMessage({id:"nps.email.rating.link.expired.feedback"})){
            
              this.addButtonExpiredToken()
            }
        }
       // document.getElementById('submit').classList.add("Mui-disabled");
      });
    this.setState({ loading: false });
  };

  componentDidUpdate(_, prevState) { }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      feedback: {
        ...prevState.feedback,
        [name]: value
      }
    }));
  }

  addButtonExpiredToken(){
    const intl = this.props.intl;
    
  const { classes } = this.props;
  this.ButtonExpired =   
        <Button
      
        variant="contained"
        color="primary"
        className={classes.submit}
        
        onClick={this.resendEmail}
        style={{width: "300px",marginRight:'16px'}}
 
      >
        {intl.formatMessage({id:"resend.feedback.link"})}
      </Button>

  this.setState({ buttonRender: true, loading: false});
  
 

}

async resendEmail(){
  const intl = this.props.intl;
  this.setState({ loading: true});

  console.log("entrei aqui")

Api.put(`feedback/resendFeedback/${this.props.match.params.token}`)
  .then(result => {
    if (result.data.success) {

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "success",
        notificationMessage: intl.formatMessage({id:"nps.email.rating.feedback.link.sent"}),
        disabledResendButton : true
      });

      
    }
  })
  .catch(err => {
    this.setState({
      loading: false,
      openNotification: true,
      notificationVariant: "error",
      disabledResendButton : true,
      notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      
    });
  })

  
  

}

  insertClassification(feedback) {
    const intl = this.props.intl;

    const { starRating } = this.state;
    const { history } = this.props;

    Api.post("/calledclassifications/external", { ObservationAssigned: feedback.description, NoteAssigned: starRating, CalledId: feedback.calledid })
      .then(result => {
        // console.log(result)
        if (result.data.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({id:"feedback.assigned.feedback.received"})
          });
          setTimeout(function () { history.push("/"); }, 1000);
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({id:"feedback.assigned.error"})
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
      })

    this.setState({ loading: false })
  }

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    this.setState({ loading: true });

    const { starRating } = this.state;

    if ((starRating === 0 || starRating == undefined || starRating == null) && this.state.ratingVisible) {
      
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"feedback.assigned.grade.attendance"}),
      });

    } else {
      let feedbackBoValue;

      let feedbackBoId = document.getElementsByClassName('active')[0].id;

      if (feedbackBoId == 'ThumbUp') {
        feedbackBoValue = "1"; //Mão pra cima e aceitou o atendimento
      } else {
        feedbackBoValue = "2" //Mão pra baixo e recusou o atendimento, feedbackbo serve pra isso
      }

      const { description, accesstoken } = this.state.feedback;
      const { history } = this.props;

      if (feedbackBoValue == '2' && description == "") {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"feedback.assigned.called.reopen.justification"})
        });
      } else {

        let config = {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        };
    
        if(this.props.location.search && this.props.location.search.length > 20){
          config.headers['Authorization'] = `Bearer ${this.props.location.search.substr(1)}`;
        }

        Api.put("/feedback", { Description: description, FeedbackBo: feedbackBoValue, AccessToken: accesstoken, Rating: starRating, ApplicationOriginId: 2 }, config)
          .then(result => {
            if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({id:"feedback.assigned.feedback.received"})
              });
              setTimeout(function () { history.push("/"); }, 3000);
            }
          })
          .catch(err => {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            if(err.response && err.response.data && err.response.data.errors &&  err.response.data.errors[0])
            {
              
                if(err.response.data.errors[0] == intl.formatMessage({id:"nps.email.rating.link.expired.feedback"})){
                
                  this.addButtonExpiredToken()
                }
            }
          })
      }
    }

  };
  closeNotification() {
    this.setState({ openNotification: false });
  }
  render() {
    const intl = this.props.intl;

    const styles = {

      regularFont : {
       color: "black",
       fontWeight: "700",
       margin: "0px",
       padding: "0px",
     },
 
     grayFont : {
       margin: "0px",
       padding: "0px",
       display: 'flex',
       color: "#909497 ",
       fontSize: "15px",
       fontWeight: "500",
     },
 
      alignMiddle : {
     
       verticalAlign : "middle"
     },

   }
    const { classes } = this.props;
    const { feedback } = this.state;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <Container component="main" maxWidth="md">

        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        { this.state.feedback.feedbackBo && this.state.feedback.feedbackBo != "" && this.state.feedback.feedbackBo > 0 ? (
          <div style={{marginTop: "20px", marginBottom: "20px"}}>
            <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
              <div>
                <h4 style={styles.regularFont} ><CallEndIcon/> Chamado #{this.state.feedback.calledId} • {this.state.feedback.title} Já foi avaliado!</h4>
              </div>
            <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
              <div>
                <h4 style={styles.grayFont}> <AccountCircleIcon style={styles.alignMiddle}/> <span  style={styles.regularFont} > {intl.formatMessage({id:"modalRating.called.user"})} :</span> <span>{this.state.feedback.technicalName}</span> </h4>
              </div>
            <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
            <div>
              <h4 style={styles.grayFont}> <DescriptionIcon style={styles.alignMiddle}/> <span style={styles.regularFont}>{intl.formatMessage({id:"description"})}</span> <span>{ReactHtmlParser(this.state.feedback.calledDescription)}</span> </h4>
            </div>
            <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
            <div>
              <Accordion style={{marginTop:  "10px"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`last-accompaniment-content`}
                  id={`alast-accompaniment-header`} >
                  <PlaylistAddCheckIcon style={styles.alignMiddle}/><span style={styles.regularFont}>Ultimo Acompanhamento :</span>
                </AccordionSummary>
                <AccordionDetails>
                  <p><div dangerouslySetInnerHTML={{__html: this.state.feedback.lastaccompainiment}} /></p>
                </AccordionDetails>
              </Accordion>
            </div>
            <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
            <div>
              <Rating 
                style={{fontSize: "45px"}}
                readOnly
                value={this.state.feedback.feedbackBo}
              />
            </div>
          </div>
        ) : (
        <div>
        <div>
          <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
          <div>
            <h4 style={styles.regularFont} >Avalie seu chamado</h4>
          </div>

          <div style={{marginTop: "20px"}}>
            <h4 style={styles.grayFont}> <CallEndIcon style={styles.alignMiddle}/> Chamado {this.state.feedback.calledId} • {this.state.feedback.title}</h4>
          </div>
          <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
         
        </div>
        <div>
          <h4 style={styles.grayFont}> <AccountCircleIcon style={styles.alignMiddle}/> <span  style={styles.regularFont} > {intl.formatMessage({id:"modalRating.called.user"})} :</span> <span>{this.state.feedback.technicalName}</span> </h4>
        </div>

        <Divider style={{marginTop: "20px", marginBottom: "20px"}} />

        <div>
          <h4 style={styles.grayFont}> <DescriptionIcon style={styles.alignMiddle}/> <span style={styles.regularFont}>{intl.formatMessage({id:"description"})}</span> <span>{ReactHtmlParser(this.state.feedback.calledDescription)}</span> </h4>
        </div>

        <Divider style={{marginTop: "20px", marginBottom: "20px"}} />

        <div>
          <Accordion style={{marginTop:  "10px"}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`last-accompaniment-content`}
              id={`alast-accompaniment-header`} >
               <PlaylistAddCheckIcon style={styles.alignMiddle}/><span style={styles.regularFont}>Ultimo Acompanhamento :</span>
            </AccordionSummary>
            <AccordionDetails>
              <p><div dangerouslySetInnerHTML={{__html: this.state.feedback.lastaccompainiment}} /></p>
            </AccordionDetails>
          </Accordion>
          
        </div>

        <Divider style={{marginTop: "20px", marginBottom: "20px"}} />

        <div>
          <div>
            <h4 style={styles.regularFont} > Você está de acordo com a solução apresentada? </h4>
          </div>
        </div>

        <Grid container spacing={2} style={{marginTop: "15px"}}>
          <Grid item xs={6} sm={6} style={{textAlign: 'right'}}>
              <a href='#'><ThumbUp disabled={this.state.disabledButtons} style={{fontSize: '32px'}} onClick={this.selectedFeedback} id="ThumbUp"/></a>
          </Grid>
          <Grid item xs={6} sm={6}>
            <a href='#'> <ThumbDown disabled={this.state.disabledButtons} style={{fontSize: '32px'}} onClick={this.selectedFeedback} id="ThumbDown"/> </a>
          </Grid>
        </Grid>
        {this.state.ratingVisible && (
          <div style={{marginTop: "15px"}}>
            <div>
              <h4 style={styles.regularFont} > O que você achou do Atendimento? </h4>
            </div>
            <div style={{marginTop: "6px"}}>
              <h4 style={styles.grayFont}> Escolha de 1 a 5 estrelas para avaliar</h4>
            </div>
          </div>
        )}
        {!this.state.ratingVisible && (
          <div style={{marginTop: "15px"}}>
            <div>
              <h4 style={styles.regularFont} > Explique um pouco sobre o que aconteceu </h4>
            </div>
          </div>
        )}
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={12} container alignItems="flex-start" justify="left" direction="row" style={{display: this.state.ratingVisible ? '' : 'none' }}>
              <Rating 
                style={{fontSize: "45px"}}
                name="rating"
                required
                disabled={this.state.disabledButtons}
                value={this.state.starRating}
                onChange={(event, newValue) => {
                  this.setState({
                    starRating: newValue
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                id="outlined-multiline-static"
                label={intl.formatMessage({id:"comment"})}
                name="description"
                disabled={this.state.disabledButtons}
                multiline
                rows="8"
                fullWidth
                variant="outlined"
                onChange={this.handleInputChange}
              />
            </Grid>
          </Grid>
          <div style={{textAlign: "right"}}>

          
          {!this.state.disabledResendButton && (

          <>
            {this.ButtonExpired}
          </>

          )}  
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              id="submit"
              disabled={this.state.disabledButtons}
              style={{width: "200px"}}
              
            >
              Avaliar Chamado
            </Button>
          </div>
            
          </form>
        </div>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
    );
  }
}

Feedback.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectIntl(withStyles(Styles)(Feedback));
