import React, { Component, useEffect  } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Link from "@mui/material/Link";
import moment from "moment";
import Grid from '@mui/material/Grid';
import SimpleCheckBox from '../checkbox/check';

import {IconButton, Tooltip} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckIcon from '@mui/icons-material/Check';
import ReactHtmlParser from 'react-html-parser';
import { renderToString, renderToStaticMarkup } from "react-dom/server"
import { PapiroConsole } from "../../utils/papiroConsole";
import './styles.css'
import { injectIntl } from 'react-intl';
import parse from "html-react-parser";

class AccompanimentAction extends Component {

    constructor(props) {
        super(props);
        
        //this.action = renderToStaticMarkup(<p> {props.action.description} </p>);
		this.action = props.action.description;

        let reg = new RegExp(/\@\@{.*\|\@\|.*\}$$/gm)
        let testReg = reg.test(props.action.description.trim())

        if(props.action.description.trim().startsWith("<p> &lt;h") || props.action.description.trim().startsWith("<p>&lt;")) {
            this.action = renderToStaticMarkup(props.action.description)
            PapiroConsole.log("=== AccompanimentAction Video if 1 ===")
            PapiroConsole.log(this.action)
        } else if(props.action.description.trim().startsWith("<div style=\"") || props.action.description.trim().startsWith("<div dir=") || props.action.description.trim().startsWith("<p>") || props.action.description.trim().startsWith("<h1>") || props.action.description.trim().startsWith("<h2>") || props.action.description.trim().startsWith("<h3>")) {
            this.action = props.action.description
            PapiroConsole.log("=== AccompanimentAction Video else if ===")
            PapiroConsole.log(this.action)
        } else if(props.action.description.trim().endsWith(".webm") || props.action.description.trim().endsWith(".webm}")){
			
            let descriptionSplice = props.action.description.split(" : ");
            if(descriptionSplice.length > 0){
                if(descriptionSplice[0] && descriptionSplice[1]){      
                    let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"video.sent"}))} <br/> <video key={'downloadVideo0'} id={'downloadVideo0'} style={{ width: '310px', height: '310px' }} playsInline controls><source src={descriptionSplice[1]} type='video/webm'/></video> </p>
                    this.action = renderToStaticMarkup(newDescription);
                }
            }
        } else if(testReg) {
			
            let descriptionSplice = props.action.description.split(" : ")
            if(descriptionSplice.length > 0) {
                if(descriptionSplice[0] && descriptionSplice[1]) {
                    let newAction = descriptionSplice[1].slice(3, -1)
                    let newActionSplice = newAction.split("|@|")
                    if(newActionSplice.length > 0) {
                        if(newActionSplice[0] && newActionSplice[1]) {
                            let title = newActionSplice[1]
                            if(title.trim().endsWith("}"))
                                title = title.slice(0, -1)
							if(props.action.description.trim().endsWith(".pdf") || props.action.description.trim().endsWith(".pdf}")){	
								
								let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"document.sent"}))} <br/> <a href={newActionSplice[0]}  target="_blank"> {title} </a> </p>
								this.action = renderToStaticMarkup(newDescription);
							}
							else{
								
								let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"video.sent"}))} <br/> <a href={newActionSplice[0]}  target="_blank"> {title} </a> </p>
								this.action = renderToStaticMarkup(newDescription);
							}
                        }
                    }
                }
            }
        }else if(props.action.description.trim().endsWith(".pdf") || props.action.description.trim().endsWith(".pdf}")){
			
			let descriptionSplice = props.action.description.split(" : ");
            if(descriptionSplice.length > 0){
                if(descriptionSplice[0] && descriptionSplice[1]){
                    let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"document.sent"}))} <br/> <a href={descriptionSplice[1]}  target="_blank"> {descriptionSplice[1]} </a> </p>
                    this.action = renderToStaticMarkup(newDescription);
                }
            }
            
        }else if(props.action.description.trim().endsWith(".jpg") || props.action.description.trim().endsWith(".png") || props.action.description.trim().endsWith(".jpeg")
		  || props.action.description.trim().endsWith(".jpg}") || props.action.description.trim().endsWith(".png}") || props.action.description.trim().endsWith(".jpeg}")){
            let descriptionSplice = props.action.description.split(" : ");
            if(descriptionSplice.length > 0){
                if(descriptionSplice[0] && descriptionSplice[1]){
                    let newDescription = <p> {descriptionSplice[0]} <br/> <a href={descriptionSplice[1]}  target="_blank"> {descriptionSplice[1]} </a> </p>
                    this.action = renderToStaticMarkup(newDescription);
                }
            }
            
        }
    }
    
    render() {

        const { classes, action, showPrivacy, attachments, getFile, id, finishAccompanimentId, addAccompanimentToKnowledgeBase, deleteKnowledgeBase, knowledgebage, originId, openAccompanimentLocationModal, client } = this.props;

        const handleFile = (fileName, id,filerepositorymethod) => {
            getFile(fileName, id,filerepositorymethod);
        }
        const handleKnowledgeBaseState = async (statename, value) => {
            //? Adiciona à base ou deleta dependendo do estado do checkbox (value)
            value ? addAccompanimentToKnowledgeBase() : deleteKnowledgeBase(); 
        }
        const handleOpenAccompanimentLocationModal = () => {
            openAccompanimentLocationModal()
        }
        
        return (
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12}>
                    <div className={classes.actionBox}>
                        <Grid item xs={12} sm={12}>
                            <div className={action.privacy ? 'privacyAction' : 'publicAction'}>
                                <p>
                                    <span className="user-action-name">{action.useraction.name}</span>
                                    <span className="date">{moment(action.actiontime).format("DD/MM/YYYY HH:mm")}</span>
                                    {showPrivacy && <span className="type">{action.privacy ? this.props.intl.formatMessage({id:"private.action"}) : this.props.intl.formatMessage({id:"public.action"})}</span>}
                                    { originId && originId == 6 && (<IconButton style={{ padding: 0 }} aria-label="LocationIcon"><LocationOnIcon onClick={handleOpenAccompanimentLocationModal} style={{ fontColor: "#CC1717", fill: "#CC1717" }} /></IconButton>)}
                                    { id == finishAccompanimentId && (<Tooltip title={<h5>{this.props.intl.formatMessage({id:"accompaniment.actions.title.recently.added.action"})}</h5>}><IconButton style={{ padding: 0, marginLeft: 10 }} aria-label="CheckIcon"><CheckIcon style={{ fontColor: "#43a047", fill: "#43a047" }} /></IconButton></Tooltip>) }
                                    <span style={{float: "right", border: "none", marginTop: "-10px"}}>

                                        {(!action.privacy && !client) && 

                                            <SimpleCheckBox 
                                                label={this.props.intl.formatMessage({id:"add.to.knowledgebase"})}
                                                name='knowledgebage' 
                                                stateName='knowledgebage' 
                                                changeSelect={handleKnowledgeBaseState} 
                                                selected={knowledgebage} />
                                        
                                        }
                                       
                                    </span>
                                </p>
                            </div>
                        </Grid>
                        {/*this.action*/}
						{/*ReactHtmlParser(this.action)*/} 
						{/*<p><div className="action-text-content" style={{ fontWeight: 'normal' }} dangerouslySetInnerHTML={{__html: this.action}} /></p>
						<div className="action-text-content" style={{ fontWeight: 'normal' }}> { ReactHtmlParser (this.action) } </div>
									*/}
						<p><div className="action-text-content" style={{ fontWeight: 'normal' }}> { parse (this.action) } </div></p>

                        <div className={classes.attachmentsBox} style={{border: 0}}>
                            <ul>
                                {attachments.map(item => {
                                    return (<li key={item.attachment.path}>
                                        <AttachFileIcon /> <Link onClick={() => handleFile(item.attachment.path, id, item.attachment.filerepositorymethod)}>{item.attachment.description}</Link>
                                    </li>)
                                })}
                            </ul>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

AccompanimentAction.propTypes = {
    classes: PropTypes.object.isRequired,
    action: PropTypes.object.isRequired,
    showPrivacy: PropTypes.bool.isRequired,
    attachments: PropTypes.array,
    getFile: PropTypes.func,
    id: PropTypes.number
};

export default injectIntl(withStyles(Styles)(AccompanimentAction));