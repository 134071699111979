export default function headerMenu(state = [], action) {
  switch (action.type) {
    case 'TOGGLE':
      return {
        open: action.open,
      }
    case 'RELOAD':
      return {
        refresh: action.refresh,
      }
    default:
      return state;
  }
}
